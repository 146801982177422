import { useCallback, useMemo } from 'react';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { useAtom } from 'jotai';
import { getUnixTime } from 'date-fns';
import { atomWithStorage } from 'jotai/utils';
import { Alert } from '@uniled/api-sdk';

const alertsKey = 'LATEST_ALERTS';
const lastFetchKey = 'LAST_FETCH_ALERTS';
// atoms only populate their value on mount. Rubbish, so just populating default value with storage value
const atom_lastFetch = atomWithStorage<number>(
    lastFetchKey,
    Number(localStorage.getItem(lastFetchKey)),
);
const atom_latestAlerts = atomWithStorage<Alert[]>(
    alertsKey,
    JSON.parse(localStorage.getItem(alertsKey)),
);

// only do a max of 1 fetch per minute
const minSecondsFromLastFetch = 60;

function useSystemAlerts() {
    const [lastFetch, setLastFetch] = useAtom(atom_lastFetch);
    const [latestAlerts, setLatestAlerts] = useAtom(atom_latestAlerts);
    const { start } = useAPIClientRequest(apiClient.Entities.Alert.list);
    const requiresFetch = useMemo(
        () => lastFetch < getUnixTime(new Date()) - minSecondsFromLastFetch,
        [lastFetch],
    );

    // just want to prevent showing 'old' alerts until they have been refetched
    const publicAlerts = useMemo(
        () => (latestAlerts && !requiresFetch ? latestAlerts : []),
        [latestAlerts, requiresFetch],
    );

    const fetchAlerts = useCallback(async () => {
        if (lastFetch == null || lastFetch <= 0 || requiresFetch) {
            const [err, succ] = await to(start({ filters: { scope: ['live', 'upcoming'] } }));

            if (!err && succ != null) {
                setLastFetch(getUnixTime(new Date()));
                setLatestAlerts(succ?.data?.data ?? []);
            }
        }
    }, [lastFetch, requiresFetch, setLastFetch, setLatestAlerts, start]);
    return { fetchAlerts, alerts: publicAlerts };
}

export default useSystemAlerts;
