import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAPIClientRequest, useEntityPage } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import {
    Box,
    LinearProgress,
    LinearProgressProps,
    ListItem,
    Stack,
    Tooltip,
    Typography,
    TypographyProps,
} from '@mui/material';
import { CampaignPopShot } from '@uniled/api-sdk';
import { NetworkRequestState } from '@uniled/data-layer';
import { AutoGrid, ListItemText } from 'c-components';
import { formatBytes } from 'c-lib';
import { CheckCircle, Error } from '@mui/icons-material';

type Props = {
    id: string;
    file: File;
    lineItemId: string;
    onUploadComplete: (id: string, popshot: CampaignPopShot) => void;
    onUploadFailed: (id: string) => void;
    pro: boolean;
};

const primaryTypography: TypographyProps<any> = {
    component: 'div',
    sx: { wordBreak: 'break-all' },
};

const PopShotUploadListItem: React.FC<Props> = ({
    id,
    file,
    lineItemId,
    onUploadComplete,
    onUploadFailed,
    pro,
}) => {
    const [progress, setProgress] = useState<ProgressEvent>(null);
    const progressPercentage = useMemo(
        () =>
            progress?.total != null && progress?.loaded != null
                ? (progress.loaded / progress.total) * 100
                : 0,
        [progress],
    );
    const { start, requestState, error } = useAPIClientRequest(
        apiClient.Entities.CampaignPopShot.create,
    );

    const progressColor = useMemo<LinearProgressProps['color']>(() => {
        if (requestState === NetworkRequestState.Error) return 'error';
        if (progressPercentage >= 100) return 'success';

        return 'primary';
    }, [requestState, progressPercentage]);

    const { id: CampaignId } = useEntityPage();

    const uploadFile = useCallback(
        async (file: File) => {
            //
            const formData = new FormData();
            formData.set('campaign_id', String(CampaignId));
            formData.set('file', file);
            formData.set('line_item_id', lineItemId);
            formData.set('type', pro ? 'professional' : 'pop_shot');
            const [, success] = await to(start({ formData, onProgress: setProgress }));

            if (success?.data?.data != null) {
                onUploadComplete(id, success?.data?.data);
            } else {
                onUploadFailed(id);
            }
        },
        [CampaignId, start, onUploadComplete, onUploadFailed, id, lineItemId, pro],
    );

    useEffect(() => {
        uploadFile(file);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const textProps = useMemo<TypographyProps<any, any>>(() => {
        if (requestState === NetworkRequestState.Success)
            return { color: 'success.main', ...primaryTypography };
        if (requestState === NetworkRequestState.Error)
            return { color: 'error.main', ...primaryTypography };
        return { component: 'div', ...primaryTypography };
    }, [requestState]);

    return (
        <ListItem>
            <ListItemText
                primary={file.name}
                primaryTypographyProps={textProps}
                secondaryTypographyProps={textProps}
                secondary={
                    <Stack gap={1} width="100%">
                        <AutoGrid spacing={1}>
                            {formatBytes(file.size)} | {file.type}
                        </AutoGrid>
                        <Box width="100%">
                            <Stack direction="row" gap={1} alignItems="center">
                                <Box flex={1}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={progressPercentage}
                                        color={progressColor}
                                    />
                                </Box>
                                {requestState === NetworkRequestState.InProgress && (
                                    <Typography variant="caption">
                                        {progressPercentage.toFixed(0)} %
                                    </Typography>
                                )}
                                {requestState === NetworkRequestState.Error && (
                                    <Tooltip title={String(error)}>
                                        <Typography color="error.main">
                                            <Error />
                                        </Typography>
                                    </Tooltip>
                                )}
                                {requestState === NetworkRequestState.Success && (
                                    <Typography color="success.main">
                                        <CheckCircle />
                                    </Typography>
                                )}
                            </Stack>
                        </Box>
                    </Stack>
                }
            />
        </ListItem>
    );
};

export default PopShotUploadListItem;
