// search for greatest common divisor
function greatestCommonDivisor(width: number, height: number) {
    return width % height ? greatestCommonDivisor(height, width % height) : height;
}

export function aspectRatio(width: number, height: number) {
    const divisor = greatestCommonDivisor(width, height);

    if (Number.isNaN(width / divisor) || Number.isNaN(height / divisor)) {
        return '';
    }

    return `${width / divisor}:${height / divisor}`;
}

export function sortAspectRatios(a: string, b: string) {
    const [aW, aH] = a.split(':');
    const [bW, bH] = b.split(':');

    return Number(aW) / Number(aH) - Number(bW) / Number(bH);
}
