import {
    displays_TagActions,
    displays_TagAdapter,
    displays_TagThunks as defaultThunks,
    displays_TagSelectors as defaultSelectors,
    displays_TagReducer,
} from './Displays_Tag-slice';
import * as thunks from './Displays_Tag-thunks';
import * as selectors from './Displays_Tag-selectors';

const displays_TagThunks = { ...defaultThunks, ...thunks };
const displays_TagSelectors = { ...defaultSelectors, ...selectors };

export { default as Displays_TagSlice } from './Displays_Tag-slice';
export {
    displays_TagSelectors,
    displays_TagActions,
    displays_TagAdapter,
    displays_TagThunks,
    displays_TagReducer,
};
