import React from 'react';
import { Box, FormLabel, IconButton, Stack } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { Controller, useFieldArray } from 'react-hook-form';
import { AddCircle, Cancel } from '@mui/icons-material';
import AllEntitiesDropdown from 'c-components/AllEntitiesDropdown';
import TextField from 'c-components/Forms/TextField/TextField';

const fieldName = 'log_source_campaign_identifiers';

const PopSettingsLogSourceIdentifier: React.FC = () => {
    const { fields, append, remove } = useFieldArray({ name: fieldName });
    const t = useCommonTranslation();
    return (
        <Stack gap={2}>
            <FormLabel>
                {t(
                    'Modules.Main.Campaigns.CampaignSchedule.popSettings.logSourceCampaignIdentifiersTitle',
                )}
            </FormLabel>
            <Stack gap={2}>
                <Controller
                    name={fieldName}
                    render={() => (
                        <>
                            {fields.map((field, index) => (
                                <Stack direction="row" gap={2} key={field.id}>
                                    <Controller
                                        name={`${fieldName}.${index}.source`}
                                        render={({ field }) => (
                                            <AllEntitiesDropdown
                                                {...field}
                                                entityName="PopDisplays_LogSourceAccount"
                                                labelField="name"
                                                label={t(
                                                    'Modules.Main.Campaigns.CampaignSchedule.popSettings.logSourceCampaignIdentifierSourceLabel',
                                                )}
                                                size="small"
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`${fieldName}.${index}.identifier`}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label={t(
                                                    'Modules.Main.Campaigns.CampaignSchedule.popSettings.logSourceCampaignIdentifierLabel',
                                                )}
                                                size="small"
                                            />
                                        )}
                                    />
                                    <IconButton onClick={() => remove(index)}>
                                        <Cancel fontSize="inherit" />
                                    </IconButton>
                                </Stack>
                            ))}
                        </>
                    )}
                />
                <Box>
                    <IconButton onClick={() => append({ source: null, identifier: '' })}>
                        <AddCircle fontSize="inherit" />
                    </IconButton>
                </Box>
            </Stack>
        </Stack>
    );
};

export default PopSettingsLogSourceIdentifier;
