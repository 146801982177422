import React, { PropsWithChildren, useMemo } from 'react';
import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { CenterSmUp, MuiRouterLink, SystemAlerts } from 'c-components';
import { BrandLogo } from 'c-components';
import { PreAuthRoutes } from 'c-routes';
import { useSystemAlerts } from 'c-main/Hooks';
import ContentFooter from '../ContentFooter';
import ContentLayout from '../ContentLayout';
import PageLayout, { PageLayoutProps } from '../PageLayout';

type Props = {
    flashMessage?: React.ReactNode;
    title?: string;
    subtitle?: string;
    action?: React.ReactNode;
    maxWidth?: BoxProps['maxWidth'];
} & PageLayoutProps;

const PreAuthContentLayout: React.FC<PropsWithChildren> = ({ children }) => (
    <ContentLayout>
        <Box
            flex={1}
            display="flex"
            alignItems="center"
            maxWidth={['100vw', '92.4rem']}
            flexDirection="column"
            width="100vw"
        >
            {children}
        </Box>
        <ContentFooter />
    </ContentLayout>
);

const defaultMaxWidth = ['100vw', '30rem'];

const PreAuthLayout = ({
    title,
    subtitle,
    children,
    flashMessage,
    maxWidth = defaultMaxWidth,
    ...layoutProps
}: React.PropsWithChildren<Props>) => {
    const { alerts } = useSystemAlerts();

    const theFlashMessage = useMemo(() => {
        const alertsEl = alerts.length > 0 ? <SystemAlerts alerts={alerts} /> : null;

        if (flashMessage) {
            return (
                <Stack gap={2}>
                    {alertsEl}
                    <>{flashMessage}</>
                </Stack>
            );
        }

        return alertsEl;
    }, [flashMessage, alerts]);

    const hasTitleContent = useMemo(
        () => title != null || subtitle != null || theFlashMessage != null,
        [title, subtitle, theFlashMessage],
    );
    return (
        <PageLayout contentComponent={PreAuthContentLayout} {...layoutProps}>
            <Box
                flex={1}
                display="flex"
                maxWidth={maxWidth}
                flexDirection="column"
                width="100vw"
                mb="4.5rem"
                id="your-mum"
            >
                <Box
                    py={{ xs: 8, md: 4 }}
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                >
                    <MuiRouterLink to={PreAuthRoutes.Login}>
                        <BrandLogo />
                    </MuiRouterLink>
                </Box>
                {hasTitleContent && (
                    <Box mb="3rem" textAlign="center" display="flex" flexDirection="column">
                        {title && (
                            <Box pb={2}>
                                <Typography component="h1" variant="h1">
                                    {title}
                                </Typography>
                            </Box>
                        )}
                        {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
                        {theFlashMessage && (
                            <CenterSmUp>
                                <Box mt={3} mb={2} px={4} id="flashes">
                                    {theFlashMessage}
                                </Box>
                            </CenterSmUp>
                        )}
                    </Box>
                )}
                <Box px={4}>{children}</Box>
            </Box>
        </PageLayout>
    );
};

export default PreAuthLayout;
