import React, { useCallback, useMemo, useState } from 'react';
import { PermissionByCategory, PermissionNamespace, PermissionsByEntity } from 'c-admin';
import { CardContent, CardHeaderProps, Collapse, Grid } from '@mui/material';
import { AllEntities } from '@uniled/api-sdk';
import { TransparentCard } from 'c-components';
import { KeyboardArrowDown } from '@mui/icons-material';
import { cssRotation } from 'c-lib';
import { Role } from 'c-entity-types';
import EntityPermissionTile from './EntityPermissionTile';
import SelectAllPermissions from './SelectAllPermissions';
import EntityPermissionChangeCounts from './EntityPermissionChangeCounts';

type Props = {
    role: Role;
    grouped: PermissionByCategory;
};
const EntityPermissionGroups: React.FC<Props> = ({ role, grouped }) => {
    const [open, setOpen] = useState<Record<string, boolean>>({});
    const onHeaderClick = useCallback(
        (namespace: string) => {
            setOpen({ ...open, [namespace]: !open[namespace] });
        },
        [open, setOpen],
    );

    const cardHeaderProps = useMemo<CardHeaderProps>(() => ({ sx: { cursor: 'pointer' } }), []);
    const allPermissionIdsByNamespace = useMemo<Record<PermissionNamespace, number[]>>(
        () =>
            Object.keys(grouped.rawPermissionsByNamespace).reduce((acc, curr) => {
                acc[curr] = grouped.rawPermissionsByNamespace[curr].map(p => p.id);
                return acc;
            }, {} as Record<PermissionNamespace, number[]>),
        [grouped],
    );

    return (
        <>
            {Object.keys(grouped.namespaces).map(namespace => (
                <TransparentCard
                    key={namespace}
                    title={namespace}
                    titleTypographyProps={{ variant: 'h4' }}
                    cardHeaderProps={{
                        ...cardHeaderProps,
                        onClick: () => onHeaderClick(namespace),
                    }}
                    controls={[
                        <EntityPermissionChangeCounts
                            ids={allPermissionIdsByNamespace[namespace]}
                            role={role}
                        />,
                        <SelectAllPermissions ids={allPermissionIdsByNamespace[namespace]} />,
                        <KeyboardArrowDown
                            css={cssRotation({ rotate: open[namespace] === true })}
                        />,
                    ]}
                >
                    <Collapse
                        in={open[namespace] === true}
                        unmountOnExit={false}
                        mountOnEnter={false}
                    >
                        <CardContent>
                            <PermissionNamespaceMemo entities={grouped.namespaces[namespace]} />
                        </CardContent>
                    </Collapse>
                </TransparentCard>
            ))}
        </>
    );
};

const PermissionNamespaceWrapper: React.FC<{ entities: PermissionsByEntity }> = ({ entities }) => (
    <>
        <Grid container spacing={4}>
            {Object.keys(entities).map(entityName => (
                <Grid item xs={12} sm={6} md={4} xl={3} key={entityName}>
                    <EntityPermissionTile
                        key={entityName}
                        entityName={entityName as keyof AllEntities}
                        permissions={entities[entityName]}
                    />
                </Grid>
            ))}
        </Grid>
    </>
);
const PermissionNamespaceMemo = React.memo(PermissionNamespaceWrapper);

export default React.memo(EntityPermissionGroups);
