import React, { useMemo } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useEntityData, User } from 'c-data';
import CopyButton from 'c-components/CopyButton';
import { useDateUtils } from 'c-hooks';
import { userName } from 'c-admin/Lib';
import { useCommonTranslation } from 'c-translation';
import UserAvatar from '../UserAvatar/UserAvatar';

// const clientReadPerms = [PermissionName.UniledportalClientRead];

const UserProfileCard = ({ userId }: { userId: number }) => {
    const { getById } = useEntityData<User>('User');
    const user = getById({ id: userId });
    const userNameString = useMemo(() => userName(user), [user]);
    const { formatDateString } = useDateUtils();
    const date = useMemo(
        () => (user?.created_at ? formatDateString({ date: user?.created_at }) : ''),
        [user?.created_at, formatDateString],
    );
    // const clients = useMemo(
    //     () =>
    //         user?.clients?.length > 0 ? (
    //             <JoinComponents
    //                 components={user.clients.map(clientId => (
    //                     <EntityField<Client>
    //                         key={clientId}
    //                         id={clientId}
    //                         entityName="Client"
    //                         field="name"
    //                     />
    //                 ))}
    //                 separator=", "
    //             />
    //         ) : null,
    //     [user?.clients],
    // );

    const t = useCommonTranslation();

    return (
        <Box id={`user-profile-card-${userId}`} display="flex" alignItems="center">
            {!user && <CircularProgress />}
            {user && (
                <>
                    <Box title={userNameString}>
                        <UserAvatar user={user} size={6} />
                    </Box>
                    <Box padding="0.75em" maxWidth="15em">
                        <Box title={userNameString}>
                            <Typography noWrap>{userNameString}</Typography>
                        </Box>
                        <Stack gap={1}>
                            <Box title={user.email}>
                                <Typography noWrap variant="caption" color="grey.600">
                                    {user.email} <CopyButton copyContent={user.email} />
                                </Typography>
                            </Box>
                            {/* <Box className={classNames([subTitle, lineHeight])}>{role?.name}</Box> */}
                            {/* <IfHasAllPermissions permissions={clientReadPerms}> */}
                            {/*    {user?.clients?.length > 0 && ( */}
                            {/*        <Box> */}
                            {/*            <Typography variant="caption" color="grey.600"> */}
                            {/*                {t('Modules.Auth.User.UserProfileCard.from')} {clients} */}
                            {/*            </Typography> */}
                            {/*        </Box> */}
                            {/*    )} */}
                            {/* </IfHasAllPermissions> */}
                            <Box title={date}>
                                <Typography noWrap variant="caption" color="grey.600">
                                    {t('Modules.Auth.User.UserProfileCard.joined')} {date}
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default UserProfileCard;
