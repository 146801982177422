import React from 'react';
import { FilterableEntityTableProps } from 'c-pagination';
import { Box } from '@mui/material';
import { FlashMessage, LoadingSpinner } from 'c-components';

const ContainedRenderer: FilterableEntityTableProps['CustomRenderer'] = ({
    wrapperComponentRef,
    table,
    filterForm,
    loading,
    pagination,
    error,
}) => (
    <Box
        ref={wrapperComponentRef}
        flex={1}
        overflow="hidden"
        display="flex"
        flexDirection="column"
        position="relative"
    >
        {filterForm != null && <Box id="filters">{filterForm}</Box>}

        {loading && <LoadingSpinner />}
        {error && (
            <Box my={1}>
                <FlashMessage status="error">{error}</FlashMessage>
            </Box>
        )}
        <Box
            flex={1}
            id="table"
            overflow="hidden"
            height="100%"
            display="flex"
            // alignItems="stretch"
        >
            {table}
        </Box>
        <Box id="pagination">{pagination}</Box>
    </Box>
);

export default ContainedRenderer;
