import React, { useCallback, useEffect, useMemo } from 'react';
import to from 'await-to-js';
import { MetaLayout, PreAuthLayout } from 'c-wrapper';
import { useCommonTranslation } from 'c-translation';
import { FlashMessage, LinkButton } from 'c-components';
import { Box, CircularProgress, Paper, Stack } from '@mui/material';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { generatePath } from 'react-router-dom';
import { PreAuthRoutes } from 'c-routes';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CampaignInviteImg from 'c-assets/campaign-invite.png';

const VerifyEmailPage = () => {
    const t = useCommonTranslation();

    const token = useMemo(() => new URLSearchParams(window.location.search).get('token'), []);

    const { start, isLoading, hasFailed, error, hasSucceeded } = useAPIClientRequest(
        apiClient.Auth.verifyEmail,
    );

    const verify = useCallback(async () => {
        await to(start(token));
    }, [start, token]);

    useEffect(() => {
        /**
         * for some reason this page is double monuting because of react router
         * so just cancelling the timeout when the first instance inevitably unmounts.
         */
        const timeout = setTimeout(() => {
            verify();
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MetaLayout metaTitle="Pages.VerifyEmail.metaTitle">
            <PreAuthLayout>
                <Box maxWidth="sm" width="100%" mx="auto" my="auto">
                    <Paper elevation={2}>
                        <Box px={10} py={4}>
                            <Stack spacing={4}>
                                <Box textAlign="center">
                                    <img
                                        src={CampaignInviteImg}
                                        alt="campaign invite icon"
                                        height={170}
                                    />
                                </Box>

                                {isLoading && (
                                    <Box textAlign="center">
                                        <CircularProgress />
                                    </Box>
                                )}

                                {hasSucceeded && (
                                    <FlashMessage status="success">
                                        {t('Pages.VerifyEmail.verifySuccess')}
                                    </FlashMessage>
                                )}
                                {hasFailed && (
                                    <FlashMessage status="error" mb={2}>
                                        {String(error)}
                                    </FlashMessage>
                                )}

                                <Box textAlign="center" mt={2}>
                                    <LinkButton
                                        to={generatePath(PreAuthRoutes.Login)}
                                        id="invite-login"
                                        variant="outlined"
                                    >
                                        {t('Pages.VerifyEmail.loginLabel')}
                                    </LinkButton>
                                </Box>
                            </Stack>
                        </Box>
                    </Paper>
                </Box>
            </PreAuthLayout>
        </MetaLayout>
    );
};

export default VerifyEmailPage;
