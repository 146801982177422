export type NewCampaignFormSchema = {
    name?: string;
    reference?: string;
    start_datetime?: string;
    end_datetime?: string;

    buyer_id?: number;
    agency_id?: number;
    client_id?: number;

    agency?: string;
    client?: string;

    line_items?: string[];

    // <LineItemId, NumberOfDays>
    days: Record<string, number>;

    // <LineItemId, DateString>
    start_dates: Record<string, string>;
    end_dates: Record<string, string>;
};

export type UpdateCampaignLineItemsFormSchema = NewCampaignFormSchema & {
    originalLineItems?: string[];
    originalDays: NewCampaignFormSchema['days'];
};

export const CampaignFormNames = {
    name: 'name',
    reference: 'reference',
    start: 'start_datetime',
    end: 'end_datetime',
    is_dsp: 'is_dsp',
    is_vistar: 'is_vistar',

    buyer: 'buyer_id',
    agency_id: 'agency_id',
    client_id: 'client_id',
    additional_contacts: 'additional_contacts',

    agency_name: 'agency',
    client_name: 'client',

    lineItems: 'line_items',

    lineItemDays: 'days',
    lineItemStartDates: 'start_dates',
    lineItemEndDates: 'end_dates',

    // for campaign updating
    originalLineItems: 'originalLineItems',
    originalDays: 'originalDays',
};
