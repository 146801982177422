import React, { useCallback, useMemo } from 'react';
import {
    CampaignCreativesResponse,
    CampaignErrorableType,
    PermissionName,
    UserType,
    CreativeGroup,
    Creative,
} from '@uniled/api-sdk';
import { Box, List, Stack, Typography } from '@mui/material';
import { Button, DialogV2, FlashMessage, LoadingSpinner } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import {
    AddCreativeGroup,
    CreativeDraggableItem,
    DeleteCreativesAndGroups,
    OpenCreativeGroup,
    ReValidateCampaign,
    SetSelectedCreatives,
    UpdateCreatives,
    UpdateGroup,
} from 'c-main/Components/CreativeManagement/types';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { NetworkRequestState } from '@uniled/data-layer';
import { Error, Folder, FolderCopy, InsertDriveFile } from '@mui/icons-material';
import { useCampaignErrors } from 'c-main/Hooks';
import { uniq } from 'ramda';
import { IfHasAllPermissions, IfUserIsOneOfTypes } from 'c-auth-module/Components';
import NewGroup from 'c-main/Components/CreativeManagement/Components/Groups/NewGroup';
import { isStaticGroup, StaticCreativeGroup } from 'c-main/Components/CreativeManagement/lib';
import { useBoolean } from 'react-hanger';
import GroupListItem from './GroupListItem';

type Props = {
    campaignId: number;
    data: CampaignCreativesResponse;
    onClickGroup: OpenCreativeGroup;
    openGroup?: number;
    addGroup: AddCreativeGroup;
    updateGroup: UpdateGroup;

    setSelectedCreatives: SetSelectedCreatives;
    updateCreatives: UpdateCreatives;
    reValidateCampaign: ReValidateCampaign;
    deleteData: DeleteCreativesAndGroups;

    autoGroupsBtn: React.ReactNode;
};
const CreatePerms: PermissionName[] = [PermissionName.UniledportalCreative_groupCreate];
const hundredHeightSx = { height: '100%' };

const specialGroupsUserType = [UserType.Admin, UserType.Agency, UserType.Buyer, UserType.Client];

const GroupWrapper: React.FC<Props> = ({
    campaignId,
    data,
    onClickGroup,
    openGroup,

    addGroup,

    updateGroup,
    setSelectedCreatives,
    updateCreatives,

    reValidateCampaign,
    deleteData,

    autoGroupsBtn,
}) => {
    const countByGroup = useMemo(
        () =>
            data.creativeGroups.reduce(
                (totals, group) => ({
                    ...totals,
                    [group.id]: data.creatives.filter(
                        c => c.creativeGroupIds.indexOf(group.id) !== -1,
                    ).length,
                }),
                {},
            ),
        [data],
    );

    const { start, requestState, error, reset } = useAPIClientRequest(
        apiClient.Entities.Creative.update,
    );
    const moveCreatives = useCallback(
        async (creatives: Partial<Creative>[]) => {
            const [, success] = await to(
                start(creatives as Creative[], ['file', 'creativeGroupIds', 'lineItemsCount']),
            );

            if (Array.isArray(success?.data?.data)) {
                updateCreatives(success?.data?.data);
                setSelectedCreatives([]);
            }
        },
        [start, updateCreatives, setSelectedCreatives],
    );
    const onCreativeDropped = useCallback(
        (dropData: CreativeDraggableItem, groupId?: number) => {
            moveCreatives(
                dropData.creativeIds.map(id => {
                    if (isStaticGroup(groupId)) {
                        // dropped onto the `All Creatives` group, so remove from all groups
                        return {
                            id,
                            creativeGroupIds: [],
                        };
                    }

                    const creative = data.creatives.find(({ id: CreativeID }) => id === CreativeID);
                    const currentGroupIds = creative?.creativeGroupIds ?? [];
                    const alreadyInGroup = currentGroupIds.indexOf(groupId) !== -1;

                    if (alreadyInGroup && openGroup === groupId) {
                        // only remove a creative from its group if the group is currently open
                        return {
                            id,
                            // if already in the group filter out the group id
                            creativeGroupIds: uniq(
                                [...currentGroupIds].filter(gid => gid !== groupId),
                            ),
                        };
                    }

                    return {
                        id,
                        // not already in the group so add it to the group list for the creative
                        creativeGroupIds: uniq([...currentGroupIds, groupId]),
                    };
                }),
            );
        },
        [moveCreatives, data?.creatives, openGroup],
    );

    const { getErrors } = useCampaignErrors(campaignId);

    const creativeErrorsByGroup = useMemo(
        () =>
            data.creativeGroups.reduce(
                (totals, group) => ({
                    ...totals,
                    [group.id]: getErrors({
                        entityIds: data.creatives
                            .filter(c => c.creativeGroupIds.indexOf(group.id) !== -1)
                            .map(c => c.id),
                        entityTypes: [CampaignErrorableType.Creative],
                    }),
                }),
                {},
            ),
        [data, getErrors],
    );

    const memoGroups = useMemo(
        () =>
            data.creativeGroups.map(g => (
                <React.Fragment key={g.id}>
                    <GroupListItem
                        htmlClassName={`creative-group-custom creative-group-${g.id}`}
                        group={g}
                        reValidateCampaign={reValidateCampaign}
                        creativesCount={countByGroup[g.id] ?? 0}
                        onClick={onClickGroup}
                        isActive={openGroup === g.id}
                        updateGroup={updateGroup}
                        onCreativesDropped={onCreativeDropped}
                        deleteData={deleteData}
                        icon={<Folder fontSize="inherit" />}
                        warningCount={creativeErrorsByGroup?.[g.id]?.length ?? 0}
                    />
                </React.Fragment>
            )),
        [
            countByGroup,
            creativeErrorsByGroup,
            data.creativeGroups,
            onClickGroup,
            openGroup,
            updateGroup,
            onCreativeDropped,
            reValidateCampaign,
            deleteData,
        ],
    );
    const selectAllArtwork = useCallback(() => {
        onClickGroup(StaticCreativeGroup.AllCreatives);
    }, [onClickGroup]);
    const selectInvalidGroup = useCallback(() => {
        onClickGroup(StaticCreativeGroup.Invalid);
    }, [onClickGroup]);
    const selectUngroupedGroup = useCallback(() => {
        onClickGroup(StaticCreativeGroup.Ungrouped);
    }, [onClickGroup]);
    const selectUnassignedGroup = useCallback(() => {
        onClickGroup(StaticCreativeGroup.UnAssigned);
    }, [onClickGroup]);

    const allCreativeErrors = useMemo(
        () =>
            getErrors({
                entityIds: data?.creatives?.map(c => c.id) ?? [],
                entityTypes: [CampaignErrorableType.Creative],
            }),
        [getErrors, data],
    );

    const t = useCommonTranslation();

    // filling it in with all the info used in GroupListItem
    const allCreativesGroup = useMemo<CreativeGroup>(
        () =>
            ({
                id: StaticCreativeGroup.AllCreatives,
                name: t('Modules.Main.CreativeManagement.groups.allArtwork'),
            } as CreativeGroup),
        [t],
    );

    const invalidGroup = useMemo<CreativeGroup>(
        () =>
            ({
                id: StaticCreativeGroup.Invalid,
                name: t('Modules.Main.CreativeManagement.groups.invalidGroup'),
            } as CreativeGroup),
        [t],
    );

    const ungroupedGroup = useMemo<CreativeGroup>(
        () =>
            ({
                id: StaticCreativeGroup.Ungrouped,
                name: t('Modules.Main.CreativeManagement.groups.ungroupedGroup'),
            } as CreativeGroup),
        [t],
    );

    const unassignedGroup = useMemo<CreativeGroup>(
        () =>
            ({
                id: StaticCreativeGroup.UnAssigned,
                name: t('Modules.Main.CreativeManagement.groups.unassignedGroup'),
            } as CreativeGroup),
        [t],
    );

    const ungroupedCount = useMemo(
        () =>
            (data?.creatives ?? []).reduce(
                (total, creative) => (creative.creativeGroupIds?.length === 0 ? total + 1 : total),
                0,
            ),
        [data],
    );

    const unassignedCount = useMemo(
        () =>
            (data?.creatives ?? []).reduce(
                (total, creative) =>
                    creative.lineItemsCount == null || creative.lineItemsCount <= 0
                        ? total + 1
                        : total,
                0,
            ),
        [data],
    );

    const invalidCount = useMemo(
        () => uniq(allCreativeErrors.map(err => err.errorable_id)).length,
        [allCreativeErrors],
    );

    const {
        setTrue: OpenNewGroup,
        value: NewGroupOpen,
        setFalse: CloseNewGroup,
    } = useBoolean(false);

    return (
        <>
            <DialogV2
                title="Modules.Main.CreativeManagement.dragAndDrop.dragMoveFailedTitle"
                onClose={reset}
                open={requestState === NetworkRequestState.Error}
            >
                <Box textAlign="center">
                    <FlashMessage status="error">{String(error ?? '')}</FlashMessage>
                </Box>
            </DialogV2>
            <Box display="flex" flex={1} width="100%" flexDirection="column">
                <IfHasAllPermissions permissions={CreatePerms}>
                    <Button
                        id="new-creative-group"
                        variant="outlined"
                        fullWidth
                        sx={{ borderRadius: 1.5, height: 40 }}
                        onClick={OpenNewGroup}
                    >
                        {useCommonTranslation('Modules.Main.CreativeManagement.groups.addGroup')}
                    </Button>
                </IfHasAllPermissions>
                <Box mt={1.5} mb={1} display="flex" justifyContent="space-between">
                    <Typography variant="body2" color="text.disabled">
                        {useCommonTranslation('Modules.Main.CreativeManagement.groups.header')}
                    </Typography>
                    {autoGroupsBtn}
                </Box>
                <Box flex={1} overflow="auto">
                    <List disablePadding sx={hundredHeightSx}>
                        {requestState === NetworkRequestState.InProgress && <LoadingSpinner />}

                        <Stack gap={1} sx={hundredHeightSx}>
                            <GroupListItem
                                group={allCreativesGroup}
                                canDropName={t(
                                    'Modules.Main.CreativeManagement.groups.allArtworkRemove',
                                )}
                                isDropRemovingGroups
                                creativesCount={data.creatives.length}
                                onClick={selectAllArtwork}
                                isActive={openGroup == StaticCreativeGroup.AllCreatives}
                                updateGroup={updateGroup}
                                onCreativesDropped={onCreativeDropped}
                                reValidateCampaign={reValidateCampaign}
                                deleteData={deleteData}
                                icon={<FolderCopy fontSize="inherit" />}
                                warningCount={allCreativeErrors.length}
                                editable={false}
                                warningIcon={<Error color="warning" />}
                                htmlClassName="creative-group-all"
                            />
                            {NewGroupOpen && (
                                <NewGroup addGroup={addGroup} closeNewGroup={CloseNewGroup} />
                            )}
                            {memoGroups}
                            <IfUserIsOneOfTypes roles={specialGroupsUserType}>
                                <Box mt={5}>
                                    <GroupListItem
                                        group={invalidGroup}
                                        creativesCount={null}
                                        onClick={selectInvalidGroup}
                                        isActive={openGroup == StaticCreativeGroup.Invalid}
                                        updateGroup={updateGroup}
                                        reValidateCampaign={reValidateCampaign}
                                        deleteData={deleteData}
                                        icon={<InsertDriveFile fontSize="inherit" />}
                                        warningCount={invalidCount}
                                        showWarningsIfZero
                                        droppable={false}
                                        editable={false}
                                        htmlClassName="creative-group-invalid"
                                    />
                                    <GroupListItem
                                        group={ungroupedGroup}
                                        creativesCount={null}
                                        onClick={selectUngroupedGroup}
                                        isActive={openGroup == StaticCreativeGroup.Ungrouped}
                                        updateGroup={updateGroup}
                                        reValidateCampaign={reValidateCampaign}
                                        deleteData={deleteData}
                                        icon={<InsertDriveFile fontSize="inherit" />}
                                        warningCount={ungroupedCount}
                                        showWarningsIfZero
                                        droppable={false}
                                        editable={false}
                                        htmlClassName="creative-group-ungrouped"
                                    />
                                    <GroupListItem
                                        group={unassignedGroup}
                                        creativesCount={null}
                                        onClick={selectUnassignedGroup}
                                        isActive={openGroup == StaticCreativeGroup.UnAssigned}
                                        updateGroup={updateGroup}
                                        reValidateCampaign={reValidateCampaign}
                                        deleteData={deleteData}
                                        icon={<InsertDriveFile fontSize="inherit" />}
                                        warningCount={unassignedCount}
                                        showWarningsIfZero
                                        droppable={false}
                                        editable={false}
                                        htmlClassName="creative-group-unassigned"
                                    />
                                </Box>
                            </IfUserIsOneOfTypes>
                        </Stack>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default GroupWrapper;
