import { useTranslation } from 'react-i18next';
import { AppTranslationOverloads } from 'c-translation/Types';
import { useMemo } from 'react';

const useCommonTranslation: AppTranslationOverloads = (...params: any[]) => {
    const { t } = useTranslation();
    const translateFunc = useMemo(
        () => (path: string, data?: Record<string, unknown>) => t(path, data),
        [t],
    );
    return (params.length > 0 ? t(params[0], params[1]) : translateFunc) as any;
};

export default useCommonTranslation;
