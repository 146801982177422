import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { roleSelectors } from 'c-admin/Slices';
import { Role } from 'c-entity-types';
import { Permission } from '@uniled/api-sdk';
import { Dialog } from 'c-components';
import { checkedPermissionIds, permissionDiffs } from 'c-admin/Lib';
import { useAllEntityData, useUpdateEntityData } from 'c-data';
import { NetworkRequestState } from '@uniled/data-layer';
import { Box, DialogContentText, Grid, Typography } from '@mui/material';
import { Translate } from 'c-translation';

type Props = {
    role: Role;
    onClose: () => void;
    name: string;
    campaignAgencyInvite: boolean;
};
const PermissionDiffDialog: React.FC<Props> = ({ role, onClose, name, campaignAgencyInvite }) => {
    const { update, getUpdatingById } = useUpdateEntityData<Role>('Role');
    const { allEntities } = useAllEntityData<Permission>('Permission');
    const permissionValues = useSelector(roleSelectors.getPermissionsDictionary);
    const state = getUpdatingById(role.id).state;

    const onSubmit = useCallback(() => {
        update(role.id, {
            id: role.id,
            name,
            invite_type: { ...role.invite_type, campaign_agency: campaignAgencyInvite },
            permissions: checkedPermissionIds(permissionValues).map(pid => +pid),
        } as Role);
        onClose();
    }, [name, campaignAgencyInvite, permissionValues, update, role.id, onClose]);

    const diff = useMemo(
        () =>
            permissionDiffs(
                permissionValues,
                allEntities.map(p => p.id),
                role.permissions,
            ),
        [permissionValues, role.permissions, allEntities],
    );

    return (
        <Dialog
            MuiDialogProps={{ maxWidth: 'lg', fullWidth: false }}
            title="Modules.Admin.RoleEdit.saveDialogTitle"
            onClose={onClose}
            removeForm
            show
            content={
                <>
                    {name !== role.name && (
                        <DialogContentText>
                            <Translate
                                path="Modules.Admin.RoleEdit.nameChangedWarning"
                                data={{ from: role.name, to: name }}
                            />
                        </DialogContentText>
                    )}
                    {campaignAgencyInvite !== role.invite_type.campaign_agency && (
                        <DialogContentText>
                            <Translate
                                path="Modules.Admin.RoleEdit.campaignAgencyInviteChangedWarning"
                                data={{
                                    from: role.invite_type.campaign_agency,
                                    to: campaignAgencyInvite,
                                }}
                            />
                        </DialogContentText>
                    )}
                    {(diff.added.length > 0 || diff.removed.length > 0) && (
                        <Typography variant="subtitle1">
                            <Translate path="Modules.Admin.RoleEdit.permissionChangedTitle" />
                        </Typography>
                    )}
                    <DialogContentText component={Box}>
                        <Grid container spacing={2}>
                            {diff.added.length > 0 && (
                                <Grid item xs={12} md>
                                    <Typography variant="subtitle1" color="success.main">
                                        <Translate
                                            path="Modules.Admin.RoleEdit.permissionsAdded"
                                            data={{ count: diff.added.length }}
                                        />
                                    </Typography>
                                    <Grid container>
                                        {diff.added.map(perm => (
                                            <Grid item xs={12} key={perm}>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="success.main"
                                                >
                                                    {allEntities.find(p => p.id === perm)?.name ??
                                                        perm}
                                                </Typography>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            )}
                            {diff.removed.length > 0 && (
                                <Grid item xs={12} md>
                                    <Typography variant="subtitle1" color="error.main">
                                        <Translate
                                            path="Modules.Admin.RoleEdit.permissionsRemoved"
                                            data={{ count: diff.removed.length }}
                                        />
                                    </Typography>
                                    <Grid container>
                                        {diff.removed.map(perm => (
                                            <Grid item xs={12} key={perm}>
                                                <Typography variant="subtitle2" color="error.main">
                                                    {allEntities.find(p => p.id === perm)?.name ??
                                                        perm}
                                                </Typography>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContentText>
                </>
            }
            onSave={onSubmit}
            saveLabel="Modules.Admin.RoleEdit.saveLabel"
            saveDisabled={state === NetworkRequestState.InProgress}
            cancelDisabled={state === NetworkRequestState.InProgress}
        />
    );
};

export default React.memo(PermissionDiffDialog);
