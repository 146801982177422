import React, { forwardRef, Ref, useEffect, useMemo } from 'react';
import { IMask, useIMask } from 'react-imask';
import { repeat } from 'ramda';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material';
import TextField, { CustomTextFieldProps } from './TextField';

export type MaskedTextFieldProps = {
    prefix: string;
    type: 'numeric' | 'alpha' | 'alphanumeric' | 'any';
    maxLength: number;
    onChange?: (val: string) => void;
    value?: string;
} & Omit<CustomTextFieldProps, 'type' | 'onChange' | 'value'>;

const AlphaNumericMaskChar = '$';
const AlphaNumericMaskCharRegex = /[a-z0-9A-Z]/;
const PlaceholderChar = '_';

const numericCharDef = '0';
const alphaCharDef = 'a';

const escapePrefixChars = (char: string) => {
    if (char === numericCharDef) return `\\${numericCharDef}`;
    if (char === alphaCharDef) return `\\${alphaCharDef}`;
    return char;
};

const generateNumeric = (length: number) =>
    length > 0 ? repeat(numericCharDef, length).join('') : '';
const generateAlpha = (length: number) => (length > 0 ? repeat(alphaCharDef, length).join('') : '');
const generateAny = (length: number) => (length > 0 ? repeat('*', length).join('') : '');
const generateAlphaNumeric = (length: number) =>
    length > 0 ? repeat(AlphaNumericMaskChar, length).join('') : '';

const maskFuncs = {
    numeric: generateNumeric,
    alpha: generateAlpha,
    alphanumeric: generateAlphaNumeric,
    any: generateAny,
};

const MaskedTextField = (
    { prefix, type, maxLength, onChange, value: DefaultValue, ...props }: MaskedTextFieldProps,
    ref: Ref<any>,
) => {
    const escapedPrefix = useMemo(
        () =>
            prefix
                .split('')
                .map(char => escapePrefixChars(char))
                .join(''),
        [prefix],
    );

    const opts = useMemo<IMask.AnyMaskedOptions>(
        () => ({
            mask: `{${escapedPrefix}}${maskFuncs[type ?? 'any'](
                maxLength - (prefix?.length ?? 0),
            )}`,
            validate: value1 => value1?.length <= maxLength,
            placeholderChar: PlaceholderChar,
            lazy: false,
            prepare: val => val.replaceAll(PlaceholderChar, ''),
            definitions: {
                [AlphaNumericMaskChar]: AlphaNumericMaskCharRegex,
            },
            defaultValue: DefaultValue,
        }),
        [maxLength, prefix, type, DefaultValue, escapedPrefix],
    );

    const { ref: IMaskRef, value, unmaskedValue } = useIMask(opts);
    useEffect(() => {
        onChange?.(unmaskedValue);
    }, [unmaskedValue, onChange]);

    return (
        <TextField {...(props as MuiTextFieldProps)} value={value} ref={ref} inputRef={IMaskRef} />
    );
};

export default forwardRef<any, MaskedTextFieldProps>(MaskedTextField);
