import React, { useRef } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import { useBoolean } from 'react-hanger';
import { AutoGrid, Button } from 'c-components';
import { BarChart, KeyboardArrowDown, TableChart, Timeline } from '@mui/icons-material';
import { Translate } from 'c-translation';
import { TypeSwitcherProps } from './types';
import { FieldReportType, ReportView } from '../types';

const labels = {
    [FieldReportType.OverallLeaderboard]: <TableChart />,
    [FieldReportType.OverTime]: <Timeline />,
    [FieldReportType.Overall]: <BarChart />,
};

const TypeSwitcher: React.FC<TypeSwitcherProps> = ({
    type,
    onTypeChange,
    field,
    view,
    disabled,
}) => {
    const btnRef = useRef();
    const openState = useBoolean(false);

    return (
        <Box>
            <Button
                onClick={openState.toggle}
                ref={btnRef}
                disabled={disabled}
                color={view !== ReportView.Map ? 'primary' : 'secondary'}
                className="view-by-dropdown"
            >
                {labels[type]}
                <Box ml={1}>
                    <Translate path="Modules.Main.Campaigns.Overview.Reports.viewBy" />
                </Box>
                <KeyboardArrowDown />
            </Button>
            <Menu anchorEl={btnRef.current} open={openState.value} onClose={openState.setFalse}>
                {(field != null || view === ReportView.Map) && (
                    <MenuItem
                        onClick={() => onTypeChange(FieldReportType.OverallLeaderboard)}
                        selected={type === FieldReportType.OverallLeaderboard}
                        className="view-by-leaderboard"
                    >
                        <AutoGrid spacing={0.5}>
                            {labels[FieldReportType.OverallLeaderboard]}

                            <Translate path="Modules.Main.Campaigns.Overview.Reports.overallLeaderboard.title" />
                        </AutoGrid>
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => onTypeChange(FieldReportType.OverTime)}
                    selected={type === FieldReportType.OverTime}
                    className="view-by-overtime"
                >
                    <AutoGrid spacing={1}>
                        {labels[FieldReportType.OverTime]}
                        <Translate path="Modules.Main.Campaigns.Overview.Reports.typeOverTime" />
                    </AutoGrid>
                </MenuItem>
                <MenuItem
                    onClick={() => onTypeChange(FieldReportType.Overall)}
                    selected={type === FieldReportType.Overall}
                    className="view-by-overall"
                >
                    <AutoGrid spacing={1}>
                        {labels[FieldReportType.Overall]}
                        <Translate path="Modules.Main.Campaigns.Overview.Reports.typeOverall" />
                    </AutoGrid>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default TypeSwitcher;
