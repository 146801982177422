import React from 'react';
import { AutoGrid, Button, ControlledFormInput, FlashMessage, TextField } from 'c-components';
import UserAssociationAssignment from 'c-admin/Components/User/UserAssociationAssignment';
import { useCommonTranslation } from 'c-translation';
import { PermissionName } from '@uniled/api-sdk';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { NetworkRequestState } from '@uniled/data-layer';
import { Box } from '@mui/material';
import { useCreateEntity } from 'c-data';
import { useFormContext } from 'react-hook-form';
import ValidationErrors from 'c-components/ValidationErrors';

type Props = {
    //
};

const NewUserForm: React.FC<Props> = () => {
    const t = useCommonTranslation();
    const { saveState, saveError, saveValidationErrors } = useCreateEntity('User');
    const { formState } = useFormContext();
    return (
        <AutoGrid spacing={2} xs={12}>
            <AutoGrid spacing={2} xs={12} sm={6} md={4} lg={3}>
                <ControlledFormInput
                    name="name_first"
                    render={({ field, fieldState, formState }) => (
                        <TextField
                            {...field}
                            label={t('Modules.Admin.UserForm.firstName')}
                            required
                        />
                    )}
                />
                <ControlledFormInput
                    name="name_last"
                    render={({ field, fieldState, formState }) => (
                        <TextField
                            {...field}
                            label={t('Modules.Admin.UserForm.lastName')}
                            required
                        />
                    )}
                />
                <ControlledFormInput
                    name="email"
                    render={({ field, fieldState, formState }) => (
                        <TextField
                            {...field}
                            type="email"
                            label={t('Modules.Admin.UserForm.email')}
                            required
                        />
                    )}
                />
            </AutoGrid>

            <IfHasAllPermissions
                permissions={[
                    PermissionName.UniledportalBuyerRead,
                    PermissionName.UniledportalBuyer_userCreate,
                ]}
            >
                <UserAssociationAssignment />
            </IfHasAllPermissions>
            {saveState === NetworkRequestState.Error && (
                <Box>
                    <FlashMessage status="error">
                        {saveError ?? t('Modules.Admin.UserForm.failedToCreate')}
                        <ValidationErrors validationErrors={saveValidationErrors} />
                    </FlashMessage>
                </Box>
            )}
            {saveState === NetworkRequestState.Success && (
                <Box>
                    <FlashMessage status="success">
                        {t('Modules.Admin.UserForm.createSuccess')}
                    </FlashMessage>
                </Box>
            )}
            <Button
                disabled={saveState === NetworkRequestState.InProgress || !formState.isValid}
                type="submit"
            >
                {t('Modules.Admin.UserForm.createBtn')}
            </Button>
        </AutoGrid>
    );
};

export default NewUserForm;
