import { mergeSystemSearchConfig as mergeFunction } from './Lib';
import { Config } from '../../config';
import {
    SystemSearchRecentResourceThunks,
    SystemSearchRecentResourceActions,
    SystemSearchRecentResourceSelectors,
    SystemSearchRecentResourceReducer,
} from './Slices/SystemSearchRecentResource';
import { systemSearchReducer } from './Slices/SystemSearch';

export const config: Config = {
    mergeFunction,
    reducers: {
        systemSearchRecentResource: SystemSearchRecentResourceReducer,
        systemSearch: systemSearchReducer,
    },
    EntitySchemaActions: {
        SpotlightRecentResource: {
            thunks: SystemSearchRecentResourceThunks,
            actions: SystemSearchRecentResourceActions as any,
            entityName: 'SpotlightRecentResource',
            selectors: SystemSearchRecentResourceSelectors,
        },
    },
    navigation: { groups: {} },
    pages: [],
    systemSearch: {},
};
