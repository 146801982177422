import React from 'react';
import { formatNumber } from 'c-lib';

type Props = {
    value: number | string;
    intlOptions?: Intl.NumberFormatOptions;
};

// TODO: choose locale based on something
const NumberFormat: React.FC<Props> = ({ value, intlOptions }) => (
    <>{formatNumber(value, intlOptions)}</>
);

export default NumberFormat;
