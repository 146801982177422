import { Skin } from 'c-entity-types';
import { baseInitialState, createBaseSlice } from 'c-data';
import { createByIdNetworkRequestsActions } from 'c-lib';
import { SkinState } from './Skin-types';
import FakeSkins from './FakeSkins';

const initialState: SkinState = {
    ...baseInitialState,
    uploadingLargeLogoById: {},
    uploadingSmallLogoById: {},
    attachingSkinById: {},
};

const additionalReducers = {
    // example: (state: SkinState, action: PayloadAction<string>): SkinState => state,
    ...createByIdNetworkRequestsActions<SkinState>()<
        'uploadingLargeLogoById',
        'UploadingLargeLogo'
    >('uploadingLargeLogoById', 'UploadingLargeLogo'),
    ...createByIdNetworkRequestsActions<SkinState>()<
        'uploadingSmallLogoById',
        'UploadingSmallLogo'
    >('uploadingSmallLogoById', 'UploadingSmallLogo'),
    ...createByIdNetworkRequestsActions<SkinState>()<'attachingSkinById', 'AttachingSkin'>(
        'attachingSkinById',
        'AttachingSkin',
    ),
};

const slice = createBaseSlice<Skin, SkinState, typeof additionalReducers>(
    {
        name: 'skin',
        entityName: 'Skin',
        initialState,
        reducers: additionalReducers,
    },
    FakeSkins,
);

const skinSlice = {
    skinActions: slice.slice.actions,
    skinReducer: slice.slice.reducer,
    skinAdapter: slice.adapter,
    skinSelectors: slice.selectors,
    skinThunks: slice.thunks,
};

export default skinSlice;
export const { skinReducer, skinActions, skinAdapter, skinSelectors, skinThunks } = skinSlice;
