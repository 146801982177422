import React, { useCallback } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Campaign } from '@uniled/api-sdk';
import { Stack } from '@mui/material';
import CampaignUserEditedBooleanCheckbox from './CampaignUserEditedBooleanCheckbox';

type Props = RenderColumnDataProps<Campaign>;

const CampaignSignOffCell: React.FC<Props> = ({ entity }) => {
    const updateSignOff = useCallback(
        (campaign: Campaign, newState: boolean) =>
            ({ id: campaign.id, sign_off: newState } as Campaign),
        [],
    );
    const updateInFlight = useCallback(
        (campaign: Campaign, newState: boolean) =>
            ({
                id: campaign.id,
                data_status: { ...campaign.data_status, inflight_report_sent: newState },
            } as Campaign),
        [],
    );
    const updatePCAReport = useCallback(
        (campaign: Campaign, newState: boolean) =>
            ({
                id: campaign.id,
                data_status: { ...campaign.data_status, pca_report_sent: newState },
            } as Campaign),
        [],
    );
    return (
        <Stack gap={1}>
            <CampaignUserEditedBooleanCheckbox
                campaign={entity}
                value={entity.sign_off}
                dateKey="sign_off_updated_at"
                userIdKey="sign_off_updated_by"
                label="Modules.Main.Campaigns.DataStatus.table.signOffCheckboxLabel"
                generateSaveData={updateSignOff}
            />
            <CampaignUserEditedBooleanCheckbox
                campaign={entity}
                value={entity.data_status.inflight_report_sent}
                dateKey="inflight_report_requested_at"
                userIdKey="inflight_report_requested_by"
                label="Modules.Main.Campaigns.DataStatus.table.inflightReportSentCheckboxLabel"
                generateSaveData={updateInFlight}
            />
            <CampaignUserEditedBooleanCheckbox
                campaign={entity}
                value={entity.data_status.pca_report_sent}
                dateKey="pca_report_requested_at"
                userIdKey="pca_report_requested_by"
                label="Modules.Main.Campaigns.DataStatus.table.pcaReportSentCheckboxLabel"
                generateSaveData={updatePCAReport}
            />
        </Stack>
    );
};

export default CampaignSignOffCell;
