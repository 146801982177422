import React, { useCallback, useEffect, useMemo } from 'react';
import { AutoGrid, Button, LinkButton } from 'c-components';
import { Box, ButtonProps, Stack, SvgIconProps, Typography } from '@mui/material';
import { CalendarMonth, Delete, Download, DriveFileMove, Upload } from '@mui/icons-material';
import apiClient from 'c-data/apiClient';
import { useCommonTranslation } from 'c-translation';
import { useBoolean } from 'react-hanger';
import {
    DeleteCreativesAndGroups,
    ReValidateCampaign,
    SetSelectedCreatives,
    UpdateCreatives,
} from 'c-main/Components/CreativeManagement/types';
import { useAPIClientRequest, useAuthenticatedUser } from 'c-hooks';
import to from 'await-to-js';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PermissionName, CreativeGroup, Creative } from '@uniled/api-sdk';
import { UpdateCreativeGroupsPerms } from 'c-main/Components/CreativeManagement/lib';
import MoveCreativesDialog from './MoveCreativesDialog';
import UpdateDateDialog from './UpdateDateDialog';
import UploadDialog from './UploadDialog';
import DeleteCreativesDialog from './DeleteCreativesDialog';

type Props = {
    campaignId: number;
    selectedCreatives: number[];
    setSelectedCreatives: SetSelectedCreatives;
    groups: CreativeGroup[];
    groupCount: number;
    openGroup?: number;
    updateCreatives: UpdateCreatives;
    reValidateCampaign: ReValidateCampaign;
    deleteData: DeleteCreativesAndGroups;
    creatives: Creative[];
    existingFilesArray: string[];
};

const CreateCreativesPerms: PermissionName[] = [PermissionName.UniledportalCreativeCreate];
const UpdateCreativesPerms: PermissionName[] = [PermissionName.UniledportalCreativeUpdate];
const DeleteCreativesPerms: PermissionName[] = [PermissionName.UniledportalCreativeDestroy];

const iconProps: SvgIconProps = { fontSize: 'inherit', sx: { mr: 0.5 } };
const buttonProps: ButtonProps['sx'] = { display: 'flex', alignItems: 'center' };

const CreativesWrapperControls: React.FC<Props> = ({
    campaignId,
    selectedCreatives,
    setSelectedCreatives,
    groupCount,
    groups,
    openGroup,
    updateCreatives,
    reValidateCampaign,
    deleteData,
    creatives,
    existingFilesArray,
}) => {
    const t = useCommonTranslation();
    const moveDialogState = useBoolean(false);
    const deleteDialogState = useBoolean(false);
    const liveDateDialogState = useBoolean(false);
    const endDateDialogState = useBoolean(false);
    const uploadDialogState = useBoolean(false);
    const downloadLink = useMemo(
        () =>
            apiClient.Entities.Campaign.downloadLink(campaignId, {
                creative_ids: selectedCreatives,
                include_creatives: true,
            }),
        [selectedCreatives, campaignId],
    );

    const downloadBtn = useMemo(
        () => (
            <LinkButton
                to={downloadLink}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                target="_blank"
                size="small"
                sx={buttonProps}
                disabled={selectedCreatives.length === 0}
                id="download-creatives-btn"
            >
                <Download {...iconProps} />
                {t('Modules.Main.CreativeManagement.creatives.controls.downloadBtnLabel')}
            </LinkButton>
        ),
        [selectedCreatives, downloadLink, t],
    );

    const moveBtn = useMemo(() => {
        if (groupCount > 0) {
            return (
                <IfHasAllPermissions permissions={UpdateCreativeGroupsPerms}>
                    <Button
                        size="small"
                        disabled={selectedCreatives.length === 0}
                        onClick={moveDialogState.setTrue}
                        sx={buttonProps}
                        id="move-creatives-btn"
                    >
                        <DriveFileMove {...iconProps} />
                        {t('Modules.Main.CreativeManagement.creatives.controls.moveBtnLabel')}
                    </Button>
                </IfHasAllPermissions>
            );
        }
        return null;
    }, [groupCount, selectedCreatives.length, moveDialogState.setTrue, t]);

    const uploadBtn = useMemo(
        () => (
            <IfHasAllPermissions permissions={CreateCreativesPerms} otherwise={null}>
                <Button
                    size="small"
                    onClick={uploadDialogState.setTrue}
                    sx={buttonProps}
                    id="upload-creatives-btn"
                >
                    <Upload {...iconProps} />
                    {t('Modules.Main.CreativeManagement.creatives.controls.uploadBtnLabel')}
                </Button>
            </IfHasAllPermissions>
        ),
        [uploadDialogState.setTrue, t],
    );

    const liveDateBtn = useMemo(
        () => (
            <IfHasAllPermissions permissions={UpdateCreativesPerms}>
                <Button
                    size="small"
                    disabled={selectedCreatives.length === 0}
                    onClick={liveDateDialogState.setTrue}
                    sx={buttonProps}
                    id="live-dates-creatives-btn"
                >
                    <CalendarMonth {...iconProps} />
                    {t('Modules.Main.CreativeManagement.creatives.controls.liveDateBtnLabel')}
                </Button>
            </IfHasAllPermissions>
        ),
        [selectedCreatives.length, liveDateDialogState.setTrue, t],
    );

    const endDateBtn = useMemo(
        () => (
            <IfHasAllPermissions permissions={UpdateCreativesPerms}>
                <Button
                    size="small"
                    disabled={selectedCreatives.length === 0}
                    onClick={endDateDialogState.setTrue}
                    sx={buttonProps}
                    id="end-dates-creatives-btn"
                >
                    <CalendarMonth {...iconProps} />
                    {t('Modules.Main.CreativeManagement.creatives.controls.endDateBtnLabel')}
                </Button>
            </IfHasAllPermissions>
        ),
        [selectedCreatives.length, endDateDialogState.setTrue, t],
    );

    const { start, requestState, error, reset } = useAPIClientRequest(
        apiClient.Entities.Creative.update,
    );
    const submitUpdateCreatives = useCallback(
        async (creatives: Partial<Creative>[]) => {
            const [, success] = await to(
                start(creatives as Creative[], ['file', 'creativeGroupIds', 'lineItemsCount']),
            );

            if (Array.isArray(success?.data?.data)) {
                updateCreatives(success?.data?.data);
                // asked not to deselect creatives after updating them
                // setSelectedCreatives([]);
            }
        },
        [start, updateCreatives],
    );

    const { isAdmin, user } = useAuthenticatedUser();

    const deletableSelectedCreatives = useMemo(
        () =>
            selectedCreatives.filter(creativeId => {
                if (isAdmin) return true;

                const creative = creatives.find(c => c.id === creativeId);
                return creative != null && creative.user_id === user.id;
            }),
        [selectedCreatives, creatives, isAdmin, user],
    );

    const nonDeletableSelectedCreatives = useMemo(
        () =>
            selectedCreatives
                .filter(creativeId => deletableSelectedCreatives.indexOf(creativeId) === -1)
                .map(creativeId => creatives.find(c => c.id === creativeId)),
        [selectedCreatives, deletableSelectedCreatives, creatives],
    );

    const deleteBtn = useMemo(
        () => (
            <IfHasAllPermissions permissions={DeleteCreativesPerms} otherwise={null}>
                <Button
                    size="small"
                    onClick={deleteDialogState.setTrue}
                    disabled={deletableSelectedCreatives.length === 0}
                    sx={buttonProps}
                    id="delete-creatives-btn"
                >
                    <Delete {...iconProps} />
                    {t('Modules.Main.CreativeManagement.creatives.controls.deleteBtnLabel')}
                </Button>
            </IfHasAllPermissions>
        ),
        [deleteDialogState.setTrue, t, deletableSelectedCreatives.length],
    );
    const {
        start: startDelete,
        requestState: deleteRequestState,
        error: deleteError,
        reset: resetDelete,
    } = useAPIClientRequest(apiClient.Entities.Creative.delete);
    const deleteSelectedCreatives = useCallback(async () => {
        const [err, success] = await to(startDelete(deletableSelectedCreatives));

        if (!err && success != null) {
            deleteData({ creatives: deletableSelectedCreatives });
            setSelectedCreatives([]);
            reValidateCampaign();
        }
    }, [
        startDelete,
        deletableSelectedCreatives,
        deleteData,
        setSelectedCreatives,
        reValidateCampaign,
    ]);

    useEffect(() => {
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moveDialogState.value, liveDateDialogState.value, endDateDialogState.value]);

    useEffect(() => {
        resetDelete();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteDialogState.value]);

    return (
        <>
            <MoveCreativesDialog
                dialogState={moveDialogState}
                groups={groups}
                selectedCreatives={selectedCreatives}
                updateCreatives={submitUpdateCreatives}
                updateCreativesState={requestState}
                updateCreativesError={error}
            />
            <UpdateDateDialog
                dialogState={liveDateDialogState}
                selectedCreatives={selectedCreatives}
                title="Modules.Main.CreativeManagement.creatives.controls.dates.updateLiveDateTitle"
                description="Modules.Main.CreativeManagement.creatives.controls.dates.updateLiveDateDescription"
                dateField="live_date"
                updateCreatives={submitUpdateCreatives}
                updateCreativesState={requestState}
                updateCreativesError={error}
            />
            <UpdateDateDialog
                dialogState={endDateDialogState}
                selectedCreatives={selectedCreatives}
                title="Modules.Main.CreativeManagement.creatives.controls.dates.updateEndDateTitle"
                description="Modules.Main.CreativeManagement.creatives.controls.dates.updateEndDateDescription"
                dateField="end_date"
                updateCreatives={submitUpdateCreatives}
                updateCreativesState={requestState}
                updateCreativesError={error}
            />
            {uploadDialogState.value && (
                <UploadDialog
                    closeDialog={uploadDialogState.setFalse}
                    isDialogOpen={uploadDialogState.value}
                    updateCreatives={updateCreatives}
                    reValidateCampaign={reValidateCampaign}
                    openGroup={openGroup}
                    existingFilesArray={existingFilesArray}
                />
            )}
            <DeleteCreativesDialog
                open={deleteDialogState.value}
                onClose={deleteDialogState.setFalse}
                deleteCreativesError={deleteError}
                deleteCreativesState={deleteRequestState}
                deleteSelectedCreatives={deleteSelectedCreatives}
                title="Modules.Main.CreativeManagement.creatives.controls.delete.title"
                description={
                    <>
                        {nonDeletableSelectedCreatives.length > 0 ? (
                            <Box textAlign="left">
                                <Stack gap={2}>
                                    {t(
                                        'Modules.Main.CreativeManagement.creatives.controls.delete.noPermission',
                                    )}
                                    <Stack gap={0.5}>
                                        {nonDeletableSelectedCreatives.map(creative => (
                                            <Box key={creative.id}>- {creative.name}</Box>
                                        ))}
                                    </Stack>
                                    {t(
                                        'Modules.Main.CreativeManagement.creatives.controls.delete.noPermissionTwo',
                                    )}
                                </Stack>
                            </Box>
                        ) : (
                            t(
                                'Modules.Main.CreativeManagement.creatives.controls.delete.description',
                                { count: deletableSelectedCreatives.length },
                            )
                        )}
                    </>
                }
                buttonLabel={useCommonTranslation(
                    'Modules.Main.CreativeManagement.creatives.controls.delete.buttonLabel',
                    { count: deletableSelectedCreatives.length },
                )}
            />
            <AutoGrid spacing={2} alignItems="center">
                {uploadBtn}
                {downloadBtn}
                {deleteBtn}
                {moveBtn}
                {liveDateBtn}
                {endDateBtn}
                <Typography variant="caption">
                    {selectedCreatives.length > 0
                        ? t('Modules.Main.CreativeManagement.creatives.controls.selectedCount', {
                              count: selectedCreatives.length,
                          })
                        : ''}
                </Typography>
            </AutoGrid>
        </>
    );
};

export default CreativesWrapperControls;
