import { AllEntities } from '@uniled/api-sdk';
import { generatePath } from 'react-router-dom';
import { EntityListRow } from '../types';

function generateRows({
    ids,
    baseEntityName,
    routeTemplate,
    onClick,
}: {
    ids: number[];
    baseEntityName: keyof AllEntities;
    routeTemplate?: string;
    onClick?: (id: number, event: React.MouseEvent<HTMLTableRowElement>) => void;
}): EntityListRow[] {
    return ids.map((id, index) => ({
        id,
        index,
        baseEntityName,
        onClick: onClick ? event => onClick(id, event) : null,
        link: routeTemplate ? generatePath(routeTemplate, { id }) : undefined,
    }));
}

export default generateRows;
