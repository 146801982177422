import React, { useMemo } from 'react';
import { EntityField, Table, TableBody, TableCell, TableHead, TableRow } from 'c-components';
import { Box, TableContainer, TypographyProps } from '@mui/material';
import { Displays_LineItem, Displays_Owner as Owner } from '@uniled/api-sdk';
import { useCommonTranslation } from 'c-translation';
import { aspectRatio } from 'c-lib';
import {
    getHumanReadableLineItemResolutions,
    getLineItemResolutions,
} from 'c-main/Components/CreativeManagement/lib';

type Props = {
    lineItem: Displays_LineItem;
};

const tdProps = {
    dense: true,
    isHeader: true,
    sx: { px: 2 },
    typographyProps: { variant: 'body2', color: 'grey.700' } as TypographyProps,
};

const AssignLineItemTable: React.FC<Props> = ({ lineItem }) => {
    const t = useCommonTranslation();
    const lineItemResolutions = useMemo(() => getLineItemResolutions([lineItem]), [lineItem]);
    const lineItemAspectRatios = useMemo(
        () => lineItemResolutions.map(([w, h]) => aspectRatio(w, h)).join(', '),
        [lineItemResolutions],
    );

    return (
        <Box borderRadius={2} p={2} bgcolor="grey.300">
            <TableContainer>
                <Table spacedOutRows>
                    <TableHead>
                        <TableRow isHeader>
                            <TableCell {...tdProps}>
                                {t('Modules.Main.Campaigns.Create.LineItemsTable.ownerHeader')}
                            </TableCell>
                            <TableCell {...tdProps}>
                                {t(
                                    'Modules.Main.Campaigns.Create.LineItemsTable.environmentHeader',
                                )}
                            </TableCell>
                            <TableCell {...tdProps}>
                                {t('Modules.Main.Campaigns.Create.LineItemsTable.nameHeader')}
                            </TableCell>
                            <TableCell {...tdProps}>
                                {t('Modules.Main.Campaigns.Create.LineItemsTable.sizeHeader')}
                            </TableCell>
                            <TableCell {...tdProps}>
                                {t('Modules.Main.Campaigns.Create.LineItemsTable.resolutionHeader')}
                            </TableCell>
                            <TableCell {...tdProps}>
                                {t(
                                    'Modules.Main.Campaigns.Create.LineItemsTable.aspectRatioHeader',
                                )}
                            </TableCell>
                            <TableCell {...tdProps}>
                                {t('Modules.Main.Campaigns.Create.LineItemsTable.fileTypesHeader')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <EntityField<Owner>
                                    field="name"
                                    entityName="Displays_Owner"
                                    id={lineItem.owner_id}
                                />
                            </TableCell>
                            <TableCell>{lineItem.environment}</TableCell>
                            <TableCell>
                                <b>{lineItem.name}</b>
                            </TableCell>
                            <TableCell>{lineItem.size}</TableCell>
                            <TableCell>{getHumanReadableLineItemResolutions([lineItem])}</TableCell>
                            <TableCell>{lineItemAspectRatios}</TableCell>
                            <TableCell>{lineItem.fileTypes?.map(t => t.type).join(', ')}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default AssignLineItemTable;
