import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
    animatedItem: {
        animation: `$flickerAnimation 3000ms ${theme.transitions.easing.easeInOut} infinite`,
    },
    '@keyframes flickerAnimation': {
        '0%': {
            opacity: 0,
        },
        '50%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
}));

const FadeInOut: React.FC<BoxProps> = ({ children, ...rest }) => {
    const classes = useStyles();
    return (
        <Box className={classes.animatedItem} {...rest}>
            {children}
        </Box>
    );
};

export default FadeInOut;
