import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    AutoGrid,
    Button,
    DialogV2,
    Filterdropdown,
    FlashMessage,
    LoadingSpinner,
    OptionSchema,
    TextField,
} from 'c-components';
import { useCommonTranslation } from 'c-translation';
import {
    CampaignCreativesAutoGenerateGroupsResponse,
    CampaignCreativesAutoGroupsGenerateRequest,
} from '@uniled/api-sdk';
import { Box, CircularProgress, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useAPIClientRequest, UseListSelection, useListSelection } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { ErrorImageTryAgain } from 'c-components';
import { useDebounce } from 'use-debounce';
import { stringWithoutWhitespace } from 'c-lib';
import to from 'await-to-js';
import { ResetCreativesData } from 'c-main/Components/CreativeManagement/types';
import AutoGroupsTable from './AutoGroupsTable';

type Props = {
    campaignId: number;
    resolutions: string[];
    open: boolean;
    onClose: () => void;
    resetData: ResetCreativesData;
};

const AutoGroupsDialog: React.FC<Props> = ({
    campaignId,
    open,
    resolutions,
    onClose,
    resetData,
}) => {
    const resolutionOptions = useMemo<OptionSchema[]>(
        () =>
            resolutions
                .sort((a, b) => Number(a.split('x')?.[0] ?? '') - Number(b.split('x')?.[0] ?? ''))
                .map(res => ({ label: res, value: stringWithoutWhitespace(res) })),
        [resolutions],
    );

    const t = useCommonTranslation();
    const [resolutionsVal, setResolutionsVal] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const onSearchUpdated = useCallback(e => {
        setSearchTerm(e.target.value);
    }, []);

    const {
        start: AutoGenGroups,
        error: GenError,
        isLoading: GenLoading,
        hasFailed: GenFailed,
        data: GeneratedGroups,
    } = useAPIClientRequest(apiClient.Entities.Campaign.autoGenerateCreativeGroups);

    const genGroups = useCallback(async () => {
        await to(AutoGenGroups(campaignId, resolutionsVal.length > 0 ? resolutionsVal : undefined));
    }, [AutoGenGroups, campaignId, resolutionsVal]);

    useEffect(() => {
        // on open
        genGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const allGroups = useMemo<CampaignCreativesAutoGenerateGroupsResponse['groups']>(
        () =>
            GeneratedGroups?.data?.groups ??
            ([] as unknown as CampaignCreativesAutoGenerateGroupsResponse['groups']),
        [GeneratedGroups],
    );

    const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);
    const visibleGroups = useMemo(
        () =>
            allGroups.filter(
                g => g.name.toLowerCase().indexOf(debouncedSearchTerm ?? '') !== -1,
            ) as typeof allGroups,
        [allGroups, debouncedSearchTerm],
    );

    const hookProps = useMemo<UseListSelection>(
        () => ({
            itemIds: allGroups.map(g => g.name),
            visibleItemIds: visibleGroups.map(g => g.name),
        }),
        [allGroups, visibleGroups],
    );
    const listSelectionProps = useListSelection(hookProps);

    const {
        start: CreateGroups,
        error: CreateError,
        isLoading: CreateLoading,
        hasFailed: CreateFailed,
    } = useAPIClientRequest(apiClient.Entities.Campaign.createAutoGeneratedGroups);

    const createSelectedGroups = useCallback(async () => {
        const newGroups = allGroups.reduce(
            (createGroups, group) => {
                if (listSelectionProps.selectedIds.indexOf(group.name) !== -1) {
                    createGroups.groups.push({ creatives: group.new, name: group.name });
                }

                return createGroups;
            },
            { groups: [] } as unknown as CampaignCreativesAutoGroupsGenerateRequest,
        );

        const [, success] = await to(CreateGroups(campaignId, newGroups));

        if (
            success &&
            success?.data != null &&
            success?.data?.creativeGroups &&
            success?.data?.creatives
        ) {
            resetData(success.data);
            onClose();
        }
    }, [CreateGroups, allGroups, campaignId, listSelectionProps.selectedIds, resetData, onClose]);

    const createDisabled = useMemo(
        () => listSelectionProps.selectedCount === 0,
        [listSelectionProps.selectedCount],
    );

    const onResolutionsClose = useCallback(() => {
        genGroups();
    }, [genGroups]);

    return (
        <DialogV2
            title="Modules.Main.CreativeManagement.groups.autoGroups.dialogTitle"
            maxWidth="md"
            onClose={onClose}
            open={open}
            actions={
                <Box>
                    {!GenFailed && (
                        <>
                            {CreateFailed && (
                                <Box mb={2} textAlign="center">
                                    <FlashMessage status="error">
                                        {String(CreateError)}
                                    </FlashMessage>
                                </Box>
                            )}
                            <Button
                                size="large"
                                disabled={createDisabled || CreateLoading}
                                onClick={createSelectedGroups}
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                {t(
                                    'Modules.Main.CreativeManagement.groups.autoGroups.createGroupsLabel',
                                )}
                                {CreateLoading && (
                                    <CircularProgress size={15} sx={{ ml: 1, color: 'inherit' }} />
                                )}
                            </Button>
                        </>
                    )}
                </Box>
            }
        >
            <Box height="60vh" display="flex" flexDirection="column" overflow="hidden" pt={1}>
                <Box px={1}>
                    <AutoGrid spacing={2} xs={12} sm={6} lg={4}>
                        <TextField
                            value={searchTerm}
                            placeholder={t(
                                'Modules.Main.CreativeManagement.creatives.filters.searchPlaceholder',
                            )}
                            onChange={onSearchUpdated}
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Filterdropdown
                            multiple
                            placeholder={t(
                                'Modules.Main.CreativeManagement.creatives.filters.resolutionsPlaceholder',
                            )}
                            options={resolutionOptions}
                            value={resolutionsVal}
                            onChange={setResolutionsVal}
                            onClose={onResolutionsClose}
                        />
                    </AutoGrid>
                </Box>
                {GenFailed && (
                    <Box
                        flex={1}
                        display="flex"
                        overflow="hidden"
                        position="relative"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <ErrorImageTryAgain
                            title="Modules.Main.CreativeManagement.groups.autoGroups.generateGroupsErrorTitle"
                            description={String(GenError)}
                            retryLabel="Modules.Main.CreativeManagement.groups.autoGroups.generateGroupsRetryLabel"
                            retry={genGroups}
                        />
                    </Box>
                )}
                {!GenFailed && (
                    <Box flex={1} display="flex" overflow="hidden" position="relative">
                        {GenLoading && <LoadingSpinner />}

                        <AutoGroupsTable
                            visibleGroups={visibleGroups}
                            searchTerm={debouncedSearchTerm}
                            disableCheckboxes={CreateLoading}
                            {...listSelectionProps}
                        />
                    </Box>
                )}
            </Box>
        </DialogV2>
    );
};

export default AutoGroupsDialog;
