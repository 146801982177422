import React, { useMemo } from 'react';
import { Alert as MuiAlert, AlertProps } from '@mui/material';
import merge from 'deepmerge';

type Props = AlertProps;

const Alert: React.FC<Props> = props => {
    const { variant, sx } = props;

    const theSx = useMemo(() => {
        if (variant === 'outlined') {
            //     // merge this override SX with whatever other SX may have been supplied
            return merge({ borderWidth: 2 }, (sx ?? {}) as any) as AlertProps['sx'];
        }

        return sx;
    }, [variant, sx]);

    return <MuiAlert {...props} sx={theSx} />;
};

export default Alert;
