import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Drawer } from '@mui/material';
import cx from 'classnames';
import { uiSelectors, uiThunks } from 'c-wrapper/Slices/UI';
import { drawerWidth, navigationInsetShadow } from 'c-wrapper/constants';
import { DesktopMobileSwitcher } from 'c-components';
import { useIsMobileView } from 'c-hooks';
import { makeStyles } from '@mui/styles';
import DrawerContent from './DrawerContent';
import AppBarToolbar from './AppBarToolbar';
import ResetDrawerOverlay from './ResetDrawerOverlay';

const useStyles = makeStyles(theme => ({
    appBar: {
        // zIndex: theme.zIndex.drawer + 1,
        width: '100%',
        background: theme.palette?.custom?.navigationBgColor ?? theme.palette.common.white,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // borderBottom: `1px solid ${theme.palette.divider}`,
    },
    appBarLargeShift: {
        paddingLeft: theme.spacing(7),
        // [theme.breakpoints.up('sm')]: {
        // paddingLeft: theme.spacing(9),
        // },
    },
    appBarShift: {
        paddingLeft: drawerWidth,
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin', 'padding'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'absolute',
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerOpen: {
        height: '100%',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    customDrawerStyling: {
        background: theme.palette?.custom?.navigationBgColor ?? theme.palette.common.white,
    },
}));

const PaperProps = {
    sx: {
        boxShadow: navigationInsetShadow,
        border: 'none',
    },
};

const TopAppBar = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const open = useSelector(uiSelectors.isDrawerOpen);
    const isDrawerOverlay = useIsMobileView();

    const setOpen = useCallback(
        (newState: boolean) => {
            dispatch(uiThunks.toggleDrawerOpen(newState));
        },
        [dispatch],
    );

    const elevation = useMemo(() => (isDrawerOverlay ? 8 : 0), [isDrawerOverlay]);

    const handleDrawerOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleDrawerClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const container = window?.document?.body;

    const desktopDrawer = useMemo(
        () => (
            <Drawer
                // desktop
                id="drawer-navigation-container"
                variant="permanent"
                className={cx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                PaperProps={PaperProps}
                classes={{
                    paper: cx(classes.customDrawerStyling, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <DrawerContent isOpen={open} onClose={handleDrawerClose} />
            </Drawer>
        ),
        [open, classes, handleDrawerClose],
    );

    const mobileDrawer = useMemo(
        () => (
            <Drawer
                // mobile
                id="drawer-navigation-container"
                container={container}
                variant="temporary"
                anchor="left"
                open={open}
                onClose={handleDrawerClose}
                classes={{
                    paper: cx(classes.drawerPaper, classes.customDrawerStyling),
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <DrawerContent mobileDrawer isOpen={open} onClose={handleDrawerClose} />
            </Drawer>
        ),
        [open, classes, handleDrawerClose, container],
    );

    return (
        <>
            <ResetDrawerOverlay />
            <AppBar
                position="sticky"
                className={cx(classes.appBar, {
                    [classes.appBarShift]: open,
                    [classes.appBarLargeShift]: !isDrawerOverlay,
                })}
                elevation={elevation}
            >
                <AppBarToolbar
                    mobileDrawer={isDrawerOverlay}
                    handleOpen={handleDrawerOpen}
                    open={open}
                />
            </AppBar>
            <DesktopMobileSwitcher desktopContent={desktopDrawer} mobileContent={mobileDrawer} />
        </>
    );
};

export default TopAppBar;
