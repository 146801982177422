import React from 'react';
import { AutoGrid, Chip } from 'c-components';
import { useBoolean } from 'react-hanger';
import { Box, Typography } from '@mui/material';
import { ChatMessageAttachment } from '../../types';
import AttachmentIcon from '../AttachmentIcon';

type Props = {
    attachments: ChatMessageAttachment[];
    onRemove: (index: number) => void;
};

const NewMessageAttachments: React.FC<Props> = ({ attachments, onRemove }) => {
    return (
        <AutoGrid flexWrap="nowrap" gap={2}>
            {attachments.map((a, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Attachment key={idx} onRemove={() => onRemove(idx)} attachment={a} />
            ))}
        </AutoGrid>
    );
};

const Attachment: React.FC<{ attachment: ChatMessageAttachment; onRemove: () => void }> = ({
    attachment,
    onRemove,
}) => {
    const { value: isHovering, setTrue, setFalse } = useBoolean(false);
    return (
        <Chip
            onMouseEnter={setTrue}
            onMouseLeave={setFalse}
            onDelete={onRemove}
            label={
                <AutoGrid gap={0.5} alignItems="center" xs flexWrap="nowrap">
                    <Box color="grey.400">
                        <AttachmentIcon
                            mimetype={attachment.mimetype}
                            fontSize="inherit"
                            color="inherit"
                        />
                    </Box>
                    <Typography
                        noWrap
                        variant="caption"
                        maxWidth={isHovering ? undefined : 100}
                        component="p"
                    >
                        {attachment.name}
                    </Typography>
                </AutoGrid>
            }
        />
    );
};
export default NewMessageAttachments;
