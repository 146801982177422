import React, { useEffect, useMemo } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Grid,
    GridSize,
    Tooltip,
    Typography,
} from '@mui/material';
import { formatBytes } from 'c-lib';
import HighlightWord from 'c-components/HighlightWord';
import { useDateUtils } from 'c-hooks';
import FileIcon from './FileIcon';
import { FileBrowserFile, ViewOptionProps } from './types';
import FilePreview from './FilePreview';
import OpenFileNewTab from './OpenFileNewTab';
import RawFilePreview from './RawFilePreview';
import { generateFileResolution } from './lib';
import OpenFileDownload from './OpenFileDownload';
import FileHighlightFilterText from './FileHighlightFilterText';

type Props = ViewOptionProps;

type GridSizes = { xs?: GridSize; sm?: GridSize; md?: GridSize; lg?: GridSize; xl?: GridSize };
const defaultGridSizes: GridSizes = { xs: 12, sm: 6, md: 4, lg: 3, xl: 3 };
const withPreviewGridSizes: GridSizes = { xs: 12, sm: 12, md: 12, lg: 6, xl: 6 };

const generateFileGridId = (file: FileBrowserFile) => `file-grid-${file.id}`;

const FileBrowserGridView: React.FC<Props> = ({
    files,
    focusedFile,
    onFileClick,
    ListWrapper,
    filters,
}) => {
    const { formatDateString } = useDateUtils();
    const searchTerm = useMemo(() => filters?.term ?? '', [filters?.term]);
    // scroll to active tile
    useEffect(() => {
        if (focusedFile != null) {
            setTimeout(() => {
                document
                    ?.getElementById(generateFileGridId(focusedFile))
                    ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }, 500);
        }
    }, [focusedFile]);
    const { xs, sm, md, lg, xl } = useMemo<GridSizes>(
        () => (focusedFile != null ? withPreviewGridSizes : defaultGridSizes),
        [focusedFile],
    );
    return (
        <Grid container spacing={2} flex={1} overflow="hidden">
            <Grid item xs overflow="auto" flex={1} height="100%">
                <ListWrapper>
                    <Grid container spacing={2}>
                        {files.map(file => (
                            <Grid
                                item
                                id={generateFileGridId(file)}
                                key={file.id}
                                xs={xs}
                                sm={sm}
                                md={md}
                                lg={lg}
                                xl={xl}
                                display="flex"
                                sx={{ position: 'relative' }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        mt: 1,
                                        mr: 1,
                                    }}
                                >
                                    <OpenFileDownload file={file} />
                                    <OpenFileNewTab file={file} />
                                </Box>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        left: 0,
                                        mt: 2,
                                        ml: 4,
                                    }}
                                >
                                    <Typography noWrap variant="caption">
                                        {formatDateString({ date: file.createdAt })}
                                    </Typography>
                                </Box>
                                <Card
                                    sx={{
                                        flex: 1,
                                        cursor: 'pointer',
                                        borderWidth: 3,
                                        borderStyle: 'solid',
                                        borderColor:
                                            focusedFile?.id === file.id
                                                ? 'primary.main'
                                                : 'transparent',
                                        transition: 'all 500ms',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    onClick={() => onFileClick(file.id)}
                                >
                                    <Box>
                                        <CardMedia sx={{ position: 'relative', display: 'flex' }}>
                                            <Box mx="auto" mt={4}>
                                                <RawFilePreview
                                                    file={file}
                                                    maxHeight={115}
                                                    maxWidth={115}
                                                    preferredSize="small"
                                                    showVideoPreview={false}
                                                    fallback={
                                                        <FileIcon
                                                            file={file}
                                                            sx={{ width: '100%', height: 115 }}
                                                        />
                                                    }
                                                />
                                            </Box>
                                        </CardMedia>
                                        <CardContent>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Typography noWrap variant="caption">
                                                        <FileHighlightFilterText
                                                            filters={filters.resolutions}
                                                            text={generateFileResolution(file)}
                                                        />{' '}
                                                        (
                                                        <FileHighlightFilterText
                                                            filters={filters.aspectRatios}
                                                            text={file.tags.aspectRatio}
                                                        />
                                                        ){' '}
                                                        <FileHighlightFilterText
                                                            filters={filters.fileTypes}
                                                            text={file.fileTypeSimple}
                                                        />
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        noWrap
                                                        variant="caption"
                                                        component="p"
                                                        align="right"
                                                    >
                                                        {formatBytes(file.size ?? 0)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm sx={{ overflow: 'hidden' }}>
                                                    <Tooltip title={file.name}>
                                                        <Typography
                                                            sx={{
                                                                wordBreak: 'break-all',
                                                                whiteSpace: 'pre-line',
                                                            }}
                                                            variant="caption"
                                                            component={Box}
                                                        >
                                                            <HighlightWord
                                                                text={file.name}
                                                                searchTerm={searchTerm}
                                                            />
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </ListWrapper>
            </Grid>
            {focusedFile && (
                <Grid item xs={12} md={6}>
                    <FilePreview file={focusedFile} />
                </Grid>
            )}
        </Grid>
    );
};

export default FileBrowserGridView;
