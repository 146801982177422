import React from 'react';
import { AllEntities, AnEntity } from '@uniled/api-sdk';
import { PageEntityConfig } from 'c-config';

export type UseEntityPageContextValues = {
    entityName?: keyof AllEntities;
    pageConfig: PageEntityConfig<AnEntity>;
};

export const UseEntityPageContext = React.createContext<UseEntityPageContextValues>({
    entityName: null,
    pageConfig: null,
});
