import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Box, CircularProgress, ClickAwayListener, IconButton, Paper, Popper } from '@mui/material';
import { useBoolean } from 'react-hanger';
import { PermissionName } from '@uniled/api-sdk';
import { AddCircle } from '@mui/icons-material';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { StaticDateTimePicker } from '@mui/x-date-pickers-pro';
import { Button } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { onClickStopPropagation } from 'c-lib';

type Props = {
    ownerId: number;

    onSaveDate: (ownerId: number, newDate: Date) => void;
    isLoading: boolean;
};

const adminPerms = [PermissionName.Admin];

const LastContactedMediaOwnerControls: React.FC<Props> = ({ ownerId, onSaveDate, isLoading }) => {
    const btnRef = useRef();
    const [localDateValue, setLocalDateValue] = useState(null);
    const { value: isOpen, setTrue: open, setFalse: close } = useBoolean(false);

    const onClosePicker = useCallback(() => {
        close();
        setLocalDateValue(null);
    }, [close]);

    const onSave = useCallback(() => {
        onSaveDate(ownerId, localDateValue);
        onClosePicker();
    }, [onClosePicker, onSaveDate, ownerId, localDateValue]);

    return (
        <>
            <Box onClick={onClickStopPropagation}>
                <Popper open={isOpen} anchorEl={btnRef.current} sx={{ zIndex: 9999 }}>
                    <ClickAwayListener onClickAway={onClosePicker}>
                        <Paper elevation={2}>
                            <Box display="flex" flexDirection="column">
                                <StaticDateTimePicker
                                    // displayStaticWrapperAs="desktop"
                                    // openTo="day"
                                    value={localDateValue}
                                    onChange={setLocalDateValue}
                                    slotProps={useMemo(() => ({ actionBar: { actions: [] } }), [])}
                                    // renderInput={params => <TextField {...params} />}
                                />
                                <Box ml="auto" mr={2} mb={2}>
                                    <Button size="small" onClick={onSave}>
                                        {useCommonTranslation(
                                            'Modules.Main.Campaigns.DataStatus.LastContactedMediaOwner.saveDate',
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
                <IfHasAllPermissions permissions={adminPerms}>
                    <Box display="flex">
                        <IconButton
                            size="small"
                            ref={btnRef}
                            onClick={open}
                            disabled={isLoading}
                            color="primary"
                        >
                            {isLoading ? <CircularProgress size={15} /> : <AddCircle />}
                        </IconButton>
                    </Box>
                </IfHasAllPermissions>
            </Box>
        </>
    );
};

export default LastContactedMediaOwnerControls;
