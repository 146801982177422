import { useEffect } from 'react';
import Hotjar from '@hotjar/browser';
import { useAuthenticatedUser } from 'c-hooks';

function HotjarInitialiser(): null {
    const { user } = useAuthenticatedUser();

    useEffect(() => {
        Hotjar.init(Number(import.meta.env.VITE_HOTJAR_SITE_ID), 6);
    }, []);

    useEffect(() => {
        if (user) {
            Hotjar.identify(String(user.id), { name: user.name, email: user.email });
        }
    }, [user]);
    return null;
}

export default HotjarInitialiser;
