import React, { PropsWithChildren, Ref, useMemo } from 'react';
import { ListItem, ListItemProps } from '@mui/material';
import merge from 'deepmerge';

type Props = PropsWithChildren<ListItemProps> & {
    dropHoveredOver?: boolean;
    canDrop?: boolean;
};

const baseSx = {
    borderRadius: 1.5,
    borderWidth: '2px',
};

const dropHoveredOver = {
    ...baseSx,
    borderStyle: 'dashed',
    borderColor: 'primary.main',
    borderBottomStyle: 'dashed',
    borderBottomColor: 'primary.main',
};

const canDrop = {
    ...dropHoveredOver,
    borderColor: 'grey.500',
    borderBottomColor: 'grey.500',
};

const defaultSx = {
    ...baseSx,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderBottomStyle: 'solid',
    borderBottomColor: 'grey.300',
};
const defaultSxSelected = {
    ...baseSx,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderBottomStyle: 'solid',
    borderBottomColor: 'primary.main',
};

const FolderStyledListItem = ({ canDrop, dropHoveredOver, ...props }: Props, ref: Ref<any>) => {
    const baselineSx = useMemo(() => {
        if (canDrop && !dropHoveredOver) return canDrop;
        if (dropHoveredOver) return dropHoveredOver;

        if (props.selected) return defaultSxSelected;

        return defaultSx;
    }, [dropHoveredOver, canDrop, props.selected]);

    const sx = useMemo(
        () => merge(baselineSx as any, { ...props.sx } as any),
        [baselineSx, props.sx],
    );

    return <ListItem ref={ref} {...props} sx={sx} />;
};

export default React.forwardRef(FolderStyledListItem);
