import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Collapse, IconButton, ListItem, Tooltip } from '@mui/material';
import { DrawerNavigationItem } from 'c-wrapper/Types/DrawerConfiguration';
import { ListItemIcon, ListItemText, MuiRouterLink } from 'c-components';
import { Translate } from 'c-translation';
import { uiSelectors } from 'c-wrapper/Slices/UI';
import DrawerNavigationGroup from 'c-wrapper/Components/DrawerNavigationGroup';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { ExpandLess } from '@mui/icons-material';
import { useBoolean } from 'react-hanger';
import { cssRotation } from 'c-lib';
import { useUserPermissions } from 'c-auth-module/Hooks';

type Props = {
    item: DrawerNavigationItem;
    inSubList?: boolean;
};

const useStyles = makeStyles(theme => ({
    active: {
        borderRight: `5px solid ${theme.palette.primary.main}`,
    },
    semiActive: {
        borderRight: `5px solid ${alpha(theme.palette.primary.main, 0.5)}`,
    },
}));

const DrawerNavigationListItem: React.FC<Props> = props => {
    const { pathname } = useLocation();
    const {
        item: { link },
    } = props;
    const isActive = useMemo(() => pathname.startsWith(link), [pathname, link]);
    const exactMatch = useMemo(() => pathname === link, [pathname, link]);

    /**
     * we want to stop re rendering the list item every time the pathname changes
     * unless the isActive boolean check has changed
     */
    return <MemoizedItem {...props} isActive={isActive} exactMatch={exactMatch} />;
};

type ItemProps = Props & {
    isActive: boolean;
    exactMatch: boolean;
};
const MemoizedItem = React.memo<ItemProps>(
    ({
        isActive,
        exactMatch,
        inSubList,
        item: { link, items, label, icon, id, hideIfNoChildren },
    }) => {
        const { hasAll } = useUserPermissions();
        const { active, semiActive } = useStyles();
        const drawerOpen = useSelector(uiSelectors.isDrawerOpen);
        const childItems = useMemo(() => {
            const visibleChildItems =
                Object.entries(items ?? {})
                    ?.map(x => x[1])
                    ?.filter(item => hasAll(item.permissions)) ?? [];

            return items == null ||
                (visibleChildItems.length === 0 && hideIfNoChildren === true) ? undefined : (
                <DrawerNavigationGroup isSubList items={visibleChildItems} />
            );
        }, [items, hasAll, hideIfNoChildren]);

        const { toggle, value } = useBoolean(false);

        const isOpen = useMemo(() => {
            return (childItems && isActive && drawerOpen) || value === true;
        }, [childItems, isActive, drawerOpen, value]);

        const onClickToggleOpen = useCallback(
            e => {
                e.preventDefault();
                e.stopPropagation();
                toggle();
            },
            [toggle],
        );

        if (childItems == null && hideIfNoChildren) {
            return null;
        }

        return (
            <>
                <Tooltip
                    title={<Translate path={label} />}
                    placement="right"
                    disableHoverListener={drawerOpen}
                >
                    <ListItem
                        id={`navigation-drawer-${id}`}
                        button
                        component={MuiRouterLink}
                        to={link}
                        color="inherit"
                        className={cx({
                            [active]: !inSubList && isActive && exactMatch,
                            [semiActive]: !exactMatch && isActive && !inSubList,
                        })}
                    >
                        <ListItemIcon sx={{ fontSize: '1.5em' }}>{icon}</ListItemIcon>
                        <ListItemText
                            primary={<Translate path={label} />}
                            primaryTypographyProps={{
                                sx: { color: inSubList && isActive && 'primary.main' },
                            }}
                        />
                        {childItems && !isActive && (
                            <IconButton onClick={onClickToggleOpen} disabled={isActive}>
                                <ExpandLess css={cssRotation({ rotate: isOpen })} />
                            </IconButton>
                        )}
                    </ListItem>
                </Tooltip>
                {childItems && (
                    <Collapse in={isOpen && drawerOpen} unmountOnExit>
                        {childItems}
                    </Collapse>
                )}
            </>
        );
    },
);

export default DrawerNavigationListItem;
