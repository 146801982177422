import {
    channelFrameActions,
    channelFrameAdapter,
    channelFrameThunks as defaultThunks,
    channelFrameSelectors as defaultSelectors,
    channelFrameReducer,
} from './ChannelFrame-slice';
import * as thunks from './ChannelFrame-thunks';
import * as selectors from './ChannelFrame-selectors';

const channelFrameThunks = { ...defaultThunks, ...thunks };
const channelFrameSelectors = { ...defaultSelectors, ...selectors };

export { default as ChannelFrameSlice } from './ChannelFrame-slice';
export {
    channelFrameSelectors,
    channelFrameActions,
    channelFrameAdapter,
    channelFrameThunks,
    channelFrameReducer,
};
