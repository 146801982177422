import React, { useCallback, useEffect, useMemo } from 'react';
import { useAuthenticatedUser, useSkinSwitcher } from 'c-hooks';
import { Box } from '@mui/material';
import { OptionSchema } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { useSelector } from 'react-redux';
import { authSelectors } from 'c-auth-module/Slices/Auth';
import { useRouteMatch } from 'react-router-dom';
import { PostAuthRoutes } from 'c-routes';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { PermissionName } from '@uniled/api-sdk';
import { DEFAULT_SKIN_ID } from 'c-wrapper/Slices/UI/ui-selectors';
import AdminSkinSwitcher from './AdminSkinSwitcher';
import NonAdminSkinSwitcher from './NonAdminSkinSwitcher';

type Props = {
    open: boolean;
};

const SkinSwitcher: React.FC<Props> = ({ open }) => {
    const isLoggedIn = useSelector(authSelectors.isLoggedIn);
    const match = useRouteMatch(PostAuthRoutes.Admin.EditSkin);
    const { hasAll } = useUserPermissions();
    const { user } = useAuthenticatedUser();

    const canAdminSkins = useMemo(
        () =>
            isLoggedIn &&
            hasAll([PermissionName.UniledportalSkinUpdate, PermissionName.UniledportalSkinCreate]),
        [hasAll, isLoggedIn],
    );

    const {
        selectedSkin,
        getSkinById,
        currentSkinId,
        isCustomSkinSelected,
        switchSkin,
        switchSkinToDefault,
    } = useSkinSwitcher();

    const userHasSkins = useMemo(() => user?.skins?.length > 0, [user?.skins?.length]);
    const userDefaultSkin = useMemo(() => {
        if (userHasSkins) return user.skins[0];

        return DEFAULT_SKIN_ID;
    }, [user.skins, userHasSkins]);

    const onSkinSelected = useCallback(
        val => {
            if (val == null && userHasSkins) {
                // set to the user's "first" skin (if they have one)
                switchSkin(userDefaultSkin);
                return;
            }

            // allowing the skin to be set to null/undefined will mean the default built into the code skin will be used
            if (val !== DEFAULT_SKIN_ID) {
                switchSkin(val);
                getSkinById(val);
                return;
            }

            switchSkinToDefault();
        },
        [userHasSkins, switchSkinToDefault, switchSkin, userDefaultSkin, getSkinById],
    );
    const t = useCommonTranslation();
    const additionalOptions = useMemo<OptionSchema[]>(
        () => [
            {
                value: DEFAULT_SKIN_ID,
                label: t('Modules.Admin.SkinSwitcher.defaultThemeOptionLabel'),
            },
        ],
        [t],
    );
    useEffect(() => {
        // if the skin entity changes, update the selected skin
        // check not on the skin editing page because we don't want to interrupt users who are editing skins
        if (selectedSkin != null && match == null) {
            switchSkin(selectedSkin.id);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSkin]);

    useEffect(() => {
        if (currentSkinId !== DEFAULT_SKIN_ID && isCustomSkinSelected && isLoggedIn) {
            // force refresh skin on mount
            getSkinById(currentSkinId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box py={2}>
            {canAdminSkins && (
                <AdminSkinSwitcher
                    additionalOptions={additionalOptions}
                    onChange={onSkinSelected}
                    selectedSkinId={selectedSkin?.id ?? DEFAULT_SKIN_ID}
                />
            )}
            {!canAdminSkins && isLoggedIn && user?.skins?.length > 1 && (
                <NonAdminSkinSwitcher
                    onChange={onSkinSelected}
                    selectedSkinId={selectedSkin?.id ?? DEFAULT_SKIN_ID}
                />
            )}
        </Box>
    );
};

export default SkinSwitcher;
