import React, { forwardRef, useCallback, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

interface OptionType {
    value: string;
    label: string;
    inputValue?: string;
}

const DynamicAutocomplete = ({
    options,
    setOptions,
    setValue,
    value,
    label = '',
    freeSolo = true,
    placeholder = '',
    disabled,
}) => {
    const [inputValue, setInputValue] = useState(value ?? '');

    const handleFilterOptions = useCallback(
        (options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some(option => option.label === inputValue);

            if (inputValue !== '' && !isExisting) {
                filtered.push({
                    inputValue,
                    label: `Add "${inputValue}"`,
                    value: inputValue,
                });
            }
            return filtered;
        },
        [options],
    );

    const addNewVendor = useCallback(
        (inputValue: string) => {
            const newVendor = { label: inputValue, value: inputValue };
            setOptions(currentOptions => [...currentOptions, newVendor]);
            setValue(newVendor);
            setInputValue(inputValue);
        },
        [setOptions, setValue],
    );

    const onChangeHandler = useCallback(
        (option: OptionType) => {
            setValue(option);
            setInputValue(option.label);
        },
        [setValue],
    );

    const handleChange = useCallback(
        (event, newValue, reason) => {
            if (reason === 'selectOption') {
                if (newValue && newValue.inputValue) {
                    addNewVendor(newValue.inputValue);
                } else {
                    onChangeHandler(newValue || { label: '', value: '' });
                }
            } else if (reason === 'clear') {
                setValue(null);
                setInputValue('');
            }
        },
        [addNewVendor, onChangeHandler, setValue],
    );

    const handleInputChange = useCallback((event, newInputValue, reason) => {
        if (reason === 'input') {
            setInputValue(newInputValue);
        }
    }, []);

    return (
        <Autocomplete
            disabled={disabled}
            sx={{ backgroundColor: 'white' }}
            value={value || null}
            onChange={handleChange}
            filterOptions={handleFilterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={options}
            getOptionLabel={option => option.label || option.value || ''}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    variant="outlined"
                    fullWidth
                />
            )}
            freeSolo={freeSolo}
            inputValue={inputValue}
            onInputChange={handleInputChange}
        />
    );
};

export default forwardRef(DynamicAutocomplete);
