import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, IconButton, IconButtonProps } from '@mui/material';
import { Apps, Download, ViewCarousel, ViewList } from '@mui/icons-material';
import { useIsMobileView } from 'c-hooks';
import equal from 'fast-deep-equal';
import { last, uniqWith } from 'ramda';
import { sortFilesBy } from 'c-components/FileBrowser/lib';
import { Checkbox } from 'c-components/Forms';
import apiClient from 'c-data/apiClient';
import {
    FileBrowserFile,
    FileBrowserLocalSearchFormData,
    FileBrowserOrderBy,
    FileBrowserOrderState,
    FileBrowserSelectState,
    FileFilterStats,
    ViewOptionProps,
} from './types';
import FileBrowserGrid from './FileBrowserGridView';
import FileBrowserListView from './FileBrowserListView/FileBrowserListView';
import FileBrowserCarouselView from './FileBrowserCarouselView';
import FilePreviewDialog from './FilePreviewDialog';
import FileBrowserTagFilterWrapper from './FileBrowserTagFilter';
import FileBrowserOrdering from './FileBrowserOrdering';

type Props = {
    files: FileBrowserFile[];
    enableListView?: boolean;
    enableGridView?: boolean;
    enableCarouselView?: boolean;
    beforeControls?: React.ReactNode;
    enableLocalFilters?: boolean;
    generateDownloadUrl?: (files: FileBrowserFile[]) => string;
    showSelectedFileInDialog?: boolean;
};

const ListView = 'list';
const GridView = 'grid';
const CarouselView = 'carousel';

const defaultGridView = ({
    enableListView,
    enableGridView,
    enableCarouselView,
}: Partial<Props>) => {
    if (enableListView) return ListView;
    if (enableGridView) return GridView;
    if (enableCarouselView) return CarouselView;

    return null;
};

const defaultDownloadUrlGen: Props['generateDownloadUrl'] = files =>
    apiClient.Entities.Creative.downloadLink(files.map(f => f.downloadId));

const FileBrowser: React.FC<Props> = ({
    files,
    enableGridView = true,
    enableListView = true,
    enableCarouselView = true,
    beforeControls,
    enableLocalFilters = false,
    generateDownloadUrl = defaultDownloadUrlGen,
    showSelectedFileInDialog = false,
}) => {
    const [view, setView] = useState(() =>
        defaultGridView({
            enableGridView,
            enableListView,
            enableCarouselView,
        }),
    );
    const onListBtnClick = useCallback(() => setView(ListView), [setView]);
    const onGridBtnClick = useCallback(() => setView(GridView), [setView]);
    const onCarouselBtnClick = useCallback(() => setView(CarouselView), [setView]);

    const [focusedFileId, setFocusedFileId] = useState<FileBrowserFile['id']>(null);
    const [filterData, setFilterData] = useState<FileBrowserLocalSearchFormData>({
        fileTypes: [],
        resolutions: [],
        aspectRatios: [],
        term: '',
    });
    const [orderBy, setOrderBy] = useState<FileBrowserOrderState>({
        direction: 'desc',
        column: FileBrowserOrderBy.UploadDate,
    });

    const [selectedFiles, setSelectedFiles] = useState<FileBrowserFile[]>([]);

    useEffect(() => {
        // unset currently 'focused' file when switching views
        setFocusedFileId(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view]);

    const enabledViewCount = useMemo(() => {
        let count = 0;
        if (enableGridView) count += 1;
        if (enableListView) count += 1;
        if (enableCarouselView) count += 1;

        return count;
    }, [enableCarouselView, enableGridView, enableListView]);

    const isMobile = useIsMobileView();

    const ListWrapper = useMemo<React.FC<PropsWithChildren>>(
        () =>
            ({ children }: PropsWithChildren<any>) =>
                (
                    <Box display="flex" flex={1}>
                        {children}
                    </Box>
                ),
        [],
    );

    const onFileClick = useCallback(
        (file: number) => {
            setFocusedFileId(val => (val === file ? null : file));
        },
        [setFocusedFileId],
    );

    const deselectFile = useCallback(() => {
        setFocusedFileId(null);
    }, [setFocusedFileId]);

    const focusedFile = useMemo(
        () => files.find(f => f.id === focusedFileId),
        [focusedFileId, files],
    );

    const hideInlineFilePreview = useMemo(() => {
        if (isMobile) {
            return true;
        }

        return showSelectedFileInDialog && focusedFile != null;
    }, [focusedFile, isMobile, showSelectedFileInDialog]);

    const [filteredFileIds, setFilteredFileIds] = useState<number[]>([]);
    const onFilterUpdate = useCallback(
        (fileIds: number[], data: FileBrowserLocalSearchFormData) => {
            setFilteredFileIds(currentVal => (!equal(currentVal, fileIds) ? fileIds : currentVal));
            setFilterData(currentVal => (!equal(data, currentVal) ? data : currentVal));
        },
        [setFilteredFileIds, setFilterData],
    );

    const shownFiles = useMemo(() => {
        if (enableLocalFilters) {
            return sortFilesBy(
                files.filter(f => filteredFileIds.indexOf(f.id) !== -1),
                orderBy.column,
                orderBy.direction,
            );
        }
        return files;
    }, [enableLocalFilters, files, filteredFileIds, orderBy]);

    const stats = useMemo<FileFilterStats>(
        () => ({
            total: files.length,
            shown: shownFiles.length,
            hidden: Math.abs(files.length - shownFiles.length),
        }),
        [files, shownFiles],
    );

    useEffect(() => {
        if (focusedFileId != null && !shownFiles.some(f => f.id === focusedFileId)) {
            // unset currently 'focused' file to null because its no longer in the file browser
            setFocusedFileId(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shownFiles]);

    const onFileToggled = useCallback<ViewOptionProps['onFileToggled']>(
        (file: FileBrowserFile, e: React.MouseEvent) => {
            setSelectedFiles(val => {
                const previouslySelectedFile = last(val);
                const prevSelectedIndex = shownFiles.findIndex(
                    f => f.id === previouslySelectedFile?.id,
                );
                const currentlySelected = val.some(v => v.id === file.id);

                if (
                    !e?.shiftKey ||
                    previouslySelectedFile == null ||
                    previouslySelectedFile?.id === file.id ||
                    // doing this check because the previously selected file could be in a different folder/page or something
                    prevSelectedIndex === -1
                ) {
                    if (!currentlySelected) return [...val, file];
                    return val.filter(v => v.id !== file.id);
                }

                const selectedFileIndex = shownFiles.findIndex(f => f.id === file.id);
                const filesInBetween = shownFiles.slice(
                    Math.min(prevSelectedIndex, selectedFileIndex),
                    Math.max(selectedFileIndex, prevSelectedIndex) + 1,
                );

                const withoutBetween = val.reduce((currFiles, file) => {
                    if (!filesInBetween.some(f => f.id === file.id)) currFiles.push(file);

                    return currFiles;
                }, []);

                if (!currentlySelected) {
                    // select everything between the last selected file and the file just clicked on
                    return [...withoutBetween, ...filesInBetween];
                }

                return withoutBetween;
            });
        },
        [shownFiles],
    );
    const viewProps = useMemo<ViewOptionProps>(
        () => ({
            filters: filterData,
            files: shownFiles,
            ListWrapper,
            onFileClick,
            // hiding the focused file from each file browser view to simplify logic within each one of them
            focusedFile: !hideInlineFilePreview ? focusedFile : undefined,
            onFileToggled,
            selectedFiles,
        }),
        [
            filterData,
            shownFiles,
            ListWrapper,
            onFileClick,
            hideInlineFilePreview,
            focusedFile,
            onFileToggled,
            selectedFiles,
        ],
    );

    const selectAllState = useMemo(() => {
        if (selectedFiles.length === 0) return FileBrowserSelectState.Unchecked;

        const filesLength = files.length;
        // if this file browser is implemented in a paginated way, then some selected files
        // may not be currently visible
        const selectedVisible = selectedFiles.reduce((acc, curr) => {
            if (files.some(f => f.id === curr.id)) acc.push(curr);
            return acc;
        }, []);

        if (selectedVisible.length === filesLength) return FileBrowserSelectState.Checked;

        return FileBrowserSelectState.Indeterminate;
    }, [selectedFiles, files]);

    const onSelectAllToggle = useCallback(() => {
        if (
            selectAllState === FileBrowserSelectState.Unchecked ||
            selectAllState === FileBrowserSelectState.Indeterminate
        ) {
            // select all current available files in browser
            setSelectedFiles(val => {
                return uniqWith((a, b) => a.id === b.id, [...val, ...shownFiles]);
            });
        } else {
            setSelectedFiles(val => {
                // if 'select all' is checked, remove all current files in browser
                return val.filter(f => !shownFiles.some(f1 => f1.id == f.id));
            });
        }
    }, [selectAllState, shownFiles]);

    const selectAllFiles = useMemo(
        () => (
            <Box pl={3.5}>
                <Checkbox
                    onClick={onSelectAllToggle}
                    checked={selectAllState === FileBrowserSelectState.Checked}
                    indeterminate={selectAllState === FileBrowserSelectState.Indeterminate}
                    label=""
                    formControlLabelSx={{ mr: 0 }}
                />
            </Box>
        ),
        [onSelectAllToggle, selectAllState],
    );

    const downloadBtn = useMemo(() => {
        if (selectedFiles.length > 0) {
            return (
                <IconButton
                    href={generateDownloadUrl(selectedFiles)}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    target={'_blank' as any}
                    size="small"
                >
                    <Download />
                </IconButton>
            );
        }
        return null;
    }, [generateDownloadUrl, selectedFiles]);

    const prependedControls = useMemo(
        () => (
            <Box display="flex">
                {selectAllFiles}
                {downloadBtn}
            </Box>
        ),
        [selectAllFiles, downloadBtn],
    );

    return (
        <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
            {enabledViewCount <= 1 && (
                <Box display="flex">
                    {!enableLocalFilters && prependedControls}
                    {beforeControls}
                </Box>
            )}
            {enabledViewCount > 1 && (
                <Grid
                    container
                    spacing={2}
                    sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
                >
                    {!enableLocalFilters && <Grid item>{prependedControls}</Grid>}
                    {beforeControls}
                    {enableLocalFilters && (
                        <FileBrowserTagFilterWrapper
                            files={files}
                            onChange={onFilterUpdate}
                            stats={stats}
                        />
                    )}
                    {enableLocalFilters && (
                        <Grid item sx={{ mr: 'auto', my: 'auto', flex: 1 }}>
                            <FileBrowserOrdering
                                state={orderBy}
                                setState={setOrderBy}
                                prependedControls={prependedControls}
                            />
                        </Grid>
                    )}
                    <Grid item>
                        <Grid container>
                            {enableListView && (
                                <Grid item xs>
                                    <ToggleButton
                                        className="view-list"
                                        onClick={onListBtnClick}
                                        active={view === ListView}
                                    >
                                        <ViewList />
                                    </ToggleButton>
                                </Grid>
                            )}
                            {enableGridView && (
                                <Grid item xs>
                                    <ToggleButton
                                        className="view-grid"
                                        onClick={onGridBtnClick}
                                        active={view === GridView}
                                    >
                                        <Apps />
                                    </ToggleButton>
                                </Grid>
                            )}
                            {enableCarouselView && (
                                <Grid item xs>
                                    <ToggleButton
                                        onClick={onCarouselBtnClick}
                                        active={view === CarouselView}
                                    >
                                        <ViewCarousel />
                                    </ToggleButton>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Box mt={1} flex={1} display="flex" overflow="hidden">
                {view === ListView && <FileBrowserListView {...viewProps} />}
                {view === GridView && <FileBrowserGrid {...viewProps} />}
                {view === CarouselView && <FileBrowserCarouselView {...viewProps} />}
            </Box>
            <FilePreviewDialog
                file={focusedFile}
                open={focusedFile != null && hideInlineFilePreview}
                close={deselectFile}
            />
        </Box>
    );
};

const ToggleButton: React.FC<{ active: boolean } & IconButtonProps> = ({
    active,
    children,
    ...props
}) => (
    <IconButton sx={{ color: `grey.${active ? '700' : '500'}` }} {...props}>
        {children}
    </IconButton>
);

export default FileBrowser;
