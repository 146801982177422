import React, { useMemo } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { TabsWrapper } from 'c-components';
import { TabWrapperSettings } from 'c-types';
import Forms from './Forms';
import TypographyExamples from './TypographyExamples';

type Props = {
    //
};

const SkinEditKitchenSink: React.FC<Props> = () => {
    const tabs = useMemo<TabWrapperSettings['tabs']>(
        () => [
            {
                title: 'Modules.Admin.SkinEdit.form.formTabTitle',
                content: (
                    <Card>
                        <Box p={2}>
                            <CardContent>
                                <Forms />
                            </CardContent>
                        </Box>
                    </Card>
                ),
            },
            {
                title: 'Modules.Admin.SkinEdit.form.typographyTabTitle',
                content: (
                    <Card>
                        <Box p={2}>
                            <CardContent>
                                <TypographyExamples />
                            </CardContent>
                        </Box>
                    </Card>
                ),
            },
        ],
        [],
    );

    return (
        <Box mt={2}>
            <TabsWrapper tabs={tabs} />
        </Box>
    );
};

export default SkinEditKitchenSink;
