import React, { Ref } from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

type Props = MuiLinkProps & RouterLinkProps;

const MuiRouterLink = (props: Props, ref: Ref<any>) => (
    <MuiLink component={RouterLink} ref={ref} {...props} />
);

export default React.forwardRef(MuiRouterLink);
