import React, { useEffect } from 'react';
import { CardContent } from '@mui/material';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { Translate } from 'c-translation';
import { PermissionName, Campaign } from '@uniled/api-sdk';
import { campaignIcon } from 'c-main/icons';
import { useEntityPage } from 'c-hooks';
import { campaignName } from 'c-main/Lib';
import { useEntityData } from 'c-data';
import { generatePath } from 'react-router-dom';
import { Alert, TransparentCard } from 'c-components';
// eslint-disable-next-line import/no-cycle
import { EditCampaignLineItems } from 'c-main/Components';
import ReactGA from 'react-ga';
import useCampaignWrapperState from 'c-main/Components/Campaign/CampaignReports/useCampaignWrapperState';

const UpdateCampaignLineItemsPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { id } = useEntityPage();
    const { getById } = useEntityData<Campaign>('Campaign');
    const { source } = useCampaignWrapperState(id);

    const campaign = getById({ id });

    return (
        <TransparentCard>
            <CardContent>
                {campaign?.flags?.under_configuration === true ? (
                    <Alert severity="warning" variant="outlined">
                        <Translate path="Modules.Main.Campaigns.Overview.underConstructionWarning" />
                    </Alert>
                ) : (
                    <EditCampaignLineItems campaign={campaign} source={source} />
                )}
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageEntityConfig<Campaign> = {
    id: 'UpdateCampaignLineItemsPage',
    component: UpdateCampaignLineItemsPage,
    systemSearch: {
        title: 'Modules.Main.Campaigns.EditLineItems.metaTitle',
        type: 'entity',
        entityName: 'Campaign',
        icon: campaignIcon,
        // aliases: [],
        defaultIncludes: ['buyer', 'date', 'budgetSummary', 'budgetSummary.lineItems', 'lineItems'],
        route: PostAuthRoutes.Campaigns.UpdateCampaignLineItems,
        permissions: [
            PermissionName.UniledportalCampaignRead,
            PermissionName.UniledportalCampaignUpdate,
            PermissionName.UniledportalBudget_summary_line_itemRead,
            PermissionName.UniledportalBudget_summaryRead,
            PermissionName.UniledportalBudget_summary_line_itemRead,
            PermissionName.UniledportalDateUpdate,
        ],
        saveAsRecentPage: false,
        excludeFromSystemSearch: true,
        breadcrumbs: [
            {
                label: 'Modules.Main.Campaigns.List.metaTitle',
                link: PostAuthRoutes.Campaigns.CampaignList,
            },
        ],
        nameGen: () => null,
        generateEntityBreadcrumbs: (crumbs, t, entity, id) => [
            ...crumbs,
            {
                label: campaignName(entity),
                link: generatePath(PostAuthRoutes.Campaigns.CampaignOverview, { id }),
            },
            {
                label: 'Modules.Main.Campaigns.EditLineItems.editLineItemsBreadcrumb',
            },
        ],
    },
};

export default setup;
