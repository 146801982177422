import React, { useMemo } from 'react';
import { List } from '@mui/material';
import { DrawerNavigationItem } from 'c-wrapper/Types';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { useUserPermissions } from 'c-auth-module/Hooks';
import DrawerNavigationListItem from './DrawerNavigationListItem';

type Props = {
    items: DrawerNavigationItem[];
    isSubList?: boolean;
};

const DrawerNavigationGroup: React.FC<Props> = ({ items, isSubList = false }) => {
    const { hasAll } = useUserPermissions();
    const visibleChildren = useMemo(
        () => items.filter(item => hasAll(item.permissions)),
        [hasAll, items],
    );

    return (
        <List disablePadding={isSubList}>
            {visibleChildren.map(item => (
                <IfHasAllPermissions key={item.label} permissions={item.permissions}>
                    <DrawerNavigationListItem key={item.label} item={item} inSubList={isSubList} />
                </IfHasAllPermissions>
            ))}
        </List>
    );
};

export default React.memo(DrawerNavigationGroup);
