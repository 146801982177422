import React from 'react';
import { PCAReportEnergyByFieldResponse } from '@uniled/api-sdk';
import { AutoGrid, NumberFormat } from 'c-components';
import { Box, Typography, TypographyProps } from '@mui/material';

type Props = {
    campaignStats: PCAReportEnergyByFieldResponse['campaignStats'];
};
const gridItemProps = { sx: { display: 'flex', justifyContent: 'center' } };

const MetricPerformance: React.FC<Props> = ({ campaignStats }) => {
    return (
        <>
            <AutoGrid spacing={0} xs={12} md={4} GridItemProps={gridItemProps}>
                <Block
                    count={+campaignStats.plays}
                    countColor={
                        +campaignStats.plays_percentage >= 100 ? 'success.main' : 'warning.main'
                    }
                    label="Plays"
                />
                <Block count={+campaignStats.kilowatts} countColor="grey.600" label="kWh" />
                <Block count={+campaignStats.kgco2} countColor="grey.600" label="kgs CO2e" />
            </AutoGrid>
        </>
    );
};

type BlockProps = {
    count: number | string;
    countColor: TypographyProps['color'];
    label: string;
    percentage?: boolean;
};
const Block: React.FC<BlockProps> = ({ count, countColor, label, percentage = false }) => (
    <Box>
        <Typography variant="h2" color={countColor}>
            <NumberFormat value={count} />
        </Typography>
        <Typography variant="body1" color="grey.400" textAlign={{ xs: 'center', md: 'left' }}>
            {label}
        </Typography>
    </Box>
);

export default MetricPerformance;
