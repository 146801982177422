export enum PasswordRequirements {
    Match = 'match',
    Length = 'length',
    Number = 'number',
    LowerCase = 'lowerCase',
    UpperCase = 'upperCase',
    SpecialCharacter = 'specialCharacter',
}

export type PasswordStrengthErrors = PasswordRequirements[];
