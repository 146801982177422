import React, { forwardRef, Ref, useMemo, useRef } from 'react';
import {
    Box,
    BoxProps,
    FormControl,
    FormControlProps,
    FormHelperText,
    FormLabel,
    Menu,
} from '@mui/material';
import { RgbaStringColorPicker } from 'react-colorful';
import { useBoolean } from 'react-hanger';
import { ColorPickerBaseProps } from 'react-colorful/dist/types';
import { hexToRgbA } from 'c-lib';
import { TextField } from '../TextField';

type Props = {
    greyBackdrop?: boolean;
    whiteBackdrop?: boolean;
    value?: string;
    label?: React.ReactNode;
    helperText?: string;
    asDropdown?: boolean;
    dropdownHeight?: BoxProps['height'];
    dropdownWidth?: BoxProps['width'];
} & Omit<ColorPickerBaseProps<string>, 'color'> &
    FormControlProps;

const RgbaColorPicker = (
    {
        greyBackdrop = false,
        whiteBackdrop = false,
        value,
        label,
        fullWidth,
        error,
        helperText,
        asDropdown = false,
        dropdownHeight = 25,
        dropdownWidth = '100%',
        ...props
    }: Props,
    ref: Ref<any>,
) => {
    const actualValue = useMemo(() => (value?.startsWith('#') ? hexToRgbA(value) : value), [value]);
    const ThePicker = useMemo(
        () => (
            <FormControl fullWidth={fullWidth} error={error}>
                {label && <FormLabel>{label}</FormLabel>}
                <Box py={1}>
                    <RgbaStringColorPicker color={actualValue} {...props} />
                </Box>
                <Box py={1}>
                    <TextField
                        value={actualValue}
                        onChange={props.onChange as any}
                        whiteBackdrop={whiteBackdrop}
                        greyBackdrop={greyBackdrop}
                    />
                </Box>
                {error && <FormHelperText error>{helperText}</FormHelperText>}
            </FormControl>
        ),
        [fullWidth, error, label, props, helperText, whiteBackdrop, greyBackdrop, actualValue],
    );

    const open = useBoolean(false);
    const menuAnchor = useRef();

    if (asDropdown) {
        return (
            <>
                <Menu open={open.value} onClose={open.setFalse} anchorEl={menuAnchor?.current}>
                    <Box pl={3} pr={1}>
                        {ThePicker}
                    </Box>
                </Menu>
                <Box
                    ref={menuAnchor}
                    bgcolor={value}
                    height={dropdownHeight}
                    width={dropdownWidth}
                    onClick={props.disabled ? undefined : open.toggle}
                    sx={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
                />
            </>
        );
    }

    if (whiteBackdrop && !greyBackdrop) {
        return <Box bgcolor="white">{ThePicker}</Box>;
    }

    if (greyBackdrop) {
        return <Box bgcolor="grey.200">{ThePicker}</Box>;
    }

    return ThePicker;
};

export default forwardRef<any, Props>(RgbaColorPicker);
