import React, { Ref, useCallback, useMemo } from 'react';
import {
    FormControl,
    FormHelperText,
    FormLabel,
    ToggleButton,
    ToggleButtonGroup as MuiToggleButtonGroup,
    ToggleButtonGroupProps,
} from '@mui/material';
import { ToggleButtonOptionSchema } from '../formTypes';

type Props = {
    options: ToggleButtonOptionSchema[];
    onChange?: (newValue: any) => void;
    error?: boolean;
    helperText?: string;
    allowExclusiveDeselect?: boolean;
    label?: React.ReactNode;
} & Omit<ToggleButtonGroupProps, 'onChange'>;

const ToggleButtonGroup = (
    {
        options,
        error,
        helperText,
        onChange,
        allowExclusiveDeselect = false,
        exclusive,
        label,
        ...rest
    }: Props,
    ref: Ref<any>,
) => {
    const toggleOptions = useMemo(() => {
        return options.map(({ label, value, ...restProps }) => (
            <ToggleButton key={`${value}`} value={value} {...restProps}>
                {label}
            </ToggleButton>
        ));
    }, [options]);

    const handleChange = useCallback(
        (e, newValue) => {
            // exclusive means single value
            if (newValue == null && exclusive && !allowExclusiveDeselect) {
                return;
            }
            onChange(newValue);
        },
        [onChange, exclusive, allowExclusiveDeselect],
    );
    return (
        <FormControl error={error != null} component="fieldset" ref={ref}>
            {label && <FormLabel>{label}</FormLabel>}
            <MuiToggleButtonGroup exclusive={exclusive} onChange={handleChange} {...rest}>
                {toggleOptions}
            </MuiToggleButtonGroup>
            {error && <FormHelperText error>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default React.forwardRef(ToggleButtonGroup);
