import React, { useCallback, useMemo, useState } from 'react';
import { Displays_LineItem } from '@uniled/api-sdk';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import {
    AutoGrid,
    dataGridColumnsWithSpacedOutRendering,
    DataGridsPropsSpacedOut,
    FileBrowserFile,
    Filterdropdown,
    OptionSchema,
    TextField,
} from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { Box, InputAdornment, Typography, TypographyProps } from '@mui/material';
import { InfoOutlined, Search, WarningAmber } from '@mui/icons-material';
import { getLineItemResolutions } from 'c-main/Components/CreativeManagement/lib';
import { uniq } from 'ramda';
import { generateFileResolutionTag } from 'c-components/FileBrowser/lib';

type Props = {
    loading: boolean;
    lineItems: Displays_LineItem[];
    onSelectionUpdated: (ids: string[]) => void;
    selectedLineItems: string[];
    file: FileBrowserFile;
    canAction: boolean;
    alreadyAssignedLineItems?: string[];

    // if in unassign mode don't show info like if the line item is already assigned
    // don't prevent already assigned line items from being selectable
    unAssignMode?: boolean;
};

const emptyAlreadyAssigned = [];

type ListedLineItem = {
    id: string;
    name: string;
    owner: string;
    environment: string;
    size: string;
    resolutions: string[];
    fileTypes: string[];
};

const AssignmentLineItemsGrid: React.FC<Props> = ({
    loading,
    lineItems,
    onSelectionUpdated,
    selectedLineItems,
    file,
    canAction,
    alreadyAssignedLineItems = emptyAlreadyAssigned,
    unAssignMode = false,
}) => {
    const [search, setSearch] = useState('');
    const [resolutions, setResolutions] = useState([]);
    const [owners, setOwners] = useState([]);
    const [environments, setEnvironments] = useState([]);
    const [sizes, setSizes] = useState([]);

    const onSelectUpdate = useCallback<DataGridProProps['onRowSelectionModelChange']>(
        selectionModel => {
            onSelectionUpdated(selectionModel as string[]);
        },
        [onSelectionUpdated],
    );
    const lineItemResolutions = useMemo(() => getLineItemResolutions(lineItems), [lineItems]);

    const ownerOpts = useMemo<OptionSchema[]>(
        () =>
            uniq(lineItems.map(l => l.owner?.name ?? '')).map(name => ({
                label: name,
                value: name,
            })),
        [lineItems],
    );
    const envsOpts = useMemo<OptionSchema[]>(
        () => uniq(lineItems.map(l => l.environment)).map(env => ({ label: env, value: env })),
        [lineItems],
    );
    const sizeOpts = useMemo<OptionSchema[]>(
        () => uniq(lineItems.map(l => l.size)).map(size => ({ label: size, value: size })),
        [lineItems],
    );

    const resolutionOptions = useMemo<OptionSchema[]>(
        () =>
            lineItemResolutions.map(res => ({
                label: generateFileResolutionTag(res[0], res[1]),
                value: generateFileResolutionTag(res[0], res[1]),
            })),
        [lineItemResolutions],
    );

    const rows = useMemo<ListedLineItem[]>(
        () =>
            lineItems.map(item => ({
                id: item.id,
                name: item.name,
                environment: item.environment,
                size: item.size,
                resolutions:
                    item.resolutions?.map(res =>
                        generateFileResolutionTag(res.width, res.height),
                    ) ?? [],
                fileTypes: item.fileTypes?.map(type => type.type) ?? [],
                owner: item.owner?.name ?? '',
            })),
        [lineItems],
    );

    const t = useCommonTranslation();

    const columns = useMemo<DataGridProProps['columns']>(
        () =>
            dataGridColumnsWithSpacedOutRendering([
                {
                    field: 'name',
                    renderCell: params => (
                        <LineItemNameCell
                            unAssignMode={unAssignMode}
                            alreadyAssigned={
                                alreadyAssignedLineItems.indexOf(params.id as string) !== -1
                            }
                            isSelected={selectedLineItems.indexOf(params.id as string) !== -1}
                            label={params.value}
                            lineItem={params.row}
                            file={file}
                        />
                    ),

                    headerName: t(
                        'Modules.Main.CreativeManagement.creatives.assignDialog.lineItemNameCol',
                    ),
                    sortable: true,
                    flex: 1,
                    minWidth: 200,
                },
                {
                    valueGetter: params => params.row?.owner?.name,
                    field: 'owner',
                    headerName: t(
                        'Modules.Main.CreativeManagement.creatives.assignDialog.mediaOwnerCol',
                    ),
                    sortable: true,
                    minWidth: 250,
                },
                {
                    field: 'environment',
                    headerName: t(
                        'Modules.Main.CreativeManagement.creatives.assignDialog.environmentCol',
                    ),
                    sortable: true,
                    minWidth: 200,
                },
                {
                    field: 'size',
                    headerName: t('Modules.Main.CreativeManagement.creatives.assignDialog.sizeCol'),
                    minWidth: 100,
                },
                {
                    field: 'resolutions',
                    headerName: t(
                        'Modules.Main.CreativeManagement.creatives.assignDialog.resolutionsCol',
                    ),
                    minWidth: 150,
                    renderCell: params => params.value.join(', '),
                },
                {
                    field: 'fileTypes',
                    headerName: t(
                        'Modules.Main.CreativeManagement.creatives.assignDialog.fileTypesCol',
                    ),
                    minWidth: 200,
                    renderCell: params => params.value.join(', '),
                },
            ]),
        [t, alreadyAssignedLineItems, selectedLineItems, file, unAssignMode],
    );

    const filterModel = useMemo<DataGridProProps['filterModel']>(
        () => ({
            items: [
                { id: 1, field: 'name', operator: 'contains', value: search },
                { id: 2, field: 'owner', operator: 'isAnyOf', value: owners },
                {
                    id: 3,
                    field: 'environment',
                    operator: 'isAnyOf',
                    value: environments,
                },
                { id: 4, field: 'size', operator: 'isAnyOf', value: sizes },
                {
                    id: 5,
                    field: 'resolutions',
                    operator: 'isAnyOf',
                    value: resolutions,
                },
            ],
        }),
        [search, owners, environments, sizes, resolutions],
    );

    const props = useMemo<Partial<DataGridProProps>>(
        () => ({
            disableColumnResize: false,
            checkboxSelection: true,
            hideFooter: true,
            columns,
            rows,
            rowHeight: 60,
            isRowSelectable: params =>
                alreadyAssignedLineItems.indexOf(params.id as string) === -1 && canAction,
        }),
        [columns, rows, alreadyAssignedLineItems, canAction],
    );

    const onSearchChange = useCallback(e => {
        setSearch(e.target.value);
    }, []);

    return (
        <Box flex={1} height="100%" display="flex" flexDirection="column" overflow="hidden" pt={1}>
            <AutoGrid gap={2} xs={12} md={4} lg>
                <TextField
                    value={search}
                    size="small"
                    variant="outlined"
                    onChange={onSearchChange}
                    placeholder={useCommonTranslation(
                        'Modules.Main.CreativeManagement.creatives.assignDialog.searchLabel',
                    )}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search fontSize="inherit" />
                            </InputAdornment>
                        ),
                    }}
                />
                <Filterdropdown
                    selectAllOption
                    multiple
                    options={ownerOpts}
                    onChange={setOwners}
                    value={owners}
                    placeholder={useCommonTranslation(
                        'Modules.Main.CreativeManagement.creatives.assignDialog.mediaOwnerDropdownLabel',
                    )}
                />
                <Filterdropdown
                    selectAllOption
                    multiple
                    options={envsOpts}
                    onChange={setEnvironments}
                    value={environments}
                    placeholder={useCommonTranslation(
                        'Modules.Main.CreativeManagement.creatives.assignDialog.environmentDropdownLabel',
                    )}
                />
                <Filterdropdown
                    selectAllOption
                    multiple
                    options={sizeOpts}
                    onChange={setSizes}
                    value={sizes}
                    placeholder={useCommonTranslation(
                        'Modules.Main.CreativeManagement.creatives.assignDialog.sizeDropdownLabel',
                    )}
                />
                <Filterdropdown
                    selectAllOption
                    multiple
                    options={resolutionOptions}
                    onChange={setResolutions}
                    value={resolutions}
                    placeholder={useCommonTranslation(
                        'Modules.Main.CreativeManagement.creatives.assignDialog.resolutionDropdownLabel',
                    )}
                />
            </AutoGrid>
            <Box flex={1} overflow="hidden">
                <DataGridPro
                    loading={loading}
                    {...DataGridsPropsSpacedOut(props)}
                    onRowSelectionModelChange={onSelectUpdate}
                    rowSelectionModel={selectedLineItems}
                    filterModel={filterModel}
                />
            </Box>
        </Box>
    );
};

const LineItemNameCell: React.FC<{
    alreadyAssigned: boolean;
    isSelected: boolean;
    label: string;
    lineItem: ListedLineItem;
    file: FileBrowserFile;
    unAssignMode?: boolean;
}> = ({ alreadyAssigned, isSelected, label, lineItem, file, unAssignMode }) => {
    const t = useCommonTranslation();

    // just want to know if at least one resolution matches
    const resMatch = useMemo(
        () =>
            lineItem.resolutions.length === 0 ||
            lineItem.resolutions.reduce((currentMatch, res) => {
                if (currentMatch === true) return currentMatch; // matched against at least one resolution already
                return res === generateFileResolutionTag(file.width, file.height);
            }, false),
        [lineItem, file],
    );

    const showResMatchError = useMemo(
        () => (isSelected || alreadyAssigned) && !resMatch && !unAssignMode,
        [isSelected, resMatch, alreadyAssigned, unAssignMode],
    );

    return (
        <Typography noWrap variant="body2" component={Box}>
            <b>{label}</b>
            <AutoGrid gap={1} flexWrap="nowrap">
                {alreadyAssigned && (
                    <LineItemWarning
                        color="info.main"
                        icon={<InfoOutlined fontSize="inherit" />}
                        label={t(
                            'Modules.Main.CreativeManagement.creatives.assignDialog.lineItemAlreadyAssigned',
                        )}
                    />
                )}
                {showResMatchError && (
                    <LineItemWarning
                        color="warning.main"
                        icon={<WarningAmber fontSize="inherit" />}
                        label={t(
                            'Modules.Main.CreativeManagement.creatives.assignDialog.lineItemResolutionMismatch',
                        )}
                    />
                )}
            </AutoGrid>
        </Typography>
    );
};

const LineItemWarning: React.FC<{
    color: TypographyProps['color'];
    icon: React.ReactNode;
    label: string;
}> = ({ color, icon, label }) => (
    <Typography color={color} variant="caption" component={Box}>
        <Box display="flex" alignItems="flex-start">
            {icon} <Box ml={0.25}>{label}</Box>
        </Box>
    </Typography>
);

export default AssignmentLineItemsGrid;
