import { endOfDay, isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';
import { Campaign } from '@uniled/api-sdk';
import { isoDateNoTimeDiff } from 'c-lib';

export const isCampaignArchived = (campaign: Campaign) => campaign?.progress?.archived === true;

export const isCampaignUpcoming = (campaign: Campaign) =>
    isAfter(startOfDay(isoDateNoTimeDiff(campaign?.date?.date_start)), startOfDay(new Date()));

export const isCampaignLive = (campaign: Campaign) =>
    (isBefore(isoDateNoTimeDiff(campaign?.date?.date_start), startOfDay(new Date())) ||
        isSameDay(isoDateNoTimeDiff(campaign?.date?.date_start), startOfDay(new Date()))) &&
    (isAfter(isoDateNoTimeDiff(campaign?.date?.date_end), endOfDay(new Date())) ||
        isSameDay(isoDateNoTimeDiff(campaign?.date?.date_end), endOfDay(new Date())));

export const isCampaignPast = (campaign: Campaign) =>
    !isCampaignLive(campaign) &&
    isBefore(isoDateNoTimeDiff(campaign?.date?.date_end), endOfDay(new Date()));
