import React, { useCallback, useContext, useMemo } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { FormContext } from '../FormWrapper';

type AsProps = { error?: boolean; helperText?: string };

type NativeInputs = 'input' | 'select' | 'textarea';

type Props<T> = Omit<ControllerProps<T>, 'render'> & {
    // this is most useful for hidden fields or when
    as?:
        | React.ComponentType<AsProps>
        | React.ComponentType<any>
        | React.ReactElement
        | NativeInputs;
    render?: ControllerProps<any>['render'];
    id?: string;
};

function generateFieldId(name: string, id?: string, formId?: string): string {
    if (id) return id;

    if (formId) {
        return `${formId}-${name}`;
    }

    return name;
}

/**
 * Currently a placeholder component which will have juicy functionality added to it
 * @param props
 * @constructor
 */
function ControlledFormInput<T = any>({ name, id, render, as, defaultValue, ...rest }: Props<T>) {
    const { formId } = useContext(FormContext);
    const fieldId = useMemo(() => generateFieldId(name, id, formId), [name, id, formId]);

    const renderAs: ControllerProps<any>['render'] = useCallback(
        ({ field, fieldState }) => {
            const componentProps = {
                ...field,
                id: fieldId,
                error: fieldState.error != null,
                helperText: fieldState.error?.message,
            };

            if (componentProps.value == null && defaultValue != null) {
                componentProps.value = defaultValue;
            }

            if (React.isValidElement(as)) {
                return React.cloneElement(as, componentProps);
            }

            return React.createElement(as as NativeInputs, componentProps as any);
        },
        [as, defaultValue, fieldId],
    );

    return (
        <Controller
            defaultValue={defaultValue}
            render={render || renderAs}
            name={name}
            {...(rest as any)}
        />
    );
}

export default ControlledFormInput;
