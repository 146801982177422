import React from 'react';
import { useCommonTranslation } from 'c-translation';
import { useEntityData } from 'c-data';
import { campaignName } from 'c-main/Lib';
import { Campaign } from '@uniled/api-sdk';

type Props = {
    campaignId: number;
};

const CampaignName: React.FC<Props> = ({ campaignId }) => {
    const defaultTitle = useCommonTranslation('Modules.Main.Campaigns.Overview.pageTitle');
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id: campaignId });
    return <>{campaign ? campaignName(campaign) : defaultTitle}</>;
};

export default CampaignName;
