import {
    clientActions,
    clientAdapter,
    clientThunks as defaultThunks,
    clientSelectors as defaultSelectors,
    clientReducer,
} from './Client-slice';
import * as thunks from './Client-thunks';
import * as selectors from './Client-selectors';

const clientThunks = { ...defaultThunks, ...thunks };
const clientSelectors = { ...defaultSelectors, ...selectors };

export { default as ClientSlice } from './Client-slice';
export { clientSelectors, clientActions, clientAdapter, clientThunks, clientReducer };
