import { useEntityData } from 'c-data';
import { useCallback, useEffect, useMemo } from 'react';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { CampaignError, CampaignErrorableType, CampaignErrorType, Campaign } from '@uniled/api-sdk';

function useCampaignErrors(id: number) {
    const { getById, upsertEntity } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });

    const { start: GetErrors } = useAPIClientRequest(apiClient.Entities.Campaign.errors);
    const fetchCampaignErrors = useCallback(async () => {
        const [, resp] = await to(GetErrors(id));

        if (Array.isArray(resp?.data?.data)) {
            // partial upsert
            // will only override the provided keys
            upsertEntity({
                id,
                errors: resp?.data?.data,
                created_at: campaign.created_at,
                updated_at: campaign.updated_at,
            } as Campaign);
        }
    }, [id, GetErrors, upsertEntity, campaign]);

    useEffect(() => {
        if (campaign != null && !Array.isArray(campaign?.errors)) {
            // if (campaign != null) {
            fetchCampaignErrors();
        }
    }, [campaign, fetchCampaignErrors]);

    const errors = useMemo<CampaignError[]>(() => campaign?.errors ?? [], [campaign]);

    const getErrors = useCallback(
        ({
            entityIds,
            entityTypes,
            errorTypes,
        }: {
            entityIds?: (string | number)[];
            entityTypes?: CampaignErrorableType[];
            errorTypes?: CampaignErrorType[];
        }) => {
            const expectedMatchCount = [
                entityIds != null,
                entityTypes != null,
                errorTypes != null,
            ].filter(val => val === true).length;

            return errors.filter(err => {
                const matchId = entityIds
                    ? entityIds.map(id => String(id)).indexOf(err.errorable_id as any) !== -1
                    : true;
                const matchEntityType =
                    entityTypes && entityTypes.indexOf(err.errorable_type) !== -1;
                const matchErrorType = errorTypes && errorTypes.indexOf(err.type) !== -1;

                const matchCount = [matchId, matchEntityType, matchErrorType].filter(
                    val => val === true,
                ).length;

                return matchCount >= expectedMatchCount;
            });
        },

        [errors],
    );

    return {
        campaign,
        errors,
        getErrors,
    };
}

export default useCampaignErrors;
