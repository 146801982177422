import React, { useMemo } from 'react';
import { Box, List, ListSubheader } from '@mui/material';
import { compareAsc, parse } from 'date-fns';
import { Button } from 'c-components';
import { useDateUtils } from 'c-hooks';
import { User } from 'c-entity-types';
import { ChatMessage } from '../../types';
import { groupByFormat, groupMessages } from '../../lib';
import Message from './Message';

type Props = {
    localUser: User;
    messages: ChatMessage[];
};
const MessageList: React.FC<Props> = ({ localUser, messages }) => {
    const groupedMessages = useMemo(() => groupMessages(messages), [messages]);
    const orderedKeys = useMemo(
        () =>
            Object.keys(groupedMessages).sort((a, b) => {
                const aDate = parse(a, groupByFormat, new Date());
                const bDate = parse(b, groupByFormat, new Date());

                return compareAsc(aDate, bDate);
            }),
        [groupedMessages],
    );

    const { formatDateString } = useDateUtils();

    const feed = useMemo(
        () =>
            orderedKeys.reduce(
                (acc, dateKey) => [
                    ...acc,
                    <ListSubheader key={dateKey}>
                        <Box display="flex" justifyContent="center">
                            <Button variant="outlined" disabled>
                                {formatDateString({
                                    date: parse(dateKey, groupByFormat, new Date()),
                                    removeTime: true,
                                })}
                            </Button>
                        </Box>
                    </ListSubheader>,
                    groupedMessages?.[dateKey]?.map(mes => (
                        <Message
                            key={mes.id}
                            isMe={mes.createdBy?.id === localUser.id}
                            message={mes}
                        />
                    )),
                ],
                [],
            ),
        [orderedKeys, groupedMessages, formatDateString, localUser],
    );

    return <List>{feed}</List>;
};

export default MessageList;
