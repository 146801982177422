import React, { useCallback, useMemo, useRef } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { PermissionName, Campaign } from '@uniled/api-sdk';
import {
    Box,
    CircularProgress,
    ClickAwayListener,
    IconButton,
    Paper,
    Popper,
    Typography,
} from '@mui/material';
import { useUpdateEntityData } from 'c-data';
import { NetworkRequestState } from '@uniled/data-layer';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { isValid, parse } from 'date-fns';
import { useDateUtils } from 'c-hooks';
import { AutoGrid, TextField } from 'c-components';
import { AddCircle, Close } from '@mui/icons-material';
import { useBoolean } from 'react-hanger';
import { StaticDatePicker } from '@mui/x-date-pickers-pro';
import { remove } from 'ramda';

type Props = RenderColumnDataProps<Campaign>;

const adminPerms = [PermissionName.Admin];

const CampaignPlannerAwareCell: React.FC<Props> = ({ entity }) => {
    const { update, getUpdatingById } = useUpdateEntityData<Campaign>('Campaign');
    const loading = useMemo(
        () => getUpdatingById(entity?.id).state === NetworkRequestState.InProgress,
        [entity?.id, getUpdatingById],
    );

    const { dayMonthYearApiFormat, apiFormatDateString } = useDateUtils();
    const dates = useMemo<Campaign['internal_planner_aware']>(() => {
        if (Array.isArray(entity?.internal_planner_aware)) {
            return entity?.internal_planner_aware.filter(date =>
                isValid(parse(date, dayMonthYearApiFormat, new Date())),
            );
        }

        return [];
    }, [entity, dayMonthYearApiFormat]);

    const btnRef = useRef();
    const { value: isOpen, setTrue: open, setFalse: close } = useBoolean(false);

    const onSave = useCallback(
        (newValue: Date) => {
            update(entity.id, {
                id: entity.id,
                internal_planner_aware: [...dates, apiFormatDateString({ date: newValue })],
            } as Campaign);
            close();
        },
        [update, entity.id, dates, apiFormatDateString, close],
    );

    const onRemoveDate = useCallback(
        (index: number) => {
            update(entity.id, {
                id: entity.id,
                internal_planner_aware: remove(index, 1, dates),
            } as Campaign);
        },
        [dates, update, entity.id],
    );

    return (
        <>
            <AutoGrid xs={12} rowGap={1}>
                {dates.map((d, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <DateRow key={`${d}-${i}`} date={d} removeDate={onRemoveDate} index={i} />
                ))}
                <IfHasAllPermissions permissions={adminPerms}>
                    <Box display="flex" justifyContent="center">
                        <IconButton size="small" ref={btnRef} onClick={open} disabled={loading}>
                            {loading ? <CircularProgress size={15} /> : <AddCircle />}
                        </IconButton>
                    </Box>
                </IfHasAllPermissions>
            </AutoGrid>

            <Popper open={isOpen} anchorEl={btnRef.current}>
                <ClickAwayListener onClickAway={close}>
                    <Paper elevation={2}>
                        <Box>
                            <StaticDatePicker
                                displayStaticWrapperAs="desktop"
                                openTo="day"
                                value={null}
                                onChange={onSave}
                            />
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    );
};

type RowProps = {
    index: number;
    date: string;
    removeDate: (index: number) => void;
};

const DateRow: React.FC<RowProps> = ({ date, index, removeDate }) => {
    const { formatDateString } = useDateUtils();
    const onClick = useCallback(() => {
        removeDate(index);
    }, [removeDate, index]);
    return (
        <Typography noWrap display="flex" alignItems="center">
            {formatDateString({ date })}
            <IconButton size="small" onClick={onClick}>
                <Close fontSize="inherit" />
            </IconButton>
        </Typography>
    );
};

export default CampaignPlannerAwareCell;
