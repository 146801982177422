import { Displays_Market } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { Displays_MarketState } from './Displays_Market-types';

const initialState: Displays_MarketState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: Displays_MarketState, action: PayloadAction<string>): Displays_MarketState => state,
};

const slice = createBaseSlice<Displays_Market, Displays_MarketState, typeof additionalReducers>({
    name: 'displays_Market',
    entityName: 'Displays_Market',
    initialState,
    reducers: additionalReducers,
});

const displays_MarketSlice = {
    displays_MarketActions: slice.slice.actions,
    displays_MarketReducer: slice.slice.reducer,
    displays_MarketAdapter: slice.adapter,
    displays_MarketSelectors: slice.selectors,
    displays_MarketThunks: slice.thunks,
};

export default displays_MarketSlice;
export const {
    displays_MarketReducer,
    displays_MarketActions,
    displays_MarketAdapter,
    displays_MarketSelectors,
    displays_MarketThunks,
} = displays_MarketSlice;
