import React, { useCallback, useEffect, useState } from 'react';
import { FilterConfig, FilterConfigRendererProps } from 'c-pagination';
import { useCommonTranslation } from 'c-translation';
import { TextField } from 'c-components';
import { useDebounce } from 'use-debounce';

type Props = FilterConfigRendererProps<FilterConfig>;

const TextFilter: React.FC<Props> = ({ config, onChange, value, id, className }) => {
    /**
     * We're assuming that each date filter will only have a maximum of a single value
     */
    const [localVal, setLocalVal] = useState(String(value?.[0] ?? ''));
    const onDateChange = useCallback(e => {
        setLocalVal(e.target.value);
    }, []);

    const [debouncedValue] = useDebounce(localVal, 1000);

    useEffect(() => {
        if (debouncedValue?.trim()?.length === 0) onChange([]);
        else onChange([debouncedValue]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    useEffect(() => {
        // update to the latest value
        // temp disabling because it resulted in the textfield value updating as you were typing if a search finished
        // in the middle of typing

        // resetting the only if the value is emptied which would probably happen as a result of resetting
        if (value?.[0] === '' || value?.[0] == null) setLocalVal(String(value?.[0] ?? ''));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <>
            <TextField
                value={localVal}
                label={useCommonTranslation(config.label)}
                size="small"
                onChange={onDateChange}
                id={id}
                className={className}
            />
        </>
    );
};

export default TextFilter;
