import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UserType } from '@uniled/api-sdk';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { Translate, useCommonTranslation } from 'c-translation';
import { Button, FlashMessage, ToggleButtonGroup, ToggleButtonOptionSchema } from 'c-components';
import { useBoolean, usePrevious } from 'react-hanger';
import { useAPIClientRequest, useUserImpersonation } from 'c-hooks';
import { NetworkRequestState } from '@uniled/data-layer';

import apiClient from 'c-data/apiClient';
import to from 'await-to-js';

type Props = {
    id: number;
};

const userByIdIncludes = ['availableUserTypes'];

const ImpersonateUserSection: React.FC<{ userId: number; success: NetworkRequestState }> = ({
    userId,
    success,
}) => {
    const { start, startImpersonatingLoadingState, isImpersonating } = useUserImpersonation();
    const [selectedUserType, setSelectedUserType] = useState<UserType>();
    const {
        start: LoadUser,
        data: UserData,
        isLoading: IsUserLoading,
    } = useAPIClientRequest(apiClient.Entities.User.get);
    const user = useMemo(() => UserData?.data?.data, [UserData]);
    const loadUser = useCallback(async () => {
        await to(LoadUser(userId, userByIdIncludes));
    }, [LoadUser, userId]);
    useEffect(() => {
        if (success === NetworkRequestState.Success) {
            loadUser();
        }
    }, [loadUser, success]);

    useEffect(() => {
        setSelectedUserType(null);
        if (userId != null) {
            loadUser();
        }
    }, [userId, loadUser]);

    useEffect(() => {
        if (user?.id === userId && user?.availableUserTypes?.length === 1) {
            setSelectedUserType(user.availableUserTypes[0]);
        }
    }, [user, userId]);

    const onClickStart = useCallback(() => {
        start(userId, selectedUserType);
    }, [userId, start, selectedUserType]);

    const prevState = usePrevious(startImpersonatingLoadingState.state);
    const hasSuccessfullyImpersonated = useBoolean(false);

    useEffect(() => {
        if (
            prevState === NetworkRequestState.InProgress &&
            startImpersonatingLoadingState.state === NetworkRequestState.Success
        ) {
            hasSuccessfullyImpersonated.setTrue();
        }
        if (
            prevState === NetworkRequestState.Success &&
            startImpersonatingLoadingState.state === NetworkRequestState.Idle
        ) {
            hasSuccessfullyImpersonated.setFalse();
        }
    }, [startImpersonatingLoadingState.state, prevState, hasSuccessfullyImpersonated]);

    const t = useCommonTranslation();
    const availableUserTypes = useMemo(
        () => user?.availableUserTypes ?? [],
        [user?.availableUserTypes],
    );
    const possibleImpersonate = useMemo(() => {
        if (user?.properties?.verified && user?.active && !user?.deleted) {
            return true;
        }
        return false;
    }, [user]);
    const showUserTypeChoice = useMemo(() => availableUserTypes.length > 1, [availableUserTypes]);

    const availableUserTypeOptions = useMemo<ToggleButtonOptionSchema[]>(
        () =>
            availableUserTypes.map(type => ({
                label: t(`Pages.Login.userTypes.${type}`),
                value: type,
            })),
        [availableUserTypes, t],
    );

    if (isImpersonating) {
        return (
            <FlashMessage status="error">
                <Translate path="Modules.Admin.ImpersonateUser.isAlreadyImpersonatingError" />
            </FlashMessage>
        );
    }

    return (
        <Box>
            {UserData && !IsUserLoading && (
                <Box>
                    {availableUserTypeOptions.length > 0 && possibleImpersonate && (
                        <Grid container spacing={2}>
                            {showUserTypeChoice && (
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <Typography
                                        variant="subtitle1"
                                        color="textSecondary"
                                        sx={{ mb: 1 }}
                                    >
                                        <Translate path="Modules.Admin.ImpersonateUser.chooseUserType" />
                                    </Typography>
                                    <ToggleButtonGroup
                                        exclusive
                                        color="primary"
                                        options={availableUserTypeOptions}
                                        onChange={setSelectedUserType}
                                        value={selectedUserType}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <Button
                                    disabled={
                                        selectedUserType == null ||
                                        IsUserLoading ||
                                        startImpersonatingLoadingState.state ===
                                            NetworkRequestState.InProgress
                                    }
                                    onClick={onClickStart}
                                >
                                    {t('Modules.Admin.ImpersonateUser.startImpersonateButton', {
                                        type: selectedUserType
                                            ? t(`Pages.Login.userTypes.${selectedUserType}`)
                                            : '',
                                    })}
                                </Button>
                            </Grid>
                            {hasSuccessfullyImpersonated.value && (
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <FlashMessage status="success">
                                        <Translate path="Modules.Admin.ImpersonateUser.impersonateSuccess" />
                                    </FlashMessage>
                                </Grid>
                            )}
                            {startImpersonatingLoadingState.state === NetworkRequestState.Error && (
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <FlashMessage status="error">
                                        {startImpersonatingLoadingState.error ?? (
                                            <Translate path="Modules.Admin.ImpersonateUser.genericFailureMessage" />
                                        )}
                                    </FlashMessage>
                                </Grid>
                            )}
                        </Grid>
                    )}
                    {availableUserTypeOptions.length === 0 ||
                        (!possibleImpersonate && (
                            <Typography align="center">
                                {t('Modules.Admin.ImpersonateUser.noimpersonate')}
                            </Typography>
                        ))}
                </Box>
            )}
            {IsUserLoading && (
                <Box p={2} display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default ImpersonateUserSection;
