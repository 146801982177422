import {
    roleActions,
    roleAdapter,
    roleThunks as defaultThunks,
    roleSelectors as defaultSelectors,
    roleReducer,
} from './Role-slice';
import * as thunks from './Role-thunks';
import * as selectors from './Role-selectors';

const roleThunks = { ...defaultThunks, ...thunks };
const roleSelectors = { ...defaultSelectors, ...selectors };

export { default as RoleSlice } from './Role-slice';
export { roleSelectors, roleActions, roleAdapter, roleThunks, roleReducer };
