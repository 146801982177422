import {
    displaysLineItemsActions,
    displaysLineItemsAdapter,
    displaysLineItemsThunks as defaultThunks,
    displaysLineItemsSelectors as defaultSelectors,
    displaysLineItemsReducer,
} from './DisplaysLineItems-slice';
import * as thunks from './DisplaysLineItems-thunks';
import * as selectors from './DisplaysLineItems-selectors';

const displaysLineItemsThunks = { ...defaultThunks, ...thunks };
const displaysLineItemsSelectors = { ...defaultSelectors, ...selectors };

export { default as DisplaysLineItemsSlice } from './DisplaysLineItems-slice';
export {
    displaysLineItemsSelectors,
    displaysLineItemsActions,
    displaysLineItemsAdapter,
    displaysLineItemsThunks,
    displaysLineItemsReducer,
};
