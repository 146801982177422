import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Stack, Tab, Typography } from '@mui/material';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { Displays_Screen, PermissionName } from '@uniled/api-sdk';
import { useEntityPage } from 'c-hooks';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useCommonTranslation } from 'c-translation';
import { useForm } from 'react-hook-form';

import { useUpdateEntityData } from 'c-data';
import { FilterableEntityTable, FilterableEntityTableRendererType } from 'c-pagination';
import {
    displayEditPacksColumns,
    displayEditTagColumns,
} from 'c-displays/Components/DisplaysEntityColumns';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import { VendorIDs } from 'c-displays/Components/VendorIDs';
import ReactGA from 'react-ga';
import { removeEmptyProperties } from 'c-pagination/Components/EntityFilters/utils';
import { generatePath } from 'react-router-dom';

import Audience from 'c-displays/Pages/DisplaysDisplayEdit/Audience';
import { DisplayForm } from './DisplayForm';
import { OperationalTimings } from '../../Components/OperationalTimings';

const textSearchCols = ['id'];

const DisplaysDisplayEditPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const t = useCommonTranslation();
    const { entity, id } = useEntityPage<Displays_Screen>();
    const [value, setValue] = React.useState('1');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const paginationTagForTags = `displays-displayEdit-admin-Tags-list-${id}`;
    const paginationTagForPacks = `displays-displayEdit-admin-Packs-list-${id}`;

    const { update, getUpdatingById } = useUpdateEntityData('Displays_Screen');
    const { state, error } = useMemo(() => getUpdatingById(id), [getUpdatingById, id]);
    const defaultValues = useMemo(
        () => ({
            ...entity,
            market_id: entity?.market_id || '',
            partner_id: entity?.partner_id || '',
            owner_id: entity?.mediaOwners[0]?.id || '',
            filetypes: entity?.fileTypes.map(file => file.type) ?? [],
        }),
        [entity],
    );
    // const { start, data } = useAPIClientRequest(ApiClient.Entities.Displays_AudienceUpload.list);
    // useEffect(() => {
    //     start({
    //         // includes: ['audienceSource'],
    //         filters: {
    //             'filter.display_id': '1234569931',
    //             'filter.audience_version_id': 1,
    //             'filter.valid_from': ['>=2021-09-01'],
    //         },
    //     });
    // }, [start, id]);
    const handleFormSubmit = useCallback(
        data => {
            const dataWithNewChanges = removeEmptyProperties(data);
            delete dataWithNewChanges.fileTypes;
            delete dataWithNewChanges.mediaOwners;
            update(id, dataWithNewChanges as any);
        },
        [id, update],
    );

    const {
        handleSubmit,
        control,
        getValues,
        setValue: setFormValue,
        watch,
    } = useForm({
        defaultValues,
    });

    const operationalTimingsDisplay = useMemo(() => {
        const operationalTimes = entity.operationalTimes;
        return (
            <OperationalTimings
                operationalTimings={operationalTimes}
                id={id}
                entityName="Displays_Screen"
            />
        );
    }, [entity.operationalTimes, id]);

    const VendorIDsDisplay = useMemo(() => {
        const vendorIds = entity.displaysVendorIds;
        return <VendorIDs id={id} vendorIds={vendorIds} entity={entity} />;
    }, [entity, id]);
    return (
        <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange}>
                        <Tab
                            label={t('Modules.Displays.DisplaysDisplayEdit.tabs.displayTab')}
                            value="1"
                        />
                        <Tab
                            label={t(
                                'Modules.Displays.DisplaysDisplayEdit.tabs.operationalTimingsTab',
                            )}
                            value="2"
                        />
                        <Tab
                            label={t('Modules.Displays.DisplaysDisplayEdit.tabs.tagTab')}
                            value="3"
                        />
                        <Tab
                            label={t('Modules.Displays.DisplaysDisplayEdit.tabs.packsTab')}
                            value="4"
                        />
                        <Tab
                            label={t('Modules.Displays.DisplaysDisplayEdit.tabs.vendorTab')}
                            value="5"
                        />
                        <Tab
                            label={t('Modules.Displays.DisplaysDisplayEdit.tabs.audience')}
                            value="6"
                        />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Box pt={2}>
                        <Stack direction="row" pb={2} gap={4} alignItems="center">
                            <Typography variant="h1">
                                {t('Modules.Displays.DisplaysDisplayEdit.description')}
                            </Typography>
                            <Typography variant="h4">{entity.address}</Typography>
                        </Stack>
                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                            <DisplayForm
                                control={control}
                                state={state}
                                error={error}
                                setFormValue={setFormValue}
                                id={id}
                                getValues={getValues}
                                entity={entity}
                                watch={watch}
                            />
                        </form>
                    </Box>
                </TabPanel>
                <TabPanel value="2">{operationalTimingsDisplay}</TabPanel>
                <TabPanel value="3">
                    <>
                        {entity.tags.length > 0 && (
                            <FullPageEntityTableWrapper title="Modules.Displays.DisplaysDisplayEdit.tabs.tagTab">
                                <FilterableEntityTable
                                    key={paginationTagForTags}
                                    tag={paginationTagForTags}
                                    columns={displayEditTagColumns}
                                    onRowClick={id => {
                                        const rout = generatePath(
                                            PostAuthRoutes.Displays.DisplaysTagEdit,
                                            { id },
                                        );
                                        window.open(rout, '_blank');
                                    }}
                                    baseEntityName="Displays_Tag"
                                    textSearchColumns={textSearchCols}
                                    rendererType={FilterableEntityTableRendererType.Contained}
                                    routeTemplate={PostAuthRoutes.Displays.DisplaysTagEdit}
                                    direction="desc"
                                    filters={{
                                        'filter.id': entity.tags.map(tag => tag.id),
                                    }}
                                    showFilters
                                    includes={[
                                        'screensCount',
                                        'operationalTimeScreensCount',
                                        'activeScreensCount',
                                        'inactiveScreensCount',
                                        'tags',
                                    ]}
                                />
                            </FullPageEntityTableWrapper>
                        )}
                        {entity.tags.length === 0 && (
                            <Typography>
                                {t('Modules.Displays.DisplaysDisplayEdit.tabs.emptyTagTab')}{' '}
                            </Typography>
                        )}
                    </>
                </TabPanel>
                <TabPanel value="4">
                    <>
                        {entity.packs.length > 0 && (
                            <FullPageEntityTableWrapper title="Modules.Displays.DisplaysDisplayEdit.tabs.packsTab">
                                <FilterableEntityTable
                                    key={paginationTagForPacks}
                                    tag={paginationTagForPacks}
                                    columns={displayEditPacksColumns}
                                    onRowClick={id => {
                                        const rout = generatePath(
                                            PostAuthRoutes.Displays.DisplaysPackEdit,
                                            { id },
                                        );
                                        window.open(rout, '_blank');
                                    }}
                                    baseEntityName="Displays_Pack"
                                    textSearchColumns={textSearchCols}
                                    rendererType={FilterableEntityTableRendererType.Contained}
                                    direction="desc"
                                    showFilters
                                    filters={{
                                        'filter.id': entity.packs.map(pack => pack.id),
                                    }}
                                    includes={[
                                        'country',
                                        'activeDisplaysCount',
                                        'inactiveDisplaysCount',
                                        'resolutions',
                                        'market',
                                    ]}
                                />
                            </FullPageEntityTableWrapper>
                        )}
                        {entity.packs.length === 0 && (
                            <Typography>
                                {t('Modules.Displays.DisplaysDisplayEdit.tabs.emptyPacksTab')}
                            </Typography>
                        )}
                    </>
                </TabPanel>
                <TabPanel value="5">{VendorIDsDisplay}</TabPanel>
                <TabPanel value="6">
                    <Audience
                        audienceVersions={entity?.audienceVersion}
                        frameId={entity.frame_id}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    );
};

const setup: PageEntityConfig<Displays_Screen> = {
    id: 'DisplaysDisplayEditPage',
    component: DisplaysDisplayEditPage,
    systemSearch: {
        title: 'Modules.Displays.DisplaysDisplayEdit.metaTitle',
        description: 'Modules.Displays.DisplaysDisplayEdit.description',
        icon: undefined,
        type: 'entity',
        aliases: undefined,
        route: PostAuthRoutes.Displays.DisplaysDisplayEdit,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.Displays.AdminHome.metaTitle',
                link: PostAuthRoutes.Displays.DisplaysAdminHome,
            },
            {
                label: 'Modules.Displays.DisplaysList.metaTitle',
                link: PostAuthRoutes.Displays.DisplaysList,
            },
        ],
        entityName: 'Displays_Screen',
        nameGen: (entity, id) => String(id),
        permissions: [PermissionName.Admin],
        defaultIncludes: [
            'energy_conversion_override',
            'energy_uses_per_second',
            'fileTypes',
            'tags',
            'operationalTimes',
            'packs',
            'mediaOwners',
            'markets',
            'resolutions',
            'displaysVendorIds',
            'owner',
            'audienceVersion',
        ],
    },
};
export default setup;
