/* eslint-disable react/no-unused-prop-types */
import {
    Dialog as MuiDialog,
    DialogProps as MuiDialogProps,
    DialogActions,
    DialogContent,
    DialogContentProps as MuiDialogContentProps,
    DialogContentText,
    DialogTitle,
    Box,
} from '@mui/material';
import React, { useState } from 'react';
import { TranslationPath as CommonTranslationPath, useCommonTranslation } from 'c-translation';
import { AnyObjectSchema } from 'yup';
import { SubmitHandler, UseFormProps } from 'react-hook-form';
import FormWrapper from 'c-components/Forms/FormWrapper';
import { SubmitButton, Button } from '../../Forms';

type DialogProps = {
    MuiDialogProps?: Partial<MuiDialogProps>;
    DialogContentProps?: Partial<MuiDialogContentProps>;
    MuiDialogContentProps?: Partial<MuiDialogProps>;
    title?: CommonTranslationPath | React.ReactNode;
    description?: CommonTranslationPath | React.ReactNode;
    onSubmit?: SubmitHandler<any>;
    cancelButtonId?: string;
    saveButtonId?: string;
    submitButtonId?: string;
    deleteButtonId?: string;
    submitDisabled?: boolean;
    submitLabel?: CommonTranslationPath;
    saveLabel?: CommonTranslationPath;
    cancelLabel?: CommonTranslationPath;
    deleteLabel?: CommonTranslationPath | React.ReactNode;
    onClose: MuiDialogProps['onClose'];
    onSave?: () => void;
    onDelete?: () => void;
    cancelDisabled?: boolean;
    saveDisabled?: boolean;
    deleteDisabled?: boolean;
    show: boolean;
    validationSchema?: AnyObjectSchema;
    content: JSX.Element;
    formOptions?: UseFormProps<any>;
    removeForm?: boolean;
    beforeActions?: React.ReactNode;
    afterActions?: React.ReactNode;
};

const Content = ({
    description,
    content,
    onSubmit,
    onClose,
    onSave,
    onDelete,
    validationSchema,
    cancelButtonId = 'dialog-cancel',
    saveButtonId = 'dialog-save',
    submitButtonId = 'dialog-submit',
    deleteButtonId = 'dialog-delete',
    cancelLabel = 'Dialog.defaultCancelLabel' as any,
    submitLabel = 'Dialog.defaultSubmitLabel' as any,
    saveLabel = 'Dialog.defaultSubmitLabel' as any,
    deleteLabel = 'Dialog.defaultDeleteLabel' as any,
    cancelDisabled,
    submitDisabled,
    saveDisabled,
    deleteDisabled,
    removeForm,
    formOptions,
    beforeActions,
    afterActions,
    MuiDialogContentProps,
}: DialogProps) => {
    const t = useCommonTranslation();
    const descriptionText = typeof description === 'string' ? t(description) : description;
    const [formId] = useState(Math.random().toString());
    const saveLabelTranslated = useCommonTranslation(saveLabel);
    const deleteLabelTranslated = typeof deleteLabel === 'string' ? t(deleteLabel) : deleteLabel;
    const formContent = (
        <>
            <DialogContent dividers {...MuiDialogContentProps}>
                {description && <DialogContentText>{descriptionText}</DialogContentText>}
                {content}
            </DialogContent>
            <DialogActions>
                {beforeActions && beforeActions}
                {onDelete && (
                    <Box mr="auto">
                        <Button
                            variant="text"
                            color="error"
                            onClick={onDelete}
                            disabled={deleteDisabled}
                            id={deleteButtonId}
                            className="close-modal"
                        >
                            {deleteLabelTranslated}
                        </Button>
                    </Box>
                )}
                <Button
                    variant="text"
                    onClick={onClose as any}
                    disabled={cancelDisabled}
                    id={cancelButtonId}
                    className="close-modal"
                >
                    {useCommonTranslation(cancelLabel)}
                </Button>

                {onSubmit && (
                    <SubmitButton
                        label={submitLabel}
                        disabled={submitDisabled}
                        id={submitButtonId}
                        form={formId}
                        className={submitDisabled ? 'disabled' : ''}
                    />
                )}
                {onSave && (
                    <Button
                        variant="text"
                        onClick={onSave}
                        disabled={saveDisabled}
                        id={saveButtonId}
                        className={saveDisabled ? 'disabled' : ''}
                    >
                        {saveLabelTranslated}
                    </Button>
                )}

                {afterActions && afterActions}
            </DialogActions>
        </>
    );
    if (removeForm) return formContent;
    return (
        <FormWrapper
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            formId={formId}
            formOptions={formOptions}
        >
            {formContent}
        </FormWrapper>
    );
};
const Dialog = ({ MuiDialogProps, ...props }: DialogProps) => {
    const t = useCommonTranslation();
    return (
        <MuiDialog
            className="open-modal"
            open={props.show}
            onClose={props.onClose}
            maxWidth="sm"
            scroll="paper"
            fullWidth
            {...MuiDialogProps}
        >
            {props.title && (
                <DialogTitle>
                    {typeof props.title === 'string' ? t(props.title) : props.title}
                </DialogTitle>
            )}
            <Content {...props} />
        </MuiDialog>
    );
};

export default Dialog;
