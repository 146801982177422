// private static string $EQ = '=';
// private static string $NOT = '<>';
// private static string $LT = '<';
// private static string $LTE = '<=';
// private static string $GT = '>';
// private static string $GTE = '>=';
// private static string $NULL = 'null';

const NOT = '<>';
const LTE = '<=';
const GTE = '>=';
const LT = '<';
const GT = '>';

const operators = [NOT, LTE, GTE, GT, LT];

/**
 * Return [value, operator]
 * @param value
 * @constructor
 */
export const splitValueAndOperator = (value: string): [string, string] => {
    const operator = operators.find(op => value.startsWith(op));

    if (operator != null) {
        return [value.substr(operator.length), operator];
    }

    return [value, null];
    // return operators.reduce(
    //     (operator, [value, op]) => {
    //         return [value, op];
    //     },
    //     [value, null],
    // );
};

export const removeEmptyProperties = obj => {
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const key in obj) {
        if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
            // eslint-disable-next-line no-param-reassign
            delete obj[key];
        }
    }
    return obj;
};
