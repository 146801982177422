import { Filter } from '../types';
import splitTerms from './splitTerms';

function shouldOverwriteFilters(term: string, configFilters: Filter[]): boolean {
    const splitTerm = splitTerms(term);
    let shouldOverwriteFilter = false;

    if (splitTerm.length === 1) {
        configFilters.forEach((configFilter: Filter) => {
            if (`${configFilter.prefix}:` === term) {
                shouldOverwriteFilter = true;
            }
        });
    }

    return shouldOverwriteFilter;
}

export default shouldOverwriteFilters;
