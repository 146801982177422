import React, { useMemo } from 'react';
import { FileBrowserFile, FileIconType } from 'c-components/FileBrowser/types';
import { Description, Image, PictureAsPdf, VideoCameraFront } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { getFileType } from './lib';

type Props = {
    file: FileBrowserFile;
} & SvgIconProps;

const FileIcon: React.FC<Props> = ({ file, ...props }) => {
    const fileType = useMemo(() => getFileType(file), [file]);
    const Icon: React.FC<SvgIconProps> = useMemo(() => {
        switch (fileType) {
            case FileIconType.Video:
                return VideoCameraFront;
            case FileIconType.PDF:
                return PictureAsPdf;
            case FileIconType.Image:
                return Image;
            case FileIconType.File:
            default:
                return Description;
        }
    }, [fileType]);

    return <Icon {...props} />;
};

export default FileIcon;
