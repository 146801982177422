import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { difference, uniq } from 'ramda';
import { CampaignFormNames } from 'c-main/Types';

const selectedFormKey = 'lineItemsShowSelected';
function useSelectedLineItems() {
    const { watch, setValue } = useFormContext();

    const value = watch(CampaignFormNames.lineItems);
    const originalLineItems = watch(CampaignFormNames.originalLineItems);
    const actualValue = useMemo(() => (Array.isArray(value) ? value : []), [value]);
    const add = useCallback(
        (ids: string[]) => {
            const newValue = uniq([...actualValue, ...ids]);
            const diff = difference(ids ?? [], actualValue ?? []).length;
            setValue(CampaignFormNames.lineItems, newValue);

            return diff;
        },
        [setValue, actualValue],
    );
    const remove = useCallback(
        id =>
            setValue(
                CampaignFormNames.lineItems,
                actualValue.filter(v => v !== id),
            ),
        [setValue, actualValue],
    );

    const isSelected = useCallback(id => actualValue.indexOf(id) !== -1, [actualValue]);

    const showSelected = watch(selectedFormKey) ?? false;
    const toggleShowSelected = useCallback(() => {
        setValue(selectedFormKey, !showSelected);
    }, [setValue, showSelected]);

    return {
        value: actualValue,
        add,
        remove,
        isSelected,

        showSelected,
        toggleShowSelected,
        originalLineItems: (originalLineItems as number[]) ?? [],
    };
}

export default useSelectedLineItems;
