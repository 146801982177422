import React, { useCallback, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useCommonTranslation } from 'c-translation';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { Alert, AutoGrid, Button, DateField, SelectField, TimePickerField } from 'c-components';
import { Controller, useForm } from 'react-hook-form';
import { addYears, format, parseISO, startOfDay } from 'date-fns';
import { Displays_OperationalTime } from '@uniled/api-sdk';
import { useEntityData } from 'c-data';
import { Box, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

type Props = {
    id: number;
    entityName: 'Displays_Pack' | 'Displays_Screen' | 'Displays_Tag';
    operationalTimings: Displays_OperationalTime[];
};

const getDayNameFromDate = (dateStr: string): string => {
    if (!dateStr) return '';
    const date = parseISO(dateStr);
    return format(date, 'EEEE');
};

const groupTimingsByDay = (
    timings: Displays_OperationalTime[],
): Record<string, Displays_OperationalTime[]> =>
    timings.reduce(
        (acc: Record<string, Displays_OperationalTime[]>, timing: Displays_OperationalTime) => {
            const key = timing.day || getDayNameFromDate(timing.date);
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(timing);
            return acc;
        },
        {},
    );

const filterTimings = (
    timings: Displays_OperationalTime[],
    filter: string,
): Displays_OperationalTime[] => {
    switch (filter) {
        case 'Monday':
        case 'Tuesday':
        case 'Wednesday':
        case 'Thursday':
        case 'Friday':
        case 'Saturday':
        case 'Sunday':
            return timings.filter(timing => {
                const day = timing.day || getDayNameFromDate(timing.date);
                return day === filter;
            });
        case 'Weekdays':
            // eslint-disable-next-line no-case-declarations
            const weekdays = new Set(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']);
            return timings.filter(timing => {
                const day = timing.day || getDayNameFromDate(timing.date);
                return weekdays.has(day);
            });
        case 'Weekends':
            return timings.filter(timing => {
                const day = timing.day || getDayNameFromDate(timing.date);
                return ['Saturday', 'Sunday'].includes(day);
            });
        case 'Everyday':
            return timings;
        default:
            return timings;
    }
};

const sortDays = (days: string[]): string[] => {
    const order = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return days.sort((a, b) => order.indexOf(a) - order.indexOf(b));
};

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const OperationalTimings = ({ operationalTimings, id, entityName }: Props) => {
    const { control, handleSubmit, watch, reset } = useForm();
    const selectedOption = watch('type');
    const [filter, setFilter] = useState('Everyday');
    const [formVisible, setFormVisible] = useState(true);
    const t = useCommonTranslation();

    const {
        start: createStart,
        hasFailed: hasCreateFailed,
        error: createError,
        isLoading: isCreateLoading,
    } = useAPIClientRequest(apiClient.Entities[entityName].createOperationalHours);
    const {
        start: deleteStart,
        error: deleteError,
        isLoading: isDeleteLoading,
    } = useAPIClientRequest(apiClient.Entities[entityName].deleteOperationalHours);
    const { upsertEntity } = useEntityData(entityName);

    const deleteButtonHandler = useCallback(
        (operationalHourId: number) => async () => {
            const [error, response] = await to(
                deleteStart(id, operationalHourId, ['operationalTimes']),
            );
            if (response && response?.data?.data?.id === id) {
                upsertEntity(response?.data?.data);
            }
        },
        [id, deleteStart, upsertEntity],
    );

    const createOpTime = useCallback(
        data => async () => {
            const [error, response] = await to(createStart(id, [data], ['operationalTimes']));
            if (response && response?.data?.data?.id === id) {
                upsertEntity(response?.data?.data);
            }

            reset();
            setFormVisible(false);
        },
        [id, createStart, reset, upsertEntity],
    );

    const onSubmit = data => {
        const newData = { ...data };
        if (newData.type === 'recurring') {
            delete newData.date;
        }
        if (newData.type === 'schedule') {
            delete newData.day;
        }
        createOpTime(newData)();
    };

    const handleFormSubmit = event => {
        event.preventDefault();
        handleSubmit(onSubmit)();
    };

    const table = useMemo(() => {
        const filteredTimings = filterTimings(operationalTimings, filter);
        const groupedByDay = groupTimingsByDay(filteredTimings);
        const sortedDays = sortDays(Object.keys(groupedByDay));
        return (
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Modules.Displays.OperationalTimings.day')}</TableCell>
                            <TableCell>{t('Modules.Displays.OperationalTimings.type')}</TableCell>
                            <TableCell>
                                {t('Modules.Displays.OperationalTimings.startTime')}
                            </TableCell>
                            <TableCell>
                                {t('Modules.Displays.OperationalTimings.endTime')}
                            </TableCell>
                            <TableCell align="right">
                                {t('Modules.Displays.OperationalTimings.action')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedDays.map(day => (
                            <React.Fragment key={day}>
                                <TableRow>
                                    <TableCell colSpan={5} sx={{ fontWeight: 'bold' }}>
                                        {day}
                                    </TableCell>
                                </TableRow>
                                {groupedByDay[day].map(timing => (
                                    <TableRow key={timing.id}>
                                        <TableCell />
                                        <TableCell>
                                            {timing.type === 'recurring'
                                                ? t('Modules.Displays.OperationalTimings.recurring')
                                                : `${t(
                                                      'Modules.Displays.OperationalTimings.schedule',
                                                  )} - ${timing.date}`}
                                        </TableCell>
                                        <TableCell>{timing.start_time}</TableCell>
                                        <TableCell>{timing.end_time}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={deleteButtonHandler(timing.id)}
                                                disabled={isDeleteLoading}
                                            >
                                                <DeleteIcon
                                                    color={isDeleteLoading ? 'disabled' : 'error'}
                                                />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }, [operationalTimings, filter, t, deleteButtonHandler, isDeleteLoading]);

    return (
        <AutoGrid xs={12} gap={2} p={4}>
            <form onSubmit={handleFormSubmit}>
                <Controller
                    name="type"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <SelectField
                            {...field}
                            value={field.value}
                            options={[
                                {
                                    value: '',
                                    label: t('Modules.Displays.OperationalTimings.selectOption'),
                                },
                                {
                                    value: 'recurring',
                                    label: t('Modules.Displays.OperationalTimings.recurring'),
                                },
                                {
                                    value: 'schedule',
                                    label: t('Modules.Displays.OperationalTimings.schedule'),
                                },
                            ]}
                            fullWidth
                            label={t('Modules.Displays.OperationalTimings.selectOption')}
                        />
                    )}
                />

                {formVisible && selectedOption === 'recurring' && (
                    <AutoGrid gap={2} pt={2} pb={2} xs={3}>
                        <Controller
                            name="start_time"
                            control={control}
                            render={({ field }) => (
                                <TimePickerField
                                    {...field}
                                    value={field.value}
                                    onChange={value => field.onChange(value)}
                                    inputProps={{
                                        label: t('Modules.Displays.OperationalTimings.startTime'),
                                        fullWidth: true,
                                    }}
                                    sx={{ paddingRight: '2.5rem' }}
                                    format="HH:mm"
                                    minutesStep={1}
                                />
                            )}
                        />
                        <Controller
                            name="end_time"
                            control={control}
                            render={({ field }) => (
                                <TimePickerField
                                    {...field}
                                    value={field.value}
                                    onChange={value => field.onChange(value)}
                                    inputProps={{
                                        label: t('Modules.Displays.OperationalTimings.endTime'),
                                        fullWidth: true,
                                    }}
                                    sx={{ paddingRight: '2.5rem' }}
                                    format="HH:mm"
                                    minutesStep={1}
                                />
                            )}
                        />
                        <Controller
                            name="day"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <SelectField
                                    {...field}
                                    value={field.value}
                                    options={[
                                        {
                                            value: 'Everyday',
                                            label: t(
                                                'Modules.Displays.OperationalTimings.everyday',
                                            ),
                                        },
                                        {
                                            value: 'Weekdays',
                                            label: t(
                                                'Modules.Displays.OperationalTimings.weekdays',
                                            ),
                                        },
                                        {
                                            value: 'Weekends',
                                            label: t(
                                                'Modules.Displays.OperationalTimings.weekends',
                                            ),
                                        },
                                        {
                                            value: 'Monday',
                                            label: t('Modules.Displays.OperationalTimings.monday'),
                                        },
                                        {
                                            value: 'Tuesday',
                                            label: t('Modules.Displays.OperationalTimings.tuesday'),
                                        },
                                        {
                                            value: 'Wednesday',
                                            label: t(
                                                'Modules.Displays.OperationalTimings.wednesday',
                                            ),
                                        },
                                        {
                                            value: 'Thursday',
                                            label: t(
                                                'Modules.Displays.OperationalTimings.thursday',
                                            ),
                                        },
                                        {
                                            value: 'Friday',
                                            label: t('Modules.Displays.OperationalTimings.friday'),
                                        },
                                        {
                                            value: 'Saturday',
                                            label: t(
                                                'Modules.Displays.OperationalTimings.saturday',
                                            ),
                                        },
                                        {
                                            value: 'Sunday',
                                            label: t('Modules.Displays.OperationalTimings.sunday'),
                                        },
                                    ]}
                                    fullWidth
                                    label={t('Modules.Displays.OperationalTimings.chooseADay')}
                                />
                            )}
                        />
                    </AutoGrid>
                )}
                {formVisible && selectedOption === 'schedule' && (
                    <AutoGrid gap={2} pt={2} pb={2} xs={3}>
                        <Controller
                            name="date"
                            control={control}
                            defaultValue=""
                            render={({ field }) => {
                                const currentDate = startOfDay(new Date());
                                const minDate = currentDate;
                                const maxDate = addYears(currentDate, 1);
                                return (
                                    <DateField
                                        {...field}
                                        inputProps={{
                                            fullWidth: true,
                                        }}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="start_time"
                            control={control}
                            render={({ field }) => (
                                <TimePickerField
                                    {...field}
                                    value={field.value}
                                    onChange={value => field.onChange(value)}
                                    inputProps={{
                                        label: t('Modules.Displays.OperationalTimings.startTime'),
                                        fullWidth: true,
                                    }}
                                    sx={{ paddingRight: '2.5rem' }}
                                    format="HH:mm"
                                    minutesStep={1}
                                />
                            )}
                        />
                        <Controller
                            name="end_time"
                            control={control}
                            render={({ field }) => (
                                <TimePickerField
                                    {...field}
                                    value={field.value}
                                    onChange={value => field.onChange(value)}
                                    inputProps={{
                                        label: t('Modules.Displays.OperationalTimings.endTime'),
                                        fullWidth: true,
                                    }}
                                    sx={{ paddingRight: '2.5rem' }}
                                    format="HH:mm"
                                    minutesStep={1}
                                />
                            )}
                        />
                    </AutoGrid>
                )}
                {formVisible && selectedOption && (
                    <Button type="submit" disabled={isCreateLoading}>
                        {t('Modules.Displays.OperationalTimings.submit')}
                    </Button>
                )}
            </form>
            <Box display="flex" justifyContent="space-between" mb={2} mt={2}>
                <Button
                    onClick={() => setFilter('Everyday')}
                    style={{
                        backgroundColor: filter === 'Everyday' ? 'gray' : 'initial',
                        color: filter === 'Everyday' ? 'white' : 'initial',
                    }}
                >
                    {t('Modules.Displays.OperationalTimings.everyday')}
                </Button>
                <Button
                    onClick={() => setFilter('Weekdays')}
                    style={{
                        backgroundColor: filter === 'Weekdays' ? 'gray' : 'initial',
                        color: filter === 'Weekdays' ? 'white' : 'initial',
                    }}
                >
                    {t('Modules.Displays.OperationalTimings.weekdays')}
                </Button>
                <Button
                    onClick={() => setFilter('Weekends')}
                    style={{
                        backgroundColor: filter === 'Weekends' ? 'gray' : 'initial',
                        color: filter === 'Weekends' ? 'white' : 'initial',
                    }}
                >
                    {t('Modules.Displays.OperationalTimings.weekends')}
                </Button>
                {daysOfWeek.map(day => (
                    <Button
                        key={day}
                        onClick={() => setFilter(day)}
                        style={{
                            backgroundColor: filter === day ? 'gray' : 'initial',
                            color: filter === day ? 'white' : 'initial',
                        }}
                    >
                        {t(`Modules.Displays.OperationalTimings.${day.toLowerCase()}`)}
                    </Button>
                ))}
            </Box>
            {table}
            {createError && (
                <Alert severity="error" variant="outlined">
                    {String(createError)}
                </Alert>
            )}
            {deleteError && (
                <Alert severity="error" variant="outlined">
                    {String(deleteError)}
                </Alert>
            )}
        </AutoGrid>
    );
};

export default OperationalTimings;
