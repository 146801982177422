import React, { useCallback, useMemo, useRef } from 'react';
import { BarChart, KeyboardArrowDown } from '@mui/icons-material';
import { DataStatusFilterProps } from './types';
import { useBoolean } from 'react-hanger';
import { useCommonTranslation } from 'c-translation';
import { Button, Checkbox } from 'c-components';
import { Menu, MenuItem } from '@mui/material';
import { CampaignNoticeKey } from '@uniled/api-sdk';

const DataStatusSelector: React.FC<DataStatusFilterProps> = ({
    dataStatus,
    onDataStatusChange,
    notices,
}) => {
    const btnRef = useRef();
    const openState = useBoolean(false);

    const toggleDataStatusValue = useCallback(
        (status: CampaignNoticeKey) => {
            onDataStatusChange(dataStatus === status ? undefined : status);
            openState.setFalse();
        },
        [dataStatus, onDataStatusChange, openState],
    );

    const t = useCommonTranslation();
    const label = useMemo(() => {
        if (dataStatus === CampaignNoticeKey.AwaitingData)
            return t('Modules.Main.Campaigns.DataStatus.awaitingDataLabel');
        if (dataStatus === CampaignNoticeKey.InspectingData)
            return t('Modules.Main.Campaigns.DataStatus.inspectingDataLabel');
        if (dataStatus === CampaignNoticeKey.AwaitingBookingPlan)
            return t('Modules.Main.Campaigns.DataStatus.awaitingBookingPlanLabel');

        return t('Modules.Main.Campaigns.Overview.Reports.dataStatusDropdown');
    }, [dataStatus, t]);
    return (
        <>
            <Button
                ref={btnRef}
                onClick={openState.toggle}
                color={dataStatus != null ? 'primary' : 'secondary'}
            >
                <BarChart sx={{ mr: 0.5 }} />
                {label}
                <KeyboardArrowDown />
            </Button>
            <Menu anchorEl={btnRef.current} open={openState.value} onClose={openState.setFalse}>
                {notices.indexOf(CampaignNoticeKey.AwaitingData) !== -1 && (
                    <MenuItem>
                        <Checkbox
                            isBoolean
                            onChange={() => {
                                toggleDataStatusValue(CampaignNoticeKey.AwaitingData);
                            }}
                            checked={dataStatus === CampaignNoticeKey.AwaitingData}
                            label={t('Modules.Main.Campaigns.DataStatus.awaitingDataFilterLabel')}
                        />
                    </MenuItem>
                )}
                {notices.indexOf(CampaignNoticeKey.InspectingData) !== -1 && (
                    <MenuItem>
                        <Checkbox
                            isBoolean
                            onChange={() => {
                                toggleDataStatusValue(CampaignNoticeKey.InspectingData);
                            }}
                            checked={dataStatus === CampaignNoticeKey.InspectingData}
                            label={t('Modules.Main.Campaigns.DataStatus.inspectingDataFilterLabel')}
                        />
                    </MenuItem>
                )}
                {notices.indexOf(CampaignNoticeKey.AwaitingBookingPlan) !== -1 && (
                    <MenuItem>
                        <Checkbox
                            isBoolean
                            onChange={() => {
                                toggleDataStatusValue(CampaignNoticeKey.AwaitingBookingPlan);
                            }}
                            checked={dataStatus === CampaignNoticeKey.AwaitingBookingPlan}
                            label={t(
                                'Modules.Main.Campaigns.DataStatus.awaitingBookingPlanFilterLabel',
                            )}
                        />
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default DataStatusSelector;
