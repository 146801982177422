import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import {
    FilterableEntityTable,
    FilterableEntityTableRefAPI,
    FilterableEntityTableRendererType,
    generateColumn,
} from 'c-pagination';
import { useEntityData, usePaginatedEntityData } from 'c-data';
import { PermissionName, Displays_LineItem } from '@uniled/api-sdk';
import { useCommonTranslation } from 'c-translation';
import { useAtom, useAtomValue } from 'jotai';
import { usePrevious } from 'react-hanger';
import CampaignPopShotUpload from 'c-main/Components/Campaign/CampaignPopShot/CampaignPopShotUpload';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { DialogV2 } from 'c-components';
import apiClient from 'c-data/apiClient';
import { atom_campaignUploadArtworkOpen } from 'c-main/Pages/Campaign/CampaignOverviewPage/atoms';
import LineItemPopShotsCell from '../CampaignOverviewDisplays/LineItemPopShotsCell';
import LineItemOwnerCell from '../CampaignOverviewDisplays/LineItemOwnerCell';
import { atom_uploadPopShotDialog, atom_uploadPopShotDialogLineItem } from './atoms';
import PopShotLineItemsControlsCell from './PopShotLineItemsControlsCell';

type Props = {
    id: number;
};

const lineItemIncludes = ['owner', 'campaignPopShots', 'campaignPopShots.file'];

export const tableCols = [
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.ownerHeader',
        field: 'owner.name',
        entityName: 'Displays_LineItem',
        filterKeyPath: 'owner.id',
        permissions: [PermissionName.UniledportalOwnerRead],
        RenderCell: LineItemOwnerCell as any,
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.nameHeader',
        field: 'name',
        entityName: 'Displays_LineItem',
        orderable: true,
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.popshotsHeader',
        field: 'campaignPopShots',
        entityName: 'Displays_LineItem',
        RenderCell: LineItemPopShotsCell,
        permissions: [
            PermissionName.UniledportalCampaign_pop_shotsRead,
            PermissionName.UniledportalFileRead,
        ],
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.actionsHeader',
        field: 'actions',
        entityName: 'Displays_LineItem',
        RenderCell: PopShotLineItemsControlsCell,
    }),
];

const textSearchColumns = ['name'];
const tableProps = { spacedOutRows: true };
const CampaignPopShotsDisplays: React.FC<Props> = ({ id }) => {
    const ref = useRef<FilterableEntityTableRefAPI>();
    const paginationTag = useMemo(() => `campaign-popshots-displays-${id}`, [id]);

    const [uploadPopShotLineItemIdDialog, setUploadPopShotLineItemIdDialog] = useAtom(
        atom_uploadPopShotDialogLineItem,
    );

    const closeUploadPopShotDialog = useCallback(() => {
        setUploadPopShotLineItemIdDialog('');
        ref.current.search();
    }, [setUploadPopShotLineItemIdDialog]);

    const { getById } = useEntityData<Displays_LineItem>('Displays_LineItem');

    const uploadPopShotLineItem = useMemo(
        () =>
            uploadPopShotLineItemIdDialog !== ''
                ? getById({ id: uploadPopShotLineItemIdDialog as any })
                : null,
        [uploadPopShotLineItemIdDialog, getById],
    );

    const artworkDialogOpen = useAtomValue(atom_campaignUploadArtworkOpen);
    const prevArtworkDialogOpen = usePrevious(artworkDialogOpen);

    useEffect(() => {
        if (prevArtworkDialogOpen === true && !artworkDialogOpen) {
            ref.current?.search();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [artworkDialogOpen]);

    const { paginatedData } = usePaginatedEntityData(paginationTag, 'Displays_LineItem');
    const totalItems = useMemo(() => paginatedData?.meta?.pagination?.total, [paginatedData]);
    const t = useCommonTranslation();
    const header = useMemo(
        () =>
            t('Modules.Main.Campaigns.Overview.Displays.displayCountHeader', {
                count: totalItems ?? '',
            }),
        [t, totalItems],
    );

    const lineItemsSearch = useCallback(
        payload => apiClient.Entities.Campaign.lineItems(id, payload),
        [id],
    );

    const dialogOpen = useAtomValue(atom_uploadPopShotDialog);

    useEffect(() => {
        if (!dialogOpen) ref.current.search();
    }, [dialogOpen]);

    return (
        <Box mb={2}>
            {uploadPopShotLineItem != null && (
                <DndProvider backend={HTML5Backend}>
                    <DialogV2
                        open
                        onClose={closeUploadPopShotDialog}
                        title="Modules.Main.Campaigns.PopShots.upload.title"
                    >
                        <CampaignPopShotUpload lineItemId={uploadPopShotLineItem.id} />
                    </DialogV2>
                </DndProvider>
            )}
            <>
                <Box px={2} display="flex" justifyContent="space-between">
                    <Typography variant="h3" color="textSecondary">
                        {header}
                    </Typography>
                </Box>

                <Box maxHeight="75vh" display="flex" overflow="hidden">
                    <FilterableEntityTable
                        ref={ref}
                        tag={paginationTag}
                        columns={tableCols}
                        baseEntityName="Displays_LineItem"
                        showFilters
                        customListSearch={lineItemsSearch}
                        textSearchColumns={textSearchColumns}
                        includes={lineItemIncludes}
                        tableProps={tableProps}
                        rendererType={FilterableEntityTableRendererType.Contained}
                        alwaysOpenFilters
                        filtersInlineSearch
                        onlyIncludeFilterKeys={useMemo(() => ['filter.owner.id'], [])}
                    />
                </Box>
            </>
        </Box>
    );
};

export default CampaignPopShotsDisplays;
