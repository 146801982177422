import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    ElasticSearchFilters,
    EsPCAReportFilterField,
    PCAMapReportPlayStats,
    PCAReportCountField,
    PCAReportMetric,
    Campaign,
} from '@uniled/api-sdk';
import { Box, CardHeader, Grid, Typography } from '@mui/material';
import { ReportDateTimeframe } from 'c-reports/Types';
import {
    FieldReportType,
    ReportTabProps,
    ReportView,
} from 'c-main/Components/Campaign/CampaignReports/types';
import { format, parseISO } from 'date-fns';
import { LocationOn, Tv } from '@mui/icons-material';
import { Translate } from 'c-translation';
import { MuiRouterLink } from 'c-components';
import ByFieldES from 'c-main/Components/Campaign/CampaignReports/ElasticSearch/ByFieldES';
import { googleMapsLatLngUrl } from 'c-lib';
import MetricPerformance from '../../MetricPerformance';

type Props = {
    frameId: string;
    frameInfo: PCAMapReportPlayStats;
    metric: PCAReportMetric;
    campaign: Campaign;
    start: Date;
    end: Date;
    accumulativeOverTime: boolean;
    setAccumulativeOverTime: (state: boolean) => void;
    environment: string;
    fieldFilters?: Record<string, string[]>;
};
const onFiltersUpdated = () => {};

const SelectedFrameInfo: React.FC<Props> = ({
    frameId,
    frameInfo,
    campaign,
    metric,
    start,
    end,
    accumulativeOverTime,
    setAccumulativeOverTime,
    environment: PCADataEnvironment,
}) => {
    const wrapperRef = useRef<HTMLDivElement>();

    const campaignStart = useMemo(
        () => new Date(format(parseISO(campaign?.date?.date_start), 'yyyy-MM-dd')),
        [campaign?.date?.date_start],
    );
    const campaignEnd = useMemo(
        () => new Date(format(parseISO(campaign?.date?.date_end), 'yyyy-MM-dd')),
        [campaign?.date?.date_end],
    );

    const esFieldFilters = useMemo<ElasticSearchFilters>(
        () => ({ [EsPCAReportFilterField.FrameId]: [frameId] }),
        [frameId],
    );

    const [
        actual,
        expected,
        percentage,
        inSchedule,
        outSchedule,
        unbooked,

        latitude,
        longitude,

        town,
        tvRegion,
        surfaceArea,
        conurbation,
        environment,
        screenFormat,
        lineItemName,
        mediaOwner,
        resolution,
    ] = frameInfo as PCAMapReportPlayStats;

    const [selectedFrameTimeframe, setSelectedFrameTimeframe] = useState(ReportDateTimeframe.All);

    const reportTabProps = useMemo<ReportTabProps>(
        () => ({
            campaign,
            campaignStart,
            campaignEnd,
            currentStartDate: start,
            currentEndDate: end,
            metric,
            overTimeAccumulative: accumulativeOverTime,
            setOverTimeAccumulative: setAccumulativeOverTime,
            dateSettings: {
                onDatesUpdated: newTimeFrame => setSelectedFrameTimeframe(newTimeFrame),
                timeframe: ReportDateTimeframe.All,
                availableTimeframes: [
                    ReportDateTimeframe.Daily,
                    ReportDateTimeframe.All,
                    ReportDateTimeframe.Range,
                    ReportDateTimeframe.Monthly,
                    ReportDateTimeframe.Weekly,
                ],
            },
            countFields: [
                PCAReportCountField.UnBooked,
                PCAReportCountField.OutSchedule,
                PCAReportCountField.InSchedule,
            ],

            environment: PCADataEnvironment,
        }),
        [
            campaign,
            campaignStart,
            campaignEnd,
            start,
            end,
            metric,
            accumulativeOverTime,
            setAccumulativeOverTime,
            PCADataEnvironment,
        ],
    );

    useEffect(() => {
        if (wrapperRef.current) {
            wrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [frameId]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} component="div" ref={wrapperRef}>
                <CardHeader
                    sx={{ pb: 0 }}
                    title={
                        <>
                            <Tv />{' '}
                            <Box ml={1}>
                                {frameInfo[11]} ({frameId})
                            </Box>
                        </>
                    }
                    titleTypographyProps={{ sx: { mt: 2, display: 'flex', alignItems: 'center' } }}
                    subheader={
                        <>
                            <LocationOn />
                            <MuiRouterLink
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                component="a"
                                href={googleMapsLatLngUrl(latitude, longitude)}
                                target="_blank"
                                sx={{ display: 'flex', color: 'inherit' }}
                            >
                                {town}
                                <Box ml={1}>
                                    {latitude}, {longitude}
                                </Box>
                            </MuiRouterLink>
                        </>
                    }
                    subheaderTypographyProps={{
                        sx: { mt: 2, display: 'flex', alignItems: 'center' },
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} sx={{ pl: 2 }}>
                    <Block
                        title={
                            <Translate path="Modules.Main.Campaigns.Overview.Reports.fields.mediaOwner" />
                        }
                        value={mediaOwner}
                    />
                    <Block
                        title={
                            <Translate path="Modules.Main.Campaigns.Overview.Reports.fields.environment" />
                        }
                        value={environment}
                    />
                    <Block
                        title={
                            <Translate path="Modules.Main.Campaigns.Overview.Reports.fields.resolution" />
                        }
                        value={resolution}
                    />
                    <Block
                        title={
                            <Translate path="Modules.Main.Campaigns.Overview.Reports.fields.format" />
                        }
                        value={screenFormat}
                    />
                </Grid>
            </Grid>
            <Box width="100%" my={3}>
                <MetricPerformance
                    metric={metric}
                    performance={percentage}
                    actual={actual}
                    expected={expected}
                    inSchedule={inSchedule}
                    outSchedule={outSchedule}
                    unbooked={unbooked}
                />
            </Box>
            <Grid item xs={12}>
                <ByFieldES
                    field={null}
                    view={ReportView.Field}
                    fieldReportType={FieldReportType.OverTime}
                    initialTimeFrame={selectedFrameTimeframe}
                    {...reportTabProps}
                    currentStartDate={start}
                    currentEndDate={end}
                    fieldFilters={esFieldFilters}
                    refineFilters={false}
                    onFiltersUpdated={onFiltersUpdated}
                    onResponse={onFiltersUpdated}
                    groupResultsByDate
                />
            </Grid>
        </Grid>
    );
};

const Block: React.FC<{ title: React.ReactNode; value: React.ReactNode }> = ({ title, value }) => (
    <Grid item xs={12} sm={6} md="auto">
        <Typography color="grey.500">{title}</Typography>
        <Typography sx={{ maxWidth: '20em', wordBreak: 'break-word' }}>{value}</Typography>
    </Grid>
);

export default SelectedFrameInfo;
