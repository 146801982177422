import React, { useCallback, useEffect } from 'react';
import { useBoolean, UseBoolean, usePrevious } from 'react-hanger';
import { NetworkRequestState } from '@uniled/data-layer';
import { Button, Checkbox, DialogV2, FlashMessage } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import {
    DeleteCreativesAndGroups,
    ReValidateCampaign,
} from 'c-main/Components/CreativeManagement/types';
import { Box } from '@mui/material';
import { PermissionName, CreativeGroup } from '@uniled/api-sdk';
import { IfHasAllPermissions } from 'c-auth-module/Components';

type Props = {
    dialogState: UseBoolean;
    group: CreativeGroup;
    reValidateCampaign: ReValidateCampaign;
    deleteData: DeleteCreativesAndGroups;
};
const DeleteCreativesPerms: PermissionName[] = [PermissionName.UniledportalCreativeDestroy];

const DeleteGroupDialog: React.FC<Props> = ({
    dialogState: { setFalse: CloseDialog, value: DialogOpen },
    group,
    reValidateCampaign,
    deleteData,
}) => {
    const { value, toggle } = useBoolean(false);

    const { start, requestState, error, reset } = useAPIClientRequest(
        apiClient.Entities.CreativeGroup.delete,
    );
    const onDelete = useCallback(async () => {
        const [err, success] = await to(start([group.id], value));

        if (!err && success != null) {
            deleteData({ groups: [group.id], deleteCreativesFromGroup: value });
            if (value) {
                // also deleted creatives
                reValidateCampaign();
            }
        }
    }, [deleteData, group.id, start, value, reValidateCampaign]);

    const prevLoadingState = usePrevious(requestState);
    useEffect(() => {
        if (
            requestState === NetworkRequestState.Success &&
            prevLoadingState === NetworkRequestState.InProgress
        ) {
            CloseDialog();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestState]);

    useEffect(() => {
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <DialogV2
            // onDelete={onDelete}
            onClose={CloseDialog}
            open={DialogOpen}
            title={useCommonTranslation(
                'Modules.Main.CreativeManagement.creatives.groups.deleteGroup.dialogTitle',
                { name: group.name },
            )}
            description={
                <IfHasAllPermissions permissions={DeleteCreativesPerms}>
                    {useCommonTranslation(
                        'Modules.Main.CreativeManagement.creatives.groups.deleteGroup.dialogDescription',
                    )}
                </IfHasAllPermissions>
            }
            // deleteDisabled={requestState === NetworkRequestState.InProgress}
            // deleteLabel="Modules.Main.CreativeManagement.creatives.groups.deleteGroup.deleteBtnLabel"
            actions={
                <>
                    <Button
                        color="error"
                        disabled={requestState === NetworkRequestState.InProgress}
                        onClick={onDelete}
                    >
                        {useCommonTranslation(
                            'Modules.Main.CreativeManagement.creatives.groups.deleteGroup.deleteBtnLabel',
                        )}
                    </Button>
                </>
            }
        >
            <Box textAlign="center">
                <IfHasAllPermissions permissions={DeleteCreativesPerms}>
                    <Checkbox
                        isBoolean
                        disabled={requestState === NetworkRequestState.InProgress}
                        onChange={toggle}
                        value={value}
                        label={useCommonTranslation(
                            'Modules.Main.CreativeManagement.creatives.groups.deleteGroup.alsoDeleteCreativesLabel',
                        )}
                    />
                </IfHasAllPermissions>
                {requestState === NetworkRequestState.Error && (
                    <Box mt={2} textAlign="center">
                        <FlashMessage status="error">{String(error)}</FlashMessage>
                    </Box>
                )}
            </Box>
        </DialogV2>
    );
};

export default DeleteGroupDialog;
