import { useColorCorrection } from 'c-hooks';
import { useMemo } from 'react';
import { ChartColors } from './Colors';

function useChartColors() {
    const { daltonizeColor } = useColorCorrection();

    return useMemo(() => ChartColors.map(c => daltonizeColor(c)), [daltonizeColor]);
}

export default useChartColors;
