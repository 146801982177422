import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIsMobileView } from 'c-hooks';
import { uiThunks } from 'c-wrapper/Slices/UI';

const ResetDrawerOverlay = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const isDrawerOverlay = useIsMobileView();

    const setOpen = useCallback(
        (newState: boolean) => {
            dispatch(uiThunks.toggleDrawerOpen(newState));
        },
        [dispatch],
    );
    useEffect(() => {
        if (isDrawerOverlay) {
            dispatch(uiThunks.toggleDrawerOpen(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDrawerOverlay, pathname]);

    return null;
};

export default ResetDrawerOverlay;
