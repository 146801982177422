import React from 'react';
import { Box } from '@mui/material';
import { CurrencyFormat } from 'c-components';

type Props = { value: string };
const EntityDataCurrencyColumn: React.FC<Props> = ({ value }) => {
    return (
        <Box>
            <CurrencyFormat value={value ?? ''} />
        </Box>
    );
};

export default EntityDataCurrencyColumn;
