import React, { useCallback, useMemo, useState } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import {
    CalendarViewMonth,
    CalendarViewWeek,
    DateRange,
    Event,
    KeyboardArrowDown,
} from '@mui/icons-material';
import { TranslationPath, useCommonTranslation } from 'c-translation';
import { Button } from 'c-components';
import { ReportDateSettings, ReportDateTimeframe } from 'c-reports/Types';

type Props = Pick<ReportDateSettings, 'availableTimeframes'> & {
    timeFrame: ReportDateTimeframe;
    setTimeFrame: (value: ReportDateTimeframe) => void;
};

const timeFrameIcons = {
    [ReportDateTimeframe.Daily]: <Event />,
    [ReportDateTimeframe.Weekly]: <CalendarViewWeek />,
    [ReportDateTimeframe.BiWeekly]: <CalendarViewWeek />,
    [ReportDateTimeframe.Monthly]: <CalendarViewMonth />,
    [ReportDateTimeframe.Range]: <DateRange />,
    [ReportDateTimeframe.All]: <DateRange />,
};

const timeFrameLabels: Record<ReportDateTimeframe, TranslationPath> = {
    [ReportDateTimeframe.Daily]: 'Reporting.timeframes.timeframeDaily',
    [ReportDateTimeframe.Weekly]: 'Reporting.timeframes.timeframeWeekly',
    [ReportDateTimeframe.BiWeekly]: 'Reporting.timeframes.timeframeBiWeekly',
    [ReportDateTimeframe.Monthly]: 'Reporting.timeframes.timeframeMonthly',
    [ReportDateTimeframe.Range]: 'Reporting.timeframes.timeframeRange',
    [ReportDateTimeframe.All]: 'Reporting.timeframes.timeframeAll',
};

const TimeFrameSwitcher: React.FC<Props> = ({ timeFrame, setTimeFrame, availableTimeframes }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const onBtnClick = useCallback(
        e => {
            setAnchorEl(e.currentTarget);
        },
        [setAnchorEl],
    );
    const onClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
    const showDaily = useMemo(
        () => availableTimeframes.indexOf(ReportDateTimeframe.Daily) !== -1,
        [availableTimeframes],
    );
    const showWeekly = useMemo(
        () => availableTimeframes.indexOf(ReportDateTimeframe.Weekly) !== -1,
        [availableTimeframes],
    );
    const showBiWeekly = useMemo(
        () => availableTimeframes.indexOf(ReportDateTimeframe.BiWeekly) !== -1,
        [availableTimeframes],
    );
    const showMonthly = useMemo(
        () => availableTimeframes.indexOf(ReportDateTimeframe.Monthly) !== -1,
        [availableTimeframes],
    );
    const showRange = useMemo(
        () => availableTimeframes.indexOf(ReportDateTimeframe.Range) !== -1,
        [availableTimeframes],
    );

    const optionCount = useMemo(
        () =>
            [showDaily, showWeekly, showBiWeekly, showRange, showMonthly].filter(
                val => val !== false,
            ).length,
        [showDaily, showMonthly, showRange, showWeekly, showBiWeekly],
    );

    const onSetTimeframe = useCallback(
        (timeframe: ReportDateTimeframe) => {
            onClose();
            setTimeFrame(timeframe);
        },
        [onClose, setTimeFrame],
    );

    return (
        <>
            <Button onClick={onBtnClick} disabled={optionCount < 2} className="timeframe-dropdown">
                {timeFrameIcons[timeFrame]}{' '}
                <Box ml={1}>{useCommonTranslation(timeFrameLabels[timeFrame])}</Box>
                <KeyboardArrowDown />
            </Button>
            <Menu anchorEl={anchorEl} open={menuOpen} onClose={onClose}>
                {showDaily && (
                    <TimeFrameMenuItem
                        setTimeFrame={onSetTimeframe}
                        timeFrame={ReportDateTimeframe.Daily}
                        className="timeframe-daily"
                    />
                )}
                {showWeekly && (
                    <TimeFrameMenuItem
                        setTimeFrame={onSetTimeframe}
                        timeFrame={ReportDateTimeframe.Weekly}
                        className="timeframe-weekly"
                    />
                )}
                {showBiWeekly && (
                    <TimeFrameMenuItem
                        setTimeFrame={onSetTimeframe}
                        timeFrame={ReportDateTimeframe.BiWeekly}
                        className="timeframe-biweekly"
                    />
                )}
                {showMonthly && (
                    <TimeFrameMenuItem
                        setTimeFrame={onSetTimeframe}
                        timeFrame={ReportDateTimeframe.Monthly}
                        className="timeframe-monthly"
                    />
                )}
                {showRange && (
                    <TimeFrameMenuItem
                        setTimeFrame={onSetTimeframe}
                        timeFrame={ReportDateTimeframe.Range}
                        className="timeframe-range"
                    />
                )}
                {showRange && (
                    <TimeFrameMenuItem
                        setTimeFrame={onSetTimeframe}
                        timeFrame={ReportDateTimeframe.All}
                        className="timeframe-all"
                    />
                )}
            </Menu>
        </>
    );
};

const TimeFrameMenuItem: React.FC<
    Pick<Props, 'timeFrame' | 'setTimeFrame'> & { className: string }
> = ({ timeFrame, setTimeFrame }) => (
    <MenuItem
        onClick={() => {
            setTimeFrame(timeFrame as ReportDateTimeframe);
        }}
    >
        {timeFrameIcons[timeFrame]} {useCommonTranslation(timeFrameLabels[timeFrame])}
    </MenuItem>
);

export default React.memo(TimeFrameSwitcher);
