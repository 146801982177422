import { Box, Popover } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useBoolean } from 'react-hanger';
import { makeStyles } from '@mui/styles';
import UserProfileCard from '../UserProfileCard';
import { Props } from './Props';

const useStyles = makeStyles(() => ({
    popoverContent: {
        padding: 8,
        pointerEvents: 'all',
    },
    popover: {
        pointerEvents: 'none',
    },
}));

const boxSx = { cursor: 'pointer' };
const UserProfilePopOver = ({
    children,
    userId,
    anchorOrigin = {
        vertical: 'top',
        horizontal: 'left',
    },
}: Props) => {
    const anchor = useRef(undefined as HTMLDivElement);
    const open = useBoolean(false);
    const hover = useBoolean(false);
    const { popoverContent, popover } = useStyles();
    const id = `user-profile-popover-${userId}`;
    useEffect(() => {
        const handleClick = () => {
            if (open.value && !hover.value) {
                open.setFalse();
            }
        };
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [hover.value, open, popoverContent]);
    return (
        <>
            {
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <Box
                    sx={boxSx}
                    aria-owns={open ? id : undefined}
                    aria-haspopup="true"
                    ref={anchor}
                    onClick={open.toggle}
                    onMouseEnter={hover.setTrue}
                    onMouseLeave={hover.setFalse}
                >
                    {children}
                </Box>
            }
            <Popover
                id={id}
                className={popover}
                open={open.value || hover.value}
                anchorEl={anchor.current}
                anchorOrigin={anchorOrigin}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Box
                    className={popoverContent}
                    onMouseEnter={hover.setTrue}
                    onMouseLeave={hover.setFalse}
                >
                    <UserProfileCard userId={userId} />
                </Box>
            </Popover>
        </>
    );
};

export default UserProfilePopOver;
