import React, { useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Displays_LineItem } from '@uniled/api-sdk';
import { getHumanReadableLineItemFileTypes } from 'c-main/Components/CreativeManagement/lib';

type Props = RenderColumnDataProps<Displays_LineItem>;

/**
 * We're assuming this component will be used in on a page which includes the `resolutions` relationship on line items.
 */
const LineItemFileTypesCell: React.FC<Props> = ({ entity }) => {
    const types = useMemo(() => getHumanReadableLineItemFileTypes([entity]), [entity]);

    return <>{types}</>;
};

export default LineItemFileTypesCell;
