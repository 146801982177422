import { ElasticSearchHistogramResponse, PCAReportMetric } from '@uniled/api-sdk';
import { assocPath } from 'ramda';
import { esValuesToPcaValues } from './Transformers';

/**
 *
 * Hourly example
 * {
	"Global D48": {
	    // dates
		"2021-07-05": {
		    // hours
			"0": [
				140,
				0,
				"",
				0,
				0,
				140
			],
		}
	}
}
 */
export const hourlyHistogramToOldDataStructure = ({
    metric,
    data,
    groupByField = true,
    onlyHourNumber = true,
}: {
    metric: PCAReportMetric;
    data: ElasticSearchHistogramResponse;
    groupByField?: boolean;
    onlyHourNumber?: boolean;
}) =>
    Object.entries(data?.data ?? {}).reduce((acc, [dateKey, byField]) => {
        // just getting the date portion (ignoring hours) to avoid localisation
        // since the date string is `yyyy-MM-dd HH:mm`
        const dateString = dateKey.split(' ')[0];
        // second portion is the time `HH:mm`
        let hourString = dateKey.split(' ')[1] ?? '';

        // if `10:00`, it'll become `10`
        if (onlyHourNumber) hourString = String(Number(hourString.split(':')[0]));

        Object.entries(byField).forEach(([fieldName, fieldState]) => {
            const path = groupByField
                ? [fieldName, dateString, hourString]
                : [dateString, hourString];

            // eslint-disable-next-line no-param-reassign
            acc = assocPath(path, esValuesToPcaValues(metric, fieldState), acc);
        });
        return acc;
    }, {});

/**
 *
 * Daily example
 *
 {
	"Global D48": {
		"2021-07-05": [
			140,
			0,
			"",
			0,
			0,
			140
		]
	}
}
 */
export const dailyHistogramToOldDataStructure = ({
    metric,
    data,
    groupByField = true,
}: {
    metric: PCAReportMetric;
    data: ElasticSearchHistogramResponse;
    groupByField?: boolean;
}) =>
    Object.entries(data?.data ?? {}).reduce((acc, [dateKey, byField]) => {
        // just getting the date portion (ignoring hours) to avoid localisation
        // since the date string is `yyyy-MM-dd HH:mm`
        const dateString = dateKey.split(' ')[0];

        Object.entries(byField).forEach(([fieldName, fieldState]) => {
            const path = groupByField ? [fieldName, dateString] : [dateString];
            // eslint-disable-next-line no-param-reassign
            acc = assocPath(path, esValuesToPcaValues(metric, fieldState), acc);
        });
        return acc;
    }, {});
