import React, { CSSProperties, PropsWithChildren, useEffect, useMemo } from 'react';
import { useDragLayer } from 'react-dnd';
import { Box } from '@mui/material';
import { DraggableMouseAdjustment, DraggableStyleOptions, getDraggableStyles } from './lib';

type DragLayerProps = {
    height: number;
    width: number;
    mousePosition?: DraggableMouseAdjustment;

    onIsDraggingChange?: (isDragging: boolean) => void;
};

const CustomDragLayerStyles: CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};
const CustomDragLayer: React.FC<PropsWithChildren<DragLayerProps>> = ({
    width,
    height,
    mousePosition = DraggableMouseAdjustment.TopLeft,
    onIsDraggingChange,
    children,
}) => {
    const { isDragging, initialOffset, clientOffset } = useDragLayer(monitor => ({
        initialOffset: monitor.getInitialSourceClientOffset(),
        clientOffset: monitor.getClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    useEffect(() => {
        onIsDraggingChange?.(isDragging);
    }, [isDragging, onIsDraggingChange]);

    const dragStyleOpts = useMemo<DraggableStyleOptions>(
        () => ({
            initialOffset,
            currentOffset: clientOffset,
            size: { width, height },
            adjustPosition: mousePosition,
        }),
        [clientOffset, initialOffset, width, height, mousePosition],
    );

    if (!isDragging) {
        return null;
    }

    return (
        <Box style={CustomDragLayerStyles}>
            <Box height={height} width={width} style={getDraggableStyles(dragStyleOpts)}>
                {children}
            </Box>
        </Box>
    );
};

export default CustomDragLayer;
