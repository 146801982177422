import { RenderColumnDataProps } from 'c-pagination';
import { PopDisplays_Display } from '@uniled/api-sdk';
import React, { useCallback, useMemo } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import { deleteIcon } from 'c-main/icons';
import { useDeleteEntityData } from 'c-data';
import { useBoolean } from 'react-hanger';
import { Button, DialogV2, FlashMessage } from 'c-components';
import { NetworkRequestState } from '@uniled/data-layer';
import { useCommonTranslation } from 'c-translation';
import { onClickStopPropagation } from 'c-lib';

type Props = RenderColumnDataProps<PopDisplays_Display>;

const DisplayListActionsCell: React.FC<Props> = ({ entity }) => {
    const { deleteEntity, getDeletingById } = useDeleteEntityData('PopDisplays_Display');

    const doDelete = useCallback(() => {
        deleteEntity(entity.id);
    }, [deleteEntity, entity]);

    const dialogState = useBoolean(false);

    const openDialog = useCallback(
        e => {
            e.stopPropagation();
            e.preventDefault();

            dialogState.setTrue();
        },
        [dialogState],
    );

    const error = useMemo(() => getDeletingById(entity.id).error, [getDeletingById, entity.id]);

    return (
        <Box onClick={onClickStopPropagation}>
            <DialogV2
                onClose={dialogState.setFalse}
                open={dialogState.value}
                title="Modules.PopDisplays.Display.table.deleteDisplayTitle"
                description={useCommonTranslation(
                    'Modules.PopDisplays.Display.table.deleteDisplayDescription',
                    { name: entity?.name ?? entity.channel },
                )}
                actions={
                    <Stack gap={2} alignItems="center">
                        {error && <FlashMessage status="error">{error}</FlashMessage>}
                        <Box>
                            <Button
                                disabled={
                                    getDeletingById(entity.id).state ===
                                    NetworkRequestState.InProgress
                                }
                                color="error"
                                onClick={doDelete}
                            >
                                {useCommonTranslation(
                                    'Modules.PopDisplays.Display.table.deleteButtonLabel',
                                )}
                            </Button>
                        </Box>
                    </Stack>
                }
            />
            <IconButton color="error" onClick={openDialog}>
                {deleteIcon}
            </IconButton>
        </Box>
    );
};

export default DisplayListActionsCell;
