import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FilterConfigDateRange, FilterConfigRendererProps } from 'c-pagination';
import { useCommonTranslation } from 'c-translation';
import { Button, DateRangePicker, TextField } from 'c-components';
import { useTheme } from '@mui/styles';
import { Box, IconButton, InputAdornment, Menu, useMediaQuery } from '@mui/material';
import { useBoolean, usePrevious } from 'react-hanger';
import { isSameDay } from 'date-fns';
import { useDateUtils } from 'c-hooks';
import { CalendarMonth } from '@mui/icons-material';
import { splitValueAndOperator } from './utils';

type Props = FilterConfigRendererProps<FilterConfigDateRange>;

const DateFilter: React.FC<Props> = ({ config, onChange, value, id, className }) => {
    /**
     * We're assuming that each date filter will only have a maximum of a single value
     */
    const actualStart = useMemo(() => {
        const [val] = splitValueAndOperator(String(value?.[0] ?? ''));
        if (val != null && val?.length > 0) {
            // ignore time
            return new Date(val.split(' ')[0]);
        }
        return undefined;
    }, [value]);

    const actualEnd = useMemo(() => {
        const [val] = splitValueAndOperator(String(value?.[1] ?? ''));
        if (val != null && val?.length > 0) {
            return new Date(val.split(' ')[0]);
        }
        return undefined;
    }, [value]);

    const [localDates, setLocalDates] = useState<[Date, Date]>([actualStart, actualEnd]);

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const anchorEl = useRef();
    const menuOpen = useBoolean(false);

    const onDateRangeChange = useCallback(
        (start: Date, end: Date) => {
            setLocalDates([start, end]);
            if (start && end && !isSameDay(start, end)) {
                menuOpen.setFalse();
            }
        },
        [menuOpen],
    );
    const onClear = useCallback(() => {
        setLocalDates([null, null]);
        onChange([]);
        menuOpen.setFalse();
    }, [onChange, menuOpen]);

    const prevOpen = usePrevious(menuOpen.value);

    const { apiFormatDateString, formatDateString } = useDateUtils();
    const dateRangeLabel = useMemo(
        () =>
            localDates[0] == null && localDates[1] == null
                ? ''
                : `${formatDateString({ date: localDates[0] })} - ${formatDateString({
                      date: localDates[1],
                  })}`,
        [formatDateString, localDates],
    );

    useEffect(() => {
        if (prevOpen === true && menuOpen.value === false) {
            if (localDates[0] != null && localDates[1] != null) {
                onChange([
                    localDates[0] ? `>=${apiFormatDateString({ date: localDates[0] })}` : undefined,
                    localDates[1]
                        ? `<=${apiFormatDateString({ date: localDates[1] })} 23:59:59`
                        : undefined,
                ]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevOpen, menuOpen.value]);

    useEffect(() => {
        if (value?.length === 0) {
            setLocalDates([null, null]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <>
            <TextField
                id={id}
                className={className}
                value={dateRangeLabel}
                ref={anchorEl}
                disabled
                label={useCommonTranslation(config.label)}
                size="small"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={menuOpen.setTrue} size="small">
                                <CalendarMonth fontSize="inherit" />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <Menu anchorEl={anchorEl.current} open={menuOpen.value} onClose={menuOpen.setFalse}>
                <Box mb={1} mx={1}>
                    <Button onClick={onClear} size="small">
                        {useCommonTranslation('Pagination.dateRange.clear')}
                    </Button>
                </Box>
                <DateRangePicker
                    startDate={localDates[0]}
                    endDate={localDates[1]}
                    onChange={onDateRangeChange}
                    months={isMdDown ? 1 : 2}
                    editableDateInputs
                />
            </Menu>
        </>
    );
    // return (
    //     <>
    //         <DateField
    //             value={actualValue === '' ? null : actualValue}
    //             label={t(config.label)}
    //             inputProps={{
    //                 size: 'small',
    //                 id,
    //             }}
    //             onChange={onDateChange}
    //         />
    //     </>
    // );
};

export default DateFilter;
