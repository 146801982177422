import { PermissionName } from '@uniled/api-sdk';
import { User } from 'c-entity-types';

const userHasPermissions = (user: User, permissions: PermissionName[]) => {
    const names = user?.permissionNames ?? [];
    return permissions.reduce((acc, curr) => {
        if (names.indexOf(curr) !== -1) {
            acc.push(curr);
        }

        return acc;
    }, [] as PermissionName[]);
};

export default userHasPermissions;
