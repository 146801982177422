import React, { Ref, useCallback, useMemo } from 'react';
import { DateRangeProps, Range } from 'react-date-range';
import { useFormContext } from 'react-hook-form';
import { format, formatISO, parse, parseISO } from 'date-fns';
import { useDateUtils } from 'c-hooks';
import DateRangePicker from './DateRangePicker';

type Props = DateRangeProps & {
    startDateKey: string;
    endDateKey: string;

    dateFormat?: string;
    dateFormatISO?: boolean;
};

const toDateObject = (dateFormat: string, isoDate: boolean, value?: string) => {
    if (value == null) {
        return new Date();
    }
    if (isoDate) {
        return parseISO(value);
    }
    return parse(value, dateFormat, new Date());
};

const DateRangePickerFormWrapper = (
    { startDateKey, endDateKey, dateFormat, dateFormatISO = false, onChange, ...props }: Props,
    ref: Ref<any>,
) => {
    const { dayMonthYearApiFormat } = useDateUtils();
    const { watch, setValue } = useFormContext();
    const start = watch(startDateKey);
    const end = watch(endDateKey);

    const theDateFormat = useMemo(
        () => dateFormat ?? dayMonthYearApiFormat,
        [dateFormat, dayMonthYearApiFormat],
    );

    const state = useMemo<Range[]>(
        () => [
            {
                key: 'selection',
                startDate: toDateObject(theDateFormat, dateFormatISO, start),
                endDate: toDateObject(theDateFormat, dateFormatISO, end),
            },
        ],
        [start, end, dateFormatISO, theDateFormat],
    );

    const onSelectionChange = useCallback(
        (startDate: Date, endDate: Date) => {
            if (startDate) {
                const startString = dateFormatISO
                    ? formatISO(startDate)
                    : format(startDate, theDateFormat);
                setValue(startDateKey, startString, { shouldDirty: true, shouldValidate: true });
            }

            if (endDate) {
                const endString = dateFormatISO
                    ? formatISO(endDate)
                    : format(endDate, theDateFormat);
                setValue(endDateKey, endString, { shouldDirty: true, shouldValidate: true });
            }
        },
        [theDateFormat, dateFormatISO, endDateKey, setValue, startDateKey],
    );

    return <DateRangePicker ref={ref} onChange={onSelectionChange} ranges={state} {...props} />;
};

export default React.forwardRef(DateRangePickerFormWrapper);
