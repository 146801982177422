import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, DialogV2, ErrorImageTryAgain, FileBrowserFile, FlashMessage } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { Box, CircularProgress, Divider, Stack } from '@mui/material';
import { ResetCreativesData, ReValidateCampaign } from 'c-main/Components/CreativeManagement/types';
import { PermissionName } from '@uniled/api-sdk';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import AssignmentLineItemsGrid from './AssignmentLineItemsGrid';
import CreativeDetailsTable from './CreativeDetailsTable';
import { useUserPermissions } from 'c-auth-module/Hooks';

type Props = {
    open: boolean;
    onClose: () => void;
    campaignId: number;
    file: FileBrowserFile;
    resetData: ResetCreativesData;
    reValidateCampaign: ReValidateCampaign;
};
const UnAssignPerms: PermissionName[] = [
    PermissionName.DisplaysLine_itemsRead,
    PermissionName.UniledportalCreativeRead,
    PermissionName.UniledportalDisplayRead,
    PermissionName.UniledportalCampaign_displayDestroy,
    PermissionName.UniledportalCampaign_display_creativeDestroy,
];

const UnAssignCreativeDialog: React.FC<Props> = ({
    open,
    onClose,
    campaignId,
    file,
    resetData,
    reValidateCampaign,
}) => {
    const [selectedLineItems, setSelectedLineItems] = useState<string[]>([]);
    const t = useCommonTranslation();

    const {
        start: LoadCreative,
        isLoading: IsCreativeLoading,
        hasFailed: HasCreativeLoadFailed,
        data: CreativeData,
    } = useAPIClientRequest(apiClient.Entities.Creative.get);

    const {
        start: UnAssignCreatives,
        isLoading: AreCreativesUnAssigning,
        hasFailed: HaveCreativesFailed,
        error,
    } = useAPIClientRequest(apiClient.Entities.Campaign.forceUnAssignCreativesFromLineItems);

    const creativesFailureMessage = useMemo(() => {
        const errStr = String(error);
        if (errStr.trim().length > 0) return errStr;

        return t('Modules.Main.CreativeManagement.creatives.unAssignDialog.failedToUnAssign');
    }, [t, error]);

    const lineItems = useMemo(() => CreativeData?.data?.data?.lineItems ?? [], [CreativeData]);

    const loadCreative = useCallback(async () => {
        setSelectedLineItems([]);
        await to(
            LoadCreative(file.downloadId, [
                'lineItems',
                'lineItems.fileTypes',
                'lineItems.resolutions',
            ]),
        );
    }, [LoadCreative, file.downloadId]);

    useEffect(() => {
        loadCreative();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const unAssignDisabled = useMemo(
        () => selectedLineItems.length === 0 || AreCreativesUnAssigning,
        [selectedLineItems.length, AreCreativesUnAssigning],
    );

    const unAssignCreative = useCallback(async () => {
        const [err, suc] = await to(
            UnAssignCreatives(campaignId, {
                creatives: [
                    {
                        id: file.downloadId,
                        lineItems: selectedLineItems,
                    },
                ],
            }),
        );

        if (!err && suc?.data?.creatives != null && suc?.data?.creativeGroups != null) {
            //
            resetData(suc.data);
            reValidateCampaign();
            onClose();
        }
    }, [
        UnAssignCreatives,
        campaignId,
        selectedLineItems,
        file,
        resetData,
        reValidateCampaign,
        onClose,
    ]);

    const failedToLoad = useMemo(() => HasCreativeLoadFailed, [HasCreativeLoadFailed]);

    const { hasAll } = useUserPermissions();
    const canUnAssign = useMemo(() => hasAll(UnAssignPerms), [hasAll]);

    return (
        <DialogV2
            open={open}
            onClose={onClose}
            maxWidth="xl"
            title="Modules.Main.CreativeManagement.creatives.unAssignDialog.dialogTitle"
            description="Modules.Main.CreativeManagement.creatives.unAssignDialog.dialogDescription"
            contentHeight="70vh"
            actions={
                <>
                    <IfHasAllPermissions permissions={UnAssignPerms}>
                        <Button disabled={unAssignDisabled} onClick={unAssignCreative}>
                            {t(
                                'Modules.Main.CreativeManagement.creatives.unAssignDialog.unAssignButtonLabel',
                            )}
                            {AreCreativesUnAssigning && (
                                <Box ml={1} display="flex" alignItems="center">
                                    <CircularProgress size={15} />
                                </Box>
                            )}
                        </Button>
                    </IfHasAllPermissions>
                </>
            }
        >
            <Box display="flex" flexDirection="column" flex={1} overflow="hidden">
                <Stack spacing={2} flex={1} overflow="hidden">
                    {file && (
                        <Box>
                            <CreativeDetailsTable file={file} />
                        </Box>
                    )}
                    <Divider />
                    <Box flex={1} minHeight={250} height="100%" overflow="hidden" display="flex">
                        {!failedToLoad && (
                            <AssignmentLineItemsGrid
                                canAction={canUnAssign}
                                unAssignMode
                                loading={IsCreativeLoading}
                                lineItems={lineItems}
                                onSelectionUpdated={setSelectedLineItems}
                                selectedLineItems={selectedLineItems}
                                file={file}
                            />
                        )}

                        {failedToLoad && (
                            <ErrorImageTryAgain
                                title="Modules.Main.CreativeManagement.creatives.assignDialog.failedToLoadAssignData"
                                retryLabel="Modules.Main.CreativeManagement.creatives.assignDialog.failedToLoadAssignDataRetry"
                                retry={loadCreative}
                            />
                        )}
                    </Box>
                    {HaveCreativesFailed && (
                        <Box alignSelf="center">
                            <FlashMessage status="error">{creativesFailureMessage}</FlashMessage>
                        </Box>
                    )}
                </Stack>
            </Box>
        </DialogV2>
    );
};

export default UnAssignCreativeDialog;
