import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, DialogV2, ErrorImageTryAgain } from 'c-components';
import { CampaignInstruction, PermissionName } from '@uniled/api-sdk';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { useBoolean, useArray } from 'react-hanger';
import ChatWrapper from 'c-main/Components/Chat/ChatWrapper';
import { useAPIClientRequest, useAuthenticatedUser } from 'c-hooks';
import { useCommonTranslation } from 'c-translation';
import { Typography } from '@mui/material';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { ChatMessage } from 'c-main/Components/Chat';

type Props = {
    campaignId: number;
};

const InstructionsPerms = [
    PermissionName.UniledportalCampaign_instructionsRead,
    PermissionName.UniledportalCampaign_instruction_filesRead,
];
const InstructionsCreatePerms = [
    PermissionName.UniledportalCampaign_instructionsCreate,
    PermissionName.UniledportalCampaign_instruction_filesCreate,
];

const CampaignInstructions: React.FC<Props> = ({ campaignId }) => {
    const { setTrue, setFalse, value } = useBoolean(false);
    const { user } = useAuthenticatedUser();
    const t = useCommonTranslation();

    const {
        start: CreateInstruction,
        isLoading: IsCreating,
        hasSucceeded: NewMessageSuccess,
        hasFailed: HasCreateFailed,
        error: CreateError,
    } = useAPIClientRequest(apiClient.Entities.CampaignInstruction.create);
    const {
        start: ListInstructions,
        isLoading: ListLoading,
        hasFailed: HasInstructionsListFailed,
    } = useAPIClientRequest(apiClient.Entities.CampaignInstruction.list);

    const {
        value: instructions,
        add,
        setValue: setInstructions,
    } = useArray<CampaignInstruction>([]);
    const chatMessages = useMemo<ChatMessage[]>(
        () =>
            instructions.map(instruction => ({
                id: instruction.id,
                attachments:
                    instruction?.campaignInstructionFiles?.map(file => ({
                        downloadUrl: file.url,
                        filesize: file.filesize,
                        mimetype: file.mimetype,
                        name: file.original_filename,
                    })) ?? [],
                content: instruction.instruction_details,
                createdAt: instruction.created_at,
                createdBy: instruction.user,
            })),
        [instructions],
    );

    const onSend = useCallback(
        async (message: string, files: File[]) => {
            if (message.length === 0 || IsCreating) return;

            const formData = new FormData();
            formData.set('campaign_id', campaignId as any);
            formData.set('instruction_details', message);
            files.forEach(file => formData.append('files[]', file));

            const [, succ] = await to(
                CreateInstruction({ formData }, ['campaignInstructionFiles', 'user']),
            );

            if (succ?.data?.data) {
                add({ ...succ?.data?.data });
            }
        },
        [CreateInstruction, add, campaignId, IsCreating],
    );

    const loadInstructions = useCallback(async () => {
        const [, succ] = await to(
            ListInstructions({
                includes: ['campaignInstructionFiles', 'user'],
                filters: { 'filter.campaign_id': [campaignId] },
                perPage: 999,
                orderBy: 'id',
                direction: 'desc',
            }),
        );

        if (Array.isArray(succ?.data?.data)) {
            setInstructions([...succ.data.data]);
        }
    }, [ListInstructions, campaignId, setInstructions]);

    useEffect(() => {
        if (value === true) {
            loadInstructions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <>
            <IfHasAllPermissions permissions={InstructionsPerms}>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={setTrue}
                    id="campaign-header-instructions"
                >
                    {t('Modules.Main.Campaigns.Instructions.openDialogBtnlabel')}
                </Button>
            </IfHasAllPermissions>

            <DialogV2
                onClose={setFalse}
                open={value}
                contentHeight="70vh"
                maxWidth="md"
                title="Modules.Main.Campaigns.Instructions.dialogTitle"
                titleDivider
            >
                <ChatWrapper
                    localUser={user}
                    messages={chatMessages}
                    onSendMessage={onSend}
                    newMessageError={HasCreateFailed ? String(CreateError) : null}
                    messageListLoading={ListLoading}
                    messageListLoadingFailed={
                        HasInstructionsListFailed ? (
                            <ErrorImageTryAgain
                                title={t('Modules.Main.Campaigns.Instructions.failedToLoad')}
                                retryLabel={t(
                                    'Modules.Main.Campaigns.Instructions.failedToLoadRetry',
                                )}
                                retry={loadInstructions}
                            />
                        ) : null
                    }
                    newMessageLoading={IsCreating}
                    newMessageSuccess={NewMessageSuccess}
                    emptyMessagesContent={
                        <Typography variant="body2" color="grey.500">
                            {t('Modules.Main.Campaigns.Instructions.emptyInstructions')}
                        </Typography>
                    }
                    newMessagePlaceholder={t(
                        'Modules.Main.Campaigns.Instructions.newMessagePlaceholder',
                    )}
                    sendNewMessagePermissions={InstructionsCreatePerms}
                />
            </DialogV2>
        </>
    );
};

export default CampaignInstructions;
