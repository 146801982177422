import React, { useCallback } from 'react';
import { Checkbox } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { checkedPermissionIds } from 'c-admin/Lib';
import { useDispatch, useSelector } from 'react-redux';
import { roleSelectors, roleThunks } from 'c-admin/Slices/Role';

type Props = {
    ids: number[];
};

const SelectAllPermissions: React.FC<Props> = ({ ids }) => {
    const dispatch = useDispatch();
    const val = useSelector(roleSelectors.getPermissionsDictionary);

    const checked = checkedPermissionIds(val).filter(perm => ids.indexOf(+perm) !== -1);
    const allChecked = checked.length >= ids.length && checked.length > 0;
    const indeterminate = !allChecked && checked.length > 0;

    const onChange = useCallback(() => {
        if (indeterminate === true || !allChecked) {
            dispatch(
                roleThunks.setRoleEditingPermissionStateMulti({
                    ...val,
                    ...ids.reduce((acc, curr) => {
                        acc[curr] = true;
                        return acc;
                    }, {}),
                }),
            );
        } else if (allChecked) {
            dispatch(
                roleThunks.setRoleEditingPermissionStateMulti({
                    ...val,
                    ...ids.reduce((acc, curr) => {
                        acc[curr] = false;
                        return acc;
                    }, {}),
                }),
            );
        }
    }, [ids, indeterminate, allChecked, val, dispatch]);

    return (
        <>
            <Checkbox
                label={useCommonTranslation('Modules.Admin.RoleEdit.selectAllPermissions')}
                stopCheckboxClickPropagation
                isBoolean
                value={allChecked}
                indeterminate={indeterminate}
                onChange={onChange}
            />
        </>
    );
};

export default SelectAllPermissions;
