import React from 'react';
import { FilterableEntityTableProps } from 'c-pagination';
import { Box, Grid } from '@mui/material';
import { FlashMessage, LoadingSpinner } from 'c-components';

const DefaultRenderer: FilterableEntityTableProps['CustomRenderer'] = ({
    wrapperComponentRef,
    table,
    filterForm,
    loading,
    pagination,
    error,
}) => (
    <Grid container ref={wrapperComponentRef}>
        {filterForm != null && (
            <Grid item xs={12}>
                {filterForm}
            </Grid>
        )}

        <Grid item xs={12}>
            <Box position="relative">
                {loading && <LoadingSpinner />}
                {error && (
                    <Box marginY={1}>
                        <FlashMessage status="error">{error}</FlashMessage>
                    </Box>
                )}
                {table}
                {pagination}
            </Box>
        </Grid>
    </Grid>
);

export default DefaultRenderer;
