import React, { useEffect } from 'react';
import { CardContent } from '@mui/material';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import { TransparentCard } from 'c-components';
import { DisplaysAdminTiles } from 'c-displays/Components';
import { displaysAdminIcon } from 'c-main/icons';
import { PermissionName } from '@uniled/api-sdk';
import ReactGA from 'react-ga';

const DisplaysAdminHomePage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <TransparentCard title={useCommonTranslation('Modules.Displays.AdminHome.metaTitle')}>
            <CardContent>
                <DisplaysAdminTiles />
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageConfig = {
    id: 'DisplaysAdminHomePage',
    component: DisplaysAdminHomePage,
    systemSearch: {
        title: 'Modules.Displays.AdminHome.metaTitle',
        description: 'Modules.Displays.AdminHome.description',
        icon: displaysAdminIcon,
        type: 'page',
        aliases: 'SystemSearch.aliases.displaysAdmin',
        route: PostAuthRoutes.Displays.DisplaysAdminHome,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            { label: 'Modules.Displays.AdminHome.metaTitle' },
        ],
        permissions: [PermissionName.Admin],
    },
};

export default setup;
