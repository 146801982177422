import { PopDisplays_LogFileResource } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { PopDisplays_LogFileResourceState } from './LogFileResource-types';

const initialState: PopDisplays_LogFileResourceState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: PopDisplays_LogFileResourceState, action: PayloadAction<string>): PopDisplays_LogFileResourceState => state,
};

const slice = createBaseSlice<
    PopDisplays_LogFileResource,
    PopDisplays_LogFileResourceState,
    typeof additionalReducers
>({
    name: 'logFileResource',
    entityName: 'PopDisplays_LogFileResource',
    initialState,
    reducers: additionalReducers,
});

const logFileResourceSlice = {
    logFileResourceActions: slice.slice.actions,
    logFileResourceReducer: slice.slice.reducer,
    logFileResourceAdapter: slice.adapter,
    logFileResourceSelectors: slice.selectors,
    logFileResourceThunks: slice.thunks,
};

export default logFileResourceSlice;
export const {
    logFileResourceReducer,
    logFileResourceActions,

    logFileResourceAdapter,
    logFileResourceSelectors,
    logFileResourceThunks,
} = logFileResourceSlice;
