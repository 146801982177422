import React, { CSSProperties, useMemo } from 'react';
import { Box, SvgIconProps } from '@mui/material';
import { useBoolean } from 'react-hanger';
import merge from 'deepmerge';
import { FileBrowserFile, FileIconType } from './types';
import FileIcon from './FileIcon';
import { getFileType } from './lib';

type Props = {
    file: FileBrowserFile;
    preferredSize?: keyof FileBrowserFile['urls'];
    sizePreferenceOrder?: Array<keyof FileBrowserFile['urls']>;
    preferredPosterSize?: keyof FileBrowserFile['urls'];
    posterSizePreferenceOrder?: Array<keyof FileBrowserFile['urls']>;
    maxHeight?: CSSProperties['maxHeight'];
    maxWidth?: CSSProperties['maxWidth'];
    fileIconSx?: SvgIconProps['sx'];
    showVideoPreview?: boolean;
    showPdfEmbed?: boolean;
    fallback?: React.ReactNode;
    forceShowFallback?: boolean;
    imgStyles?: React.CSSProperties;
};

const defaultSizePreferenceOrder: Props['sizePreferenceOrder'] = [
    'raw',
    'small',
    'medium',
    'large',
];

const defaultPosterSizePreferenceOrder: Props['sizePreferenceOrder'] = [
    'large',
    'raw',
    'medium',
    'small',
];

const RawFilePreview: React.FC<Props> = ({
    file,
    preferredSize,
    sizePreferenceOrder = defaultSizePreferenceOrder,
    preferredPosterSize,
    posterSizePreferenceOrder = defaultPosterSizePreferenceOrder,
    maxHeight,
    maxWidth = '100%',
    fileIconSx,
    showVideoPreview = true,
    showPdfEmbed = false,
    fallback,
    forceShowFallback: DefaultForceShowFallback = false,
    imgStyles,
}) => {
    const fileUrl = useMemo(
        () =>
            // try returning the preferred size first
            file?.urls?.[preferredSize] ??
            // return the first URL which isn't null in priority order
            file?.urls?.[sizePreferenceOrder.find(size => file?.urls?.[size] != null)],
        [file, sizePreferenceOrder, preferredSize],
    );
    const posterFileUrl = useMemo(
        () =>
            // try returning the preferred size first
            file?.urls?.[preferredPosterSize] ??
            // return the first URL which isn't null in priority order
            file?.urls?.[posterSizePreferenceOrder.find(size => file?.urls?.[size] != null)],
        [file, posterSizePreferenceOrder, preferredPosterSize],
    );
    const fileType = useMemo(() => getFileType(file), [file]);
    const forceShowFalback = useBoolean(DefaultForceShowFallback);

    const $imgStyle = useMemo(
        () => merge(imgStyles, { alignSelf: 'center', maxHeight, maxWidth }),
        [imgStyles, maxHeight, maxWidth],
    );

    const showImagePreview = useMemo(() => {
        if (showVideoPreview && fileType === FileIconType.Video) return false;

        if (fileUrl == null) return false;

        if (forceShowFalback.value) return false;

        return true;
    }, [fileUrl, fileType, forceShowFalback.value, showVideoPreview]);

    const embedPdf = useMemo(
        () => showPdfEmbed && fileType === FileIconType.PDF,
        [fileType, showPdfEmbed],
    );

    if (embedPdf) {
        return (
            <Box height="70vh" width="100%">
                <embed src={fileUrl} width="100%" height="100%" />
            </Box>
        );
    }

    if (showImagePreview) {
        return (
            <img
                key={`${file.id}-${file.name}`}
                src={fileUrl}
                onError={forceShowFalback.setTrue}
                alt=""
                height="auto"
                width="auto"
                style={$imgStyle}
            />
        );
    }

    if (
        fileType === FileIconType.Video &&
        showVideoPreview &&
        fileUrl != null &&
        !forceShowFalback.value
    ) {
        return (
            <Box
                display="flex"
                overflow="auto"
                width={file.width ?? 'auto'}
                height={file.height ?? 'auto'}
            >
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                    controls
                    key={`${file.id}-${file.name}`}
                    poster={posterFileUrl}
                    style={{
                        maxHeight: '90%',
                        maxWidth: '100%',
                        margin: 'auto',
                        height: '100%',
                        width: '100%',
                    }}
                    // onError={forceShowFalback.setTrue}
                >
                    <source src={fileUrl} type={file.mimeType} />
                </video>
            </Box>
        );
    }

    return (fallback ?? <FileIcon file={file} sx={fileIconSx} />) as any;
};

export default RawFilePreview;
