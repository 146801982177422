import { ScheduleFormDataRule } from 'c-main/Components/Schedule/types';
import { ScheduleRule } from '@uniled/api-sdk';
import { AllScheduleRuleTimes } from 'c-main/Components/Schedule/lib';

export const emptyFormDataRule = (): ScheduleFormDataRule => ({
    date_range: [null, null],
    days_of_week: [1, 2, 3, 4, 5, 6, 7],
    // days_of_week: {
    //     monday: true,
    //     tuesday: true,
    //     wednesday: true,
    //     thursday: true,
    //     friday: true,
    //     saturday: true,
    //     sunday: true,
    // },
    hours: [{ from: null, to: null }],
});

export const emptyScheduleRuleHours = (): ScheduleRule['hours_of_day'] =>
    AllScheduleRuleTimes.reduce(
        (hours, hour) => ({ ...hours, [hour]: false }),
        {} as ScheduleRule['hours_of_day'],
    );
