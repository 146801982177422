import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Stack } from '@mui/material';
import { TabContext, TabList, TabListProps, TabPanel } from '@mui/lab';
import { useTheme } from '@mui/styles';
import { css } from '@emotion/react';
import merge from 'deepmerge';
import { useCommonTranslation } from 'c-translation';
import { TabSettings, TabWrapperSettings } from 'c-types';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { useUrlState, useUrlStateValue } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { useParams } from 'react-router-dom';
import StyledTab from '../StyledTab';
import useAPIClientRequest from '../../Hooks/useAPIClientRequest';

type Props = TabWrapperSettings & {
    defaultOpenTab?: string;
    afterTabs?: React.ReactNode;
};

interface RouteParams {
    id: string;
}

const TabIndicatorProps = { style: { height: 4 } };
const TabIndicatorPropsSecondary = { style: { height: 4, bottom: -2, overflow: 'visible' } };
const TabListSxDefault: TabListProps['sx'] = { mb: 2 };

const TabsWrapper: React.FC<Props> = ({
    tabs,
    tabListProps,
    type = 'primary',
    urlStatePath,
    defaultOpenTab = '0',
    onTabChange,
    afterTabs,
}) => {
    const { hasAll } = useUserPermissions();
    const selectedTab = useUrlStateValue(urlStatePath, null);
    const { push } = useUrlState();

    const filteredTabs = useMemo(() => tabs.filter(t => hasAll(t.permissions)), [tabs, hasAll]);

    const [openTab, setOpenTab] = useState<string>(() => {
        if (urlStatePath != null) {
            return selectedTab ?? filteredTabs[0]?.urlName;
        }

        return defaultOpenTab;
    });
    const { id } = useParams<RouteParams>();
    const { start } = useAPIClientRequest(apiClient.Entities.Campaign.auditEvent);

    useEffect(() => {
        if (openTab === 'displays') {
            start(Number(id), { audit_event: 'viewed_displays_tab' });
        }

        if (openTab === 'creatives') {
            start(Number(id), { audit_event: 'viewed_creatives_tab' });
        }

        if (openTab === 'reporting') {
            start(Number(id), { audit_event: 'viewed_reporting_tab' });
        }
    }, [id, start, openTab]);

    useEffect(() => {
        if (urlStatePath && selectedTab !== openTab && selectedTab != null) {
            setOpenTab(selectedTab);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab]);

    useEffect(() => {
        onTabChange?.(openTab);
    }, [openTab, onTabChange]);

    const handleChange = useCallback(
        (e, newValue) => {
            setOpenTab(newValue);
            if (urlStatePath) {
                push(urlStatePath, newValue);
            }
        },
        [setOpenTab, push, urlStatePath],
    );

    const theme = useTheme();

    const sx = useMemo<TabListProps['sx']>(() => {
        const innerSx: TabListProps['sx'] = merge(
            TabListSxDefault,
            (tabListProps?.sx ?? {}) as any,
        );
        if (type === 'primary') {
            innerSx.bgcolor = theme.palette.background.paper;
        }
        if (type === 'secondary') {
            innerSx.borderBottom = `2px solid ${theme.palette.divider}`;
        }
        innerSx.overflow = 'visible';
        return innerSx;
    }, [tabListProps?.sx, type, theme]);

    const styledTabs = useMemo(
        () =>
            filteredTabs.map((tab, index) => {
                const val = tab.urlName ?? String(index);
                return (
                    <StyledTab
                        // eslint-disable-next-line react/no-array-index-key
                        key={String(index)}
                        className={tab.className}
                        label={
                            <IfHasAllPermissions permissions={tab.permissions}>
                                <TabLabel tab={tab} />
                            </IfHasAllPermissions>
                        }
                        value={val}
                        selected={openTab === val}
                        type={type}
                    />
                );
            }),
        [filteredTabs, type, openTab],
    );

    const tabPanels = useMemo(
        () =>
            filteredTabs.map((tab, index) => (
                <TabPanel
                    // eslint-disable-next-line react/no-array-index-key
                    key={String(index)}
                    value={tab.urlName ?? String(index)}
                    sx={{ ...tab.tabPanelStyling, p: 0 }}
                >
                    <IfHasAllPermissions permissions={tab.permissions}>
                        {tab.content}
                    </IfHasAllPermissions>
                </TabPanel>
            )),
        [filteredTabs],
    );

    const tabList = useMemo(
        () => (
            <TabList
                onChange={handleChange}
                // TabIndicatorProps={{ style: { display: 'none' } }}
                scrollButtons="auto"
                centered
                variant="fullWidth"
                {...tabListProps}
                sx={sx}
                TabIndicatorProps={
                    type === 'primary' ? TabIndicatorProps : TabIndicatorPropsSecondary
                }
                css={css('.MuiTabs-scroller{overflow: visible !important;}')}
            >
                {styledTabs}
            </TabList>
        ),
        [afterTabs],
    );

    return (
        <>
            <TabContext value={openTab}>
                {!afterTabs ? (
                    tabList
                ) : (
                    <Stack direction="row">
                        {tabList}
                        {afterTabs}
                    </Stack>
                )}
                {tabPanels}
            </TabContext>
        </>
    );
};

type TabLabelProps = {
    tab: TabSettings;
};

const TabLabel: React.FC<TabLabelProps> = ({ tab }) => {
    const t = useCommonTranslation();
    if (tab.badgeCount != null && Number.isFinite(tab.badgeCount)) {
        return (
            <Badge
                badgeContent={tab.badgeCount}
                max={tab.maxCount ?? 99}
                color={tab.color ?? 'primary'}
            >
                {typeof tab.title === 'string' ? t(tab.title) : tab.title}
            </Badge>
        );
    }

    return <>{typeof tab.title === 'string' ? t(tab.title) : tab.title}</>;
};

export default TabsWrapper;
