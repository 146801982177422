import React, { useEffect, useMemo } from 'react';
import { BudgetSummaryLineItem, Displays_LineItem, Displays_Owner as Owner } from '@uniled/api-sdk';
import { Box, CircularProgress, List, ListItem, ListSubheader, Typography } from '@mui/material';
import { useEntityData, usePaginatedEntityData } from 'c-data';
import { useSelector } from 'react-redux';
import { displaysLineItemsSelectors } from 'c-main/Slices';
import { NetworkRequestState } from '@uniled/data-layer';
import { AutoGrid, EntityField, ListItemText } from 'c-components';
import { Translate, useCommonTranslation, useModelFieldTranslation } from 'c-translation';
import { useDateUtils } from 'c-hooks';

type Props = {
    lineItems?: BudgetSummaryLineItem[];
};

const CampaignBudgetSummaryLineItems: React.FC<Props> = ({ lineItems }) => {
    const ids = useMemo(() => lineItems.map(i => i.line_item_id), [lineItems]);
    const { reset, getPaginationData, paginatedData } = usePaginatedEntityData<Displays_LineItem>(
        'budget-summary-items',
        'Displays_LineItem',
    );

    const byOwner = useSelector(displaysLineItemsSelectors.lineItemsByOwner)(ids);
    useEffect(() => {
        getPaginationData(
            {
                filters: { 'filter.id': ids },
                perPage: ids.length,
                includes: ['owner'],
            },
            true,
        );
        return () => {
            reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const noOwnerList = useMemo(() => {
        if (byOwner.noOwner?.length > 0) {
            return (
                <LineItemsByOwner
                    lineItems={byOwner.noOwner}
                    lineItemsSummaries={lineItems.filter(
                        i => byOwner.noOwner.indexOf(i.line_item_id) !== -1,
                    )}
                />
            );
        }

        return null;
    }, [byOwner.noOwner, lineItems]);

    const byOwnerLists = useMemo(
        () =>
            Object.entries(byOwner.byOwner).reduce((views, [ownerId, lineItemIds]) => {
                views.push(
                    <LineItemsByOwner
                        key={ownerId}
                        ownerId={+ownerId}
                        lineItems={lineItemIds}
                        lineItemsSummaries={lineItems.filter(
                            i => lineItemIds.indexOf(i.line_item_id) !== -1,
                        )}
                    />,
                );
                return views;
            }, [] as React.ReactNode[]),
        [byOwner.byOwner, lineItems],
    );

    const isLoading = useMemo(
        () => paginatedData?.loadingState?.state === NetworkRequestState.InProgress,
        [paginatedData?.loadingState?.state],
    );

    return (
        <>
            <Box textAlign="center" my={2}>
                {isLoading && <CircularProgress />}
            </Box>
            <Box>
                {!isLoading && (
                    <List disablePadding>
                        {noOwnerList}
                        {byOwnerLists}
                    </List>
                )}
            </Box>
        </>
    );
};

const LineItemsByOwner: React.FC<{
    ownerId?: number;
    lineItems: number[];
    lineItemsSummaries: BudgetSummaryLineItem[];
}> = ({ ownerId, lineItems, lineItemsSummaries }) => {
    return (
        <>
            <ListSubheader disableSticky sx={{ pt: 2 }}>
                {ownerId && (
                    <Typography variant="h4">
                        <EntityField<Owner> id={ownerId} entityName="Displays_Owner" field="name" />{' '}
                        ({lineItems.length})
                    </Typography>
                )}
            </ListSubheader>
            {lineItemsSummaries.map(sum => (
                <ListItem key={sum.id} divider>
                    <ListItemText
                        primary={
                            <EntityField<Displays_LineItem>
                                id={sum.line_item_id}
                                entityName="Displays_LineItem"
                                field="name"
                            />
                        }
                        secondary={
                            <LineItemDetails lineItemId={sum.line_item_id} lineItemsSummary={sum} />
                        }
                        secondaryTypographyProps={{ component: 'div' as any }}
                    />
                    <Translate
                        path="Modules.Main.Campaigns.Overview.Costs.dayCount"
                        data={{ count: sum.days }}
                    />
                </ListItem>
            ))}
        </>
    );
};

const LineItemDetails: React.FC<{
    lineItemId: number;
    lineItemsSummary: BudgetSummaryLineItem;
}> = ({ lineItemId, lineItemsSummary }) => {
    const { getById } = useEntityData<Displays_LineItem>('Displays_LineItem');
    const lineItem = getById({ id: lineItemId });
    const mt = useModelFieldTranslation();

    const { formatDateStringIgnoreTime } = useDateUtils();
    const from = useMemo(
        () =>
            lineItemsSummary.start_date != null
                ? formatDateStringIgnoreTime({ date: lineItemsSummary.start_date })
                : null,
        [formatDateStringIgnoreTime, lineItemsSummary],
    );
    const to = useMemo(
        () =>
            lineItemsSummary.start_date != null
                ? formatDateStringIgnoreTime({ date: lineItemsSummary.end_date })
                : null,
        [formatDateStringIgnoreTime, lineItemsSummary],
    );

    const fromTo = useMemo(() => {
        if (from == null && to == null) return null;

        return (
            <Box>
                <AutoGrid spacing={1}>
                    <Box>{from}</Box> <>{'->'}</> <Box>{to}</Box>
                </AutoGrid>
            </Box>
        );
    }, [from, to]);

    return (
        <>
            <Box>
                <AutoGrid spacing={1}>
                    <Box>
                        {useCommonTranslation(
                            'Modules.Main.Campaigns.Create.LineItemsTable.environmentHeader',
                        )}
                        : {lineItem.environment}
                    </Box>
                    <Box>
                        {useCommonTranslation(
                            'Modules.Main.Campaigns.Create.LineItemsTable.typeHeader',
                        )}
                        : {mt('Displays_LineItem', 'type', lineItem.type)}
                    </Box>
                    <Box>
                        {useCommonTranslation(
                            'Modules.Main.Campaigns.Create.LineItemsTable.sizeHeader',
                        )}
                        : {lineItem.size}
                    </Box>
                </AutoGrid>
            </Box>
            {fromTo}
        </>
    );
};

export default CampaignBudgetSummaryLineItems;
