import { Box, Typography } from '@mui/material';
import React from 'react';
import { useCommonTranslation } from 'c-translation';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Filter, Select } from '../../types';

type FilterProps = { filter: Filter; onSelect: Select };

const useStyles = makeStyles((theme: Theme) => ({
    filterBox: {
        backgroundColor: theme.palette.grey[500],
        color: theme.palette.grey[300],
        padding: '2px 10px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        '&:hover': {
            color: theme.palette.grey[200],
            backgroundColor: theme.palette.grey[600],
        },
    },
}));

const SystemSearchFilter = ({ filter: { prefix, description }, onSelect }: FilterProps) => (
    <Box
        id={`system-search-filter-${prefix}`}
        className={useStyles().filterBox}
        onClick={() => onSelect(prefix)}
    >
        <Typography variant="body2">
            <b>{prefix}</b>: {useCommonTranslation(description)}
        </Typography>
    </Box>
);

export default SystemSearchFilter;
