import { useFormContext } from 'react-hook-form';
import { useAPIClientRequest, useAuthenticatedUser } from 'c-hooks';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { apiClient, useEntityData } from 'c-data';
import React, { useCallback, useEffect, useMemo } from 'react';
import { PermissionName, Buyer } from '@uniled/api-sdk';
import {
    Autocomplete,
    ControlledFormInput,
    EntityAutocomplete,
    OptionSchema,
    SelectField,
} from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { CampaignFormNames } from 'c-main/Types';
import to from 'await-to-js';
import { Box, Stack } from '@mui/material';

const BuyerDropdown = () => {
    const t = useCommonTranslation();
    const { setValue, watch } = useFormContext();
    const { user, userBuyers } = useAuthenticatedUser();
    const { hasSome } = useUserPermissions();
    const { getById } = useEntityData<Buyer>('Buyer');
    const showAllBuyers = useMemo(
        () =>
            hasSome([
                PermissionName.UniledportalBuyerUpdate,
                PermissionName.UniledportalBuyerCreate,
                PermissionName.Admin,
            ]),
        [hasSome],
    );

    const buyerOptions = useMemo<OptionSchema[]>(
        () =>
            userBuyers.map(buyerId => ({
                label: getById({ id: buyerId })?.name,
                value: buyerId,
            })),
        [userBuyers, getById],
    );
    useEffect(() => {
        if (!showAllBuyers && buyerOptions?.length > 0) {
            setTimeout(() => {
                // basically need a frame (but taking longer) because the buyer component is rendered before the reference input
                // if the value is changed before `watch` is called to subscribe to the change in the reference component
                // the watch subscriber won't be re-rendered
                setValue(CampaignFormNames.buyer, buyerOptions[0].value);
            }, 200);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // use our custom API hook to provide us with a function for loading a buyer by id
    const { start, data } = useAPIClientRequest(apiClient.Entities.Buyer.get);
    const loadSelectedBuyer = useCallback(
        async (buyerId: number) => {
            // wrap api call in in to(...) as it catches exceptions for us automatically
            await to(start(buyerId, ['users']));
        },
        [start],
    );
    const currentBuyerId = watch(CampaignFormNames.buyer);
    useEffect(() => {
        if (currentBuyerId != null) {
            // when the currently selected buyer changes, load that buyer's user list if its not null
            loadSelectedBuyer(currentBuyerId);
        } else {
            setValue(CampaignFormNames.additional_contacts, null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBuyerId]);
    const buyerUsers = useMemo(
        () => (currentBuyerId == null ? [] : data?.data?.data?.users ?? []),
        [currentBuyerId, data?.data?.data?.users],
    );
    const buyerUserOptions = useMemo<OptionSchema[]>(
        // turn the list of buyer users into an array of OptionSchema objects
        // those objects can then be given to SelectField component
        () => buyerUsers.map(user => ({ label: user.name, value: user.id })),
        [buyerUsers],
    );

    const contactsDropdown = useMemo(() => {
        if (buyerUserOptions.length === 0) return null;
        return (
            <Stack gap={2}>
                <Box pt={1}>
                    <ControlledFormInput
                        name={CampaignFormNames.additional_contacts}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                id="campaign-additional-contacts"
                                multiple
                                options={buyerUserOptions}
                                textFieldProps={{
                                    label: t(
                                        'Modules.Main.Campaigns.Create.additionalContactsDropdownLabel',
                                    ),
                                }}
                            />
                        )}
                    />
                </Box>
            </Stack>
        );
    }, [buyerUserOptions, t]);
    if (!showAllBuyers && buyerOptions?.length === 1) {
        // will auto select first buyer
        return null;
    }

    if (showAllBuyers) {
        return (
            <ControlledFormInput
                name={CampaignFormNames.buyer}
                render={({ field }) => (
                    <Stack>
                        <EntityAutocomplete
                            {...field}
                            id="campaign-buyer"
                            entityName="Buyer"
                            textFieldProps={{
                                label: t('Modules.Main.Campaigns.Create.buyerDropdownLabel'),
                                required: true,
                            }}
                            searchColumns="name"
                            labelColumn="name"
                        />
                        {contactsDropdown}
                    </Stack>
                )}
            />
        );
    }

    return (
        <Stack>
            <ControlledFormInput
                name={CampaignFormNames.buyer}
                render={({ field }) => (
                    <SelectField
                        {...field}
                        options={buyerOptions}
                        label={t('Modules.Main.Campaigns.Create.buyerDropdownLabel')}
                        required
                    />
                )}
            />
            {contactsDropdown}
        </Stack>
    );
};

export default BuyerDropdown;
