import { useCallback, useEffect, useMemo } from 'react';
import { atom, useAtom } from 'jotai';
import { Displays_LineItem, SchedulableType, ScheduleGet } from '@uniled/api-sdk';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { NetworkRequestState } from '@uniled/data-layer';
import to from 'await-to-js';
import { sortEntityAsc } from 'c-data';
import { uniq } from 'ramda';
import { extractSchedulableRowId, generateSchedulableRowId } from 'c-main/Components/Schedule/lib';
import _ from 'lodash';

const atom_campaignId = atom<number>(null as number);
const atom_lineItems = atom<Displays_LineItem[]>([]);
const atom_schedules = atom<ScheduleGet[]>([]);
const atom_selectedLineItems = atom<string[]>([]);
const atom_selectedScreens = atom<number[]>([]);
const maxItemsPerPage = 999999;

const includes: string[] = [
    'schedulable',
    'rules',
    'schedule',
    'schedulable.owner',
    'schedulable.resolutions',
    'schedulable.fileTypes',
    'schedulable.screensCount',
];
function transformAndDeduplicate(input) {
    const transformed = input.map(item => ({
        id: item.schedulable.id,
        name: item.schedulable.name,
        type: item.schedulable.type,
        market_id: item.schedulable.market_id,
        animation: item.schedulable.animation,
        description: item.schedulable.description,
        size: item.schedulable.size,
        slot_length: item.schedulable.slot_length,
        slots: item.schedulable.slots,
        environment: item.schedulable.environment,
        brochure: item.schedulable.brochure,
        brochure_link: item.schedulable.brochure_link,
        owner_id: item.schedulable.owner_id,
        created_at: item.schedulable.created_at,
        updated_at: item.schedulable.updated_at,
        deleted_at: item.schedulable.deleted_at,
        owner: {
            ...item.schedulable.owner,
        },
        screensCount: item.schedulable.screensCount,
        fileTypes: [...item.schedulable.fileTypes],
        resolutions: item.schedulable.resolutions.map(resolution => ({
            ...resolution,
        })),
    }));

    const uniqueById = _.uniqBy(transformed, 'id');
    return uniqueById;
}
function transformDataToSchedulesArray(data) {
    if (!data) return [];

    const schedulesMap = data.reduce((acc, item) => {
        const scheduleId = item.schedule_id;
        const scheduleData = {
            id: item.schedule.id,
            campaign_id: item.schedule.campaign_id,
            name: item.schedule.name,
            template: item.schedule.template,
            created_at: item.schedule.created_at,
            updated_at: item.schedule.updated_at,
            schedulables: [],
            rules: item.rules,
        };

        if (!acc[scheduleId]) {
            acc[scheduleId] = scheduleData;
        }

        acc[scheduleId].schedulables.push({
            id: item.id,
            schedule_id: item.schedule_id,
            schedulable_id: item.schedulable_id,
            schedulable_type: item.schedulable_type,
            campaign_id: item.campaign_id,
            proof_of_play: item.proof_of_play,
            sov: item.sov,
            created_at: item.created_at,
            updated_at: item.updated_at,
        });

        return acc;
    }, {});

    return Object.values(schedulesMap);
}

function useCampaignSchedule(id: number) {
    const [currentCampaignId, setCurrentCampaignId] = useAtom(atom_campaignId);
    const [lineItems, setLineItems] = useAtom(atom_lineItems);
    const [schedules, setSchedules] = useAtom(atom_schedules);
    const [selectedLineItems, setSelectedLineItems] = useAtom(atom_selectedLineItems);
    const [selectedScreens, setSelectedScreens] = useAtom(atom_selectedScreens);

    useEffect(() => {
        if (id !== currentCampaignId) {
            setCurrentCampaignId(id);
            setLineItems([]);
            setSchedules([]);
            setSelectedLineItems([]);
            setSelectedScreens([]);
        }
    }, [
        id,
        currentCampaignId,
        setCurrentCampaignId,
        setLineItems,
        setSchedules,
        setSelectedLineItems,
        setSelectedScreens,
    ]);
    const {
        start,
        requestStateRef: requestStateRefLineItemSchedulables,
        isLoading,
    } = useAPIClientRequest(apiClient.Entities.Campaign.lineItemSchedualables);
    const fetchLineItemSchedulables = useCallback(
        async (includes, perPage) => {
            if (requestStateRefLineItemSchedulables.current === NetworkRequestState.InProgress)
                return;
            const [err, success] = await to(start(id, includes, perPage));
            if (!err && success && success?.data && Array.isArray(success?.data?.data)) {
                setSchedules(
                    transformDataToSchedulesArray(success?.data?.data as any) as ScheduleGet[],
                );
                setLineItems(
                    transformAndDeduplicate(
                        success?.data?.data as any,
                    ) as unknown as Displays_LineItem[],
                );
            }
        },
        [id, requestStateRefLineItemSchedulables, setLineItems, setSchedules, start],
    );

    const updateSchedules = useCallback(
        (newSchedules: ScheduleGet[]) => {
            const newScheduleList = [...schedules];

            newSchedules.forEach(schedule => {
                const index = schedules.findIndex(s => s.id === schedule.id);
                if (index == -1) newScheduleList.push(schedule);
                else newScheduleList[index] = schedule;
            });

            setSchedules(newScheduleList);
        },
        [setSchedules, schedules],
    );

    const removeSchedules = useCallback(
        (scheduleIds: number[]) => {
            setSchedules(schedules.filter(s => scheduleIds.indexOf(s.id) === -1));
        },
        [setSchedules, schedules],
    );

    const {
        start: getCampaignLineItems,
        isLoading: areLineItemsLoading,
        requestStateRef: lineItemsRef,
        error: lineItemsError,
    } = useAPIClientRequest(apiClient.Entities.Campaign.lineItems);

    const fetchLineItems = useCallback(async () => {
        if (lineItemsRef.current === NetworkRequestState.InProgress) return;

        const [err, success] = await to(
            getCampaignLineItems(id, {
                includes: ['owner', 'resolutions', 'fileTypes', 'screensCount'],
            }),
        );

        if (!err && success && success?.data && Array.isArray(success?.data?.data)) {
            setLineItems(uniq(success.data.data));
        }
    }, [lineItemsRef, getCampaignLineItems, id, setLineItems]);

    const {
        start: GetCampaignSchedules,
        isLoading: areSchedulesLoading,
        requestStateRef,
        error: schedulesError,
    } = useAPIClientRequest(apiClient.Entities.Schedule.campaignSchedules);

    const fetchSchedules = useCallback(async () => {
        if (requestStateRef.current === NetworkRequestState.InProgress) return;

        const [err, success] = await to(
            GetCampaignSchedules({
                campaign_id: id,
                includes: ['rules', 'schedulables'],
            }),
        );

        if (!err && success && success?.data && Array.isArray(success?.data?.data)) {
            setSchedules(success.data.data);
        }
    }, [requestStateRef, GetCampaignSchedules, id, setSchedules]);

    // assuming this is used within the CampaignSchedulePage which includes the campaign's line items
    // const lineItems = useMemo(() => uniq(campaign?.lineItems ?? []), [campaign]);
    // const filters = useMemo(() => ({owners: schedules.m}), [schedules])

    const orderedSchedules = useMemo(() => schedules.sort(sortEntityAsc), [schedules]);
    const selectedSchedulables = useMemo(
        () => [
            ...selectedLineItems.map(id => generateSchedulableRowId(id, SchedulableType.LineItem)),
            ...selectedScreens.map(id => generateSchedulableRowId(id, SchedulableType.Screen)),
        ],
        [selectedLineItems, selectedScreens],
    );

    const setSelectedSchedulables = useCallback(
        (ids: string[] | number[]) => {
            /**
             * we're prefixing screen and line item ids because they refer to different backend models
             * so we need to send those ids in different fields to the API when we want to
             * associate a line item vs a screen with a schedule
             */

            const schedulables = ids.map(sid => extractSchedulableRowId(sid));
            setSelectedLineItems(
                schedulables
                    .filter(s => s.type === SchedulableType.LineItem)
                    .map(s => String(s.id)),
            );
            setSelectedScreens(
                schedulables.filter(s => s.type === SchedulableType.Screen).map(s => Number(s.id)),
            );
        },
        [setSelectedLineItems, setSelectedScreens],
    );

    const fetchData = useCallback(() => {
        fetchLineItemSchedulables(includes, maxItemsPerPage);
    }, [fetchLineItemSchedulables]);

    return useMemo(
        () => ({
            fetchData,
            fetchLineItems,
            fetchSchedules,

            schedules: orderedSchedules,
            areSchedulesLoading,
            schedulesError,

            lineItems,
            areLineItemsLoading,
            lineItemsError,

            isDataLoading: areSchedulesLoading || areLineItemsLoading || isLoading,

            selectedLineItems,
            setSelectedLineItems,
            selectedScreens,
            setSelectedScreens,
            selectedSchedulables,
            setSelectedSchedulables,

            updateSchedules,
            removeSchedules,
        }),
        [
            fetchData,
            fetchLineItems,
            fetchSchedules,
            orderedSchedules,
            areSchedulesLoading,
            schedulesError,
            lineItems,
            areLineItemsLoading,
            lineItemsError,
            isLoading,
            selectedLineItems,
            setSelectedLineItems,
            selectedScreens,
            setSelectedScreens,
            selectedSchedulables,
            setSelectedSchedulables,
            updateSchedules,
            removeSchedules,
        ],
    );
}

export default useCampaignSchedule;
