import React, { useCallback, useState } from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';
import { ToggleButtonGroup } from 'c-components';

const TypographyExamples = () => {
    const [color, setColor] = useState('textPrimary');

    return (
        <>
            <ToggleButtonGroup
                color="primary"
                exclusive
                value={color}
                onChange={setColor}
                options={[
                    {
                        label: 'Text Primary',
                        value: 'textPrimary',
                    },
                    {
                        label: 'Text Secondary',
                        value: 'textSecondary',
                    },
                    {
                        label: 'Primary',
                        value: 'primary.main',
                    },
                    {
                        label: 'Secondary',
                        value: 'secondary.main',
                    },
                    {
                        label: 'Success',
                        value: 'success.main',
                    },
                    {
                        label: 'Warning',
                        value: 'warning.main',
                    },
                    {
                        label: 'Info',
                        value: 'info.main',
                    },
                    {
                        label: 'Error',
                        value: 'error.main',
                    },
                ]}
            />
            <Box my={2}>
                <All color={color} />
            </Box>
        </>
    );
};

const All: React.FC<{ color: TypographyProps['color'] }> = ({ color }) => (
    <>
        <Typography variant="h1" color={color}>
            Header 1
        </Typography>
        <Typography variant="h2" color={color}>
            Header 2
        </Typography>
        <Typography variant="h3" color={color}>
            Header 3
        </Typography>
        <Typography variant="h4" color={color}>
            Header 4
        </Typography>
        <Typography variant="h5" color={color}>
            Header 5
        </Typography>
        <Typography variant="h6" color={color}>
            Header 6
        </Typography>
        <Typography variant="body1" color={color}>
            Body 1
        </Typography>
        <Typography variant="body2" color={color}>
            Body 2
        </Typography>
        <Typography variant="button" component="div" color={color}>
            Button
        </Typography>
        <Typography variant="caption" component="div" color={color}>
            Caption
        </Typography>
        <Typography variant="overline" component="div" color={color}>
            Overline
        </Typography>
        <Typography variant="subtitle1" color={color}>
            Subtitle 1
        </Typography>
        <Typography variant="subtitle2" color={color}>
            Subtitle 2
        </Typography>
    </>
);

export default TypographyExamples;
