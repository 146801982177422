import React, { useMemo } from 'react';
import { BaseEntity } from '@uniled/api-sdk';
import { Box } from '@mui/material';
import { RenderColumnDataProps } from 'c-pagination/types';
import EntityDataBooleanColumn from './EntityDataBooleanColumn';
import EntityDataDateRangeColumn from './EntityDataDateRangeColumn';
import EntityDataTextColumn from './EntityDataTextColumn';
import EntityRelationListColumn from './EntityRelationListColumn';
import EntityDataCurrencyColumn from './EntityDataCurrencyColumn';
import EntityDataDateColumn from './EntityDataDateColumn';
import EntityDataFileSizeColumn from './EntityDataFileSizeColumn';

function EntityDataColumn<Entity extends BaseEntity>({
    column,
    entity,
    highlightText,
    columnText,
}: RenderColumnDataProps<Entity>) {
    const content = useMemo(() => {
        if (column.dataType === 'boolean') {
            return (
                <EntityDataBooleanColumn
                    value={columnText as unknown as boolean}
                    boolProps={column.booleanProps}
                />
            );
        }

        if (column.dataType === 'dateRange') {
            return (
                <EntityDataDateRangeColumn entity={entity} dateRangeProps={column.dateRangeProps} />
            );
        }

        if (column.dataType === 'relationList') {
            return (
                <EntityRelationListColumn
                    entity={entity}
                    relationProps={column.relationListProps}
                />
            );
        }

        if (column.dataType === 'currency') {
            return <EntityDataCurrencyColumn value={columnText} />;
        }

        if (column.dataType === 'date') {
            return <EntityDataDateColumn value={columnText} dateProps={column.dateProps} />;
        }

        if (column.dataType === 'fileSize') {
            return <EntityDataFileSizeColumn value={columnText} />;
        }

        return (
            <EntityDataTextColumn
                value={columnText != null ? String(columnText) : ''}
                highlightText={highlightText}
                wrapInTooltip={column.wrapContentInTooltip}
            />
        );
    }, [
        column.wrapContentInTooltip,
        column.dataType,
        column.booleanProps,
        column.dateRangeProps,
        column.relationListProps,
        columnText,
        highlightText,
        entity,
    ]);

    return (
        <Box textAlign={column.textAlign} sx={column.contentWrapperSx}>
            {content}
        </Box>
    );
}

export default EntityDataColumn;
