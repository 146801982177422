import React, { useEffect } from 'react';
import { CardContent } from '@mui/material';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { agencyIcon } from 'c-main/icons';
import { EntityField, TransparentCard } from 'c-components';
import { PermissionName, Agency } from '@uniled/api-sdk';
import { useEntityPage } from 'c-hooks';
import ReactGA from 'react-ga';
import AgencyOverviewDetailTabs from './Components/AgencyOverviewDetailTabs';

const AgencyOverviewPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { id } = useEntityPage();
    return (
        <TransparentCard title={<EntityField<Agency> id={id} entityName="Agency" field="name" />}>
            <CardContent>
                <AgencyOverviewDetailTabs id={id} />
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageEntityConfig<Agency> = {
    id: 'AgencyOverviewPage',
    component: AgencyOverviewPage,
    systemSearch: {
        title: undefined,
        type: 'entity',
        icon: agencyIcon,
        // aliases: undefined,
        route: PostAuthRoutes.Agency.AgencyOverview,
        prefix: 'SystemSearch.prefixes.agency',
        prefixDescription: 'SystemSearch.prefixDescriptions.agency',
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Main.Agency.List.metaTitle', link: PostAuthRoutes.Agency.AgencyList },
        ],
        entityName: 'Agency',
        searchColumn: ['name'],
        nameGen: (entity, id) => entity?.name ?? String(id),
        permissions: [
            PermissionName.UniledportalAgencyRead,
            PermissionName.UniledportalAgencyUpdate,
            PermissionName.UniledportalAgencyCreate,
        ],
    },
};

export default setup;
