import { Displays_AnalogueScreen } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { Displays_AnalogueScreenState } from './Displays_AnalogueScreen-types';

const initialState: Displays_AnalogueScreenState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: Displays_AnalogueScreenState, action: PayloadAction<string>): Displays_AnalogueScreenState => state,
};

const slice = createBaseSlice<
    Displays_AnalogueScreen,
    Displays_AnalogueScreenState,
    typeof additionalReducers
>({
    name: 'displays_AnalogueScreen',
    entityName: 'Displays_AnalogueScreen',
    initialState,
    reducers: additionalReducers,
});

const displays_AnalogueScreenSlice = {
    displays_AnalogueScreenActions: slice.slice.actions,
    displays_AnalogueScreenReducer: slice.slice.reducer,
    displays_AnalogueScreenAdapter: slice.adapter,
    displays_AnalogueScreenSelectors: slice.selectors,
    displays_AnalogueScreenThunks: slice.thunks,
};

export default displays_AnalogueScreenSlice;
export const {
    displays_AnalogueScreenReducer,
    displays_AnalogueScreenActions,
    displays_AnalogueScreenAdapter,
    displays_AnalogueScreenSelectors,
    displays_AnalogueScreenThunks,
} = displays_AnalogueScreenSlice;
