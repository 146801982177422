import React from 'react';
import { Collapse, IconButton, ListItem } from '@mui/material';
import { AutoGrid, ListItemIcon, ListItemText } from 'c-components';
import { Delete } from '@mui/icons-material';
import { Displays_LineItem } from '@uniled/api-sdk';
import { getHumanReadableLineItemResolutions } from 'c-main/Components/CreativeManagement/lib';

type Props = {
    uniqueLineItemIds: string[];
    removeLineItemIndex: (index: number) => void;
    lineItems: Displays_LineItem[];
};

const BulkAttachFoundFrames: React.FC<Props> = ({
    uniqueLineItemIds,
    removeLineItemIndex,
    lineItems,
}) => (
    <>
        <Collapse in sx={{ maxHeight: '70vh', overflow: 'auto' }}>
            <AutoGrid spacing={0} xs={12} justifyContent="flex-start">
                {lineItems
                    ?.filter(lineItem => uniqueLineItemIds.indexOf(lineItem.id) !== -1)
                    .map(lineItem => (
                        <ListItem key={lineItem.id}>
                            <ListItemIcon>
                                <IconButton
                                    onClick={() => {
                                        removeLineItemIndex(uniqueLineItemIds.indexOf(lineItem.id));
                                    }}
                                >
                                    <Delete />
                                </IconButton>
                            </ListItemIcon>
                            <ListItemText
                                primary={lineItem.name}
                                secondary={
                                    <AutoGrid gap={2}>
                                        <>{lineItem.owner?.name}</>
                                        <>{getHumanReadableLineItemResolutions([lineItem])}</>
                                        <>{lineItem.size}</>
                                    </AutoGrid>
                                }
                            />
                        </ListItem>
                    ))}
            </AutoGrid>
        </Collapse>
    </>
);

export default BulkAttachFoundFrames;
