import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { PreAuthRoutes } from 'c-routes';

type Props = React.PropsWithChildren<
    { authenticated: boolean; redirectPath?: string } & RouteProps
>;

const PrivateRoute: React.FC<Props> = ({
    children,
    authenticated = false,
    redirectPath = PreAuthRoutes.Login,
    ...rest
}) => (
    <Route
        {...rest}
        render={({ location }) =>
            authenticated ? (
                children
            ) : (
                <Redirect
                    to={{
                        pathname: redirectPath,
                        state: { from: location },
                    }}
                />
            )
        }
    />
);

export default PrivateRoute;
