import React, { useCallback } from 'react';
import { useUpdateEntityData } from 'c-data';
import { BaseEntity } from '@uniled/api-sdk';
import EntityDialog, { EntityDialogProps } from '../EntityDialog/EntityDialog';

const UpdateEntityDialog = <Entity extends BaseEntity>({
    entityName,
    entityId,
    includes,
    ...props
}: EntityDialogProps<Entity> & { entityId: number; includes?: string[] }) => {
    const { getUpdatingById, resetUpdatingById, update } = useUpdateEntityData<Entity>(entityName);
    const updating = getUpdatingById(entityId);
    const resetState = useCallback(() => {
        resetUpdatingById(entityId);
    }, [resetUpdatingById, entityId]);
    const postEntity = useCallback(
        (entity: Partial<Entity>) => {
            update(entityId, entity as Entity, includes);
        },
        [entityId, update, includes],
    );
    if (!entityId) return null;
    return (
        <EntityDialog<Entity>
            {...props}
            id={entityId}
            state={updating.state}
            error={updating.error}
            resetState={resetState}
            postEntity={postEntity}
            formOptions={{ defaultValues: props.defaultData, ...props.formOptions }}
        />
    );
};

export default UpdateEntityDialog;
