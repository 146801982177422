import React from 'react';
import { createRoot } from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';
import App from './Application/App';

if (import.meta.env.VITE_MUI_X_LICENSE != null && import.meta.env.VITE_MUI_X_LICENSE !== '') {
    LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE);
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
