import React, { Ref, useMemo } from 'react';
import merge from 'deepmerge';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';

export type ButtonProps = MuiButtonProps & {
    iconChild?: boolean;
};

const baseIconButtonSx = {
    borderRadius: '50%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};
const smallSize = 27;
const smallIconSx = {
    ...baseIconButtonSx,
    width: smallSize,
    height: smallSize,
    maxWidth: smallSize,
    minWidth: smallSize,
};

const medSize = 34;
const mediumIconSx = {
    ...baseIconButtonSx,
    width: medSize,
    height: medSize,
    maxWidth: medSize,
    minWidth: medSize,
};

const largeSize = 44;
const largeIconSx = {
    ...baseIconButtonSx,
    width: largeSize,
    height: largeSize,
    maxWidth: largeSize,
    minWidth: largeSize,
};

const Button = (
    {
        iconChild = false,
        variant = 'contained',
        color = 'primary',
        size = 'medium',
        sx,
        ...rest
    }: ButtonProps,
    ref: Ref<any>,
) => {
    const baseSx = useMemo(() => {
        if (!iconChild) return {};
        if (size === 'small') return smallIconSx;
        if (size === 'medium') return mediumIconSx;
        if (size === 'large') return largeIconSx;
        return {};
    }, [size, iconChild]);

    const mergedSx = useMemo(() => merge(baseSx, sx ?? ({} as any)), [baseSx, sx]);
    return (
        <MuiButton
            variant={variant}
            color={color}
            sx={mergedSx}
            size={size}
            disableElevation
            {...rest}
            ref={ref}
        />
    );
};

export default React.forwardRef(Button);
