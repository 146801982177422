import React from 'react';
import { AutoGrid, Button } from 'c-components';
import { Box, Link, Typography } from '@mui/material';
import { ChatMessageAttachment } from '../../types';
import AttachmentIcon from '../AttachmentIcon';

type Props = {
    attachments: ChatMessageAttachment[];
};

const MessageAttachments: React.FC<Props> = ({ attachments }) => {
    return (
        <AutoGrid xs="auto" gap={1}>
            {attachments.map((a, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Attachment key={idx} attachment={a} />
            ))}
        </AutoGrid>
    );
};

const btnSx = {
    borderRadius: 1,
    bgcolor: 'grey.300',
    borderColor: 'transparent',
    p: 1,
    color: 'grey.700',
    boxShadow: 1,
};

const Attachment: React.FC<{ attachment: ChatMessageAttachment }> = ({ attachment }) => {
    return (
        <Button
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={Link}
            href={attachment.downloadUrl}
            // @ts-ignore
            target="_blank"
            variant="outlined"
            sx={btnSx}
        >
            <AutoGrid gap={0.5} alignItems="center" xs flexWrap="nowrap">
                <Box display="flex" alignItems="center">
                    <AttachmentIcon
                        mimetype={attachment.mimetype}
                        // fontSize="inherit"
                        color="inherit"
                    />
                </Box>
                <Typography variant="caption" component="p" title={attachment.name} noWrap>
                    {attachment.name}
                </Typography>
            </AutoGrid>
        </Button>
    );
};
export default MessageAttachments;
