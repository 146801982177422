import React, { Ref, useCallback, useMemo } from 'react';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import { useTheme } from '@mui/styles';
import { ChartReportProps } from 'c-reports/Types';
import { generateBrush } from 'c-reports/Generators';

type Props = Omit<ChartReportProps<any>, 'onDataUpdated'>;

const chooseColour = (defaultColor: string, override?: string) => {
    if (typeof override === 'string' && override.trim().length > 0) {
        return override;
    }
    return defaultColor;
};

const ReportBarChart = (
    {
        data,
        chartProps,
        brushProps,
        parts,
        xAxisDataKey,
        yAxisDataKey,
        renderTooltipLabel,
        yAxisTickFormatter,
        hideLegend,
        hideTooltip,
        hideBrush,
        xAxisProps,
        yAxisProps,
        chartChildren,
    }: Props,
    ref: Ref<any>,
) => {
    const CustomTooltip = useCallback(
        (tooltipProps: TooltipProps<any, any>) => {
            if (
                renderTooltipLabel &&
                tooltipProps.active &&
                tooltipProps.payload &&
                tooltipProps.payload.length
            ) {
                return renderTooltipLabel(tooltipProps);
            }

            return null;
        },
        [renderTooltipLabel],
    );

    const theme = useTheme();
    const allBars = useMemo(
        () =>
            parts.map(p => (
                <Bar key={p.dataKey} dataKey={p.dataKey} {...(p.barProps as any)}>
                    {data.map(entry => (
                        <Cell
                            key={entry[p.dataKey]}
                            fill={chooseColour(
                                p.barProps?.fill,
                                (p.barProps?.customColors ?? {})?.[entry[xAxisDataKey]] ?? null,
                            )}
                        />
                    ))}
                </Bar>
            )),
        [parts, data, xAxisDataKey],
    );

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                ref={ref}
                data={data}
                margin={{
                    top: 25,
                    right: 50,
                    left: 40,
                    bottom: 5,
                }}
                {...chartProps}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={xAxisDataKey} {...xAxisProps} />
                <YAxis dataKey={yAxisDataKey} tickFormatter={yAxisTickFormatter} {...yAxisProps} />
                {!hideTooltip && (
                    <Tooltip content={renderTooltipLabel ? <CustomTooltip /> : undefined} />
                )}
                {!hideLegend && (
                    <Legend verticalAlign="top" align="right" wrapperStyle={{ top: 5 }} />
                )}
                {allBars}
                {!hideBrush &&
                    generateBrush({
                        brushProps,
                        startIndex: 0,
                        // endIndex: data.length - 1,
                        dataKey: xAxisDataKey,
                        theme,
                    })}
                {[...(chartChildren ?? [])]}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default React.forwardRef(ReportBarChart);
