import { Box, Stack, TableHead } from '@mui/material';
import React from 'react';
import { TableCell, TableRow } from 'c-components';

const GanttChartHeaders = (dateRange: { startDate: Date; endDate: Date }) => {
    const formatDateWithDay = dateString => {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        });
        const dayOfWeek = date.toLocaleDateString('en-GB', { weekday: 'short' });

        return (
            <Stack display="flex" justifyContent="center">
                <Box textAlign="center">{day}</Box>
                <Box textAlign="center">{dayOfWeek}</Box>
            </Stack>
        );
    };

    const dateArray = createDateRangeArray(dateRange.startDate, dateRange.endDate);

    return (
        <TableHead>
            <TableRow>
                {dateArray.map(date => (
                    <TableCell sx={{ padding: '1rem' }} key={date}>
                        {formatDateWithDay(date)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default GanttChartHeaders;

export const createDateRangeArray = (startDate: Date, endDate: Date) => {
    const dateArray = [];
    const currentDate = new Date(startDate);
    while (currentDate <= new Date(endDate)) {
        dateArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
};
