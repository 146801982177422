import React, { forwardRef, Ref, SyntheticEvent, useCallback, useMemo, useRef } from 'react';
import {
    Autocomplete as MuiAutoComplete,
    AutocompleteChangeReason,
    AutocompleteProps as MuiAutocompleteProps,
    Box,
    Chip,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useBoolean } from 'react-hanger';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { TranslationPath, useCommonTranslation } from 'c-translation';
import { TextField } from '../TextField';
import { OptionSchema } from '../formTypes';
import { CustomTextFieldProps } from '../TextField/TextField';

export type AutocompleteProps = Omit<
    MuiAutocompleteProps<any, any, any, any>,
    'options' | 'renderInput' | 'onChange'
> & {
    //
    options: OptionSchema[];
    textFieldProps?: CustomTextFieldProps;
    onChange: (value: any | any[], reason: AutocompleteChangeReason) => void;
    renderOptionCheckboxes?: boolean;
    openHelperPlaceholder?: TranslationPath;
    enableOptionGrouping?: boolean;

    // in case you've added extra text onto your option labels. Trim the extra off so it just
    // filters on the portion of the text you want it to
    alterSearchTerm?: (term: string) => string;

    stayOpenOnSelect?: boolean;
    selectAllOption?: boolean;

    formatPlaceholder?: (values: OptionSchema[]) => string;
};

const checkedCheckbox = <CheckBoxIcon color="primary" sx={{ mr: 0.5 }} />;
const indeterminateCheckbox = <IndeterminateCheckBoxIcon color="primary" sx={{ mr: 0.5 }} />;
const unCheckedCheckbox = <CheckBoxOutlineBlankIcon sx={{ mr: 0.5 }} />;

const selectAllUncheckedOptVal = '____SELECT____ALL_____UNCHECKED';
const selectAllIndeterminateOptVal = '____SELECT____ALL_____INDETERMINDATE____';
const selectAllSelectedOptVal = '____SELECT____ALL_____SELECTED____';

const SelectAllIcons = {
    [selectAllSelectedOptVal]: checkedCheckbox,
    [selectAllIndeterminateOptVal]: indeterminateCheckbox,
    [selectAllUncheckedOptVal]: unCheckedCheckbox,
};

const isSelectAllVal = val =>
    [selectAllUncheckedOptVal, selectAllIndeterminateOptVal, selectAllSelectedOptVal].indexOf(
        val,
    ) !== -1;

const containsSelectAllVal = (opts: OptionSchema[]) => opts.some(opt => isSelectAllVal(opt.value));

const selectAllValue = (opts: OptionSchema[], value: any[]) => {
    if (value?.length >= opts?.length) return selectAllSelectedOptVal;
    if (value?.length > 0) return selectAllIndeterminateOptVal;

    return selectAllUncheckedOptVal;
};

const getOptLabel = (opt: OptionSchema | string) =>
    typeof opt === 'string' ? opt : opt.label ?? '';
const defaultAlterSearchTerm: AutocompleteProps['alterSearchTerm'] = term => term;

const calcFilterOptions = (
    enableOptionGrouping: boolean,
    actualSearchTerm: string,
    options: OptionSchema[],
    selectAllOpt?: OptionSchema,
) => {
    let filteredOpts: OptionSchema[] = [];
    if (!enableOptionGrouping) {
        filteredOpts = options.filter(
            o => String(o.label).toLowerCase().indexOf(actualSearchTerm) !== -1,
        );
    } else {
        // check if the option's label contains the input val string
        // or if the option's group name contains the input val string
        filteredOpts = options.filter(
            o =>
                String(o.label).toLowerCase().indexOf(actualSearchTerm) !== -1 ||
                o.group?.toLowerCase().indexOf(actualSearchTerm) !== -1,
        );
    }

    if (selectAllOpt != null) filteredOpts.unshift(selectAllOpt);

    return filteredOpts;
};

const Autocomplete = (
    {
        textFieldProps,
        multiple,
        onChange,
        value,
        options,
        inputValue,
        renderOptionCheckboxes = false,
        openHelperPlaceholder = 'Forms.autocomplete.defaultOpenPlaceholder',
        enableOptionGrouping = false,
        alterSearchTerm = defaultAlterSearchTerm,
        stayOpenOnSelect = false,
        onClose: OnCloseOverride,
        selectAllOption = false,
        formatPlaceholder,
        ...props
    }: AutocompleteProps,
    ref: Ref<any>,
) => {
    const { value: isOpen, setFalse, setTrue: onOpen } = useBoolean(false);
    const optionFilterSearchTerm = useRef('');

    const onClose = useCallback<MuiAutocompleteProps<any, any, any, any>['onClose']>(
        (e, reason) => {
            if (reason !== 'selectOption' && reason !== 'removeOption') {
                setFalse();
                OnCloseOverride?.(e, reason);
            }
        },
        [setFalse, OnCloseOverride],
    );

    const actualValue = useMemo(() => {
        if (multiple && !Array.isArray(value)) {
            return [];
        }

        return value ?? '';
    }, [multiple, value]);

    const t = useCommonTranslation();
    const selectAllOpt = useMemo<OptionSchema>(() => {
        if (!selectAllOption || !multiple) return null;

        return {
            value: selectAllValue(options, actualValue),
            label: t('Forms.autocomplete.selectAllOptionLabel'),
        };
    }, [selectAllOption, multiple, options, actualValue, t]);

    const onChangeAutocomplete = useCallback<MuiAutocompleteProps<any, any, any, any>['onChange']>(
        (e: SyntheticEvent, values: OptionSchema | OptionSchema[], reason) => {
            if (!multiple) {
                onChange((values as OptionSchema)?.value ?? undefined, reason);
            } else {
                let newValue = [];
                if (reason === 'selectOption' && containsSelectAllVal(values as OptionSchema[])) {
                    if (
                        (values as OptionSchema[]).some(
                            opt => opt.value === selectAllUncheckedOptVal,
                        )
                    ) {
                        // not all options checked, so select all visible options
                        newValue = calcFilterOptions(
                            enableOptionGrouping,
                            optionFilterSearchTerm.current,
                            options,
                            selectAllOpt,
                        ).map(o => o.value);
                    } else if (
                        (values as OptionSchema[]).some(
                            opt => opt.value === selectAllSelectedOptVal,
                        )
                    ) {
                        // all opts are already selected so leave empty
                        // onChange([], reason);
                    }
                } else {
                    newValue = (values as OptionSchema[])?.map(o => o.value) ?? [];
                }

                onChange(
                    newValue.filter(v => !isSelectAllVal(v)),
                    reason,
                );
            }
        },
        [multiple, onChange, enableOptionGrouping, options, selectAllOpt],
    ) as any;

    const values = useMemo(() => options.map(o => o.value), [options]);

    const autoCompleteValue = useMemo<OptionSchema[] | OptionSchema>(() => {
        if (multiple) {
            return options.filter(opt => actualValue.indexOf(opt.value) !== -1);
        }

        return options.filter(opt => (actualValue as any) === opt.value)[0] ?? null;
    }, [actualValue, options, multiple]);

    const shrinkInput = useMemo(() => {
        if (inputValue?.length > 0) {
            return true;
        }
        if (!multiple) {
            return autoCompleteValue != null;
        }
        if (multiple && Array.isArray(autoCompleteValue)) {
            return autoCompleteValue.length > 0;
        }
        return false;
    }, [multiple, autoCompleteValue, inputValue]);

    const defaultPlaceholder = useCommonTranslation(openHelperPlaceholder);

    const formattedPlaceholder = useMemo(() => {
        if (formatPlaceholder == null) return textFieldProps?.placeholder;

        if (multiple && Array.isArray(value)) {
            return formatPlaceholder(options.filter(opt => value.indexOf(opt.value) !== -1));
        }
        if (!multiple && value != null) {
            return formatPlaceholder(options.filter(opt => value === opt.value));
        }

        return formatPlaceholder([]);
    }, [formatPlaceholder, textFieldProps?.placeholder, multiple, value, options]);

    const renderInput: MuiAutocompleteProps<any, any, any, any>['renderInput'] = useCallback(
        textInputProps => (
            <TextField
                {...textInputProps}
                {...textFieldProps}
                InputProps={{ ...textInputProps.InputProps, ...textFieldProps?.InputProps }}
                InputLabelProps={{
                    // types are broken, but the value is actually set
                    shrink: (textInputProps.inputProps as any)?.value?.length > 0 || shrinkInput,
                }}
                placeholder={formattedPlaceholder ?? defaultPlaceholder}
            />
        ),
        [shrinkInput, textFieldProps, defaultPlaceholder, formattedPlaceholder],
    );

    /**
     * Using icons instead of form control checkboxes because MUI checkbox rendering performance is woeful
     */

    const renderOption: MuiAutocompleteProps<any, any, any, any>['renderOption'] = useCallback(
        (optionProps, option: OptionSchema, { selected }) => {
            if (renderOptionCheckboxes) {
                if (isSelectAllVal(option.value)) {
                    return (
                        <li {...optionProps} key={option.value}>
                            {SelectAllIcons[option.value]}
                            {option.label}
                        </li>
                    );
                }
                return (
                    <li {...optionProps} key={option.value}>
                        {selected && checkedCheckbox}
                        {!selected && unCheckedCheckbox}
                        {option.label}
                    </li>
                );
            }

            return (
                <li {...optionProps} key={option.value}>
                    {option.label}
                </li>
            );
        },
        [renderOptionCheckboxes],
    );

    const renderTags: MuiAutocompleteProps<any, any, any, any>['renderTags'] = useCallback(
        (autoCompleteValue: OptionSchema[], getTagProps) =>
            autoCompleteValue
                .filter(val => values.indexOf(val.value) !== -1)
                .map((option, index: number) => (
                    <Chip
                        key={option.label}
                        label={option.label}
                        color="primary"
                        {...getTagProps({ index })}
                    />
                )),
        [values],
    );

    const groupOptions = useCallback((opt: OptionSchema) => opt.group, []);

    const filterOptions: MuiAutocompleteProps<any, any, any, any>['filterOptions'] = useCallback(
        (options: OptionSchema[], state) => {
            const actualSearchTerm = alterSearchTerm(state.inputValue.toLowerCase());
            optionFilterSearchTerm.current = actualSearchTerm;
            return calcFilterOptions(enableOptionGrouping, actualSearchTerm, options, selectAllOpt);
        },
        [alterSearchTerm, enableOptionGrouping, selectAllOpt],
    );

    return (
        <Box width="100%">
            <MuiAutoComplete
                ref={ref}
                fullWidth
                open={stayOpenOnSelect ? isOpen : undefined}
                onOpen={stayOpenOnSelect ? onOpen : undefined}
                onClose={stayOpenOnSelect ? onClose : OnCloseOverride}
                getOptionLabel={getOptLabel}
                isOptionEqualToValue={(opt, val) => opt.value === val.value}
                filterOptions={filterOptions}
                onChange={onChangeAutocomplete}
                renderInput={renderInput}
                renderOption={renderOption}
                renderTags={renderTags}
                multiple={multiple}
                value={autoCompleteValue}
                options={options}
                inputValue={inputValue}
                groupBy={enableOptionGrouping ? groupOptions : undefined}
                noOptionsText={defaultPlaceholder}
                {...props}
            />
        </Box>
    );
};

export default forwardRef(Autocomplete);
