// https://stackoverflow.com/questions/21646738/convert-hex-to-rgba

import { stringWithoutWhitespace } from './strings';

export function hexToRgbA(hex) {
    let c = null;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = `0x${c.join('')}`;
        // eslint-disable-next-line no-bitwise
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},1)`;
    }
    return 'rgba(0,0,0,1)';
}

export function hexToRgbTuple(hex: string) {
    let hexToMatch = hex;
    if (hex.startsWith('#')) hexToMatch = hex.substring(1);
    if (hexToMatch.length !== 6) return [0, 0, 0];

    const aRgbHex = hex.match(/.{1,2}/g);
    return [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)];
}

export function hexToRgbString(hex: string) {
    const [r, g, b] = hexToRgbTuple(hex);
    return `rgb(${r}, ${g}, ${b})`;
}

const matchRgbaColors = /rgba\((\d{1,3}),(\d{1,3}),(\d{1,3}),(([0-1])?|\d(\.\d*)?)\)/;
const matchRgbColors = /rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/;
export function colorStringToRgbaTuple(color: string): string[] {
    const colorToCheck = stringWithoutWhitespace(color);
    let colorParts = [0, 0, 0, 1];
    if (color.startsWith('rgba')) {
        const parts = matchRgbaColors.exec(colorToCheck)?.slice(1, 5);
        if (parts?.length === 4)
            colorParts = [Number(parts[0]), Number(parts[1]), Number(parts[2]), Number(parts[3])];
    }
    if (color.startsWith('rgb(')) {
        const parts = matchRgbColors.exec(colorToCheck)?.slice(1, 4);
        if (parts?.length === 3)
            colorParts = [Number(parts[0]), Number(parts[1]), Number(parts[2]), 1];
    }
    if (color.startsWith('#')) {
        return colorStringToRgbaTuple(hexToRgbA(colorToCheck));
    }

    return colorParts
        .map((part, index) => {
            if (index === 3) return part < 0 || part > 1 ? 1 : part;
            return part > 255 || part < 0 ? 0 : part;
        })
        .map(number => String(number));
}
