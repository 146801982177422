import React, { DOMAttributes, PropsWithChildren, useEffect } from 'react';
import { AnyObjectSchema } from 'yup';
import { DefaultValues, FormProvider, SubmitHandler, useForm, UseFormProps } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export type FormWrapperProps<FormValues extends Record<string, any>> = {
    formOptions?: UseFormProps<FormValues>;
    validationSchema?: AnyObjectSchema;
    onSubmit: SubmitHandler<FormValues>;
    autoResetOnDefaultValues?: boolean;
    formId?: string;
    formProps?: DOMAttributes<HTMLFormElement>;
};

interface FormWrapperContext {
    formId: string | null;
}

export const FormContext = React.createContext<FormWrapperContext>({ formId: null });

function FormWrapper<FormValues>({
    formOptions = {},
    validationSchema,
    onSubmit,
    autoResetOnDefaultValues = true,
    formId,
    children,
    formProps,
}: PropsWithChildren<FormWrapperProps<FormValues>>) {
    const methods = useForm<FormValues>({
        mode: 'all',
        resolver: validationSchema ? yupResolver(validationSchema) : undefined,
        ...formOptions,
    });

    useEffect(() => {
        if (methods && autoResetOnDefaultValues === true) {
            methods.reset(formOptions?.defaultValues as DefaultValues<FormValues>);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formOptions?.defaultValues, autoResetOnDefaultValues]);

    return (
        <FormContext.Provider value={{ formId }}>
            <FormProvider {...(methods as any)}>
                <form onSubmit={methods.handleSubmit(onSubmit)} id={formId} {...formProps}>
                    {children}
                </form>
            </FormProvider>
        </FormContext.Provider>
    );
}

export default FormWrapper;
