import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { AutoGrid, Dialog, FlashMessage } from 'c-components';
import { campaignName } from 'c-main/Lib';
import { NetworkRequestState } from '@uniled/data-layer';
import { useEntityData } from 'c-data';
import { useBoolean, usePrevious } from 'react-hanger';
import { Translate } from 'c-translation';
import { Campaign } from '@uniled/api-sdk';

type Props = {
    newCampaignState: NetworkRequestState;
    error?: string;
    newlyCreatedCampaignId: number;
};

const NewCampaignCreateState: React.FC<Props> = ({
    newCampaignState,
    newlyCreatedCampaignId,
    error,
}) => {
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id: newlyCreatedCampaignId });
    const dialogState = useBoolean(false);

    const prevLoadingState = usePrevious(newCampaignState);

    useEffect(() => {
        if (
            (prevLoadingState === NetworkRequestState.Idle ||
                prevLoadingState === NetworkRequestState.Error) &&
            newCampaignState === NetworkRequestState.InProgress
        ) {
            dialogState.setTrue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newCampaignState]);

    if (newCampaignState === NetworkRequestState.Idle) {
        return null;
    }

    return (
        <Dialog
            title={null}
            removeForm
            onClose={dialogState.setFalse}
            show={dialogState.value}
            content={
                <>
                    <AutoGrid spacing={2} xs={12}>
                        {newCampaignState === NetworkRequestState.InProgress && (
                            <Box display="flex" alignItems="center">
                                <Translate path="Modules.Main.Campaigns.Create.creating" />
                                <CircularProgress sx={{ ml: 2 }} />
                            </Box>
                        )}
                        {newCampaignState === NetworkRequestState.Error && (
                            <FlashMessage status="error">
                                <Translate path="Modules.Main.Campaigns.Create.failedToCreate" />
                            </FlashMessage>
                        )}
                        {campaign && <Box>Created Campaign: {campaignName(campaign)}</Box>}
                    </AutoGrid>
                </>
            }
        />
    );
};

export default NewCampaignCreateState;
