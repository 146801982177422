import React, { useEffect, useMemo } from 'react';
import { PageConfig } from 'c-config';
import { BadRequest, LayoutSetter } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { useDispatch, useSelector } from 'react-redux';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { useIsMobileView, useUserImpersonation } from 'c-hooks';
import { uiActions } from 'c-wrapper/Slices/UI/ui-slice';
import { systemSearchSelectors, MobileSystemSearch } from 'c-system-search';
import { SystemSearchRecentResourceThunks } from '../../../Modules/SystemSearch/Slices/SystemSearchRecentResource';
import Breadcrumbs from '../Breadcrumbs';

const GetPage = ({ page }: { page: PageConfig }) => {
    const { isImpersonating } = useUserImpersonation();
    const isSystemSearchOpen = useSelector(systemSearchSelectors.isOpen);
    const isMobile = useIsMobileView();
    const dispatch = useDispatch();
    const t = useCommonTranslation();

    const staticBreadcrumbs = useMemo(
        () => page?.systemSearch.breadcrumbs ?? [],
        [page?.systemSearch],
    );
    const generatedBreadcrumbs = useMemo(
        () => page?.systemSearch?.generateBreadcrumbs?.(staticBreadcrumbs, t) ?? staticBreadcrumbs,
        [page?.systemSearch, staticBreadcrumbs, t],
    );

    const breadCrumbs = useMemo(
        () =>
            generatedBreadcrumbs.map(x => ({
                ...x,
                label: typeof x.label === 'string' ? t(x.label) : x.label,
            })),
        [generatedBreadcrumbs, t],
    );

    const pageTitle = useCommonTranslation(page.systemSearch.title);

    useEffect(() => {
        if (page.systemSearch.saveAsRecentPage === true && !isImpersonating) {
            dispatch(
                SystemSearchRecentResourceThunks.create({
                    label: pageTitle,
                    resourceIdentifier: page.id,
                    type: 'page',
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const content = useMemo(() => {
        if (isSystemSearchOpen && isMobile) {
            return <MobileSystemSearch />;
        }

        return <page.component />;
    }, [page, isSystemSearchOpen, isMobile]);

    const breadcrumbComponent = useMemo(() => {
        const defaultContent = <Breadcrumbs breadcrumbs={breadCrumbs} />;

        if (page?.systemSearch?.renderBreadcrumbs != null) {
            const Component = page?.systemSearch?.renderBreadcrumbs;
            return <Component breadcrumbs={breadCrumbs} defaultContent={defaultContent} />;
        }

        return defaultContent;
    }, [page?.systemSearch, breadCrumbs]);

    return (
        <IfHasAllPermissions
            permissions={page.systemSearch?.permissions}
            otherwise={<BadRequest statusCode={403} />}
        >
            <>
                {breadcrumbComponent}
                <LayoutSetter
                    metaTitle={page.systemSearch.title}
                    pageId={page.id}
                    disableDesktopContentScrolling={page.disableDesktopContentScrolling}
                />
                {content}
            </>
        </IfHasAllPermissions>
    );
};

export default React.memo(GetPage);
