import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';

type MessageStatus = 'success' | 'error' | 'info' | 'warn';

export type FlashMessageProps = {
    dense?: boolean;
    status?: MessageStatus;
    useTypography?: boolean;
};

type Props = BoxProps & FlashMessageProps;

const STATUS_TO_COLOR = {
    success: { bgcolor: 'success.main', color: 'success.contrastText' },
    error: { bgcolor: 'error.main', color: 'error.contrastText' },
    info: { bgcolor: 'info.main', color: 'info.contrastText' },
    warn: { bgcolor: 'warning.main', color: 'secondary.contrastText' },
};

const DEFAULT_BG_COLOR = '#F4F4F4';
const DEFAULT_COLOR = 'text.primary';

const FlashMessage = ({
    status,
    dense = true,
    useTypography = true,
    children,
    ...boxProps
}: Props) => {
    const padding = dense
        ? { pt: '.8rem', pb: '.9rem', px: 2 }
        : { pt: '1.8rem', pb: '2.1rem', px: 4 };

    const { bgcolor, color } = STATUS_TO_COLOR[status] || {};

    const text = useTypography ? (
        <Typography
            variant="body1"
            color="inherit"
            style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
            component={Box}
        >
            {children}
        </Typography>
    ) : (
        children
    );

    return (
        <Box
            bgcolor={bgcolor || DEFAULT_BG_COLOR}
            color={color || DEFAULT_COLOR}
            borderRadius=".5rem"
            {...padding}
            {...boxProps}
        >
            {text}
        </Box>
    );
};

export default FlashMessage;
