import React, { InputHTMLAttributes, PropsWithChildren, useCallback, useState } from 'react';
import { Box, IconButton, IconButtonProps } from '@mui/material';
import { Alert } from 'c-components/MuiOverrides';

type Props = PropsWithChildren<
    {
        id: string;
        onFileSelected: (file: File[]) => void;
        multiple?: boolean;
        accept?: InputHTMLAttributes<any>['accept'];
    } & IconButtonProps
>;

const isValidFilename = filename => {
    const disallowedChars = /[\\/:*?"<>|~]/;
    return !disallowedChars.test(filename);
};

const FileUploadIconButton: React.FC<Props> = ({
    id,
    onFileSelected,
    multiple = false,
    accept = 'image/*',
    children,
    ...rest
}) => {
    const [error, setError] = useState<string | null>(null);
    const onChange = useCallback(
        e => {
            setError(null);
            const files: FileList = e.target.files;
            const validFiles = [];
            const skippedFiles = [];

            if (files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    const file = files.item(i);
                    if (file && isValidFilename(file.name)) {
                        validFiles.push(file);
                    } else {
                        skippedFiles.push(file?.name);
                    }
                }
                if (validFiles.length > 0) {
                    onFileSelected(validFiles);
                    e.target.value = '';
                }

                if (skippedFiles.length > 0) {
                    setError(
                        `Some files were skipped due to invalid characters in their names: ${skippedFiles.join(
                            ', ',
                        )},   \n Invalid characters are: '\\', '/', ':', '*', '?', '"', '<', '>', '|', '~'`,
                    );
                }
            }
        },
        [onFileSelected],
    );
    return (
        <>
            <label htmlFor={id}>
                <Box display="none">
                    <input
                        accept={accept}
                        type="file"
                        id={id}
                        onChange={onChange}
                        multiple={multiple}
                    />
                </Box>
                <IconButton
                    // just has to be a span to work for some reason
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component="span"
                    {...rest}
                >
                    {children}
                </IconButton>
            </label>
            {error && <Alert severity="error">{error}</Alert>}
        </>
    );
};

export default FileUploadIconButton;
