import {
    creativeActions,
    creativeAdapter,
    creativeThunks as defaultThunks,
    creativeSelectors as defaultSelectors,
    creativeReducer,
} from './Creative-slice';
import * as thunks from './Creative-thunks';
import * as selectors from './Creative-selectors';

const creativeThunks = { ...defaultThunks, ...thunks };
const creativeSelectors = { ...defaultSelectors, ...selectors };

export { default as CreativeSlice } from './Creative-slice';
export { creativeSelectors, creativeActions, creativeAdapter, creativeThunks, creativeReducer };
