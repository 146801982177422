import React, { Ref, useEffect, useMemo, useState } from 'react';
import { AllEntities, BaseEntity } from '@uniled/api-sdk';
import { getBaseSliceSelectors } from '@uniled/data-layer';
import { OptionSchema } from 'c-components/Forms/formTypes';
import Autocomplete, { AutocompleteProps } from 'c-components/Forms/Autocomplete';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { useAllEntityData } from 'c-data';

type Props<Entity extends BaseEntity> = {
    entityName: keyof AllEntities;
    labelField: keyof Entity | string;
    valueField?: keyof Entity | string;
    label?: string | React.ReactNode;
    multi?: boolean;
    value: number | number[];
    onChange: (newValue: number | number[]) => void;
    disabled?: boolean;
    additionalOptions?: OptionSchema[];
    useLocalEntityData?: boolean;
    size?: AutocompleteProps['size'];
    filter?: Record<string, string[] | number[]>;
    includes?: string[];
    loadingDisable?: boolean;
};

function AllEntitiesDropdown<Entity extends BaseEntity>(
    {
        entityName,
        labelField,
        valueField = 'id',
        label,
        multi = false,
        value,
        onChange,
        disabled = false,
        additionalOptions = [],
        useLocalEntityData = false,
        size,
        filter = {},
        includes = [],
        loadingDisable = false,
    }: Props<Entity>,
    ref: Ref<any>,
) {
    const { allEntities, getAll } = useAllEntityData<Entity>(
        useLocalEntityData ? undefined : entityName,
    );
    const [tracker, setTracker] = useState<Record<string, { filter: string; activated: boolean }>>(
        {},
    );
    const filterHash = JSON.stringify({ filter, includes });

    const actuallyUseLocalData = useMemo(
        () => useLocalEntityData || getBaseSliceSelectors(entityName) == null,
        [entityName, useLocalEntityData],
    );
    const { start, data, isLoading } = useAPIClientRequest(apiClient.Entities[entityName].list);

    const entities = useMemo(() => {
        if (!actuallyUseLocalData) return allEntities;
        return data?.data?.data ?? [];
    }, [actuallyUseLocalData, data, allEntities]);

    useEffect(() => {
        const currentTracker = tracker[entityName];
        if (!currentTracker || currentTracker.filter !== filterHash || !currentTracker.activated) {
            if (!actuallyUseLocalData) getAll(undefined, true);
            else start({ perPage: 99999, filters: filter, includes });
            setTracker(prev => ({
                ...prev,
                [entityName]: { filter: filterHash, activated: true },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);
    const options = useMemo<OptionSchema[]>(
        // @ts-ignore
        () => [
            ...additionalOptions,
            ...(entities.map(entity => ({
                value: entity[valueField],
                label: entity[labelField] as unknown as string,
            })) ?? []),
        ],
        [additionalOptions, entities, valueField, labelField],
    );

    return (
        <Autocomplete
            ref={ref}
            value={value}
            onChange={onChange}
            textFieldProps={{
                label,
            }}
            options={options}
            multiple={multi}
            disabled={disabled || isLoading}
            size={size}
        />
    );
}

export default React.forwardRef(AllEntitiesDropdown);
