import React, { forwardRef, Ref, useMemo } from 'react';
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup as MuiRadioGroup,
    RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material';
import { OptionSchema } from '../formTypes';

type Props = {
    options: OptionSchema[];
    label?: string | React.ReactNode;
    fullWidth?: boolean;
    error?: boolean;
    helperText?: string;
} & MuiRadioGroupProps;
const RadioGroupField = (
    { options, value, label, error, helperText, fullWidth = true, ...rest }: Props,
    ref: Ref<any>,
) => {
    const radioOptions = useMemo(() => {
        return options.map(opt => (
            <FormControlLabel
                key={`${opt.value}`}
                control={<Radio />}
                value={opt.value}
                label={opt.label}
            />
        ));
    }, [options]);

    return (
        <FormControl fullWidth={fullWidth} error={error}>
            {label && <FormLabel>{label}</FormLabel>}
            <MuiRadioGroup value={value ?? ''} {...rest} ref={ref}>
                {radioOptions}
            </MuiRadioGroup>
            {error && <FormHelperText error>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default forwardRef(RadioGroupField);
