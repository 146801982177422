import React from 'react';
import { Avatar as MuiAvatar, AvatarProps } from '@mui/material';

type Props = AvatarProps;

const Avatar: React.FC<Props> = ({ sx, ...props }) => (
    <MuiAvatar sx={{ bgcolor: 'primary.main', ...sx }} {...props} />
);

export default Avatar;
