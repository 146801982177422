import React, { useEffect, useMemo } from 'react';
import { FilterableEntityTable } from 'c-pagination';
import { simpleCampaignEntityTableProps, userEntityTableProps } from 'c-main/entityColumns';
import { campaignThunks, creativeThunks } from 'c-main/Slices';
import { useDispatch } from 'react-redux';
import { TabsWrapper } from 'c-components';
import { userThunks } from 'c-admin/Slices';
import { TabWrapperSettings } from 'c-types';
import { CreativeSearchFilterPayload } from 'c-main/Types';
import { CreativeSearchWrapper } from 'c-main/Components';

type Props = {
    id: number;
};

const AgencyOverviewDetailTabs: React.FC<Props> = ({ id }) => {
    const dispatch = useDispatch();
    const campaignsPaginationTag = useMemo(() => `agency-campaigns-${id}`, [id]);
    const creativesPaginationTag = useMemo(() => `agency-creatives-${id}`, [id]);
    const usersPaginationTag = useMemo(() => `agency-users-${id}`, [id]);

    const defaultCreativePayload = useMemo<Partial<CreativeSearchFilterPayload>>(
        () => ({ agencies: [id] }),
        [id],
    );

    useEffect(
        () => () => {
            dispatch(campaignThunks.resetPaginatedList(campaignsPaginationTag));
            dispatch(creativeThunks.resetPaginatedList(creativesPaginationTag));
            dispatch(userThunks.resetPaginatedList(usersPaginationTag));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const tabs = useMemo<TabWrapperSettings['tabs']>(
        () => [
            {
                title: 'Modules.Main.Agency.Overview.Tabs.campaigns',
                content: (
                    <FilterableEntityTable
                        key={campaignsPaginationTag}
                        tag={campaignsPaginationTag}
                        filters={{ 'filter.agency.id': [id] }}
                        resetOnUnmount={false}
                        refreshOnMount={false}
                        {...simpleCampaignEntityTableProps}
                    />
                ),
            },
            // {
            //     title: 'Modules.Main.Agency.Overview.Tabs.creative',
            //     content: (
            //         <CreativeSearchWrapper
            //             showAgencyFilter={false}
            //             defaultFilters={defaultCreativePayload}
            //             paginationTag={creativesPaginationTag}
            //         />
            //     ),
            // },
            {
                title: 'Modules.Main.Agency.Overview.Tabs.users',
                content: (
                    <FilterableEntityTable
                        key={usersPaginationTag}
                        tag={usersPaginationTag}
                        filters={{ 'filter.agency.id': [id], 'filter.agencies.id': [id] }}
                        resetOnUnmount={false}
                        refreshOnMount={false}
                        {...userEntityTableProps}
                    />
                ),
            },
        ],
        [
            campaignsPaginationTag,
            creativesPaginationTag,
            defaultCreativePayload,
            usersPaginationTag,
            id,
        ],
    );

    return (
        <>
            <TabsWrapper tabs={tabs} />
        </>
    );
};

export default AgencyOverviewDetailTabs;
