import { CampaignCreateRequest, CampaignLineItemSettings } from '@uniled/api-sdk';
import { NewCampaignFormSchema } from 'c-main/Types';

export function campaignLineItemSettings(formData: NewCampaignFormSchema, maxDays: number) {
    return (formData.line_items ?? []).reduce((acc, lineItemId) => {
        let lineItemDays = maxDays;
        const possibleDays = formData.days?.[lineItemId];

        if ((possibleDays as unknown as string) !== '' && possibleDays != null)
            lineItemDays = +possibleDays;

        acc[lineItemId] = {
            duration_days: +lineItemDays,
            start_date: formData.start_dates?.[lineItemId],
            end_date: formData.end_dates?.[lineItemId],
        } as CampaignLineItemSettings;
        return acc;
    }, {} as CampaignCreateRequest['line_items']);
}
