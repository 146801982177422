import { AllEntities, BaseEntity } from '@uniled/api-sdk';
import { useEntityData } from 'c-data';
import { useMemo } from 'react';

function useEntityField<Entity extends BaseEntity>(
    id: number,
    entityName: keyof AllEntities,
    field: keyof Entity,
) {
    const { getById } = useEntityData<Entity>(entityName);
    const entity = getById({ id });

    const text = useMemo<string>(() => (entity?.[field] ?? '') as string, [entity, field]);
    return useMemo(
        () => ({
            text,
            entity,
        }),
        [text, entity],
    );
}

export default useEntityField;
