import React, { useMemo } from 'react';
import { AutoGrid, TableCell } from 'c-components';
import { secondsToDhms } from 'c-lib';
import { differenceInSeconds, parse } from 'date-fns';
import { MediaOwnerIssueRow } from 'c-main/Types';
import { Box } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { useDateUtils } from 'c-hooks';

type Props = {
    issue: MediaOwnerIssueRow;
};

const dateFormat = 'yyyy-MM-dd HH:mm:ss';

const MediaOwnerDisplayIssueEntry: React.FC<Props> = ({ issue }) => {
    const hms = useMemo<ReturnType<typeof secondsToDhms> | null>(() => {
        if (issue.startDate && issue.endDate) {
            try {
                return secondsToDhms(
                    differenceInSeconds(
                        parse(issue.endDate, dateFormat, new Date()),
                        parse(issue.startDate, dateFormat, new Date()),
                    ),
                );
                // eslint-disable-next-line no-empty
            } catch {}
        }

        return null;
    }, [issue]);

    const { formatDateString, formatTime } = useDateUtils();
    const startDate = useMemo(
        () =>
            issue.startDate
                ? formatDateString({ date: parse(issue.startDate, dateFormat, new Date()) })
                : '',
        [issue.startDate, formatDateString],
    );
    const endDate = useMemo(
        () =>
            issue.endDate
                ? formatDateString({ date: parse(issue.endDate, dateFormat, new Date()) })
                : '',
        [issue.endDate, formatDateString],
    );
    const startTime = useMemo(
        () => (issue.startDate ? formatTime(parse(issue.startDate, dateFormat, new Date())) : ''),
        [issue.startDate, formatTime],
    );
    const endTime = useMemo(
        () => (issue.endDate ? formatTime(parse(issue.endDate, dateFormat, new Date())) : ''),
        [issue.endDate, formatTime],
    );

    const t = useCommonTranslation();

    return (
        <>
            <TableCell>{issue.displayName}</TableCell>
            <TableCell>{startDate}</TableCell>
            <TableCell>{startTime}</TableCell>
            <TableCell>{endDate}</TableCell>
            <TableCell>{endTime}</TableCell>
            <TableCell>
                {issue.startDate != null && issue.endDate != null && (
                    <AutoGrid spacing={1} flexWrap="nowrap" sx={{ whiteSpace: 'nowrap' }}>
                        {hms.h > 0 && (
                            <Box>
                                {t('Duration.hours', {
                                    count: hms.h,
                                })}
                            </Box>
                        )}
                        {hms.m > 0 && (
                            <Box>
                                {t('Duration.minutes', {
                                    count: hms.m,
                                })}
                            </Box>
                        )}
                    </AutoGrid>
                )}
            </TableCell>
            <TableCell>{issue.incidentId}</TableCell>
        </>
    );
};

export default MediaOwnerDisplayIssueEntry;
