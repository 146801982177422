import React, { Ref, SyntheticEvent, useCallback, useMemo } from 'react';
import {
    Checkbox as MuiCheckbox,
    CheckboxProps as MuiCheckboxProps,
    FormControlLabel,
    FormControlProps,
} from '@mui/material';
import { OptionSchema } from '../formTypes';

export type CheckboxProps = {
    label?: React.ReactNode;
    isBoolean?: boolean;
    option?: OptionSchema;
    onChange?: (value: any, e: React.ChangeEvent<HTMLInputElement>) => void;
    error?: boolean;
    helperText?: string;
    stopCheckboxClickPropagation?: boolean;
    formControlLabelSx?: FormControlProps['sx'];
} & Omit<MuiCheckboxProps, 'onChange'>;

const Checkbox = (
    {
        label,
        isBoolean = false,
        option,
        onChange,
        value,
        checked,
        // eslint-disable-next-line unused-imports/no-unused-vars
        helperText,
        // eslint-disable-next-line unused-imports/no-unused-vars
        error,
        stopCheckboxClickPropagation = false,
        formControlLabelSx,
        ...rest
    }: CheckboxProps,
    ref: Ref<any>,
) => {
    const onCheckboxClick = useCallback((e: SyntheticEvent) => {
        e.stopPropagation();
    }, []);

    const handleOnChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange != null) {
                if (option) {
                    onChange(option.value, e);
                } else if (isBoolean) {
                    onChange(e.target.checked, e);
                } else {
                    onChange(e, e);
                }
            }
        },
        [onChange, option, isBoolean],
    );

    const isChecked = useMemo(() => {
        if (checked != null) {
            return checked;
        }
        if (isBoolean && value === true) {
            return true;
        }
        if (option != null && value === option.value) {
            return true;
        }

        return false;
    }, [checked, value, isBoolean, option]);

    return (
        <FormControlLabel
            label={label}
            onClick={stopCheckboxClickPropagation ? onCheckboxClick : undefined}
            sx={formControlLabelSx}
            control={
                <MuiCheckbox
                    color="primary"
                    checked={isChecked}
                    onChange={handleOnChange}
                    {...rest}
                />
            }
            ref={ref}
        />
    );
};

export default React.forwardRef(Checkbox);
