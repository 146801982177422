import React, { useMemo } from 'react';
import { CardContent } from '@mui/material';
import { Translate, useCommonTranslation } from 'c-translation';
import { LinkButton, TransparentCard } from 'c-components';
import { PostAuthRoutes } from 'c-routes';
import { FilterableEntityTable, generateColumn } from 'c-pagination';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PermissionName, UserType, Campaign } from '@uniled/api-sdk';
import { CampaignScopes, CampaignStatusFilterConfig } from 'c-main/entityColumns';
import {
    CampaignListThumbnailCell,
    CampaignPlayoutsCell,
    CampaignStatusTableCell,
} from 'c-main/Components/Campaign/CampaignTableCells';

const columns = [
    generateColumn<Campaign>({
        headerTitle: '',
        field: 'thumbnail',
        entityName: 'Campaign',
        RenderCell: CampaignListThumbnailCell,
        permissions: [PermissionName.UniledportalFileRead],
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.idCol',
        field: 'id',
        entityName: 'Campaign',
        orderable: true,
        orderByAllowedUserType: [UserType.Admin],
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.buyerCol',
        field: 'buyer.name',
        entityName: 'Campaign',
        orderable: true,
        orderByAllowedUserType: [UserType.Admin],
        orderByKey: 'buyer.name',
        filterKeyPath: 'buyer.id',
        permissions: [PermissionName.UniledportalBuyerRead],
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.brandCol',
        field: 'client.name',
        entityName: 'Campaign',
        orderable: true,
        orderByAllowedUserType: [UserType.Admin],
        orderByKey: 'client.name',
        filterKeyPath: 'client.id',
        permissions: [PermissionName.UniledportalClientRead],
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.nameCol',
        field: 'name',
        entityName: 'Campaign',
        orderable: true,
        orderByAllowedUserType: [UserType.Admin],
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.dateRangeCol',
        field: 'date',
        entityName: 'Campaign',
        dataType: 'dateRange',
        dateRangeProps: { startCol: 'date.date_start', endCol: 'date.date_end' },
        orderable: true,
        orderByAllowedUserType: [UserType.Admin],
        orderByKey: 'date.date_start',
        permissions: [PermissionName.UniledportalDateRead],
    }),

    // generateColumn<Campaign>({
    //     headerTitle: 'Modules.Main.Campaigns.List.table.statusCol',
    //     field: 'status',
    //     entityName: 'Campaign',
    // }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.displayCountCol',
        field: 'lineItemsCount',
        entityName: 'Campaign',
        textAlign: 'center',
        orderable: true,
        orderByAllowedUserType: [UserType.Admin],
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.referenceCol',
        field: 'reference',
        entityName: 'Campaign',
        orderable: true,
        orderByAllowedUserType: [UserType.Admin],
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.reportingCol',
        field: 'owners',
        entityName: 'Campaign',
        orderable: true,
        orderByAllowedUserType: [UserType.Admin],
        orderByKey: 'playout_percentage',
        permissions: [PermissionName.ReportingPcareportRead],
        RenderCell: CampaignPlayoutsCell,
    }),
    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.statusCol',
        field: 'status',
        entityName: 'Campaign',
        RenderCell: CampaignStatusTableCell,
    }),
];

type Props = {
    paginationTag: string;
};
const includeFilters = ['filter.market.id', 'filter.client.id', 'filter.buyer.id'];
const customFilterConfig = [CampaignStatusFilterConfig];
const textSearchCols = ['name', 'reference', 'id', 'buyer.name'];
const includes = [
    'client',
    'date',
    'buyer',
    'owners',
    'owners.owner',
    'creativeThumbnails',
    'lineItemsCount',
];

const CampaignListSearch: React.FC<Props> = ({ paginationTag }) => (
    <TransparentCard
        title={useCommonTranslation('Modules.Main.Campaigns.List.listTitle')}
        controls={[
            <IfHasAllPermissions
                key="camp-create"
                permissions={[PermissionName.UniledportalCampaignCreate]}
            >
                <LinkButton size="large" to={PostAuthRoutes.Campaigns.NewCampaignPage}>
                    <Translate path="Modules.Main.Campaigns.List.createCampaign" />
                </LinkButton>
            </IfHasAllPermissions>,
        ]}
    >
        <CardContent>
            <FilterableEntityTable
                key={paginationTag}
                tag={paginationTag}
                columns={columns}
                resetOnUnmount={false}
                baseEntityName="Campaign"
                showFilters
                filters={useMemo(
                    () => ({ [CampaignScopes.scopeKey]: [CampaignScopes.notArchived] }),
                    [],
                )}
                routeTemplate={PostAuthRoutes.Campaigns.CampaignOverview}
                textSearchColumns={textSearchCols}
                includes={includes}
                onlyIncludeFilterKeys={includeFilters}
                customFilterConfig={customFilterConfig}
                revertToIdSearchOnNumberOnlyInput={false}
            />
        </CardContent>
    </TransparentCard>
);

export default CampaignListSearch;
