import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box, FormLabel, Stack, Typography } from '@mui/material';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import ReactGA from 'react-ga';
import { Displays_AnalogueScreen, PermissionName } from '@uniled/api-sdk';
import { useEntityPage } from 'c-hooks';
import { useUpdateEntityData } from 'c-data';
import { Controller, useForm } from 'react-hook-form';
import { NetworkRequestState } from '@uniled/data-layer';
import {
    Alert,
    AllEntitiesDropdown,
    AutoGrid,
    Button,
    SelectField,
    TextAreaField,
    TextField,
} from 'c-components';
import { Redirect } from 'react-router-dom';
import { useBrochureEditHandling } from 'c-displays/Pages/utils';
import { useBoolean } from 'react-hanger';

const DisplaysAnalogueScreenEditPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const t = useCommonTranslation();
    const { entity, id } = useEntityPage<Displays_AnalogueScreen>();
    const { update, getUpdatingById } = useUpdateEntityData('Displays_AnalogueScreen');
    const { state, error } = useMemo(() => getUpdatingById(id), [getUpdatingById, id]);
    const handleFormSubmit = useCallback(
        (data: Displays_AnalogueScreen) => {
            update(id, data);
        },
        [id, update],
    );
    const defaultValues = useMemo(
        () =>
            ({
                ...entity,
            } as any),
        [entity],
    );

    const dialogState = useBoolean(false);
    const delDialogState = useBoolean(false);
    const { control, handleSubmit, setValue, getValues, watch } = useForm({ defaultValues });
    const brochureDownload = getValues('brochure');
    const market = watch('market_id');
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            setValue('owner_id', null);
        }
    }, [market, setValue]);
    useEffect(() => {
        if (market == null) {
            setValue('market_id', null);
        }
    }, [market, setValue]);

    const { dialogBrochureFileDelete, dialogBrochureFileUpdate, handleBrochureDownload } =
        useBrochureEditHandling(
            id,
            setValue,
            'Displays_Screen',
            brochureDownload,
            dialogState,
            delDialogState,
        );
    const SuccessAlert = useMemo(() => {
        if (state === NetworkRequestState.Success) {
            return (
                <>
                    <Alert severity="success">{t('Modules.Displays.AnalgueEdit.success')}</Alert>
                    <Redirect to={PostAuthRoutes.Displays.CountryList} />
                </>
            );
        }
        return null;
    }, [state, t]);
    const SubmitButton = useMemo(
        () => (
            <Button type="submit" disabled={state === NetworkRequestState.InProgress}>
                {t('Modules.Displays.AnalgueEdit.submitButton')}
            </Button>
        ),
        [state, t],
    );
    const ownerField = useMemo(
        () => (
            <Controller
                control={control}
                name="owner_id"
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.DisplaysDisplayEdit.fields.mediaOwners')} *
                        </FormLabel>
                        <AllEntitiesDropdown
                            entityName="Displays_Owner"
                            labelField="name"
                            {...field}
                            useLocalEntityData
                            filter={{ 'filter.markets.id': [market] }}
                            includes={['market']}
                            loadingDisable
                            disabled={market == null}
                        />
                    </>
                )}
            />
        ),
        [control, market, t],
    );

    return (
        <Box p={2}>
            <Typography variant="h1" pb={2}>
                {t('Modules.Displays.AnalgueEdit.description')}
            </Typography>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <Box p={2}>
                    <AutoGrid xs={4} gap={2} pb={2}>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field }) => (
                                <>
                                    <FormLabel sx={{ color: 'red' }}>
                                        {t('Modules.Displays.AnalgueEdit.fields.name')} *
                                    </FormLabel>
                                    <TextField {...field} />
                                </>
                            )}
                        />
                        <Controller
                            control={control}
                            name="market_id"
                            render={({ field }) => (
                                <>
                                    <FormLabel sx={{ color: 'red' }}>
                                        {t('Modules.Displays.AnalgueEdit.fields.market')} *
                                    </FormLabel>
                                    <AllEntitiesDropdown
                                        entityName="Market"
                                        useLocalEntityData
                                        labelField="name"
                                        {...field}
                                    />
                                </>
                            )}
                        />
                        {ownerField}
                        <Controller
                            control={control}
                            name="env"
                            render={({ field }) => (
                                <>
                                    <FormLabel>
                                        {t('Modules.Displays.AnalgueEdit.fields.env')}
                                    </FormLabel>
                                    <TextField {...field} />
                                </>
                            )}
                        />
                        <Controller
                            control={control}
                            name="size"
                            render={({ field }) => (
                                <>
                                    <FormLabel>
                                        {t('Modules.Displays.AnalgueEdit.fields.size')}
                                    </FormLabel>
                                    <TextField {...field} />
                                </>
                            )}
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormLabel>
                                        {t('Modules.Displays.AnalgueEdit.fields.status')}
                                    </FormLabel>
                                    <SelectField
                                        value={field.value}
                                        options={[
                                            {
                                                value: 0,
                                                label: t(
                                                    'Modules.Displays.AnalgueEdit.fields.inactive',
                                                ),
                                            },
                                            {
                                                value: 1,
                                                label: t(
                                                    'Modules.Displays.AnalgueEdit.fields.active',
                                                ),
                                            },
                                        ]}
                                        {...field}
                                    />
                                </>
                            )}
                            name="status"
                        />
                        <Controller
                            control={control}
                            name="description"
                            render={({ field }) => (
                                <>
                                    <FormLabel>
                                        {t('Modules.Displays.AnalgueEdit.fields.description')}
                                    </FormLabel>
                                    <TextAreaField {...field} />
                                </>
                            )}
                        />
                    </AutoGrid>
                    <Box pb={2}>
                        <Controller
                            control={control}
                            name="brochure"
                            render={({ field }) => (
                                <AutoGrid xs={3} gap={4}>
                                    {brochureDownload != null && (
                                        <Stack pt={4}>
                                            <Button
                                                onClick={e => [
                                                    e.preventDefault(),
                                                    handleBrochureDownload(field.value),
                                                ]}
                                            >
                                                {t(
                                                    'Modules.Displays.DisplaysPackEdit.fields.downloadButtonLabel',
                                                )}
                                            </Button>
                                        </Stack>
                                    )}
                                    {brochureDownload != null && (
                                        <Stack pt={4}>
                                            <Button
                                                color="error"
                                                onClick={() => delDialogState.setTrue()}
                                            >
                                                {t(
                                                    'Modules.Displays.DisplaysPackEdit.fields.deleteButtonLabel',
                                                )}
                                            </Button>
                                        </Stack>
                                    )}
                                    {dialogBrochureFileUpdate}
                                </AutoGrid>
                            )}
                        />
                    </Box>
                    {dialogBrochureFileDelete}
                    {state === NetworkRequestState.Error && (
                        <Box pb={2}>
                            <Alert severity="error" variant="outlined">
                                {String(error)}
                            </Alert>
                        </Box>
                    )}
                    {SubmitButton}
                    {SuccessAlert}
                </Box>
            </form>
        </Box>
    );
};

const setup: PageEntityConfig<Displays_AnalogueScreen> = {
    id: 'DisplaysAnalogueScreenEditPage',
    component: DisplaysAnalogueScreenEditPage,
    systemSearch: {
        title: undefined,
        description: undefined,
        icon: undefined,
        type: 'entity',
        aliases: undefined,
        route: PostAuthRoutes.Displays.DisplaysAnalogueScreenEdit,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.Displays.AdminHome.metaTitle',
                link: PostAuthRoutes.Displays.DisplaysAdminHome,
            },
            {
                label: 'Modules.Displays.Analogue.metaTitle',
                link: PostAuthRoutes.Displays.AnaloguesList,
            },
        ],
        entityName: 'Displays_AnalogueScreen',
        nameGen: (entity, id) => (entity.name ? entity.name : String(id)),
        permissions: [PermissionName.Admin],
        defaultIncludes: ['owner', 'market'],
    },
};

export default setup;
