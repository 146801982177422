import React from 'react';
import AutoGrid from 'c-components/AutoGrid';
import Avatar from 'c-components/Avatar';
import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { formatBytes } from 'c-lib';
import RawFilePreview from './RawFilePreview';
import OpenFileNewTab from './OpenFileNewTab';
import FileIcon from './FileIcon';
import { generateFileResolution } from './lib';
import OpenFileDownload from './OpenFileDownload';
import OpenFilePreviewDialog from './OpenFilePreviewDialog';
import { FileBrowserFile } from './types';

type Props = {
    file: FileBrowserFile;
};

const FilePreview: React.FC<Props> = ({ file }) => (
    <Card
        elevation={0}
        sx={{
            bgcolor: 'transparent',
            height: '100%',
            alignItems: 'center',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            position: 'sticky',
            top: 0,
        }}
    >
        <CardHeader
            sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
            avatar={
                <Avatar>
                    <FileIcon file={file} />
                </Avatar>
            }
            title={
                <Box display="flex" alignItems="center">
                    {generateFileResolution(file)}
                </Box>
            }
            titleTypographyProps={{ variant: 'h2' }}
            subheader={`${file.mimeType} (${formatBytes(file.size)})`}
            action={
                <Box ml="auto">
                    <FilePreviewActions file={file} />
                </Box>
            }
        />
        <CardContent sx={{ px: 5, textAlign: 'center', my: 'auto' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography
                        sx={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}
                        variant="body1"
                        component={Box}
                    >
                        {file.name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            maxHeight: 350,
                            mx: 'auto',
                            overflow: 'hidden',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <RawFilePreview
                            file={file}
                            maxHeight={350}
                            fileIconSx={{ width: '60%', height: '100%' }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

const FilePreviewActions: React.FC<Props> = ({ file }) => {
    return (
        <>
            <AutoGrid spacing={2} xs>
                <OpenFilePreviewDialog file={file} />
                <OpenFileDownload file={file} />
                <OpenFileNewTab file={file} />
            </AutoGrid>
        </>
    );
};

export default FilePreview;
