import React from 'react';
import CampaignIcon from '@mui/icons-material/Campaign';
import BrushIcon from '@mui/icons-material/Brush';
import ColorLenseIcon from '@mui/icons-material/ColorLens';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ScreenLockLandscapeIcon from '@mui/icons-material/ScreenLockLandscape';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FlagIcon from '@mui/icons-material/Flag';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import PublicIcon from '@mui/icons-material/Public';
import DirtyLensIcon from '@mui/icons-material/DirtyLens';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ConstructionIcon from '@mui/icons-material/Construction';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import DateRangeIcon from '@mui/icons-material/DateRange';
import MonitorIcon from '@mui/icons-material/Monitor';
import ContactsIcon from '@mui/icons-material/Contacts';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import StyleIcon from '@mui/icons-material/Style';
import LanguageIcon from '@mui/icons-material/Language';
import StoreIcon from '@mui/icons-material/Store';
// export const dashboardIcon = <DashboardIcon fontSize="inherit" />;
// temp while the dashboard and campaign list screens are identical
export const dashboardIcon = <CampaignIcon fontSize="inherit" />;
export const campaignIcon = <CampaignIcon fontSize="inherit" />;
export const agencyIcon = <BrushIcon fontSize="inherit" />;
export const buyerIcon = <MonetizationOnIcon fontSize="inherit" />;
export const clientIcon = <DirtyLensIcon fontSize="inherit" />;
export const mediaOwnerIcon = <ScreenLockLandscapeIcon fontSize="inherit" />;
export const creativeIcon = <BurstModeIcon fontSize="inherit" />;
export const adminPanelIcon = <AdminPanelSettingsIcon fontSize="inherit" />;
export const skinIcon = <ColorLenseIcon fontSize="inherit" />;
export const roleIcon = <LockIcon fontSize="inherit" />;
export const userIcon = <PeopleIcon fontSize="inherit" />;
export const dataStatusIcon = <FlagIcon fontSize="inherit" />;
export const marketsIcon = <PublicIcon fontSize="inherit" />;

export const systemSupportIcon = <SupportAgentIcon fontSize="inherit" />;
export const frameSearchIcon = <ScreenSearchDesktopIcon fontSize="inherit" />;

export const uploadIcon = <CloudUploadIcon fontSize="inherit" />;
export const deleteIcon = <DeleteIcon fontSize="inherit" />;
export const downloadIcon = <DownloadIcon fontSize="inherit" />;
export const favouriteIcon = <StarIcon fontSize="inherit" />;
export const favouriteInactiveIcon = <StarBorderIcon fontSize="inherit" />;

export const editIcon = <EditIcon fontSize="inherit" />;
export const campaignProgressCompleteIcon = <CheckIcon fontSize="inherit" />;
export const emailIcon = <EmailIcon fontSize="inherit" />;
export const sendEmailIcon = <SendIcon fontSize="inherit" />;

export const taskQueueIcon = <FormatListNumberedIcon fontSize="inherit" />;
export const logFileImportIcon = <CloudSyncIcon fontSize="inherit" />;
export const logFileSourceIcon = <SyncAltIcon fontSize="inherit" />;
export const playoutExportIcon = <FileDownloadIcon fontSize="inherit" />;
export const virtualCampaignBuilderIcon = <ConstructionIcon fontSize="inherit" />;
export const displaysIcon = <DisplaySettingsIcon fontSize="inherit" />;
export const scheduleTemplatesIcon = <DateRangeIcon fontSize="inherit" />;

export const displaysAdminIcon = <DisplaySettingsIcon fontSize="inherit" />;
export const displaysListIcon = <MonitorIcon fontSize="inherit" />;

export const ownerListIcon = <ContactsIcon fontSize="inherit" />;
export const packsListIcon = <LibraryBooksIcon fontSize="inherit" />;

export const analogueListIcon = <LeakAddIcon fontSize="inherit" />;

export const tagsListIcon = <StyleIcon fontSize="inherit" />;
export const countriesListIcon = <LanguageIcon fontSize="inherit" />;
export const marketsListIcon = <StoreIcon fontSize="inherit" />;
