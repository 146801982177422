const defaultIntlOpts = { minimumFractionDigits: 0, maximumFractionDigits: 2 };
const defaultWholeIntlOpts = { minimumFractionDigits: 0, maximumFractionDigits: 0 };

export const formatNumber = (
    value: number | string,
    intlOptions: Intl.NumberFormatOptions = defaultIntlOpts,
) => Number(value).toLocaleString('en-GB', intlOptions);

export const formatNumberWhole = (
    value: number | string,
    intlOptions: Intl.NumberFormatOptions = defaultWholeIntlOpts,
) => Number(value).toLocaleString('en-GB', intlOptions);

export const formatNumberCompact = (
    value: number | string,
    minCompactNumber: number,
    intlOptions?: Intl.NumberFormatOptions,
) => {
    const numberVal = Number(value);

    if (numberVal < minCompactNumber) {
        return formatNumber(numberVal, intlOptions);
    }

    return numberVal.toLocaleString('en-GB', { notation: 'compact' });
};
