import {
    campaignPopShotActions,
    campaignPopShotAdapter,
    campaignPopShotThunks as defaultThunks,
    campaignPopShotSelectors as defaultSelectors,
    campaignPopShotReducer,
} from './CampaignPopShot-slice';
import * as thunks from './CampaignPopShot-thunks';
import * as selectors from './CampaignPopShot-selectors';

const campaignPopShotThunks = { ...defaultThunks, ...thunks };
const campaignPopShotSelectors = { ...defaultSelectors, ...selectors };

export { default as CampaignPopShotSlice } from './CampaignPopShot-slice';
export {
    campaignPopShotSelectors,
    campaignPopShotActions,
    campaignPopShotAdapter,
    campaignPopShotThunks,
    campaignPopShotReducer,
};
