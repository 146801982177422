import { useSelector } from 'react-redux';
import { authSelectors } from 'c-auth-module/Slices/Auth';
import { useEntityData } from 'c-data/slices';
import { useEffect, useMemo } from 'react';
import { equals, reject, uniq } from 'ramda';
import { UserType } from '@uniled/api-sdk';
import { User } from 'c-entity-types';

type Props = {
    user?: User;
    userBuyers?: number[];
    userMediaOwners?: number[];
    isAdmin: boolean;
    isAgency: boolean;
    isBuyer: boolean;
    isBrand: boolean;
    isMediaOwner: boolean;

    chosenUserType?: UserType;
};

function useAuthenticatedUser(): Props {
    const user = useSelector(authSelectors.authenticatedUser);
    const isLoggedIn = useSelector(authSelectors.isLoggedIn);
    const { getById } = useEntityData<User>('User');

    const actualUser = getById({ id: user?.id });

    const userBuyers = useMemo(
        () => reject(equals(null), uniq([actualUser?.buyer_id, ...(actualUser?.buyers ?? [])])),
        [actualUser],
    );
    const userMediaOwners = useMemo(
        () => reject(equals(null), uniq([actualUser?.owner_id, ...(actualUser?.owners ?? [])])),
        [actualUser],
    );

    const chosenUserType = useMemo(() => actualUser?.chosenUserType, [actualUser]);

    const isAdmin = useMemo(() => chosenUserType === UserType.Admin, [chosenUserType]);
    const isAgency = useMemo(
        () => chosenUserType === UserType.Agency || chosenUserType === UserType.Admin,
        [chosenUserType],
    );
    const isBuyer = useMemo(
        () => chosenUserType === UserType.Buyer || chosenUserType === UserType.Admin,
        [chosenUserType],
    );
    const isBrand = useMemo(
        () => chosenUserType === UserType.Client || chosenUserType === UserType.Admin,
        [chosenUserType],
    );
    const isMediaOwner = useMemo(
        () => chosenUserType === UserType.Owner || chosenUserType === UserType.Admin,
        [chosenUserType],
    );

    useEffect(() => {
        window.TestData = { ...window.TestData, loggedInUser: { ...actualUser } };
    }, [actualUser]);

    return useMemo(() => {
        if (isLoggedIn && user?.id) {
            return {
                user: actualUser,
                userBuyers,
                userMediaOwners,
                isAdmin,
                isAgency,
                isBuyer,
                isBrand,
                isMediaOwner,
                chosenUserType,
            };
        }

        return {
            user: null,
            userBuyers: [],
            userMediaOwners: [],
            isAdmin,
            isAgency,
            isBuyer,
            isBrand,
            isMediaOwner,
            chosenUserType,
        };
    }, [
        actualUser,
        chosenUserType,
        isAdmin,
        isAgency,
        isBrand,
        isBuyer,
        isLoggedIn,
        isMediaOwner,
        user?.id,
        userBuyers,
        userMediaOwners,
    ]);
}

export default useAuthenticatedUser;
