import React, { useCallback, useMemo, useState } from 'react';
import { ItemId, updateListSelection } from 'c-lib';

export type UseListSelection = {
    itemIds: ItemId[];
    visibleItemIds: ItemId[];

    // if you want the state controlled outside the hook
    value?: ItemId[];
    onChange?: (ids: ItemId[]) => void;
};

export function useListSelection({
    itemIds,
    visibleItemIds,

    value: controlledSelectedIds,
    onChange,
}: UseListSelection) {
    const controlled = onChange != null;

    const [selectedIds, setSelectedIds] = useState<ItemId[]>([]);
    const actualSelectedIds = useMemo(
        () => (controlled ? controlledSelectedIds : selectedIds),
        [controlled, selectedIds, controlledSelectedIds],
    );

    const selectAllChecked = useMemo(
        () => itemIds?.length > 0 && actualSelectedIds.length >= itemIds.length,

        [actualSelectedIds.length, itemIds.length],
    );
    const selectAllIndeterminate = useMemo(
        () => !selectAllChecked && actualSelectedIds.length > 0,
        [selectAllChecked, actualSelectedIds.length],
    );

    const onSelectAllToggle = useCallback(() => {
        let newSelected;

        if (selectAllChecked || selectAllIndeterminate) newSelected = [];
        else newSelected = [...visibleItemIds];

        if (!controlled) setSelectedIds(newSelected);
        else onChange(newSelected);
    }, [selectAllChecked, selectAllIndeterminate, visibleItemIds, controlled, onChange]);

    const onItemToggled = useCallback(
        (itemId: ItemId, e: React.MouseEvent) => {
            if (!controlled)
                setSelectedIds(curr =>
                    updateListSelection(curr, visibleItemIds, itemId, e?.shiftKey),
                );
            else
                onChange(
                    updateListSelection(actualSelectedIds, visibleItemIds, itemId, e?.shiftKey),
                );
        },
        [controlled, onChange, actualSelectedIds, visibleItemIds],
    );

    return useMemo(
        () => ({
            selectAllChecked,
            selectAllIndeterminate,
            onSelectAllToggle,
            onItemToggled,
            selectedIds: actualSelectedIds,
            totalCount: itemIds.length,
            selectedCount: actualSelectedIds.length,
        }),
        [
            actualSelectedIds,
            itemIds.length,
            onItemToggled,
            onSelectAllToggle,
            selectAllChecked,
            selectAllIndeterminate,
        ],
    );
}
