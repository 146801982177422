import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { Translate } from 'c-translation';
import { userIcon } from 'c-main/icons';
import { PermissionName, User } from '@uniled/api-sdk';
import { LinkButton } from 'c-components';
import {
    FilterableEntityTable,
    FilterableEntityTableRendererType,
    generateColumn,
    RenderRowProps,
} from 'c-pagination';

import { IfHasAllPermissions } from 'c-auth-module/Components';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import ReactGA from 'react-ga';

import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/styles';

const paginationTag = 'role-list-search';

const UserListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const [disabled, setDisabled] = useState([]);
    const theme = useTheme();

    const setDeletedIds = useCallback(entity => {
        const isDeleted = !entity?.properties.verified || entity?.deleted || !entity?.active;
        if (isDeleted) {
            setDisabled(prev => {
                if (!prev.includes(entity.id)) {
                    return [...prev, entity.id];
                }
                return prev;
            });
        }
    }, []);

    const columns = useMemo(() => {
        const RenderCell: React.FC<{ entity: User }> = ({ entity }) => {
            useEffect(() => {
                setDeletedIds(entity);
            }, [entity]);
            return <>{entity.id}</>;
        };
        return [
            generateColumn<User>({
                headerTitle: 'Modules.Auth.User.table.idCol',
                field: 'id',
                entityName: 'User',
                orderable: true,
                RenderCell,
            }),
            generateColumn<User>({
                headerTitle: 'Modules.Auth.User.table.nameCol',
                field: 'name',
                entityName: 'User',
                orderable: true,
            }),
            generateColumn<User>({
                headerTitle: 'Modules.Auth.User.table.emailCol',
                field: 'email',
                entityName: 'User',
                orderable: true,
            }),
            generateColumn<User>({
                headerTitle: 'Modules.Auth.User.table.languageCol',
                field: 'locale',
                entityName: 'User',
            }),
            generateColumn<User>({
                headerTitle: 'Modules.Auth.User.table.rolesCol',
                field: 'roles',
                entityName: 'User',
                permissions: [
                    PermissionName.UniledportalRoleRead,
                    PermissionName.UniledportalRoleUpdate,
                ],
                dataType: 'relationList',
                relationListProps: {
                    dialogTitle: 'Modules.Auth.User.table.roleDialogTitle',
                    relationships: [
                        {
                            relationIdKey: 'roles',
                            entityName: 'Role',
                            relationLabelField: 'name',
                        },
                    ],
                },
            }),
            generateColumn<User>({
                headerTitle: 'Modules.Auth.User.table.buyerCol',
                field: 'buyers',
                entityName: 'User',
                permissions: [PermissionName.UniledportalBuyerRead],
                dataType: 'relationList',
                relationListProps: {
                    dialogTitle: 'Modules.Auth.User.table.buyerDialogTitle',
                    relationships: [
                        {
                            relationIdKey: 'buyer',
                            entityName: 'Buyer',
                            relationLabelField: 'name',
                        },
                        {
                            relationIdKey: 'buyers',
                            entityName: 'Buyer',
                            relationLabelField: 'name',
                        },
                    ],
                },
            }),
            generateColumn<User>({
                headerTitle: 'Modules.Auth.User.table.agencyCol',
                field: 'agencies',
                entityName: 'User',
                permissions: [PermissionName.UniledportalAgencyRead],
                dataType: 'relationList',
                relationListProps: {
                    dialogTitle: 'Modules.Auth.User.table.agencyDialogTitle',
                    relationships: [
                        {
                            relationIdKey: 'agency',
                            entityName: 'Agency',
                            relationLabelField: 'name',
                        },
                        {
                            relationIdKey: 'agencies',
                            entityName: 'Agency',
                            relationLabelField: 'name',
                        },
                    ],
                },
            }),
            generateColumn<User>({
                headerTitle: 'Modules.Auth.User.table.ownerCol',
                field: 'owners',
                entityName: 'User',
                permissions: [PermissionName.UniledportalOwnerRead],
                dataType: 'relationList',
                relationListProps: {
                    dialogTitle: 'Modules.Auth.User.table.ownerDialogTitle',
                    relationships: [
                        {
                            relationIdKey: 'owner',
                            entityName: 'Owner',
                            relationLabelField: 'name',
                        },
                        {
                            relationIdKey: 'owners',
                            entityName: 'Owner',
                            relationLabelField: 'name',
                        },
                    ],
                },
            }),
        ];
    }, [setDeletedIds]);

    const generateRowSx = useCallback<RenderRowProps<any>['generateSx']>(
        (row, id) => {
            if (disabled.includes(id)) {
                return {
                    backgroundColor: alpha(theme.palette.grey[500], 0.1),
                };
            }
            return {};
        },
        [disabled, theme],
    );

    return (
        <FullPageEntityTableWrapper
            title="Modules.Admin.UsersList.metaTitle"
            controls={useMemo(
                () => [
                    <IfHasAllPermissions
                        key="new-user-btn"
                        permissions={[PermissionName.UniledportalUserCreate]}
                    >
                        <LinkButton size="large" to={PostAuthRoutes.Admin.NewUser}>
                            <Translate path="Modules.Admin.UsersList.createUser" />
                        </LinkButton>
                    </IfHasAllPermissions>,
                ],
                [],
            )}
        >
            <FilterableEntityTable
                key={paginationTag}
                tag={paginationTag}
                routeTemplate={PostAuthRoutes.Admin.EditUser}
                columns={columns}
                includes={[
                    'buyer',
                    'buyers',
                    'roles',
                    'agency',
                    'agencies',
                    'owner',
                    'owners',
                    'locale',
                ]}
                textSearchColumns={['name', 'email']}
                baseEntityName="User"
                rendererType={FilterableEntityTableRendererType.Contained}
                generateRowSx={generateRowSx}
            />
        </FullPageEntityTableWrapper>
    );
};

const setup: PageConfig = {
    id: 'UserListPage',
    component: UserListPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.Admin.UsersList.metaTitle',
        description: 'Modules.Admin.UsersList.description',
        type: 'page',
        aliases: 'SystemSearch.aliases.userListPage',
        route: PostAuthRoutes.Admin.UserList,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            { label: 'Modules.Admin.UsersList.metaTitle' },
        ],
        icon: userIcon,
        permissions: [
            PermissionName.UniledportalUserRead,
            PermissionName.UniledportalUserUpdate,
            PermissionName.UniledportalUserCreate,
        ],
    },
};

export default setup;
