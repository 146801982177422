import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    ElasticSearchFrameIdMapDataResponse,
    PCAMapReportPlayStats,
    PCAMapReportResponse,
    PCAReportMetric,
} from '@uniled/api-sdk';
import { MapDataType } from 'c-main/Components/Campaign/CampaignReports/types';
import { generateHeatMapData, generateMarkerLocations, MarkerLocation } from 'c-main/Lib';
import { HeatmapLayerProps } from '@react-google-maps/api';
import { mapDataToSqlMapData } from 'c-main/Components/Campaign/CampaignReports/ElasticSearch/Transformers';

const MapHeatValues: MapDataType[] = [
    MapDataType.HeatNone,
    MapDataType.HeatActual,
    MapDataType.HeatExpected,
    MapDataType.HeatPerformance,
    MapDataType.HeatInSchedule,
    MapDataType.HeatOutSchedule,
    MapDataType.HeatUnbooked,
];

function usePCAMapState(metric: PCAReportMetric) {
    const [selectedFrameMarker, setSelectedFrameMarker] = useState<string>(null);
    const [pcaData, setPcaData] = useState<
        PCAMapReportResponse | ElasticSearchFrameIdMapDataResponse
    >(null);

    const actualPcaData = useMemo<PCAMapReportResponse>(
        () => mapDataToSqlMapData(metric, pcaData as ElasticSearchFrameIdMapDataResponse),
        [pcaData, metric],
    );

    const mapRef = useRef<google.maps.Map | null>();

    const onMapLoad = useCallback(map => {
        mapRef.current = map;
    }, []);

    const onMapUnmount = useCallback(() => {
        mapRef.current = null;
    }, []);

    const [dataTypes, setDataTypes] = useState<MapDataType[]>([MapDataType.HeatActual]);

    const selectedFrameInfo = useMemo<PCAMapReportPlayStats>(
        () => actualPcaData?.reportData?.[selectedFrameMarker],
        [actualPcaData, selectedFrameMarker],
    );

    const heatLayerSelected = useMemo(
        () => dataTypes.find(t => MapHeatValues.indexOf(t) !== -1),
        [dataTypes],
    );

    const toggleMapType = useCallback(
        (type: MapDataType) => {
            if (dataTypes.indexOf(type) === -1) {
                // new type currently not selected
                let newMapDataTypes = [...dataTypes];
                if (MapHeatValues.indexOf(type) !== -1) {
                    // turn off all heat types
                    newMapDataTypes = newMapDataTypes.filter(t => MapHeatValues.indexOf(t) === -1);
                }

                setDataTypes([...newMapDataTypes, type]);
            } else {
                // deselect data type that was already selected
                setDataTypes(dataTypes.filter(t => t !== type));
            }
        },
        [setDataTypes, dataTypes],
    );

    const heatMapData = useMemo<HeatmapLayerProps['data']>(
        () => generateHeatMapData(actualPcaData?.reportData, heatLayerSelected),
        [actualPcaData, heatLayerSelected],
    );

    const markerLocations = useMemo<MarkerLocation[]>(
        () => generateMarkerLocations(actualPcaData?.reportData, heatLayerSelected),
        [actualPcaData, heatLayerSelected],
    );

    const onMarkerClick = useCallback(
        (markerIndex: number) => {
            setSelectedFrameMarker(markerLocations[markerIndex].frameId);
        },
        [markerLocations],
    );

    const repositionedOnce = useRef(false);

    useEffect(() => {
        if (markerLocations.length > 0 && !repositionedOnce.current) {
            // basically repositioning the map on first load when the first set of markers (screens) are loaded
            const bounds = new google.maps.LatLngBounds();
            markerLocations.forEach(loc => {
                if (loc.location != null) bounds.extend(loc.location);
            });

            mapRef?.current?.fitBounds?.(bounds);
            repositionedOnce.current = true;
        }
    }, [markerLocations]);

    return {
        mapRef,
        onMapLoad,
        onMapUnmount,
        selectedFrameMarker,
        selectedFrameInfo,
        pcaData: actualPcaData,
        setPcaData,
        dataTypes,
        toggleMapType,
        heatLayerSelected,
        heatMapData,
        markerLocations,
        onMarkerClick,
    };
}

export default usePCAMapState;
