import {
    buyerCostActions,
    buyerCostAdapter,
    buyerCostThunks as defaultThunks,
    buyerCostSelectors as defaultSelectors,
    buyerCostReducer,
} from './BuyerCost-slice';
import * as thunks from './BuyerCost-thunks';
import * as selectors from './BuyerCost-selectors';

const buyerCostThunks = { ...defaultThunks, ...thunks };
const buyerCostSelectors = { ...defaultSelectors, ...selectors };

export { default as BuyerCostSlice } from './BuyerCost-slice';
export {
    buyerCostSelectors,
    buyerCostActions,
    buyerCostAdapter,
    buyerCostThunks,
    buyerCostReducer,
};
