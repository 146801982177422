import { useCallback, useMemo } from 'react';
import { format, parse, parseISO } from 'date-fns';
import {
    formatDateString as UtilFormatDateString,
    formatDateStringIgnoreTime as UtilFormatDateStringIgnoreTime,
    LANG_en_gb,
    LANG_en_us,
} from 'c-lib';
import useLanguageSwitcher from './useLanguageSwitcher';

// the api format
const apiDayMonthYearHourMinuteSecondFormat = 'yyyy-MM-dd HH:mm:ss';

const defaultDayMonthYearOutputFormat = 'yyyy-MM-dd';
const defaultDayMonthYearHourMinuteOutputFormat = 'yyyy-MM-dd HH:mm';
const defaultTimeFormat = 'HH:mm';

const defaultDayDisplayFormat = 'dd';
const defaultMonthDisplayFormat = 'MMM';
const defaultYearDisplayFormat = 'yyyy';
const defaultDayMonthYearDisplayFormat = `${defaultDayDisplayFormat}-${defaultMonthDisplayFormat}-${defaultYearDisplayFormat}`;
const USADayMonthYearDisplayFormat = `${defaultMonthDisplayFormat}-${defaultDayDisplayFormat}-${defaultYearDisplayFormat}`;

const DMYDateFormatting = {
    [LANG_en_gb]: defaultDayMonthYearDisplayFormat,
    [LANG_en_us]: USADayMonthYearDisplayFormat,
};

const DMYDatePickerFormatting = {
    [LANG_en_gb]: 'dd/MM/yyyy',
    [LANG_en_us]: 'MM/dd/yyyy',
};

const DMYDateTimePickerFormatting = {
    [LANG_en_gb]: 'dd/MM/yyyy HH:mm',
    [LANG_en_us]: 'MM/dd/yyyy HH:mm',
};

function useDateUtils() {
    const { lang } = useLanguageSwitcher();
    const dayMonthYearFormat = useMemo(
        () => DMYDateFormatting[lang] ?? defaultDayMonthYearDisplayFormat,
        [lang],
    );
    const datePickerInputFormat = useMemo(
        () => DMYDatePickerFormatting[lang] ?? DMYDatePickerFormatting[LANG_en_gb],
        [lang],
    );
    const dateTimePickerInputFormat = useMemo(
        () => DMYDateTimePickerFormatting[lang] ?? DMYDateTimePickerFormatting[LANG_en_gb],
        [lang],
    );

    const formatDateString = useCallback<typeof UtilFormatDateString>(
        args =>
            UtilFormatDateString({
                timeFormat: defaultTimeFormat,
                dateFormatString: dayMonthYearFormat,
                ...args,
            }),
        [dayMonthYearFormat],
    );
    const formatDateStringIgnoreTime = useCallback<typeof UtilFormatDateStringIgnoreTime>(
        args =>
            UtilFormatDateStringIgnoreTime({
                dateFormatString: dayMonthYearFormat,
                ...args,
            }),
        [dayMonthYearFormat],
    );
    const formatDateStringIgnoreTimeDiff = useCallback<typeof formatDateString>(
        args =>
            formatDateString({
                ...args,
                date: typeof args.date === 'string' ? args.date.split('+')[0] : args.date,
            }),
        [formatDateString],
    );
    const apiFormatDateString = useCallback<typeof UtilFormatDateString>(
        args =>
            formatDateString({
                dateFormatString: defaultDayMonthYearOutputFormat,
                ...args,
            }),
        [formatDateString],
    );
    const apiFormatDateTimeString = useCallback<typeof UtilFormatDateString>(
        args =>
            formatDateString({
                dateFormatString: defaultDayMonthYearOutputFormat,
                timeFormat: defaultTimeFormat,
                removeTime: false,
                ...args,
            }),
        [formatDateString],
    );

    const formatTime = useCallback(
        // ISO8601 date
        (date: string | Date) => {
            let theDate = null;
            if (typeof date === 'string') {
                theDate = parseISO(date);
            }
            return format(theDate, defaultTimeFormat);
        },
        [],
    );
    const parseApiDayMonthYearHourMinuteSecondFormat = useCallback(
        (date: string, ignoreTime = false) => {
            if (ignoreTime)
                return parse(date.split(' ')[0], defaultDayMonthYearOutputFormat, new Date());
            return parse(date, apiDayMonthYearHourMinuteSecondFormat, new Date());
        },
        [],
    );
    const parseApiDayMonthYearFormat = useCallback((date: string) => {
        return parse(date, defaultDayMonthYearOutputFormat, new Date());
    }, []);

    return useMemo(
        () => ({
            formatDateString,
            formatDateStringIgnoreTime,
            formatDateStringIgnoreTimeDiff,
            apiFormatDateString,
            apiFormatDateTimeString,
            formatTime,
            timeFormatString: defaultTimeFormat,
            dayMonthYearFormat,
            dayMonthYearApiFormat: defaultDayMonthYearOutputFormat,
            dayMonthYearHourMinuteApiFormat: defaultDayMonthYearHourMinuteOutputFormat,
            datePickerInputFormat,
            dateTimePickerInputFormat,

            parseApiDayMonthYearHourMinuteSecondFormat,
            parseApiDayMonthYearFormat,
            apiDayMonthYearHourMinuteSecondFormat,
        }),
        [
            apiFormatDateString,
            apiFormatDateTimeString,
            datePickerInputFormat,
            dateTimePickerInputFormat,
            dayMonthYearFormat,
            formatDateString,
            formatDateStringIgnoreTime,
            formatDateStringIgnoreTimeDiff,
            formatTime,
            parseApiDayMonthYearFormat,
            parseApiDayMonthYearHourMinuteSecondFormat,
        ],
    );
}

export default useDateUtils;
