import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';
import { CommonState } from 'c-types';
import { pick } from 'ramda';

export const lineItemsByOwner = createSelector(
    (state: CommonState) => state.displaysLineItems.entities,
    entities =>
        memoize((lineItemIds: number[]) => {
            const relevantEntities = Object.values(pick(lineItemIds, entities));
            return {
                noOwner: relevantEntities
                    .filter(entity => entity.owner_id == null)
                    .map(item => item.id) as number[],
                byOwner: relevantEntities
                    .filter(entity => entity.owner_id != null)
                    .reduce((acc, curr) => {
                        if (!acc[curr.owner_id]) {
                            acc[curr.owner_id] = [];
                        }
                        acc[curr.owner_id].push(curr.id);
                        return acc;
                    }, {} as Record<number, number[]>),
            };
        }),
);
