import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useBoolean } from 'react-hanger';
// eslint-disable-next-line import/no-extraneous-dependencies
import DOMPurify from 'dompurify';

const ExpandableHtmlContent = ({ contentHtml }) => {
    const expand = useBoolean(false);
    const maxLength = 200;
    const div = document.createElement('div');
    div.innerHTML = DOMPurify.sanitize(contentHtml);
    const textContent = div.textContent || div.innerText || '';

    const shouldShorten = textContent.length > maxLength;
    const displayContent =
        shouldShorten && !expand.value ? `${textContent.substring(0, maxLength)}...` : contentHtml;

    const sanitizedDisplayContent = DOMPurify.sanitize(displayContent, {
        ALLOWED_TAGS: ['a', 'button', 'p', 'br', 'ul', 'li', 'b', 'i'],
        ALLOWED_ATTR: ['href', 'title', 'target', 'type'],
    });

    return (
        <Box display="flex" alignItems="center" p={1}>
            <Box dangerouslySetInnerHTML={{ __html: sanitizedDisplayContent }} />
            {shouldShorten && (
                <Tooltip title={expand.value ? 'Collapse' : 'Expand'}>
                    <IconButton onClick={expand.toggle} size="small">
                        {expand.value ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};

export default ExpandableHtmlContent;
