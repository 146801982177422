import {
    userSelectors,
    userActions,
    userAdapter,
    userThunks as defaultThunks,
    userReducer,
} from './user-slice';
import * as thunks from './user-thunks';

const userThunks = { ...defaultThunks, ...thunks };

export { default as userSlice } from './user-slice';
export { userSelectors, userActions, userAdapter, userThunks, userReducer };
