import React, { useCallback, useMemo } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight, FastForward, FastRewind } from '@mui/icons-material';
import {
    addDays,
    addMonths,
    endOfMonth,
    isAfter,
    isSameDay,
    startOfMonth,
    subDays,
    subMonths,
} from 'date-fns';
import { ReportDateSettings, ReportDateTimeframe } from 'c-reports/Types';
import { Translate } from 'c-translation';
import { useDateUtils } from 'c-hooks';
import TimeFrameSwitcher from './TimeFrameSwitcher';
import DateRangeDropdown from './DateRangeDropdown';
import DateDropdown from './DateDropdown';

type Props = ReportDateSettings & {
    startDate: Date;
    endDate: Date;
    onChange: (timeframe: ReportDateTimeframe, start: Date, end: Date) => void;
    onSwitchTimeframe: (timeframe: ReportDateTimeframe) => void;
};

const ReportDateSwitcher: React.FC<Props> = ({
    startDate,
    endDate,
    onChange,
    minDate,
    maxDate,
    availableTimeframes,
    onSwitchTimeframe,
    timeframe,
    showGoStart,
    showGoEnd,
}) => {
    const startDateRange = useMemo<[Date, Date]>(() => {
        if (timeframe === ReportDateTimeframe.Daily) {
            return [minDate, minDate];
        }
        if (timeframe === ReportDateTimeframe.Weekly) {
            return [minDate, addDays(minDate, 7)];
        }
        if (timeframe === ReportDateTimeframe.BiWeekly) {
            return [minDate, addDays(minDate, 14)];
        }
        if (timeframe === ReportDateTimeframe.Monthly) {
            return [startOfMonth(minDate), endOfMonth(minDate)];
        }
        return [new Date(), new Date()];
    }, [timeframe, minDate]);

    const endDateRange = useMemo<[Date, Date]>(() => {
        if (timeframe === ReportDateTimeframe.Daily) {
            return [maxDate, maxDate];
        }
        if (timeframe === ReportDateTimeframe.Weekly) {
            return [subDays(maxDate, 7), maxDate];
        }
        if (timeframe === ReportDateTimeframe.BiWeekly) {
            return [subDays(maxDate, 14), maxDate];
        }
        if (timeframe === ReportDateTimeframe.Monthly) {
            return [startOfMonth(maxDate), endOfMonth(maxDate)];
        }
        return [new Date(), new Date()];
    }, [timeframe, maxDate]);

    const { formatDateString } = useDateUtils();
    const nextPrevRange = useMemo<[Date, Date]>(() => {
        if (timeframe === ReportDateTimeframe.Daily) {
            return [subDays(startDate, 1), subDays(startDate, 1)];
        }
        if (timeframe === ReportDateTimeframe.Weekly) {
            return [subDays(startDate, 7), subDays(endDate, 7)];
        }
        if (timeframe === ReportDateTimeframe.BiWeekly) {
            return [subDays(startDate, 14), subDays(endDate, 14)];
        }
        if (timeframe === ReportDateTimeframe.Monthly) {
            const newStart = subMonths(startDate, 1);
            return [newStart, endOfMonth(newStart)];
        }
        return [new Date(), new Date()];
    }, [timeframe, endDate, startDate]);

    const prevDisabled = useMemo(() => {
        if (timeframe === ReportDateTimeframe.All) return true;
        if (isSameDay(nextPrevRange[0], minDate)) return false;
        if (isAfter(nextPrevRange[1], minDate) || isSameDay(nextPrevRange[1], minDate))
            return false;

        return true;
    }, [minDate, nextPrevRange, timeframe]);

    const nextDisabled = useMemo(
        () =>
            timeframe === ReportDateTimeframe.All ||
            isSameDay(endDate, maxDate) ||
            isAfter(endDate, maxDate),
        [maxDate, endDate, timeframe],
    );

    const onStart = useCallback(() => {
        if (!prevDisabled) {
            onChange(timeframe, startDateRange[0], startDateRange[1]);
        }
    }, [prevDisabled, onChange, startDateRange, timeframe]);

    const onEnd = useCallback(() => {
        if (!nextDisabled) {
            onChange(timeframe, endDateRange[0], endDateRange[1]);
        }
    }, [nextDisabled, onChange, endDateRange, timeframe]);

    const onPrev = useCallback(() => {
        if (!prevDisabled) {
            onChange(timeframe, nextPrevRange[0], nextPrevRange[1]);
        }
    }, [prevDisabled, onChange, nextPrevRange, timeframe]);

    const onNext = useCallback(() => {
        if (!nextDisabled) {
            if (timeframe === ReportDateTimeframe.Daily) {
                onChange(timeframe, addDays(startDate, 1), addDays(startDate, 1));
            } else if (timeframe === ReportDateTimeframe.Weekly) {
                onChange(timeframe, addDays(startDate, 7), addDays(endDate, 7));
            } else if (timeframe === ReportDateTimeframe.BiWeekly) {
                onChange(timeframe, addDays(startDate, 14), addDays(endDate, 14));
            } else if (timeframe === ReportDateTimeframe.Monthly) {
                const newStart = addMonths(startDate, 1);
                onChange(timeframe, newStart, endOfMonth(newStart));
            }
        }
    }, [nextDisabled, timeframe, onChange, startDate, endDate]);

    const onDateRangeChange = useCallback(
        (start: Date, end: Date) => {
            if (
                timeframe === ReportDateTimeframe.Range ||
                timeframe === ReportDateTimeframe.Daily
            ) {
                onChange(timeframe, start, end);
            }
        },
        [onChange, timeframe],
    );

    const dateRangeLabel = useMemo(
        () => (
            <>
                {timeframe === ReportDateTimeframe.All && (
                    <Typography color="textSecondary" variant="body1">
                        <Translate path="Reporting.timeframes.timeFrameAllLabel" />
                    </Typography>
                )}
                {timeframe === ReportDateTimeframe.Daily && formatDateString({ date: startDate })}
                {[ReportDateTimeframe.Daily, ReportDateTimeframe.All].indexOf(timeframe) === -1 && (
                    <>
                        {formatDateString({ date: startDate })} -
                        {formatDateString({ date: endDate })}
                    </>
                )}
            </>
        ),
        [timeframe, formatDateString, startDate, endDate],
    );

    const showPlainTextDateLabel = useMemo(
        () => timeframe !== ReportDateTimeframe.Range && timeframe !== ReportDateTimeframe.Daily,
        [timeframe],
    );

    const showStartBtn = useMemo(
        () => timeframe !== ReportDateTimeframe.Range && showGoStart,
        [timeframe, showGoStart],
    );
    const showEndBtn = useMemo(
        () => timeframe !== ReportDateTimeframe.Range && showGoEnd,
        [timeframe, showGoEnd],
    );

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {showStartBtn && (
                        <Grid item>
                            <IconButton disabled={prevDisabled} onClick={onStart}>
                                <FastRewind />
                            </IconButton>
                        </Grid>
                    )}
                    {timeframe !== ReportDateTimeframe.Range && (
                        <Grid item>
                            <IconButton disabled={prevDisabled} onClick={onPrev}>
                                <ChevronLeft />
                            </IconButton>
                        </Grid>
                    )}
                    <Grid item sx={{ pr: timeframe === ReportDateTimeframe.Range ? 2 : 0 }}>
                        {showPlainTextDateLabel && dateRangeLabel}
                        {timeframe === ReportDateTimeframe.Daily && (
                            <DateDropdown
                                dateLabel={dateRangeLabel}
                                date={startDate}
                                onChange={onDateRangeChange}
                                minDate={minDate}
                                maxDate={maxDate}
                            />
                        )}
                        {timeframe === ReportDateTimeframe.Range && (
                            <DateRangeDropdown
                                dateRangeLabel={dateRangeLabel}
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={maxDate}
                                minDate={minDate}
                                onChange={onDateRangeChange}
                            />
                        )}
                    </Grid>
                    {timeframe !== ReportDateTimeframe.Range && (
                        <Grid item>
                            <IconButton disabled={nextDisabled} onClick={onNext}>
                                <ChevronRight />
                            </IconButton>
                        </Grid>
                    )}
                    {showEndBtn && (
                        <Grid item>
                            <IconButton disabled={nextDisabled} onClick={onEnd}>
                                <FastForward />
                            </IconButton>
                        </Grid>
                    )}
                    {Array.isArray(availableTimeframes) && availableTimeframes.length > 0 && (
                        <Grid item>
                            <TimeFrameSwitcher
                                timeFrame={timeframe}
                                availableTimeframes={availableTimeframes}
                                setTimeFrame={onSwitchTimeframe}
                            />
                        </Grid>
                    )}
                </Grid>
            </>
        </Box>
    );
};

export default ReportDateSwitcher;
