import {
    displayActions,
    displayAdapter,
    displayThunks as defaultThunks,
    displaySelectors as defaultSelectors,
    displayReducer,
} from './Display-slice';
import * as thunks from './Display-thunks';
import * as selectors from './Display-selectors';

const displayThunks = { ...defaultThunks, ...thunks };
const displaySelectors = { ...defaultSelectors, ...selectors };

export { default as DisplaySlice } from './Display-slice';
export { displaySelectors, displayActions, displayAdapter, displayThunks, displayReducer };
