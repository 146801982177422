import {
    PCAReportByFieldDailyResponse,
    PCAReportByFieldHourlyResponse,
    PCAReportByFieldResponse,
} from '@uniled/api-sdk';
import { dateSortArray } from 'c-lib';
import { ChartDataKeyDateFormat } from 'c-reports/Generators';

const toAccumulative = (
    chartData: Record<string, number>[],
    actualLabel: string,
    percentageLabel: string,
    expectedLabel: string,
    missingLabel: string,
    inScheduleLabel: string,
    outScheduleLabel: string,
    unbookedLabel: string,
) => {
    let actualCumulative = 0;
    let expectedCumulative = 0;

    let inScheduleCumulative = 0;
    let outScheduleCumulative = 0;
    let unbookedCumulative = 0;
    return chartData.map(dataRow => {
        actualCumulative += dataRow[actualLabel];
        expectedCumulative += dataRow[expectedLabel];

        inScheduleCumulative += dataRow[inScheduleLabel];
        outScheduleCumulative += dataRow[outScheduleLabel];
        unbookedCumulative += dataRow[unbookedLabel];

        return {
            ...dataRow,
            [percentageLabel]:
                (expectedCumulative === 0
                    ? actualCumulative
                    : actualCumulative / expectedCumulative) * 100,
            [expectedLabel]: expectedCumulative,
            [actualLabel]: actualCumulative,
            [missingLabel]:
                actualCumulative <= expectedCumulative ? expectedCumulative - actualCumulative : 0,

            [inScheduleLabel]: inScheduleCumulative,
            [outScheduleLabel]: outScheduleCumulative,
            [unbookedLabel]: unbookedCumulative,
        };
    });
};

export const ByFieldOverall = (
    data: PCAReportByFieldResponse,
    includeFields: string[],
    actualLabel: string,
    percentageLabel: string,
    expectedLabel: string,
    missingLabel: string,
    fieldLabel: string,
    inScheduleLabel: string,
    outScheduleLabel: string,
    unbookedLabel: string,
    accumulative = false,
) => {
    if (Object.keys(data?.reportData ?? {}).length > 0) {
        const chartData = Object.keys(data?.reportData).reduce((acc, fieldValue) => {
            if (includeFields.indexOf(fieldValue) !== -1) {
                const dayData = data.reportData[fieldValue];

                acc.push({
                    [fieldLabel]: fieldValue,
                    [percentageLabel]: dayData[2],
                    [expectedLabel]: dayData[1],
                    [actualLabel]: dayData[0],
                    [missingLabel]: dayData[0] <= dayData[1] ? dayData[1] - dayData[0] : 0,

                    [inScheduleLabel]: dayData[3],
                    [outScheduleLabel]: dayData[4],
                    [unbookedLabel]: dayData[5],
                    id: dayData?.[6] ?? null,
                    name: fieldValue,
                });
            }
            return acc;
        }, []);

        return accumulative
            ? toAccumulative(
                  chartData,
                  actualLabel,
                  percentageLabel,
                  expectedLabel,
                  missingLabel,
                  inScheduleLabel,
                  outScheduleLabel,
                  unbookedLabel,
              )
            : chartData;
    }

    return [];
};

export const ByDayByHourOverall = (
    data: PCAReportByFieldResponse,
    actualLabel: string,
    percentageLabel: string,
    expectedLabel: string,
    missingLabel: string,
    hourLabel: string,
    inScheduleLabel: string,
    outScheduleLabel: string,
    unbookedLabel: string,
    accumulative = false,
) => {
    if (Object.keys(data?.reportData ?? {}).length > 0) {
        // this is just expecting a single day of the hourly plays report endpoint
        const firstKey = Object.keys(data?.reportData ?? {})[0];

        if (firstKey != null) {
            const dayData = data.reportData[firstKey];

            const chartData = Object.keys(dayData).reduce((acc, hourKey) => {
                const hourData = dayData[hourKey];

                acc.push({
                    [hourLabel]: hourKey,
                    [percentageLabel]: hourData[2],
                    [expectedLabel]: hourData[1],
                    [actualLabel]: hourData[0],
                    [inScheduleLabel]: hourData[3],
                    [outScheduleLabel]: hourData[4],
                    [unbookedLabel]: hourData[5],
                });
                return acc;
            }, []);

            return accumulative
                ? toAccumulative(
                      chartData,
                      actualLabel,
                      percentageLabel,
                      expectedLabel,
                      missingLabel,
                      inScheduleLabel,
                      outScheduleLabel,
                      unbookedLabel,
                  )
                : chartData;
        }
    }

    return [];
};

/**
 * Modify chart data to be accumulative on charts which pit fields against each other.
 *
 * For example, we show line charts which pit towns against each other. Each town gets its own line on the chart.
 *
 * We need to modify the values so they accumulate over time.
 */
const byFieldOvertimeAccumulative = (
    chartData: Record<string, number>[],
    includeFields: string[],
    actualLabel: string,
    percentageLabel: string,
    expectedLabel: string,
) => {
    const counts = includeFields.reduce((acc, fieldValue) => {
        acc[fieldValue] = { actual: 0, expected: 0 };
        return acc;
    }, {} as Record<string, { actual: number; expected: number }>);

    return chartData.map(dataRow => {
        const newData = {
            ...dataRow,
        };

        /**
         * Using towns as an example, we're looping over each of the town names in the chart data
         * bumping up the accumulated numbers on each iteration.
         */
        includeFields.forEach(fieldValue => {
            // check if chart row has data for this field before trying to modify it
            if (dataRow[fieldValue] != null) {
                const fieldExpectedLabel = `${fieldValue} ${expectedLabel}`;
                const fieldPercentageLabel = `${fieldValue} ${percentageLabel}`;

                counts[fieldValue].actual += dataRow[fieldValue];
                counts[fieldValue].expected += dataRow[fieldExpectedLabel];

                newData[fieldValue] = counts[fieldValue].actual;
                newData[fieldExpectedLabel] = counts[fieldValue].expected;

                if (counts[fieldValue].expected > 0) {
                    newData[fieldPercentageLabel] =
                        (counts[fieldValue].actual / counts[fieldValue].expected) * 100;
                } else {
                    newData[fieldPercentageLabel] = counts[fieldValue].actual * 100;
                }
            }
        });

        return newData;
    });
};

/**
 * Using towns as an example... (but applies for all PCA fields)
 *
 * This function calculates data shown in the line charts which pit fields against each other.
 *
 * Using towns as an example, it shows how they all performed against each other in a single line chart
 * where each town gets its own line on the chart.
 */
export const ByFieldDaily = (
    data: PCAReportByFieldDailyResponse,
    includeFields: string[],
    actualLabel: string,
    percentageLabel: string,
    expectedLabel: string,
    fieldLabel: string,
    dateLabel: string,
    accumulative: boolean,
) => {
    if (Object.keys(data?.reportData ?? {}).length > 0) {
        const chartData = Object.keys(data?.reportData)
            .reduce((acc, fieldValue) => {
                if (includeFields.indexOf(fieldValue) !== -1) {
                    // e.g. if searching for PCA data by town, this will contain data for the entire town
                    // `fieldValue` would be equal to something like 'Watford' or 'Bournemouth (town names)
                    const fieldData = data.reportData[fieldValue];

                    // going through individual dates (days) for each field (town in this example)
                    Object.entries(fieldData).forEach(([date, stats]) => {
                        // find the index of the array storing data for the date in this iteration
                        // so we can add the current field's data into it.
                        const index = acc.findIndex(data => data[dateLabel] === date);

                        const fieldActualLabel = fieldValue;
                        const fieldExpectedLabel = `${fieldValue} ${expectedLabel}`;
                        const fieldPercentageLabel = `${fieldValue} ${percentageLabel}`;

                        if (index !== -1) {
                            acc[index] = {
                                ...acc[index],
                                [fieldLabel]: [...acc[index][fieldLabel], fieldValue],
                                [fieldPercentageLabel]: stats[2],
                                [fieldExpectedLabel]: stats[1],
                                [fieldActualLabel]: stats[0],
                            };
                        } else {
                            acc.push({
                                [dateLabel]: date,
                                [fieldLabel]: [fieldActualLabel],
                                [fieldPercentageLabel]: stats[2],
                                [fieldExpectedLabel]: stats[1],
                                [fieldActualLabel]: stats[0],
                            });
                        }
                    });
                }
                return acc;
            }, [])
            .sort(dateSortArray(dateLabel, ChartDataKeyDateFormat));

        return accumulative
            ? byFieldOvertimeAccumulative(
                  chartData,
                  includeFields,
                  actualLabel,
                  percentageLabel,
                  expectedLabel,
              )
            : chartData;
    }

    return [];
};

/**
 * Using towns as an example... (but applies for all PCA fields)
 *
 * This function calculates data shown in the line charts which pit fields against each other.
 *
 * Using towns as an example, it shows how they all performed against each other in a single line chart
 * where each town gets its own line on the chart.
 *
 * This function expects data for a single day which contains data broken down into hourly chunks.
 */
export const ByFieldHourly = (
    data: PCAReportByFieldHourlyResponse,
    includeFields: string[],
    actualLabel: string,
    percentageLabel: string,
    expectedLabel: string,
    fieldLabel: string,
    hourLabel: string,
    accumulative: boolean,
) => {
    if (Object.keys(data?.reportData ?? {}).length > 0) {
        const chartData = Object.keys(data?.reportData)
            .reduce((acc, fieldValue) => {
                if (includeFields.indexOf(fieldValue) !== -1) {
                    const areaData = data.reportData[fieldValue];
                    Object.entries(areaData).forEach(([date, dayStats]) => {
                        Object.entries(dayStats).forEach(([hour, stats]) => {
                            const index = acc.findIndex(data => data[hourLabel] === hour);

                            const fieldActualLabel = fieldValue;
                            const fieldExpectedLabel = `${fieldValue} ${expectedLabel}`;
                            const fieldPercentageLabel = `${fieldValue} ${percentageLabel}`;

                            if (index !== -1) {
                                acc[index] = {
                                    ...acc[index],
                                    [fieldLabel]: [...acc[index][fieldLabel], fieldValue],
                                    [`${fieldValue} ${percentageLabel}`]: stats[2],
                                    [fieldExpectedLabel]: stats[1],
                                    [fieldActualLabel]: stats[0],
                                };
                            } else {
                                acc.push({
                                    [fieldLabel]: [fieldActualLabel],
                                    [hourLabel]: hour,
                                    [fieldPercentageLabel]: stats[2],
                                    [fieldExpectedLabel]: stats[1],
                                    [fieldActualLabel]: stats[0],
                                });
                            }
                        });
                    });
                }

                return acc;
            }, [])
            .sort((a, b) => {
                // sometimes it'll come in with plain number keys for each hour like '1','12','15', etc.
                // sometimes it'll be full on hour strings like '08:00' '20:00'
                // really simple check to get the hour number portion from the key for sorting
                let aHr = a[hourLabel];
                let bHr = b[hourLabel];
                if (
                    typeof aHr === 'string' &&
                    aHr.indexOf(':') !== -1 &&
                    typeof bHr === 'string' &&
                    bHr.indexOf(':') !== -1
                ) {
                    aHr = aHr.split(':')[0];
                    bHr = bHr.split(':')[0];
                }
                return Number(aHr) - Number(bHr);
            });

        return accumulative
            ? byFieldOvertimeAccumulative(
                  chartData,
                  includeFields,
                  actualLabel,
                  percentageLabel,
                  expectedLabel,
              )
            : chartData;
    }

    return [];
};
