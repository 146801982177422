import { Creative } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { CreativeState } from './Creative-types';

const initialState: CreativeState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: CreativeState, action: PayloadAction<string>): CreativeState => state,
};

const slice = createBaseSlice<Creative, CreativeState, typeof additionalReducers>({
    name: 'creative',
    entityName: 'Creative',
    initialState,
    reducers: additionalReducers,
});

const creativeSlice = {
    creativeActions: slice.slice.actions,
    creativeReducer: slice.slice.reducer,
    creativeAdapter: slice.adapter,
    creativeSelectors: slice.selectors,
    creativeThunks: slice.thunks,
};

export default creativeSlice;
export const {
    creativeReducer,
    creativeActions,
    creativeAdapter,
    creativeSelectors,
    creativeThunks,
} = creativeSlice;
