import {
    missingDisplayActions,
    missingDisplayAdapter,
    missingDisplayThunks as defaultThunks,
    missingDisplaySelectors as defaultSelectors,
    missingDisplayReducer,
} from './MissingDisplay-slice';
import * as thunks from './MissingDisplay-thunks';
import * as selectors from './MissingDisplay-selectors';

const missingDisplayThunks = { ...defaultThunks, ...thunks };
const missingDisplaySelectors = { ...defaultSelectors, ...selectors };

export { default as MissingDisplaySlice } from './MissingDisplay-slice';
export { 
    missingDisplaySelectors,
    missingDisplayActions,
    missingDisplayAdapter,
    missingDisplayThunks,
    missingDisplayReducer,
};
