import React, { useCallback, useEffect, useMemo } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { PermissionName } from '@uniled/api-sdk';
import { displaysIcon, downloadIcon } from 'c-main/icons';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import {
    FilterableEntityTable,
    FilterableEntityTableProps,
    FilterableEntityTableRendererType,
    generateColumn,
} from 'c-pagination';
import { PopDisplays_Display } from '@uniled/api-sdk';
import { Translate } from 'c-translation';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Alert, CreateEntityButton, DialogV2 } from 'c-components';
import * as yup from 'yup';
import { DisplayForm, DisplayListActionsCell } from 'c-pop-displays/Components/Display';
import { stringOnlyNumbers } from 'c-lib';
import { omit } from 'ramda';
import { usePaginatedEntityData } from 'c-data';
import { useAPIClientRequest } from 'c-hooks';
import { AlertProps, CircularProgress, IconButton } from '@mui/material';
import to from 'await-to-js';
import apiClient from 'c-data/apiClient';
import ReactGA from 'react-ga';

const paginationTag = 'pop-display-list-search';
const textSearchCols = ['channel'];

const columns = [
    generateColumn<PopDisplays_Display>({
        headerTitle: 'Modules.PopDisplays.Display.table.idCol',
        field: 'id',
        entityName: 'PopDisplays_Display',
        orderable: true,
    }),
    generateColumn<PopDisplays_Display>({
        headerTitle: 'Modules.PopDisplays.Display.table.sourceCol',
        field: 'source',
        entityName: 'PopDisplays_Display',
    }),
    generateColumn<PopDisplays_Display>({
        headerTitle: 'Modules.PopDisplays.Display.table.nameCol',
        field: 'name',
        entityName: 'PopDisplays_Display',
    }),
    generateColumn<PopDisplays_Display>({
        headerTitle: 'Modules.PopDisplays.Display.table.channelCol',
        field: 'channel',
        entityName: 'PopDisplays_Display',
        orderable: true,
    }),
    generateColumn<PopDisplays_Display>({
        headerTitle: 'Modules.PopDisplays.Display.table.frameCol',
        field: 'frame',
        entityName: 'PopDisplays_Display',
    }),
    generateColumn<PopDisplays_Display>({
        headerTitle: 'Modules.PopDisplays.Display.table.ownerCol',
        field: 'owner',
        entityName: 'PopDisplays_Display',
    }),
    generateColumn<PopDisplays_Display>({
        headerTitle: 'Modules.PopDisplays.Display.table.frameIdCol',
        field: 'frame_id',
        entityName: 'PopDisplays_Display',
    }),
    generateColumn<PopDisplays_Display>({
        headerTitle: 'Modules.PopDisplays.Display.table.displayPortalIdCol',
        field: 'displays_portal_id',
        entityName: 'PopDisplays_Display',
    }),
    generateColumn<PopDisplays_Display>({
        headerTitle: 'Modules.PopDisplays.Display.table.sizeCol',
        field: 'size',
        entityName: 'PopDisplays_Display',
    }),
    generateColumn<PopDisplays_Display>({
        headerTitle: 'Modules.PopDisplays.Display.table.resolutionCol',
        field: 'resolution',
        entityName: 'PopDisplays_Display',
    }),
    generateColumn<PopDisplays_Display>({
        headerTitle: 'Modules.PopDisplays.Display.table.slotsCol',
        field: 'slots',
        entityName: 'PopDisplays_Display',
    }),
    generateColumn<PopDisplays_Display>({
        headerTitle: 'Modules.PopDisplays.Display.table.slotLengthCol',
        field: 'slot_length',
        entityName: 'PopDisplays_Display',
    }),
    generateColumn<PopDisplays_Display>({
        headerTitle: '',
        field: 'actions',
        entityName: 'PopDisplays_Display',
        RenderCell: DisplayListActionsCell,
        permissions: [PermissionName.Pop_displaysDisplaysDestroy],
    }),
];

const schema = yup.object({
    source: yup.string().required(),
});

const defaultValues: Partial<PopDisplays_Display> = {
    channel: '',
    frame: '',
    display_key: '',
};

const updateSearchPayload: FilterableEntityTableProps['updateSearchPayload'] = payload => {
    const searchValue = Object.values(payload?.searchables ?? {}).find(v =>
        stringOnlyNumbers(String(v)),
    );
    if (searchValue != null && String(searchValue).length > 7) {
        // frame IDs are typically 10 characters afaik, and I doubt we'll have display ids exceeding an ID count of 7 digits
        return {
            ...payload,
            filters: { ...payload.filters, 'filter.v_displays.frame_id': [searchValue] },
            searchables: {},
        } as unknown as typeof payload.filters;
    }

    return {
        ...payload,
        filters: omit(['filter.v_displays.frame_id'], payload.filters) as typeof payload.filters,
    };
};

const DisplayListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <FullPageEntityTableWrapper
            title="Modules.PopDisplays.Display.metaTitle"
            controls={useMemo(
                () => [
                    <CreateEntityButton<PopDisplays_Display>
                        key="new-display-btn"
                        entityName="PopDisplays_Display"
                        title="Modules.PopDisplays.Display.newDisplay.title"
                        buttonLabel={
                            <Translate path="Modules.PopDisplays.Display.newDisplay.createDisplayButton" />
                        }
                        submitLabel="Modules.PopDisplays.Display.newDisplay.createDisplayButton"
                        form={<DisplayForm />}
                        defaultData={defaultValues}
                        formOptions={{
                            resolver: yupResolver(schema),
                        }}
                        redirectPath={PostAuthRoutes.PopDisplays.DisplayOverview}
                        permissions={[PermissionName.Pop_displaysDisplaysCreate]}
                        transformData={(data: Partial<PopDisplays_Display>) => {
                            let theDisplayPortalId = data.displays_portal_id;
                            if (String(theDisplayPortalId).trim() === '')
                                theDisplayPortalId = undefined; // undefined stuff doesn't get included in JSON

                            return {
                                ...data,
                                displays_portal_id: theDisplayPortalId,
                            } as PopDisplays_Display;
                        }}
                    />,
                    <DownloadBtn key="download" />,
                ],
                [],
            )}
        >
            <FilterableEntityTable
                key={paginationTag}
                tag={paginationTag}
                columns={columns}
                baseEntityName="PopDisplays_Display"
                textSearchColumns={textSearchCols}
                rendererType={FilterableEntityTableRendererType.Contained}
                orderBy="channel"
                direction="asc"
                showFilters
                routeTemplate={PostAuthRoutes.PopDisplays.DisplayOverview}
                updateSearchPayload={updateSearchPayload}
            />
        </FullPageEntityTableWrapper>
    );
};

const DownloadBtn: React.FC = () => {
    const { paginatedData } = usePaginatedEntityData(paginationTag, 'PopDisplays_Display');
    const { start, data, isLoading, hasSucceeded, hasFailed, reset, error } = useAPIClientRequest(
        apiClient.PopDisplays.displayExport,
    );

    const download = useCallback(async () => {
        await to(start({ filters: paginatedData?.filters ?? {}, page: 1, perPage: 9999999 }));
    }, [start, paginatedData]);

    const alertColor = useMemo<AlertProps['color']>(() => {
        if (hasSucceeded) return 'success';
        if (hasFailed) return 'error';

        return 'info';
    }, [hasSucceeded, hasFailed]);

    const message = useMemo(() => {
        if (hasSucceeded) return data?.data?.message ? String(data?.data?.message) : '';
        if (hasFailed) return String(error);

        return null;
    }, [data?.data?.message, error, hasFailed, hasSucceeded]);

    return (
        <>
            <DialogV2 onClose={reset} open={hasSucceeded || hasFailed}>
                <Alert variant="outlined" color={alertColor}>
                    {message}
                </Alert>
            </DialogV2>
            <IconButton onClick={download} disabled={isLoading}>
                {isLoading ? <CircularProgress size={20} /> : downloadIcon}
            </IconButton>
        </>
    );
};

const setup: PageConfig = {
    id: 'DisplayListPage',
    component: DisplayListPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.PopDisplays.Display.metaTitle',
        description: 'Modules.PopDisplays.Display.description',
        icon: displaysIcon,
        type: 'page',
        aliases: 'SystemSearch.aliases.popDisplaysDisplayList',
        route: PostAuthRoutes.PopDisplays.DisplayList,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.PopDisplays.AdminHome.metaTitle',
                link: PostAuthRoutes.PopDisplays.PopDisplaysHome,
            },
            { label: 'Modules.PopDisplays.Display.metaTitle' },
        ],
        permissions: [
            PermissionName.Pop_displaysDisplaysRead,
            PermissionName.Pop_displaysDisplaysUpdate,
            PermissionName.Pop_displaysDisplaysCreate,
        ],
    },
};

export default setup;
