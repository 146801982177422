import React, { useMemo } from 'react';
import { CampaignError, CampaignErrorType } from '@uniled/api-sdk';
import { Box, Typography } from '@mui/material';
import { Translate, useCommonTranslation, useCommonTranslationInstance } from 'c-translation';
import { Alert } from 'c-components/MuiOverrides';

type Props = {
    error: CampaignError;
    hideExpected?: boolean;
    hideOutcome?: boolean;
};

const HideAvailableOutcome = [
    CampaignErrorType.MissingCreativeResolutions,
    CampaignErrorType.NoMatchingImageCreatives,
    CampaignErrorType.NoMatchingAnalogueCreatives,
    CampaignErrorType.NoMatchingVideoCreatives,
];

const CampaignErrorAlert: React.FC<Props> = ({
    error,
    hideExpected = false,
    hideOutcome = false,
}) => {
    const i18n = useCommonTranslationInstance();
    const t = useCommonTranslation();
    const showExpectedMemo = useMemo(
        () =>
            !hideExpected &&
            error?.data?.expected?.length > 0 &&
            HideAvailableOutcome.indexOf(error.type) === -1,
        [error, hideExpected],
    );
    const showAvailableMemo = useMemo(
        () =>
            !hideOutcome &&
            error?.data?.outcome?.length > 0 &&
            HideAvailableOutcome.indexOf(error.type) === -1,
        [error, hideOutcome],
    );

    const titlePath = useMemo(
        () =>
            `Modules.Main.CampaignError.errorableType.${error.errorable_type}.errorTitles.${error.type}`,
        [error],
    );
    const expectedPath = useMemo(
        () =>
            `Modules.Main.CampaignError.errorableType.${error.errorable_type}.expected.${error.type}`,
        [error],
    );
    const availablePath = useMemo(
        () =>
            `Modules.Main.CampaignError.errorableType.${error.errorable_type}.available.${error.type}`,
        [error],
    );

    const expectedContent = useMemo(() => {
        if (!showExpectedMemo || !i18n) {
            return '';
        }

        const str = error.data.expected.join(', ');

        if (i18n.exists(expectedPath)) {
            return <Typography variant="body2">{t(expectedPath, { expected: str })}</Typography>;
        }

        return (
            <Typography variant="body2">
                <Translate path="Modules.Main.CampaignError.expectedTitle" />:{' '}
                <Typography variant="caption">{str}</Typography>
            </Typography>
        );
    }, [error.data.expected, i18n, expectedPath, showExpectedMemo, t]);

    const availableContent = useMemo(() => {
        if (!showAvailableMemo || !i18n) {
            return '';
        }

        const str = error.data.outcome.join(', ');

        if (i18n.exists(availablePath)) {
            return <Typography variant="body2">{t(availablePath, { available: str })}</Typography>;
        }

        return (
            <Typography variant="body2">
                <Translate path="Modules.Main.CampaignError.availableTitle" />:{' '}
                <Typography variant="caption">{str}</Typography>
            </Typography>
        );
    }, [availablePath, error.data.outcome, i18n, showAvailableMemo, t]);

    return (
        <Alert severity="warning" variant="outlined">
            {useCommonTranslation(titlePath)}
            {showExpectedMemo && <Box display="flex">{expectedContent}</Box>}
            {showAvailableMemo && <Box display="flex">{availableContent}</Box>}
        </Alert>
    );
};

export default CampaignErrorAlert;
