import React, { useCallback, useMemo } from 'react';
import { Campaign } from '@uniled/api-sdk';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Checkbox } from 'c-components';
import UpdatedByUserCaption from 'c-components/UpdatedByUserCaption';
import { useUpdateEntityData } from 'c-data';
import { NetworkRequestState } from '@uniled/data-layer';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { TranslationPath, useCommonTranslation } from 'c-translation';

type Props = {
    campaign: Campaign;
    value: boolean;
    dateKey: keyof Campaign;
    userIdKey: keyof Campaign;
    label: TranslationPath;
    generateSaveData: (campaign: Campaign, newState: boolean) => Campaign;
};

const CampaignUserEditedBooleanCheckbox: React.FC<Props> = ({
    value,
    dateKey,
    userIdKey,
    campaign,
    label,
    generateSaveData,
}) => {
    const { update, getUpdatingById } = useUpdateEntityData<Campaign>('Campaign');
    const loading = useMemo(
        () => getUpdatingById(campaign.id).state === NetworkRequestState.InProgress,
        [campaign.id, getUpdatingById],
    );

    const onChange = useCallback(() => {
        update(campaign.id, generateSaveData(campaign, !value));
    }, [update, campaign, value, generateSaveData]);

    const { hasAdminPermission } = useUserPermissions();

    const showCaption = campaign?.[userIdKey] != null && value === true;

    return (
        <Box>
            <Box>
                <Checkbox
                    disabled={loading || !hasAdminPermission}
                    checked={value}
                    label={
                        <Typography variant="caption" noWrap>
                            {useCommonTranslation(label)}
                        </Typography>
                    }
                    onChange={onChange}
                    isBoolean
                    checkedIcon={loading ? <CircularProgress size={15} /> : undefined}
                    icon={loading ? <CircularProgress size={15} /> : undefined}
                />
            </Box>
            {showCaption && (
                <UpdatedByUserCaption
                    justifyContent="flex-start"
                    userId={campaign[userIdKey] as number}
                    updatedAt={campaign[dateKey] as string}
                />
            )}
        </Box>
    );
};

export default CampaignUserEditedBooleanCheckbox;
