import { useCallback } from 'react';
import { AllEntities } from '@uniled/api-sdk';
import useCommonTranslation from './useCommonTranslation';
import useCommonTranslationInstance from './useCommonTranslationInstance';

const useModelFieldTranslation = () => {
    const t = useCommonTranslation();
    const i18n = useCommonTranslationInstance();

    return useCallback(
        (entityName: keyof AllEntities, field: string, rawValue: string) => {
            const key = `Models.${entityName}.${field}.${rawValue}`;
            if (i18n.exists(key)) {
                return t(key);
            }

            return rawValue;
        },
        [t, i18n],
    );
};

export default useModelFieldTranslation;
