import React, { useMemo } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Translate } from 'c-translation';
import { useEntityData, User } from 'c-data';
import { Alert, AutoGrid, Button, EntityField } from 'c-components';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PermissionName, Client, Campaign } from '@uniled/api-sdk';
import { useDateUtils } from 'c-hooks';
import { UserProfilePopOver } from 'c-admin/Components';
import { NetworkRequestState } from '@uniled/data-layer';

type Props = {
    // campaign id
    id: number;
};

const clientReadPerms = [PermissionName.UniledportalClientRead];
const dateReadPerms = [PermissionName.UniledportalDateRead];

const CampaignOverviewDetails: React.FC<Props> = ({ id }) => {
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });
    const { formatDateStringIgnoreTime } = useDateUtils();
    return useMemo(
        () => (
            <Box>
                <AutoGrid spacing={2}>
                    <Block
                        title={
                            <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.bookedByHeader" />
                        }
                        value={<BookedBy campaign={campaign} />}
                    />
                    <IfHasAllPermissions permissions={clientReadPerms}>
                        <Block
                            title={
                                <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.brandHeader" />
                            }
                            value={
                                <EntityField<Client>
                                    id={campaign?.client_id}
                                    entityName="Client"
                                    field="name"
                                />
                            }
                        />
                    </IfHasAllPermissions>
                    {/* <IfHasAllPermissions permissions={[PermissionName.UniledportalAgencyRead]}> */}
                    {/*    <Block */}
                    {/*        title={ */}
                    {/*            <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.agencyHeader" /> */}
                    {/*        } */}
                    {/*        value={ */}
                    {/*            <EntityField<Agency> */}
                    {/*                id={campaign?.agency_id} */}
                    {/*                entityName="Agency" */}
                    {/*                field="name" */}
                    {/*            /> */}
                    {/*        } */}
                    {/*    /> */}
                    {/* </IfHasAllPermissions> */}
                    <IfHasAllPermissions permissions={dateReadPerms}>
                        <Block
                            title={
                                <Translate path="Modules.Main.Campaigns.Overview.OverviewSection.duration" />
                            }
                            value={
                                <>
                                    {formatDateStringIgnoreTime({
                                        date: campaign?.date?.date_start,
                                    })}{' '}
                                    -{' '}
                                    {formatDateStringIgnoreTime({
                                        date: campaign?.date?.date_end,
                                    })}
                                </>
                            }
                        />
                    </IfHasAllPermissions>
                </AutoGrid>
                {campaign?.flags?.under_configuration === true && (
                    <Alert severity="warning" variant="outlined">
                        <Translate path="Modules.Main.Campaigns.Overview.underConstructionWarning" />
                    </Alert>
                )}
            </Box>
        ),
        [campaign, formatDateStringIgnoreTime],
    );
};

const Block: React.FC<{ title: React.ReactNode; value: React.ReactNode }> = ({ title, value }) => (
    <Box>
        <Typography color="grey.500">{title}</Typography>
        <Typography sx={{ maxWidth: '20em', wordBreak: 'break-word' }} component="div">
            {value}
        </Typography>
    </Box>
);

const BookedBy: React.FC<{ campaign: Campaign }> = ({ campaign }) => {
    const userId = campaign.user_id;
    const { getById, getLoadingById } = useEntityData<User>('User');
    const user = getById({ id: userId });
    // const firstBrand = user?.clients?.[0];
    // const { hasAll } = useUserPermissions();
    // const canReadClients = useMemo(() => hasAll(clientReadPerms), [hasAll]);

    const isLoading = useMemo(
        () => getLoadingById(userId).state === NetworkRequestState.InProgress,
        [userId, getLoadingById],
    );
    const username = useMemo(
        () =>
            user ? (
                <UserProfilePopOver userId={userId}>
                    <Box>
                        <Button variant="text">{user?.name}</Button>
                    </Box>
                </UserProfilePopOver>
            ) : null,
        [user, userId],
    );
    if (!user && isLoading)
        return (
            <>
                <CircularProgress size={15} />
            </>
        );

    if (!user) return <>-</>;

    // if (firstBrand && canReadClients) {
    //     return (
    //         <Typography component="div">
    //             <AutoGrid alignItems="center">
    //                 <IfHasAllPermissions permissions={clientReadPerms}>
    //                     {' '}
    //                     <Box>
    //                         <EntityField<Client> id={firstBrand} entityName="Client" field="name" />
    //                         {` - `}
    //                     </Box>
    //                 </IfHasAllPermissions>
    //                 {username}
    //             </AutoGrid>
    //         </Typography>
    //     );
    // }

    return <>{username}</>;
};

export default CampaignOverviewDetails;
