import { parseISO } from 'date-fns';
import { Creative } from '@uniled/api-sdk';
import { FileBrowserFile } from 'c-components';
import { aspectRatio, isoDateNoTimeDiff } from 'c-lib';
import { generateFileResolutionTag } from '../../../../Components/FileBrowser/lib';

export const creativeToFileBrowserFile = (creative?: Creative): FileBrowserFile => {
    const fileTypeSimple = (
        creative?.file?.filetype?.split('/')[1] ?? creative?.file?.image_type
    )?.toUpperCase();
    let actualMimeType = creative?.file?.filetype;

    if (creative?.file?.filename?.endsWith('.mp4')) {
        // there's just a lot of inconsistent annoying data
        actualMimeType = 'video/mp4';
    }
    const fileAspectRatio = aspectRatio(creative?.file?.image_width, creative?.file?.image_height);
    const [ratioLeft, ratioRight] = fileAspectRatio.split(':');

    let name = creative?.name;

    if (name?.trim()?.length == 0) {
        name = creative?.file?.filename_raw ?? creative?.file?.filename ?? '';
    }

    return {
        downloadId: creative?.id,
        id: creative?.file?.id ?? creative?.id,
        name,
        createdAt: creative?.created_at,
        createdAtDate: parseISO(creative?.created_at),
        createdBy: creative?.user_id,
        updatedAt: creative?.updated_at,
        updatedAtDate: creative?.updated_at ? parseISO(creative.updated_at) : null,
        liveDate: creative?.live_date,
        liveDateDate: creative?.live_date ? isoDateNoTimeDiff(creative?.live_date) : null,
        endDateDate: creative?.end_date ? isoDateNoTimeDiff(creative?.end_date) : null,
        endDate: creative?.end_date,
        durationMs: creative?.file?.duration_ms ?? 0,
        height: creative?.file?.image_height ?? 0,
        width: creative?.file?.image_width ?? 0,
        aspectRatio: fileAspectRatio,
        aspectRatioNormalised: Number(ratioRight) / Number(ratioLeft),
        size: creative?.file?.filesize ?? 0,
        assignedDisplays: creative?.lineItemsCount ?? 0,
        mimeType: actualMimeType ?? '',
        fileType: (creative?.file?.image_type as FileBrowserFile['fileType']) ?? '',
        fileTypeSimple,
        groupIds: creative?.creativeGroupIds ?? [],
        urls: {
            raw: creative?.file?.urls?.raw,
            download: creative?.file?.urls?.download,

            small: creative?.file?.urls?.small ?? creative?.file?.urls?.raw,
            medium: creative?.file?.urls?.partner ?? creative?.file?.urls?.raw,
            large: creative?.file?.urls?.poster ?? creative?.file?.urls?.raw,
        },
        tags: {
            fileType: fileTypeSimple,
            resolution: generateFileResolutionTag(
                creative?.file?.image_width,
                creative?.file?.image_height,
            ),
            aspectRatio: fileAspectRatio,
        },
    };
};

export const creativesToFileBrowserFiles = (creatives: Creative[]): FileBrowserFile[] =>
    creatives?.filter(c => c.file_id != null).map(creativeToFileBrowserFile) ?? [];
