import { useEffect, useState } from 'react';
import equal from 'fast-deep-equal';
import useUrlState from './useUrlState';

function useUrlStateValue<T>(keyPath?: string, defaultValue: T = null) {
    const { get } = useUrlState();
    const [value, setValue] = useState<T | null>(() => {
        return keyPath != null ? get(keyPath, defaultValue) : null;
    });

    useEffect(() => {
        setValue(currentVal => {
            if (keyPath != null) {
                /**
                 * don't want to set a new object ref if the actual underlying value hasn't changed
                 */
                const newValue = get(keyPath, defaultValue);
                if (!equal(currentVal, newValue)) {
                    return newValue;
                }
            }
            return currentVal;
        });
    }, [keyPath, defaultValue, get]);

    return value;
}

export default useUrlStateValue;
