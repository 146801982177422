import memoize from 'lodash/memoize';
import { createSelector } from '@reduxjs/toolkit';
import { NetworkRequestState, NetworkRequest } from '@uniled/data-layer';
import { CommonState } from 'c-types/RootState';

const getLargeLogosDictionary = (state: CommonState) => state.skin.uploadingLargeLogoById;
const getSmallLogosDictionary = (state: CommonState) => state.skin.uploadingSmallLogoById;

const getAttachingDictionary = (state: CommonState) => state.skin.attachingSkinById;

const idleState = { state: NetworkRequestState.Idle } as NetworkRequest;
export const getUploadingLargeLogoById = createSelector(getLargeLogosDictionary, uploading =>
    memoize((id: number) => uploading[id] ?? idleState),
);

export const getUploadingSmallLogoById = createSelector(getSmallLogosDictionary, uploading =>
    memoize((id: number) => uploading[id] ?? idleState),
);

export const getAttachingSkinById = createSelector(getAttachingDictionary, attaching =>
    memoize((id: number) => attaching[id] ?? idleState),
);
