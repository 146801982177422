import React, { useMemo } from 'react';
import { Chip as MuiChip, ChipProps, lighten } from '@mui/material';
import { useTheme } from '@mui/styles';
import merge from 'deepmerge';

type Props = ChipProps;

const Chip: React.FC<Props> = props => {
    const theme = useTheme();
    const { variant, color, sx } = props;

    const theSx = useMemo(() => {
        const themeColor = theme?.palette?.[color]?.main;
        if (variant === 'outlined') {
            // merge this override SX with whatever other SX may have been supplied
            return merge(
                { bgcolor: themeColor ? lighten(themeColor, 0.96) : theme.palette?.action?.hover },
                (sx ?? {}) as any,
            ) as ChipProps['sx'];
        }

        return sx;
    }, [variant, color, sx, theme]);

    return <MuiChip {...props} sx={theSx} />;
};

export default Chip;
