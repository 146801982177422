import React, { useCallback, useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Campaign } from '@uniled/api-sdk';
import { NumberFormat } from 'c-components';
import { Box, Stack, Typography, TypographyProps } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { useAPIClientRequest, useDateUtils } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { useEntityData } from 'c-data';
import { useBoolean } from 'react-hanger';
import { NetworkRequestState } from '@uniled/data-layer';
import to from 'await-to-js';
import LastContactedMediaOwnerDetails from './LastContactedMediaOwner/LastContactedMediaOwnerDetails';

type Props = RenderColumnDataProps<Campaign> & {
    showMediaOwnersWithNoPca?: boolean;
};

// const rowCss = css('cursor: pointer;');
const CampaignDataStatusReportingCell: React.FC<Props> = ({
    entity,
    showMediaOwnersWithNoPca = true,
}) => {
    const { start, isLoading, /* hasFailed, error, reset, hasSucceeded, */ requestStateRef } =
        useAPIClientRequest(apiClient.Entities.CampaignOwnerContactedDate.create);
    const { getById, getLoadingById } = useEntityData('Campaign');
    const isSomethingLoading = useMemo(
        () => isLoading || getLoadingById(entity.id).state === NetworkRequestState.InProgress,
        [isLoading, getLoadingById, entity],
    );

    const { apiFormatDateTimeString } = useDateUtils();
    const onSave = useCallback(
        async (ownerId: number, newValue: Date) => {
            if (requestStateRef.current === NetworkRequestState.InProgress) return;

            const [err, succ] = await to(
                start({
                    campaign_id: entity.id,
                    owner_id: ownerId,
                    contacted_at: apiFormatDateTimeString({ date: newValue }),
                }),
            );

            if (!err && succ?.data?.data?.campaign_id === entity.id) {
                getById({ id: entity.id, forceRefresh: true, includes: ['ownerContactedDates'] });
            }
        },
        [apiFormatDateTimeString, entity, getById, start, requestStateRef],
    );

    const { value: IsDialogOpen, /* setTrue: OpenDialog , */ setFalse: CloseDialog } =
        useBoolean(false);

    const byMediaOwnerContent = useMemo(
        () => (
            <Stack gap={0.5} display="flex">
                <table>
                    <tbody>
                        {(entity.owners ?? [])
                            .filter(o => o.owner?.id != null)
                            .map(o => (
                                <tr key={o.owner_id}>
                                    <td>
                                        <Typography variant="body2" align="right" noWrap>
                                            {`${o.owner?.name}: `}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography variant="body2" noWrap>
                                            {o.playout_percentage != null ? (
                                                <Box
                                                    component="span"
                                                    color={
                                                        Number(o.playout_percentage) >= 100
                                                            ? 'success.main'
                                                            : 'error.main'
                                                    }
                                                >
                                                    <NumberFormat value={o.playout_percentage} />%
                                                </Box>
                                            ) : (
                                                '-'
                                            )}
                                        </Typography>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </Stack>
        ),
        [entity],
    );

    const overallColor = useMemo<TypographyProps['color']>(() => {
        if (entity.pca_last_run_at == null) {
            return undefined;
        }

        if (Number(entity.playout_percentage) >= 100) {
            return 'success.main';
        }

        return 'error.main';
    }, [entity.pca_last_run_at, entity.playout_percentage]);

    const t = useCommonTranslation();
    const overallContent = useMemo(
        () =>
            entity.playout_percentage != null && (
                <Typography noWrap variant="h6" textAlign="right">
                    {`${t('Modules.Main.Campaigns.DataStatus.table.reportingColOverall')} `}
                    <Box color={overallColor} component="span">
                        <NumberFormat value={entity.playout_percentage} />%
                    </Box>
                </Typography>
            ),
        [overallColor, entity.playout_percentage, t],
    );

    if (entity.playout_percentage == null && !showMediaOwnersWithNoPca) {
        return null;
    }

    return (
        <Stack gap={0.5}>
            {byMediaOwnerContent}
            <Box mt={2}>{overallContent}</Box>
            {IsDialogOpen && (
                <LastContactedMediaOwnerDetails
                    onClose={CloseDialog}
                    owners={entity.owners}
                    contacts={entity.ownerContactedDates ?? []}
                    onSaveDate={onSave}
                    isLoading={isSomethingLoading}
                    overallContent={overallContent}
                />
            )}
        </Stack>
    );
};

export default CampaignDataStatusReportingCell;
