import React, { useCallback, useState } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { DialogV2, EntityAutocomplete } from 'c-components';
import apiClient from 'c-data/apiClient';
import { useAtom } from 'jotai';
import { useCommonTranslation } from 'c-translation';
import { atom_uploadPopShotDialog } from 'c-main/Components/Campaign/CampaignOverviewPopShotsTab/atoms';
import { Box, IconButton, Stack } from '@mui/material';
import { Upload } from '@mui/icons-material';
import CampaignPopShotUpload from 'c-main/Components/Campaign/CampaignPopShot/CampaignPopShotUpload';

type Props = {
    campaignId: number;
};

const PopShotUploadSelectLineItem: React.FC<Props> = ({ campaignId }) => {
    const [dialogOpen, setDialogOpen] = useAtom(atom_uploadPopShotDialog);
    const [uploadPopShotLineItemIdDialog, setUploadPopShotLineItemIdDialog] =
        useState<string>(null);

    const lineItemsSearch = useCallback(
        (...rest) => apiClient.Entities.Campaign.lineItems(campaignId, ...rest),
        [campaignId],
    );

    const openUploadPopShotDialog = useCallback(() => {
        setDialogOpen(true);
    }, [setDialogOpen]);
    const closeUploadPopShotDialog = useCallback(() => {
        setDialogOpen(false);
    }, [setDialogOpen]);
    const t = useCommonTranslation();
    return (
        <>
            <Box display="flex" alignItems="flex-end">
                <IconButton onClick={openUploadPopShotDialog} size="small">
                    <Upload />
                </IconButton>
            </Box>
            <DndProvider backend={HTML5Backend}>
                <DialogV2
                    open={dialogOpen}
                    onClose={closeUploadPopShotDialog}
                    title="Modules.Main.Campaigns.PopShots.upload.title"
                >
                    {dialogOpen && (
                        <Stack gap={2} py={2}>
                            <EntityAutocomplete
                                entityName="Displays_LineItem"
                                customListSearch={lineItemsSearch as any}
                                textFieldProps={{
                                    label: t(
                                        'Modules.Main.Campaigns.PopShots.upload.lineItemLabel',
                                    ),
                                }}
                                value={uploadPopShotLineItemIdDialog}
                                onChange={setUploadPopShotLineItemIdDialog}
                                searchColumns="name"
                                labelColumn="name"
                            />
                            {uploadPopShotLineItemIdDialog && (
                                <CampaignPopShotUpload
                                    key={uploadPopShotLineItemIdDialog}
                                    lineItemId={uploadPopShotLineItemIdDialog}
                                />
                            )}
                        </Stack>
                    )}
                </DialogV2>
            </DndProvider>
        </>
    );
};

export default PopShotUploadSelectLineItem;
