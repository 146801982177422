import { Displays_Owner as Owner } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { OwnerState } from './Owner-types';

const initialState: OwnerState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: OwnerState, action: PayloadAction<string>): OwnerState => state,
};

const slice = createBaseSlice<Owner, OwnerState, typeof additionalReducers>({
    name: 'owner',
    entityName: 'Owner',
    initialState,
    reducers: additionalReducers,
});

const ownerSlice = {
    ownerActions: slice.slice.actions,
    ownerReducer: slice.slice.reducer,
    ownerAdapter: slice.adapter,
    ownerSelectors: slice.selectors,
    ownerThunks: slice.thunks,
};

export default ownerSlice;
export const { ownerReducer, ownerActions, ownerAdapter, ownerSelectors, ownerThunks } = ownerSlice;
