import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, DialogV2, ErrorImageTryAgain, FileBrowserFile, FlashMessage } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { Box, CircularProgress, Divider, Stack } from '@mui/material';
import { ResetCreativesData, ReValidateCampaign } from 'c-main/Components/CreativeManagement/types';
import CreativeDetailsTable from './CreativeDetailsTable';
import AssignmentLineItemsGrid from './AssignmentLineItemsGrid';

type Props = {
    open: boolean;
    onClose: () => void;
    campaignId: number;
    file: FileBrowserFile;
    resetData: ResetCreativesData;
    reValidateCampaign: ReValidateCampaign;
};

const AssignCreativeDialog: React.FC<Props> = ({
    open,
    onClose,
    campaignId,
    file,
    resetData,
    reValidateCampaign,
}) => {
    const [selectedLineItems, setSelectedLineItems] = useState<string[]>([]);
    const t = useCommonTranslation();

    const {
        start: LoadCreative,
        isLoading: IsCreativeLoading,
        hasFailed: HasCreativeLoadFailed,
        data: CreativeData,
    } = useAPIClientRequest(apiClient.Entities.Creative.get);

    const alreadyAssignedLineItems = useMemo(
        () => CreativeData?.data?.data?.lineItems?.map(line => line.id) ?? [],
        [CreativeData],
    );

    const {
        start: AssignCreatives,
        isLoading: AreCreativesAssigning,
        hasFailed: HaveCreativesFailed,
        error,
    } = useAPIClientRequest(apiClient.Entities.Campaign.forceAssignCreativesToLineItems);

    const creativesFailureMessage = useMemo(() => {
        const errStr = String(error);
        if (errStr.trim().length > 0) return errStr;

        return t('Modules.Main.CreativeManagement.creatives.assignDialog.failedToAssign');
    }, [t, error]);

    const {
        start: LoadLineItems,
        data: LineItemData,
        isLoading: AreLineItemsLoading,
        hasFailed: HaveLineItemsFailed,
    } = useAPIClientRequest(apiClient.Entities.Campaign.lineItems);

    const lineItems = useMemo(() => LineItemData?.data?.data ?? [], [LineItemData]);

    const loadLineItems = useCallback(async () => {
        setSelectedLineItems([]);
        await to(
            LoadCreative(file.downloadId, [
                'lineItems',
                'lineItems.fileTypes',
                'lineItems.resolutions',
            ]),
        );
        await to(
            LoadLineItems(campaignId, {
                perPage: 9999,
                includes: ['owner', 'resolutions', 'fileTypes'],
            }),
        );
    }, [LoadCreative, LoadLineItems, campaignId, file.downloadId]);

    useEffect(() => {
        loadLineItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const assignDisabled = useMemo(
        () => selectedLineItems.length === 0 || AreCreativesAssigning,
        [selectedLineItems.length, AreCreativesAssigning],
    );

    const assignCreatives = useCallback(async () => {
        const [err, suc] = await to(
            AssignCreatives(campaignId, {
                lineItems: selectedLineItems,
                creatives: [file.downloadId],
            }),
        );

        if (!err && suc?.data?.creatives != null && suc?.data?.creativeGroups != null) {
            //
            resetData(suc.data);
            reValidateCampaign();
            onClose();
        }
    }, [
        AssignCreatives,
        campaignId,
        selectedLineItems,
        file,
        resetData,
        reValidateCampaign,
        onClose,
    ]);

    //

    const failedToLoad = useMemo(
        () => HasCreativeLoadFailed || HaveLineItemsFailed,
        [HasCreativeLoadFailed, HaveLineItemsFailed],
    );

    return (
        <DialogV2
            open={open}
            onClose={onClose}
            maxWidth="xl"
            title="Modules.Main.CreativeManagement.creatives.assignDialog.dialogTitle"
            description="Modules.Main.CreativeManagement.creatives.assignDialog.dialogDescription"
            contentHeight="70vh"
            actions={
                <>
                    <Button disabled={assignDisabled} onClick={assignCreatives}>
                        {t(
                            'Modules.Main.CreativeManagement.creatives.assignDialog.assignButtonLabel',
                        )}
                        {AreCreativesAssigning && (
                            <Box ml={1} display="flex" alignItems="center">
                                <CircularProgress size={15} />
                            </Box>
                        )}
                    </Button>
                </>
            }
        >
            <Box display="flex" flexDirection="column" flex={1} overflow="hidden">
                <Stack spacing={2} flex={1} overflow="hidden">
                    {file && (
                        <Box>
                            <CreativeDetailsTable file={file} />
                        </Box>
                    )}
                    <Divider />
                    <Box flex={1} minHeight={250} height="100%" overflow="hidden" display="flex">
                        {!failedToLoad && (
                            <AssignmentLineItemsGrid
                                canAction
                                loading={AreLineItemsLoading || IsCreativeLoading}
                                lineItems={lineItems}
                                onSelectionUpdated={setSelectedLineItems}
                                selectedLineItems={selectedLineItems}
                                alreadyAssignedLineItems={alreadyAssignedLineItems}
                                file={file}
                            />
                        )}

                        {failedToLoad && (
                            <ErrorImageTryAgain
                                title="Modules.Main.CreativeManagement.creatives.assignDialog.failedToLoadAssignData"
                                retryLabel="Modules.Main.CreativeManagement.creatives.assignDialog.failedToLoadAssignDataRetry"
                                retry={loadLineItems}
                            />
                        )}
                    </Box>
                    {HaveCreativesFailed && (
                        <Box alignSelf="center">
                            <FlashMessage status="error">{creativesFailureMessage}</FlashMessage>
                        </Box>
                    )}
                </Stack>
            </Box>
        </DialogV2>
    );
};

export default AssignCreativeDialog;
