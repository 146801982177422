import { Client } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { ClientState } from './Client-types';

const initialState: ClientState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: ClientState, action: PayloadAction<string>): ClientState => state,
};

const slice = createBaseSlice<Client, ClientState, typeof additionalReducers>({
    name: 'client',
    entityName: 'Client',
    initialState,
    reducers: additionalReducers,
});

const clientSlice = {
    clientActions: slice.slice.actions,
    clientReducer: slice.slice.reducer,
    clientAdapter: slice.adapter,
    clientSelectors: slice.selectors,
    clientThunks: slice.thunks,
};

export default clientSlice;
export const { clientReducer, clientActions, clientAdapter, clientSelectors, clientThunks } =
    clientSlice;
