import React, { PropsWithChildren } from 'react';
import { CardContent, CardHeaderProps, Collapse, Stack } from '@mui/material';
import { TranslationPath, useCommonTranslation } from 'c-translation';
import { KeyboardArrowDown } from '@mui/icons-material';
import { cssRotation } from 'c-lib';
import { useBoolean } from 'react-hanger';
import TransparentCard from 'c-components/TransparentCard';

type Props = {
    title: TranslationPath | React.ReactNode;
    subTitle?: TranslationPath | React.ReactNode;
    titleTypographyProps?: CardHeaderProps['titleTypographyProps'];
    defaultOpen?: boolean;
};

const CollapsibleTransparentCard: React.FC<PropsWithChildren<Props>> = ({
    title,
    subTitle,
    defaultOpen = false,
    titleTypographyProps,
    children,
}) => {
    const open = useBoolean(defaultOpen);
    const t = useCommonTranslation();
    return (
        <TransparentCard
            title={
                <Stack direction="row" alignItems="center" gap={1}>
                    {typeof title === 'string' ? t(title) : title}
                    <KeyboardArrowDown css={cssRotation({ rotate: open.value })} />
                </Stack>
            }
            titleTypographyProps={titleTypographyProps}
            subTitle={typeof subTitle === 'string' ? t(subTitle) : subTitle}
            cardHeaderProps={{ onClick: open.toggle, sx: { cursor: 'pointer' } }}
        >
            <Collapse in={open.value}>
                <CardContent>{children}</CardContent>
            </Collapse>
        </TransparentCard>
    );
};

export default CollapsibleTransparentCard;
