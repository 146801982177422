import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AllEntities, BaseEntity } from '@uniled/api-sdk';
import { TranslationPath as CommonTranslationPath } from 'c-translation';
import { NetworkRequestState } from '@uniled/data-layer';
import { AnyObjectSchema } from 'yup';
import FormWrapper from 'c-components/Forms/FormWrapper';
import SubmitButton from 'c-components/Forms/SubmitButton';
import FlashMessage from 'c-components/FlashMessage';
import DialogV2 from 'c-components/DialogV2';
import { generatePath, useHistory } from 'react-router-dom';
import { UseFormProps } from 'react-hook-form';
import { Box, ButtonProps, DialogActions } from '@mui/material';

export type EntityDialogProps<Entity extends BaseEntity> = {
    entityName: keyof AllEntities;
    title: CommonTranslationPath | React.ReactNode;
    description?: CommonTranslationPath | React.ReactNode;
    onClose?: () => void;
    show: boolean;
    form: JSX.Element;
    onSuccess?: (id: number) => void;
    onError?: (error: string) => void;
    validationSchema?: AnyObjectSchema;
    submitLabel?: CommonTranslationPath;
    submitButtonColor?: ButtonProps['color'];
    transformData?: (data: Partial<Entity>) => Partial<Entity>;

    // really important this default data is memoized, because the form will refresh if a new pointer is passed to it.
    defaultData?: Partial<Entity>;
    redirectPath?: string;
    formOptions?: UseFormProps<any>;
    submitButtonId?: string;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | string;
};

type EntityDataTypes<Entity extends BaseEntity> = {
    id: number;
    state: NetworkRequestState;
    error?: string;
    postEntity: (entity: Partial<Entity>) => void;
    resetState: () => void;
};

const defaultTransformData = entity => entity;

const actionsSx = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pt: 4,
    pb: 2,
    px: 5,
};

const EntityDialog = <Entity extends BaseEntity>({
    title,
    description,
    onClose,
    show,
    form,
    onSuccess,
    onError,
    validationSchema,
    submitLabel,
    submitButtonColor,
    defaultData,
    redirectPath,
    formOptions,
    submitButtonId = 'create-entity-submit',
    id,
    state,
    error,
    postEntity,
    resetState,
    transformData = defaultTransformData,
    maxWidth = 'sm',
}: Omit<EntityDialogProps<Entity>, 'entityName'> & EntityDataTypes<Entity>) => {
    const $history = useHistory();
    const closeFunc = useCallback(
        e => {
            e?.stopPropagation?.();
            e?.preventDefault?.();
            resetState();
            onClose();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onClose],
    );

    const successFunc = useCallback(
        (id: number) => {
            resetState();
            onSuccess?.(id);
            if (redirectPath) {
                $history.push(generatePath(redirectPath, { id }));
            }
        },
        [onSuccess, redirectPath, $history, resetState],
    );
    const onSubmit = useCallback(
        (data: Partial<Entity>) => {
            postEntity(transformData({ ...defaultData, ...data }));
        },
        [defaultData, postEntity, transformData],
    );

    useEffect(() => {
        if (state === NetworkRequestState.Success) {
            closeFunc(null);
            successFunc(id);
            resetState();
        } else if (state === NetworkRequestState.Error) {
            onError?.(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);
    const content = useMemo(
        () => (
            <>
                {form}
                {error != null && (
                    <Box mt={2}>
                        <FlashMessage status="error">{error}</FlashMessage>
                    </Box>
                )}
            </>
        ),
        [form, error],
    );

    const [formId] = useState(Math.random().toString());

    const submitDisabled = useMemo(() => state === NetworkRequestState.InProgress, [state]);
    const submitButton = useMemo(
        () => (
            <SubmitButton
                label={submitLabel}
                disabled={submitDisabled}
                id={submitButtonId}
                form={formId}
                className={submitDisabled ? 'disabled' : ''}
                color={submitButtonColor}
            />
        ),
        [submitLabel, formId, submitButtonId, submitDisabled, submitButtonColor],
    );

    return (
        <DialogV2
            titleDivider
            onClose={closeFunc}
            open={show}
            title={title}
            description={description}
            maxWidth={maxWidth as 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false}
        >
            <FormWrapper
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                formId={formId}
                formOptions={formOptions}
            >
                {content}
                <DialogActions sx={actionsSx}>{submitButton}</DialogActions>
            </FormWrapper>
        </DialogV2>
    );
};

export default EntityDialog;
