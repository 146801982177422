import React, { useMemo } from 'react';
import { TableBody as MuiTableBody, TableBodyProps } from '@mui/material';
import { useTheme } from '@mui/styles';
import { css } from '@emotion/react';

const TableBody: React.FC<TableBodyProps> = props => {
    const theme = useTheme();

    const tableBodyCss = useMemo(
        () =>
            css(`
                            background: ${theme?.palette?.background?.paper ?? 'white'};
                            td, tr {border-bottom-width: 2px;}
                            // messes with MUI too much
                            // tr:first-child td:first-child { border-top-left-radius: 10px; }
                            // tr:first-child td:last-child { border-top-right-radius: 10px; }
                            // tr:last-child td:first-child { border-bottom-left-radius: 10px; }
                            // tr:last-child td:last-child { border-bottom-right-radius: 10px; }
                            tr:last-child td { border-bottom: none; }
            `),
        [theme],
    );

    return <MuiTableBody css={tableBodyCss} {...props} />;
};

export default TableBody;
