import { Displays_Owner } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { Displays_OwnerState } from './Displays_Owner-types';

const initialState: Displays_OwnerState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: Displays_OwnerState, action: PayloadAction<string>): Displays_OwnerState => state,
};

const slice = createBaseSlice<Displays_Owner, Displays_OwnerState, typeof additionalReducers>({
    name: 'displays_Owner',
    entityName: 'Displays_Owner',
    initialState,
    reducers: additionalReducers,
});

const displays_OwnerSlice = {
    displays_OwnerActions: slice.slice.actions,
    displays_OwnerReducer: slice.slice.reducer,
    displays_OwnerAdapter: slice.adapter,
    displays_OwnerSelectors: slice.selectors,
    displays_OwnerThunks: slice.thunks,
};

export default displays_OwnerSlice;
export const {
    displays_OwnerReducer,
    displays_OwnerActions,
    displays_OwnerAdapter,
    displays_OwnerSelectors,
    displays_OwnerThunks,
} = displays_OwnerSlice;
