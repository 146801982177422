import React, { useCallback, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import {
    Autocomplete,
    Button,
    CheckboxGroup,
    ControlledFormInput,
    EntityAutocomplete,
    FormWrapper,
    RadioGroupField,
    SelectField,
    Slider,
    TextField,
    ToggleButtonGroup,
} from 'c-components';
import { UseFormProps } from 'react-hook-form';
import {
    FormatAlignCenter,
    FormatAlignLeft,
    FormatAlignRight,
    FormatColorFill,
    VolumeUp,
} from '@mui/icons-material';

const Section = ({ items }: { items: React.ReactNode[] }) => (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <Grid container spacing={2}>
            {items.map((i, ii) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item key={ii} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {i}
                </Grid>
            ))}
        </Grid>
    </Grid>
);

const Forms = () => {
    const onSubmit = useCallback(data => {
        // don't care
    }, []);
    const formOptions = useMemo<UseFormProps<any>>(
        () => ({
            defaultValues: {
                sliderSmall: 25,
                sliderMedium: 25,
                sliderSmallSec: 50,
                sliderMediumSec: 50,
                sliderMediumIcon: 75,
                sliderMediumIconSec: 75,
                sliderMediumMarks: 4,
                sliderMediumRange: [20, 50],
            },
        }),
        [],
    );

    return (
        <Box>
            <FormWrapper onSubmit={onSubmit} formOptions={formOptions}>
                <Grid container spacing={4}>
                    <Section
                        items={[
                            <Button variant="contained">Button</Button>,
                            <Button variant="outlined">Button</Button>,
                            <Button variant="text">Button</Button>,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <ToggleButtonGroup
                                        color="primary"
                                        exclusive
                                        options={[
                                            { label: 'Option 1', value: 1 },
                                            { label: 'Option 2', value: 2 },
                                            { label: 'Option 3', value: 3 },
                                        ]}
                                        {...field}
                                    />
                                )}
                                name="toggle"
                            />,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <ToggleButtonGroup
                                        color="secondary"
                                        exclusive
                                        options={[
                                            { label: <FormatAlignLeft />, value: 1 },
                                            { label: <FormatAlignCenter />, value: 2 },
                                            { label: <FormatAlignRight />, value: 3 },
                                        ]}
                                        {...field}
                                    />
                                )}
                                name="toggleSec"
                            />,
                        ]}
                    />
                    <Section
                        items={[
                            <ControlledFormInput
                                render={({ field }) => (
                                    <TextField label="TextField" variant="filled" {...field} />
                                )}
                                name="textFilled"
                            />,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <TextField label="TextField" variant="outlined" {...field} />
                                )}
                                name="textOutlined"
                            />,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <TextField label="TextField" variant="standard" {...field} />
                                )}
                                name="textStandard"
                            />,
                        ]}
                    />
                    <Section
                        items={[
                            <ControlledFormInput
                                render={({ field }) => (
                                    <CheckboxGroup
                                        label="Checkbox Group"
                                        multiple
                                        options={[
                                            { label: 'Option 1', value: 1 },
                                            { label: 'Option 2', value: 2 },
                                            { label: 'Option 3', value: 3 },
                                        ]}
                                        {...field}
                                    />
                                )}
                                name="checkboxGroup"
                            />,
                        ]}
                    />
                    <Section
                        items={[
                            <ControlledFormInput
                                render={({ field }) => (
                                    <RadioGroupField
                                        label="Radio Group"
                                        options={[
                                            { label: 'Option 1', value: 1 },
                                            { label: 'Option 2', value: 2 },
                                            { label: 'Option 3', value: 3 },
                                        ]}
                                        {...field}
                                    />
                                )}
                                name="radioGroup"
                            />,
                        ]}
                    />
                    <Section
                        items={[
                            <ControlledFormInput
                                render={({ field }) => (
                                    <SelectField
                                        label="Single Select"
                                        options={[
                                            { label: 'Option 1', value: '1' },
                                            { label: 'Option 2', value: '2' },
                                            { label: 'Option 3', value: '3' },
                                        ]}
                                        {...field}
                                    />
                                )}
                                name="selectSingle"
                            />,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <SelectField
                                        label="Multi Select"
                                        multiple
                                        options={[
                                            { label: 'Option 1', value: '1' },
                                            { label: 'Option 2', value: '2' },
                                            { label: 'Option 3', value: '3' },
                                        ]}
                                        {...field}
                                    />
                                )}
                                name="selectMulti"
                            />,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <Autocomplete
                                        textFieldProps={{
                                            label: 'Autocomplete Select',
                                        }}
                                        multiple
                                        options={[
                                            { label: 'Option 1', value: '1' },
                                            { label: 'Option 2', value: '2' },
                                            { label: 'Option 3', value: '3' },
                                        ]}
                                        {...field}
                                    />
                                )}
                                name="autocompleteMulti"
                            />,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <EntityAutocomplete
                                        entityName="User"
                                        textFieldProps={{
                                            label: 'User Search',
                                        }}
                                        searchColumns="name"
                                        labelColumn="name"
                                        {...field}
                                    />
                                )}
                                name="entityDropdownSingle"
                            />,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <EntityAutocomplete
                                        entityName="User"
                                        textFieldProps={{
                                            label: 'User Search Multi',
                                        }}
                                        searchColumns="name"
                                        labelColumn="name"
                                        multiple
                                        {...field}
                                    />
                                )}
                                name="entityDropdownMulti"
                            />,
                        ]}
                    />
                    <Section
                        items={[
                            <ControlledFormInput
                                render={({ field }) => (
                                    <Slider label="Small Slider" size="small" {...field} />
                                )}
                                name="sliderSmall"
                            />,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <Slider label="Normal Slider" size="medium" {...field} />
                                )}
                                name="sliderMedium"
                            />,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <Slider
                                        label="Small Slider"
                                        size="small"
                                        color="secondary"
                                        {...field}
                                    />
                                )}
                                name="sliderSmallSec"
                            />,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <Slider
                                        label="Normal Slider"
                                        size="medium"
                                        color="secondary"
                                        {...field}
                                    />
                                )}
                                name="sliderMediumSec"
                            />,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <Slider label="Icon Slider" icon={<VolumeUp />} {...field} />
                                )}
                                name="sliderMediumIcon"
                            />,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <Slider
                                        label="Icon Slider"
                                        color="secondary"
                                        icon={<VolumeUp />}
                                        {...field}
                                    />
                                )}
                                name="sliderMediumIconSec"
                            />,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <Slider
                                        label="Marks Slider"
                                        max={10}
                                        marks={[
                                            {
                                                value: 0,
                                                label: '0',
                                            },
                                            {
                                                value: 2,
                                                label: '2',
                                            },
                                            {
                                                value: 6,
                                                label: '6',
                                            },
                                            {
                                                value: 10,
                                                label: '10',
                                            },
                                        ]}
                                        {...field}
                                    />
                                )}
                                name="sliderMediumMarks"
                            />,
                            <ControlledFormInput
                                render={({ field }) => (
                                    <Slider label="Range Slider" disableSwap {...field} />
                                )}
                                name="sliderMediumRange"
                            />,
                        ]}
                    />
                </Grid>
            </FormWrapper>
        </Box>
    );
};

export default Forms;
