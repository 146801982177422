import React, { useCallback, useEffect, useMemo } from 'react';
import { PermissionName, ScheduleGet } from '@uniled/api-sdk';
import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { deleteIcon, editIcon } from 'c-main/icons';
import { Chip } from 'c-components';
import {
    AddToQueue,
    ContentCopy,
    Tv,
    RemoveFromQueue,
    ExpandMore,
    ExpandLess,
} from '@mui/icons-material';
import { scheduleName } from 'c-main/Components/Schedule/lib';
import useCampaignSchedule from 'c-main/Components/Schedule/useCampaignSchedule';
import SchedulableAssociateButtons from 'c-main/Components/Schedule/Components/Schedulables/SchedulableAssociateButtons';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import { UseBoolean, useBoolean } from 'react-hanger';
import ScheduleRuleSummary from './ScheduleRuleSummary';

type Props = {
    campaignId: number;
    schedule: ScheduleGet;
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
    onScreensClicked: () => void;
    onDetachClicked: () => void;
    setId: (id: number) => void;
    collapseAll?: UseBoolean;
};

const updatePerms = [
    PermissionName.UniliveapiSchedulesUpdate,
    PermissionName.UniliveapiSchedulesCreate,
    PermissionName.UniliveapiSchedulesDestroy,
    PermissionName.UniliveapiRulesCreate,
    PermissionName.UniliveapiRulesUpdate,
    PermissionName.UniliveapiRulesDestroy,
];

const deletePerms = [
    PermissionName.UniliveapiSchedulesDestroy,
    PermissionName.UniliveapiRulesDestroy,
];

const attachPerms = [
    PermissionName.UniliveapiSchedulesCreate,
    PermissionName.UniliveapiSchedulesRead,
    PermissionName.UniliveapiSchedulesUpdate,
    PermissionName.UniliveapiSchedulesDestroy,
];

const ScheduleListItem: React.FC<Props> = ({
    campaignId,
    schedule,
    onEdit,
    onDelete,
    onScreensClicked,
    onDetachClicked,
    setId,
    collapseAll,
}) => {
    // const timePairs = useMemo(() => ruleHoursToTimePairs(rule.hours_of_day), [rule.hours_of_day]);
    const { expandedScheduleId, setExpandedScheduleId } = useNewCampaignSchedule(campaignId);
    const collapsed = useBoolean(false);
    const focusRef = React.useRef<HTMLDivElement>(null);
    const expanded = useMemo(
        () => expandedScheduleId === schedule.id,
        [expandedScheduleId, schedule.id],
    );

    useEffect(() => {
        if (expanded) {
            collapsed.setTrue();
            focusRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [collapsed, expanded, expandedScheduleId, schedule.id]);

    useEffect(() => {
        if (collapseAll.value) {
            collapsed.setFalse();
            collapseAll.setFalse();
        }
    }, [collapseAll, collapsed]);

    const rules = useMemo(
        () => (
            <Stack>
                {schedule?.rules?.map(rule => (
                    <Box key={rule.id}>
                        <ScheduleRuleSummary rule={rule} />
                    </Box>
                ))}
            </Stack>
        ),
        [schedule.rules],
    );
    const { selected, selectedScreens } = useNewCampaignSchedule(campaignId);
    const allSelected = useMemo(
        () => selected.concat(selectedScreens),
        [selected, selectedScreens],
    );
    const onEditClicked = useCallback(() => {
        onEdit(schedule.id);
    }, [onEdit, schedule.id]);
    const onDeleteClicked = useCallback(() => {
        onDelete(schedule.id);
    }, [onDelete, schedule.id]);

    const t = useCommonTranslation();
    const selectedSchedulables = useCampaignSchedule(campaignId).selectedSchedulables;

    const header = useMemo(
        () => (
            <Typography variant="subtitle2" color="primary">
                {scheduleName(schedule, t)}
            </Typography>
        ),
        [schedule, t],
    );
    const attachClick = useCallback(() => {
        setId(schedule.id);
        onScreensClicked();
    }, [onScreensClicked, schedule.id, setId]);

    const detachClick = useCallback(() => {
        setId(schedule.id);
        onDetachClicked();
    }, [onDetachClicked, schedule.id, setId]);

    const actions = useMemo(
        () => (
            <Box>
                <IfHasAllPermissions permissions={attachPerms}>
                    <IconButton
                        color="primary"
                        disabled={allSelected.length == 0}
                        onClick={attachClick}
                        size="small"
                    >
                        <AddToQueue fontSize="inherit" />
                    </IconButton>
                </IfHasAllPermissions>
                <IfHasAllPermissions permissions={attachPerms}>
                    <IconButton
                        color="primary"
                        disabled={allSelected.length == 0}
                        onClick={detachClick}
                        size="small"
                    >
                        <RemoveFromQueue fontSize="inherit" />
                    </IconButton>
                </IfHasAllPermissions>
                <IfHasAllPermissions permissions={updatePerms}>
                    <IconButton onClick={onEditClicked} disabled={schedule.is_default} size="small">
                        {editIcon}
                    </IconButton>
                </IfHasAllPermissions>
                <IfHasAllPermissions permissions={deletePerms}>
                    <IconButton
                        color="error"
                        onClick={onDeleteClicked}
                        size="small"
                        disabled={schedule.is_default}
                    >
                        {deleteIcon}
                    </IconButton>
                </IfHasAllPermissions>
                <IconButton onClick={collapsed.toggle} size="small">
                    {collapsed.value ? (
                        <ExpandLess
                            fontSize="inherit"
                            onClick={() => setExpandedScheduleId(null)}
                        />
                    ) : (
                        <ExpandMore fontSize="inherit" />
                    )}
                </IconButton>
            </Box>
        ),
        [
            allSelected.length,
            attachClick,
            collapsed.toggle,
            collapsed.value,
            detachClick,
            onDeleteClicked,
            onEditClicked,
            schedule,
            setExpandedScheduleId,
        ],
    );
    const screenCount = useMemo(
        () =>
            schedule?.schedulables?.length > 0 && (
                <>
                    <Chip
                        variant="outlined"
                        size="small"
                        icon={<Tv fontSize="inherit" sx={{ pl: 0.5 }} />}
                        label={schedule.schedulables.length}
                    />
                </>
            ),
        [schedule?.schedulables?.length],
    );

    return (
        <Box ref={focusRef}>
            <Stack gap={1} alignItems="center" direction="row" justifyContent="space-between">
                {header}
                {selectedSchedulables.length > 0 ? (
                    <SchedulableAssociateButtons campaignId={campaignId} schedule={schedule} />
                ) : (
                    actions
                )}
            </Stack>
            <Collapse in={collapsed.value}>
                {rules}
                {screenCount}
            </Collapse>
        </Box>
    );
};

export default ScheduleListItem;
