import React, { useMemo } from 'react';
import { CampaignDataNotice, Campaign } from '@uniled/api-sdk';
import { RenderColumnDataProps } from 'c-pagination';
import { AutoGrid } from 'c-components';
import ChildNoticesButton from './ChildNoticesButton';
import CampaignLevelNotices from './CampaignLevelNotices';
import { calculateCounts } from './lib';

type Props = RenderColumnDataProps<Campaign>;

type ByNoticeCount = Record<keyof CampaignDataNotice, number>;

const CampaignNoticesCell: React.FC<Props> = props => {
    const { entity } = props;
    const countByNoticeKey = useMemo<ByNoticeCount>(
        () => ({
            inspecting_data: calculateCounts(entity?.notices?.media_owners ?? {}, 'inspecting_data')
                .count,
            awaiting_data: calculateCounts(entity?.notices?.media_owners ?? {}, 'awaiting_data')
                .count,
            awaiting_booking_plan: calculateCounts(
                entity?.notices?.media_owners ?? {},
                'awaiting_booking_plan',
            ).count,
            pca_report_sent: calculateCounts(entity?.notices?.media_owners ?? {}, 'pca_report_sent')
                .count,
            inflight_report_sent: calculateCounts(
                entity?.notices?.media_owners ?? {},
                'inflight_report_sent',
            ).count,
        }),
        [entity.notices],
    );

    const anyIssues = useMemo(
        () => countByNoticeKey.awaiting_data > 0 || countByNoticeKey.inspecting_data > 0,
        [countByNoticeKey],
    );
    return (
        <>
            <CampaignLevelNotices {...props} />
            {anyIssues && (
                <AutoGrid xs={12} spacing={0}>
                    {countByNoticeKey.awaiting_data > 0 && (
                        <ChildNoticesButton
                            noticeKey="awaiting_data"
                            btnLabel="Modules.Main.Campaigns.DataStatus.table.awaitingDataCol"
                            entity={entity}
                        />
                    )}
                    {countByNoticeKey.inspecting_data > 0 && (
                        <ChildNoticesButton
                            noticeKey="inspecting_data"
                            btnLabel="Modules.Main.Campaigns.DataStatus.table.inspectingDataCol"
                            entity={entity}
                        />
                    )}
                </AutoGrid>
            )}
        </>
    );
};

export default CampaignNoticesCell;
