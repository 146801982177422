import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { ascend, descend, prop, sort } from 'ramda';
import { Box, TableSortLabel } from '@mui/material';
import { ElasticSearchGroupByFieldResponse, PCAReportEnergyStats } from '@uniled/api-sdk';
import {
    ReportProps,
    ReportType,
    ReportWrapperProps,
    TableReportHeader,
    TableReportRow,
} from 'c-reports/Types';
import { ReportWrapper } from 'c-reports/Components';
import { useCommonTranslation } from 'c-translation';
import { NumberFormat } from 'c-components';
import HoursMins from 'c-main/Components/Campaign/CampaignReports/HoursMins';
import { Direction } from 'c-types';

type Props = {
    campaignId: number;
    data: ElasticSearchGroupByFieldResponse['data'];
    reportWrapperProps: ReportWrapperProps<any>;
    fieldLabel: string;
};

const playsCol: keyof PCAReportEnergyStats = 'sum_of_plays';
const timeCol: keyof PCAReportEnergyStats = 'sum_of_time';
const energyCol: keyof PCAReportEnergyStats = 'kilowatts';
const co2Col: keyof PCAReportEnergyStats = 'kgco2';

const OverallLeaderboardCharts: React.FC<Props> = ({
    data,
    fieldLabel,
    reportWrapperProps,
    campaignId,
}) => {
    const [orderByCol, setOrderByCol] = useState<keyof PCAReportEnergyStats>(playsCol);
    const [orderByDirection, setOrderByDirection] = useState(Direction.DESC);

    const orderByClicked = useCallback(
        (field: keyof PCAReportEnergyStats) => {
            let newDirection;

            let flip = false;
            // if same field, flip the ordering direction
            if (orderByCol === field) {
                flip = true;
            }

            if (flip) {
                newDirection = orderByDirection === Direction.ASC ? Direction.DESC : Direction.ASC;
            } else {
                newDirection = Direction.ASC;
            }

            // if switching fields order descending
            if (orderByCol !== field) {
                newDirection = Direction.DESC;
            }

            setOrderByCol(field);
            setOrderByDirection(newDirection);
        },
        [orderByCol, orderByDirection],
    );

    const t = useCommonTranslation();
    const headerLabels = useMemo(() => {
        return [
            fieldLabel,
            t('Modules.Main.Campaigns.Overview.Reports.energyUsage.playsHeader'),
            t('Modules.Main.Campaigns.Overview.Reports.energyUsage.playTimeHeader'),
            t('Modules.Main.Campaigns.Overview.Reports.energyUsage.kwhHeader'),
            t('Modules.Main.Campaigns.Overview.Reports.energyUsage.kgCo2eHeader'),
        ];
    }, [fieldLabel, t]);
    const headerFields = useMemo(() => {
        return [fieldLabel, playsCol, timeCol, energyCol, co2Col];
    }, [fieldLabel]);

    const headers = useMemo<TableReportHeader[]>(
        () => [
            { content: '#', width: 0 },
            ...headerLabels.map((field, i) => ({
                content: (
                    <TableHeader
                        key={field}
                        onClick={orderByClicked}
                        active={orderByCol === headerFields[i]}
                        field={headerFields[i]}
                        direction={orderByDirection}
                    >
                        {field}
                    </TableHeader>
                ),
            })),
        ],
        [headerFields, headerLabels, orderByClicked, orderByCol, orderByDirection],
    );

    const dir = useMemo(
        () => (orderByDirection === Direction.DESC ? descend : ascend),
        [orderByDirection],
    );
    const tableData = useMemo(
        () =>
            Object.entries(data).map(([fieldName, value]) => ({
                ...value,
                [fieldLabel]: fieldName,
            })),
        [fieldLabel, data],
    );

    const byDirection = useMemo(() => dir(prop(orderByCol)), [dir, orderByCol]);
    const filteredSortedData = useMemo(
        () => sort(byDirection as any, tableData),
        [byDirection, tableData],
    );
    // const filteredSortedData = useMemo(() => tableData, [tableData, fieldLabel]);

    const rows = useMemo<TableReportRow[]>(
        () =>
            filteredSortedData.map((dataRow, dataIndex) => ({
                content: [
                    { content: dataIndex + 1 },
                    { content: dataRow[fieldLabel] },
                    { content: <NumberFormat value={dataRow.sum_of_plays} /> },
                    {
                        content: (
                            <>
                                <HoursMins
                                    seconds={dataRow.sum_of_time}
                                    variant="body2"
                                    alwaysShowMinutes
                                />
                            </>
                        ),
                    },
                    { content: <NumberFormat value={dataRow.kilowatts} /> },
                    { content: <NumberFormat value={dataRow.kgco2} /> },
                ],
            })),
        [filteredSortedData, fieldLabel],
    );

    const reportProps = useMemo<ReportProps<unknown>[]>(
        () => [
            {
                type: ReportType.Table,
                namePrefix: `${campaignId}_energy_usage_leaderboard`,
                data: filteredSortedData,
                renderTooltipLabel: null,
                rows,
                headers,
                disableVirtualization: true,
            },
            // } as TableOrChartProps<any>,
        ],
        [campaignId, filteredSortedData, headers, rows],
    );

    const beforeChartComponent = useMemo(
        () => (
            <>
                {reportWrapperProps?.beforeChartComponent && (
                    <Box>{reportWrapperProps.beforeChartComponent}</Box>
                )}
            </>
        ),
        [reportWrapperProps.beforeChartComponent],
    );

    return (
        <>
            <ReportWrapper
                reportProps={reportProps}
                {...reportWrapperProps}
                beforeChartComponent={beforeChartComponent}
                reportWrapperProps={{
                    ...reportWrapperProps.reportWrapperProps,
                    maxHeight: '80vh',
                    overflow: 'auto',
                }}
            />
        </>
    );
};

type HeaderProps = {
    //
    field: string;
    active: boolean;
    direction: Direction;
    onClick: (field: keyof PCAReportEnergyStats) => void;
};
const TableHeader: React.FC<PropsWithChildren<HeaderProps>> = ({
    field,
    active,
    direction,
    onClick,
    children,
}) => {
    const orderByClicked = useCallback(() => onClick(field as any), [onClick, field]);
    return (
        <TableSortLabel direction={direction} active={active} onClick={orderByClicked}>
            {children}
        </TableSortLabel>
    );
};

export default OverallLeaderboardCharts;
