import React, { useCallback, useMemo, useRef, useState } from 'react';
import { AutoGrid, Button, DialogV2 } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { useBoolean } from 'react-hanger';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';
import { Box, lighten, List, Typography } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useTheme } from '@mui/styles';
import ChannelFrameImportListItem from './ChannelFrameImportListItem';

type Props = {
    onClose: () => void;
};
const ChannelFrameImportButton: React.FC<Props> = ({ onClose }) => {
    const dialogProps = useBoolean(false);
    const t = useCommonTranslation();

    const closeDialog = useCallback(() => {
        dialogProps.setFalse();
        onClose();
    }, [onClose, dialogProps]);
    return (
        <>
            <Button onClick={dialogProps.toggle}>
                {t('Modules.PopDisplays.ChannelFrame.import.btnLabel')}
            </Button>
            {dialogProps.value && (
                <DndProvider backend={HTML5Backend}>
                    <UploadDialog open={dialogProps.value} onClose={closeDialog} />
                </DndProvider>
            )}
        </>
    );
};

const UploadDialog: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
    const theme = useTheme();
    const dropzoneBg = useMemo(() => lighten(theme.palette.secondary.main, 0.85), [theme]);

    const [uploadFiles, setUploadFiles] = useState<Record<string, File>>({});

    const addFiles = useCallback((files: File[]) => {
        setUploadFiles(val => {
            const newFiles: Record<string, File> = {};

            files.forEach(file => {
                let uniqueId = null;
                while (uniqueId == null) {
                    const newId = String(Math.random());
                    if (val[newId] == null && newFiles[newId] == null) uniqueId = newId;
                }

                newFiles[uniqueId] = file;
            });

            return { ...val, ...newFiles };
        });
    }, []);

    const onDrop = useCallback(
        (data: { files: File[] }) => {
            addFiles(data.files);
        },
        [addFiles],
    );
    const onChange = useCallback(
        e => {
            const files: FileList = e.target.files;

            if (files.length > 0 && files.item(0)) {
                addFiles(Array.from(files));
                // reset selected files so the same file can be selected again and trigger change event
                e.target.value = '';
            }
        },
        [addFiles],
    );

    const [{ isOver, canDrop }, drop] = useDrop(
        {
            accept: NativeTypes.FILE,
            drop: onDrop,
            collect: monitor => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
        },
        [onDrop],
    );

    const fileInputRef = useRef<HTMLInputElement>();

    const onBrowseClick = useCallback(() => {
        fileInputRef?.current?.click();
    }, []);

    const filesUploading = Object.keys(uploadFiles ?? {}).length > 0;

    const t = useCommonTranslation();

    return (
        <DialogV2
            onClose={onClose}
            open={open}
            title="Modules.PopDisplays.ChannelFrame.import.title"
            description="Modules.PopDisplays.ChannelFrame.import.description"
        >
            <AutoGrid rowSpacing={2} xs={12}>
                <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                    <Box display="none">
                        <input
                            accept="*"
                            type="file"
                            id="file-upload"
                            multiple
                            onChange={onChange}
                            ref={fileInputRef}
                        />
                    </Box>
                    <Box
                        ref={drop}
                        mt={2}
                        p={4}
                        height={!filesUploading ? 350 : undefined}
                        sx={{
                            borderWidth: 2,
                            borderStyle: canDrop ? 'dashed' : 'none',
                            borderColor: isOver && canDrop ? 'success.main' : 'primary.main',
                            borderRadius: 1,
                        }}
                        bgcolor={dropzoneBg}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Box textAlign="center">
                            <Typography
                                variant="h1"
                                color={isOver && canDrop ? 'success.main' : 'primary'}
                            >
                                <CloudUploadOutlinedIcon
                                    fontSize="large"
                                    sx={{ fontSize: '2em' }}
                                />
                            </Typography>
                            <Typography
                                variant="body1"
                                color={isOver && canDrop ? 'success.main' : undefined}
                            >
                                {t(
                                    'Modules.Main.CreativeManagement.creatives.controls.upload.dropzoneText',
                                )}
                            </Typography>
                        </Box>
                    </Box>
                </label>

                {!filesUploading && (
                    <Box textAlign="center" mt={2}>
                        <Button variant="outlined" onClick={onBrowseClick}>
                            {t(
                                'Modules.Main.CreativeManagement.creatives.controls.upload.browseBtnLabel',
                            )}
                        </Button>
                    </Box>
                )}
                <Box>
                    <List disablePadding>
                        {Object.entries(uploadFiles).map(([id, file]) => (
                            <ChannelFrameImportListItem key={id} file={file} />
                        ))}
                    </List>
                </Box>
            </AutoGrid>
        </DialogV2>
    );
};

export default ChannelFrameImportButton;
