import React, { useCallback, useEffect } from 'react';
import { usePrevious } from 'react-hanger';
import { Button, DialogV2, FlashMessage } from 'c-components';
import { Box } from '@mui/material';
import { NetworkRequestState } from '@uniled/data-layer';
import { TranslationPath } from 'c-translation';

type Props = {
    open: boolean;
    onClose: () => void;
    deleteSelectedCreatives: () => void;
    deleteCreativesState: NetworkRequestState;
    deleteCreativesError?: string;
    title: TranslationPath;
    description: string | React.ReactNode;
    buttonLabel: string;
};

const DeleteCreativesDialog: React.FC<Props> = ({
    open,
    onClose,
    deleteSelectedCreatives,
    deleteCreativesState,
    deleteCreativesError,
    title,
    description,
    buttonLabel,
}) => {
    const onSave = useCallback(() => {
        deleteSelectedCreatives();
    }, [deleteSelectedCreatives]);

    const prevLoadingState = usePrevious(deleteCreativesState);
    useEffect(() => {
        if (
            deleteCreativesState === NetworkRequestState.Success &&
            prevLoadingState === NetworkRequestState.InProgress
        ) {
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteCreativesState]);

    return (
        <DialogV2
            onClose={onClose}
            open={open}
            title={title}
            description={description}
            actions={
                <>
                    <Button
                        color="error"
                        size="small"
                        disabled={deleteCreativesState === NetworkRequestState.InProgress}
                        onClick={onSave}
                    >
                        {buttonLabel}
                    </Button>
                </>
            }
        >
            <>
                <Box>
                    {deleteCreativesState === NetworkRequestState.Error && (
                        <Box mt={2}>
                            <FlashMessage status="error">
                                {String(deleteCreativesError)}
                            </FlashMessage>
                        </Box>
                    )}
                </Box>
            </>
        </DialogV2>
    );
};

export default DeleteCreativesDialog;
