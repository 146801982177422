import React, { PropsWithChildren } from 'react';
import { Box, Card, CardHeader, CardContent, Collapse } from '@mui/material';
import { Translate, TranslationPath } from 'c-translation';
import { KeyboardArrowDown } from '@mui/icons-material';
import { cssRotation } from 'c-lib';
import { useBoolean } from 'react-hanger';

type Props = {
    title: TranslationPath;
    defaultOpen?: boolean;
};

const CollapsibleCard: React.FC<PropsWithChildren<Props>> = ({
    title,
    defaultOpen = false,
    children,
}) => {
    const open = useBoolean(defaultOpen);
    return (
        <Card>
            <CardHeader
                title={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Translate path={title} />{' '}
                        <KeyboardArrowDown css={cssRotation({ rotate: open.value })} />
                    </Box>
                }
                sx={{ cursor: 'pointer' }}
                onClick={open.toggle}
            />
            <Collapse in={open.value}>
                <CardContent>{children}</CardContent>
            </Collapse>
        </Card>
    );
};

export default CollapsibleCard;
