import {
    SystemSearchRecentResourceSelectors,
    SystemSearchRecentResourceActions,
    SystemSearchRecentResourceAdapter,
    SystemSearchRecentResourceThunks as defaultThunks,
    SystemSearchRecentResourceReducer,
} from './SystemSearchRecentResource-slice';
import thunks from './SystemSearchRecentResource-thunks';

const SystemSearchRecentResourceThunks = { ...defaultThunks, ...thunks };

export { default as SystemSearchRecentResourceSlice } from './SystemSearchRecentResource-slice';
export {
    SystemSearchRecentResourceSelectors,
    SystemSearchRecentResourceActions,
    SystemSearchRecentResourceAdapter,
    SystemSearchRecentResourceThunks,
    SystemSearchRecentResourceReducer,
};
