import React from 'react';
import { PermissionByCategory } from 'c-admin/types';
import { Box, Grid, Typography } from '@mui/material';
import { Translate } from 'c-translation';
import { Role } from 'c-entity-types';
import EntityPermissionGroups from './EntityPermissionGroups';
import PermissionCheckbox from './PermissionCheckbox';

type Props = {
    role: Role;
    grouped: PermissionByCategory;
    nameInput: React.ReactNode;
    submitButton: React.ReactNode;
};

const PermissionsNamespaceGroups: React.FC<Props> = ({
    role,
    grouped,
    nameInput,
    submitButton,
}) => (
    <Box sx={{ py: 2 }}>
        <Box py={2}>
            {submitButton}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    {nameInput}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        <Translate path="Modules.Admin.RoleEdit.generalPermissionsHeader" />
                    </Typography>
                    {grouped.general.map(permission => (
                        <Box key={permission.name}>
                            <PermissionCheckbox permission={permission} label={permission.name} />
                        </Box>
                    ))}
                </Grid>
            </Grid>
        </Box>

        <Box py={2}>
            <Typography variant="h4">
                <Translate path="Modules.Admin.RoleEdit.entityPermissionsHeader" />
            </Typography>
            <EntityPermissionGroups role={role} grouped={grouped} />
        </Box>
    </Box>
);

export default React.memo(PermissionsNamespaceGroups);
