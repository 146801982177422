import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import MetaLayout from 'c-wrapper/Components/MetaLayout';
import PreAuthLayout from 'c-wrapper/Components/PreAuthLayout';
import { useCommonTranslation } from 'c-translation';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { Box, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { AutoGrid, FlashMessage, LinkButton } from 'c-components';
import { InviteRoutes, PreAuthRoutes } from 'c-routes';
import { generatePath } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CampaignInviteImg from 'c-assets/campaign-invite.png';

const InviteLandingPage = () => {
    const token = useMemo(() => new URLSearchParams(window.location.search).get('token'), []);
    const t = useCommonTranslation();

    const {
        start: LoadInviteInfo,
        isLoading: IsInfoLoading,
        hasFailed: HasInfoFailed,
        error: InfoError,
        hasSucceeded: HasInfoSucceeded,
        data: InfoData,
    } = useAPIClientRequest(apiClient.Auth.inviteDetails);

    // const campaignId = useMemo(() => {
    //     if (HasInfoSucceeded && InfoData?.data?.data?.[0]?.campaign_id != null) {
    //         return InfoData?.data?.data?.[0]?.campaign_id;
    //     }
    //
    //     return null;
    // }, [HasInfoSucceeded, InfoData]);

    const campaignName = useMemo(() => {
        if (HasInfoSucceeded && InfoData?.data?.data?.[0]?.campaign_id != null) {
            return t('Pages.AcceptInvite.inviteDescription', {
                name: `#${InfoData?.data?.data?.[0]?.campaign_id} - ${InfoData?.data?.data?.[0]?.campaign_name}`,
            });
        }

        return null;
    }, [HasInfoSucceeded, InfoData, t]);

    const loadTokenInfo = useCallback(
        async (inviteToken: string) => {
            await to(LoadInviteInfo(inviteToken));
        },
        [LoadInviteInfo],
    );

    useEffect(() => {
        loadTokenInfo(token);
    }, [token, loadTokenInfo]);

    const content = useMemo(() => {
        if (IsInfoLoading) {
            return (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            );
        }
        if (HasInfoFailed) {
            return (
                <InviteBox>
                    <FlashMessage textAlign="center" status="error">
                        {String(InfoError)}
                    </FlashMessage>
                </InviteBox>
            );
        }
        if (campaignName != null) {
            return (
                <InviteBox>
                    <Box>
                        <Typography variant="h4" align="center">
                            {campaignName}
                        </Typography>
                    </Box>
                    <AutoGrid xs="auto" gap={2} justifyContent="center">
                        <Box>
                            <Typography variant="body2">
                                {t('Pages.AcceptInvite.inviteNoAccount')}
                                <LinkButton
                                    to={generatePath(PreAuthRoutes.RegisterToken, { token })}
                                    variant="text"
                                    id="invite-register"
                                >
                                    {t('Pages.AcceptInvite.inviteRegister')}
                                </LinkButton>
                            </Typography>
                        </Box>
                        <LinkButton
                            to={generatePath(InviteRoutes.LoginToken, { token })}
                            id="invite-login"
                            variant="outlined"
                        >
                            {t('Pages.AcceptInvite.inviteLogin')}
                        </LinkButton>
                    </AutoGrid>
                </InviteBox>
            );
        }

        return <></>;
    }, [HasInfoFailed, InfoError, IsInfoLoading, campaignName, t, token]);
    return (
        <MetaLayout metaTitle="Pages.AcceptInvite.inviteLandingMetaTitle">
            <PreAuthLayout maxWidth="xl">{content}</PreAuthLayout>
        </MetaLayout>
    );
};

const InviteBox: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <Box maxWidth="sm" width="100%" mx="auto" my="auto">
            <Paper elevation={2}>
                <Box px={10} py={4}>
                    <Stack spacing={4}>
                        <Box textAlign="center">
                            <img src={CampaignInviteImg} alt="campaign invite icon" height={170} />
                        </Box>
                        {children}
                    </Stack>
                </Box>
            </Paper>
        </Box>
    );
};

export default InviteLandingPage;
