import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AutoGrid, Button, DialogV2, FlashMessage, LoadingSpinner, Alert } from 'c-components';
import { NetworkRequestState } from '@uniled/data-layer';
import { useArray, useBoolean, usePrevious } from 'react-hanger';
import { FrameValidationResponse } from '@uniled/api-sdk';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { uniq } from 'ramda';
import { Box } from '@mui/material';
import { useAPIClientRequest } from 'c-hooks';
import { useCommonTranslation } from 'c-translation';
import useSelectedLineItems from './useSelectedLineItems';
import BulkAttachSearch from './BulkAttachFrames/BulkAttachSearch';
import BulkAttachFoundFrames from './BulkAttachFrames/BulkAttachFoundFrames';
import BulkAttachInvalidFrames from './BulkAttachFrames/BulkAttachInvalidFrames';
import BulkAttachConflicts from './BulkAttachFrames/BulkAttachConflicts';

type Props = {
    onAddedLineItems: () => void;
    marketId: number;
};

const BulkAttachFrames: React.FC<Props> = ({ onAddedLineItems, marketId }) => {
    const { add } = useSelectedLineItems();

    const dialogState = useBoolean(false);
    const [inputVal, setInputVal] = useState('');
    const [framesResponse, setFramesResponse] = useState<FrameValidationResponse>(null);

    // key is the conflict frame id, value is the line item id
    const [frameConflictChoices, setFrameConflictChoices] = useState<Record<string, string>>({});
    const {
        setValue: setSelectedLineItemIds,
        value: selectedLineItemIds,
        removeIndex: removeLineItemIndex,
    } = useArray<string>([]);

    const uniqueIds = useMemo(
        () =>
            uniq(
                inputVal
                    .split('\n')
                    ?.map(id => id.trim())
                    .filter(id => id.length > 0),
            ),
        [inputVal],
    );

    const { start, requestState, reset, error, hasFailed } = useAPIClientRequest(
        apiClient.Entities.Displays_LineItem.validateFrameIds,
    );

    const {
        start: getLineItems,
        data: LineItems,
        reset: resetLineItems,

        requestState: lineItemsLoadingState,
    } = useAPIClientRequest(apiClient.Entities.Displays_LineItem.list);

    const fetchLineItemData = useCallback(
        async (ids: string[]) => {
            await getLineItems({
                filters: { 'filter.id': ids },
                includes: ['owner', 'resolutions', 'fileTypes'],
                perPage: ids.length,
                page: 1,
            });
        },
        [getLineItems],
    );

    const addLineItems = useCallback(() => {
        add([...selectedLineItemIds, ...Object.values(frameConflictChoices ?? {})]);
        dialogState.setFalse();

        setTimeout(() => {
            onAddedLineItems();
        }, 500);
    }, [add, selectedLineItemIds, dialogState, onAddedLineItems, frameConflictChoices]);

    const search = useCallback(async () => {
        const [err, response] = await to(
            start({
                frames: uniqueIds.map(id => ({ vendor_id: String(id) })),
                market_id: marketId,
            }),
        );

        if (!err && Array.isArray(response?.data?.available)) {
            setFramesResponse(response?.data);
            const newUniqueLineItemIds = uniq([
                ...response.data.available.map(available => available.line_item_id),
                ...response.data.conflicts.reduce((lineItemIds, conflict) => {
                    return [...lineItemIds, ...conflict.options.map(opt => opt.line_item_id)];
                }, [] as string[]),
            ]);

            if (newUniqueLineItemIds.length > 0) {
                // load auto selected and conflicting line item ids
                fetchLineItemData(newUniqueLineItemIds);

                setSelectedLineItemIds(
                    response.data.available.map(available => available.line_item_id),
                );
            }
        } else {
            setFramesResponse(null);
        }
    }, [uniqueIds, start, fetchLineItemData, setSelectedLineItemIds, marketId]);

    const t = useCommonTranslation();

    const prevOpen = usePrevious(dialogState.value);

    useEffect(() => {
        if (prevOpen === true && !dialogState.value) {
            setTimeout(() => {
                // reset state when the dialog is closed
                reset();
                resetLineItems();

                setFramesResponse(null);
                setInputVal('');
                setSelectedLineItemIds([]);
                setFrameConflictChoices({});
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogState.value]);

    const showConflicts = useMemo(
        () => framesResponse?.conflicts?.length > 0 && LineItems?.data?.data?.length > 0,
        [framesResponse, LineItems],
    );
    const showSearch = useMemo(
        () =>
            dialogState.value &&
            (framesResponse == null || (selectedLineItemIds.length === 0 && !showConflicts)),
        [dialogState.value, framesResponse, selectedLineItemIds.length, showConflicts],
    );
    const showFoundFrames = useMemo(
        () => framesResponse != null && !showSearch,
        [framesResponse, showSearch],
    );

    const invalidFrames = useMemo(
        () =>
            framesResponse != null
                ? [
                      ...(framesResponse?.invalid?.map(invalid => invalid.vendor_id) ?? []),
                      ...(framesResponse?.missing ?? []),
                      // ...(uniqueIds.filter(frameId => foundFrameIds.indexOf(frameId) === -1) ?? []),
                  ]
                : [],
        [framesResponse],
    );

    const onSelectConflict = useCallback((frameId: string, lineItemId: string) => {
        setFrameConflictChoices(curr => ({ ...curr, [frameId]: lineItemId }));
    }, []);

    const isLoading = useMemo(
        () =>
            lineItemsLoadingState === NetworkRequestState.InProgress ||
            requestState === NetworkRequestState.InProgress,
        [lineItemsLoadingState, requestState],
    );

    const dialogButton = useMemo(
        () => (
            <Button onClick={dialogState.setTrue} size="small" sx={{ ml: 'auto' }}>
                {t('Modules.Main.Campaigns.Create.bulkAttachButtonLabel')}
            </Button>
        ),
        [dialogState, t],
    );
    if (marketId == null) {
        return (
            <>
                <DialogV2
                    title={t('Modules.Main.Campaigns.Create.bulkAttachTitle')}
                    onClose={dialogState.setFalse}
                    open={dialogState.value}
                >
                    <Alert severity="error">
                        {t('Modules.Main.Campaigns.Create.bulkAttachMissingMarket')}
                    </Alert>
                </DialogV2>
                {dialogButton}
            </>
        );
    }
    return (
        <>
            <DialogV2
                title={t('Modules.Main.Campaigns.Create.bulkAttachTitle')}
                onClose={dialogState.setFalse}
                open={dialogState.value}
                actions={
                    <>
                        {showSearch && (
                            <Button
                                onClick={search}
                                disabled={requestState === NetworkRequestState.InProgress}
                            >
                                {t('Modules.Main.Campaigns.Create.bulkAttachFramesSubmit')}
                            </Button>
                        )}
                        {showFoundFrames && (
                            <Button onClick={addLineItems}>
                                {t('Modules.Main.Campaigns.Create.bulkAttachFramesSelect')}
                            </Button>
                        )}
                    </>
                }
            >
                <>
                    {showSearch && (
                        <>
                            <BulkAttachSearch
                                inputValue={inputVal}
                                setInputValue={setInputVal}
                                uniqueIdCount={uniqueIds.length}
                            />
                        </>
                    )}
                    <AutoGrid spacing={2} xs={12} sx={{ textAlign: 'center' }}>
                        {showConflicts && (
                            <BulkAttachConflicts
                                conflicts={framesResponse.conflicts}
                                lineItems={LineItems?.data?.data ?? []}
                                selectedConflicts={frameConflictChoices}
                                onSelectConflict={onSelectConflict}
                            />
                        )}

                        {isLoading && (
                            <Box
                                sx={{
                                    mb: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <LoadingSpinner />
                            </Box>
                        )}

                        {showFoundFrames && (
                            <BulkAttachFoundFrames
                                uniqueLineItemIds={selectedLineItemIds}
                                removeLineItemIndex={removeLineItemIndex}
                                lineItems={LineItems?.data?.data ?? []}
                            />
                        )}

                        {invalidFrames.length > 0 && (
                            <BulkAttachInvalidFrames invalidFrames={invalidFrames} />
                        )}
                    </AutoGrid>

                    {hasFailed && error != null && (
                        <Box mt={2}>
                            <FlashMessage status="error">{String(error)}</FlashMessage>
                        </Box>
                    )}
                </>
            </DialogV2>
            {dialogButton}
        </>
    );
};

export default BulkAttachFrames;
