import React, {
    CSSProperties,
    KeyboardEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import SwipeableViews from 'react-swipeable-views';
import HighlightWord from 'c-components/HighlightWord';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useNumber } from 'react-hanger';
import { ChevronLeft, ChevronRight, OpenInFull } from '@mui/icons-material';
import { useIsMobileView } from 'c-hooks';
import { formatBytes } from 'c-lib';
import { useHotkeys } from 'react-hotkeys-hook';
import { useCommonTranslation } from 'c-translation';
import FileIcon from './FileIcon';
import RawFilePreview from './RawFilePreview';
import OpenFileNewTab from './OpenFileNewTab';
import FilePreviewDialog from './FilePreviewDialog';
import { generateFileResolution } from './lib';
import OpenFileDownload from './OpenFileDownload';
import FileHighlightFilterText from './FileHighlightFilterText';
import { FileBrowserFile, ViewOptionProps } from './types';

type Props = ViewOptionProps & {
    preferredSize?: keyof FileBrowserFile['urls'];
    maxHeight?: CSSProperties['maxHeight'];
    initialSelectedFileIndex?: number;
};

const thumbnailId = (index: number) => `thumb-${index}`;

const FileBrowserCarouselView: React.FC<Props> = ({
    files,
    filters,
    preferredSize = 'large',
    maxHeight = 300,
    initialSelectedFileIndex = 0,
}) => {
    const indexState = useNumber(initialSelectedFileIndex);

    const selectedFile = useMemo(() => files[indexState.value], [files, indexState.value]);
    const [expandedFileId, setExpandedFileId] = useState<number>(null);
    const searchTerm = useMemo(() => filters?.term ?? '', [filters?.term]);

    useEffect(() => {
        if (expandedFileId != null && !files.some(f => f.id === expandedFileId)) {
            // unset currently 'expanded' file to null because its no longer in the file browser
            setExpandedFileId(null);
            indexState.setValue(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const onChangeIndex = useCallback(
        index => {
            indexState.setValue(index);
            document
                ?.querySelector(`#${thumbnailId(index)}`)
                ?.scrollIntoView({ behavior: 'smooth' });
        },
        [indexState],
    );

    const goBack = useCallback(() => {
        if (indexState.value >= 1) {
            onChangeIndex(indexState.value - 1);
        }
    }, [indexState.value, onChangeIndex]);
    const goNext = useCallback(() => {
        if (indexState.value + 1 <= files.length - 1) {
            onChangeIndex(indexState.value + 1);
        }
    }, [indexState.value, files.length, onChangeIndex]);

    const isMobile = useIsMobileView();

    const deselectFile = useCallback(() => {
        setExpandedFileId(null);
    }, [setExpandedFileId]);

    const theExpandedFile = useMemo(
        () => files.find(f => f.id === expandedFileId),
        [files, expandedFileId],
    );

    const expandLabel = useCommonTranslation('FileBrowser.filePreview.expand');

    const onRightKey = useCallback(
        (e: KeyboardEvent) => {
            e.preventDefault();
            goNext();
        },
        [goNext],
    );
    const onLeftKey = useCallback(
        (e: KeyboardEvent) => {
            e.preventDefault();
            goBack();
        },
        [goBack],
    );
    useHotkeys('right', onRightKey as any, [onRightKey]);
    useHotkeys('left', onLeftKey as any, [onLeftKey]);

    return (
        <Box px={2} py={2} sx={{ overflow: 'hidden' }}>
            <Box sx={{ display: 'flex', overflow: 'auto' }}>
                {files.map((file, index) => (
                    <Box
                        key={file.id}
                        onClick={() => onChangeIndex(index)}
                        id={thumbnailId(index)}
                        sx={{
                            transform: index === indexState.value ? 'scale(1.5)' : undefined,
                            mx: index === indexState.value ? 2 : 1,
                            px: index === indexState.value ? 1 : 0,
                            cursor: 'pointer',
                            transition: 'all 300ms',
                            display: 'flex',
                            alignItems: 'center',
                            height: 60,
                            p: 1,
                            bgcolor: index === indexState.value ? 'grey.200' : undefined,
                            '&:hover': {
                                bgcolor: 'grey.100',
                            },
                        }}
                    >
                        <Box>
                            <Tooltip title={file.name}>
                                <Box>
                                    <RawFilePreview
                                        file={file}
                                        maxHeight={40}
                                        maxWidth={40}
                                        preferredSize="small"
                                        showVideoPreview={false}
                                        fallback={
                                            <FileIcon
                                                file={file}
                                                sx={{ width: 40, height: '100%' }}
                                            />
                                        }
                                    />
                                </Box>
                            </Tooltip>
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box textAlign="center" py={2}>
                <Typography variant="body1">
                    <HighlightWord text={selectedFile?.name} searchTerm={searchTerm} />

                    <OpenFileDownload file={selectedFile} />
                    <OpenFileNewTab file={selectedFile} />
                    <Tooltip title={expandLabel}>
                        <IconButton onClick={() => setExpandedFileId(selectedFile.id)}>
                            <OpenInFull />
                        </IconButton>
                    </Tooltip>
                </Typography>
                {selectedFile && selectedFile.tags && (
                    <Typography variant="body1" color="textSecondary">
                        <FileHighlightFilterText
                            filters={filters.resolutions}
                            text={generateFileResolution(selectedFile)}
                        />
                        {' ('}
                        <FileHighlightFilterText
                            filters={filters.aspectRatios}
                            text={selectedFile.tags?.aspectRatio ?? ''}
                        />
                        {') '}
                        {` (${formatBytes(selectedFile?.size)})`}
                        {/* {` | ${formatDateString({ date: selectedFile.createdAt })} `} */}
                    </Typography>
                )}
            </Box>
            <Box sx={{ position: 'relative', cursor: 'move', px: 5 }}>
                <Box sx={{ position: 'absolute', top: '50%', left: 0, zIndex: 1 }}>
                    <IconButton size="large" onClick={goBack}>
                        <ChevronLeft />
                    </IconButton>
                </Box>
                <SwipeableViews
                    style={{ padding: `0 ${isMobile ? 0 : 20}%` }}
                    enableMouseEvents
                    onChangeIndex={onChangeIndex}
                    resistance
                    index={indexState.value}
                >
                    {files.map((file, fi) => (
                        <Box
                            key={file.id}
                            sx={{
                                transform: `scale(${selectedFile?.id === file.id ? '1' : '0.7'})`,
                                transition: 'all 500ms',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                            onClick={() => onChangeIndex(fi)}
                        >
                            <Box display="flex" overflow="hidden">
                                <RawFilePreview
                                    file={file}
                                    maxHeight={maxHeight}
                                    preferredSize={preferredSize}
                                    showVideoPreview
                                    showPdfEmbed
                                    fallback={
                                        <FileIcon
                                            key={file.id}
                                            file={file}
                                            sx={{ width: '100%', minWidth: 300, minHeight: 300 }}
                                        />
                                    }
                                />
                            </Box>
                        </Box>
                    ))}
                </SwipeableViews>

                <Box sx={{ position: 'absolute', top: '50%', right: 0, zIndex: 1 }}>
                    <IconButton size="large" onClick={goNext}>
                        <ChevronRight />
                    </IconButton>
                </Box>
            </Box>

            <FilePreviewDialog
                file={theExpandedFile}
                open={theExpandedFile != null}
                close={deselectFile}
            />
        </Box>
    );
};

export default FileBrowserCarouselView;
