import { Config } from 'src/config';
import { mergeSystemSearchConfig as mergeFunction } from 'c-system-search';
import * as pages from './Pages';
import * as slices from './Slices';

export const config: Config = {
    mergeFunction,
    reducers: {
        jobStatus: slices.jobStatusReducer,
        logFileImport: slices.logFileImportReducer,
        logFileResource: slices.logFileResourceReducer,
        logImportSource: slices.logImportSourceReducer,
        popDisplay: slices.displayReducer,
        popChannelFrame: slices.channelFrameReducer,
        schedule: slices.scheduleReducer,
    },
    EntitySchemaActions: {
        PopDisplays_JobStatus: {
            actions: slices.jobStatusActions as any,
            entityName: 'PopDisplays_JobStatus',
            selectors: slices.jobStatusSelectors,
            thunks: slices.jobStatusThunks,
        },
        PopDisplays_LogFileImport: {
            actions: slices.logFileImportActions as any,
            entityName: 'PopDisplays_LogFileImport',
            selectors: slices.logFileImportSelectors,
            thunks: slices.logFileImportThunks,
        },
        PopDisplays_LogFileResource: {
            actions: slices.logFileResourceActions as any,
            entityName: 'PopDisplays_LogFileResource',
            selectors: slices.logFileResourceSelectors,
            thunks: slices.logFileResourceThunks,
        },
        PopDisplays_LogImportSource: {
            actions: slices.logImportSourceActions as any,
            entityName: 'PopDisplays_LogImportSource',
            selectors: slices.logImportSourceSelectors,
            thunks: slices.logImportSourceThunks,
        },
        PopDisplays_Display: {
            actions: slices.displayActions as any,
            entityName: 'PopDisplays_Display',
            selectors: slices.displaySelectors,
            thunks: slices.displayThunks,
        },
        PopDisplays_ChannelFrame: {
            actions: slices.channelFrameActions as any,
            entityName: 'PopDisplays_ChannelFrame',
            selectors: slices.channelFrameSelectors,
            thunks: slices.channelFrameThunks,
        },
        Schedule: {
            actions: slices.scheduleActions as any,
            entityName: 'Schedule',
            selectors: slices.scheduleSelectors,
            thunks: slices.scheduleThunks,
        },
    },
    navigation: {
        groups: {
            2: {
                items: {
                    1: {
                        items: {
                            50: {
                                id: 'popdisplays-admin-home',
                                label: 'Navigation.popDisplaysAdmin',
                                icon: pages.PopDisplaysHomePage.systemSearch.icon,
                                link: pages.PopDisplaysHomePage.systemSearch.route,
                                hideIfNoChildren: true,
                                permissions:
                                    pages.PopDisplaysHomePage.systemSearch?.permissions ?? [],
                                items: {
                                    1: {
                                        id: 'pop-virtual-campaign-builder',
                                        icon: pages.VirtualCampaignBuilderPage.systemSearch.icon,
                                        label: 'Navigation.popDisplaysVirtualCampaignBuilder',
                                        link: pages.VirtualCampaignBuilderPage.systemSearch.route,
                                        permissions:
                                            pages.VirtualCampaignBuilderPage.systemSearch
                                                ?.permissions ?? [],
                                    },
                                    2: {
                                        id: 'pop-displays-list',
                                        icon: pages.DisplayListPage.systemSearch.icon,
                                        label: 'Navigation.popDisplaysDisplays',
                                        link: pages.DisplayListPage.systemSearch.route,
                                        permissions:
                                            pages.DisplayListPage.systemSearch?.permissions ?? [],
                                    },
                                    3: {
                                        id: 'pop-channelframes-list',
                                        icon: pages.ChannelFrameListPage.systemSearch.icon,
                                        label: 'Navigation.popDisplaysChannelFrames',
                                        link: pages.ChannelFrameListPage.systemSearch.route,
                                        permissions:
                                            pages.ChannelFrameListPage.systemSearch?.permissions ??
                                            [],
                                    },
                                    4: {
                                        id: 'log-file-import-list',
                                        icon: pages.LogFileImportListPage.systemSearch.icon,
                                        label: 'Navigation.popDisplaysLogFileImports',
                                        link: pages.LogFileImportListPage.systemSearch.route,
                                        permissions:
                                            pages.LogFileImportListPage.systemSearch?.permissions ??
                                            [],
                                    },
                                    5: {
                                        id: 'log-file-import-source-list',
                                        icon: pages.LogImportSourceListPage.systemSearch.icon,
                                        label: 'Navigation.popDisplaysLogImportSource',
                                        link: pages.LogImportSourceListPage.systemSearch.route,
                                        permissions:
                                            pages.LogImportSourceListPage.systemSearch
                                                ?.permissions ?? [],
                                    },
                                    6: {
                                        id: 'playout-export',
                                        icon: pages.PlayoutExportPage.systemSearch.icon,
                                        label: 'Navigation.popDisplayPlayoutExport',
                                        link: pages.PlayoutExportPage.systemSearch.route,
                                        permissions:
                                            pages.PlayoutExportPage.systemSearch?.permissions ?? [],
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },

    pages: [
        pages.PopDisplaysHomePage,
        pages.LogFileImportListPage,
        pages.LogImportSourceListPage,
        pages.DisplayListPage,
        pages.DisplayOverviewPage,
        pages.ChannelFrameListPage,
        pages.ChannelFrameOverviewPage,
        pages.PlayoutExportPage,
        pages.VirtualCampaignBuilderPage,
    ],
    systemSearch: {},
    widgets: [],
};
