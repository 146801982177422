import { PopDisplays_Display } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { PopDisplays_DisplayState } from './Display-types';

const initialState: PopDisplays_DisplayState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: PopDisplays_DisplayState, action: PayloadAction<string>): PopDisplays_DisplayState => state,
};

const slice = createBaseSlice<
    PopDisplays_Display,
    PopDisplays_DisplayState,
    typeof additionalReducers
>({
    name: 'popDisplay',
    entityName: 'PopDisplays_Display',
    initialState,
    reducers: additionalReducers,
});

const displaySlice = {
    displayActions: slice.slice.actions,
    displayReducer: slice.slice.reducer,
    displayAdapter: slice.adapter,
    displaySelectors: slice.selectors,
    displayThunks: slice.thunks,
};

export default displaySlice;
export const { displayReducer, displayActions, displayAdapter, displaySelectors, displayThunks } =
    displaySlice;
