import React from 'react';
import { Box } from '@mui/material';
import { useBoolean } from 'react-hanger';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import UserProfileCard from '../UserProfileCard';
import { Props } from './Props';

const useStyles = makeStyles(() => ({
    popoverContent: {
        padding: 8,
        pointerEvents: 'all',
    },
}));
const UserProfilePopOver = ({ children, userId }: Props) => {
    const open = useBoolean(false);
    const { popoverContent } = useStyles();
    return (
        <>
            <Box onClick={open.toggle}>{children}</Box>
            <Dialog onClose={open.setFalse} open={open.value}>
                <Box className={popoverContent}>
                    <UserProfileCard userId={userId} />
                </Box>
            </Dialog>
        </>
    );
};

export default UserProfilePopOver;
