import React, { useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { PreAuthRoutes } from 'c-routes';

const NoUserRedirect = () => {
    const location = useLocation();

    const noUserRedirect = useMemo(
        () => (
            <Redirect from="/" to={{ pathname: PreAuthRoutes.Login, state: { from: location } }} />
        ),
        [location],
    );

    return noUserRedirect;
};

export default NoUserRedirect;
