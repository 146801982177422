import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, TextField } from 'c-components';
import { Menu } from '@mui/material';
import { isSameDay } from 'date-fns';
import { useBoolean } from 'react-hanger';
import { StaticDatePicker } from '@mui/x-date-pickers-pro';

type Props = {
    dateLabel: React.ReactNode;
    date: Date;
    onChange: (start: Date, end: Date) => void;
    minDate: Date;
    maxDate: Date;
};

const DateDropdown: React.FC<Props> = ({ dateLabel, date, onChange, minDate, maxDate }) => {
    const anchorEl = useRef();
    const menuOpen = useBoolean(false);
    const [localDate, setLocalDate] = useState<Date>(date);

    const onBtnClick = useCallback(() => {
        setLocalDate(date);
        menuOpen.setTrue();
    }, [date, menuOpen]);

    const onDateChange = useCallback(
        (newDate: Date) => {
            setLocalDate(newDate);

            if (newDate && !isSameDay(newDate, date)) {
                onChange(newDate, newDate);
                menuOpen.setFalse();
            }
        },
        [onChange, menuOpen, date],
    );

    return (
        <>
            <Button variant="outlined" onClick={onBtnClick} ref={anchorEl}>
                {dateLabel}
            </Button>

            <Menu anchorEl={anchorEl.current} open={menuOpen.value} onClose={menuOpen.setFalse}>
                <StaticDatePicker
                    openTo="day"
                    minDate={minDate}
                    maxDate={maxDate}
                    value={localDate}
                    onChange={onDateChange}
                    slotProps={useMemo(
                        () => ({ actionBar: { actions: [] }, toolbar: { hidden: true } }),
                        [],
                    )}
                />
            </Menu>
        </>
    );
};

export default DateDropdown;
