import React, { useCallback, useEffect, useMemo } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { PermissionName } from '@uniled/api-sdk';
import { ownerListIcon } from 'c-main/icons';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import { FilterableEntityTable, FilterableEntityTableRendererType } from 'c-pagination';
import {
    displayAnalogueColumns,
    displayOwnerColumns,
    displayPacksColumnsForOwnersPAge,
    displayScreenColumnsOtherTables,
} from 'c-displays/Components/DisplaysEntityColumns';
import { atom_OwnersDialogState, atom_OwnersPackDialogState } from 'c-displays/atoms';
import {
    AllEntitiesDropdown,
    ControlledFormInput,
    CreateEntityButton,
    DialogV2,
    TextField,
} from 'c-components';
import { useAtom } from 'jotai/index';
import { useCommonTranslation } from 'c-translation';
import * as yup from 'yup';
import { Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller } from 'react-hook-form';
import ReactGA from 'react-ga';
import { generatePath } from 'react-router-dom';

const textSearchCols = ['name'];
const paginationTag = 'displays-admin-owners-list';

const OwnerListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const t = useCommonTranslation();
    const [atomValue, setAtomState] = useAtom(atom_OwnersDialogState);
    const [atomPackValue, setAtomPackState] = useAtom(atom_OwnersPackDialogState);
    const schema = yup.object().shape({
        partner_id: yup
            .number()
            .required('Partner ID is required')
            .integer('Partner ID must be an integer'),
        name: yup.string().required('Name is required'),
        markets: yup
            .array()
            .of(
                yup
                    .number()
                    .required('Market ID is required')
                    .integer('Market ID must be an integer'),
            )
            .required('At least one market is required'),
        countries: yup
            .array()
            .of(
                yup
                    .number()
                    .required('Country ID is required')
                    .integer('Country ID must be an integer'),
            )
            .required('At least one country is required'),
        status: yup.boolean(),
    });

    const closeDialog = useCallback(() => {
        setAtomState({ status: null, id: null });
        setAtomPackState({ id: null });
    }, [setAtomPackState, setAtomState]);
    // eslint-disable-next-line consistent-return
    const displayScreensTable = useMemo(() => {
        if (atomValue.id == null) {
            return null;
        }
        if (atomValue.status != null) {
            return (
                <>
                    <DialogV2 maxWidth="xl" onClose={closeDialog} open={atomValue.status != null}>
                        <FullPageEntityTableWrapper title="Modules.Displays.DisplaysList.metaTitle">
                            <FilterableEntityTable
                                tag={`owners-screens-list-${atomValue.id}`}
                                columns={displayScreenColumnsOtherTables}
                                baseEntityName="Displays_Screen"
                                textSearchColumns={textSearchCols}
                                rendererType={FilterableEntityTableRendererType.Contained}
                                revertToIdSearchOnNumberOnlyInput={false}
                                onRowClick={id => {
                                    const rout = generatePath(
                                        PostAuthRoutes.Displays.DisplaysDisplayEdit,
                                        { id },
                                    );
                                    window.open(rout, '_blank');
                                }}
                                orderBy="id"
                                direction="desc"
                                filters={{
                                    'filter.status': [atomValue.status],
                                    'filter.owners.id': [atomValue.id],
                                }}
                                includes={[
                                    'mediaOwners',
                                    'resolutions',
                                    'packs',
                                    'status',
                                    'analogueScreensCount',
                                    'mediaOwners',
                                ]}
                                resetOnUnmount
                            />
                        </FullPageEntityTableWrapper>
                    </DialogV2>
                    ;
                </>
            );
        }
        if (atomValue.status == null) {
            return (
                <DialogV2 onClose={closeDialog} open={atomValue.status == null} maxWidth="xl">
                    <FullPageEntityTableWrapper title="Modules.Displays.Analogue.metaTitle">
                        <FilterableEntityTable
                            tag={`owners-analogues-list-${atomValue.id}`}
                            columns={displayAnalogueColumns}
                            baseEntityName="Displays_AnalogueScreen"
                            textSearchColumns={textSearchCols}
                            rendererType={FilterableEntityTableRendererType.Contained}
                            direction="desc"
                            onRowClick={id => {
                                const rout = generatePath(
                                    PostAuthRoutes.Displays.DisplaysAnalogueScreenEdit,
                                    { id },
                                );
                                window.open(rout, '_blank');
                            }}
                            filters={{
                                'filter.owner.id': [atomValue.id],
                            }}
                            includes={['owner', 'market']}
                        />
                    </FullPageEntityTableWrapper>
                </DialogV2>
            );
        }
        return null;
    }, [atomValue.id, atomValue.status, closeDialog]);

    const packsTable = useMemo(() => {
        if (atomPackValue.id == null) {
            return null;
        }
        return (
            <>
                <DialogV2 onClose={closeDialog} open maxWidth="xl">
                    <FullPageEntityTableWrapper title="Modules.Displays.Packs.metaTitle">
                        <FilterableEntityTable
                            tag={`owner-packs-list-${atomPackValue.id}`}
                            columns={displayPacksColumnsForOwnersPAge}
                            baseEntityName="Displays_Pack"
                            textSearchColumns={textSearchCols}
                            rendererType={FilterableEntityTableRendererType.Contained}
                            direction="desc"
                            filters={{
                                'filter.owner.id': [atomPackValue.id],
                            }}
                            onRowClick={id => {
                                const rout = generatePath(
                                    PostAuthRoutes.Displays.DisplaysPackEdit,
                                    { id },
                                );
                                window.open(rout, '_blank');
                            }}
                            includes={[
                                'owner',
                                'country',
                                'activeDisplaysCount',
                                'inactiveDisplaysCount',
                                'resolutions',
                                'market',
                            ]}
                        />
                    </FullPageEntityTableWrapper>
                </DialogV2>
            </>
        );
    }, [atomPackValue.id, closeDialog]);

    return (
        <>
            {displayScreensTable}
            {packsTable}
            <FullPageEntityTableWrapper
                title="Modules.Displays.OwnerList.metaTitle"
                controls={useMemo(
                    () => [
                        <CreateEntityButton
                            entityName="Displays_Owner"
                            title="Modules.Displays.OwnerList.createBtn"
                            form={<NewOwnerForm />}
                            buttonLabel={t('Modules.Displays.OwnerList.createBtn')}
                            submitLabel="Modules.Displays.OwnerList.submit"
                            key="new-owner-button"
                            permissions={[PermissionName.DisplaysOwnersCreate]}
                            redirectPath={PostAuthRoutes.Displays.DisplaysOwnerEdit}
                            formOptions={{
                                resolver: yupResolver(schema),
                            }}
                        />,
                    ],
                    [schema, t],
                )}
            >
                <FilterableEntityTable
                    key={paginationTag}
                    tag={paginationTag}
                    columns={displayOwnerColumns}
                    baseEntityName="Displays_Owner"
                    textSearchColumns={textSearchCols}
                    rendererType={FilterableEntityTableRendererType.Contained}
                    revertToIdSearchOnNumberOnlyInput={false}
                    direction="desc"
                    // routeTemplate={PostAuthRoutes.Displays.DisplaysOwnerEdit}
                    showFilters
                    includes={[
                        'unilive_id',
                        'packs',
                        'countries',
                        'displays',
                        'activeScreensCount',
                        'inactiveScreensCount',
                        'analogueScreensCount',
                        'partner',
                        'market',
                    ]}
                />
            </FullPageEntityTableWrapper>
        </>
    );
};

const NewOwnerForm = () => {
    const t = useCommonTranslation();
    return (
        <Stack gap={2}>
            <ControlledFormInput
                render={({ field }) => (
                    <TextField
                        label={t('Modules.Displays.OwnerList.buttonForm.name')}
                        {...field}
                        autoFocus
                    />
                )}
                name="name"
            />
            <Controller
                render={({ field }) => (
                    <AllEntitiesDropdown
                        entityName="Market"
                        multi
                        labelField="name"
                        label={t('Modules.Displays.OwnerList.buttonForm.markets')}
                        {...field}
                    />
                )}
                name="markets"
            />
            <Controller
                render={({ field }) => (
                    <AllEntitiesDropdown
                        entityName="Displays_Country"
                        multi
                        labelField="name"
                        label={t('Modules.Displays.OwnerList.buttonForm.countries')}
                        {...field}
                    />
                )}
                name="countries"
            />
            <Controller
                render={({ field }) => (
                    <AllEntitiesDropdown
                        entityName="Displays_Partner"
                        labelField="name"
                        label={t('Modules.Displays.OwnerList.buttonForm.partner')}
                        {...field}
                    />
                )}
                name="partner_id"
            />
        </Stack>
    );
};

const setup: PageConfig = {
    id: 'OwnerListPage',
    component: OwnerListPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.Displays.OwnerList.metaTitle',
        description: 'Modules.Displays.OwnerList.description',
        icon: ownerListIcon,
        type: 'page',
        aliases: 'SystemSearch.aliases.displaysOwnerDisplayList',
        route: PostAuthRoutes.Displays.OwnerList,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.Displays.AdminHome.metaTitle',
                link: PostAuthRoutes.Displays.DisplaysAdminHome,
            },
            {
                label: 'Modules.Displays.OwnerList.metaTitle',
            },
        ],
        permissions: [PermissionName.Admin],
    },
};

export default setup;
