import { RenderColumnDataProps } from 'c-pagination';
import { CampaignErrorableType, Displays_LineItem, Campaign } from '@uniled/api-sdk';
import { useBoolean } from 'react-hanger';
import { Badge, Dialog, DialogContent } from '@mui/material';
import { CampaignErrorList } from 'c-main/Components';
import { AutoGrid, Button, CopyButton } from 'c-components';
import React, { useMemo } from 'react';
import { useEntityPage } from 'c-hooks';
import { useEntityData } from 'c-data';
import { css } from '@emotion/react';

type Props = RenderColumnDataProps<Displays_LineItem>;

const copyClass = 'controls';

const styles = css(`
    .${copyClass} {
        pointer-events: none;
        opacity: 0;
        transition: all 200ms;
        z-index: 10;
    }
    &:hover {
        .${copyClass} {
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
        }
    }

`);
/**
 * We're assuming this component will be used in the displays tab of the campaign by id page.
 */
const LineItemNameWithErrorsCell: React.FC<Props> = ({ entity, defaultContent }) => {
    const { id } = useEntityPage();
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });
    const lineItemErrors = useMemo(
        () =>
            campaign?.errors?.filter(
                err =>
                    err.errorable_type === CampaignErrorableType.LineItem &&
                    String(err.errorable_id) === String(entity.id),
            ) ?? [],
        [campaign, entity],
    );

    // this is actually a react component function, but the linter is just confused.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dialogState = useBoolean(false);

    const copy = useMemo(
        () => <CopyButton copyContent={entity.name} className={copyClass} />,
        [entity],
    );

    if (lineItemErrors.length > 0) {
        return (
            <AutoGrid spacing={2} flexWrap="nowrap" css={styles}>
                <Dialog open={dialogState.value} onClose={dialogState.setFalse}>
                    <DialogContent>
                        <CampaignErrorList errors={lineItemErrors} />
                    </DialogContent>
                </Dialog>
                <Badge variant="dot" color="warning" badgeContent={lineItemErrors.length}>
                    <Button variant="text" color="warning" onClick={dialogState.setTrue}>
                        {defaultContent}
                    </Button>
                </Badge>
                {copy}
            </AutoGrid>
        );
    }
    return (
        <AutoGrid spacing={1} flexWrap="nowrap" css={styles}>
            {defaultContent}
            {copy}
        </AutoGrid>
    );
};

export default LineItemNameWithErrorsCell;
