import {
    displays_OwnerActions,
    displays_OwnerAdapter,
    displays_OwnerThunks as defaultThunks,
    displays_OwnerSelectors as defaultSelectors,
    displays_OwnerReducer,
} from './Displays_Owner-slice';
import * as thunks from './Displays_Owner-thunks';
import * as selectors from './Displays_Owner-selectors';

const displays_OwnerThunks = { ...defaultThunks, ...thunks };
const displays_OwnerSelectors = { ...defaultSelectors, ...selectors };

export { default as Displays_OwnerSlice } from './Displays_Owner-slice';
export {
    displays_OwnerSelectors,
    displays_OwnerActions,
    displays_OwnerAdapter,
    displays_OwnerThunks,
    displays_OwnerReducer,
};
