import * as yup from 'yup';
import { AnyObjectSchema } from 'yup';
import { CampaignFormNames } from 'c-main/Types';

export const stepOneSchema = (overrides?: Record<string, yup.AnySchema>) =>
    yup.object({
        [CampaignFormNames.name]: yup.string().required().min(3),
        [CampaignFormNames.reference]: yup.string().required().min(3),

        [CampaignFormNames.start]: yup.string().required().min(1),
        [CampaignFormNames.end]: yup.string().required().min(1),

        [CampaignFormNames.buyer]: yup.number().required(),
        [CampaignFormNames.agency_id]: yup.number().notRequired(),
        [CampaignFormNames.client_id]: yup.number().notRequired(),

        // [CampaignFormNames.agency_name]: yup.string().required().min(1),
        [CampaignFormNames.client_name]: yup.string().required().min(1),
        ...overrides,
    }) as AnyObjectSchema;
