import React, { useEffect } from 'react';
import { CardContent } from '@mui/material';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { PermissionName, PopDisplays_ChannelFrame } from '@uniled/api-sdk';
import { displaysIcon } from 'c-main/icons';
import { TransparentCard } from 'c-components';
import { useEntityPage } from 'c-hooks';
import ReactGA from 'react-ga';
import ChannelFrameDetails from '../Components/ChannelFrame/ChannelFrameDetails';

const name: PageEntityConfig<PopDisplays_ChannelFrame>['systemSearch']['nameGen'] = (entity, id) =>
    entity?.player ?? String(id);

const ChannelFrameOverviewPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { id } = useEntityPage();
    return (
        <TransparentCard>
            <CardContent>
                <ChannelFrameDetails id={id} />
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageEntityConfig<PopDisplays_ChannelFrame> = {
    id: 'ChannelFrameOverviewPage',
    component: ChannelFrameOverviewPage,
    systemSearch: {
        title: undefined,
        icon: displaysIcon,
        type: 'entity',
        route: PostAuthRoutes.PopDisplays.ChannelFrameOverview,
        saveAsRecentPage: true,
        entityName: 'PopDisplays_ChannelFrame',
        defaultIncludes: ['displays', 'logFilesParsedByMedia' /* , 'logFilesParsedByDate' */],
        nameGen: name,
        permissions: [
            PermissionName.Pop_displaysDisplay_channel_framesRead,
            PermissionName.Pop_displaysDisplay_channel_framesUpdate,
            PermissionName.Pop_displaysDisplay_channel_framesCreate,
        ],
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.PopDisplays.AdminHome.metaTitle',
                link: PostAuthRoutes.PopDisplays.PopDisplaysHome,
            },
            {
                label: 'Modules.PopDisplays.ChannelFrame.metaTitle',
                link: PostAuthRoutes.PopDisplays.ChannelFrameList,
            },
        ],
    },
};

export default setup;
