import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { scrollWindowToTop } from 'c-lib';
import { uiThunks } from 'c-wrapper/Slices/UI';

const ScrollToTop = () => {
    const dispatch = useDispatch();

    const { pathname } = useLocation();

    useEffect(() => {
        scrollWindowToTop();
        dispatch(uiThunks.setPlayAroundSkin(null));
    }, [pathname, dispatch]);

    return null;
};

export default ScrollToTop;
