import React, { useCallback, useMemo } from 'react';
import { AutocompleteProps } from '@mui/material';
import { Autocomplete, OptionSchema } from 'c-components';
import { useCommonTranslation } from 'c-translation';

type Props = {
    allFields: string[];
    selectedFieldValues: string[];
    setSelectedFieldValues: (fields: string[]) => void;
};

const FieldFilter: React.FC<Props> = ({
    allFields,
    selectedFieldValues,
    setSelectedFieldValues,
}) => {
    const actualOptions = useMemo<OptionSchema[]>(
        () => allFields.map(f => ({ label: f, value: f })),
        [allFields],
    );
    const selectedPlaceholder = useCommonTranslation(
        'Modules.Main.Campaigns.Overview.Reports.selectedTotalPlaceholder',
        { selected: selectedFieldValues.length, total: allFields.length },
    );
    const renderTags: AutocompleteProps<any, any, any, any>['renderTags'] = useCallback(
        () => <>{selectedPlaceholder}</>,
        [selectedPlaceholder],
    );

    return (
        <Autocomplete
            disableCloseOnSelect
            multiple
            options={actualOptions}
            onChange={setSelectedFieldValues}
            value={selectedFieldValues}
            textFieldProps={{ placeholder: '', variant: 'outlined' }}
            renderTags={renderTags}
        />
    );
};

export default FieldFilter;
