import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Stack } from '@mui/material';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import to from 'await-to-js';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import { Alert, Autocomplete } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { NetworkRequestState } from '@uniled/data-layer';

const DetachTarget = ({ target, id, detachBool }) => {
    const { start, error, requestState } = useAPIClientRequest(
        ApiClient.Entities.Campaign.detachTargetFromSchedules,
    );
    const { setTargets } = useNewCampaignSchedule(id);
    const targetId = useMemo(() => target?.id, [target]);

    const scheduleOptions = target.schedules.map(schedule => ({
        value: schedule.id,
        label: schedule.name,
    }));

    const { control, handleSubmit, setValue } = useForm({
        defaultValues: {
            schedules: [],
        },
    });

    const detachTargetFromSchedules = useCallback(
        async data => {
            const [err, success] = await to(start(id, targetId, { schedules: data.schedules }));
            if (!err && success && success?.data?.success) {
                const updatedSchedules = target.schedules.filter(
                    schedule => !data.schedules.includes(schedule.id),
                );

                setTargets(prevTargets =>
                    prevTargets.map(t =>
                        t.id === targetId ? { ...t, schedules: updatedSchedules } : t,
                    ),
                );

                detachBool.setFalse();
            }
        },
        [start, id, targetId, detachBool, setTargets, target.schedules],
    );

    const t = useCommonTranslation();

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(detachTargetFromSchedules)}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
            <Controller
                name="schedules"
                control={control}
                rules={{
                    required: t(
                        'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.verification.atLeastOneSelected',
                    ),
                }}
                render={({ field, fieldState: { error } }) => (
                    <Stack direction="row" gap={1}>
                        <Autocomplete
                            value={scheduleOptions.filter(option =>
                                field.value.includes(option.value),
                            )}
                            options={scheduleOptions}
                            {...field}
                            multiple
                            textFieldProps={{
                                label: t(
                                    'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.detachTarget',
                                ),
                                error: !!error,
                                helperText: error
                                    ? error.message
                                    : t(
                                          'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.verification.schedulesSelect',
                                      ),
                            }}
                            disabled={requestState === NetworkRequestState.InProgress}
                        />
                        <Box>
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={() =>
                                    setValue(
                                        'schedules',
                                        scheduleOptions.map(option => option.value),
                                    )
                                }
                                disabled={requestState === NetworkRequestState.InProgress}
                            >
                                {t('Forms.autocomplete.selectAllOptionLabel')}
                            </Button>
                        </Box>
                    </Stack>
                )}
            />
            <Button
                type="submit"
                disabled={requestState === NetworkRequestState.InProgress}
                variant="contained"
                color="primary"
            >
                {t('Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.detachTarget')}
            </Button>
            {error && (
                <Alert severity="error" variant="outlined">
                    {String(error)}
                </Alert>
            )}
        </Box>
    );
};

export default DetachTarget;
