import {
    logFileResourceActions,
    logFileResourceAdapter,
    logFileResourceThunks as defaultThunks,
    logFileResourceSelectors as defaultSelectors,
    logFileResourceReducer,
} from './LogFileResource-slice';
import * as thunks from './LogFileResource-thunks';
import * as selectors from './LogFileResource-selectors';

const logFileResourceThunks = { ...defaultThunks, ...thunks };
const logFileResourceSelectors = { ...defaultSelectors, ...selectors };

export { default as LogFileResourceSlice } from './LogFileResource-slice';
export {
    logFileResourceSelectors,
    logFileResourceActions,
    logFileResourceAdapter,
    logFileResourceThunks,
    logFileResourceReducer,
};
