import { Skin } from 'c-entity-types';

export const newSkinDefaults: Partial<Skin> = {
    primary_color: 'rgba(25, 118, 212, 1)',
    secondary_color: 'rgba(83,86,90,1)',
    divider_color: 'rgba(0,0,0,0.12)',
    card_bg_color: 'rgba(255,255,255,1)',

    primary_text_color: 'rgba(0,0,0,0.87)',
    secondary_text_color: 'rgba(0,0,0,0.6)',
    disabled_text_color: 'rgba(0,0,0,0.38)',

    content_bg_color: 'rgba(238,238,238,1)',
    nav_bg_color: 'rgba(255,255,255,1)',
};
