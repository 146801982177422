import { CommonThunk } from 'c-types';
import { roleActions } from 'c-admin/Slices/Role/Role-slice';
import { RoleState } from 'c-admin/Slices/Role/Role-types';

export const setRoleEditingPermissions =
    (perms: RoleState['roleEditingPermissions']): CommonThunk =>
    async dispatch => {
        dispatch(roleActions.setRoleEditingPermissions(perms));
    };

export const setRoleEditingPermissionState =
    (permId: number, state: boolean): CommonThunk =>
    async dispatch => {
        dispatch(roleActions.toggleRoleEditingPermission({ permission: permId, state }));
    };

export const setRoleEditingPermissionStateMulti =
    (perms: Record<number, boolean>): CommonThunk =>
    async dispatch => {
        dispatch(roleActions.toggleRoleEditingPermissionMulti(perms));
    };
