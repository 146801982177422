import { useCommonTranslation } from 'c-translation';
import {
    Alert,
    AllEntitiesDropdown,
    AutoGrid,
    Button,
    EntityAutocomplete,
    Filterdropdown,
    SelectField,
    TextField,
} from 'c-components';
import { Controller } from 'react-hook-form';
import { Box, FormLabel } from '@mui/material';
import React, { useMemo } from 'react';
import { NetworkRequestState } from '@uniled/data-layer';
import { Redirect } from 'react-router-dom';
import { PostAuthRoutes } from 'c-routes';

export const DisplayTagForm = ({ control, state, error, id }) => {
    const t = useCommonTranslation();
    const options = [
        { label: 'Yes', value: 1 },
        { label: 'No', value: 0 },
    ];

    const SuccessAlert = useMemo(() => {
        if (state === NetworkRequestState.Success) {
            return (
                <>
                    <Alert severity="success">
                        {t('Modules.Displays.DisplaysTagEdit.success')}
                    </Alert>
                    <Redirect to={PostAuthRoutes.Displays.TagsList} />
                </>
            );
        }
        return null;
    }, [state, t]);

    const SubmitButton = useMemo(
        () => (
            <Button type="submit" disabled={state === NetworkRequestState.InProgress}>
                {t('Modules.Displays.DisplaysTagEdit.submit')}
            </Button>
        ),
        [state, t],
    );
    return (
        <Box>
            <AutoGrid xs={4} gap={2} pb={2}>
                <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysTagEdit.fields.name')} *
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="operational"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysTagEdit.fields.operational')} *
                            </FormLabel>
                            <Filterdropdown
                                options={options}
                                value={field.value}
                                onChange={newValue => {
                                    field.onChange(newValue);
                                }}
                            />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysTagEdit.fields.market')}
                            </FormLabel>
                            <AllEntitiesDropdown entityName="Market" labelField="name" {...field} />
                        </>
                    )}
                    name="market_id"
                />
                <Controller
                    control={control}
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysTagEdit.fields.partner')}
                            </FormLabel>
                            <AllEntitiesDropdown
                                entityName="Displays_Partner"
                                labelField="name"
                                {...field}
                            />
                        </>
                    )}
                    name="partner_id"
                />
                <Controller
                    control={control}
                    name="description"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysTagEdit.fields.description')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="parent_id"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysTagEdit.fields.parent')}
                            </FormLabel>
                            <EntityAutocomplete
                                searchColumns="name"
                                labelColumn="name"
                                entityName="Displays_Tag"
                                {...field}
                            />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    render={({ field }) => (
                        <>
                            <FormLabel>{t('Modules.Displays.Tags.buttonForm.status')}</FormLabel>
                            <SelectField
                                value={field.value}
                                options={[
                                    {
                                        value: 0,
                                        label: t(
                                            'Modules.Displays.DisplaysTagEdit.fields.inactive',
                                        ),
                                    },
                                    {
                                        value: 1,
                                        label: t('Modules.Displays.DisplaysTagEdit.fields.active'),
                                    },
                                ]}
                                {...field}
                            />
                        </>
                    )}
                    name="status"
                />
            </AutoGrid>
            {state === NetworkRequestState.Error && (
                <Box pb={2}>
                    <Alert severity="error" variant="outlined">
                        {String(error)}
                    </Alert>
                </Box>
            )}
            {SubmitButton}
            {SuccessAlert}
        </Box>
    );
};
