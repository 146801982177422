import { isBefore, isDate, isValid, parse, parseISO } from 'date-fns';

export const dateSort = (a: string | Date, b: string | Date, direction: 'asc' | 'desc') => {
    const dateA = isDate(a) ? (a as Date) : parseISO(a as string);
    const dateB = isDate(b) ? (b as Date) : parseISO(b as string);
    if (!isValid(dateA) && !isValid(dateB)) return 0;

    if (!isValid(dateA) && isValid(dateB)) return 1;
    if (isValid(dateA) && !isValid(dateB)) return -1;

    if (direction === 'asc') {
        return dateA.getTime() - dateB.getTime();
    }

    return dateB.getTime() - dateA.getTime();
};

export const dateSortArray = (dateFieldKey: string, dateFormat: string) => (a, b) => {
    try {
        const aDate = parse(a[dateFieldKey], dateFormat, new Date());
        const bDate = parse(b[dateFieldKey], dateFormat, new Date());

        if (isBefore(aDate, bDate)) return -1;
        if (isBefore(bDate, aDate)) return 1;

        return 0;
        // eslint-disable-next-line no-empty
    } catch {}

    return 0;
};
