import React, { useCallback, useState } from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';
import AutoGrid from 'c-components/AutoGrid';
import { Box, Typography } from '@mui/material';
import { TranslationPath, useCommonTranslation } from 'c-translation';
import { Alert } from 'c-components/MuiOverrides';

type Props = {
    dropzoneLabel?: TranslationPath;
    fileAccept: string;
    onFilesAdded: (files: File[]) => void;
    disabled?: boolean;
};
const isFilenameValid = filename => {
    const disallowedCharsRegex = /[\\/:*?"<>|~]/;
    return !disallowedCharsRegex.test(filename);
};

const FileUploadDropzone: React.FC<Props> = ({
    dropzoneLabel,
    fileAccept,
    onFilesAdded,
    disabled = false,
}) => {
    const [error, setError] = useState<string | null>(null);
    const addFiles = useCallback(
        (files: File[]) => {
            setError(null);
            const validFiles = files?.filter(file => isFilenameValid(file?.name));
            if (validFiles.length > 0) {
                onFilesAdded(validFiles);
            }

            const invalidFilesCount = files.length - validFiles.length;
            if (invalidFilesCount > 0) {
                setError(
                    `${invalidFilesCount} file(s) were not added due to invalid characters in their names.    \n Invalid characters are: '\\', '/', ':', '*', '?', '"', '<', '>', '|', '~'`,
                );
            }
        },
        [onFilesAdded],
    );
    const onDrop = useCallback(
        (data: { files: File[] }) => {
            if (!disabled) {
                addFiles(data.files);
            }
        },
        [addFiles, disabled],
    );
    const onChange = useCallback(
        e => {
            const files: FileList = e.target.files;

            if (files.length > 0 && files.item(0)) {
                addFiles(Array.from(files));
                // reset selected files so the same file can be selected again and trigger change event
                e.target.value = '';
            }
        },
        [addFiles],
    );

    const [{ isOver, canDrop }, drop] = useDrop(
        {
            accept: NativeTypes.FILE,
            drop: onDrop,
            collect: monitor => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
        },
        [onDrop],
    );

    return (
        <>
            <AutoGrid spacing={2} xs={12} sx={{ opacity: disabled ? 0.5 : 1 }}>
                <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                    <Box display="none">
                        <input
                            accept={fileAccept}
                            type="file"
                            id="file-upload"
                            multiple
                            onChange={onChange}
                            disabled={disabled}
                        />
                    </Box>
                    <Box
                        ref={drop}
                        mt={2}
                        p={4}
                        sx={{
                            borderWidth: 4,
                            borderStyle: 'dashed',
                            borderColor: !disabled
                                ? isOver && canDrop
                                    ? 'success.main'
                                    : 'primary.main'
                                : 'primary.main',
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography
                            variant="subtitle1"
                            color={
                                !disabled
                                    ? isOver && canDrop
                                        ? 'success.main'
                                        : 'textSecondary'
                                    : 'textSecondary'
                            }
                        >
                            {useCommonTranslation(dropzoneLabel)}
                        </Typography>
                    </Box>
                </label>
            </AutoGrid>
            {error && <Alert severity="error">{error}</Alert>}
        </>
    );
};

const Wrapper: React.FC<Props> = props => (
    <DndProvider backend={HTML5Backend}>
        <FileUploadDropzone {...props} />
    </DndProvider>
);

export default Wrapper;
