import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { ControlledFormInput, DateRangePickerFormWrapper } from 'c-components';
import { CampaignFormNames } from 'c-main/Types';
import { useTheme } from '@mui/styles';

type Props = {
    //
};

const DetailsStep: React.FC<Props> = () => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            <Box display="flex" justifyContent="center">
                <ControlledFormInput
                    render={({ field }) => (
                        <DateRangePickerFormWrapper
                            startDateKey={CampaignFormNames.start}
                            endDateKey={CampaignFormNames.end}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            direction={isMdDown ? 'vertical' : 'horizontal'}
                        />
                    )}
                    name="aaa"
                />
            </Box>
        </>
    );
};

export default DetailsStep;
