import React, { useMemo } from 'react';
import { Campaign, PermissionName } from '@uniled/api-sdk';
import { Link, Stack } from '@mui/material';
import { Translate } from 'c-translation';
import { Alert } from 'c-components';
import { IfHasAllPermissions } from 'c-auth-module/Components';

type Props = {
    campaign: Campaign;
};

const CampaignNotices: React.FC<Props> = ({ campaign }) => {
    const hasAwaitingData = useMemo(
        () => campaign?.notices?.overall?.awaiting_data || campaign?.data_status?.awaiting_data,
        [campaign],
    );

    const hasInspectingData = useMemo(
        () => campaign?.notices?.overall?.inspecting_data || campaign?.data_status?.inspecting_data,
        [campaign],
    );

    const hasAwaitingBookingPlan = useMemo(
        () =>
            campaign?.notices?.overall?.awaiting_booking_plan ||
            campaign?.data_status?.awaiting_booking_plan,
        [campaign],
    );

    if (
        !campaign?.data_status?.awaiting_data &&
        !campaign?.data_status?.inspecting_data &&
        !campaign?.data_status?.awaiting_booking_plan &&
        !campaign?.notices?.overall?.awaiting_data &&
        !campaign?.notices?.overall?.inspecting_data &&
        !campaign?.notices?.overall?.awaiting_booking_plan &&
        campaign?.booking_summary_validated === true
    ) {
        return null;
    }

    return (
        <Stack spacing={2}>
            <IfHasAllPermissions permissions={[PermissionName.GeneralView_data_status]}>
                {hasAwaitingData && (
                    <Alert severity="warning" variant="outlined">
                        <Translate path="Modules.Main.Campaigns.DataStatus.awaitingDataLabelAlert" />
                    </Alert>
                )}
                {hasInspectingData && (
                    <Alert severity="warning" variant="outlined">
                        <Translate path="Modules.Main.Campaigns.DataStatus.inspectingDataLabelAlert" />
                    </Alert>
                )}
            </IfHasAllPermissions>
            <IfHasAllPermissions permissions={[PermissionName.GeneralView_data_status_booking]}>
                {campaign?.booking_summary_validated === false && (
                    <Alert severity="warning" variant="outlined">
                        <Translate path="Modules.Main.Campaigns.DataStatus.bookingPlanValidatedLabelAlert" />
                        <MailTo />
                    </Alert>
                )}
            </IfHasAllPermissions>
        </Stack>
    );
};

const MailTo = () => (
    <Link href="mailto:data@uniledsoftware.com" target="_blank">
        data@uniledsoftware.com
    </Link>
);

export default CampaignNotices;
