import { en } from 'c-translation/Langs';
import { DeepPartial } from 'c-types';

const enUS: DeepPartial<typeof en> = {
    CommonWords: {
        Impacts: 'Impressions',
        Pack: 'Package',
        pack: 'package',
        frame: 'unit',
        Frame: 'Unit',
        TVRegion: 'State',
        Region: 'State',
        Conurbation: 'Market (DMA)',
        Town: 'CBSA',
        MediaOwners: 'Partners',
        MediaOwner: 'Partner',
        owners: 'partners',
        Owners: 'Partners',
        Owner: 'Partner',
        owner: 'partner',
    },
};

export default enUS;
