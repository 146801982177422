import React, { Ref, useCallback } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';
import { ChartReportProps } from 'c-reports/Types';

type Props = Omit<ChartReportProps<any>, 'onDataUpdated' | 'yAxisDataKey' | 'xAxisDataKey'>;

const ReportPieChart = (
    {
        data,
        chartProps,
        parts,
        renderTooltipLabel,
        hideLegend,
        hideTooltip,
        pieProps,
        chartChildren,
    }: Props,
    ref: Ref<any>,
) => {
    const CustomTooltip = useCallback(
        (tooltipProps: TooltipProps<any, any>) => {
            if (
                renderTooltipLabel &&
                tooltipProps.active &&
                tooltipProps.payload &&
                tooltipProps.payload.length
            ) {
                return renderTooltipLabel(tooltipProps);
            }

            return null;
        },
        [renderTooltipLabel],
    );

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart
                ref={ref}
                margin={{
                    top: 25,
                    right: 50,
                    left: 40,
                    bottom: 5,
                }}
                {...chartProps}
            >
                {!hideTooltip && (
                    <Tooltip content={renderTooltipLabel ? <CustomTooltip /> : undefined} />
                )}
                {!hideLegend && (
                    <Legend verticalAlign="top" align="right" wrapperStyle={{ top: 5 }} />
                )}
                <Pie
                    data={data}
                    dataKey={pieProps.dataKey}
                    // paddingAngle={5}
                    // cx={120}
                    // cy={200}
                    innerRadius={40}
                    outerRadius={90}
                    {...(pieProps as any)}
                >
                    {data.map((entry, index) => (
                        <Cell
                            // eslint-disable-next-line react/no-array-index-key
                            key={`cell-${index}`}
                            {...parts?.[index]?.pieCellProps}
                        />
                    ))}
                </Pie>
                {[...(chartChildren ?? [])]}
            </PieChart>
        </ResponsiveContainer>
    );
};

export default React.forwardRef(ReportPieChart);
