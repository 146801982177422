import React, { forwardRef, Ref } from 'react';
import {
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    Slider as MuiSlider,
    SliderProps as MuiSliderProps,
} from '@mui/material';

type Props = MuiSliderProps & {
    error?: boolean;
    helperText?: string;
    label?: string;
    icon?: React.ReactNode;
};

const Slider = ({ error, helperText, label, icon, ...rest }: Props, ref: Ref<any>) => {
    return (
        <FormControl error={error != null} component="fieldset" ref={ref} fullWidth>
            {label && <FormLabel component="legend">{label}</FormLabel>}
            <Grid container spacing={2}>
                {icon && <Grid item>{icon}</Grid>}
                <Grid item xs>
                    <MuiSlider valueLabelDisplay="auto" {...rest} ref={ref} />
                </Grid>
            </Grid>
            {error && <FormHelperText error>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default forwardRef(Slider);
