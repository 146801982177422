/**
 * Format a file size string from a number of bytes.
 * @param bytes
 * @param decimals
 */
function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    // return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`;
}

export default formatBytes;
