import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { PageConfig } from 'c-config';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CampaignInviteImg from 'c-assets/campaign-invite.png';
import { PostAuthRoutes } from 'c-routes';
import { useAPIClientRequest, useAuthenticatedUser } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { AutoGrid, Button, FlashMessage, LinkButton, LoadingSpinner } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { generatePath, Redirect, useHistory } from 'react-router-dom';
import { useEntityData } from 'c-data';
import ReactGA from 'react-ga';
import { Displays_Owner as Owner, Buyer } from '@uniled/api-sdk';

// https://unilivecampaigns.com/invite?token=rS2pCeNJa54u

const AcceptCampaignInvitePage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const token = useMemo(() => new URLSearchParams(window.location.search).get('token'), []);
    const t = useCommonTranslation();

    const {
        start: LoadInviteInfo,
        isLoading: IsInfoLoading,
        hasFailed: HasInfoFailed,
        error: InfoError,
        hasSucceeded: HasInfoSucceeded,
        data: InfoData,
    } = useAPIClientRequest(apiClient.Auth.inviteDetails);
    const {
        start: AcceptInvite,
        isLoading: AcceptInviteLoading,
        hasFailed: AcceptInviteFailed,
        error: AcceptInviteError,
    } = useAPIClientRequest(apiClient.Auth.inviteAccept);

    const campaignId = useMemo(() => {
        if (HasInfoSucceeded && InfoData?.data?.data?.[0]?.campaign_id != null) {
            return InfoData?.data?.data?.[0]?.campaign_id;
        }

        return null;
    }, [HasInfoSucceeded, InfoData]);

    const rawCampaignName = useMemo(
        () =>
            `#${InfoData?.data?.data?.[0]?.campaign_id} - ${InfoData?.data?.data?.[0]?.campaign_name}`,
        [InfoData],
    );
    const campaignInviteText = useMemo(() => {
        if (HasInfoSucceeded && InfoData?.data?.data?.[0]?.campaign_id != null) {
            return t('Modules.Main.Campaigns.Invite.inviteDescription', {
                name: rawCampaignName,
            });
        }

        return null;
    }, [HasInfoSucceeded, InfoData?.data?.data, rawCampaignName, t]);

    const loadTokenInfo = useCallback(
        async (inviteToken: string) => {
            await to(LoadInviteInfo(inviteToken));
        },
        [LoadInviteInfo],
    );

    const $history = useHistory();
    const acceptInvite = useCallback(async () => {
        const [err, succ] = await to(AcceptInvite(token));

        if (!err && succ) {
            $history.push(
                generatePath(PostAuthRoutes.Campaigns.CampaignOverview, { id: campaignId }),
            );
        }
    }, [token, AcceptInvite, $history, campaignId]);

    useEffect(() => {
        loadTokenInfo(token);
    }, [token, loadTokenInfo]);

    const { isAdmin, isMediaOwner, isBuyer, userBuyers, userMediaOwners } = useAuthenticatedUser();
    const { getById: getOwnerById } = useEntityData<Owner>('Displays_Owner');
    const { getById: getBuyerById } = useEntityData<Buyer>('Buyer');
    const userAssociations = useMemo(() => {
        if (isBuyer)
            return userBuyers?.map(buyerId => getBuyerById({ id: buyerId })?.name ?? '').join(', ');

        if (isMediaOwner)
            return userMediaOwners
                ?.map(ownerId => getOwnerById({ id: ownerId })?.name ?? '')
                .join(', ');

        return '';
    }, [userBuyers, userMediaOwners, getOwnerById, getBuyerById, isBuyer, isMediaOwner]);

    const notAllowed = useMemo(
        () => !isAdmin && (isMediaOwner || isBuyer),
        [isMediaOwner, isBuyer, isAdmin],
    );

    if (HasInfoFailed) {
        return (
            <InviteBox>
                <FlashMessage textAlign="center" status="error">
                    {String(InfoError)}
                </FlashMessage>
            </InviteBox>
        );
    }

    if (notAllowed && campaignId != null) {
        return (
            <Redirect
                to={generatePath(PostAuthRoutes.Campaigns.CampaignOverview, { id: campaignId })}
            />
        );
    }

    if (campaignInviteText != null) {
        // if (notAllowed) {
        //     return (
        //         <InviteBox>
        //             <Alert severity="error">
        //                 {t('Modules.Main.Campaigns.Invite.inviteNotAllowed', {
        //                     campaign_name: rawCampaignName,
        //                     association: userAssociations,
        //                 })}
        //             </Alert>
        //         </InviteBox>
        //     );
        // }
        return (
            <InviteBox>
                <Box>
                    <Typography variant="h4" align="center" sx={{ wordBreak: 'break-all' }}>
                        {campaignInviteText}
                    </Typography>
                </Box>
                {AcceptInviteFailed && (
                    <FlashMessage textAlign="center" status="error">
                        {String(AcceptInviteError)}
                    </FlashMessage>
                )}
                <AutoGrid xs="auto" gap={2} justifyContent="center">
                    <LinkButton
                        to={PostAuthRoutes.Dashboard.Dashboard}
                        variant="outlined"
                        disabled={AcceptInviteLoading}
                        id="decline-invite"
                    >
                        {t('Modules.Main.Campaigns.Invite.declineLabel')}
                    </LinkButton>
                    <Button
                        onClick={acceptInvite}
                        disabled={AcceptInviteLoading}
                        id="accept-invite"
                    >
                        {t('Modules.Main.Campaigns.Invite.acceptLabel')}
                    </Button>
                </AutoGrid>
            </InviteBox>
        );
    }

    if (IsInfoLoading) {
        return <LoadingSpinner />;
    }
    return <></>;
};

const InviteBox: React.FC<PropsWithChildren> = ({ children }) => (
    <Box maxWidth="sm" width="100%" mx="auto" my="auto">
        <Paper elevation={2}>
            <Box p={10}>
                <Stack spacing={4}>
                    <Box textAlign="center">
                        <img src={CampaignInviteImg} alt="campaign invite icon" height={170} />
                    </Box>
                    {children}
                </Stack>
            </Box>
        </Paper>
    </Box>
);

const setup: PageConfig = {
    id: 'AcceptCampaignInvitePage',
    component: AcceptCampaignInvitePage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.Main.Campaigns.Invite.metaTitle',
        type: 'page',
        aliases: undefined,
        route: PostAuthRoutes.Campaigns.AcceptCampaignInvite,
        saveAsRecentPage: false,
        breadcrumbs: [],
    },
};

export default setup;
