import React, { PropsWithChildren } from 'react';
import { Box, Fade } from '@mui/material';

type Props = {
    visible: boolean;
};

const StepContent: React.FC<PropsWithChildren<Props>> = ({ visible, children }) => (
    <Fade in={visible}>
        <Box>{children}</Box>
    </Fade>
);

export default StepContent;
