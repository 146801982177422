import React from 'react';
import { useBoolean } from 'react-hanger';
import { Box, Grid } from '@mui/material';
import { Translate, TranslationPath } from 'c-translation';
import { CollapsibleCard, ControlledFormInput, RgbaColorPicker } from 'c-components';
import { Skin } from 'c-entity-types';

type Props = {
    submitButton: React.ReactNode;
    title: TranslationPath;
    items: ColorSectionItem[];
};

type ColorSectionItem = {
    key: keyof Skin;
    label: TranslationPath;
};

const SkinColorSection: React.FC<Props> = ({ submitButton, title, items }) => {
    const open = useBoolean(false);
    return (
        <CollapsibleCard title={title}>
            <Box mb={2} pl={4}>{submitButton}</Box>
            <Grid container spacing={2}>
                {items.map(item => (
                    <Grid
                        key={item.key}
                        item
                        xs={12}
                        sm={6}
                        lg={4}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <ControlledFormInput
                            render={({ field }) => (
                                <RgbaColorPicker
                                    {...field}
                                    label={<Translate path={item.label} />}
                                    greyBackdrop={false}
                                    whiteBackdrop={false}
                                />
                            )}
                            name={item.key}
                        />
                    </Grid>
                ))}
            </Grid>
        </CollapsibleCard>
    );
};

export default SkinColorSection;
