import React, { useMemo } from 'react';
import { Skin } from 'c-entity-types';
import { css } from '@emotion/react';

type Props = {
    //
    small: boolean;
    DefaultLogo: React.FC;
    skin?: Skin;
};

const SvgLogoWrapper: React.FC<Props> = ({ DefaultLogo, skin, small }) => {
    const logoSrc = useMemo(() => {
        if (small && skin?.logo_small_path != null) {
            return skin.logo_small_path;
        }
        if (!small && skin?.logo_path != null) {
            return skin.logo_path;
        }

        return null;
    }, [skin, small]);

    const imageProps = useMemo<any>(() => {
        let $imgProps: any = {
            children: <DefaultLogo />,
            height: '100%',
            width: '100%',
            style: { maxWidth: '100%', alignSelf: 'center' },
        };

        if (small) {
            $imgProps = { ...$imgProps, css: css({ transition: 'all 300ms' }) };
        }

        if (logoSrc != null) {
            $imgProps.src = logoSrc;
            $imgProps.children = undefined;
        }

        return $imgProps;
    }, [small, DefaultLogo, logoSrc]);

    if (logoSrc != null) {
        return <img alt="" {...imageProps} />;
    }
    return <svg {...imageProps} />;
};

export default SvgLogoWrapper;
