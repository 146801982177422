import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Displays_LineItem, ScheduleGet } from '@uniled/api-sdk';
import { useCommonTranslation } from 'c-translation';
import { DataGridPro, DataGridProProps, GridFooterContainerProps } from '@mui/x-data-grid-pro';
import { ListedLineItem } from 'c-main/Components/Schedule/types';
import { Box, Checkbox, InputAdornment, Stack, Switch, Tab, Typography } from '@mui/material';
import { lineItemToListedLineItem } from 'c-main/Components/Schedule/lib';
import {
    Alert,
    AutoGrid,
    Button,
    Chip,
    DialogV2,
    Filterdropdown,
    LoadingSpinner,
    OptionSchema,
    TextField,
} from 'c-components';
import { uniq } from 'ramda';
import { Search } from '@mui/icons-material';
import { getLineItemResolutions } from 'c-main/Components/CreativeManagement/lib';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import GanttChartBar from 'c-main/Components/Schedule/Components/GanttChartComponents/GanttChartBar';
import GanttChartHeaders from 'c-main/Components/Schedule/Components/GanttChartComponents/GantChartHeaders';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import CustomFooter from 'c-main/Components/Schedule/Components/LineItems/CustomFooter';
import { TabContext, TabList } from '@mui/lab';
import { uniqBy } from 'lodash';

import { useBoolean } from 'react-hanger';
import DetachDialog from 'c-main/Components/Schedule/Components/LineItems/DetachDialog';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { NetworkRequestState } from '@uniled/data-layer';

interface Item {
    id: number | string;
    name: string;
    owner: string;
    schedules: string[];
    type: string;
}

type Props = {
    campaignId: number;
    schedules: ScheduleGet[];
    lineItems: Displays_LineItem[];
    loading: boolean;
    onSelectLineItems: (lineItems: Item) => void;
    selectedLineItems: Item[];
    setSelected: (lineItems: (prevSelected: Item[]) => Item[]) => void;
};

// a column has to be defined in the data grid's columns to be filterable
// so hiding the columns we want to filter on, but not show in the GUI
const initialState = {
    columns: {
        columnVisibilityModel: {
            environment: false,
            resolutions: false,
            size: false,
            graph: false,
        },
    },
};

const CampaignScheduleLineItemList: React.FC<Props> = ({
    campaignId,
    schedules,
    lineItems,
    loading,
    onSelectLineItems,
    selectedLineItems,
    setSelected,
}) => {
    const t = useCommonTranslation();
    const [search, setSearch] = useState('');
    const [environments, setEnvironments] = useState([]);
    const [resolutions, setResolutions] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [owners, setOwners] = useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        environment: false,
        resolutions: false,
        size: false,
        graph: false,
        owner: true,
        schedules: true,
        frame_id: true,
    });
    const [value, setValue] = useState('1');

    const detachState = useBoolean(false);
    const [detach, setDetach] = useState({ lineItemId: null, schedules: [], screenId: null });
    const handleDetach = useCallback(
        (id, type, schedules) => {
            if (type === 'Display') {
                setDetach({
                    screenId: id,
                    lineItemId: null,
                    schedules: schedules.map(schedule => ({
                        id: schedule.id,
                        name: schedule.name,
                    })),
                });
            } else {
                setDetach({
                    lineItemId: id,
                    schedules: schedules.map(schedule => ({
                        id: schedule.id,
                        name: schedule.name,
                    })),
                    screenId: null,
                });
            }
            detachState.setTrue();
        },
        [detachState],
    );
    const handleCloseDetachDialog = useCallback(() => {
        setDetach({ lineItemId: null, schedules: [], screenId: null });
        detachState.setFalse();
    }, [detachState]);
    const packOpened = useBoolean(false);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const {
        getScreensFromLineItem,
        getScheduleLineItems,
        page,
        currentPage,
        closePack,
        screens,
        setScreens,
        selectedScreens,
        setSelectedScreens,
        screenLoading,
        setPrevSelectedLineItemId,
        setExpandedScheduleId,
        screensPerPage,
        screensCurrentPage,
        perPage,
    } = useNewCampaignSchedule(campaignId);
    const envsOpts = useMemo<OptionSchema[]>(
        () =>
            uniq(lineItems.map(l => l.environment))
                .filter(env => env?.trim()?.length > 0)
                .map(env => ({ label: env, value: env })),
        [lineItems],
    );

    const resOpts = useMemo<OptionSchema[]>(
        () =>
            uniq(getLineItemResolutions(lineItems)).map(res => ({
                label: `${res[0]}x${res[1]}`,
                value: `${res[0]}x${res[1]}`,
            })),
        [lineItems],
    );
    const sizeOpts = useMemo<OptionSchema[]>(
        () =>
            uniq(lineItems.map(l => l.size))
                .filter(s => s?.trim()?.length > 0)
                .map(size => ({ label: size, value: size })),
        [lineItems],
    );
    const ownerOpts = useMemo<OptionSchema[]>(
        () =>
            uniq(lineItems.map(l => l.owner?.name ?? '')).map(name => ({
                label: name,
                value: name,
            })),
        [lineItems],
    );

    const [expandedRowId, setExpandedRowId] = useState<string>(null);
    const [displayedRows, setDisplayedRows] = useState([]);
    const rows = useMemo<ListedLineItem[]>(
        () => lineItems.map(item => lineItemToListedLineItem(item, schedules, t)),
        [lineItems, schedules, t],
    );
    const togglePackDisplay = useCallback(
        rowId => {
            if (expandedRowId === rowId) {
                setDisplayedRows([]);
                setScreens([]);
                setExpandedRowId(null);
            } else {
                setExpandedRowId(rowId);
                packOpened.setTrue();
            }
        },
        [expandedRowId, packOpened, setScreens],
    );
    useEffect(() => {
        if (screens.length > 0) {
            setDisplayedRows(screens.map(item => lineItemToListedLineItem(item, schedules, t)));
            if (packOpened.value) {
                setValue('2');
                packOpened.setFalse();
            }
        } else setDisplayedRows([]);
    }, [packOpened, schedules, screens, t]);
    const { minStartDate, maxEndDate } = useMemo(() => {
        let minDate = new Date();
        let maxDate = new Date(0);
        schedules?.forEach(schedule => {
            schedule.rules?.forEach(rule => {
                const startDate = new Date(rule.start_date);
                const endDate = new Date(rule.end_date);
                if (startDate < minDate) minDate = startDate;
                if (endDate > maxDate) maxDate = endDate;
            });
        });
        return {
            minStartDate: minDate,
            maxEndDate: maxDate,
        };
    }, [schedules]);
    const [dateRange, setDateRange] = useState({
        startDate: minStartDate,
        endDate: maxEndDate,
    });
    useEffect(() => {
        setDateRange({ startDate: minStartDate, endDate: maxEndDate });
    }, [minStartDate, maxEndDate]);
    const handleDateRangeChange = range => {
        setDateRange({ startDate: range[0], endDate: range[1] });
    };
    const expandedRow = useMemo(
        () => rows.find(r => r.id === expandedRowId),
        [expandedRowId, rows],
    );

    useEffect(() => {
        if (expandedRowId == null) {
            setSearch('');
            setEnvironments([]);
            setResolutions([]);
            setSizes([]);
            setOwners([]);
            setScreens([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandedRowId]);
    useEffect(() => {
        if (!rows.some(row => row.id === expandedRowId)) {
            setExpandedRowId(null);
        }
    }, [rows, expandedRowId]);

    useEffect(() => {
        if (expandedRow && !rows.some(row => row.id === expandedRow.id)) {
            setExpandedRowId(null);
        }
    }, [rows, expandedRow]);

    const [detailsDialogRowId, setDetailsDialogRowId] = useState('');
    // const onViewAllSchedules = useCallback((row: ListedLineItem) => {
    //     setDetailsDialogRowId(row.id);
    // }, []);
    // const onCloseViewSchedules = useCallback(() => {
    //     setDetailsDialogRowId(null);
    // }, []);

    // const detailsDialogItem = useMemo(
    //     () => rows.find(r => r.id === detailsDialogRowId),
    //     [detailsDialogRowId, rows],
    // );
    const getFilteredRows = useCallback(() => {
        const filteredItems = rows.filter(row => {
            if (search && !row.name.toLowerCase().includes(search.toLowerCase())) {
                return false;
            }
            if (environments.length > 0 && !environments.includes(row.environment)) {
                return false;
            }
            if (resolutions.length > 0 && !resolutions.some(res => row.resolutions.includes(res))) {
                return false;
            }
            if (sizes.length > 0 && !sizes.includes(row.size)) {
                return false;
            }
            if (owners.length > 0 && !owners.includes(row.owner)) {
                return false;
            }
            return true;
        });
        return filteredItems;
    }, [environments, owners, resolutions, rows, search, sizes]);
    const handleSelectAllClick = useCallback(() => {
        const filteredRows = getFilteredRows();
        const allItems: Item[] = filteredRows.map(row => ({
            id: row.id,
            name: row.name,
            owner: row.owner,
            schedules: row.schedules.map(schedule => schedule.name),
            type: row.type,
        }));

        const allSelected = allItems.every(item =>
            selectedLineItems.some(selectedItem => selectedItem.id === item.id),
        );

        if (allSelected) {
            setSelected((prevSelected: Item[]) =>
                prevSelected.filter(
                    selectedItem => !allItems.some(item => item.id === selectedItem.id),
                ),
            );
        } else {
            setSelected((prevSelected: Item[]) => uniqBy([...prevSelected, ...allItems], 'id'));
        }
    }, [getFilteredRows, selectedLineItems, setSelected]);

    const allItemsSelected = useMemo(() => {
        const filteredRows = getFilteredRows();
        const allItems = filteredRows.map(row => row.id);
        return allItems.every(id => selectedLineItems.some(selectedItem => selectedItem.id === id));
    }, [getFilteredRows, selectedLineItems]);

    const indeterminate = useMemo(
        () => selectedLineItems.length > 0 && !allItemsSelected,
        [allItemsSelected, selectedLineItems.length],
    );
    const {
        start: detachStart,
        isLoading: detachLoading,
        data: detachData,
        reset: detachReset,
        error: detachError,
        requestState: detachRequestState,
    } = useAPIClientRequest(apiClient.Entities.Campaign.detachSchedules);

    const detachScheduleCall = useCallback(async () => {
        const scheduleIds = detach.schedules.map(schedule => schedule.id);
        const data = detach.lineItemId
            ? {
                  line_items: [detach.lineItemId],
                  schedules: scheduleIds,
                  displays: [],
              }
            : {
                  schedules: scheduleIds,
                  displays: [detach.screenId],
                  line_items: [],
              };
        await detachStart(campaignId, data);
    }, [campaignId, detach.lineItemId, detach.schedules, detach.screenId, detachStart]);
    useEffect(() => {
        if (detachRequestState === NetworkRequestState.Success) {
            setDetach({ lineItemId: null, schedules: [], screenId: null });
            detachReset();
            if (expandedRowId) {
                getScreensFromLineItem(expandedRowId, screensCurrentPage, screensPerPage);
            }
            getScheduleLineItems(page, perPage);

            detachState.setFalse();
        }
    }, [
        detachRequestState,
        expandedRowId,
        getScreensFromLineItem,
        screensCurrentPage,
        screensPerPage,
        detachState,
        getScheduleLineItems,
        page,
        perPage,
        currentPage,
        detachReset,
    ]);

    const columns = useMemo<DataGridProProps['columns']>(
        () => [
            {
                field: 'name',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.nameCol'),
                renderHeader: () => (
                    <Stack direction="row" gap={1} alignItems="center">
                        <Checkbox
                            checked={allItemsSelected}
                            indeterminate={indeterminate}
                            onChange={handleSelectAllClick}
                            onClick={e => e.stopPropagation()}
                        />
                        <Box fontWeight="bold">
                            {t('Modules.Main.Campaigns.CampaignSchedule.table.nameCol')}
                        </Box>
                    </Stack>
                ),
                sortable: expandedRowId == null,
                minWidth: 250,
                renderCell: ({ row }) => (
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={2}
                        sx={{ marginLeft: row.type === 'Display' ? 4 : 0 }}
                    >
                        <Checkbox
                            checked={selectedLineItems.some(
                                selectedItem => selectedItem.id === row.id,
                            )}
                            onChange={() =>
                                onSelectLineItems({
                                    id: row.id,
                                    name: row.name,
                                    owner: row.owner,
                                    schedules: row.schedules.map(schedule => schedule.name),
                                    type: row.type,
                                })
                            }
                        />

                        <Stack gap={2}>
                            {row.name}
                            {(row as any).childScreenCount > 1 && (
                                <Box>
                                    {expandedRowId !== row.id && (
                                        <Button
                                            variant="text"
                                            size="small"
                                            onClick={e => {
                                                e.stopPropagation();
                                                togglePackDisplay(row.id);
                                                getScreensFromLineItem(row.id, 1, 25);
                                            }}
                                        >
                                            {t(
                                                'Modules.Main.Campaigns.CampaignSchedule.table.openPack',
                                                {
                                                    count: (row as ListedLineItem).childScreenCount,
                                                },
                                            )}
                                        </Button>
                                    )}
                                    {expandedRowId === row.id && (
                                        <Button
                                            variant="text"
                                            size="small"
                                            onClick={e => {
                                                e.stopPropagation();
                                                closePack(row.id, expandedRowId);
                                                togglePackDisplay(row.id);
                                            }}
                                        >
                                            {t(
                                                'Modules.Main.Campaigns.CampaignSchedule.table.closePack',
                                            )}
                                        </Button>
                                    )}
                                </Box>
                            )}
                        </Stack>
                    </Stack>
                ),
            },
            {
                field: 'owner',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.ownerCol'),
                sortable: true,
                minWidth: 250,
            },
            {
                field: 'frame_id',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.frameIdCol'),
                minWidth: 120,
            },
            {
                field: 'schedules',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.scheduleCol'),
                minWidth: 550,
                renderCell: ({ row }) => {
                    const id = row.id;
                    const type = row.type;
                    const schedules = row.schedules;
                    return (
                        <Box>
                            {row.schedules.map(schedule => (
                                <Chip
                                    key={schedule.id}
                                    onDelete={() => handleDetach(id, type, [schedule])}
                                    onClick={() => setExpandedScheduleId(schedule.id)}
                                    label={schedule.name}
                                />
                            ))}
                            <Button
                                variant="text"
                                onClick={() => handleDetach(id, type, schedules)}
                            >
                                {t('Modules.Main.Campaigns.CampaignSchedule.table.clearAll')}
                            </Button>
                        </Box>
                    );
                },
            },
            {
                field: 'graph',
                minWidth: 10000,
                renderHeader: () => (
                    <GanttChartHeaders
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                    />
                ),
                renderCell: ({ row }) => (
                    <GanttChartBar
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        row={row}
                        t={t}
                    />
                ),
            },
            {
                field: 'environment',
                headerName: '',
            },
            {
                field: 'resolutions',
                headerName: '',
            },
            {
                field: 'size',
                headerName: '',
            },
        ],
        [
            t,
            expandedRowId,
            allItemsSelected,
            indeterminate,
            handleSelectAllClick,
            selectedLineItems,
            onSelectLineItems,
            togglePackDisplay,
            getScreensFromLineItem,
            closePack,
            handleDetach,
            setExpandedScheduleId,
            dateRange.startDate,
            dateRange.endDate,
        ],
    );
    const handleSelectScreen = useCallback(
        (item: Item) => {
            setSelectedScreens(prevSelected => {
                if (prevSelected.some(selectedItem => selectedItem.id === item.id)) {
                    return prevSelected.filter(selectedItem => selectedItem.id !== item.id);
                }
                return [...prevSelected, item];
            });
        },
        [setSelectedScreens],
    );

    const handleSelectAllScreensClick = useCallback(() => {
        const allScreens = displayedRows.map(screen => ({
            id: screen.id,
            name: screen.name,
            owner: screen.owner,
            schedules: screen.schedules.map(schedule => schedule.name),
            type: screen.type,
        }));

        const allSelected = allScreens.every(screen =>
            selectedScreens.some(selectedScreen => selectedScreen.id === screen.id),
        );

        if (allSelected) {
            setSelectedScreens(prevSelectedScreens =>
                prevSelectedScreens.filter(
                    selectedScreen => !allScreens.some(screen => screen.id === selectedScreen.id),
                ),
            );
        } else {
            setSelectedScreens(prevSelectedScreens =>
                uniqBy([...prevSelectedScreens, ...allScreens], 'id'),
            );
        }
    }, [displayedRows, selectedScreens, setSelectedScreens]);

    const allScreensSelected = useMemo(() => {
        const allScreens = displayedRows.map(screen => screen.id);
        return allScreens.every(id =>
            selectedScreens.some(selectedScreen => selectedScreen.id === id),
        );
    }, [displayedRows, selectedScreens]);

    const screensIndeterminate = useMemo(
        () => selectedScreens.length > 0 && !allScreensSelected,
        [allScreensSelected, selectedScreens.length],
    );

    const screenColumns = useMemo<DataGridProProps['columns']>(
        () => [
            {
                field: 'name',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.nameCol'),
                renderHeader: () => (
                    <Stack direction="row" gap={1} alignItems="center">
                        <Checkbox
                            checked={allScreensSelected}
                            indeterminate={screensIndeterminate}
                            onChange={handleSelectAllScreensClick}
                            onClick={e => e.stopPropagation()}
                        />
                        <Box fontWeight="bold">
                            {t('Modules.Main.Campaigns.CampaignSchedule.table.nameCol')}
                        </Box>
                    </Stack>
                ),
                sortable: expandedRowId == null,
                minWidth: 250,
                renderCell: ({ row }) => (
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={2}
                        sx={{ marginLeft: row.type === 'Display' ? 4 : 0 }}
                    >
                        <Checkbox
                            checked={selectedScreens.some(
                                selectedItem => selectedItem.id === row.id,
                            )}
                            onChange={() =>
                                handleSelectScreen({
                                    id: row.id,
                                    name: row.name,
                                    owner: row.owner,
                                    schedules: row.schedules.map(schedule => schedule.name),
                                    type: row.type,
                                })
                            }
                        />

                        <Stack gap={2}>{row.name}</Stack>
                    </Stack>
                ),
            },
            {
                field: 'owner',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.ownerCol'),
                sortable: true,
                minWidth: 250,
            },
            {
                field: 'frame_id',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.frameIdCol'),
                minWidth: 120,
            },
            {
                field: 'schedules',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.table.scheduleCol'),
                minWidth: 550,
                renderCell: ({ row }) => {
                    const id = row.id;
                    const type = row.type;
                    const schedules = row.schedules;
                    return (
                        <Box>
                            {row.schedules.map(schedule => (
                                <Chip
                                    key={schedule.id}
                                    onDelete={() => handleDetach(id, type, [schedule])}
                                    onClick={() => setExpandedScheduleId(schedule.id)}
                                    label={schedule.name}
                                />
                            ))}
                            <Button
                                variant="text"
                                onClick={() => handleDetach(id, type, schedules)}
                            >
                                {t('Modules.Main.Campaigns.CampaignSchedule.table.clearAll')}
                            </Button>
                        </Box>
                    );
                },
            },
            {
                field: 'graph',
                minWidth: 10000,
                renderHeader: () => (
                    <GanttChartHeaders
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                    />
                ),
                renderCell: ({ row }) => (
                    <GanttChartBar
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        row={row}
                        t={t}
                    />
                ),
            },
            {
                field: 'environment',
                headerName: '',
            },
            {
                field: 'resolutions',
                headerName: '',
            },
            {
                field: 'size',
                headerName: '',
            },
        ],
        [
            t,
            expandedRowId,
            allScreensSelected,
            screensIndeterminate,
            handleSelectAllScreensClick,
            selectedScreens,
            handleSelectScreen,
            handleDetach,
            setExpandedScheduleId,
            dateRange.startDate,
            dateRange.endDate,
        ],
    );

    const filterModel = useMemo<DataGridProProps['filterModel']>(
        () => ({
            items: [
                { id: 1, field: 'name', operator: 'contains', value: search },
                {
                    id: 2,
                    field: 'environment',
                    operator: 'isAnyOf',
                    value: environments,
                },
                {
                    id: 3,
                    field: 'resolutions',
                    operator: 'isAnyOf',
                    value: resolutions,
                },
                {
                    id: 3,
                    field: 'resolutions',
                    operator: 'isAnyOf',
                    value: resolutions,
                },
                { id: 4, field: 'size', operator: 'isAnyOf', value: sizes },
                { id: 5, field: 'owner', operator: 'isAnyOf', value: owners },
            ],
        }),
        [search, environments, resolutions, sizes, owners],
    );
    const props = useMemo<Partial<DataGridProProps>>(
        () => ({
            disableColumnResize: false,
            columns,
            rows,
            // rowHeight: 100,
            getRowHeight: () => 'auto',
        }),
        [columns, rows],
    );

    const onSearchChange = useCallback(e => {
        setSearch(e.target.value);
    }, []);

    const toggleColumnVisibility = () => {
        setColumnVisibilityModel(prevModel => ({
            ...prevModel,
            owner: !prevModel.owner,
            schedules: !prevModel.schedules,
            graph: !prevModel.graph,
            frame_id: !prevModel.frame_id,
        }));
    };
    const handleChangeFromDisplaysTab = useCallback(() => {
        setScreens([]);
        setExpandedRowId(null);
        setPrevSelectedLineItemId('');
    }, [setScreens, setPrevSelectedLineItemId]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (screens.length > 0) {
                if (value === '1') {
                    handleChangeFromDisplaysTab();
                }
            }
        }, 100);

        return () => clearTimeout(timer);
    }, [handleChangeFromDisplaysTab, screens.length, value]);
    const detachTitle = useCallback(() => {
        if (detach.lineItemId) {
            const name = rows.find(row => row.id === detach.lineItemId)?.name;
            return t('Modules.Main.Campaigns.CampaignSchedule.detachDialogTitle', { name });
        }
        const name = screens.find(screen => screen.id === detach.screenId)?.name;
        return t('Modules.Main.Campaigns.CampaignSchedule.detachDialogTitle', { name });
    }, [detach.lineItemId, detach.screenId, rows, screens, t]);
    return (
        <>
            <DialogV2
                onClose={handleCloseDetachDialog}
                open={detachState.value}
                title={detachTitle()}
                maxWidth="xl"
            >
                <Box display="flex" flexDirection="column" height="100%">
                    <Box flex={1} overflow="auto">
                        <DetachDialog
                            detach={detach}
                            detachState={detachState}
                            setDetach={setDetach}
                        />
                    </Box>
                    <Box
                        sx={{
                            position: 'sticky',
                            bottom: 0,
                            backgroundColor: 'background.paper',
                            padding: 2,
                            boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
                        }}
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={detachScheduleCall}
                            disabled={detachRequestState === NetworkRequestState.InProgress}
                        >
                            {detachLoading ? (
                                <LoadingSpinner />
                            ) : (
                                t('Modules.Main.Campaigns.CampaignSchedule.detachDialogButton')
                            )}
                        </Button>
                    </Box>
                    {detachError && <Alert severity="error">{String(detachError)}</Alert>}
                </Box>
            </DialogV2>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="Line Item Screen Tabs">
                        <Tab
                            label={t('Modules.Main.Campaigns.CampaignSchedule.lineItems')}
                            value="1"
                        />
                        <Tab
                            label={t('Modules.Main.Campaigns.CampaignSchedule.displays')}
                            value="2"
                            disabled={displayedRows.length === 0}
                        />
                    </TabList>
                </Box>
                {value === '1' && (
                    <Box overflow="scroll">
                        <Box
                            flex={1}
                            height="100%"
                            display="flex"
                            flexDirection="column"
                            overflow="hidden"
                            pt={1}
                        >
                            <AutoGrid gap={2} xs={12} md={4} lg>
                                <TextField
                                    value={search}
                                    size="small"
                                    variant="outlined"
                                    onChange={onSearchChange}
                                    placeholder={t(
                                        'Modules.Main.Campaigns.CampaignSchedule.filters.searchLabel',
                                    )}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search fontSize="inherit" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Filterdropdown
                                    selectAllOption
                                    multiple
                                    options={envsOpts}
                                    onChange={setEnvironments}
                                    value={environments}
                                    placeholder={t(
                                        'Modules.Main.Campaigns.CampaignSchedule.filters.environmentDropdownLabel',
                                    )}
                                />
                                <Filterdropdown
                                    selectAllOption
                                    multiple
                                    options={sizeOpts}
                                    onChange={setSizes}
                                    value={sizes}
                                    placeholder={t(
                                        'Modules.Main.Campaigns.CampaignSchedule.filters.sizeDropdownLabel',
                                    )}
                                />
                                {resOpts.length > 0 && (
                                    <Filterdropdown
                                        selectAllOption
                                        multiple
                                        options={resOpts}
                                        onChange={setResolutions}
                                        value={resolutions}
                                        placeholder={t(
                                            'Modules.Main.Campaigns.CampaignSchedule.filters.resolutionDropdownLabel',
                                        )}
                                    />
                                )}
                                <Filterdropdown
                                    selectAllOption
                                    multiple
                                    options={ownerOpts}
                                    onChange={setOwners}
                                    value={owners}
                                    placeholder={t(
                                        'Modules.Main.Campaigns.CampaignSchedule.filters.ownerDropdownLabel',
                                    )}
                                />
                            </AutoGrid>
                            <Stack
                                direction="row"
                                pt={3}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Switch onChange={toggleColumnVisibility} disabled={loading} />
                                {columnVisibilityModel.graph && (
                                    <DateRangePicker
                                        maxDate={maxEndDate}
                                        minDate={minStartDate}
                                        onChange={handleDateRangeChange}
                                        value={[dateRange.startDate, dateRange.endDate]}
                                    />
                                )}
                            </Stack>

                            <Box flex={1} overflow="hidden" mt={1} display="flex" minHeight="34rem">
                                <DataGridPro
                                    {...(props as DataGridProProps)}
                                    initialState={initialState}
                                    loading={
                                        loading || (displayedRows.length === 0 && screenLoading)
                                    }
                                    filterModel={filterModel}
                                    columnVisibilityModel={columnVisibilityModel}
                                    components={{
                                        Footer: CustomFooter,
                                    }}
                                    componentsProps={{
                                        footer: {
                                            campaignId,
                                        } as GridFooterContainerProps,
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
                {value === '2' && (
                    <Stack gap={1} overflow="scroll">
                        <Typography variant="h3">
                            {rows.find(row => row.id === expandedRowId)?.name}
                        </Typography>
                        <Box flex={1} overflow="hidden" mt={1} display="flex" minHeight="34rem">
                            <DataGridPro
                                columns={screenColumns}
                                rows={displayedRows}
                                initialState={initialState}
                                columnVisibilityModel={columnVisibilityModel}
                                disableColumnResize={false}
                                loading={screenLoading}
                                components={{
                                    Footer: CustomFooter,
                                }}
                                componentsProps={{
                                    footer: {
                                        campaignId,
                                        paginationType: 'screens',
                                    } as GridFooterContainerProps,
                                }}
                            />
                        </Box>
                    </Stack>
                )}
            </TabContext>
        </>
    );
};

export default CampaignScheduleLineItemList;
