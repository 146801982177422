import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NetworkRequest } from '@uniled/data-layer';
import { useHistory } from 'react-router-dom';
import { UserType } from '@uniled/api-sdk';
import {
    startImpersonatingUser,
    stopImpersonatingUser,
} from 'c-auth-module/Slices/Auth/auth-thunks';
import { authSelectors } from 'c-auth-module/Slices';
import useAuthenticatedUser from './useAuthenticatedUser';

function useUserImpersonation() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { user } = useAuthenticatedUser();
    const startLoadingSelector = useSelector(authSelectors.getStartImpersonatingState);
    const stopLoadingSelector = useSelector(authSelectors.getStopImpersonatingState);

    const startImpersonating = useCallback(
        (userId: number, userType: UserType) => {
            dispatch(startImpersonatingUser(userId, userType, history));
        },
        [dispatch, history],
    );

    const stopImpersonating = useCallback(() => {
        dispatch(stopImpersonatingUser(history));
    }, [dispatch, history]);

    const startImpersonatingLoadingState = useMemo<NetworkRequest>(
        () => startLoadingSelector,
        [startLoadingSelector],
    );

    const stopImpersonatingLoadingState = useMemo<NetworkRequest>(
        () => stopLoadingSelector,
        [stopLoadingSelector],
    );

    const isImpersonating = useMemo(() => user != null && user?.impersonatedBy != null, [user]);

    return useMemo(
        () => ({
            // impersonating: user,
            // original: user?.impersonatedBy,
            start: startImpersonating,
            stop: stopImpersonating,
            startImpersonatingLoadingState,
            stopImpersonatingLoadingState,
            isImpersonating,
        }),
        [
            isImpersonating,
            startImpersonating,
            startImpersonatingLoadingState,
            stopImpersonating,
            stopImpersonatingLoadingState,
            // user,
        ],
    );
}

export default useUserImpersonation;
