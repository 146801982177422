import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Box, Divider, Fade, IconButton } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { IconTopBarBrandLogo, MuiRouterLink } from 'c-components';
import { DrawerConfiguration } from 'c-wrapper/Types';
import { usePostAuthHome } from 'c-wrapper/Components/CommonWrapper';
import DrawerNavigationGroup from 'c-wrapper/Components/DrawerNavigationGroup';
import ContentFooter from 'c-wrapper/Components/ContentFooter';
import { makeStyles, useTheme } from '@mui/styles';
import { uiSelectors } from 'c-wrapper/Slices/UI';
import config from 'c-config';
import LoggedInUser from './LoggedInUser';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    nested: {},
    toolbarOpen: {
        justifyContent: 'center',
    },
}));

type Props = {
    onClose: () => void;
    isOpen: boolean;
    mobileDrawer?: boolean;
    navConfig?: DrawerConfiguration;
};

const DrawerDivider = <Divider sx={{ mx: 2 }} />;

const DrawerContent: React.FC<Props> = ({
    onClose,
    isOpen,
    mobileDrawer = false,
    navConfig = config.navigation,
}) => {
    const theme = useTheme();
    const classes = useStyles();
    const open = useSelector(uiSelectors.isDrawerOpen);

    return (
        <>
            <Box
                className={cx(classes.toolbar, { [classes.toolbarOpen]: isOpen })}
                position="relative"
            >
                <Box mx="auto">
                    <MuiRouterLink to={usePostAuthHome()}>
                        <IconTopBarBrandLogo />
                    </MuiRouterLink>
                </Box>

                {!mobileDrawer && (
                    <Box position="absolute" right={theme.spacing(2)}>
                        {open && (
                            <IconButton
                                id="toggle-navigation-drawer"
                                onClick={onClose}
                                color="primary"
                                className="navigation-drawer-open"
                            >
                                <ChevronLeft color="inherit" />
                            </IconButton>
                        )}
                    </Box>
                )}
            </Box>
            <LoggedInUser />

            {DrawerDivider}
            <Box display="flex" justifyContent="space-between" flexDirection="column" height="100%">
                <Box>
                    <MemoizedGroups groups={navConfig.groups} />
                </Box>
                {open && (
                    <Fade in timeout={{ enter: 1200 }}>
                        <Box>
                            <ContentFooter />
                        </Box>
                    </Fade>
                )}
            </Box>
        </>
    );
};

const MemoizedGroups = React.memo<DrawerConfiguration>(({ groups }) => (
    <>
        {Object.entries(groups).map(([, value], index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
                {index > 0 && DrawerDivider}
                <DrawerNavigationGroup items={Object.entries(value.items).map(x => x[1])} />
            </React.Fragment>
        ))}
    </>
));

export default DrawerContent;
