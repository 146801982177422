import { format, parseISO } from 'date-fns';
import { ChatMessage } from './types';
import { dateSort } from 'c-lib';

export const groupByFormat = 'yyyyMMdd';
export const groupMessages = (messages: ChatMessage[]) =>
    messages?.reduce((accumulator, message) => {
        const date = format(parseISO(message.createdAt), groupByFormat);
        return {
            ...accumulator,
            [date]: [...(accumulator[date] ?? []), message].sort((a, b) =>
                dateSort(a.createdAt, b.createdAt, 'asc'),
            ),
        };
    }, {} as { [date: string]: ChatMessage[] }) ?? {};
