import React from 'react';
import { formatNumber } from 'c-lib';

type Props = {
    value: number | string;
    intlOptions?: Intl.NumberFormatOptions;
};

// TODO: choose currency based on logged in user somehow

const CurrencyFormat: React.FC<Props> = ({
    value,
    intlOptions = { minimumFractionDigits: 0, maximumFractionDigits: 2 },
}) => {
    const isNegative = value < 0;
    return (
        <>
            {isNegative && '-'}£{formatNumber(Math.abs(+value), intlOptions)}
        </>
    );
};

export default CurrencyFormat;
