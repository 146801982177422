import {
    ParsedSystemSearchConfiguration,
    SearchResultStatus,
    SystemSearchEntityResource,
    SystemSearchResource,
    SystemSearchSearchResult,
} from '../types';
import searchParts from './searchParts';

function localSearch(
    config: ParsedSystemSearchConfiguration,
    searchTerm: string,
): SystemSearchSearchResult {
    const parts = searchParts(searchTerm);
    const shortcuts = Object.keys(config.prefixes);
    const aliases = Object.keys(config.aliases);

    const response: SystemSearchSearchResult = {
        results: [],
        status: SearchResultStatus.Success,
        entities: [],
        ...parts,
    };

    if (parts.term === '' && parts.prefix === '') {
        return response;
    }

    const searchTermLower = parts.term.toLowerCase();

    if (parts.prefix === '') {
        // find all results which match the alias
        const resources: SystemSearchResource[] = [];
        const addedRoutes: string[] = [];
        aliases
            .filter(a => a.indexOf(searchTermLower) !== -1)
            .forEach(alias => {
                config.aliases[alias].forEach(res => {
                    // allow multiple widgets for the same page route get added
                    const route = `${res.route}.${res.type}-${res.title}`;
                    if (addedRoutes.indexOf(route) === -1) {
                        addedRoutes.push(route);
                        resources.push(res);
                    }
                });
            });

        response.results = resources;
    }
    if (parts.prefix.length > 0 && shortcuts.indexOf(parts.prefix) !== -1) {
        // get all resources for the prefix
        const resources = config.prefixes[parts.prefix];

        if (parts.term.length > 0) {
            // a search term has also been entered
            // filter results matching shortcut to only match on alias
            response.results = resources.filter(res => {
                return (
                    // when an entity page is matched we always want to return it so an API search can be done against it
                    res.type === 'entity' ||
                    res.aliases
                        .map(a => a.toLowerCase())
                        .filter(a => a.indexOf(searchTermLower) !== -1).length > 0
                );
            });
        } else {
            response.results = resources;
        }
    }

    if (shortcuts.indexOf(parts.prefix) === -1 && config.entityAlways == null) {
        response.status = SearchResultStatus.InvalidShortcut;
        return response;
    }

    response.entities = response.results.filter(
        res => res.type === 'entity',
    ) as SystemSearchEntityResource[];

    if (
        response.entities.length === 0 &&
        parts.prefix?.trim() == '' &&
        config.entityAlways != null
    ) {
        response.entities.push(config.entityAlways);
    }
    response.results = response.results.filter(res => res.type === 'page');

    return response;
}

export default localSearch;
