import React, { useEffect } from 'react';
import { CardContent } from '@mui/material';
import { PermissionName } from '@uniled/api-sdk';
import { TransparentCard } from 'c-components';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import { adminPanelIcon } from 'c-main/icons';
import ReactGA from 'react-ga';
import PopDisplaysAdminTiles from '../Components/PopDisplaysAdminTiles';

const PopDisplaysHomePage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <TransparentCard title={useCommonTranslation('Modules.PopDisplays.AdminHome.metaTitle')}>
            <CardContent>
                <PopDisplaysAdminTiles />
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageConfig = {
    id: 'PopDisplaysHomePage',
    component: PopDisplaysHomePage,
    systemSearch: {
        title: 'Modules.PopDisplays.AdminHome.metaTitle',
        description: 'Modules.PopDisplays.AdminHome.description',
        type: 'page',
        icon: adminPanelIcon,
        aliases: 'SystemSearch.aliases.popDisplaysAdmin',
        route: PostAuthRoutes.PopDisplays.PopDisplaysHome,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            { label: 'Modules.PopDisplays.AdminHome.metaTitle' },
        ],
        permissions: [PermissionName.Admin],
    },
};

export default setup;
