import React, { FocusEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/styles';
// import sanitize from 'sanitize-html';
import { ReactQuillProps } from 'react-quill';
import { PermissionName, Campaign } from '@uniled/api-sdk';
import { Box, Tooltip, Typography } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { RenderColumnDataProps } from 'c-pagination';
import { WarningAmber } from '@mui/icons-material';
import { useEntityData, useUpdateEntityData } from 'c-data';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { useBoolean, usePrevious } from 'react-hanger';
import UpdatedByUserCaption from 'c-components/UpdatedByUserCaption';
import { AutoGrid, LoadingSpinner, RichTextEditor } from 'c-components';
import { NetworkRequestState } from '@uniled/data-layer';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import RichTextEditorPreview from '../../../../../Components/RichTextEditor/RichTextEditorPreview';

type Props = RenderColumnDataProps<Campaign>;

const adminPerms = [PermissionName.Admin];

// const sanitizeText = (text: string) =>
//     replace(
//         // quill editor loves using <br> where as sanitizer changes it to <br />
//         // which completely screws things up and causes quill to add extra line breaks
//         /<br \/>/g,
//         '<br>',
//         sanitize(text ?? '', {
//             allowedAttributes: { '*': ['style'] },
//         }),
//     );
const sanitizeText = (text: string) => text;

const CampaignNotesCell: React.FC<Props> = ({ baseEntity }) => {
    const t = useCommonTranslation();
    const {
        requestStateRef,
        start: update,
        isLoading,
        hasFailed,
        error,
    } = useAPIClientRequest(apiClient.Entities.Campaign.update);
    const { getById } = useEntityData<Campaign>('Campaign');
    const { upsert } = useUpdateEntityData<Campaign>('Campaign');
    const campaign = getById({ id: baseEntity.id });

    const originalSanitized = useMemo(() => sanitizeText(campaign.internal_notes), [campaign]);
    const { value: IsEditing, setTrue: StartEditing, setFalse: StopEditing } = useBoolean(false);

    const prevLoading = usePrevious(isLoading);
    useEffect(() => {
        if (!isLoading && prevLoading) {
            StopEditing();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevLoading, isLoading]);

    const onSubmit = useCallback(
        async (newValue: string) => {
            if (originalSanitized !== newValue) {
                if (requestStateRef.current === NetworkRequestState.InProgress) return;

                const [, succ] = await to(
                    update(campaign.id, {
                        id: campaign.id,
                        internal_notes: newValue,
                        internal_notes_updated_at: campaign.internal_notes_updated_at,
                    }),
                );

                if (succ?.data?.data?.id) {
                    upsert(succ?.data?.data as unknown as Campaign);
                }
            } else {
                StopEditing();
            }
        },
        [
            originalSanitized,
            requestStateRef,
            update,
            campaign.id,
            campaign.internal_notes_updated_at,
            upsert,
            StopEditing,
        ],
    );

    const id = useMemo(() => `campaign-edit-notes-${campaign.id}`, [campaign.id]);

    const contentPreview = useMemo(
        () => <RichTextEditorPreview content={originalSanitized} />,
        [originalSanitized],
    );
    const contentEdit = useMemo(
        () => (
            <Box
                onClick={StartEditing}
                sx={{
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: 'grey.600',
                    p: 2,
                    borderRadius: 1,
                    cursor: 'text',
                    minWidth: 450,
                }}
            >
                {contentPreview}
            </Box>
        ),
        [contentPreview, StartEditing],
    );

    const editorContent = useMemo(
        () => (
            <IfHasAllPermissions permissions={adminPerms} otherwise={contentPreview}>
                {IsEditing ? (
                    <RTE
                        content={originalSanitized}
                        id={id}
                        onSubmit={onSubmit}
                        isLoading={isLoading}
                    />
                ) : (
                    contentEdit
                )}
            </IfHasAllPermissions>
        ),
        [contentPreview, IsEditing, originalSanitized, id, onSubmit, isLoading, contentEdit],
    );

    return (
        <>
            {hasFailed && (
                <Tooltip title={String(error)} placement="top-start">
                    <Box>
                        <AutoGrid columnGap={0.5} justifyContent="flex-start">
                            <Box display="flex">
                                <WarningAmber fontSize="inherit" color="error" />
                            </Box>
                            <Typography variant="caption" color="error" component="div">
                                {t('Modules.Main.Campaigns.DataStatus.table.noteFailedSave')}
                            </Typography>
                        </AutoGrid>
                    </Box>
                </Tooltip>
            )}
            {editorContent}
            {campaign?.internal_notes_updated_by != null && (
                <Box mt={2} textAlign="left">
                    <UpdatedByUserCaption
                        justifyContent="flex-start"
                        userId={campaign.internal_notes_updated_by}
                        updatedAt={campaign.internal_notes_updated_at}
                    />
                </Box>
            )}
        </>
    );
};

type RTEProps = {
    id: string;
    content: string;
    onSubmit: (newValue: string) => void;
    isLoading: boolean;
};
const RTE: React.FC<RTEProps> = ({ id, content, onSubmit, isLoading }) => {
    const theme = useTheme();

    const [localValue, setLocalValue] = useState(content);
    const onQuillChange = useCallback<ReactQuillProps['onChange']>(value => {
        setLocalValue(value);
    }, []);

    useEffect(() => {
        setLocalValue(curr => {
            const newContent = sanitizeText(content);
            return newContent !== curr ? newContent : curr;
        });
    }, [content]);

    const onBlur = useCallback<ReactQuillProps['onBlur']>(
        (e, source) => {
            // source "silent" happens when you paste something into the editor
            if (source !== 'silent') onSubmit(sanitizeText(String(localValue)));
        },
        [localValue, onSubmit],
    );
    const onBlurBox = useCallback<FocusEventHandler>(
        e => {
            // when you paste something into quill, the clipboard HTML element triggers a
            // bubbling event for blurring away from the quill editor. God knows why.
            // we want to let users paste into the quill editor without the blur (unfocus) happening
            if (
                !e?.relatedTarget?.classList?.contains('ql-clipboard') &&
                !e?.target?.classList?.contains('ql-clipboard')
            ) {
                onSubmit(sanitizeText(String(localValue)));
            } else {
                e.preventDefault();
                e.stopPropagation();
            }
        },
        [localValue, onSubmit],
    );

    // https://quilljs.com/docs/modules/toolbar/
    const modules = useMemo(
        () => ({
            toolbar: [
                [
                    // { header: [1, 2, 3, 4, 5, 6] },
                    'bold',
                    'italic',
                    'underline',
                    // 'strike',
                    {
                        color: [
                            'black',
                            theme.palette.success.main,
                            theme.palette.warning.main,
                            theme.palette.error.main,
                        ],
                    },
                    // { list: 'ordered' },
                    // { list: 'bullet' },
                ],
            ],
        }),
        [theme.palette.error.main, theme.palette.success.main, theme.palette.warning.main],
    );

    const formats = useMemo(
        () => [
            'bold',
            'color',
            'code',
            'italic',
            'size',
            'strike',
            'script',
            'underline',
            'indent',
            // 'list',
            // 'header',
        ],
        [],
    );
    return (
        <>
            <Box
                onBlur={onBlurBox}
                minWidth={450}
                minHeight={150}
                display="flex"
                bgcolor="white"
                position="relative"
            >
                {isLoading && <LoadingSpinner />}
                <RichTextEditor
                    id={id}
                    autoFocus
                    modules={modules}
                    value={localValue}
                    onChange={onQuillChange}
                    onBlur={onBlur}
                    readOnly={isLoading}
                    formats={formats}
                />
            </Box>
        </>
    );
};

export default CampaignNotesCell;
