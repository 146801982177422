import React, { useMemo } from 'react';
import { difference } from 'ramda';
import { TranslationPath, useCommonTranslation } from 'c-translation';
import { Box, Divider, ListItem, TypographyProps } from '@mui/material';
import { EntityField, ListItemText } from 'c-components';
import { Displays_LineItem } from '@uniled/api-sdk';
import useSelectedLineItems from '../../CampaignLineItems/CampaignLineItemPicker/useSelectedLineItems';

const SummaryDisplaysDiff = () => {
    const { originalLineItems, value: SelectedLineItems } = useSelectedLineItems();

    const added = useMemo(
        () => difference(SelectedLineItems, originalLineItems),
        [SelectedLineItems, originalLineItems],
    );

    const removed = useMemo(
        () => difference(originalLineItems, SelectedLineItems),
        [SelectedLineItems, originalLineItems],
    );

    return (
        <>
            {added.length > 0 && (
                <>
                    <Diff
                        title="Modules.Main.Campaigns.EditLineItems.lineItemsAdded"
                        ids={added}
                        nameColor="success.main"
                        namePrefix="+"
                    />
                    <Divider />
                </>
            )}
            {removed.length > 0 && (
                <>
                    <Diff
                        title="Modules.Main.Campaigns.EditLineItems.lineItemsRemoved"
                        ids={removed}
                        nameColor="error.main"
                        namePrefix="-"
                    />
                    <Divider />
                </>
            )}
        </>
    );
};

const Diff: React.FC<{
    ids: number[];
    title: TranslationPath;
    nameColor: TypographyProps['color'];
    namePrefix: string;
}> = ({ ids, title, nameColor, namePrefix }) => {
    const t = useCommonTranslation();

    return (
        <ListItem disableGutters disablePadding>
            <ListItemText
                primary={
                    <Box display="flex" alignItems="center">
                        {t(title, {
                            count: ids.length,
                        })}
                    </Box>
                }
                secondary={
                    <>
                        {ids.map(id => (
                            <Box key={id}>
                                {`${namePrefix} `}
                                <EntityField<Displays_LineItem>
                                    id={id}
                                    entityName="Displays_LineItem"
                                    field="name"
                                />
                            </Box>
                        ))}
                    </>
                }
                secondaryTypographyProps={{ component: Box as any, color: nameColor }}
            />
        </ListItem>
    );
};

export default SummaryDisplaysDiff;
