import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import {
    FilterableEntityTable,
    FilterableEntityTableRefAPI,
    FilterableEntityTableRendererType,
    generateColumn,
} from 'c-pagination';
import apiClient from 'c-data/apiClient';
import { useEntityData, usePaginatedEntityData } from 'c-data';
import { PermissionName, UserType, Displays_LineItem, Campaign } from '@uniled/api-sdk';
import { useCommonTranslation } from 'c-translation';
import { useAPIClientRequest } from 'c-hooks';
import to from 'await-to-js';
import { useAtom, useAtomValue } from 'jotai';
import { usePrevious } from 'react-hanger';
import { atom_campaignUploadArtworkOpen } from 'c-main/Pages/Campaign/CampaignOverviewPage/atoms';
import useCampaignCreativesData from 'c-main/Components/CreativeManagement/useCampaignCreativesData';
import LineItemCreativeAssignDialog from './AssignDialog/LineItemCreativeAssignDialog';
import LineItemCreativeUnAssignDialog from './AssignDialog/LineItemCreativeUnAssignDialog';
import { LineItemEndDateCell, LineItemStartDateCell } from './LineItemDatesCells';
import LineItemNameWithErrorsCell from './LineItemNameWithErrorsCell';
import LineItemsControlsCell from './LineItemsControlsCell';
import LineItemsAssignedCreativesCell from './LineItemsAssignedCreativesCell';
import LineItemsAvailableCreativesCell from './LineItemsAvailableCreativesCell';
import CampaignDisplaysControlsHeader from './CampaignDisplaysControlsHeader';
import LineItemResolutionsCell from './LineItemResolutionsCell';
import LineItemPopShotsCell from './LineItemPopShotsCell';
import LineItemFileTypesCell from './LineItemFileTypesCell';
import LineItemOwnerCell from './LineItemOwnerCell';
import {
    atom_forceAssignAvailableCreativesLineItemIdDialog,
    atom_forceAssignLineItemIdDialog,
    atom_forceUnAssignLineItemIdDialog,
} from './atoms';
import { generateCustomFilterConfig, ReadAssignPerms, ReadAvailableAssignPerms } from './constants';

type Props = {
    id: number;
};

const lineItemIncludes = [
    'owner',
    'creativesCount',
    'availableCreativesCount',
    'creatives',
    'resolutions',
    'fileTypes',
];

export const tableCols = [
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.ownerHeader',
        field: 'owner.name',
        entityName: 'Displays_LineItem',
        filterKeyPath: 'owner.id',
        permissions: [PermissionName.UniledportalOwnerRead],
        RenderCell: LineItemOwnerCell as any,
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.environmentHeader',
        field: 'environment',
        entityName: 'Displays_LineItem',
        // orderable: true,
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.nameHeader',
        field: 'name',
        entityName: 'Displays_LineItem',
        orderable: true,
        RenderCell: LineItemNameWithErrorsCell,
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.sizeHeader',
        field: 'size',
        entityName: 'Displays_LineItem',
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.resolutionHeader',
        field: 'resolutions',
        entityName: 'Displays_LineItem',
        RenderCell: LineItemResolutionsCell,
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.assignedCreativesHeader',
        field: 'creativesCount',
        entityName: 'Displays_LineItem',
        RenderCell: LineItemsAssignedCreativesCell,
        permissions: ReadAssignPerms,
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.creativesAvailableHeader',
        field: 'availableCreativesCount',
        entityName: 'Displays_LineItem',
        RenderCell: LineItemsAvailableCreativesCell,
        permissions: ReadAvailableAssignPerms,
        userTypes: [UserType.Admin, UserType.Buyer, UserType.Agency],
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.fileTypesHeader',
        field: 'fileTypes',
        entityName: 'Displays_LineItem',
        RenderCell: LineItemFileTypesCell,
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.startDateHeader',
        field: 'start_date',
        entityName: 'Displays_LineItem',
        RenderCell: LineItemStartDateCell,
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.endDateHeader',
        field: 'end_date',
        entityName: 'Displays_LineItem',
        RenderCell: LineItemEndDateCell,
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.actionsHeader',
        field: 'actions',
        entityName: 'Displays_LineItem',
        RenderCell: LineItemsControlsCell,
        RenderHeader: CampaignDisplaysControlsHeader,
    }),
];

const textSearchColumns = ['name'];
const tableProps = { spacedOutRows: true };
const CampaignOverviewDisplays: React.FC<Props> = ({ id }) => {
    const paginationTag = useMemo(() => `campaign-displays-${id}`, [id]);

    const lineItemsSearch = useCallback(
        payload => apiClient.Entities.Campaign.lineItems(id, payload),
        [id],
    );
    const { paginatedData } = usePaginatedEntityData(paginationTag, 'Displays_LineItem');
    const creativeGroupIdFilters = useMemo<number[]>(
        () => (paginatedData?.filters?.creativeGroups as number[]) ?? [],
        [paginatedData],
    );

    const totalItems = useMemo(() => paginatedData?.meta?.pagination?.total, [paginatedData]);
    const t = useCommonTranslation();
    const header = useMemo(
        () =>
            t('Modules.Main.Campaigns.Overview.Displays.displayCountHeader', {
                count: totalItems ?? '',
            }),
        [t, totalItems],
    );

    const [forceAssignLineItemIdDialog, setForceAssignLineItemIdDialog] = useAtom(
        atom_forceAssignLineItemIdDialog,
    );
    const [forceUnAssignLineItemIdDialog, setForceUnAssignLineItemIdDialog] = useAtom(
        atom_forceUnAssignLineItemIdDialog,
    );

    const [
        forceAssignAvailableCreativesLineItemIdDialog,
        setForceAssignAvailableCreativesLineItemIdDialog,
    ] = useAtom(atom_forceAssignAvailableCreativesLineItemIdDialog);

    const closeForceAssignDialog = useCallback(() => {
        setForceAssignLineItemIdDialog('');
    }, [setForceAssignLineItemIdDialog]);

    const closeForceUnAssignDialog = useCallback(() => {
        setForceUnAssignLineItemIdDialog('');
    }, [setForceUnAssignLineItemIdDialog]);

    const closeForceAssignAvailableCreativesDialog = useCallback(() => {
        setForceAssignAvailableCreativesLineItemIdDialog('');
    }, [setForceAssignAvailableCreativesLineItemIdDialog]);

    const { getById } = useEntityData<Displays_LineItem>('Displays_LineItem');

    const forceAssignLineItem = useMemo(
        () =>
            forceAssignLineItemIdDialog !== ''
                ? getById({ id: forceAssignLineItemIdDialog as any })
                : null,
        [forceAssignLineItemIdDialog, getById],
    );
    const forceUnAssignLineItem = useMemo(
        () =>
            forceUnAssignLineItemIdDialog !== ''
                ? getById({ id: forceUnAssignLineItemIdDialog as any })
                : null,
        [forceUnAssignLineItemIdDialog, getById],
    );
    const forceAssignAvailableCreativesLineItem = useMemo(
        () =>
            forceAssignAvailableCreativesLineItemIdDialog !== ''
                ? getById({ id: forceAssignAvailableCreativesLineItemIdDialog as any })
                : null,
        [forceAssignAvailableCreativesLineItemIdDialog, getById],
    );

    const { creativeData, setCreativeData, getCreatives } = useCampaignCreativesData(id);

    useEffect(() => {
        getCreatives();
        return () => {
            closeForceAssignDialog();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { upsertEntity: upsertCampaign } = useEntityData<Campaign>('Campaign');

    const { start: Revalidate } = useAPIClientRequest(apiClient.Entities.Campaign.validate);
    const ref = useRef<FilterableEntityTableRefAPI>();
    const reValidateCampaign = useCallback(async () => {
        ref.current?.search();
        const [err, response] = await to(Revalidate(id, ['errors']));

        if (!err && response?.data?.data?.id === id) {
            upsertCampaign(response.data.data as unknown as Campaign);
        }
    }, [Revalidate, id, upsertCampaign]);

    const artworkDialogOpen = useAtomValue(atom_campaignUploadArtworkOpen);
    const prevArtworkDialogOpen = usePrevious(artworkDialogOpen);

    useEffect(() => {
        if (prevArtworkDialogOpen === true && !artworkDialogOpen) {
            ref.current?.search();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [artworkDialogOpen]);

    const customFilters = useMemo(() => generateCustomFilterConfig(creativeData), [creativeData]);

    return (
        <Box mb={2}>
            {forceAssignLineItem != null && (
                <LineItemCreativeAssignDialog
                    open
                    campaignId={id}
                    lineItem={forceAssignLineItem}
                    onClose={closeForceAssignDialog}
                    creatives={creativeData}
                    setCampaignCreatives={setCreativeData}
                    reValidateCampaign={reValidateCampaign}
                    creativeGroupsFilter={creativeGroupIdFilters}
                />
            )}
            {forceAssignAvailableCreativesLineItem != null && (
                <LineItemCreativeAssignDialog
                    open
                    onlyAllowAvailableCreatives
                    campaignId={id}
                    lineItem={forceAssignAvailableCreativesLineItem}
                    onClose={closeForceAssignAvailableCreativesDialog}
                    creatives={creativeData}
                    setCampaignCreatives={setCreativeData}
                    reValidateCampaign={reValidateCampaign}
                    creativeGroupsFilter={creativeGroupIdFilters}
                />
            )}
            {forceUnAssignLineItem != null && (
                <LineItemCreativeUnAssignDialog
                    open
                    campaignId={id}
                    lineItem={forceUnAssignLineItem}
                    onClose={closeForceUnAssignDialog}
                    creatives={creativeData}
                    setCampaignCreatives={setCreativeData}
                    reValidateCampaign={reValidateCampaign}
                    creativeGroupsFilter={creativeGroupIdFilters}
                />
            )}
            <Card elevation={0}>
                <CardContent>
                    <Box px={2} display="flex" justifyContent="space-between">
                        <Typography variant="h3" color="textSecondary">
                            {header}
                        </Typography>
                    </Box>

                    <Box maxHeight="75vh" display="flex" overflow="hidden">
                        <FilterableEntityTable
                            ref={ref}
                            tag={paginationTag}
                            columns={tableCols}
                            baseEntityName="Displays_LineItem"
                            customListSearch={lineItemsSearch}
                            showFilters
                            textSearchColumns={textSearchColumns}
                            includes={lineItemIncludes}
                            tableProps={tableProps}
                            rendererType={FilterableEntityTableRendererType.Contained}
                            // textSearchColumn={["name"]}
                            customFilterConfig={customFilters}
                        />
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default CampaignOverviewDisplays;
