import {
    callApi,
    errorMessageFromResponse,
    logout,
    NetworkRequestState,
    normaliseDataThunks,
    resetEntities,
} from '@uniled/data-layer';
import { Skin, User } from 'c-entity-types';
import apiClient from 'c-data/apiClient';
import { commonSchemas } from 'c-data/schemas';
import { User as APIUser, UserType } from '@uniled/api-sdk';
import { CommonThunk } from 'c-types';
import { uiThunks } from 'c-wrapper/Slices/UI';
import { History } from 'history';
import { PostAuthRoutes } from 'c-routes';
import {
    getStartImpersonatingState,
    getStopImpersonatingState,
} from 'c-auth-module/Slices/Auth/auth-selectors';
import { currentSkin } from 'c-wrapper/Slices/UI/ui-selectors';
import { getPreImpersonateSkin, storePreImpersonateSkin } from 'c-lib';
import { authActions } from './auth-slice';

export const login =
    ({
        email,
        password,
        userType,
        isMobile,
    }: {
        email: string;
        password: string;
        isMobile: boolean;
        userType?: UserType;
    }): CommonThunk =>
    async dispatch => {
        dispatch(authActions.startLogin());
        const [error, data] = await dispatch(
            callApi(apiClient.Auth.login({ email, password, userType }).request),
        );
        if (error || data?.data?.user == null) {
            dispatch(authActions.failedLogin(errorMessageFromResponse(error)));
            return;
        }

        // if a user type has been chosen and set by the API then we can
        if (data.data.credentials?.chosen_user_type != null && data?.data?.user != null) {
            data.data.user.chosenUserType = data.data.credentials.chosen_user_type;

            dispatch(finishLogin(data?.data?.user, isMobile));
        } else if (data?.data?.user?.availableUserTypes?.length > 1) {
            // make the user choose an available login type
            dispatch(authActions.setAvailableLoginTypes(data.data.user.availableUserTypes));
        } else {
            dispatch(authActions.failedLogin(null));
        }
    };

export const finishLogin =
    (user: User, isMobile: boolean): CommonThunk =>
    async dispatch => {
        dispatch(uiThunks.setPreviewThemeOnLogin(user?.skins?.[0] as unknown as Skin));

        dispatch(normaliseDataThunks.updateWithNormalisedData(commonSchemas.User, user));
        dispatch(
            authActions.finishedLogin({
                user: user as unknown as User,
                isMobile,
            }),
        );
    };

export const validateUserSession =
    (isMobile: boolean): CommonThunk =>
    async dispatch => {
        dispatch(authActions.startValidatingSession());
        const [error, data] = await dispatch(callApi(apiClient.Entities.User.me().request));

        if (!error) {
            dispatch(authActions.finishedValidatingSession({ user: data.data as unknown as User }));
            dispatch(finishLogin(data?.data, isMobile));
            return;
        }
        dispatch(authActions.failedValidatingSession());
    };

export const resetLoginState = (): CommonThunk => async dispatch => {
    dispatch(authActions.resetLogin());
};

export const logoutLoggedInUser = (): CommonThunk => async dispatch => {
    dispatch(authActions.startLogout());
    await dispatch(callApi(apiClient.Auth.logout().request));

    dispatch(authActions.finishedLogout());
    dispatch(logout());
};

export const startImpersonatingUser =
    (userId: number, userType: UserType, history: History): CommonThunk =>
    async (dispatch, getState) => {
        if (getStartImpersonatingState(getState()).state === NetworkRequestState.InProgress) {
            return;
        }

        dispatch(authActions.startImpersonatingUser());

        const [error] = await dispatch(
            callApi(apiClient.Auth.startImpersonating(userId, userType).request),
        );

        if (error) {
            dispatch(authActions.failedImpersonatingUser(errorMessageFromResponse(error)));
            return;
        }

        storePreImpersonateSkin(currentSkin(getState()));
        dispatch(
            postImpersonation(true, history, errorUserMe =>
                dispatch(
                    authActions.failedImpersonatingUser(errorMessageFromResponse(errorUserMe)),
                ),
            ),
        );
        dispatch(authActions.finishedImpersonatingUser());
    };

export const stopImpersonatingUser =
    (history: History): CommonThunk =>
    async (dispatch, getState) => {
        if (getStopImpersonatingState(getState()).state === NetworkRequestState.InProgress) {
            return;
        }

        dispatch(authActions.startStopImpersonatingUser());

        const [error] = await dispatch(callApi(apiClient.Auth.stopImpersonating().request));

        if (error) {
            // don't care, just logout instead.
            dispatch(logoutLoggedInUser());
            return;
        }

        dispatch(postImpersonation(false, history, () => dispatch(logoutLoggedInUser())));
        dispatch(authActions.finishedStopImpersonatingUser());
    };

const setUserSkin =
    (user: APIUser): CommonThunk =>
    async dispatch => {
        if (user?.skins?.length > 0) {
            dispatch(uiThunks.setPreviewTheme(user?.skins?.[0] as unknown as Skin, true));
        } else {
            dispatch(uiThunks.setPreviewTheme(null));
        }
    };

const postImpersonation =
    (started: boolean, history: History, onFailedUserMe: (error) => void): CommonThunk =>
    async dispatch => {
        const [errorUserMe, dataUserMe] = await dispatch(
            callApi(apiClient.Entities.User.me().request),
        );

        if (errorUserMe) {
            onFailedUserMe(errorUserMe);
            return;
        }

        let clonedUser = {};

        try {
            clonedUser = JSON.parse(JSON.stringify(dataUserMe?.data));
            // eslint-disable-next-line no-empty
        } catch (e) {}

        dispatch(
            authActions.finishedValidatingSession({ user: dataUserMe.data as unknown as User }),
        );

        dispatch(resetEntities());
        dispatch(normaliseDataThunks.updateWithNormalisedData(commonSchemas.User, dataUserMe.data));

        /**
         * Just doing a timeout delay to let the UI update and let users know the impersonation has been successful
         * before actually doing the switch over
         */
        setTimeout(() => {
            if (!started) {
                dispatch(uiThunks.setPreviewTheme(getPreImpersonateSkin()));
            } else dispatch(setUserSkin(clonedUser as APIUser));

            history.push(PostAuthRoutes.Dashboard.Dashboard);
            dispatch(authActions.resetStartImpersonatingUser());
            dispatch(authActions.resetStopImpersonatingUser());
        }, 1000);
    };
