import { SlicePaginationData } from '@uniled/data-layer';
import { EntityListColumn } from 'c-pagination/types';
import { AllEntities, BaseEntity } from '@uniled/api-sdk';

const searchPrefix = 'search.';
const filterPrefix = 'filter.';

const columnPaginationFilterOrSearchValue = (
    paginationData: SlicePaginationData<any>,
    column: EntityListColumn<any>,
    baseEntityName: keyof AllEntities,
    columnTextValue?: string,
    translatedColumnTextValue?: string,
    entity?: BaseEntity,
): string => {
    // when a user searches a paginated entity table we want to highlight why a row was matched
    // will try and extract text for the column render to highlight
    // by looking at the applied filters or the free text search value(s)

    const relationFilterValue = paginationData?.filters?.[`${filterPrefix}${column.filterKeyPath}`];
    // if a found via a relationship ID search
    if (
        Array.isArray(relationFilterValue) &&
        (relationFilterValue as number[]).indexOf(entity?.id) !== -1
    ) {
        return entity?.[column.field];
    }

    const fieldFilterValue = paginationData?.filters?.[`${filterPrefix}${column.field}`];
    // if the column's text value matches one of the filter values on its field
    if (
        Array.isArray(fieldFilterValue) &&
        columnTextValue != null &&
        columnTextValue != '' &&
        (fieldFilterValue as any[]).indexOf(columnTextValue) != -1
    ) {
        /**
         * return the translated text value, as it is the text that'll be rendered and needs to be highlighted.
         * The original untranslated string is what is originally used for comparison
         */
        return translatedColumnTextValue;
    }

    if (
        column.entityName === baseEntityName &&
        paginationData?.searchables?.[`${searchPrefix}${column.field}`]
    ) {
        return String(paginationData?.searchables?.[`${searchPrefix}${column.field}`] ?? '');
    }

    return '';
};

export default columnPaginationFilterOrSearchValue;
