import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { taskQueueIcon } from 'c-main/icons';
import { PermissionName, PopDisplays_JobStatus } from '@uniled/api-sdk';
import {
    FilterableEntityTable,
    FilterableEntityTableRefAPI,
    FilterableEntityTableRendererType,
    generateColumn,
    RenderRowProps,
} from 'c-pagination';
import { AutoGrid, Button, CountdownProgressCircle } from 'c-components';
import { useEntityData } from 'c-data';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import ReactGA from 'react-ga';

import ExpandableHtmlContent from 'c-admin/Pages/TaskQueuePage/ExpandableHtmlContent';

import { useCommonTranslation } from 'c-translation';
import DurationCol from './DurationCol';
import StopTaskCol from './StopTaskCol';
import { isErrorNote, isSuccessNote } from './lib';

const columns = [
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.idCol',
        field: 'id',
        entityName: 'PopDisplays_JobStatus',
        orderable: true,
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.nameCol',
        field: 'name',
        entityName: 'PopDisplays_JobStatus',
        RenderCell: ({ entity }) => <ExpandableHtmlContent contentHtml={entity.name} />,
        orderable: true,
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.createdCol',
        field: 'created_at',
        entityName: 'PopDisplays_JobStatus',
        dataType: 'date',
        dateProps: {
            showTime: true,
        },
        orderable: true,
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.startedAtCol',
        field: 'started_at',
        entityName: 'PopDisplays_JobStatus',
        dataType: 'date',
        dateProps: {
            showTime: true,
        },
        orderable: true,
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.completedAtCol',
        field: 'completed_at',
        entityName: 'PopDisplays_JobStatus',
        dataType: 'date',
        dateProps: {
            showTime: true,
        },
        orderable: true,
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.durationCol',
        field: 'duration',
        entityName: 'PopDisplays_JobStatus',
        RenderCell: DurationCol,
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: 'Modules.PopDisplays.JobStatus.table.statusCol',
        field: 'notes',
        entityName: 'PopDisplays_JobStatus',
        RenderCell: ({ entity }) => <ExpandableHtmlContent contentHtml={entity.notes} />,
    }),
    generateColumn<PopDisplays_JobStatus>({
        headerTitle: '',
        field: 'actions',
        entityName: 'PopDisplays_JobStatus',
        RenderCell: StopTaskCol,
    }),
];
const paginationTag = 'task-queue-list-search';

const textSearchCols = ['name'];

const TaskQueuePage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const [timer, setTimer] = useState(2_500);
    const [inputValue, setInputValue] = useState<string | number>(timer);
    const ref = useRef<FilterableEntityTableRefAPI>();
    const onTick = useCallback(() => {
        ref?.current?.search?.();
    }, []);
    const { keyedEntities } = useEntityData<PopDisplays_JobStatus>('PopDisplays_JobStatus');
    const generateSx = useCallback<RenderRowProps<any>['generateSx']>(
        (row, id) => {
            if (keyedEntities?.[id]) {
                if (isErrorNote(keyedEntities[id]))
                    return { bgcolor: '#FF7F7F', color: 'error.contrastText' };
                if (isSuccessNote(keyedEntities[id]))
                    return { bgcolor: '#90ee90', color: 'success.contrastText' };
            }
            return null;
        },
        [keyedEntities],
    );
    const handleInputChange = event => {
        const value = event.target.value;
        setInputValue(value);
    };

    const handleSubmit = useCallback(() => {
        const MIN_TIMER_VALUE = 1000;
        const MAX_TIMER_VALUE = 20000;
        const newValue = Number(inputValue);
        if (newValue < MIN_TIMER_VALUE) {
            setTimer(MIN_TIMER_VALUE);
            setInputValue(MIN_TIMER_VALUE);
        } else if (newValue > MAX_TIMER_VALUE) {
            setTimer(MAX_TIMER_VALUE);
            setInputValue(MAX_TIMER_VALUE);
        } else {
            setTimer(newValue);
        }
    }, [inputValue]);
    const t = useCommonTranslation();
    const TimeField = useMemo(
        () => (
            <AutoGrid mb={2} gap={2} display="flex" alignItems="center">
                <Typography variant="body1">
                    {t('Modules.PopDisplays.JobStatus.refresh')}:
                </Typography>
                <TextField value={inputValue} onChange={handleInputChange} type="number" />
                <Button onClick={handleSubmit} variant="text" color="primary">
                    {t('Modules.PopDisplays.JobStatus.setTime')}
                </Button>
            </AutoGrid>
        ),
        [t, inputValue, handleSubmit],
    );

    return (
        <FullPageEntityTableWrapper
            title="Modules.PopDisplays.JobStatus.metaTitle"
            controls={useMemo(() => [TimeField], [TimeField])}
        >
            <FilterableEntityTable
                ref={ref}
                key={paginationTag}
                tag={paginationTag}
                columns={columns}
                revertToIdSearchOnNumberOnlyInput={false}
                baseEntityName="PopDisplays_JobStatus"
                textSearchColumns={textSearchCols}
                generateRowSx={generateSx}
                disabledRowDividers
                orderBy="id"
                rendererType={FilterableEntityTableRendererType.Contained}
                dense
                loadBar={false}
                afterFilters={
                    <Box height="100%" display="flex" alignItems="center" pb={0.5}>
                        <CountdownProgressCircle
                            loop
                            duration={timer}
                            onComplete={onTick}
                            size={30}
                            thickness={8}
                        />
                    </Box>
                }
            />
        </FullPageEntityTableWrapper>
    );
};

const setup: PageConfig = {
    id: 'TaskQueuePage',
    component: TaskQueuePage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.PopDisplays.JobStatus.metaTitle',
        description: 'Modules.PopDisplays.JobStatus.description',
        type: 'page',
        icon: taskQueueIcon,
        aliases: 'SystemSearch.aliases.popDisplaysTaskQueue',
        route: PostAuthRoutes.Admin.TaskQueue,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            { label: 'Modules.PopDisplays.JobStatus.metaTitle' },
        ],
        permissions: [PermissionName.Pop_displaysFailed_jobsRead],
    },
};

export default setup;
