import { PostAuthRoutes } from 'c-routes';
import { Config } from 'c-config';
import { dashboardIcon } from 'c-main/icons';
import { mergeSystemSearchConfig as mergeFunction } from '../SystemSearch';
import * as pages from './Pages';
// import * as widgets from './Widgets';

export const config: Config = {
    mergeFunction,
    reducers: {},
    EntitySchemaActions: {},
    navigation: {
        groups: {
            1: {
                items: {
                    // 1: {
                    //     id: 'dashboard',
                    //     icon: dashboardIcon,
                    //     label: 'Navigation.dashboardModuleLabel',
                    //     link: PostAuthRoutes.Dashboard.Dashboard,
                    //     permissions: []
                    // },
                },
            },
        },
    },
    // pages: [pages.DashboardHomePage],
    pages: [],
    widgets: [],
    // widgets: [widgets.OnlineUsersWidget],
    systemSearch: {
        moduleIcon: dashboardIcon,
    },
};
