export const stringOnlyNumbers = (str: string) => /^\d+$/.test(str);

export const snake2CamelCase = (str: string) => str.replace(/_(\w)/g, ($, $1) => $1.toUpperCase());

export const snake2PascalCase = (str: string) => {
    const s = snake2CamelCase(str);
    return `${s.charAt(0).toUpperCase()}${s.substr(1)}`;
};

export const stringEndsWithAny = (str: string, suffixes: string[]) =>
    suffixes.some(suffix => str.endsWith(suffix));

export const stringStartsWithAny = (str: string, suffixes: string[]) =>
    suffixes.some(suffix => str.startsWith(suffix));

export const stringWithoutWhitespace = (str: string) => str.replace(/\s/g, '');
