import React, { useCallback, useMemo } from 'react';
import {
    Box,
    BoxProps,
    IconButton,
    ListItemButton,
    ListItemTextProps,
    Typography,
} from '@mui/material';
import { ListItemText } from 'c-components';
import { editIcon } from 'c-main/icons';
import { css } from '@emotion/react';
import { AddCircle, Delete, RemoveCircle } from '@mui/icons-material';
import { useBoolean } from 'react-hanger';
import {
    CreativeDraggableItem,
    CreativeDragItemType,
    DeleteCreativesAndGroups,
    OpenCreativeGroup,
    ReValidateCampaign,
    UpdateGroup,
} from 'c-main/Components/CreativeManagement/types';
import { useDrop } from 'react-dnd';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PermissionName, CreativeGroup } from '@uniled/api-sdk';
import DeleteGroupDialog from '../DeleteGroupDialog';
import GroupListItemEdit from './GroupListItemEdit';
import FolderStyledListItem from './FolderStyledListItem';

type Props = {
    group: CreativeGroup;
    creativesCount: number;
    isActive: boolean;
    onClick: OpenCreativeGroup;
    updateGroup: UpdateGroup;
    reValidateCampaign: ReValidateCampaign;
    deleteData: DeleteCreativesAndGroups;
    onCreativesDropped?: (data: CreativeDraggableItem, groupId: number) => void;

    // indicates whether dropping on this group will remove groups instead of add
    isDropRemovingGroups?: boolean;
    // the name you want to display if a droppable creative is being dragged. Will default to group name
    canDropName?: string;

    icon: React.ReactNode;
    warningIcon?: React.ReactNode;

    warningCount: number;
    showWarningsIfZero?: boolean;

    droppable?: boolean;
    editable?: boolean;

    htmlClassName: string;
};

const controlClass = 'edit-group-name';
const warningClass = 'group-warning';
const controlsCss = css(`
                .${controlClass} {
                    // visibility: hidden;
                    display: none;
                }
                &:hover .${controlClass} {
                    // visibility: visible;
                    display: unset;
                }
                .${warningClass} {
                    // visibility: visible;
                }
                &:hover .${warningClass} {
                    display: none;
                    // visibility: hidden;
                }
            `);

const groupNameSx = {
    ml: 0.75,
    '&:hover': {
        overflow: 'visible',
        textOverflow: 'unset',
        whiteSpace: 'unset',
        pr: 1.5,
        wordBreak: 'break-all',
    },
};

const EditPerms: PermissionName[] = [PermissionName.UniledportalCreative_groupUpdate];
const DeletePerms: PermissionName[] = [PermissionName.UniledportalCreative_groupDestroy];

const GroupListItem: React.FC<Props> = ({
    group,
    canDropName,
    isDropRemovingGroups = false,
    isActive,
    onClick,
    updateGroup,
    creativesCount,
    onCreativesDropped,
    reValidateCampaign,
    deleteData,
    icon,
    warningIcon,
    warningCount,
    showWarningsIfZero = false,
    droppable = true,
    editable = true,
    htmlClassName,
}) => {
    const { value: isEditing, setTrue: StartEditing, setFalse: StopEditing } = useBoolean(false);
    const deleteDialogState = useBoolean(false);

    const onDrop = useCallback(
        (data: CreativeDraggableItem) => {
            onCreativesDropped(data, group.id);
        },
        [onCreativesDropped, group.id],
    );

    const [{ isOver, canDrop }, drop] = useDrop(
        {
            accept: CreativeDragItemType.Creative,
            drop: onDrop,
            collect: monitor => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop() && droppable,
            }),
        },
        [onDrop, droppable],
    );

    const onItemClick = useCallback(() => {
        onClick(group.id);
    }, [group.id, onClick]);
    const onEditClick = useCallback(() => {
        StartEditing();
    }, [StartEditing]);

    const onDeleteClick = useCallback(() => {
        deleteDialogState.setTrue();
    }, [deleteDialogState]);

    const showWarnings = useMemo(
        () => warningCount > 0 || showWarningsIfZero,
        [warningCount, showWarningsIfZero],
    );
    const warningSx = useMemo<BoxProps['sx']>(
        () => ({
            bgcolor: warningCount > 0 ? 'warning.main' : 'grey.400',
            color: 'white',
            borderRadius: '50%',
            height: 20,
            width: 20,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '1em',
        }),
        [warningCount],
    );

    const isDropRemoving = useMemo(
        () => isActive || isDropRemovingGroups,
        [isActive, isDropRemovingGroups],
    );

    const actualIcon = useMemo(() => {
        if (canDrop && isDropRemoving) return <RemoveCircle fontSize="inherit" />;
        if (canDrop && !isDropRemoving) return <AddCircle fontSize="inherit" />;

        return icon;
    }, [canDrop, icon, isDropRemoving]);

    const primaryTypographyProps = useMemo<ListItemTextProps['primaryTypographyProps']>(() => {
        let color = 'secondary';
        if (canDrop && isDropRemoving) color = 'error.main';
        else if (canDrop && !isDropRemoving) color = 'success.main';

        return {
            color,
            variant: 'body2',
            sx: { display: 'flex', alignItems: 'center' },
        };
    }, [canDrop, isDropRemoving]);

    const groupDisplayName = useMemo(() => {
        if (canDrop && canDropName) return canDropName;
        return group.name;
    }, [canDrop, canDropName, group.name]);

    return (
        <>
            <DeleteGroupDialog
                dialogState={deleteDialogState}
                group={group}
                deleteData={deleteData}
                reValidateCampaign={reValidateCampaign}
            />
            <FolderStyledListItem
                ref={droppable ? drop : undefined}
                disablePadding
                selected={isActive && !isOver}
                className={htmlClassName}
                secondaryAction={
                    <>
                        {editable && (
                            <Box className={controlClass}>
                                <IfHasAllPermissions permissions={EditPerms}>
                                    <IconButton
                                        size="small"
                                        onClick={onEditClick}
                                        color="primary"
                                        id={`edit-creative-group-${group.id}`}
                                    >
                                        {editIcon}
                                    </IconButton>
                                </IfHasAllPermissions>
                                <IfHasAllPermissions permissions={DeletePerms}>
                                    <IconButton
                                        size="small"
                                        onClick={onDeleteClick}
                                        color="error"
                                        sx={{ pr: 0 }}
                                        id={`delete-creative-group-${group.id}`}
                                    >
                                        <Delete fontSize="inherit" />
                                    </IconButton>
                                </IfHasAllPermissions>
                            </Box>
                        )}
                        {showWarnings &&
                            (warningIcon ?? (
                                <Typography
                                    sx={warningSx}
                                    variant="caption"
                                    className={editable ? warningClass : undefined}
                                >
                                    {warningCount}
                                </Typography>
                            ))}
                    </>
                }
                css={controlsCss}
                dropHoveredOver={isOver && canDrop}
                canDrop={canDrop}
            >
                {!isEditing && (
                    <ListItemButton onClick={onItemClick} sx={{ py: 1 }}>
                        <ListItemText
                            primary={
                                <>
                                    {actualIcon}
                                    <Typography noWrap variant="body2" sx={groupNameSx}>
                                        {`${groupDisplayName} ${
                                            creativesCount != null ? `(${creativesCount})` : ''
                                        }`}
                                    </Typography>
                                </>
                            }
                            primaryTypographyProps={primaryTypographyProps}
                        />
                    </ListItemButton>
                )}
                {isEditing && (
                    <GroupListItemEdit
                        group={group}
                        updateGroup={updateGroup}
                        close={StopEditing}
                    />
                )}
            </FolderStyledListItem>
        </>
    );
};

export default GroupListItem;
