import { CampaignPopShot } from '@uniled/api-sdk';
import { FileBrowserFile } from 'c-components';
import { generateFileResolutionTag } from 'c-components/FileBrowser/lib';
import { aspectRatio } from 'c-lib';

export const popShotToFile = ({ file, created_at }: CampaignPopShot): FileBrowserFile =>
    ({
        id: file?.id,
        name: file?.filename,
        mimeType: file?.filetype,
        height: file?.image_height,
        width: file?.image_width,
        urls: file?.urls,
        size: file?.filesize ?? 0,
        createdAt: created_at,
        tags: {
            fileType: file?.filetype,
            resolution: generateFileResolutionTag(file?.image_width, file?.image_height),
            aspectRatio: aspectRatio(file?.image_width, file?.image_height),
        },
    } as FileBrowserFile);
