import React, { PropsWithChildren } from 'react';
import { isFragment } from 'react-is';
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';

type ContentLayoutProps = {
    InnerBoxProps?: BoxProps;
    footer?: React.Component | JSX.Element;
} & BoxProps;

const ContentLayout = ({ children, InnerBoxProps, ...boxProps }: ContentLayoutProps) => {
    return (
        <Box display="flex" flexDirection="column" flex={1} width="100vw" {...boxProps}>
            <Box
                // width="100vw"
                display="flex"
                flex={1}
                flexDirection="column"
                alignItems="center"
                id="content-layout-inner"
                {...InnerBoxProps}
            >
                {children}
            </Box>
        </Box>
    );
};

type ContentLayoutTitleProps = {
    title: string;
    subtitle?: string;
} & Pick<TypographyProps, 'variant'> &
    BoxProps;

const ContentLayoutHeader = (props: BoxProps) => <Box mx={{ xs: -3, sm: 0 }} {...props} />;

const ContentLayoutTitle = ({
    title,
    subtitle,
    variant = 'h1',
    ...props
}: ContentLayoutTitleProps) => (
    <Box mt={5.5} mb={1} {...props}>
        <Typography variant={variant}>{title}</Typography>
        {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
    </Box>
);

const ContentLayoutSection = (props: BoxProps) => <Box pb={2} mb={3} {...props} />;

const ContentLayoutFooter = (props: BoxProps) => <Box mt={2} {...props} />;

const ContentLayoutForm = (props: BoxProps) => (
    <Box display="flex" flexDirection="column" alignItems="stretch" {...props} />
);

const ContentLayoutFormField: React.FC<BoxProps> = ({ children, ...props }) => (
    <Box display="flex" alignItems="stretch" flexDirection="column" mb={2} {...props}>
        {children}
    </Box>
);

const ContentLayoutAction: React.FC<PropsWithChildren> = ({ children }) => (
    <Box display="flex" mx="-1.7rem" mt={6}>
        {children}
    </Box>
);

const ContentLayoutListSection: React.FC<PropsWithChildren> = ({ children }) => (
    <Box>{children}</Box>
);

/**
 * @function ContentLayoutFormFields
 * @alias ContentLayout.FormFields
 *
 * @description
 *     Provides spacing to a series of `ReactNode`s.
 *     If only 1 ReactNode provided as `children` then ReactNode is returned unmodified.
 */
const ContentLayoutFormFields = ({
    children,
    FieldProps,
    ...props
}: React.PropsWithChildren<{ FieldProps?: BoxProps } & BoxProps>) =>
    Array.isArray(children) ? (
        <Box display="flex" alignItems="stretch" flexDirection="column" mb={-2} {...props}>
            {React.Children.map(children as React.ReactElement[], (child: React.ReactElement) => {
                if (child) {
                    if (isFragment(child)) {
                        return (
                            <ContentLayoutFormFields
                                key={`layout-${child.props.name || child.key}`}
                                FieldProps={FieldProps}
                            >
                                {child.props.children}
                            </ContentLayoutFormFields>
                        );
                    }

                    if ((child as React.ReactElement).props?.type === 'hidden') {
                        return React.cloneElement(child);
                    }

                    return (
                        <ContentLayoutFormField
                            key={`layout-${
                                (child as React.ReactElement).props.name ||
                                (child as React.ReactElement).key
                            }`}
                            {...FieldProps}
                        >
                            {React.cloneElement(child)}
                        </ContentLayoutFormField>
                    );
                }

                return null;
            })}
        </Box>
    ) : (
        <Box display="flex" alignItems="stretch" flexDirection="column" {...props}>
            {children}
        </Box>
    );

ContentLayout.Header = React.memo(ContentLayoutHeader);
ContentLayout.Title = React.memo(ContentLayoutTitle);
ContentLayout.Form = React.memo(ContentLayoutForm);
ContentLayout.FormField = React.memo(ContentLayoutFormField);
ContentLayout.FormFields = React.memo(ContentLayoutFormFields);
ContentLayout.Section = React.memo(ContentLayoutSection);
ContentLayout.Action = React.memo(ContentLayoutAction);
ContentLayout.ListSection = React.memo(ContentLayoutListSection);
ContentLayout.Footer = React.memo(ContentLayoutFooter);

export default ContentLayout;
