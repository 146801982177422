import React, { useMemo } from 'react';
import { Alert as AlertEntity, AlertType } from '@uniled/api-sdk';
import { AlertProps, AlertTitle, Box, Stack } from '@mui/material';
import Alert from 'c-components/MuiOverrides/Alert';
import { useCommonTranslation } from 'c-translation';
import { useDateUtils } from 'c-hooks';

type Props = {
    alerts: AlertEntity[];
};

const SystemAlerts: React.FC<Props> = ({ alerts }) => (
    <Stack gap={2} textAlign="left">
        {alerts.map(al => (
            <AnAlert key={al.id} alert={al} />
        ))}
    </Stack>
);

const SeverityMap: Record<AlertType, AlertProps['severity']> = {
    [AlertType.Notice]: 'info',
    [AlertType.Warning]: 'warning',
    [AlertType.Error]: 'error',
};

const AnAlert: React.FC<{ alert: AlertEntity }> = ({ alert }) => {
    const { formatDateString } = useDateUtils();
    const t = useCommonTranslation();
    const start = useMemo(
        () =>
            t('Models.Alert.startTime', {
                start: formatDateString({ date: alert.start_datetime, removeTime: false }),
            }),
        [t, alert, formatDateString],
    );
    return (
        <Alert severity={SeverityMap[alert.type] ?? 'info'} variant="outlined">
            <AlertTitle>{alert.message}</AlertTitle>
            {alert.start_datetime != null && <Box>{start}</Box>}
        </Alert>
    );
};

export default SystemAlerts;
