import { ChartReportProps, ReportType, TableReportProps } from 'c-reports/Types';
import { XAxisProps } from 'recharts';

export type ChartExportProps = {
    chartProps?: ChartReportProps<any>;
    tableProps?: TableReportProps<any>;
    type: ReportType;
    closeBtn: React.ReactNode;
    namePrefix: string;
};

export enum ChartExportImageType {
    PNG = 'image/png',
    JPEG = 'image/jpeg',
}

export enum ChartLabelAnchor {
    Start = 'start',
    Middle = 'middle',
    End = 'end',
}

export type ChartFormDataSchema = {
    imageType: ChartExportImageType;
    height: number;
    width: number;
    showLegend: boolean;
    reportNamePrefix?: string;
};
/**
 * Actual chart like line, bar, etc.
 */
export type CategoricalChartFormDataSchema = ChartFormDataSchema & {
    xAxisProps: Pick<XAxisProps, 'angle'> & {
        textAnchor: ChartLabelAnchor;
        showAllLabels: boolean;
        dataKey: string;

        dateFormat: string;
        originalDateFormat: string;
    };
    chartProps: {
        // typing it out manually because RHF types can't follow into the recharts types properly
        // margin: CategoricalChartProps['margin'];
        margin: {
            top: number;
            right: number;
            bottom: number;
            left: number;
        };
    };
    originalData: any[];
};

export type ChartSegmentOverride = {
    color: string;
    originalLabel: string;
    originalIndex: number;
    label: string;
    visible: boolean;
    dataKey: string;
};

type BarDataOverride = {
    originalLabel: string;

    // override label for the chart data 'row'
    newLabel: string;
    partColors: string[];
    visible: boolean;
    originalIndex: number;
};

export type BarChartFormDataSchema = CategoricalChartFormDataSchema & {
    segments: ChartSegmentOverride[];
    dataOverrides: BarDataOverride[];
};

export type LineChartFormDataSchema = CategoricalChartFormDataSchema & {
    segments: ChartSegmentOverride[];
};
