import React from 'react';
import {
    ControlledFormInput,
    Filterdropdown,
    OptionSchema,
    ToggleButtonGroup,
} from 'c-components/Forms';
import { TranslationPath, useCommonTranslation } from 'c-translation';

type Props = {
    options: OptionSchema[];
    filterName: string;
    label: TranslationPath;
    asDropdown?: boolean;
};

const FileBrowserTagOptions: React.FC<Props> = ({
    options,
    filterName,
    asDropdown = false,
    label,
}) => {
    const t = useCommonTranslation();
    return (
        <>
            {!asDropdown && (
                <ControlledFormInput
                    render={({ field }) => (
                        <ToggleButtonGroup
                            exclusive={false}
                            size="small"
                            options={options}
                            {...field}
                        />
                    )}
                    name={filterName}
                />
            )}
            {asDropdown && (
                <ControlledFormInput
                    render={({ field }) => (
                        <Filterdropdown
                            multiple
                            options={options}
                            // textFieldProps={{ label: t(label) }}
                            placeholder={t(label)}
                            size="small"
                            {...field}
                        />
                    )}
                    name={filterName}
                />
            )}
        </>
    );
};

export default FileBrowserTagOptions;
