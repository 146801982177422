import React from 'react';
import {
    AutoGrid,
    Checkbox,
    ControlledFormInput,
    OptionSchema,
    RadioGroupField,
    TextField,
} from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { ChartExportImageType, ChartFormDataSchema } from '../types';

const ImageTypeOpts: OptionSchema[] = [
    { label: 'PNG', value: ChartExportImageType.PNG },
    { label: 'JPEG', value: ChartExportImageType.JPEG },
];

type Props = {
    isCategorical: boolean;
};

const ChartExportFormGeneral: React.FC<Props> = ({ isCategorical }) => {
    const t = useCommonTranslation();
    return (
        <AutoGrid spacing={2} xs={12} sm={6} md={4} lg={3} xl={2}>
            <AutoGrid spacing={2} xs={12} sm={6}>
                <ControlledFormInput<ChartFormDataSchema>
                    name="width"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            variant="standard"
                            label={t('Reporting.exporting.form.widthLabel')}
                        />
                    )}
                />
                <ControlledFormInput<ChartFormDataSchema>
                    name="height"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            variant="standard"
                            label={t('Reporting.exporting.form.heightLabel')}
                        />
                    )}
                />
            </AutoGrid>
            <ControlledFormInput<ChartFormDataSchema>
                name="imageType"
                render={({ field }) => (
                    <RadioGroupField
                        {...field}
                        row
                        options={ImageTypeOpts}
                        label={t('Reporting.exporting.form.imageTypeLabel')}
                    />
                )}
            />
            {isCategorical && (
                <ControlledFormInput<ChartFormDataSchema>
                    name="showLegend"
                    render={({ field }) => (
                        <Checkbox
                            {...field}
                            isBoolean
                            label={t('Reporting.exporting.form.showLegendLabel')}
                        />
                    )}
                />
            )}
        </AutoGrid>
    );
};

export default ChartExportFormGeneral;
