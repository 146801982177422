import React, { useCallback, useEffect, useState } from 'react';
import { Button, DialogV2, FlashMessage, TextField } from 'c-components';
import { Creative } from '@uniled/api-sdk';
import { useCommonTranslation } from 'c-translation';
import { Box, TextFieldProps } from '@mui/material';
import { NetworkRequestState } from '@uniled/data-layer';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { UpdateCreatives } from '../../types';

type Props = {
    open: boolean;
    onClose: () => void;

    creativeRenameId?: number;
    currentCreativeName?: string;
    updateCreatives: UpdateCreatives;
};

const RenameCreativeDialog: React.FC<Props> = ({
    open,
    onClose,
    creativeRenameId,
    currentCreativeName = '',
    updateCreatives,
}) => {
    const t = useCommonTranslation();
    const [newName, setNewName] = useState('');

    const onNameChanged = useCallback(e => {
        setNewName(e.target.value);
    }, []);

    useEffect(() => {
        reset();
        setNewName(currentCreativeName ?? '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, currentCreativeName]);

    const { start, isLoading, requestState, error, reset } = useAPIClientRequest(
        apiClient.Entities.Creative.update,
    );
    const onSave = useCallback(async () => {
        if (newName === currentCreativeName) return;
        const [, success] = await to(
            start([{ id: creativeRenameId, name: newName }] as Creative[], [
                'file',
                'creativeGroupIds',
                'lineItemsCount',
            ]),
        );

        if (Array.isArray(success?.data?.data)) {
            updateCreatives(success?.data?.data);
            onClose();
            reset();
        }
    }, [start, updateCreatives, newName, creativeRenameId, onClose, reset, currentCreativeName]);

    const onRenameKeyUp = useCallback<TextFieldProps['onKeyDown']>(
        e => {
            if (e.key === 'Enter') onSave();
        },
        [onSave],
    );

    return (
        <DialogV2
            onClose={onClose}
            open={open}
            title={t('Modules.Main.CreativeManagement.creatives.controls.rename.title')}
            description={t(
                'Modules.Main.CreativeManagement.creatives.controls.rename.description',
                {
                    name: currentCreativeName,
                },
            )}
            actions={
                <>
                    <Button
                        onClick={onSave}
                        disabled={
                            requestState === NetworkRequestState.InProgress ||
                            newName === currentCreativeName
                        }
                    >
                        {useCommonTranslation(
                            'Modules.Main.CreativeManagement.creatives.controls.rename.saveLabel',
                        )}
                    </Button>
                </>
            }
        >
            <>
                <Box mt={2}>
                    <TextField
                        onKeyUp={onRenameKeyUp}
                        autoFocus={open}
                        label={t(
                            'Modules.Main.CreativeManagement.creatives.controls.rename.nameLabel',
                        )}
                        placeholder={currentCreativeName}
                        onChange={onNameChanged}
                        value={newName}
                        disabled={isLoading}
                    />
                    {requestState === NetworkRequestState.Error && (
                        <Box mt={2}>
                            <FlashMessage status="error">{String(error)}</FlashMessage>
                        </Box>
                    )}
                </Box>
            </>
        </DialogV2>
    );
};

export default RenameCreativeDialog;
