import React, { useCallback } from 'react';
import { Box, DialogContentText } from '@mui/material';
import { TextField, Alert } from 'c-components';
import { useCommonTranslation } from 'c-translation';

type Props = {
    inputValue: string;
    setInputValue: (val: string) => void;
    uniqueIdCount: number;
};

const BulkAttachSearch: React.FC<Props> = ({ inputValue, setInputValue, uniqueIdCount }) => {
    const t = useCommonTranslation();

    const onTextChange = useCallback(
        e => {
            setInputValue(e.target.value);
        },
        [setInputValue],
    );

    return (
        <>
            <Alert severity="info">{t('Modules.Main.Campaigns.Create.bulkAttachScreenInfo')}</Alert>
            <DialogContentText>
                {t('Modules.Main.Campaigns.Create.bulkAttachDescription')}
            </DialogContentText>
            <Box my={2} display="flex" flexDirection="column">
                <TextField
                    id="frame-ids"
                    maxRows={20}
                    multiline
                    value={inputValue}
                    autoFocus
                    onChange={onTextChange}
                    helperText={
                        uniqueIdCount > 0 &&
                        t('Modules.Main.Campaigns.Create.bulkAttachFrameCount', {
                            count: uniqueIdCount,
                        })
                    }
                />
            </Box>
        </>
    );
};

export default BulkAttachSearch;
