export const MAIN_CAMPAIGN_TABS_ID = 'campaign-main-tabs';

function actualScrollToReportingTab() {
    document
        ?.getElementById(MAIN_CAMPAIGN_TABS_ID)
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
}
export function scrollToTopReportingTab(timeout = 500) {
    if (timeout <= 0) {
        actualScrollToReportingTab();
    } else {
        // just doing a timeout to let the view re render
        setTimeout(() => {
            actualScrollToReportingTab();
        }, timeout);
    }
}
