import React from 'react';
import { MediaOwnerIssues } from 'c-main/Types';
import { AutoGrid } from 'c-components';
import MediaOwnerRow from './MediaOwnerRow';

type Props = {
    issues: MediaOwnerIssues[];
};

const MediaOwnerGroupedIssues: React.FC<Props> = ({ issues }) => {
    return (
        <AutoGrid spacing={0.5} xs={12}>
            {issues.map(i => (
                <MediaOwnerRow key={i.mediaOwnerId} details={i} />
            ))}
        </AutoGrid>
    );
};
export default MediaOwnerGroupedIssues;
