import {
    marketActions,
    marketAdapter,
    marketThunks as defaultThunks,
    marketSelectors as defaultSelectors,
    marketReducer,
} from './Market-slice';
import * as thunks from './Market-thunks';
import * as selectors from './Market-selectors';

const marketThunks = { ...defaultThunks, ...thunks };
const marketSelectors = { ...defaultSelectors, ...selectors };

export { default as MarketSlice } from './Market-slice';
export { marketSelectors, marketActions, marketAdapter, marketThunks, marketReducer };
