import {
    CampaignNoticeKey,
    EsPCAReportField,
    PCAReportCountField,
    PCAReportField,
    PermissionName,
    Campaign,
} from '@uniled/api-sdk';
import { useEntityData } from 'c-data';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useUrlState, useUrlStateValue } from 'c-hooks';
import {
    FieldReportType,
    PCAMetricUrlStateKeys,
    PCAUrlState,
    ReportSource,
    ReportView,
} from 'c-main/Components/Campaign/CampaignReports/types';
import { ReportDateTimeframe } from 'c-reports/Types';
import { useBoolean } from 'react-hanger';
import { omit } from 'ramda';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { getEsCountFields } from 'c-main/Components/Campaign/CampaignReports/ElasticSearch/Transformers';
import { useAtom } from 'jotai';
import {
    atom_environmentMode,
    atom_source,
} from 'c-main/Pages/Campaign/CampaignOverviewPage/atoms';

const allCounts = [
    PCAReportCountField.InSchedule,
    PCAReportCountField.OutSchedule,
    PCAReportCountField.UnBooked,
];
const sandboxPermissions = [PermissionName.AdminCan_read_pca_sandbox];
const defaultGroupByField: Record<string, EsPCAReportField> = {
    'United States of America': EsPCAReportField.DisplayOwner,
};

function useCampaignWrapperState(id: number) {
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });
    const { hasAll } = useUserPermissions();
    const canSeeSandbox = useMemo(() => hasAll(sandboxPermissions), [hasAll]);
    const environmentOptions = useMemo(() => {
        const environments = Object?.keys(campaign?.pca_available);
        if (environments.length > 0) {
            // Conditionally filter out 'sandbox' if the user cannot see sandbox environments
            return canSeeSandbox ? environments : environments.filter(env => env !== 'sandbox');
        }
        return [];
    }, [campaign, canSeeSandbox]);
    const campaignStart = useMemo(
        () =>
            campaign?.date?.date_start != null
                ? new Date(format(parseISO(campaign?.date?.date_start), 'yyyy-MM-dd'))
                : null,
        [campaign?.date?.date_start],
    );
    const campaignEnd = useMemo(
        () =>
            campaign?.date?.date_end != null
                ? new Date(format(parseISO(campaign?.date?.date_end), 'yyyy-MM-dd'))
                : null,
        [campaign?.date?.date_end],
    );

    const urlEsSelfRefiningFiltersValue = useUrlStateValue<PCAUrlState['srf']>(
        PCAMetricUrlStateKeys.selfRefiningFilters,
        true,
    );

    const urlPCAValue = useUrlStateValue<PCAUrlState>(PCAMetricUrlStateKeys.root, {});
    const paramSource = useUrlStateValue<ReportSource>('source', ReportSource.Athena);
    const urlViewValue = useUrlStateValue<ReportView>(PCAMetricUrlStateKeys.view, ReportView.Field);
    const urlFieldValue = useUrlStateValue<string>(
        PCAMetricUrlStateKeys.field,
        defaultGroupByField[campaign?.market?.name] ?? EsPCAReportField.DisplayLineItemName,
    );

    const urlFieldSearchValue = useUrlStateValue<string>(PCAMetricUrlStateKeys.fieldSearch, '');
    const urlDateValue = useUrlStateValue<PCAUrlState['date']>(PCAMetricUrlStateKeys.date, {
        timeframe: ReportDateTimeframe.All,
    });
    const urlFiltersValue = useUrlStateValue<PCAUrlState['filters']>(
        PCAMetricUrlStateKeys.filters,
        {},
    );
    const defaultEnvironment = useMemo(() => {
        const hasLive = environmentOptions.includes('live');
        return hasLive ? 'live' : environmentOptions.length > 0 ? environmentOptions[0] : '';
    }, [environmentOptions]);
    const urlEnvironmentValue = useUrlStateValue<PCAUrlState['environment']>(
        'environment',
        defaultEnvironment,
    );
    const [environmentMode, setEnvironmentMode] = useAtom<string | null>(atom_environmentMode) as [
        string | null,
        (newValue: string | null) => void,
    ];
    const [source, setSource] = useAtom(atom_source);
    useEffect(() => {
        if (urlEnvironmentValue != null && urlEnvironmentValue !== environmentMode) {
            setEnvironmentMode(urlEnvironmentValue as string);
        }
        if (defaultEnvironment === '') {
            setEnvironmentMode(null);
        }
        if (paramSource !== source) {
            setSource(paramSource);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasPCAData = useMemo(
        () => environmentOptions.length > 0 && environmentMode != null,
        [environmentOptions, environmentMode],
    );

    const [stateSandbox, setSandbox] = useState<boolean>(Boolean(urlEnvironmentValue));

    const sandbox = useMemo(() => {
        // has sandbox data and can see sandbox data and the url value is set to true
        if (hasPCAData && stateSandbox && canSeeSandbox) return true;

        return stateSandbox && canSeeSandbox;
    }, [canSeeSandbox, hasPCAData, stateSandbox]);

    const advancedFilters = useMemo<Record<string, string[]>>(
        () => urlFiltersValue,
        [urlFiltersValue],
    );

    const showAdvancedFilters = useBoolean(false);

    const advancedFiltersCount = useMemo(
        () =>
            Object.entries(advancedFilters ?? {}).reduce((acc, [, values]) => {
                if (values?.length > 0) {
                    return acc + 1;
                }

                return acc;
            }, 0),
        [advancedFilters],
    );

    const urlReportType = useUrlStateValue<FieldReportType>(
        PCAMetricUrlStateKeys.reportType,
        FieldReportType.OverTime,
    );
    const urlByFieldReportType = useUrlStateValue<FieldReportType>(
        PCAMetricUrlStateKeys.byFieldReportType,
        FieldReportType.OverallLeaderboard,
    );

    const [view, setView] = useState<ReportView>(urlViewValue);
    const field = useMemo<string>(() => urlFieldValue, [urlFieldValue]);
    const [fieldSearchValue, setFieldSearchValue] = useState<string>(urlFieldSearchValue);

    const [reportType, setReportType] = useState<FieldReportType>(urlReportType);
    const [byFieldReportType, setByFieldFieldReportType] =
        useState<FieldReportType>(urlByFieldReportType);

    const [overallAccumulative, setByOverallAccumulative] = useState(true);
    const [byFieldAccumulative, setByFieldAccumulative] = useState(false);

    const countFields = useMemo<PCAReportCountField[]>(
        () => getEsCountFields(advancedFilters),
        [advancedFilters],
    );

    const { push, pushMany } = useUrlState();
    const onEnvironmentModeChange = useCallback(
        (newMode: string) => {
            setEnvironmentMode(newMode);
            push('environment', newMode);
        },
        [push, setEnvironmentMode],
    );

    const onSourceToggle = useCallback(
        (newMode: ReportSource) => {
            setSource(newMode);
            push('source', newMode);
        },
        [push, setSource],
    );

    useEffect(() => {
        // set the currently visible view from the URL if it is not the same as the current state
        setView(currentView => {
            if (urlViewValue !== currentView) {
                return urlViewValue;
            }

            return currentView;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlViewValue]);

    useEffect(() => {
        setSandbox(Boolean(urlEnvironmentValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlEnvironmentValue]);

    useEffect(() => {
        // set the current search term from the URL if it is not the same as the current state
        setFieldSearchValue(currentField => {
            if (urlFieldSearchValue !== currentField) {
                return urlFieldSearchValue;
            }

            return currentField;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlFieldSearchValue]);

    useEffect(() => {
        // set the current by field report type from the URL if it is not the same as the current state
        setReportType(currentField => {
            if (urlReportType !== currentField) {
                return urlReportType;
            }

            return currentField;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlReportType]);

    useEffect(() => {
        // set the current by field report type from the URL if it is not the same as the current state
        setByFieldFieldReportType(currentField => {
            if (urlByFieldReportType !== currentField) {
                return urlByFieldReportType;
            }

            return currentField;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlByFieldReportType]);

    useEffect(() => {
        // set the current by field report type from the URL if it is not the same as the current state
        setByFieldFieldReportType(currentField => {
            if (urlByFieldReportType !== currentField) {
                return urlByFieldReportType;
            }

            return currentField;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlByFieldReportType]);

    const onSwitchField = useCallback(
        (field: string) => {
            setView(ReportView.Field);
            push(PCAMetricUrlStateKeys.root, {
                ...urlPCAValue,
                view: ReportView.Field,
                field,

                // when switching away from overall, remove the by the overall report type from the url
                reportType: undefined,
            } as PCAUrlState);
        },
        [setView, push, urlPCAValue],
    );

    const onSwitchView = useCallback(
        (view: ReportView) => {
            setView(view);
            pushMany({
                [PCAMetricUrlStateKeys.view]: view,
                [PCAMetricUrlStateKeys.field]: undefined,
                // when switching away from field, remove the by field report type from the url
                [PCAMetricUrlStateKeys.byFieldReportType]: undefined,
                // when switching away from overall, remove the by report type from the url
                [PCAMetricUrlStateKeys.reportType]:
                    view === ReportView.Overall ? reportType : undefined,
            });
        },
        [pushMany, reportType],
    );

    const onSearchTermUpdated = useCallback(
        val => {
            setFieldSearchValue(val);
            push(PCAMetricUrlStateKeys.fieldSearch, val);
        },
        [setFieldSearchValue, push],
    );

    const onReportTypeSwitched = useCallback(
        (type: FieldReportType) => {
            if (view === ReportView.Map) {
                // if you're currently in map view and have selected to switch to
                // overtime, leaderboard or bar chart
                // switch to by field
                setView(ReportView.Field);
                pushMany({
                    [PCAMetricUrlStateKeys.view]: ReportView.Field,
                    // it'll default to line item
                    [PCAMetricUrlStateKeys.field]: undefined,
                    [PCAMetricUrlStateKeys.byFieldReportType]: type,
                    [PCAMetricUrlStateKeys.reportType]: undefined,
                });
            } else {
                setReportType(type);
                push(PCAMetricUrlStateKeys.reportType, type);
            }
        },
        [setReportType, view, push, pushMany],
    );

    const onSandboxToggle = useCallback(
        (state: boolean) => {
            setSandbox(state);
            push(PCAMetricUrlStateKeys.sandbox, state ? 1 : 0);
        },
        [push],
    );

    const onSelfRefiningFiltersToggle = useCallback(
        (newVal: boolean) => {
            // reset filters and count fields when switching DBs
            push(PCAMetricUrlStateKeys.selfRefiningFilters, newVal);
        },
        [push],
    );

    const onByFieldReportTypeSwitched = useCallback(
        (type: FieldReportType) => {
            setByFieldFieldReportType(type);
            push(PCAMetricUrlStateKeys.byFieldReportType, type);
        },
        [setByFieldFieldReportType, push],
    );

    const onAdvancedFiltersUpdated = useCallback(
        (filters: Record<string, string[]>, counts: PCAReportCountField[]) => {
            pushMany({
                [PCAMetricUrlStateKeys.filters]: filters,
                [PCAMetricUrlStateKeys.countFields]: counts,
            });
        },
        [pushMany],
    );

    const onResetAdvancedFilters = useCallback(() => {
        pushMany({
            [PCAMetricUrlStateKeys.filters]: {},
            [PCAMetricUrlStateKeys.countFields]: allCounts,
        });
    }, [pushMany]);

    const dataStatusFilter = useMemo<CampaignNoticeKey>(
        () => advancedFilters?.[PCAReportField.DataStatus]?.[0] as CampaignNoticeKey,
        [advancedFilters],
    );

    const notices = useMemo(() => {
        const notices: CampaignNoticeKey[] = [];

        if (campaign?.notices?.overall?.awaiting_data === true)
            notices.push(CampaignNoticeKey.AwaitingData);
        if (campaign?.notices?.overall?.awaiting_booking_plan === true)
            notices.push(CampaignNoticeKey.AwaitingBookingPlan);
        if (campaign?.notices?.overall?.inspecting_data === true)
            notices.push(CampaignNoticeKey.InspectingData);

        return notices;
    }, [campaign]);

    const hasDataNotices = useMemo(() => notices.length > 0, [notices]);

    const setDataStatusFilter = useCallback(
        (dataStatus?: CampaignNoticeKey) => {
            if (dataStatus == null) {
                onAdvancedFiltersUpdated(
                    omit([PCAReportField.DataStatus], advancedFilters),
                    countFields,
                );
            } else {
                onAdvancedFiltersUpdated(
                    {
                        ...advancedFilters,
                        [PCAReportField.DataStatus]: [dataStatus],
                    },
                    countFields,
                );
            }
        },
        [advancedFilters, onAdvancedFiltersUpdated, countFields],
    );

    return useMemo(
        () => ({
            campaignStart,
            campaignEnd,

            urlPCAValue,
            urlViewValue,
            urlFieldValue,
            urlFieldSearchValue,
            urlDateValue,
            urlFiltersValue,
            urlReportType,
            urlByFieldReportType,

            advancedFilters,
            showAdvancedFilters,
            advancedFiltersCount,
            environmentOptions,
            onEnvironmentModeChange,
            environmentMode,

            sandbox,
            onSandboxToggle,
            canSeeSandbox,
            hasPCAData,
            sandboxPermissions,

            urlEsSelfRefiningFiltersValue,
            onSelfRefiningFiltersToggle,

            // environment,
            source,
            onSourceToggle,

            view,
            field,
            fieldSearchValue,

            reportType,
            setReportType,

            byFieldReportType,

            countFields,

            overallAccumulative,
            setByOverallAccumulative,
            byFieldAccumulative,
            setByFieldAccumulative,

            notices,
            hasDataNotices,
            dataStatusFilter,
            setDataStatusFilter,

            onSwitchField,
            onSwitchView,
            onSearchTermUpdated,
            onReportTypeSwitched,
            onByFieldReportTypeSwitched,
            onAdvancedFiltersUpdated,
            onResetAdvancedFilters,
        }),
        [
            advancedFilters,
            advancedFiltersCount,
            byFieldAccumulative,
            byFieldReportType,
            campaignEnd,
            campaignStart,
            canSeeSandbox,
            countFields,
            dataStatusFilter,
            environmentMode,
            environmentOptions,
            field,
            fieldSearchValue,
            hasDataNotices,
            hasPCAData,
            notices,
            onAdvancedFiltersUpdated,
            onByFieldReportTypeSwitched,
            onEnvironmentModeChange,
            onReportTypeSwitched,
            onResetAdvancedFilters,
            onSandboxToggle,
            onSearchTermUpdated,
            onSelfRefiningFiltersToggle,
            onSourceToggle,
            onSwitchField,
            onSwitchView,
            overallAccumulative,
            reportType,
            sandbox,
            setDataStatusFilter,
            showAdvancedFilters,
            source,
            urlByFieldReportType,
            urlDateValue,
            urlEsSelfRefiningFiltersValue,
            urlFieldSearchValue,
            urlFieldValue,
            urlFiltersValue,
            urlPCAValue,
            urlReportType,
            urlViewValue,
            view,
        ],
    );
}

export default useCampaignWrapperState;
