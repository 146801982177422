import { addDays, format, isBefore, isSameDay } from 'date-fns';

type Props = {
    start: Date;
    end: Date;
    dateFormat?: string;

    // set to true if you want native Date objects returned
    returnAsDate?: boolean;
};
function datesInRange({ start, end, dateFormat = 'dd/MM/yyyy', returnAsDate = false }: Props) {
    let currentDate = start;
    const dates = [];
    while (isBefore(currentDate, end) || isSameDay(currentDate, end)) {
        dates.push(returnAsDate ? currentDate : format(currentDate, dateFormat));
        currentDate = addDays(currentDate, 1);
    }

    return dates;
}

export default datesInRange;
