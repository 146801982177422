import React from 'react';
import { TranslationPath } from '../../Types';
import { useCommonTranslation } from '../../Hooks';

type Props = {
    path: TranslationPath;
    data?: Record<string, unknown>;
};
const Translate: React.FC<Props> = ({ data, path }) => <>{useCommonTranslation(path, data)}</>;

export default Translate;
