import React, { useCallback, useMemo } from 'react';
import { Checkbox } from 'c-components/Forms';
import { Box, ListItem, ListItemAvatar, Typography } from '@mui/material';
import RawFilePreview from 'c-components/FileBrowser/RawFilePreview';
import Avatar from 'c-components/Avatar';
import FileIcon from 'c-components/FileBrowser/FileIcon';
import ListItemText from 'c-components/ListItemText';
import HighlightWord from 'c-components/HighlightWord/HighlightWord';
import FileHighlightFilterText from 'c-components/FileBrowser/FileHighlightFilterText';
import { generateFileResolution } from 'c-components/FileBrowser/lib';
import { formatBytes } from 'c-lib';
import { useDateUtils } from 'c-hooks';
import { useBoolean } from 'react-hanger';
import { CheckboxProps } from 'c-components/Forms/Checkbox/Checkbox';
import { FileBrowserFile, ViewOptionProps } from '../types';
import OpenFileDownload from '../OpenFileDownload';
import OpenFileNewTab from '../OpenFileNewTab';

type Props = {
    searchTerm: string;
    file: FileBrowserFile;
    index: number;
    isFocused: boolean;
    isSelected: boolean;
    isAnyFileFocused: boolean;
    filters: ViewOptionProps['filters'];
    onClick: (id) => void;
    onFileToggled: ViewOptionProps['onFileToggled'];
    forceShowCheckboxes: boolean;
};

const checkboxSx = { mr: 0 };

const ListViewItem: React.FC<Props> = ({
    searchTerm,
    file,
    index,
    isFocused,
    isSelected,
    isAnyFileFocused,
    filters,
    onFileToggled,
    onClick,
    forceShowCheckboxes,
}) => {
    const { formatDateString } = useDateUtils();
    const isHovering = useBoolean(false);
    const showCheckbox = useMemo(
        () => isHovering.value || forceShowCheckboxes,
        [isHovering.value, forceShowCheckboxes],
    );

    const onCheckboxChange = useCallback<CheckboxProps['onChange']>(e => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const onCheckboxClick = useCallback<CheckboxProps['onClick']>(
        e => {
            e.stopPropagation();
            e.preventDefault();
            onFileToggled(file, e);
        },
        [file, onFileToggled],
    );

    return (
        <ListItem
            selected={isFocused}
            button
            key={file.id}
            divider
            onClick={() => onClick(file.id)}
            onMouseEnter={isHovering.setTrue}
            onMouseLeave={isHovering.setFalse}
        >
            <ListItemAvatar>
                <Box display={showCheckbox ? 'none' : undefined}>
                    <RawFilePreview
                        file={file}
                        maxHeight={50}
                        maxWidth={50}
                        showVideoPreview={false}
                        preferredSize="small"
                        fallback={
                            <Avatar>
                                <FileIcon file={file} />
                            </Avatar>
                        }
                    />
                </Box>
                {showCheckbox && (
                    <Box
                        display={!showCheckbox ? 'none' : 'flex'}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Checkbox
                            checked={isSelected}
                            onChange={onCheckboxChange}
                            formControlLabelSx={checkboxSx}
                            onClick={onCheckboxClick}
                            label=""
                        />
                    </Box>
                )}
            </ListItemAvatar>
            <ListItemText
                sx={{ wordBreak: 'break-word' }}
                primary={<HighlightWord text={file.name} searchTerm={searchTerm} />}
                secondary={
                    <>
                        <FileHighlightFilterText
                            filters={filters.fileTypes}
                            text={file.fileTypeSimple}
                        />{' '}
                        |{' '}
                        <FileHighlightFilterText
                            filters={filters.resolutions}
                            text={generateFileResolution(file)}
                        />{' '}
                        |{' '}
                        <FileHighlightFilterText
                            filters={filters.aspectRatios}
                            text={file.tags.aspectRatio}
                        />{' '}
                        | {` ${formatBytes(file.size)}`}
                        {` | ${formatDateString({ date: file.createdAt })}`}
                    </>
                }
            />

            {!isAnyFileFocused && (
                <>
                    <OpenFileDownload file={file} /> <OpenFileNewTab file={file} />{' '}
                </>
            )}
            <Typography variant="caption" color="textSecondary">
                #{index + 1}
            </Typography>
        </ListItem>
    );
};

export default ListViewItem;
