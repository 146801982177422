import React, { Ref } from 'react';
import { IconButton, IconButtonProps as MuiButtonProps } from '@mui/material';
import MuiRouterLink from 'c-components/MuiRouterLink';
import { LinkProps } from 'react-router-dom';

const IconLinkButton = ({ to, children, ...props }: MuiButtonProps & LinkProps, ref: Ref<any>) => {
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <IconButton component={MuiRouterLink} to={to} {...props} ref={ref}>
            {children}
        </IconButton>
    );
};

export default React.forwardRef(IconLinkButton);
