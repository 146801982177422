import React from 'react';
import { BrowserRouter as Router, BrowserRouterProps } from 'react-router-dom';

const AppRouter = (props: React.PropsWithChildren<BrowserRouterProps>) => {
    // Just going to leave this here in case we switch to Next.js
    // const router = useRouter();
    //
    // if (isServer) {
    //     return <StaticRouter {...props} />;
    // }
    //
    // // https://nextjs.org/docs/api-reference/next/router
    // // we do not want nextjs to handle onPopState
    // // because it doesn't work properly if you use
    // // react-router-dom for all of your routing needs
    // // next's router is crap for SPAs
    // router.beforePopState(() => {
    //     return false;
    // });

    return <Router {...props} />;
};

export default AppRouter;
