import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { useCallback, useEffect, useMemo } from 'react';
import to from 'await-to-js';
import { CampaignCreativesResponse, Creative, Campaign } from '@uniled/api-sdk';
import { atom, useAtom } from 'jotai';
import { useEntityData } from 'c-data';
import { NetworkRequestState } from '@uniled/data-layer';

const emptyData: CampaignCreativesResponse = {
    creatives: [],
    creativeGroups: [],
};

const atom_campaignId = atom<number>(null as number);
const atom_campaignCreatives = atom<CampaignCreativesResponse>({ ...emptyData });
const atom_revalidateLoading = atom<boolean>(false);

function useCampaignCreativesData(campaignId: number) {
    const { upsertEntity } = useEntityData<Campaign>('Campaign');

    const [currentCampaignId, setCurrentCampaignId] = useAtom(atom_campaignId);
    const [creativeData, setCreativeData] = useAtom(atom_campaignCreatives);
    const [revalidateLoading, setRevalidateLoading] = useAtom(atom_revalidateLoading);

    const { start, requestStateRef } = useAPIClientRequest(apiClient.Entities.Campaign.creatives);
    const getCreatives = useCallback(async () => {
        if (requestStateRef?.current === NetworkRequestState.InProgress) return;

        const [, success] = await to(start(campaignId));

        if (success && success?.data) {
            setCreativeData({
                ...success?.data,
                creatives: success?.data?.creatives.filter(c => c.file_id != null),
            });
        }
    }, [requestStateRef, start, campaignId, setCreativeData]);

    const resetCreativesData = useCallback(
        (newData: CampaignCreativesResponse) => {
            setCreativeData({
                ...newData,
                creatives: newData?.creatives.filter(c => c.file_id != null),
            });
        },
        [setCreativeData],
    );

    const updateCreatives = useCallback(
        (creatives: Creative[]) => {
            setCreativeData(curr => {
                const newCreatives = [...curr.creatives];

                creatives.forEach(creative => {
                    const index = newCreatives.findIndex(c => c.id === creative.id);

                    if (index === -1) {
                        newCreatives.push(creative);
                    } else {
                        newCreatives[index] = creative;
                    }
                });

                return { ...curr, creatives: newCreatives };
            });
        },
        [setCreativeData],
    );

    const {
        start: Revalidate,
        isLoading: isRevalidateLoading,
        requestStateRef: validateStateRef,
    } = useAPIClientRequest(apiClient.Entities.Campaign.validate);
    const reValidateCampaign = useCallback(async () => {
        if (validateStateRef?.current === NetworkRequestState.InProgress) return;
        const [err, response] = await to(Revalidate(campaignId, ['errors']));

        if (!err && response?.data?.data?.id === campaignId) {
            upsertEntity(response.data.data as unknown as Campaign);
        }
    }, [Revalidate, campaignId, upsertEntity, validateStateRef]);

    useEffect(() => {
        setRevalidateLoading(isRevalidateLoading);
    }, [isRevalidateLoading, setRevalidateLoading]);

    useEffect(() => {
        if (campaignId !== currentCampaignId) {
            setCurrentCampaignId(campaignId);
            setCreativeData({ ...emptyData });
        }
    }, [campaignId, currentCampaignId, setCurrentCampaignId, setCreativeData]);

    const actualCreativesData = useMemo<CampaignCreativesResponse>(() => {
        if (campaignId === currentCampaignId) {
            const firstCreativeInCampaign =
                Number(creativeData?.creatives?.[0]?.campaign_id) === campaignId;
            const firstGroupInCampaign =
                Number(creativeData?.creativeGroups?.[0]?.campaign_id) === campaignId;
            return {
                ...creativeData,
                // only return the creative data if the campaign is the same as the data was loaded for
                creatives: firstCreativeInCampaign ? creativeData.creatives : [],

                // only return the creative group data if the campaign is the same as the data was loaded for
                creativeGroups: firstGroupInCampaign ? creativeData.creativeGroups : [],
            };
        }

        return { ...emptyData };
    }, [campaignId, currentCampaignId, creativeData]);
    const existingFilesArray = useMemo(
        () => actualCreativesData.creatives.map(file => file.name),
        [actualCreativesData.creatives],
    );
    return {
        creativeData: actualCreativesData,
        setCreativeData,
        getCreatives,
        updateCreatives,
        resetCreativesData,
        reValidateCampaign,
        revalidateLoading,
        existingFilesArray,
    };
}

export default useCampaignCreativesData;
