import React, { useMemo } from 'react';
import {
    AutoGrid,
    Checkbox,
    FileBrowserFile,
    FileDuration,
    FileName,
    TableCell,
    TableRow,
} from 'c-components';
import { Box, Typography, TypographyProps } from '@mui/material';
import FilePreviewDialog from 'c-components/FileBrowser/FilePreviewDialog';
import { useBoolean } from 'react-hanger';
import RawFilePreview from 'c-components/FileBrowser/RawFilePreview';
import Avatar from 'c-components/Avatar';
import FileIcon from 'c-components/FileBrowser/FileIcon';
import { generateFileResolution } from 'c-components/FileBrowser/lib';
import { useDateUtils } from 'c-hooks';
import { Displays_LineItem, CreativeGroup } from '@uniled/api-sdk';
import { getLineItemResolutions } from 'c-main/Components/CreativeManagement/lib';
import { InfoOutlined, WarningAmber } from '@mui/icons-material';
import { useCommonTranslation } from 'c-translation';

type Props = {
    canAction: boolean;
    file: FileBrowserFile;
    isSelected: boolean;
    alreadyAssigned: boolean;
    onCheckboxChange: (e) => void;
    lineItem: Displays_LineItem;
    unAssignMode?: boolean;
    groups: CreativeGroup[];
};

const tdProps = {
    dense: true,
    sx: { px: 1, py: 1.5 },
};
const nameTdProps = {
    ...tdProps,
    sx: { ...tdProps.sx, pl: 0, cursor: 'pointer' },
};
const greyTdProps = {
    ...tdProps,
    typographyProps: { variant: 'body2', color: 'grey.600' } as TypographyProps,
};
const checkboxSx = { mr: 0, mx: 0 };

const PreviewIconSx = {
    '&:hover': { transform: 'scale(1.2)' },
    transition: '200ms all',
    cursor: 'pointer',
};

const emptyErrors = [];
const LineItemAssignCreativeListItem: React.FC<Props> = ({
    canAction,
    file,
    isSelected,
    onCheckboxChange,
    lineItem,
    alreadyAssigned,
    unAssignMode = false,
    groups,
}) => {
    const t = useCommonTranslation();
    const dialogShowState = useBoolean(false);
    const { formatDateString } = useDateUtils();

    // array of width x height resolutions
    const resolutions = useMemo<[number, number][]>(
        () => getLineItemResolutions([lineItem]),
        [lineItem],
    );

    // just want to know if at least one resolution matches
    const resMatch = useMemo(
        () =>
            resolutions.length === 0 ||
            resolutions.reduce((currentMatch, res) => {
                if (res[0] === file.width && res[1] === file.height) return true;

                return currentMatch;
            }, false),
        [resolutions, file],
    );

    const showResMatchError = useMemo(
        () => (isSelected || alreadyAssigned) && !resMatch,
        [isSelected, resMatch, alreadyAssigned],
    );

    const showAssigned = useMemo(
        () => alreadyAssigned && !unAssignMode,
        [alreadyAssigned, unAssignMode],
    );

    const disableCheckbox = useMemo(
        () => alreadyAssigned && !unAssignMode,
        [alreadyAssigned, unAssignMode],
    );
    const groupNames = useMemo(() => groups.map(g => g.name).join(', '), [groups]);

    return (
        <>
            <FilePreviewDialog
                file={file}
                open={dialogShowState.value}
                close={dialogShowState.setFalse}
                errors={emptyErrors}
            />
            <TableRow className={`creative-row-${file.downloadId}`}>
                {canAction && (
                    <TableCell {...tdProps} sx={{ width: 50, pr: 0 }}>
                        <Box height={50} alignItems="center" justifyContent="center" display="flex">
                            <Box mx="auto">
                                <Checkbox
                                    className="toggle-check-creative"
                                    checked={isSelected}
                                    onClick={onCheckboxChange}
                                    formControlLabelSx={checkboxSx}
                                    label=""
                                    disabled={disableCheckbox}
                                />
                            </Box>
                        </Box>
                    </TableCell>
                )}
                <TableCell {...nameTdProps}>
                    <Box display="flex" alignItems="center" onClick={dialogShowState.setTrue}>
                        <Box
                            height={35}
                            width={35}
                            mr={1.5}
                            ml={!canAction ? 1.5 : 0}
                            sx={PreviewIconSx}
                            className="open-creative-preview"
                        >
                            <RawFilePreview
                                file={file}
                                maxHeight={35}
                                maxWidth={35}
                                showVideoPreview={false}
                                preferredSize="small"
                                imgStyles={{ borderRadius: '4px' }}
                                fallback={
                                    <Avatar>
                                        <FileIcon file={file} />
                                    </Avatar>
                                }
                            />
                        </Box>
                        <Box>
                            <FileName name={file.name} />
                            <Box>
                                <AutoGrid gap={1} flexWrap="nowrap">
                                    {showAssigned && (
                                        <CreativeWarning
                                            color="info.main"
                                            icon={<InfoOutlined fontSize="inherit" />}
                                            label={t(
                                                'Modules.Main.CreativeManagement.creatives.assignDialog.lineItemAlreadyAssigned',
                                            )}
                                        />
                                    )}
                                    {showResMatchError && (
                                        <CreativeWarning
                                            color="warning.main"
                                            icon={<WarningAmber fontSize="inherit" />}
                                            label={t(
                                                'Modules.Main.CreativeManagement.creatives.assignDialog.lineItemResolutionMismatch',
                                            )}
                                        />
                                    )}
                                </AutoGrid>
                            </Box>
                        </Box>
                    </Box>
                </TableCell>
                <TableCell {...greyTdProps}>{groupNames}</TableCell>
                <TableCell {...greyTdProps}>{file.fileTypeSimple}</TableCell>
                <TableCell {...greyTdProps}>
                    {file.durationMs > 0 ? <FileDuration file={file} /> : '-'}
                </TableCell>
                <TableCell {...greyTdProps}>{generateFileResolution(file)}</TableCell>
                <TableCell {...greyTdProps}>{file.tags.aspectRatio}</TableCell>
                <TableCell {...greyTdProps}>
                    {formatDateString({ date: file.createdAt, removeTime: false })}
                </TableCell>
                <TableCell {...greyTdProps}>{file.assignedDisplays}</TableCell>
            </TableRow>
        </>
    );
};

const CreativeWarning: React.FC<{
    color: TypographyProps['color'];
    icon: React.ReactNode;
    label: string;
}> = ({ color, icon, label }) => (
    <Typography color={color} variant="caption">
        <Box display="flex" alignItems="flex-start">
            {icon} <Box ml={0.25}>{label}</Box>
        </Box>
    </Typography>
);

export default LineItemAssignCreativeListItem;
