import React from 'react';
import { ListItemText as MuiListItemText, ListItemTextProps } from '@mui/material';

type Props = ListItemTextProps;

const ListItemText: React.FC<Props> = ({ primaryTypographyProps, ...props }) => (
    <MuiListItemText
        primaryTypographyProps={{ color: 'textPrimary', ...primaryTypographyProps }}
        {...props}
    />
);

export default ListItemText;
