import { ShortcutPrefixRegex } from './constants';
import { SystemSearchSearchParts } from '../types';

function searchParts(searchTerm: string): SystemSearchSearchParts {
    const prefix = new RegExp(ShortcutPrefixRegex).exec(searchTerm)?.[0] ?? '';

    return {
        prefix: prefix.replace(':', '').toLowerCase(),
        term: searchTerm.substring(prefix.length)?.trim() ?? '',
    };
}

export default searchParts;
