import React, { Ref } from 'react';
import Button from 'c-components/Forms/Button';
import MuiRouterLink from 'c-components/MuiRouterLink';
import { LinkProps } from 'react-router-dom';
import { ButtonProps } from 'c-components/Forms/Button/Button';

const LinkButton = ({ to, children, ...props }: ButtonProps & LinkProps, ref: Ref<any>) => {
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Button component={MuiRouterLink} to={to} {...props} ref={ref}>
            {children}
        </Button>
    );
};

export default React.forwardRef(LinkButton);
