import React, { useCallback, useMemo } from 'react';
import { AutoGrid, Button } from 'c-components';
import { ReportType } from 'c-reports/Types';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import ChartExportFormGeneral from './ChartExportFormGeneral';
import ChartExportFormCategorical from './ChartExportFormCategorical';
import BarChartSegments from './BarChartSegments';
import LineChartSegments from './LineChartSegments';
import { ChartFormDataSchema } from '../types';

type Props = {
    defaultData: Partial<ChartFormDataSchema>;
    type: ReportType;
};

const ChartExportForm: React.FC<Props> = ({ type, defaultData }) => {
    const isCategoricalChart = useMemo(() => type !== ReportType.Table, [type]);
    const { reset } = useFormContext();

    const resetForm = useCallback(() => {
        reset({ ...defaultData });
    }, [reset, defaultData]);
    return (
        <AutoGrid spacing={2} xs={12}>
            <Box>
                <Button variant="outlined" onClick={resetForm}>
                    {useCommonTranslation('Reporting.exporting.form.resetFormLabel')}
                </Button>
            </Box>
            <ChartExportFormGeneral isCategorical={isCategoricalChart} />
            {isCategoricalChart && <ChartExportFormCategorical type={type} />}
            {type === ReportType.Bar && <BarChartSegments />}
            {type === ReportType.Line && <LineChartSegments />}
        </AutoGrid>
    );
};

export default ChartExportForm;
