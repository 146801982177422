import { last, uniq } from 'ramda';

export type ItemId = string | number;

export function updateListSelection<IdType extends ItemId>(
    currentSelected: IdType[],
    currentVisible: IdType[],
    toggleId: IdType,
    usedShiftKey: boolean,
): IdType[] {
    const lastSelectedId = last(currentSelected);
    const lastSelectedIndex = currentVisible.indexOf(lastSelectedId);
    const toggledIdCurrentlySelected = currentSelected.indexOf(toggleId) !== -1;

    if (
        !usedShiftKey || // toggling single item
        lastSelectedId == null || // haven't got anything selected at the moment
        lastSelectedId === toggleId || // last id toggle is the same as this one
        lastSelectedIndex === -1 // last id toggled isn't currently visible
    ) {
        // not currently selected, so add to the selection l
        if (!toggledIdCurrentlySelected) return [...currentSelected, toggleId];

        return currentSelected.filter(id => id !== toggleId);
    }

    const selectedIdIndex = currentVisible.indexOf(toggleId);

    // get item ids between the two selected with shift down so we can select a range
    const filesInBetween = currentVisible.slice(
        Math.min(lastSelectedIndex, selectedIdIndex),
        Math.max(selectedIdIndex, lastSelectedIndex) + 1,
    );

    const withoutBetween = currentSelected.reduce((currItemIds, itemId) => {
        if (!filesInBetween.some(id => id === itemId)) currItemIds.push(itemId);
        return currItemIds;
    }, [] as IdType[]);

    // if not currently selected, add the files inbetween to the selected files
    if (!toggledIdCurrentlySelected) return uniq([...withoutBetween, ...filesInBetween]);

    // if is currently selected, we're deselecting the files between the last selected and new selected.
    return uniq(withoutBetween);
}
