import { uniq } from 'ramda';
import { DisplayMediaBreakdownData } from '@uniled/api-sdk';
import { dateSort } from 'c-lib';

export type LogFileParsedTotals = { count: number; duration: number };
export type LogFileParsedCountsByDay = Record<
    string,
    LogFileParsedTotals & { hours: Record<string, LogFileParsedTotals> }
>;
export type LogFileParsedCountsByMedia = Record<string, LogFileParsedCountsByDay>; // get is the media name
export type LogFileParsedAllCounts = {
    media: LogFileParsedCountsByMedia;
    day: LogFileParsedCountsByDay;
    uniqueDays: string[];
    uniqueHours: string[];
    uniqueDayHours: string[];
    uniqueMediaNames: string[];
};

export const parsedLogFileParsedMediaDataDates = (data: LogFileParsedAllCounts) =>
    data.uniqueDays.map(date => new Date(date)).sort((a, b) => dateSort(a, b, 'asc'));

export const parseLogFileParsedMediaData = (rawData: DisplayMediaBreakdownData[]) =>
    rawData.reduce(
        (totals, record) => {
            // totals grouped by date, hour
            const newDayTotals = totals.day;
            if (!newDayTotals[record.date])
                newDayTotals[record.date] = { count: 0, duration: 0, hours: {} };
            if (!newDayTotals[record.date].hours[record.hour])
                newDayTotals[record.date].hours[record.hour] = {
                    count: 0,
                    duration: 0,
                };

            newDayTotals[record.date].count += record.count;
            newDayTotals[record.date].duration += record.duration;
            newDayTotals[record.date].hours[record.hour].count += record.count;
            newDayTotals[record.date].hours[record.hour].duration += record.duration;

            // totals grouped by media name, date, hour
            const newMediaTotals = totals.media;
            if (!newMediaTotals[record.media_name]) newMediaTotals[record.media_name] = {};

            if (!newMediaTotals[record.media_name][record.date])
                newMediaTotals[record.media_name][record.date] = {
                    count: 0,
                    duration: 0,
                    hours: {},
                };
            if (!newMediaTotals[record.media_name][record.date].hours[record.hour])
                newMediaTotals[record.media_name][record.date].hours[record.hour] = {
                    count: 0,
                    duration: 0,
                };

            newMediaTotals[record.media_name][record.date].count += record.count;
            newMediaTotals[record.media_name][record.date].duration += record.duration;
            newMediaTotals[record.media_name][record.date].hours[record.hour].count += record.count;
            newMediaTotals[record.media_name][record.date].hours[record.hour].duration +=
                record.duration;

            return {
                day: newDayTotals,
                media: newMediaTotals,
                uniqueDays: uniq([...totals.uniqueDays, record.date]),
                uniqueHours: uniq([...totals.uniqueHours, record.hour]),
                uniqueDayHours: uniq([...totals.uniqueDayHours, `${record.date} ${record.hour}`]),
                uniqueMediaNames: uniq([...totals.uniqueMediaNames, record.media_name]),
            };
        },
        {
            day: {},
            media: {},
            uniqueDays: [],
            uniqueHours: [],
            uniqueDayHours: [],
            uniqueMediaNames: [],
        } as LogFileParsedAllCounts,
    );
