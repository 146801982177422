import { atom, useAtom } from 'jotai/index';
import { Displays_LineItem, ScheduleGet } from '@uniled/api-sdk';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import { useCallback, useEffect, useMemo } from 'react';
import to from 'await-to-js';
// eslint-disable-next-line import/no-extraneous-dependencies

import uniq from 'lodash/uniq';

interface Item {
    id: number | string;
    name: string;
    owner: string;
    schedules: string[];
    type: string;
}
const atom_newCampaignId = atom<number>(null as number);
const atom_schdeuleLineItems = atom<Displays_LineItem[]>([]);
const atom_schedules = atom<ScheduleGet[]>([]);
const atom_newSelectedLineItems = atom<Item[]>([]);
const atom_pages = atom<number>(1);
const atom_page = atom<number>(1);
const atom_screens = atom([]);
const atom_firstScreen = atom<string>('');
const atom_lastScreen = atom<string>('');
const atom_loading = atom<boolean>(false);
const atom_paginationPage = atom<number>(1);
const atom_paginationTotalPages = atom<number>(0);
const aton_perPage = atom<number>(25);
const atom_rowCount = atom<number>(0);
const atom_targets = atom([]);
const atom_screensCurrentPage = atom<number>(1);
const atom_screensTotalPages = atom<number>(0);
const atom_selectedScreens = atom<Item[]>([]);
const atom_screensRowCount = atom<number>(0);
const atom_screensPerPage = atom<number>(25);
const atom_prevSelectedScreens = atom<string>('');
const atom_loadingScreen = atom<boolean>(false);
const atom_schedulesCurrentPage = atom<number>(1);
const atom_schedulesTotalPages = atom<number>(0);
const atom_schedulesRowCount = atom<number>(0);
const atom_schedulesPerPage = atom<number>(25);
const atom_loadingSchedule = atom<boolean>(false);
const atom_expandedScheduleId = atom<number | null>(null as number);
const sortSchedules = (schedules: ScheduleGet[]) =>
    schedules.sort((a, b) => (b.is_default ? 1 : -1) - (a.is_default ? 1 : -1));
const useNewCampaignSchedule = id => {
    const [currentCampaignId, setCurrentCampaignId] = useAtom(atom_newCampaignId);
    const [scheduleLineItems, setScheduleLineItems] = useAtom(atom_schdeuleLineItems);
    const [schedules, setSchedules] = useAtom(atom_schedules);
    const [selected, setSelected] = useAtom(atom_newSelectedLineItems);
    const [pages, setPages] = useAtom(atom_pages);
    const [page, setPage] = useAtom(atom_page);
    const [screens, setScreens] = useAtom(atom_screens);
    const [firstScreen, setFirstScreen] = useAtom(atom_firstScreen);
    const [lastScreen, setLastScreen] = useAtom(atom_lastScreen);
    const [loading, setLoading] = useAtom(atom_loading);
    const [currentPage, setCurrentPage] = useAtom(atom_paginationPage);
    const [totalPages, setTotalPages] = useAtom(atom_paginationTotalPages);
    const [perPage, setPerPage] = useAtom(aton_perPage);
    const [rowCount, setRowCount] = useAtom(atom_rowCount);
    const [targets, setTargets] = useAtom(atom_targets);
    const [screensCurrentPage, setScreensCurrentPage] = useAtom(atom_screensCurrentPage);
    const [screensTotalPages, setScreensTotalPages] = useAtom(atom_screensTotalPages);
    const [selectedScreens, setSelectedScreens] = useAtom(atom_selectedScreens);
    const [prevSelectedLineItemId, setPrevSelectedLineItemId] = useAtom(atom_prevSelectedScreens);
    const [screensRowCount, setScreensRowCount] = useAtom(atom_screensRowCount);
    const [screensPerPage, setScreensPerPage] = useAtom(atom_screensPerPage);
    const [screenLoading, setScreenLoading] = useAtom(atom_loadingScreen);
    const [schedulesCurrentPage, setSchedulesCurrentPage] = useAtom(atom_schedulesCurrentPage);
    const [schedulesTotalPages, setSchedulesTotalPages] = useAtom(atom_schedulesTotalPages);
    const [schedulesRowCount, setSchedulesRowCount] = useAtom(atom_schedulesRowCount);
    const [schedulesPerPage, setSchedulesPerPage] = useAtom(atom_schedulesPerPage);
    const [scheduleLoading, setScheduleLoading] = useAtom(atom_loadingSchedule);
    const [expandedScheduleId, setExpandedScheduleId] = useAtom(atom_expandedScheduleId);

    const toggleSelectItem = useCallback(
        (item: Item) => {
            setSelected(prevSelected => {
                if (prevSelected.some(selectedItem => selectedItem.id === item.id)) {
                    return prevSelected.filter(selectedItem => selectedItem.id !== item.id);
                }
                return [...prevSelected, item];
            });
        },
        [setSelected],
    );
    const scheduleIds = useMemo(() => {
        const ids = schedules.map(schedule => schedule.id);
        return uniq(ids);
    }, [schedules]);

    const lineItemIds = useMemo(() => {
        const ids = scheduleLineItems.map(lineItem => lineItem.id);
        return uniq(ids);
    }, [scheduleLineItems]);
    const { start, isLoading } = useAPIClientRequest(ApiClient.Entities.Campaign.schdeuleLineItems);
    const { start: startScreen, isLoading: isLoadingScreen } = useAPIClientRequest(
        ApiClient.Entities.Campaign.screenFromSchedule,
    );
    const { start: startSchedules, isLoading: isLoadingSchedules } = useAPIClientRequest(
        ApiClient.Entities.Campaign.schedules,
    );
    const { start: startTargets } = useAPIClientRequest(ApiClient.Entities.Campaign.listTargets);

    useEffect(() => {
        if (id !== null && id !== currentCampaignId) {
            setCurrentCampaignId(id);
            setSchedules([]);
            setScheduleLineItems([]);
            setSelected([]);
            setPages(1);
            setPage(1);
            setScreens([]);
            setFirstScreen('');
            setLastScreen('');
            setLoading(false);
            setCurrentPage(1);
            setTotalPages(0);
            setPerPage(25);
            setRowCount(0);
            setTargets([]);
            setScreensCurrentPage(1);
            setScreensTotalPages(0);
            setSelectedScreens([]);
            setPrevSelectedLineItemId('');
            setScreensRowCount(0);
            setScreensPerPage(25);
            setSchedulesCurrentPage(1);
            setSchedulesTotalPages(0);
            setSchedulesRowCount(0);
            setSchedulesPerPage(25);
            setScheduleLoading(false);
            setScreenLoading(false);
        }
    }, [
        currentCampaignId,
        id,
        setCurrentCampaignId,
        setSchedules,
        setScheduleLineItems,
        setSelected,
        setPages,
        setPage,
        setScreens,
        setFirstScreen,
        setLastScreen,
        setLoading,
        setCurrentPage,
        setTotalPages,
        setPerPage,
        setRowCount,
        setTargets,
        setScreensCurrentPage,
        setScreensTotalPages,
        setSelectedScreens,
        setPrevSelectedLineItemId,
        setScreensRowCount,
        setScreensPerPage,
        setSchedulesCurrentPage,
        setSchedulesTotalPages,
        setSchedulesRowCount,
        setSchedulesPerPage,
        setScheduleLoading,
        setScreenLoading,
    ]);

    const updateSchedules = useCallback(
        (newSchedules: ScheduleGet[]) => {
            const newScheduleList = [...schedules];

            newSchedules.forEach(schedule => {
                const index = schedules.findIndex(s => s.id === schedule.id);
                if (index == -1) newScheduleList.push(schedule);
                else newScheduleList[index] = schedule;
            });

            setSchedules(newScheduleList);
        },
        [setSchedules, schedules],
    );
    const removeSchedules = useCallback(
        (scheduleIds: number[]) => {
            setSchedules(schedules.filter(s => scheduleIds.indexOf(s.id) === -1));
        },
        [setSchedules, schedules],
    );
    const closePack = useCallback(
        (expandedId, id) => {
            if (expandedId == id) {
                setPages(0);
                setPage(1);
                setScheduleLineItems(
                    scheduleLineItems.filter(item => (item as any).type !== 'Display'),
                );
                setScreens([]);
                setFirstScreen('');
                setLastScreen('');
            }
        },
        [
            setPages,
            setPage,
            setScheduleLineItems,
            scheduleLineItems,
            setScreens,
            setFirstScreen,
            setLastScreen,
        ],
    );

    const getScheduleLineItems = useCallback(
        async (page = 1, perPage = 25) => {
            if (isLoading) {
                return;
            }
            const [err, success] = await to(start(id, ['owner', 'frame_id'], page, perPage));
            if (!err && success && success?.data && Array.isArray(success?.data?.data)) {
                setScheduleLineItems(success.data.data);
                setCurrentPage((success?.data as any)?.meta.pagination.current_page);
                setTotalPages((success?.data as any)?.meta.pagination.total_pages);
                setRowCount((success?.data as any)?.meta.pagination.total);
            }
        },
        [isLoading, start, id, setScheduleLineItems, setCurrentPage, setTotalPages, setRowCount],
    );

    const listTargets = useCallback(async () => {
        const [err, success] = await to(startTargets(id));
        if (!err && success && success?.data && Array.isArray(success?.data?.data)) {
            setTargets(success.data.data);
        }
    }, [id, setTargets, startTargets]);

    const getScreensFromLineItem = useCallback(
        async (lineItemId, page = 1, perPage = 25) => {
            if (lineItemId !== prevSelectedLineItemId) {
                setScreens([]);
                setScreensTotalPages(0);
                setScreensRowCount(0);
                setScreensCurrentPage(1);
                setScreensPerPage(25);
            }
            setPages(1);
            const [err, success] = await to(startScreen(id, lineItemId, [], page, perPage));
            if (!err && success && success.data && Array.isArray(success.data.data)) {
                const lineItem = scheduleLineItems.find(item => item.id === lineItemId);
                const owner = lineItem ? lineItem.owner : null;

                const newLineItems = success.data.data.map(item => ({
                    ...item,
                    schedules: item.schedules.data,
                    owner,
                }));
                setScreens(newLineItems);
                setScreensTotalPages((success as any).data.meta.pagination.total_pages);
                setScreensCurrentPage((success?.data as any)?.meta.pagination.current_page);
                setScreensRowCount((success?.data as any)?.meta.pagination.total);
                setPrevSelectedLineItemId(lineItemId);
            }
        },
        [
            prevSelectedLineItemId,
            setPages,
            startScreen,
            id,
            setScreens,
            setScreensTotalPages,
            setScreensRowCount,
            setScreensCurrentPage,
            setScreensPerPage,
            scheduleLineItems,
            setPrevSelectedLineItemId,
        ],
    );

    const getSchedules = useCallback(
        async (page = 1, perPage = 25) => {
            const [err, success] = await to(startSchedules(id, ['rules'], page, perPage));
            if (!err && success && success?.data && Array.isArray(success?.data?.data)) {
                let defaultSchedule = schedules.find(schedule => schedule.is_default);
                const newSchedules = success.data.data;

                const newDefaultSchedule = newSchedules.find(schedule => schedule.is_default);

                if (newDefaultSchedule) {
                    defaultSchedule = newDefaultSchedule;
                }

                if (defaultSchedule && !newDefaultSchedule) {
                    newSchedules.push(defaultSchedule);
                }
                const sortedSchedules = sortSchedules(newSchedules);
                setSchedules(sortedSchedules);
                setSchedulesTotalPages((success as any).data.meta.pagination.total_pages);
                setSchedulesCurrentPage((success?.data as any)?.meta.pagination.current_page);
                setSchedulesRowCount((success?.data as any)?.meta.pagination.total);
            }
        },
        [
            startSchedules,
            id,
            schedules,
            setSchedules,
            setSchedulesTotalPages,
            setSchedulesCurrentPage,
            setSchedulesRowCount,
        ],
    );

    const fetchData = useCallback(() => {
        getScheduleLineItems();
        getSchedules();
        listTargets();
    }, [getScheduleLineItems, getSchedules, listTargets]);

    useEffect(() => {
        setLoading(isLoading || isLoadingScreen);
    }, [isLoading, isLoadingSchedules, isLoadingScreen, setLoading]);

    useEffect(() => {
        setScreenLoading(isLoadingScreen);
    }, [isLoadingScreen, setScreenLoading]);
    useEffect(() => {
        setScheduleLoading(isLoadingSchedules);
    }, [isLoadingSchedules, setScheduleLoading]);

    const handlePageSizeChange = useCallback(
        newPageSize => {
            setPerPage(newPageSize);
            getScheduleLineItems(currentPage, newPageSize);
        },
        [getScheduleLineItems, currentPage, setPerPage],
    );
    const handleScreensPageChange = useCallback(
        newPage => {
            setScreensCurrentPage(newPage);
            getScreensFromLineItem(prevSelectedLineItemId, newPage, screensPerPage);
        },
        [getScreensFromLineItem, screensPerPage, setScreensCurrentPage, prevSelectedLineItemId],
    );
    const handleScreensPageSizeChange = useCallback(
        newPageSize => {
            setScreensPerPage(newPageSize);
            getScreensFromLineItem(prevSelectedLineItemId, screensCurrentPage, newPageSize);
        },
        [setScreensPerPage, getScreensFromLineItem, prevSelectedLineItemId, screensCurrentPage],
    );
    const handlePageChange = useCallback(
        newPage => {
            setCurrentPage(newPage);
            getScheduleLineItems(newPage, perPage);
        },
        [getScheduleLineItems, perPage, setCurrentPage],
    );
    const handleSchedulesPageChange = useCallback(
        newPage => {
            setSchedulesCurrentPage(newPage);
            getSchedules(newPage, schedulesPerPage);
        },
        [getSchedules, schedulesPerPage, setSchedulesCurrentPage],
    );

    const handleSchedulesPageSizeChange = useCallback(
        newPageSize => {
            setSchedulesPerPage(newPageSize);
            getSchedules(schedulesCurrentPage, newPageSize);
        },
        [getSchedules, schedulesCurrentPage, setSchedulesPerPage],
    );
    return useMemo(
        () => ({
            fetchData,
            scheduleLineItems,
            schedules,
            getScheduleLineItems,
            getSchedules,
            scheduleIds,
            lineItemIds,
            loading,
            toggleSelectItem,
            selected,
            setSelected,
            setScheduleLineItems,
            getScreensFromLineItem,
            pages,
            page,
            closePack,
            firstScreen,
            lastScreen,
            handlePageChange,
            currentPage,
            totalPages,
            rowCount,
            perPage,
            setPerPage,
            handlePageSizeChange,
            updateSchedules,
            removeSchedules,
            targets,
            setTargets,
            listTargets,
            setScreens,
            screens,
            selectedScreens,
            setSelectedScreens,
            screensCurrentPage,
            screensTotalPages,
            screensRowCount,
            handleScreensPageChange,
            handleScreensPageSizeChange,
            screensPerPage,
            screenLoading,
            handleSchedulesPageSizeChange,
            handleSchedulesPageChange,
            schedulesCurrentPage,
            schedulesTotalPages,
            schedulesRowCount,
            schedulesPerPage,
            setPrevSelectedLineItemId,
            prevSelectedLineItemId,
            scheduleLoading,
            expandedScheduleId,
            setExpandedScheduleId,
        }),
        [
            fetchData,
            scheduleLineItems,
            schedules,
            getScheduleLineItems,
            getSchedules,
            scheduleIds,
            lineItemIds,
            loading,
            toggleSelectItem,
            selected,
            setSelected,
            setScheduleLineItems,
            getScreensFromLineItem,
            pages,
            page,
            closePack,
            firstScreen,
            lastScreen,
            handlePageChange,
            currentPage,
            totalPages,
            rowCount,
            perPage,
            setPerPage,
            handlePageSizeChange,
            updateSchedules,
            removeSchedules,
            targets,
            setTargets,
            listTargets,
            setScreens,
            screens,
            selectedScreens,
            setSelectedScreens,
            screensCurrentPage,
            screensTotalPages,
            screensRowCount,
            handleScreensPageChange,
            handleScreensPageSizeChange,
            screensPerPage,
            screenLoading,
            handleSchedulesPageSizeChange,
            handleSchedulesPageChange,
            schedulesCurrentPage,
            schedulesTotalPages,
            schedulesRowCount,
            schedulesPerPage,
            setPrevSelectedLineItemId,
            prevSelectedLineItemId,
            scheduleLoading,
            expandedScheduleId,
            setExpandedScheduleId,
        ],
    );
};
export default useNewCampaignSchedule;
