import React, { useCallback, useEffect } from 'react';
import { Button, DialogV2 } from 'c-components';
import { PermissionName } from '@uniled/api-sdk';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { useBoolean, useArray } from 'react-hanger';
import { useAPIClientRequest, useAuthenticatedUser } from 'c-hooks';
import { useCommonTranslation } from 'c-translation';
import apiClient from 'c-data/apiClient';
import ChatWrapper from 'c-main/Components/Chat/ChatWrapper';
import to from 'await-to-js';
import { Box, Stack, Typography } from '@mui/material';
import { Dot } from 'react-animated-dots';
import useCampaignWrapperState from 'c-main/Components/Campaign/CampaignReports/useCampaignWrapperState';

type Props = {
    campaignId: number;
};
const ai = { name: 'Assistant' };
const ChatPerms = [PermissionName.Admin];

const CampaignChat: React.FC<Props> = ({ campaignId }) => {
    const { setTrue, setFalse, value: isDialogOpen } = useBoolean(false);
    const { user } = useAuthenticatedUser();
    const t = useCommonTranslation();
    const { value: messages, add, setValue: setMessages } = useArray([]);
    const { source } = useCampaignWrapperState(campaignId);
    const {
        start: getChat,
        isLoading: chatLoading,
        data: chatData,
        reset: getReset,
    } = useAPIClientRequest(apiClient.Entities.Campaign_Chat.getChat);
    const {
        start: sendMessage,
        isLoading: sendLoading,
        hasFailed: sendFailed,
        reset: sendReset,
        hasSucceeded: sendSucceed,
    } = useAPIClientRequest(apiClient.Entities.Campaign_Chat.sendMessage);
    const { start: refreshChat, isLoading: refreshLoading } = useAPIClientRequest(
        apiClient.Entities.Campaign_Chat.startNewChatSession,
    );
    const closeDialog = useCallback(() => {
        setFalse();
        sendReset();
        getReset();
    }, [getReset, sendReset, setFalse]);

    const refreshButtonHandler = useCallback(async () => {
        const [, result] = await to(refreshChat(campaignId, source));
        setMessages((result as any)?.data?.data?.messages);
    }, [campaignId, refreshChat, setMessages, source]);

    useEffect(() => {
        if (isDialogOpen) {
            getChat(campaignId, source);
        }
    }, [campaignId, getChat, isDialogOpen, source]);

    useEffect(() => {
        if ((chatData as any)?.data?.data?.messages) {
            const adjustedMessages = (chatData as any).data.data.messages.map(msg => ({
                ...msg,
                createdAt: msg.created_at,
                createdBy: msg.from === 'user' ? user : ai,
                content: msg.message,
            }));
            setMessages(adjustedMessages);
        }
    }, [chatData, setMessages, user]);
    const handleSendMessage = useCallback(
        async message => {
            if (message.trim() && !sendLoading) {
                add({
                    content: message,
                    createdAt: new Date().toISOString(),
                    createdBy: user,
                });

                setTimeout(() => {
                    add({
                        content: (
                            <Box>
                                Typing <Dot>.</Dot>
                                <Dot>.</Dot>
                                <Dot>.</Dot>
                            </Box>
                        ),
                        createdAt: new Date().toISOString(),
                        createdBy: ai,
                    });
                }, 500);
                const [, result] = await to(sendMessage(campaignId, message, source));
                if ((result as any)?.data?.data?.messages) {
                    const adjustedMessages = (result as any).data.data.messages.map(msg => ({
                        ...msg,
                        createdAt: msg.created_at,
                        createdBy: msg.from === 'user' ? user : ai,
                        content: msg.message,
                    }));
                    setMessages(adjustedMessages);
                }
            }
        },
        [sendLoading, add, user, sendMessage, campaignId, source, setMessages],
    );
    return (
        <>
            <IfHasAllPermissions permissions={ChatPerms}>
                <Button size="small" variant="outlined" onClick={setTrue} id="campaign-header-chat">
                    {t('Modules.Main.Campaigns.Ai.ButtonLabel')}
                </Button>
            </IfHasAllPermissions>

            <DialogV2
                onClose={closeDialog}
                open={isDialogOpen}
                contentHeight="70vh"
                maxWidth="md"
                title={t('Modules.Main.Campaigns.Ai.title')}
                titleDivider
            >
                <Stack gap={2}>
                    <ChatWrapper
                        localUser={user}
                        messages={messages}
                        messageListLoading={chatLoading}
                        onSendMessage={handleSendMessage}
                        newMessageLoading={sendLoading}
                        newMessageSuccess={sendSucceed}
                        emptyMessagesContent={
                            <Typography variant="body2" color="grey.500">
                                {t('Modules.Main.Campaigns.Ai.aiNoMessages')}
                            </Typography>
                        }
                        newMessagePlaceholder={t('Modules.Main.Campaigns.Ai.messagePlaceholder')}
                        includeAttachments={false}
                    />
                    <Typography variant="subtitle1" align="center">
                        {t('Modules.Main.Campaigns.Ai.accuracyMessage')}
                    </Typography>
                    <Box justifyContent="center" display="flex" mb={2}>
                        <Button
                            size="small"
                            onClick={refreshButtonHandler}
                            disabled={refreshLoading}
                        >
                            {t('Modules.Main.Campaigns.Ai.restartChat')}
                        </Button>
                    </Box>
                </Stack>
            </DialogV2>
        </>
    );
};

export default CampaignChat;
