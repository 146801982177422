import React from 'react';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import LogFileImportListPage from 'c-pop-displays/Pages/LogFileImportListPage';

import LogImportSourceListPage from 'c-pop-displays/Pages/LogImportSourceListPage';
import PlayoutExportPage from 'c-pop-displays/Pages/PlayoutExportPage';
import DisplayListPage from 'c-pop-displays/Pages/DisplayListPage';
import ChannelFrameListPage from 'c-pop-displays/Pages/ChannelFrameListPage/ChannelFrameListPage';
import VirtualCampaignBuilder from 'c-pop-displays/Pages/VirtualCampaignBuilder';
import { DashboardTile } from 'c-admin/Components';
import { DashboardTileSize } from 'c-dashboard-module/types';
import { Translate, useCommonTranslation } from 'c-translation';
import { Box, Grid, Typography } from '@mui/material';

const PopDisplaysAdminTiles = () => (
    <Box>
        <Grid container gap={4}>
            <>
                <IfHasAllPermissions
                    permissions={VirtualCampaignBuilder.systemSearch.permissions}
                    disableDebugWrap
                >
                    <DashboardTile
                        id="admin-pop-virtual-campaign-builder"
                        size={DashboardTileSize.Medium}
                        title={
                            (
                                <Translate path="Navigation.popDisplaysVirtualCampaignBuilder" />
                            ) as any
                        }
                        titleIcon={VirtualCampaignBuilder.systemSearch.icon}
                        route={VirtualCampaignBuilder.systemSearch.route}
                        permissions={VirtualCampaignBuilder.systemSearch.permissions}
                    />
                </IfHasAllPermissions>
                <IfHasAllPermissions
                    permissions={DisplayListPage.systemSearch.permissions}
                    disableDebugWrap
                >
                    <DashboardTile
                        id="admin-pop-displays"
                        size={DashboardTileSize.Medium}
                        title={(<Translate path="Navigation.popDisplaysDisplays" />) as any}
                        titleIcon={DisplayListPage.systemSearch.icon}
                        route={DisplayListPage.systemSearch.route}
                        permissions={DisplayListPage.systemSearch.permissions}
                    />
                </IfHasAllPermissions>
                <IfHasAllPermissions
                    permissions={DisplayListPage.systemSearch.permissions}
                    disableDebugWrap
                >
                    <DashboardTile
                        id="admin-pop-channel-frames"
                        size={DashboardTileSize.Medium}
                        title={(<Translate path="Navigation.popDisplaysChannelFrames" />) as any}
                        titleIcon={ChannelFrameListPage.systemSearch.icon}
                        route={ChannelFrameListPage.systemSearch.route}
                        permissions={ChannelFrameListPage.systemSearch.permissions}
                    />
                </IfHasAllPermissions>
            </>
        </Grid>

        <Box>
            <Typography variant="h4" color="textSecondary" my={2}>
                {useCommonTranslation('Modules.PopDisplays.AdminHome.logsTitle')}
            </Typography>
            <Grid container columnGap={4}>
                <IfHasAllPermissions
                    permissions={LogFileImportListPage.systemSearch.permissions}
                    disableDebugWrap
                >
                    <DashboardTile
                        id="admin-pop-log-file-imports"
                        size={DashboardTileSize.Medium}
                        title={(<Translate path="Navigation.popDisplaysLogFileImports" />) as any}
                        titleIcon={LogFileImportListPage.systemSearch.icon}
                        route={LogFileImportListPage.systemSearch.route}
                        permissions={LogFileImportListPage.systemSearch.permissions}
                    />
                </IfHasAllPermissions>
                <IfHasAllPermissions
                    permissions={LogFileImportListPage.systemSearch.permissions}
                    disableDebugWrap
                >
                    <DashboardTile
                        id="admin-pop-log-file-import-sources"
                        size={DashboardTileSize.Medium}
                        title={(<Translate path="Navigation.popDisplaysLogImportSource" />) as any}
                        titleIcon={LogImportSourceListPage.systemSearch.icon}
                        route={LogImportSourceListPage.systemSearch.route}
                        permissions={LogImportSourceListPage.systemSearch.permissions}
                    />
                </IfHasAllPermissions>
                <IfHasAllPermissions
                    permissions={PlayoutExportPage.systemSearch.permissions}
                    disableDebugWrap
                >
                    <DashboardTile
                        id="admin-pop-playout-export"
                        size={DashboardTileSize.Medium}
                        title={(<Translate path="Navigation.popDisplayPlayoutExport" />) as any}
                        titleIcon={PlayoutExportPage.systemSearch.icon}
                        route={PlayoutExportPage.systemSearch.route}
                        permissions={PlayoutExportPage.systemSearch.permissions}
                    />
                </IfHasAllPermissions>
            </Grid>
        </Box>
    </Box>
);

export default PopDisplaysAdminTiles;
