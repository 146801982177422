import React from 'react';
import { FilterList, KeyboardArrowDown, LocationOn } from '@mui/icons-material';
import { Box } from '@mui/material';
import { AutoGrid, Button } from 'c-components';
import { Translate, useCommonTranslation } from 'c-translation';
import { PCAViewControlProps } from './types';
import TypeSwitcher from './TypeSwitcher';
import FieldSwitcher from './FieldSwitcher';
import DataStatusSelector from './DataStatusSelector';
import { ReportView } from '../types';
// import SearchInput from './SearchInput';

type Props = PCAViewControlProps;

const PCAViewControls: React.FC<Props> = ({
    view,
    onViewChange,
    // searchValue,
    // onSearchValueUpdated,
    type,
    onTypeChange,
    metric,
    field,
    onFieldSwitch,
    showAdvancedFilters,
    filtersCount,

    removeMapView,
    removeOverallView,
    typeSwitcherDisabled = false,

    notices,
    onDataStatusChange,
    dataStatus,
}) => {
    // const showSearch = useMemo(
    //     () => view === ReportView.Field && type === FieldReportType.OverallLeaderboard,
    //     [view, type],
    // );
    return (
        <>
            {/* {showSearch && ( */}
            {/*    <SearchInput */}
            {/*        searchValue={searchValue} */}
            {/*        onSearchValueUpdated={onSearchValueUpdated} */}
            {/*    /> */}
            {/* )} */}
            <Box ml="auto">
                <AutoGrid spacing={2}>
                    {notices.length > 0 && (
                        <DataStatusSelector
                            notices={notices}
                            onDataStatusChange={onDataStatusChange}
                            dataStatus={dataStatus}
                        />
                    )}
                    <Button
                        onClick={showAdvancedFilters?.toggle}
                        color={filtersCount > 0 ? 'primary' : 'secondary'}
                        className="pca-filter-by"
                    >
                        <FilterList sx={{ mr: 0.5 }} />
                        {useCommonTranslation('Modules.Main.Campaigns.Overview.Reports.filterBy')}
                        <KeyboardArrowDown />
                    </Button>
                    <FieldSwitcher
                        field={field}
                        metric={metric}
                        onFieldSwitch={onFieldSwitch}
                        view={view}
                        onViewChange={onViewChange}
                        removeOverallView={removeOverallView}
                    />

                    <TypeSwitcher
                        type={type}
                        onTypeChange={onTypeChange}
                        field={field}
                        view={view}
                        disabled={typeSwitcherDisabled}
                    />
                    {!removeMapView && (
                        <Button
                            color={view === ReportView.Map ? 'primary' : 'secondary'}
                            onClick={() => onViewChange(ReportView.Map)}
                            sx={{ display: 'flex', alignItems: 'center' }}
                            className="pca-map"
                        >
                            <LocationOn sx={{ mr: 0.5 }} />
                            <Translate path="Modules.Main.Campaigns.Overview.Reports.byMap" />
                        </Button>
                    )}
                </AutoGrid>
            </Box>
        </>
    );
};

export default PCAViewControls;
