import React, { useCallback, useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Campaign } from '@uniled/api-sdk';
import { AutoGrid, Button, Checkbox } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { Description } from '@mui/icons-material';
import { useBoolean } from 'react-hanger';
import { useUpdateEntityData } from 'c-data';
import { NetworkRequestState } from '@uniled/data-layer';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { getCampaignBookingSummaries } from 'c-main/Lib/Campaign';
import UpdatedByUserCaption from 'c-components/UpdatedByUserCaption';
import CampaignBookingSummaryDialog from '../CampaignBookingSummary/CampaignBookingSummaryDialog';

type Props = RenderColumnDataProps<Campaign>;

const CampaignBookingSummaryTableCell: React.FC<Props> = ({ entity }) => {
    const count = useMemo(() => getCampaignBookingSummaries(entity).length, [entity]);

    const dialogState = useBoolean(false);

    const { update, getUpdatingById } = useUpdateEntityData<Campaign>('Campaign');
    const loading = useMemo(
        () => getUpdatingById(entity.id).state === NetworkRequestState.InProgress,
        [entity.id, getUpdatingById],
    );

    const onChange = useCallback(() => {
        update(entity.id, {
            id: entity.id,
            booking_summary_validated: !entity.booking_summary_validated,
        } as Campaign);
    }, [update, entity.id, entity.booking_summary_validated]);

    const { hasAdminPermission } = useUserPermissions();

    return (
        <>
            <CampaignBookingSummaryDialog
                open={dialogState.value}
                onClose={dialogState.setFalse}
                campaign={entity}
            />
            <AutoGrid columnGap={0} flexWrap="nowrap" alignItems="center">
                <Tooltip
                    title={useCommonTranslation(
                        'Modules.Main.Campaigns.DataStatus.table.bookingSummaryValidated',
                    )}
                >
                    <Box>
                        <Checkbox
                            disabled={loading || !hasAdminPermission}
                            checked={entity.booking_summary_validated}
                            label={null}
                            onChange={onChange}
                            isBoolean
                            checkedIcon={loading ? <CircularProgress size={15} /> : undefined}
                            icon={loading ? <CircularProgress size={15} /> : undefined}
                            formControlLabelSx={{ mr: 0 }}
                        />
                    </Box>
                </Tooltip>
                <Button
                    variant="text"
                    sx={{ whiteSpace: 'nowrap' }}
                    size="small"
                    onClick={dialogState.setTrue}
                >
                    <Description fontSize="inherit" sx={{ mr: 0.5 }} />
                    {useCommonTranslation(
                        'Modules.Main.Campaigns.DataStatus.table.bookingSummaryFileCount',
                        { count },
                    )}
                </Button>
            </AutoGrid>

            {entity?.booking_summary_validated_updated_by != null && (
                <UpdatedByUserCaption
                    userId={entity.booking_summary_validated_updated_by}
                    updatedAt={entity.booking_summary_validated_updated_at}
                />
            )}
        </>
    );
};

export default CampaignBookingSummaryTableCell;
