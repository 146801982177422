import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    NetworkRequestState,
    logout,
    timeout,
    resetEntities,
    NetworkRequest,
} from '@uniled/data-layer';
import { PasswordResetState } from './password-reset-types';

const initialState: PasswordResetState = {
    requestResetEmailState: { error: null, state: NetworkRequestState.Idle },
    requestResetPasswordState: { error: null, state: NetworkRequestState.Idle },
};

const slice = createSlice({
    initialState,
    name: 'password-reset',
    reducers: {
        resetRequestEmail: state => ({
            ...state,
            requestResetEmailState: { state: NetworkRequestState.Idle },
        }),
        startResetRequestEmail: state => ({
            ...state,
            requestResetEmailState: { state: NetworkRequestState.InProgress },
        }),
        finishedResetRequestEmail: state => ({
            ...state,
            requestResetEmailState: { state: NetworkRequestState.Success },
        }),
        failedResetRequestEmail: (state, action: PayloadAction<string>) => ({
            ...state,
            requestResetEmailState: { state: NetworkRequestState.Error, error: action.payload },
        }),

        resetResetPassword: state => ({
            ...state,
            requestResetPasswordState: { state: NetworkRequestState.Idle },
        }),
        startResetPassword: state => ({
            ...state,
            requestResetPasswordState: { state: NetworkRequestState.InProgress },
        }),
        finishedResetPassword: state => ({
            ...state,
            requestResetPasswordState: { state: NetworkRequestState.Success },
        }),
        failedResetPassword: (
            state,
            action: PayloadAction<{ error: string; validation: NetworkRequest['validation'] }>,
        ) => ({
            ...state,
            requestResetPasswordState: {
                state: NetworkRequestState.Error,
                error: action.payload.error,
                validation: action.payload.validation,
            },
        }),
    },
    extraReducers: builder => {
        builder.addCase(logout, () => initialState);
        builder.addCase(timeout, () => initialState);
        builder.addCase(resetEntities, () => initialState);
    },
});
const passwordResetSlice = {
    passwordResetActions: slice.actions,
    passwordResetReducer: slice.reducer,
};
export default passwordResetSlice;
export const { passwordResetActions, passwordResetReducer } = passwordResetSlice;
