import {
    permissionActions,
    permissionAdapter,
    permissionThunks as defaultThunks,
    permissionSelectors as defaultSelectors,
    permissionReducer,
} from './Permission-slice';
import * as thunks from './Permission-thunks';
import * as selectors from './Permission-selectors';

const permissionThunks = { ...defaultThunks, ...thunks };
const permissionSelectors = { ...defaultSelectors, ...selectors };

export { default as PermissionSlice } from './Permission-slice';
export {
    permissionSelectors,
    permissionActions,
    permissionAdapter,
    permissionThunks,
    permissionReducer,
};
