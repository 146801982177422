import React from 'react';
import { PopDisplays_VirtualCampaignSummary } from '@uniled/api-sdk';
import { Box, TableContainer } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import VirtualCampaignSummaryRow from './VirtualCampaignSummaryRow';

type Props = {
    summaries: PopDisplays_VirtualCampaignSummary[];
    onSummaryOpen: (displayId: number) => void;
};

const containerSx = { maxHeight: '70vh' };
const VirtualCampaignSummary: React.FC<Props> = ({ summaries, onSummaryOpen }) => (
    <>
        <TableContainer sx={containerSx}>
            <Table stickyHeader spacedOutRows>
                <SummaryTableHead />
                <TableBody>
                    {summaries.map(sum => (
                        <VirtualCampaignSummaryRow
                            key={sum.name}
                            summary={sum}
                            onSummaryOpen={onSummaryOpen}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
);

const SummaryTableHead: React.FC = () => (
    <TableHead>
        <TableRow isHeader>
            <TableCell isHeader>
                {useCommonTranslation(
                    'Modules.PopDisplays.VirtualCampaignBuilder.summary.table.nameCol',
                )}
            </TableCell>
            <TableCell isHeader>
                {useCommonTranslation(
                    'Modules.PopDisplays.VirtualCampaignBuilder.summary.table.channelCol',
                )}
            </TableCell>
            <TableCell isHeader>
                {useCommonTranslation(
                    'Modules.PopDisplays.VirtualCampaignBuilder.summary.table.frameCol',
                )}
            </TableCell>
            <TableCell isHeader>
                {useCommonTranslation(
                    'Modules.PopDisplays.VirtualCampaignBuilder.summary.table.frameIdCol',
                )}
            </TableCell>
            <TableCell isHeader>
                {useCommonTranslation(
                    'Modules.PopDisplays.VirtualCampaignBuilder.summary.table.slotsCol',
                )}
            </TableCell>
            <TableCell isHeader>
                {useCommonTranslation(
                    'Modules.PopDisplays.VirtualCampaignBuilder.summary.table.slotsDurationCol',
                )}
            </TableCell>
            <TableCell isHeader>
                {useCommonTranslation(
                    'Modules.PopDisplays.VirtualCampaignBuilder.summary.table.ownerCol',
                )}
            </TableCell>
            <TableCell isHeader>
                {useCommonTranslation(
                    'Modules.PopDisplays.VirtualCampaignBuilder.summary.table.sourceCol',
                )}
            </TableCell>
            <TableCell isHeader>
                {useCommonTranslation(
                    'Modules.PopDisplays.VirtualCampaignBuilder.summary.table.actualCol',
                )}
            </TableCell>
            <TableCell isHeader>
                {useCommonTranslation(
                    'Modules.PopDisplays.VirtualCampaignBuilder.summary.table.minDateCol',
                )}
            </TableCell>
            <TableCell isHeader>
                {useCommonTranslation(
                    'Modules.PopDisplays.VirtualCampaignBuilder.summary.table.maxDateCol',
                )}
            </TableCell>
            <TableCell isHeader />
        </TableRow>
    </TableHead>
);

export default React.memo(VirtualCampaignSummary);
