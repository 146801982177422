import { Box, Button } from '@mui/material';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BreadCrumbPart } from 'c-wrapper/Types';
import config from 'c-config';
import { Translate, TranslationPath, useCommonTranslation } from 'c-translation';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import LayoutSetter from '../LayoutSetter/LayoutSetter';

const useStyles = makeStyles((theme: Theme) => ({
    status: {
        color: theme.palette.text.secondary,
        fontWeight: 750,
        fontSize: 80,
        bottom: 0,
        height: 'auto',
    },
    title: {
        color: theme.palette.text.secondary,
        fontWeight: 500,
        marginTop: 18,
        marginLeft: 20,
        fontSize: 30,
    },
    message: {
        color: theme.palette.text.secondary,
        fontSize: 15,
    },
    buttonWrap: {
        width: 150,
        float: 'right',
        marginTop: 20,
        right: 0,
        position: 'absolute',
    },
}));
const BadRequest = ({ statusCode, error }: { statusCode: number; error?: string }) => {
    const { pathname } = useLocation();
    const translate = useCommonTranslation();
    const history = useHistory();
    const { status, title, message, buttonWrap } = useStyles();
    const breadCrumbs = useMemo(() => {
        const crumbs: BreadCrumbPart[] = [];
        pathname
            .slice(1)
            .split('/')
            .reduce((acc, part) => {
                const result = `${acc}/${part}`;
                const pages = config.pages.filter(x => x.systemSearch.route === result);
                if (pages.length > 0) {
                    crumbs.push({
                        label: translate(pages[0].systemSearch.title),
                        link: result,
                    });
                }
                return result;
            }, '');
        return crumbs;
    }, [pathname, translate]);

    return (
        <>
            <LayoutSetter metaTitle={pathname as TranslationPath} />
            <Box position="relative" maxWidth={600} ml={10}>
                <Box mt={4} display="flex">
                    <Box className={status}>{statusCode}</Box>
                    <Box className={title}>
                        <Translate
                            path={`BadRequestPage.status.${statusCode}` as TranslationPath}
                        />
                    </Box>
                </Box>
                {error && <Box className={message}>{error}</Box>}
                <Box className={buttonWrap}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            history.push(breadCrumbs[breadCrumbs.length - 1]?.link ?? '/')
                        }
                    >
                        <Translate path={'BadRequestPage.back' as TranslationPath} />
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default BadRequest;
