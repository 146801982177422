import { CommonThunk } from 'c-types';
import { callApi, errorMessageFromResponse, normaliseDataThunks } from '@uniled/data-layer';
import apiClient from 'c-data/apiClient';
import { commonSchemas } from 'c-data';
import { NetworkRequestState } from '@uniled/data-layer';
import { skinActions } from 'c-admin/Slices/Skin/Skin-slice';
import {
    getUploadingLargeLogoById,
    getUploadingSmallLogoById,
    getAttachingSkinById,
} from './Skin-selectors';

export default {};

export const uploadLargeLogo =
    (skinId: number, file: File): CommonThunk =>
    async (dispatch, getState) => {
        if (
            getUploadingLargeLogoById(getState())(skinId).state === NetworkRequestState.InProgress
        ) {
            return;
        }

        dispatch(skinActions.startUploadingLargeLogoById(skinId));
        const formData = new FormData();
        formData.set('file', file);

        const [error, data] = await dispatch(
            callApi(apiClient.Entities.Skin.uploadLargeLogo({ skinId, formData }).request),
        );

        if (!error) {
            dispatch(normaliseDataThunks.updateWithNormalisedData(commonSchemas.Skin, data.data));
            dispatch(skinActions.finishedUploadingLargeLogoById(skinId));
            return;
        }
        dispatch(
            skinActions.failedUploadingLargeLogoById({
                id: skinId,
                error: errorMessageFromResponse(error),
            }),
        );
    };

export const uploadSmallLogo =
    (skinId: number, file: File): CommonThunk =>
    async (dispatch, getState) => {
        if (
            getUploadingSmallLogoById(getState())(skinId).state === NetworkRequestState.InProgress
        ) {
            return;
        }
        dispatch(skinActions.startUploadingSmallLogoById(skinId));
        const formData = new FormData();
        formData.set('file', file);

        const [error, data] = await dispatch(
            callApi(apiClient.Entities.Skin.uploadSmallLogo({ skinId, formData }).request),
        );

        if (!error) {
            dispatch(normaliseDataThunks.updateWithNormalisedData(commonSchemas.Skin, data.data));
            dispatch(skinActions.finishedUploadingSmallLogoById(skinId));
            return;
        }
        dispatch(
            skinActions.failedUploadingSmallLogoById({
                id: skinId,
                error: errorMessageFromResponse(error),
            }),
        );
    };

export const attachSkin =
    (
        skinId: number,
        options: {
            users?: number[];
            agencies?: number[];
            buyers?: number[];
            clients?: number[];
            owners?: number[];
        },
    ): CommonThunk =>
    async (dispatch, getState) => {
        if (getAttachingSkinById(getState())(skinId).state === NetworkRequestState.InProgress) {
            return;
        }
        dispatch(skinActions.startAttachingSkinById(skinId));

        const [error, data] = await dispatch(
            callApi(apiClient.Entities.Skin.attach({ skinId, ...options }).request),
        );

        if (!error) {
            dispatch(normaliseDataThunks.updateWithNormalisedData(commonSchemas.Skin, data.data));
            dispatch(skinActions.finishedAttachingSkinById(skinId));
            return;
        }
        dispatch(
            skinActions.failedAttachingSkinById({
                id: skinId,
                error: errorMessageFromResponse(error),
            }),
        );
    };
