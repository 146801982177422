import React, { useEffect, useState } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.css';

type Props = {
    id: string;
    autoFocus?: boolean;
} & ReactQuillProps;

const RichTextEditor: React.FC<Props> = ({ id, autoFocus = false, ...rest }) => {
    const [quillRef, setQuillRef] = useState<ReactQuill>();
    useEffect(() => {
        const editorRoot = document.querySelector(`#${id} .ql-toolbar`);

        // prevent blur when using toolbar https://github.com/quilljs/quill/issues/1680#issuecomment-493455851
        // https://github.com/quilljs/quill/issues/1290#issuecomment-315539516
        const mousedown = e => {
            e.preventDefault();
        };
        editorRoot?.addEventListener('mousedown', mousedown);

        if (autoFocus) quillRef?.focus();

        return () => {
            editorRoot?.removeEventListener('mousedown', mousedown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quillRef, id]);

    return <ReactQuill id={id} ref={setQuillRef} theme="snow" {...rest} />;
};

export default RichTextEditor;
