import React, { useEffect, useMemo } from 'react';
import { CardContent } from '@mui/material';
import { Client, Agency, Campaign } from '@uniled/api-sdk';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { campaignIcon } from 'c-main/icons';
import { useEntityField, useEntityPage } from 'c-hooks';
import { campaignName } from 'c-main/Lib';
import { useEntityData } from 'c-data';
import { generatePath } from 'react-router-dom';
import { TransparentCard } from 'c-components';
import { NewCampaignForm } from 'c-main/Components';
import { isoDateStringNoTimeDiff } from 'c-lib';
import { NewCampaignFormSchema } from 'c-main/Types';
import ReactGA from 'react-ga';
import NewCampSetup from './NewCampaignPage';

const lineItemIds = (campaign: Campaign) => campaign?.lineItems?.map(item => String(item.id)) ?? [];

const lineItemDays = (campaign: Campaign) =>
    campaign?.budgetSummary?.lineItems?.reduce(
        (accDays, lineItem) => ({ ...accDays, [lineItem.line_item_id]: lineItem.days }),
        {},
    );

const CloneCampaignPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { id } = useEntityPage();
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });

    const { text: agencyName, entity: AgencyEntity } = useEntityField<Agency>(
        campaign?.agency_id,
        'Agency',
        'name',
    );
    const { text: clientName, entity: ClientEntity } = useEntityField<Client>(
        campaign?.client_id,
        'Client',
        'name',
    );

    const defaultFormData = useMemo(
        () =>
            ({
                name: campaign.name,
                reference: campaign.reference,
                buyer_id: campaign.buyer_id,
                client: clientName,
                agency: agencyName,

                start_datetime: isoDateStringNoTimeDiff(campaign.date.date_start),
                end_datetime: isoDateStringNoTimeDiff(campaign.date.date_end),

                line_items: lineItemIds(campaign),
                days: lineItemDays(campaign),
            } as NewCampaignFormSchema),
        [agencyName, campaign, clientName],
    );

    const showForm = useMemo(
        () => campaign != null && AgencyEntity != null && ClientEntity != null,
        [campaign, AgencyEntity, ClientEntity],
    );

    return (
        <TransparentCard>
            <CardContent>
                {showForm && <NewCampaignForm defaultValues={defaultFormData} />}
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageEntityConfig<Campaign> = {
    id: 'CloneCampaignPage',
    component: CloneCampaignPage,
    systemSearch: {
        title: 'Modules.Main.Campaigns.CloneCampaign.metaTitle',
        type: 'entity',
        entityName: 'Campaign',
        icon: campaignIcon,
        // aliases: [],
        defaultIncludes: ['buyer', 'date', 'budgetSummary', 'budgetSummary.lineItems', 'lineItems'],
        route: PostAuthRoutes.Campaigns.CloneCampaign,
        permissions: NewCampSetup.systemSearch.permissions,
        saveAsRecentPage: false,
        excludeFromSystemSearch: true,
        breadcrumbs: [
            {
                label: 'Modules.Main.Campaigns.List.metaTitle',
                link: PostAuthRoutes.Campaigns.CampaignList,
            },
        ],
        nameGen: () => null,
        generateEntityBreadcrumbs: (crumbs, t, entity, id) => [
            ...crumbs,
            {
                label: campaignName(entity),
                link: generatePath(PostAuthRoutes.Campaigns.CampaignOverview, { id }),
            },
            {
                label: 'Modules.Main.Campaigns.CloneCampaign.cloneBreadcrumb',
            },
        ],
    },
};

export default setup;
