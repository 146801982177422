import {
    campaignActions,
    campaignAdapter,
    campaignThunks as defaultThunks,
    campaignSelectors as defaultSelectors,
    campaignReducer,
} from './Campaign-slice';
import * as thunks from './Campaign-thunks';
import * as selectors from './Campaign-selectors';

const campaignThunks = { ...defaultThunks, ...thunks };
const campaignSelectors = { ...defaultSelectors, ...selectors };

export { default as CampaignSlice } from './Campaign-slice';
export { campaignSelectors, campaignActions, campaignAdapter, campaignThunks, campaignReducer };
