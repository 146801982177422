import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useEntityData, User, useUpdateEntityData } from 'c-data';
import { useCommonTranslation } from 'c-translation';
import { Box, CardContent } from '@mui/material';
import {
    AutoGrid,
    Button,
    ControlledFormInput,
    FlashMessage,
    FormWrapper,
    TextField,
    TransparentCard,
} from 'c-components';
import { useFormContext, useWatch } from 'react-hook-form';
import { passwordStrength } from 'c-auth-module/Lib';
import { PasswordStrengthErrors } from 'c-auth-module/Types';
import { NetworkRequestState } from '@uniled/data-layer';
import { useAuthenticatedUser } from 'c-hooks';
import { EditUserPage } from 'c-admin/Pages/EditUserPage';
import ValidationErrors from 'c-components/ValidationErrors';
import PasswordStrength from '../../../../Auth/Components/PasswordStrength';

type Props = {
    //
    id: number;
};

const UserEditPassword: React.FC<Props> = ({ id }) => {
    const { user: LoggedInUser } = useAuthenticatedUser();
    const { getById } = useEntityData<User>('User');
    const user = getById({ id });
    const { getUpdatingById, resetUpdatingById } = useUpdateEntityData('User');
    const loadingState = getUpdatingById(user.id);

    useEffect(() => {
        // reset the update state on mount and unmount
        resetUpdatingById(id);
        return () => {
            resetUpdatingById(id);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const t = useCommonTranslation();
    const [passwordError, setPasswordError] = useState('');

    const { control, formState } = useFormContext();
    const [newPassword, passwordConfirmation] = useWatch({
        control,
        name: ['new_password', 'password_confirmation'],
    });

    useEffect(() => {
        // https://github.com/react-hook-form/react-hook-form/issues/1211
        // have to do it like this instead of normal YUP validation
        // because field errors are cleared one by one. See github issue above.
        // I have commented on it.
        const passwordStr = passwordStrength(newPassword ?? '', passwordConfirmation ?? '').join(
            ',',
        );

        if (passwordStr !== passwordError) {
            setPasswordError(passwordStr);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPassword, passwordConfirmation]);

    const editingSelf = useMemo(
        () => LoggedInUser?.id != null && user?.id != null && LoggedInUser?.id === user?.id,
        [LoggedInUser?.id, user?.id],
    );

    return (
        <Box mb={4}>
            <AutoGrid spacing={0} xs={12} md={6} lg={4}>
                <TransparentCard
                    title={t('Modules.Admin.UserEdit.passwordPageTitle', { name: user.name })}
                >
                    <CardContent>
                        <AutoGrid spacing={2} xs={12}>
                            {editingSelf && (
                                <ControlledFormInput
                                    defaultValue=""
                                    name="old_password"
                                    as={
                                        <TextField
                                            label={t('Modules.Admin.UserEdit.oldPassword')}
                                            placeholder={t('Modules.Admin.UserEdit.oldPassword')}
                                            required
                                            type="password"
                                        />
                                    }
                                />
                            )}
                            <ControlledFormInput
                                defaultValue=""
                                name="new_password"
                                as={
                                    <TextField
                                        label={useCommonTranslation(
                                            'Modules.Admin.UserEdit.newPassword',
                                        )}
                                        placeholder={useCommonTranslation(
                                            'Modules.Admin.UserEdit.newPassword',
                                        )}
                                        required
                                        type="password"
                                    />
                                }
                            />
                            <ControlledFormInput
                                defaultValue=""
                                name="password_confirmation"
                                as={
                                    <TextField
                                        label={useCommonTranslation(
                                            'Modules.Admin.UserEdit.confirmNewPassword',
                                        )}
                                        placeholder={useCommonTranslation(
                                            'Modules.Admin.UserEdit.confirmNewPassword',
                                        )}
                                        required
                                        type="password"
                                    />
                                }
                            />
                            <PasswordStrength
                                errors={(passwordError.split(',') as PasswordStrengthErrors) ?? []}
                            />

                            {loadingState.state === NetworkRequestState.Error && (
                                <Box>
                                    <FlashMessage status="error">
                                        {loadingState.error ??
                                            t('en.Modules.Admin.UserEdit.passwordSaveFail')}
                                        <ValidationErrors
                                            validationErrors={loadingState.validation}
                                        />
                                    </FlashMessage>
                                </Box>
                            )}
                            {loadingState.state === NetworkRequestState.Success && (
                                <Box>
                                    <FlashMessage status="success">
                                        {t('Modules.Admin.UserEdit.passwordSaveSuccess')}
                                    </FlashMessage>
                                </Box>
                            )}

                            <Button
                                id="password-reset-submit"
                                type="submit"
                                disabled={
                                    loadingState.state === NetworkRequestState.InProgress ||
                                    !formState.isValid ||
                                    passwordError.length > 0
                                }
                            >
                                {useCommonTranslation('Modules.Admin.UserEdit.savePassword')}
                            </Button>
                        </AutoGrid>
                    </CardContent>
                </TransparentCard>
            </AutoGrid>
        </Box>
    );
};

const UserEditPasswordWrapper: React.FC<Props> = props => {
    const { update } = useUpdateEntityData('User');

    const onSubmit = useCallback(
        (data: User) => {
            update(props.id, data, EditUserPage.systemSearch.defaultIncludes);
        },
        [props.id, update],
    );
    return (
        <FormWrapper<User> onSubmit={onSubmit}>
            <UserEditPassword {...props} />
        </FormWrapper>
    );
};

export default UserEditPasswordWrapper;
