import {
    logFileImportActions,
    logFileImportAdapter,
    logFileImportThunks as defaultThunks,
    logFileImportSelectors as defaultSelectors,
    logFileImportReducer,
} from './LogFileImport-slice';
import * as thunks from './LogFileImport-thunks';
import * as selectors from './LogFileImport-selectors';

const logFileImportThunks = { ...defaultThunks, ...thunks };
const logFileImportSelectors = { ...defaultSelectors, ...selectors };

export { default as LogFileImportSlice } from './LogFileImport-slice';
export {
    logFileImportSelectors,
    logFileImportActions,
    logFileImportAdapter,
    logFileImportThunks,
    logFileImportReducer,
};
