import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Tab, Typography } from '@mui/material';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useEntityPage } from 'c-hooks';
import { Displays_Owner, PermissionName } from '@uniled/api-sdk';
import * as yup from 'yup';
import { useUpdateEntityData } from 'c-data';
import { useForm } from 'react-hook-form';
import { DisplayOwnerForm } from 'c-displays/Pages/DisplaysOwnerEdit/OwnerEdtiForm';
import { FilterableEntityTable, FilterableEntityTableRendererType } from 'c-pagination';
import {
    displayEditPacksColumns,
    displayScreenColumnsOtherTables,
} from 'c-displays/Components/DisplaysEntityColumns';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import ReactGA from 'react-ga';
import { MassUpdatingDisplays } from 'c-displays/Pages/utils';
import { generatePath } from 'react-router-dom';

const textSearchCols = ['id'];
const DisplaysOwnerEditPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { entity, id } = useEntityPage<Displays_Owner>();
    const t = useCommonTranslation();
    const [value, setValue] = React.useState('1');
    const validationSchema = yup.object().shape({});
    const { update, getUpdatingById } = useUpdateEntityData('Displays_Owner');
    const { state, error } = useMemo(() => getUpdatingById(id), [getUpdatingById, id]);
    const defaultValues = useMemo(
        () => ({
            ...entity,
            markets: entity?.market?.map(market => market.id) || [],
            partner_id: entity?.partner_id ?? '',
            countries: entity?.countries?.map(country => country.id) ?? [],
        }),
        [entity],
    );
    const handleFormSubmit = useCallback(
        data => {
            update(id, data);
        },
        [id, update],
    );
    const { control, handleSubmit } = useForm({
        defaultValues,
    });

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const screensCount = useMemo(
        () => entity.activeScreensCount + entity.inactiveScreensCount,
        [entity],
    );
    const paginationTagForScreens = `displays-displayOwner-admin-Screens-list-${id}`;
    const paginationTagForPacks = `displays-displayOwner-admin-Packs-list-${id}`;

    return (
        <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange}>
                        <Tab
                            label={t('Modules.Displays.DisplaysOwnerEdit.tabs.editOwnerDetails')}
                            value="1"
                        />
                        <Tab label={t('Modules.Displays.DisplaysOwnerEdit.tabs.packs')} value="2" />
                        <Tab
                            label={t('Modules.Displays.DisplaysOwnerEdit.tabs.displays')}
                            value="3"
                        />
                        <Tab
                            label={t('Modules.Displays.DisplaysOwnerEdit.tabs.massUpdating')}
                            value="4"
                        />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Box pt={2}>
                        <Typography variant="h1" pb={2}>
                            {t('Modules.Displays.DisplaysOwnerEdit.description')}
                        </Typography>
                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                            <DisplayOwnerForm
                                control={control}
                                state={state}
                                error={error}
                                id={id}
                            />
                        </form>
                    </Box>
                </TabPanel>
                <TabPanel value="2">
                    <>
                        {entity.packs.length === 0 && (
                            <Typography>
                                {t('Modules.Displays.DisplaysOwnerEdit.noPacks')}
                            </Typography>
                        )}
                        {entity.packs.length > 0 && (
                            <FullPageEntityTableWrapper title="Modules.Displays.DisplaysDisplayEdit.tabs.packsTab">
                                <FilterableEntityTable
                                    key={paginationTagForPacks}
                                    tag={paginationTagForPacks}
                                    columns={displayEditPacksColumns}
                                    baseEntityName="Displays_Pack"
                                    textSearchColumns={textSearchCols}
                                    onRowClick={id => {
                                        const rout = generatePath(
                                            PostAuthRoutes.Displays.DisplaysPackEdit,
                                            { id },
                                        );
                                        window.open(rout, '_blank');
                                    }}
                                    rendererType={FilterableEntityTableRendererType.Contained}
                                    direction="desc"
                                    showFilters
                                    filters={{
                                        'filter.id': entity.packs.map(pack => pack.id),
                                    }}
                                    includes={[
                                        'country',
                                        'activeDisplaysCount',
                                        'inactiveDisplaysCount',
                                        'resolutions',
                                        'market',
                                    ]}
                                />
                            </FullPageEntityTableWrapper>
                        )}
                    </>
                </TabPanel>
                <TabPanel value="3">
                    <>
                        {screensCount === 0 && (
                            <Typography>
                                {t('Modules.Displays.DisplaysOwnerEdit.noDisplays')}
                            </Typography>
                        )}
                        {screensCount > 0 && (
                            <FullPageEntityTableWrapper title="Modules.Displays.DisplaysOwnerEdit.tabs.displays">
                                <FilterableEntityTable
                                    tag={paginationTagForScreens}
                                    columns={displayScreenColumnsOtherTables}
                                    baseEntityName="Displays_Screen"
                                    textSearchColumns={textSearchCols}
                                    rendererType={FilterableEntityTableRendererType.Contained}
                                    revertToIdSearchOnNumberOnlyInput={false}
                                    showFilters
                                    displayDownloadButton
                                    onRowClick={id => {
                                        const rout = generatePath(
                                            PostAuthRoutes.Displays.DisplaysDisplayEdit,
                                            { id },
                                        );
                                        window.open(rout, '_blank');
                                    }}
                                    filters={{ 'filter.owners.id': [entity.id] }}
                                    onlyIncludeFilterKeys={[
                                        'filter.packs.id',
                                        'filter.tags.id',
                                        'filter.size',
                                        'filter.surface_area',
                                        'filter.conurbation',
                                        'filter.tv_region',
                                        'filter.env',
                                        'filter.resolutions.id',
                                        'filter.fileTypes.id',
                                        'filter.status',
                                    ]}
                                    orderBy="id"
                                    direction="desc"
                                    includes={[
                                        'mediaOwners',
                                        'resolutions',
                                        'packs',
                                        'status',
                                        'mediaOwners',
                                    ]}
                                />
                            </FullPageEntityTableWrapper>
                        )}
                    </>
                </TabPanel>
                <TabPanel value="4">
                    <>
                        <MassUpdatingDisplays id={id} location="owners" />
                    </>
                </TabPanel>
            </TabContext>
        </Box>
    );
};

const setup: PageEntityConfig<Displays_Owner> = {
    id: 'DisplaysOwnerEditPage',
    component: DisplaysOwnerEditPage,
    systemSearch: {
        title: undefined,
        description: undefined,
        icon: undefined,
        type: 'entity',
        aliases: undefined,
        route: PostAuthRoutes.Displays.DisplaysOwnerEdit,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.Displays.AdminHome.metaTitle',
                link: PostAuthRoutes.Displays.DisplaysAdminHome,
            },
            {
                label: 'Modules.Displays.OwnerList.metaTitle',
                link: PostAuthRoutes.Displays.OwnerList,
            },
        ],
        entityName: 'Displays_Owner',
        nameGen: (entity, id) => entity?.name,
        permissions: [PermissionName.Admin],
        defaultIncludes: ['market', 'packs', 'countries'],
    },
};

export default setup;
