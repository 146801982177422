import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, InputAdornment, Typography } from '@mui/material';
import EntityAutocomplete from 'c-components/EntityAutocomplete';
import { Translate, useCommonTranslation } from 'c-translation';
import DateRangeField from 'c-components/Forms/DateRangeField';
import { useBoolean } from 'react-hanger';

import { AutoGrid, Button, DialogV2, SelectField, TextField } from 'c-components';

import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { PopDisplays_Media } from '@uniled/api-sdk';
import { uniqueCreativeValues } from 'c-main/Components/Schedule/Components/PopSettings/utils';
import { NetworkRequestState } from '@uniled/data-layer';
import { generateSearchColumnsData } from 'c-pagination';
import { Search } from '@mui/icons-material';
import to from 'await-to-js';

type Props = {
    onSelected: (names: string[]) => void;
    initialSelectedNamesString?: string;
};

const PopSettingsCreativesSearch: React.FC<Props> = ({
    onSelected,
    initialSelectedNamesString = '',
}) => {
    const t = useCommonTranslation();
    const [selectedNames, setSelectedNames] = useState(
        new Set<string>(uniqueCreativeValues(initialSelectedNamesString)),
    );
    const [[start, end], setDateRange] = useState<string[]>([]);
    const [source, setSource] = useState([]);
    const [selectionModel, setSelectionModel] = useState<number[]>();
    const [rows, setRows] = useState<PopDisplays_Media[]>();
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
    const [theSearchTerm, setTheSearchTerm] = useState('');
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [selectedSearchField, setSelectedSearchField] = useState('name');

    const searchFilters = useMemo(
        () => ({
            'filter.first_seen': end ? [`<=${end}`] : undefined,
            'filter.last_seen': start ? [`>=${start}`] : undefined,
            'filter.logImportSource.id': source,
        }),
        [start, end, source],
    );

    const creativesDialogState = useBoolean(false);
    const {
        start: tableStart,
        data,
        requestState,
    } = useAPIClientRequest(ApiClient.Entities.PopDisplays_Media.list);
    useEffect(() => {
        const { page, pageSize } = paginationModel;
        const searchPayload = {
            filters: searchFilters,
            page: page + 1,
            perPage: pageSize,
            order: 'id',
            includes: ['logImportSource'],
            searchables: generateSearchColumnsData([selectedSearchField], theSearchTerm, false),
        };
        if (start && end && source.length > 0) {
            to(tableStart(searchPayload));
        }
    }, [
        end,
        paginationModel,
        searchFilters,
        selectedSearchField,
        source,
        start,
        tableStart,
        theSearchTerm,
    ]);

    const buttonDisabler = useMemo(() => {
        if (requestState === NetworkRequestState.InProgress) {
            return true;
        }
        if (requestState === NetworkRequestState.Error) {
            return true;
        }
        if (!start || !end) {
            return true;
        }
        return source.length === 0;
    }, [requestState, start, end, source]);
    useEffect(() => {
        if (requestState === NetworkRequestState.Success) {
            setRows(data?.data?.data ?? []);
            setTotalRowCount(data?.data?.meta?.pagination.total ?? 0);
            const initialSelectedNames = uniqueCreativeValues(initialSelectedNamesString || '');
            const initialSelectionModel = rows
                ?.filter(row => initialSelectedNames.includes(row?.name))
                ?.map(row => row?.id);
            setSelectionModel(initialSelectionModel);
        }
    }, [
        data?.data?.data,
        initialSelectedNamesString,
        rows,
        totalRowCount,
        requestState,
        data?.data?.meta?.pagination.total,
    ]);

    const handleSelectionModelChange = useCallback(
        newSelectionModel => {
            setSelectionModel(newSelectionModel);
            const newSelectedNames = new Set(
                newSelectionModel
                    ?.map(id => rows.find(row => row?.id === id)?.name) // Find the name by ID.
                    ?.filter(name => name), // Filter out undefined values.
            );
            const updatedSelectedNames = new Set([...selectedNames]);
            newSelectedNames?.forEach(name => updatedSelectedNames?.add(name as string));
            rows?.forEach(row => {
                if (!newSelectionModel.includes(row?.id) && updatedSelectedNames.has(row?.name)) {
                    updatedSelectedNames.delete(row?.name);
                }
            });
            setSelectedNames(updatedSelectedNames);
        },
        [rows, selectedNames],
    );

    const handleClose = useCallback(() => {
        const initialNamesSet = new Set(initialSelectedNamesString.split('\n'));
        const appliedSelectionModel = rows
            .filter(row => initialNamesSet.has(row.name))
            .map(row => row.id);
        setSelectionModel(appliedSelectionModel);
        creativesDialogState.setFalse();
    }, [rows, initialSelectedNamesString, creativesDialogState]);

    const handleApplyButton = useCallback(() => {
        onSelected(Array.from(selectedNames));
        creativesDialogState.setFalse();
    }, [creativesDialogState, onSelected, selectedNames]);
    const onClickReset = useCallback(() => {
        setTheSearchTerm('');
    }, []);
    const resetButton = useMemo(
        () => (
            <Button size="small" onClick={onClickReset}>
                <Typography variant="caption">
                    <Translate path="Pagination.resetFilters" />
                </Typography>
            </Button>
        ),
        [onClickReset],
    );
    const creativesDialog = useMemo(() => {
        const options = [
            { label: 'name', value: 'name' },
            { label: 'Creative Reference', value: 'creative_ref' },
            { label: 'Media External Id', value: 'media_extid' },
            { label: 'Campaign Name', value: 'campaign_name' },
            { label: 'Campaign External Id', value: 'campaign_extid' },
            { label: 'Campaign Reference', value: 'campaign_ref' },
            { label: 'External Source', value: 'ext_source' },
            { label: 'Any', value: 'any' },
        ];
        const cols: GridColDef[] = [
            {
                field: 'id',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.id'),
                width: 70,
            },
            {
                field: 'name',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.name'),
                flex: 2,
            },
            {
                field: 'logImportSource.name',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.source'),
                flex: 1,
                renderCell: ({ row }) => row?.logImportSource?.name,
            },
            {
                field: 'creative_ref',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.creativeRef'),
                flex: 1,
            },
            {
                field: 'media_extid',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.mediaExtId'),
                flex: 1,
            },
            {
                field: 'campaign_name',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.campaignName'),
                flex: 1,
            },
            {
                field: 'campaign_extid',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.campaignExtId'),
                flex: 1,
            },
            {
                field: 'campaign_ref',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.campaignRef'),
                flex: 1,
            },
            {
                field: 'first_seen',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.firstSeen'),
                flex: 1,
            },
            {
                field: 'last_seen',
                headerName: t('Modules.Main.Campaigns.CampaignSchedule.popSettings.lastSeen'),
                flex: 1,
            },
        ];
        return (
            <>
                <DialogV2
                    onClose={handleClose}
                    open={creativesDialogState.value}
                    title="Modules.Main.Campaigns.CampaignSchedule.popSettings.mediaSelection"
                    maxWidth="xl"
                >
                    <AutoGrid xs={3} pt={2} gap={2}>
                        <SelectField
                            options={options}
                            value={selectedSearchField}
                            size="small"
                            onChange={e => setSelectedSearchField(e.target.value as string)}
                        />
                        <TextField
                            onChange={e => setTheSearchTerm(e.target.value)}
                            value={theSearchTerm}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">{resetButton}</InputAdornment>
                                ),
                            }}
                        />
                        <DateRangeField
                            onChange={setDateRange}
                            value={[start, end]}
                            label={t(
                                'Modules.Main.Campaigns.CampaignSchedule.popSettings.creativesSearchDatesLabel',
                            )}
                            inputProps={{ size: 'small' }}
                        />
                    </AutoGrid>
                    <DataGridPro
                        columns={cols}
                        rows={rows}
                        pageSizeOptions={[25, 50]}
                        rowCount={totalRowCount}
                        pagination
                        paginationMode="server"
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        loading={requestState === NetworkRequestState.InProgress}
                        checkboxSelection
                        rowSelectionModel={selectionModel}
                        onRowSelectionModelChange={handleSelectionModelChange}
                    />
                    <AutoGrid justifyContent="end" p={2}>
                        <Button onClick={handleApplyButton}>apply</Button>
                    </AutoGrid>
                </DialogV2>
                <Button disabled={buttonDisabler} onClick={creativesDialogState.setTrue}>
                    {t('Modules.Main.Campaigns.CampaignSchedule.popSettings.search')}
                </Button>
            </>
        );
    }, [
        t,
        handleClose,
        creativesDialogState.value,
        creativesDialogState.setTrue,
        selectedSearchField,
        theSearchTerm,
        resetButton,
        start,
        end,
        rows,
        totalRowCount,
        paginationModel,
        requestState,
        selectionModel,
        handleSelectionModelChange,
        handleApplyButton,
        buttonDisabler,
    ]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={8} md={4} pl={2} pt={2}>
                <EntityAutocomplete
                    entityName="PopDisplays_LogImportSource"
                    value={source}
                    onChange={setSource}
                    searchColumns="name"
                    labelColumn="name"
                    multiple
                    size="small"
                    textFieldProps={{
                        label: t(
                            'Modules.Main.Campaigns.CampaignSchedule.popSettings.logImportSource',
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={8} md={4}>
                <DateRangeField
                    onChange={setDateRange}
                    value={useMemo(() => [start, end], [start, end])}
                    label={t(
                        'Modules.Main.Campaigns.CampaignSchedule.popSettings.creativesSearchDatesLabel',
                    )}
                    inputProps={{ size: 'small' }}
                />
            </Grid>
            <Grid item xs={8} md={4}>
                {creativesDialog}
            </Grid>
        </Grid>
    );
};

export default PopSettingsCreativesSearch;
