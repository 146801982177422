import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    CircularProgress,
    Pagination,
    PaginationItem,
    PaginationProps,
    Typography,
} from '@mui/material';
import { CreativeType, ListSearchOptions } from '@uniled/api-sdk';
import { CreativeSearchFilters, CreativesFileList } from 'c-main/Components';
import { CreativeSearchFilterPayload } from 'c-main/Types';
import { usePaginatedEntityData } from 'c-data';
import { AutoGrid, OptionSchema, SelectField } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { formatNumber } from 'c-lib';
import { NetworkRequestState } from '@uniled/data-layer';
import { useNumber } from 'react-hanger';
import merge from 'deepmerge';

type Props = {
    paginationTag: string;
    showCampaignFilter?: boolean;
    showAgencyFilter?: boolean;
    defaultFilters?: Partial<CreativeSearchFilterPayload>;
};

const emptyFilters: CreativeSearchFilterPayload = {
    name: '',
    campaign: [],
    agencies: [],
    resolutions: [],
    creativeTypes: [CreativeType.File, CreativeType.Image, CreativeType.Video],
    // clients: [],
    // buyers: [],
};

const NameSearchKey = 'search.name';
const AgencyFilterKey = 'filter.agency.id';
const CampaignFilterKey = 'filter.campaign.id';
const CreativeTypesFilterKey = 'filter.type';
const ResolutionTypesFilterKey = 'filter.resolution.id';
const FileIdFilterKey = 'filter.file_id';

const perPageOptions: OptionSchema[] = [
    {
        label: '10',
        value: 10,
    },
    {
        label: '25',
        value: 25,
    },
    {
        label: '50',
        value: 50,
    },
    {
        label: '100',
        value: 100,
    },
];

const CreativeSearchWrapper: React.FC<Props> = ({
    paginationTag,
    showAgencyFilter = true,
    showCampaignFilter = true,
    defaultFilters = {},
}) => {
    const { paginatedData, getPaginationData } = usePaginatedEntityData(paginationTag, 'Creative');
    const actualDefaultFilters = useMemo(
        () => merge(emptyFilters, defaultFilters),
        [defaultFilters],
    );
    const [perPage, setPerPage] = useState(100);

    const [filters, setFilters] = useState<CreativeSearchFilterPayload>(() => {
        return {
            ...actualDefaultFilters,
            name: paginatedData?.searchables?.[NameSearchKey] ?? actualDefaultFilters.name,
            agencies: paginatedData?.filters?.[AgencyFilterKey] ?? actualDefaultFilters.agencies,
            campaign: paginatedData?.filters?.[CampaignFilterKey] ?? actualDefaultFilters.campaign,
            creativeTypes:
                paginatedData?.filters?.[CreativeTypesFilterKey] ??
                actualDefaultFilters.creativeTypes,
            resolutions:
                paginatedData?.filters?.[ResolutionTypesFilterKey] ??
                actualDefaultFilters.resolutions,
        } as CreativeSearchFilterPayload;
    });
    const [page, setPage] = useState(1);
    const resetCount = useNumber(0); // cheap hassle free way to reset filters

    const memoFilters = useMemo<ListSearchOptions>(() => {
        const searchFilters: ListSearchOptions['filters'] = {};

        if (filters.campaign?.length > 0) {
            searchFilters[CampaignFilterKey] = filters.campaign;
        }
        if (filters.agencies?.length > 0) {
            searchFilters[AgencyFilterKey] = filters.agencies;
        }
        if (filters.resolutions?.length > 0) {
            searchFilters[ResolutionTypesFilterKey] = filters.resolutions;
        }
        if (filters.creativeTypes?.length > 0) {
            searchFilters[CreativeTypesFilterKey] = filters.creativeTypes;
        }

        const searchables: ListSearchOptions['searchables'] = {};
        if (filters?.name != null && filters?.name?.trim()?.length > 0) {
            searchables[NameSearchKey] = filters.name;
        }
        return {
            searchables,
            filters: { ...searchFilters, [FileIdFilterKey]: ['!null'] },
            includes: ['file'],
            orderBy: 'id',
            direction: 'desc',
            perPage,
            page,
        };
    }, [
        filters.agencies,
        filters.campaign,
        filters.creativeTypes,
        filters.resolutions,
        filters.name,
        page,
        perPage,
    ]);

    useEffect(() => {
        getPaginationData(memoFilters, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoFilters]);

    const loadingIconWhite = useMemo(
        () => <CircularProgress size={15} sx={{ color: 'white' }} />,
        [],
    );
    const loadingIcon = useMemo(() => <CircularProgress size={15} color="primary" />, []);
    const t = useCommonTranslation();
    const renderPaginationItem = useCallback<PaginationProps['renderItem']>(
        props => {
            if (props.type === 'page') {
                const showLoading =
                    paginatedData?.loadingState?.state === NetworkRequestState.InProgress &&
                    props.page === paginatedData?.page;

                const theLoadingIcon = props.selected ? loadingIconWhite : loadingIcon;

                return (
                    <PaginationItem
                        {...props}
                        selected={props.page === paginatedData?.meta?.pagination.current_page}
                        page={showLoading ? theLoadingIcon : props.page}
                    />
                );
            }
            return <PaginationItem {...props} />;
        },
        [
            loadingIcon,
            loadingIconWhite,
            paginatedData?.loadingState?.state,
            paginatedData?.meta?.pagination.current_page,
            paginatedData?.page,
        ],
    );
    const pagination = useMemo(
        () => (
            <AutoGrid spacing={2} sm={12} md alignItems="center">
                <Box>
                    <Pagination
                        css={{ '.MuiPagination-ul': { flexWrap: 'nowrap' } }}
                        color="primary"
                        size="small"
                        count={paginatedData?.meta?.pagination.total_pages}
                        page={paginatedData?.meta?.pagination.current_page ?? 1}
                        onChange={(e, page) => setPage(page)}
                        renderItem={renderPaginationItem}
                    />
                </Box>
                <Box>
                    <Typography variant="body2" color="textSecondary" whiteSpace="nowrap">
                        {t('Modules.Main.Creative.SearchPage.creativeCount', {
                            count: formatNumber(paginatedData?.meta?.pagination?.count ?? 0),
                            total: formatNumber(paginatedData?.meta?.pagination?.total ?? 0),
                        })}
                    </Typography>
                </Box>
                <Box>
                    <SelectField
                        variant="standard"
                        size="small"
                        value={perPage}
                        options={perPageOptions}
                        whiteBackdrop={false}
                        greyBackdrop={false}
                        onChange={e => setPerPage(+e.target.value)}
                    />
                </Box>
            </AutoGrid>
        ),
        [
            paginatedData?.meta?.pagination.total_pages,
            paginatedData?.meta?.pagination.current_page,
            paginatedData?.meta?.pagination?.count,
            paginatedData?.meta?.pagination?.total,
            renderPaginationItem,
            t,
            perPage,
        ],
    );

    const reset = useCallback(() => {
        setFilters(actualDefaultFilters);
        resetCount.increase();
    }, [resetCount, actualDefaultFilters]);

    return (
        <Box display="flex" height="78vh" flexDirection="column" overflow="hidden">
            <Box>
                <CreativeSearchFilters
                    showCampaignFilter={showCampaignFilter}
                    showAgencyFilter={showAgencyFilter}
                    key={resetCount.value}
                    filters={filters}
                    onFilterChange={setFilters}
                    reset={reset}
                />
            </Box>
            <Box flex={1} display="flex" overflow="hidden" mt={1}>
                <CreativesFileList
                    creativeIds={paginatedData?.data ?? []}
                    pagination={pagination}
                />
            </Box>
        </Box>
    );
};

export default CreativeSearchWrapper;
