import React from 'react';
import HCFLayout, { HCFLayoutProps } from '../HCFLayout';
import ContentLayout from '../ContentLayout';

export type PageLayoutProps = {
    headerComponent?: React.ComponentType;
    contentComponent?: React.ComponentType;
    footerComponent?: React.ComponentType;
} & HCFLayoutProps;

const PageLayout = ({
    title,
    headerComponent: Header = null,
    contentComponent: Content = ContentLayout,
    footerComponent: Footer = null,
    children,
    ...layoutProps
}: React.PropsWithChildren<PageLayoutProps>) => (
    <HCFLayout {...layoutProps}>
        {Header && <HCFLayout.Header component={Header} />}
        <HCFLayout.Content component={Content}>{children}</HCFLayout.Content>
        {Footer && <HCFLayout.Footer component={Footer} />}
    </HCFLayout>
);

export default PageLayout;
