import React, { useMemo } from 'react';
import { Box, Dialog, DialogContent, IconButton } from '@mui/material';
import { Close, Download } from '@mui/icons-material';
import { useBoolean } from 'react-hanger';
import { format } from 'date-fns';
import { ChartExportProps } from './types';
import ChartExport from './ChartExport';

const ChartWrapperControls: React.FC<
    Omit<ChartExportProps & { startDate: Date; endDate: Date }, 'closeBtn'>
> = ({ type, chartProps, tableProps, namePrefix, startDate, endDate }) => {
    const open = useBoolean(false);
    const closeButton = useMemo(
        () => (
            <IconButton onClick={open.setFalse}>
                <Close />
            </IconButton>
        ),
        [open.setFalse],
    );
    const reportName = useMemo(
        () => `${namePrefix}_${format(startDate, 'yyyy-MM-dd')}___${format(endDate, 'yyyy-MM-dd')}`,
        [namePrefix, startDate, endDate],
    );
    const chartDialogComponent = useMemo(
        () => (
            <ChartExport
                type={type}
                chartProps={chartProps}
                tableProps={tableProps}
                closeBtn={closeButton}
                namePrefix={reportName}
            />
        ),
        [chartProps, type, tableProps, closeButton, reportName],
    );

    return (
        <Box>
            <IconButton onClick={open.setTrue}>
                <Download fontSize="inherit" />
            </IconButton>
            {open.value && (
                <Dialog
                    open={open.value}
                    onClose={open.setFalse}
                    fullWidth
                    // even though 100% isn't a document legal value it does work 🙈
                    maxWidth={'100%' as any}
                    scroll="paper"
                    sx={{ height: '100vh' }}
                >
                    <DialogContent>{chartDialogComponent}</DialogContent>
                </Dialog>
            )}
        </Box>
    );
};

export default ChartWrapperControls;
