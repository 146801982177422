import React, { useCallback } from 'react';
import { useLanguageSwitcher } from 'c-hooks';
import { Box, CircularProgress, Grid, IconButton } from '@mui/material';
import { LANG_en_gb, LANG_en_us } from 'c-lib';
import { FlashMessage } from 'c-components';

type Props = {
    //
};

const languages = [
    {
        icon: <>🇬🇧</>,
        language: LANG_en_gb,
    },
    {
        icon: <>🇺🇸</>,
        language: LANG_en_us,
    },
];

const LanguageSwitcher: React.FC<Props> = () => {
    const { switchLanguage, lang, isSwitching, hasFailedSwitch, failedSwitchError } =
        useLanguageSwitcher();
    const onClickLang = useCallback(
        (lang: string) => {
            switchLanguage(lang);
        },
        [switchLanguage],
    );

    return (
        <>
            {hasFailedSwitch && <FlashMessage status="error">{failedSwitchError}</FlashMessage>}
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                {languages.map(l => (
                    <Grid item key={l.language}>
                        <IconButton
                            color="primary"
                            onClick={() => onClickLang(l.language)}
                            disabled={isSwitching}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: 25,
                                    alignItems: 'center',
                                    borderBottom:
                                        l.language === lang ? '1px solid black' : undefined,
                                }}
                            >
                                {!isSwitching ? l.icon : <CircularProgress size={15} />}
                            </Box>
                        </IconButton>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default LanguageSwitcher;
