import React, { Ref, useCallback, useMemo } from 'react';
import { Box, FormControl, FormGroup, FormHelperText, FormLabel } from '@mui/material';
import { OptionSchema } from '../formTypes';
import Checkbox from '../Checkbox';
import { CheckboxProps } from '../Checkbox/Checkbox';

type Props = {
    options: OptionSchema[];
    value?: any;
    label?: string;
    onChange?: (value: any[] | any) => void;
    onToggleValue?: (value: any) => void;
    multiple?: boolean;
    error?: boolean;
    helperText?: string;
    name?: string;
    id?: string;

    /**
     * if its not part of a `real` form you don't necessarily need to wrap it in a
     * MUI <FormControl/> component.
     *
     * Long form control lists also perform badly because of some stupid internal hooks
     */
    asFormControl?: boolean;
};

/**
 * To avoid re-rendering every checkbox child when the checkbox group re-renders.
 */
const CheckboxChild = React.memo<CheckboxProps>(props => <Checkbox {...props} />);

function getCheckboxId(
    defaultId: string,
    value: string | number,
    id?: string,
    name?: string,
): string {
    if (id) return `${id}-${value}`;

    if (name) return `${name}-${value}`;

    return defaultId;
}

const CheckboxGroup = (
    {
        options,
        value,
        onChange,
        onToggleValue,
        label,
        multiple = false,
        error,
        helperText,
        name,
        id,
        asFormControl = true,
    }: Props,
    ref: Ref<any>,
) => {
    const values = useMemo(() => {
        if (multiple) {
            return Array.isArray(value) ? value : [];
        }

        return value;
    }, [value, multiple]);

    const handleChange = useCallback(
        toggledValue => {
            let newValues = multiple !== true ? [toggledValue] : [...values];

            if (multiple === true) {
                const valueIndex = values.indexOf(toggledValue);
                if (valueIndex === -1) {
                    newValues = [...values, toggledValue];
                } else {
                    newValues.splice(valueIndex, 1);
                }
            } else if (values === toggledValue) {
                newValues = null;
            }

            if (multiple !== true) {
                onChange(newValues?.[0] ?? null);
            } else {
                onChange(newValues);
            }
        },
        [values, onChange, multiple],
    );

    const content = useMemo(
        () => (
            <>
                <FormLabel component="legend">{label}</FormLabel>
                <FormGroup>
                    {options.map(option => {
                        const key = `${option.label}-${option.value}`;
                        const checkboxId = getCheckboxId(key, option.value, id, name);
                        return (
                            <CheckboxChild
                                id={checkboxId}
                                key={key}
                                onChange={onToggleValue ?? handleChange}
                                label={option.label}
                                checked={
                                    multiple
                                        ? values.indexOf(option.value) !== -1
                                        : option.value === values
                                }
                                option={option}
                            />
                        );
                    })}
                </FormGroup>
                {error && <FormHelperText error>{helperText}</FormHelperText>}
            </>
        ),
        [
            error,
            handleChange,
            helperText,
            id,
            label,
            multiple,
            name,
            onToggleValue,
            options,
            values,
        ],
    );

    return asFormControl ? (
        <FormControl error={error != null} component="fieldset" ref={ref}>
            {content}
        </FormControl>
    ) : (
        <Box ref={ref}>{content}</Box>
    );
};

export default React.forwardRef(CheckboxGroup);
