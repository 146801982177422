import React, { useEffect, useMemo } from 'react';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { Role } from 'c-entity-types';
import { roleIcon } from 'c-main/icons';
import { useEntityData } from 'c-data';
import { TabsWrapper } from 'c-components';
import { Box } from '@mui/material';
import { FilterableEntityTable } from 'c-pagination';
import { userEntityTableProps } from 'c-main/entityColumns';
import { TabWrapperSettings } from 'c-types';
import { useEntityPage } from 'c-hooks';
import { PermissionName } from '@uniled/api-sdk';
import ReactGA from 'react-ga';
import RoleEditWrapper from './Components/RoleEditWrapper';

const EditRolePage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { id } = useEntityPage();
    const { getById } = useEntityData<Role>('Role');
    const role = getById({ id });
    const usersPaginationTag = useMemo(() => `role-users-${role?.id}`, [role?.id]);
    const usersDefaultFilters = useMemo(() => ({ 'filter.roles.id': [role?.id] }), [role?.id]);

    const tabs = useMemo<TabWrapperSettings['tabs']>(
        () => [
            {
                title: 'Modules.Admin.RoleEdit.permissionTab',
                content: role && <RoleEditWrapper role={role} />,
            },
            {
                title: 'Modules.Admin.RoleEdit.usersTab',
                content: (
                    <Box>
                        <FilterableEntityTable
                            key={usersPaginationTag}
                            tag={usersPaginationTag}
                            filters={usersDefaultFilters}
                            {...userEntityTableProps}
                        />
                    </Box>
                ),
            },
        ],
        [role, usersDefaultFilters, usersPaginationTag],
    );
    return (
        <>
            <TabsWrapper tabs={tabs} />
        </>
    );
};

const setup: PageEntityConfig<Role> = {
    id: 'EditRolePage',
    component: EditRolePage,
    systemSearch: {
        title: 'Modules.Admin.RoleEdit.metaTitle',
        description: 'Modules.Admin.RoleEdit.description',
        type: 'entity',
        // aliases: [],
        route: PostAuthRoutes.Admin.EditRole,
        saveAsRecentPage: true,
        defaultIncludes: ['permissions'],
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            { label: 'Modules.Admin.RolesList.metaTitle', link: PostAuthRoutes.Admin.RoleList },
        ],
        entityName: 'Role',
        icon: roleIcon,
        nameGen: (entity, id) => entity?.name ?? String(id),
        permissions: [PermissionName.UniledportalRoleRead, PermissionName.UniledportalRoleUpdate],
    },
};

export default setup;
