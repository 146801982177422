import React, { useCallback, useEffect, useMemo } from 'react';
import { MediaOwnerIssueRow, MediaOwnerIssues } from 'c-main/Types';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { NetworkRequestState } from '@uniled/data-layer';
import { Box, CircularProgress } from '@mui/material';
import to from 'await-to-js';
import MediaOwnerGroupedIssues from './MediaOwnerGroupedIssues';

type Props = {
    id: number;
};

const CampaignDisplayIssues: React.FC<Props> = ({ id }) => {
    const { start, requestState, data, error } = useAPIClientRequest(
        apiClient.Entities.Campaign.displayIssues,
    );

    const fetchIssues = useCallback(async () => {
        await to(start(id));
    }, [start, id]);
    useEffect(() => {
        fetchIssues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const issues = useMemo<MediaOwnerIssues[]>(() => {
        if (data) {
            return data?.data?.meta?.mediaOwners.reduce(
                (acc, owner) => [
                    ...acc,
                    {
                        mediaOwnerId: owner.id,
                        mediaOwnerName: owner.name,
                        enabled: owner.display_verification_enabled,
                        issues: data?.data?.data.reduce((ownerIssues, issue) => {
                            if (
                                issue.mediaOwners.id === owner.id &&
                                issue.campaignDisplays?.length > 0
                            ) {
                                return [
                                    ...ownerIssues,
                                    ...issue.campaignDisplays.map(
                                        display =>
                                            ({
                                                displayName: display.name,
                                                displayId: display.id,
                                                startDate: issue.case_start_time,
                                                endDate: issue.case_end_time,
                                                incidentId: issue.case_number,
                                            } as MediaOwnerIssueRow),
                                    ),
                                ];
                            }

                            return ownerIssues;
                        }, [] as MediaOwnerIssueRow[]),
                    } as MediaOwnerIssues,
                ],
                [] as MediaOwnerIssues[],
            );
        }
        return [];
    }, [data]);

    return (
        <>
            <Box textAlign="center">
                {requestState === NetworkRequestState.InProgress && <CircularProgress />}
            </Box>
            {requestState !== NetworkRequestState.InProgress && issues.length > 0 && (
                <MediaOwnerGroupedIssues issues={issues} />
            )}
        </>
    );
};

export default CampaignDisplayIssues;
