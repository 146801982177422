import React, { useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Displays_LineItem, Campaign } from '@uniled/api-sdk';
import { useDateUtils, useEntityPage } from 'c-hooks';
import { useEntityData } from 'c-data';

type Props = RenderColumnDataProps<Displays_LineItem>;

export const LineItemStartDateCell: React.FC<Props> = props => (
    <LineItemDatesCell {...props} dateCol="start_date" />
);
export const LineItemEndDateCell: React.FC<Props> = props => (
    <LineItemDatesCell {...props} dateCol="end_date" />
);

/**
 * We're assuming this component will be used in the displays tab of the campaign by id page.
 *
 * `CampaignOverviewPage.tsx` already includes all the budget stuff from the campaign
 *
 */
const LineItemDatesCell: React.FC<Props & { dateCol: 'start_date' | 'end_date' }> = ({
    entity,
    dateCol,
}) => {
    const { id } = useEntityPage();
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });

    const lineItem = useMemo(
        () =>
            campaign?.budgetSummary?.lineItems?.find(
                ({ line_item_id }) => entity?.id != null && line_item_id === entity?.id,
            ),
        [campaign, entity],
    );

    const { formatDateStringIgnoreTime } = useDateUtils();
    const colData = useMemo(() => lineItem?.[dateCol], [lineItem, dateCol]);
    const formattedDate = useMemo(
        () => (colData != null ? formatDateStringIgnoreTime({ date: colData }) : '-'),
        [formatDateStringIgnoreTime, colData],
    );

    return <>{formattedDate}</>;
};
