import React from 'react';
import { formatNumberWhole } from 'c-lib';

type Props = {
    value: number | string;
    intlOptions?: Intl.NumberFormatOptions;
};

const NumberFormatWhole: React.FC<Props> = ({ value, intlOptions }) => (
    <>{formatNumberWhole(value, intlOptions)}</>
);

export default NumberFormatWhole;
