import React, { useMemo } from 'react';
import { TooltipProps } from 'recharts';
import { PCAReportCountField, PCAReportMetric } from '@uniled/api-sdk';
import { Box, Divider, Paper, Typography } from '@mui/material';
import { formatNumber, formatNumberWhole, secondsToHms } from 'c-lib';
import { NumberFormat, NumberFormatWhole } from 'c-components';
import { ReportDateTimeframe } from 'c-reports/Types';
import { FieldReportType } from 'c-main/Components/Campaign/CampaignReports/types';
import { Translate } from 'c-translation';
import HoursMins from '../../HoursMins';

type Props = {
    tooltipProps: TooltipProps<any, any>;
    expectedLabel: string;
    percentageLabel: string;
    inScheduleLabel: string;
    outScheduleLabel: string;
    unbookedLabel: string;
    timeFrame: ReportDateTimeframe;
    type: FieldReportType;
    field: string;
    metric: PCAReportMetric;
    countFields: PCAReportCountField[];
    hideActualValue?: boolean;
};

const CustomTooltip: React.FC<Props> = ({
    tooltipProps,
    expectedLabel,
    percentageLabel,
    inScheduleLabel,
    outScheduleLabel,
    unbookedLabel,
    type,
    timeFrame,
    field,
    metric,
    countFields,
    hideActualValue = false,
}) => {
    const showCountFields = useMemo(() => {
        if (countFields.length === 0) {
            return false;
        }

        if (type === FieldReportType.OverTime && field != null) {
            return false;
        }

        return true;
    }, [countFields.length, field, type]);
    return (
        <Paper elevation={4} sx={{ p: 2 }}>
            <Box maxWidth="25em">
                <Typography variant="subtitle2" noWrap>
                    {type === FieldReportType.Overall && tooltipProps.label}
                    {type === FieldReportType.OverTime &&
                        (timeFrame === ReportDateTimeframe.Weekly ||
                            timeFrame === ReportDateTimeframe.BiWeekly ||
                            timeFrame === ReportDateTimeframe.Monthly ||
                            timeFrame === ReportDateTimeframe.Range ||
                            timeFrame === ReportDateTimeframe.All) &&
                        tooltipProps.label}
                    {type === FieldReportType.OverTime &&
                        timeFrame === ReportDateTimeframe.Daily &&
                        tooltipProps.label}
                </Typography>
            </Box>
            <Box>
                {tooltipProps.payload.map(p => {
                    const hms =
                        metric === PCAReportMetric.Time
                            ? secondsToHms(p.payload[p.dataKey])
                            : undefined;
                    const expectedHms =
                        metric === PCAReportMetric.Time && field != null
                            ? secondsToHms(p.payload[`${p.dataKey} ${expectedLabel}`])
                            : undefined;
                    return (
                        <Box
                            key={p.dataKey}
                            sx={{
                                color: p.color,
                            }}
                        >
                            {!hideActualValue && (
                                <Typography variant="body1">
                                    {p.dataKey}:{' '}
                                    {metric === PCAReportMetric.Time && hms != null ? (
                                        <>
                                            <Translate
                                                path="Reporting.ticks.hoursLabel"
                                                data={{ count: formatNumber(hms.h) }}
                                            />
                                            {', '}
                                            <Translate
                                                path="Reporting.ticks.minutesLabel"
                                                data={{ count: formatNumber(hms.m) }}
                                            />
                                            {hms.s > 0 && (
                                                <>
                                                    {', '}
                                                    <Translate
                                                        path="Reporting.ticks.secondsLabel"
                                                        data={{ count: formatNumber(hms.s) }}
                                                    />
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <NumberFormatWhole value={p.payload[p.dataKey]} />
                                    )}
                                    {type === FieldReportType.OverTime &&
                                        field != null &&
                                        p.payload[`${p.dataKey} ${percentageLabel}`] != null && (
                                            <>
                                                /{' '}
                                                {metric === PCAReportMetric.Time &&
                                                expectedHms != null ? (
                                                    <>
                                                        <Translate
                                                            path="Reporting.ticks.hoursLabel"
                                                            data={{
                                                                count: formatNumber(expectedHms.h),
                                                            }}
                                                        />
                                                        {', '}
                                                        <Translate
                                                            path="Reporting.ticks.minutesLabel"
                                                            data={{
                                                                count: formatNumber(expectedHms.m),
                                                            }}
                                                        />
                                                        {expectedHms.s > 0 && (
                                                            <>
                                                                {', '}
                                                                <Translate
                                                                    path="Reporting.ticks.secondsLabel"
                                                                    data={{
                                                                        count: formatNumber(
                                                                            expectedHms.s,
                                                                        ),
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <NumberFormatWhole
                                                        value={
                                                            p.payload[
                                                                `${p.dataKey} ${expectedLabel}`
                                                            ]
                                                        }
                                                    />
                                                )}{' '}
                                                (
                                                <NumberFormat
                                                    value={
                                                        p.payload[`${p.dataKey} ${percentageLabel}`]
                                                    }
                                                />
                                                %)
                                            </>
                                        )}
                                </Typography>
                            )}
                        </Box>
                    );
                })}
                {(type === FieldReportType.Overall ||
                    (type === FieldReportType.OverTime && field == null)) && (
                    <Box>
                        <Typography variant="body1">
                            {percentageLabel}:{' '}
                            <NumberFormat
                                value={tooltipProps.payload?.[0]?.payload?.[percentageLabel]}
                            />
                            %
                        </Typography>
                    </Box>
                )}
                {showCountFields && (
                    <>
                        <Divider sx={{ my: 2 }} />
                        {countFields.indexOf(PCAReportCountField.InSchedule) !== -1 && (
                            <CountField
                                metric={metric}
                                count={tooltipProps.payload?.[0]?.payload?.[inScheduleLabel]}
                                label={inScheduleLabel}
                            />
                        )}
                        {countFields.indexOf(PCAReportCountField.OutSchedule) !== -1 && (
                            <CountField
                                metric={metric}
                                count={tooltipProps.payload?.[0]?.payload?.[outScheduleLabel]}
                                label={outScheduleLabel}
                            />
                        )}
                        {countFields.indexOf(PCAReportCountField.UnBooked) !== -1 && (
                            <CountField
                                metric={metric}
                                count={tooltipProps.payload?.[0]?.payload?.[unbookedLabel]}
                                label={unbookedLabel}
                            />
                        )}
                    </>
                )}
            </Box>
        </Paper>
    );
};

const CountField: React.FC<{ count: number; label: string; metric: PCAReportMetric }> = ({
    count,
    label,
    metric,
}) => (
    <Box>
        <Typography variant="body1">
            {label}:{' '}
            {metric === PCAReportMetric.Time ? (
                <HoursMins
                    variant="body1"
                    color={null}
                    seconds={count}
                    alwaysShowHours
                    alwaysShowMinutes
                    alwaysShowSeconds
                />
            ) : (
                formatNumberWhole(count)
            )}
        </Typography>
    </Box>
);
export default CustomTooltip;
