import React, { useMemo } from 'react';
import { SvgIconProps } from '@mui/material';
import { Description, Image, PictureAsPdf, VideoCameraFront } from '@mui/icons-material';

type Props = {
    mimetype: string;
} & SvgIconProps;

const AttachmentIcon: React.FC<Props> = ({ mimetype, ...props }) => {
    const Icon: React.FC<SvgIconProps> = useMemo(() => {
        switch (true) {
            case mimetype.indexOf('image') !== -1:
                return Image;
            case mimetype.indexOf('video') !== -1:
                return VideoCameraFront;
            case mimetype.indexOf('pdf') !== -1:
                return PictureAsPdf;
            // case mimetype.indexOf('xls') !== -1:
            // case mimetype.indexOf('csv') !== -1:
            default:
                return Description;
        }
    }, [mimetype]);

    return <Icon {...props} />;
};
export default AttachmentIcon;
