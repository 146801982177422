import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
    CampaignCreativeAutoAssignRequest,
    CampaignCreativeMassUnAssignRequest,
    Displays_LineItem,
} from '@uniled/api-sdk';
import { RenderColumnHeaderProps } from 'c-pagination';
import { useCommonTranslation } from 'c-translation';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { omit } from 'ramda';
import { AutoGrid, Button } from 'c-components';
import { PublishedWithChanges } from '@mui/icons-material';
import to from 'await-to-js';
import { useAPIClientRequest, useEntityPage } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { PaginatedEntityTableContext } from 'c-pagination/EntityPaginationContext';
import { AssignPerms, MassUnAssignPerms } from './constants';

type Props = RenderColumnHeaderProps<Displays_LineItem>;

const CampaignDisplaysControlsHeader: React.FC<Props> = ({ column, paginationData }) => {
    const { id: campaign } = useEntityPage();

    const {
        start: AutoAssignLineItem,
        isLoading: IsAutoAssignLoading,
        hasSucceeded: AutoAssignSuccess,
        hasFailed: AutoAssignFailed,
        error: AutoAssignError,
        reset: ResetAutoAssign,
    } = useAPIClientRequest(apiClient.Entities.Campaign.autoAssignCreativesToLineItems);

    const {
        start: MassUnAssignLineItem,
        isLoading: IsMassUnAssignLoading,
        hasSucceeded: MassUnAssignSuccess,
        hasFailed: MassUnAssignFailed,
        error: MassUnAssignError,
        reset: ResetMassUnAutoAssign,
    } = useAPIClientRequest(apiClient.Entities.Campaign.massUnAssignCreativesFromLineItems);

    const autoAssignLineItem = useCallback(async () => {
        const assignData: CampaignCreativeAutoAssignRequest = {};
        if (
            Array.isArray(paginationData?.filters?.creativeGroups) &&
            paginationData?.filters?.creativeGroups?.length > 0
        ) {
            assignData.creativeGroups = paginationData?.filters?.creativeGroups as number[];
        }
        assignData.filters = omit(['creativeGroups'], paginationData?.filters ?? {});
        assignData.searchables = paginationData.searchables;
        await to(AutoAssignLineItem(campaign, assignData));
    }, [AutoAssignLineItem, campaign, paginationData]);

    const massUnAssignLineItem = useCallback(async () => {
        const unAssignData: CampaignCreativeMassUnAssignRequest = {};
        if (
            Array.isArray(paginationData?.filters?.creativeGroups) &&
            paginationData?.filters?.creativeGroups?.length > 0
        ) {
            unAssignData.creative_group_ids = paginationData?.filters?.creativeGroups as number[];
        }
        unAssignData.filters = omit(['creativeGroups'], paginationData?.filters ?? {});
        unAssignData.searchables = paginationData.searchables;
        await to(MassUnAssignLineItem(campaign, unAssignData));
    }, [MassUnAssignLineItem, campaign, paginationData]);

    const { search } = useContext(PaginatedEntityTableContext);
    useEffect(() => {
        if (AutoAssignSuccess) {
            ResetAutoAssign();
            // refresh the list
            search();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AutoAssignSuccess]);
    useEffect(() => {
        if (MassUnAssignSuccess) {
            ResetMassUnAutoAssign();
            // refresh the list
            search();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [MassUnAssignSuccess]);

    const t = useCommonTranslation();
    const autoAssignTooltipTitle = useMemo(() => {
        if (!AutoAssignFailed)
            return t('Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.autoAssign');

        if (String(AutoAssignError).length > 0) return String(AutoAssignError);

        return t('Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.autoAssignFailed');
    }, [AutoAssignFailed, AutoAssignError, t]);

    const massUnAssignTooltipTitle = useMemo(() => {
        if (!MassUnAssignFailed)
            return t('Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.massUnAssign');

        if (String(MassUnAssignError).length > 0) return String(MassUnAssignError);

        return t('Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.massUnAssignFailed');
    }, [MassUnAssignFailed, AutoAssignError, t]);

    return (
        <AutoGrid xs flexWrap="nowrap" gap={1}>
            {t(column.headerTitle)}
            <IfHasAllPermissions permissions={AssignPerms}>
                <Tooltip title={autoAssignTooltipTitle}>
                    <Box>
                        <Button
                            iconChild
                            size="small"
                            onClick={autoAssignLineItem}
                            disabled={IsMassUnAssignLoading || IsAutoAssignLoading}
                            color={AutoAssignFailed ? 'error' : undefined}
                        >
                            {!IsAutoAssignLoading && <PublishedWithChanges fontSize="inherit" />}
                            {IsAutoAssignLoading && (
                                <Box display="flex" alignItems="center">
                                    <CircularProgress size={15} color="inherit" />
                                </Box>
                            )}
                        </Button>
                    </Box>
                </Tooltip>
            </IfHasAllPermissions>
            <IfHasAllPermissions permissions={MassUnAssignPerms}>
                <Tooltip title={massUnAssignTooltipTitle}>
                    <Box>
                        <Button
                            iconChild
                            size="small"
                            onClick={massUnAssignLineItem}
                            disabled={IsMassUnAssignLoading || IsAutoAssignLoading}
                            color={MassUnAssignFailed ? 'error' : 'warning'}
                        >
                            {!IsMassUnAssignLoading && <PublishedWithChanges fontSize="inherit" />}
                            {IsMassUnAssignLoading && (
                                <Box display="flex" alignItems="center">
                                    <CircularProgress size={15} color="inherit" />
                                </Box>
                            )}
                        </Button>
                    </Box>
                </Tooltip>
            </IfHasAllPermissions>
        </AutoGrid>
    );
};

export default CampaignDisplaysControlsHeader;
