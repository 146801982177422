import React, { PropsWithChildren, useMemo } from 'react';
import { Box, BoxProps, Card, CardHeader, CardHeaderProps, CardProps, Grid } from '@mui/material';
import { useIsMobileView } from 'c-hooks';
import { css } from '@emotion/react';
import { CardControl } from 'c-types';
import TransparentCardControls from './TransparentCardControls';

type Props = {
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    cardHeaderProps?: CardHeaderProps;
    controls?: React.ReactNode[];
    controlsUnderTitle?: boolean;
    controlsNextToTitle?: boolean;
    titleTypographyProps?: CardHeaderProps['titleTypographyProps'];
    sx?: CardProps['sx'];
    fullWidth?: boolean;
    dropdownControls?: CardControl[];
};

const TransparentCard: React.FC<PropsWithChildren<Props>> = ({
    title,
    subTitle,
    cardHeaderProps,
    controls,
    controlsUnderTitle = false,
    controlsNextToTitle = false,
    titleTypographyProps,
    sx,
    fullWidth = true,
    dropdownControls,
    children,
}) => {
    const isMobile = useIsMobileView();

    const actualControls = useMemo(() => {
        const controlsArray = controls ?? [];

        if (controlsArray.length > 0) {
            return (
                <Grid container spacing={isMobile ? 1 : 2}>
                    {controlsArray
                        .filter(c => c != null && c !== false)
                        .map((con, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Grid key={i} item sx={{ display: 'flex', alignItems: 'center' }}>
                                {con}
                            </Grid>
                        ))}
                </Grid>
            );
        }

        return null;
    }, [controls, isMobile]);

    const titleControls = useMemo(
        () =>
            dropdownControls != null && dropdownControls?.length > 0 ? (
                <TransparentCardControls controls={dropdownControls} />
            ) : null,
        [dropdownControls],
    );

    const actualTitle = useMemo(() => {
        const wrapperProps: BoxProps = { sx: { color: 'secondaryText' } };
        if (actualControls) {
            return (
                <Box {...wrapperProps}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: controlsNextToTitle ? undefined : 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box display="flex">
                            {title} {titleControls}
                        </Box>
                        {(!isMobile || !controlsUnderTitle) && <Box>{actualControls}</Box>}
                    </Box>
                    {isMobile && controlsUnderTitle && <Box mt={1}>{actualControls}</Box>}
                </Box>
            );
        }
        return (
            <Box {...wrapperProps}>
                {title} {titleControls}
            </Box>
        );
    }, [title, actualControls, isMobile, controlsUnderTitle, titleControls, controlsNextToTitle]);

    const cardHeaderSx = useMemo(() => ({ ...cardHeaderProps?.sx, px: 0 }), [cardHeaderProps?.sx]);

    return (
        <Card
            elevation={0}
            sx={{
                bgcolor: 'transparent',
                display: 'flex',
                flexDirection: 'column',
                width: fullWidth ? '100%' : undefined,
                ...sx,
            }}
            css={css('.MuiCardContent-root{padding-left: 0;padding-right:0;}')}
        >
            {title != null && (
                <CardHeader
                    title={actualTitle}
                    titleTypographyProps={{
                        color: 'textSecondary',
                        variant: isMobile ? 'h3' : 'h1',
                        ...titleTypographyProps,
                    }}
                    subheader={subTitle}
                    {...cardHeaderProps}
                    sx={cardHeaderSx}
                />
            )}
            {children}
        </Card>
    );
};

export default TransparentCard;
