import React, { useCallback, useRef } from 'react';
import { Button } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { Menu, MenuItem } from '@mui/material';
import { useBoolean } from 'react-hanger';
import { parse } from 'json2csv';

type Props = {
    data: any[];
    namePrefix: string;
};

const ExportDataButton: React.FC<Props> = ({ data, namePrefix }) => {
    const btnRef = useRef();

    const { value: OpenValue, toggle, setFalse: CloseDropdown } = useBoolean(false);

    const downloadFile = useCallback(
        (name: string, content: string, mimetype: string) => {
            const dataUrl = `data:${mimetype};charset=utf-8,${encodeURIComponent(content)}`;

            const saveLink = document.createElement('a');
            // download file name
            saveLink.download = name;
            // download file data
            saveLink.href = dataUrl;
            // start download
            saveLink.click();

            CloseDropdown();
        },
        [CloseDropdown],
    );
    const onJSON = useCallback(() => {
        downloadFile(`${namePrefix}.json`, JSON.stringify(data, null, 2), 'text/json');
    }, [data, downloadFile, namePrefix]);
    const onCSV = useCallback(() => {
        downloadFile(`${namePrefix}.csv`, parse(data), 'text/csv');
    }, [data, downloadFile, namePrefix]);

    return (
        <>
            <Button variant="outlined" ref={btnRef} onClick={toggle}>
                {useCommonTranslation('Reporting.exporting.exportData')}
            </Button>
            <Menu open={OpenValue} onClose={CloseDropdown} anchorEl={btnRef.current}>
                <MenuItem onClick={onJSON}>JSON</MenuItem>
                <MenuItem onClick={onCSV}>CSV</MenuItem>
            </Menu>
        </>
    );
};

export default ExportDataButton;
