import React, { useCallback, useEffect, useMemo } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { packsListIcon } from 'c-main/icons';
import { Displays_ScreenAnimationType, PermissionName } from '@uniled/api-sdk';
import { FilterableEntityTable, FilterableEntityTableRendererType } from 'c-pagination';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import {
    AllEntitiesDropdown,
    AutoGrid,
    ControlledFormInput,
    CreateEntityButton,
    DialogV2,
    SelectField,
    TextAreaField,
    TextField,
} from 'c-components';
import { useAtom } from 'jotai';
import { atom_PacksListDialogState } from 'c-displays/atoms';
import {
    displayPacksColumns,
    displayScreenColumnsOtherTables,
} from 'c-displays/Components/DisplaysEntityColumns';
import { useCommonTranslation } from 'c-translation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Box, FormLabel, IconButton, Stack } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ReactGA from 'react-ga';
import { generatePath } from 'react-router-dom';

const textSearchCols = ['name'];
const paginationTag = 'displays-admin-packs-list';

const PackListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const t = useCommonTranslation();
    const schema = yup.object().shape({
        market_id: yup.number().required('Market ID is required'),
        owner_id: yup.number().required('Owner ID is required'),
        pack_type: yup.string().required('Pack Type is required'),
        size: yup.string().required('Size is required'),
        slots: yup.string().required('Slots is required'),
        name: yup.string().required('Name is required'),
        resolutions: yup.array().required(),
    });
    const [atomValue, setAtomState] = useAtom(atom_PacksListDialogState);
    const closeDialog = useCallback(() => {
        setAtomState({ status: null, id: null });
    }, [setAtomState]);

    const displayScreensTable = useMemo(
        () => (
            <DialogV2 maxWidth="lg" onClose={closeDialog} open={atomValue.id != null}>
                <FullPageEntityTableWrapper title="Modules.Displays.DisplaysList.metaTitle">
                    <FilterableEntityTable
                        key={paginationTag}
                        tag={paginationTag}
                        columns={displayScreenColumnsOtherTables}
                        baseEntityName="Displays_Screen"
                        textSearchColumns={textSearchCols}
                        rendererType={FilterableEntityTableRendererType.Contained}
                        revertToIdSearchOnNumberOnlyInput={false}
                        orderBy="id"
                        direction="desc"
                        showFilters
                        onRowClick={id => {
                            const rout = generatePath(PostAuthRoutes.Displays.DisplaysDisplayEdit, {
                                id,
                            });
                            window.open(rout, '_blank');
                        }}
                        filters={{
                            'filter.status': [atomValue.status],
                            'filter.packs.id': [atomValue.id],
                        }}
                        includes={['mediaOwners', 'resolutions', 'packs', 'status']}
                        resetOnUnmount
                    />
                </FullPageEntityTableWrapper>
            </DialogV2>
        ),
        [atomValue.id, atomValue.status, closeDialog],
    );

    return (
        <>
            {displayScreensTable}
            <FullPageEntityTableWrapper
                title="Modules.Displays.Packs.metaTitle"
                controls={useMemo(
                    () => [
                        <CreateEntityButton
                            // transformData={(data: any) => ({
                            //     ...data,
                            //     resolutions: data.resolutions.map(
                            //         resolution => `${resolution.width}x${resolution.height}`,
                            //     ),
                            // })}
                            entityName="Displays_Pack"
                            title="Modules.Displays.Packs.createBtn"
                            form={<NewPackForm />}
                            buttonLabel={t('Modules.Displays.Packs.createBtn')}
                            submitLabel="Modules.Displays.Packs.submitBtn"
                            key="new-pack-button"
                            permissions={[PermissionName.DisplaysPacksCreate]}
                            redirectPath={PostAuthRoutes.Displays.DisplaysPackEdit}
                            formOptions={{
                                resolver: yupResolver(schema),
                            }}
                            maxWidth="xl"
                        />,
                    ],
                    [schema, t],
                )}
            >
                <FilterableEntityTable
                    key={paginationTag}
                    tag={paginationTag}
                    columns={displayPacksColumns}
                    baseEntityName="Displays_Pack"
                    textSearchColumns={textSearchCols}
                    rendererType={FilterableEntityTableRendererType.Contained}
                    direction="desc"
                    showFilters
                    includes={[
                        'country',
                        'activeDisplaysCount',
                        'inactiveDisplaysCount',
                        'resolutions',
                        'market',
                        'filetypes',
                        'sov',
                        'owner',
                    ]}
                />
            </FullPageEntityTableWrapper>
        </>
    );
};
const NewPackForm = () => {
    const { append, fields, remove } = useFieldArray({
        name: 'resolutions',
    });
    const methods = useFormContext();
    const { control, setValue: setFormValue, getValues, watch } = methods;
    const handleSlotsChange = useCallback(
        e => {
            const slotsValue = e.target.value;
            if (slotsValue != null) {
                const calculatedSov = slotsValue !== '' ? 100 / slotsValue : '';
                setFormValue('slots', slotsValue);
                setFormValue('sov', Number(calculatedSov).toFixed(10));
            }
        },
        [setFormValue],
    );

    const handleSovChange = useCallback(
        e => {
            const sovValue = e.target.value;
            if (sovValue != null) {
                const calculatedSlots = sovValue !== '' ? 100 / sovValue : '';
                setFormValue('sov', sovValue);
                setFormValue('slots', Number(calculatedSlots).toFixed(10));
            }
        },
        [setFormValue],
    );
    const removeResolution = useCallback(
        index => {
            remove(index);
            const updatedResolutions = [...getValues('resolutions')];
            updatedResolutions.splice(index, 1);
            setFormValue('resolutions', updatedResolutions);
        },
        [getValues, remove, setFormValue],
    );
    const t = useCommonTranslation();
    const market = watch('market_id');

    const ownerField = useMemo(
        () => (
            <ControlledFormInput
                name="owner_id"
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.DisplaysDisplayEdit.fields.mediaOwners')} *
                        </FormLabel>
                        <AllEntitiesDropdown
                            entityName="Displays_Owner"
                            labelField="name"
                            {...field}
                            useLocalEntityData
                            filter={{ 'filter.markets.id': [market] }}
                            includes={['market']}
                            loadingDisable
                            disabled={market == null}
                        />
                    </>
                )}
            />
        ),
        [market, t],
    );
    return (
        <AutoGrid gap={3} xs={4} justifyContent="center">
            <ControlledFormInput
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.DisplaysPackEdit.fields.type')} *
                        </FormLabel>
                        <SelectField
                            value={field.value}
                            options={[
                                {
                                    value: 'pack',
                                    label: t('Modules.Displays.DisplaysPackEdit.fields.pack'),
                                },
                                {
                                    value: 'site',
                                    label: t('Modules.Displays.DisplaysPackEdit.fields.site'),
                                },
                            ]}
                            {...field}
                        />
                    </>
                )}
                name="pack_type"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.DisplaysPackEdit.fields.name')} *
                        </FormLabel>
                        <TextField {...field} />
                    </>
                )}
                name="name"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.DisplaysPackEdit.fields.market')} *
                        </FormLabel>
                        <AllEntitiesDropdown
                            entityName="Market"
                            labelField="name"
                            useLocalEntityData
                            {...field}
                        />
                    </>
                )}
                name="market_id"
            />
            {ownerField}
            <ControlledFormInput
                render={({ field }) => (
                    <>
                        <FormLabel>{t('Modules.Displays.DisplaysPackEdit.fields.env')}</FormLabel>
                        <TextField {...field} />
                    </>
                )}
                name="env"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <>
                        <FormLabel>
                            {t('Modules.Displays.DisplaysPackEdit.fields.animation')}
                        </FormLabel>
                        <SelectField
                            value={field.value}
                            options={[
                                {
                                    value: Displays_ScreenAnimationType.Static,
                                    label: t('Modules.Displays.DisplaysPackEdit.fields.static'),
                                },
                                {
                                    value: Displays_ScreenAnimationType.Motion,
                                    label: t('Modules.Displays.DisplaysPackEdit.fields.motion'),
                                },
                                {
                                    value: Displays_ScreenAnimationType.Subtle,
                                    label: t('Modules.Displays.DisplaysPackEdit.fields.subtle'),
                                },
                            ]}
                            {...field}
                        />
                    </>
                )}
                name="animation"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.DisplaysPackEdit.fields.size')} *
                        </FormLabel>
                        <TextField {...field} />
                    </>
                )}
                name="size"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.DisplaysPackEdit.fields.slotLength')} *
                        </FormLabel>
                        <TextField type="number" {...field} />
                    </>
                )}
                name="slot_length"
            />
            <Controller
                name="slots"
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.DisplaysPackEdit.fields.slots')} *
                        </FormLabel>
                        <TextField {...field} onChange={handleSlotsChange} type="number" />
                    </>
                )}
            />
            <Controller
                name="sov"
                render={({ field }) => (
                    <>
                        <FormLabel>
                            {t('Modules.Displays.DisplaysDisplayEdit.fields.sov')}
                        </FormLabel>
                        <TextField {...field} onChange={handleSovChange} />
                    </>
                )}
            />
            <ControlledFormInput
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.DisplaysPackEdit.fields.fileTypes')} *
                        </FormLabel>
                        <AllEntitiesDropdown
                            entityName="Displays_FileType"
                            labelField="type"
                            valueField="type"
                            multi
                            {...field}
                            useLocalEntityData
                        />
                    </>
                )}
                name="filetypes"
            />
            <ControlledFormInput
                name="brochurelink"
                render={({ field }) => (
                    <>
                        <FormLabel>
                            {t('Modules.Displays.DisplaysPackEdit.fields.brochureLink')}
                        </FormLabel>
                        <TextField {...field} />
                    </>
                )}
            />
            <ControlledFormInput
                name="description"
                render={({ field }) => (
                    <>
                        <FormLabel>
                            {t('Modules.Displays.DisplaysPackEdit.fields.description')}
                        </FormLabel>
                        <TextAreaField {...field} />
                    </>
                )}
            />
            <ControlledFormInput
                name="resolutions"
                render={() => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.DisplaysPackEdit.fields.resolutions')} * :
                        </FormLabel>
                        {fields.map((resolution, index) => (
                            <Stack key={resolution.id} direction="row">
                                <Box>
                                    <Stack pr={1}>
                                        <FormLabel>
                                            {t(
                                                'Modules.Displays.DisplaysPackEdit.fields.resolutionWidth',
                                            )}
                                        </FormLabel>
                                        <ControlledFormInput
                                            name={`resolutions[${index}].width`}
                                            render={({ field }) => <TextField {...field} />}
                                        />
                                    </Stack>
                                </Box>
                                <Box>
                                    <Stack>
                                        <FormLabel>
                                            {t(
                                                'Modules.Displays.DisplaysPackEdit.fields.resolutionHeight',
                                            )}
                                        </FormLabel>
                                        <ControlledFormInput
                                            name={`resolutions[${index}].height`}
                                            render={({ field }) => <TextField {...field} />}
                                        />
                                    </Stack>
                                </Box>
                                <Box p={1}>
                                    <IconButton
                                        sx={{ marginTop: '100%' }}
                                        size="small"
                                        onClick={() => removeResolution(index)}
                                    >
                                        <RemoveIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            </Stack>
                        ))}
                        <Box>
                            <IconButton
                                size="small"
                                sx={{ marginRight: '62.5%', marginLeft: '37.5%', p: 1 }}
                                onClick={() => append({ width: '', height: '' })}
                            >
                                <AddIcon fontSize="inherit" />
                            </IconButton>
                        </Box>
                    </>
                )}
            />
        </AutoGrid>
    );
};
const setup: PageConfig = {
    id: 'PackListPage',
    component: PackListPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.Displays.Packs.metaTitle',
        description: 'Modules.Displays.Packs.description',
        icon: packsListIcon,
        type: 'page',
        aliases: 'SystemSearch.aliases.packsAdminDisplayList',
        route: PostAuthRoutes.Displays.PackList,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.Displays.AdminHome.metaTitle',
                link: PostAuthRoutes.Displays.DisplaysAdminHome,
            },
            { label: 'Modules.Displays.Packs.metaTitle' },
        ],
        permissions: [PermissionName.Admin],
    },
};

export default setup;
