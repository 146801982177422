import React, { useCallback, useMemo, useState } from 'react';
import {
    Alert,
    AutoGrid,
    Button,
    DialogV2,
    FileUploadDropzone,
    ListItemText,
    SelectField,
} from 'c-components';
import { useCommonTranslation } from 'c-translation';

import {
    Box,
    FormLabel,
    LinearProgress,
    LinearProgressProps,
    SelectChangeEvent,
    Stack,
    Tooltip,
    Typography,
    TypographyProps,
} from '@mui/material';
import { useAPIClientRequest } from 'c-hooks';
import { apiClient } from 'c-data';
import to from 'await-to-js';
import { NetworkRequestState } from '@uniled/data-layer';
import { CheckCircle, Error } from '@mui/icons-material';
import { formatBytes } from 'c-lib';

type BooleanState = {
    value: boolean;
    toggle: () => void;
    setTrue: () => void;
    setFalse: () => void;
};
interface PcaDialogProps {
    id: string | number;
    pcaState: BooleanState;
}

const primaryTypography: TypographyProps<any> = {
    component: 'div',
    sx: { wordBreak: 'break-all' },
};
export const PcaDialog: React.FC<PcaDialogProps> = ({ id, pcaState }) => {
    const t = useCommonTranslation();
    const [environment, setEnvironment] = useState<string>();
    const [files, setFiles] = useState<File[]>();
    const [progress, setProgress] = useState<ProgressEvent>(null);
    const progressPercentage = useMemo(
        () =>
            progress?.total != null && progress?.loaded != null
                ? (progress.loaded / progress.total) * 100
                : 0,
        [progress],
    );
    const {
        start,
        error,
        requestState,
        reset: resetNetworkState,
    } = useAPIClientRequest(apiClient.Entities.Campaign.pcaImport);
    const {
        start: downloadStart,
        error: downloadError,
        requestState: downloadState,
        reset: downloadReset,
    } = useAPIClientRequest(apiClient.Entities.Campaign.pcaExport);
    const handleFormSubmit: React.MouseEventHandler<HTMLButtonElement> = useCallback(async () => {
        downloadReset();
        resetNetworkState();
        setProgress(null);
        const formData = new FormData();
        formData.append('environment', environment as string);
        const [err, success] = await to(downloadStart(Number(id), formData));
    }, [downloadReset, downloadStart, environment, id, resetNetworkState]);
    const progressColor = useMemo<LinearProgressProps['color']>(() => {
        if (requestState === NetworkRequestState.Error) return 'error';
        if (progressPercentage >= 100) return 'success';

        return 'primary';
    }, [requestState, progressPercentage]);

    const onFilesAdded = useCallback(
        async (newFiles: File[]) => {
            setFiles(newFiles);
            resetNetworkState();
            downloadReset();
            setProgress(null);
            const formData = new FormData();
            formData.append('environment', environment as string);
            if (newFiles.length !== 0) {
                formData.append('file', newFiles[0]);
            }
            const [err, success] = await to(
                start(Number(id), { formData, onProgress: setProgress }),
            );
        },
        [environment, id, resetNetworkState, start, downloadReset],
    );

    const envHandler = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            resetNetworkState();
            downloadReset();
            const selectedValue = event.target.value as string;
            setEnvironment(selectedValue);
            setFiles(null);
        },
        [downloadReset, resetNetworkState],
    );

    const templateHeaders = [
        'Campaign ID',
        'Campaign Name',
        'Booking reference',
        'Campaign Buyer',
        'Campaign Agency',
        'Campaign Brand',
        'Schedule',
        'Frame ID',
        'Inside POP date',
        'Start Date',
        'Start Time',
        'Start Datetime',
        'End Date',
        'End Time',
        'End Datetime',
        'DOW',
        'Creative ID',
        'Creative Name',
        'Sum of Plays',
        'Expected Plays',
        'Number of Unique Creatives',
        'Sum of Time',
        'SOV',
        'Slot duration',
        'Expected time',
        'Audience SOV',
        'Audience',
        '% Playout',
        '% Playout Time',
        'Expected Impacts',
        'Impacts received',
        'Impacts received %',
        'Expected Impacts - In Schedule',
        'Impacts Received - In Schedule',
        'Impacts Received - Out Of Schedule',
        'Impacts Received - Unbooked',
        'Expected Plays - In schedule',
        'Plays Received - In schedule',
        'Plays Received - Out of schedule',
        'Plays Received - Unbooked',
        'Expected Time - In schedule',
        'Time Received - In schedule',
        'Time Received - Out of schedule',
        'Time Received - Unbooked',
        'Day',
        'Hour',
        'Display ID',
        'Issue ID',
        'Downtime',
        'Plays with Verification',
        'Time with Verification',
        'Impacts with Verification',
        'Energy Uses (Kwh)',
        'Carbon Emission (Kgs Co2e)',
        'Display SOV',
        'Display Slots',
        'Display Slot duration',
        'Display Town',
        'Display TV region',
        'Display Conurbation',
        'Display Type',
        'Display Resolution',
        'Display Filetypes',
        'Display Env',
        'Display Surface Area',
        'Display Lat',
        'Display Long',
        'Display Format',
        'Display Line Item ID',
        'Display Line Item Name',
        'Display Owner',
        'Display Owner ID',
        'Display Screen Name Summary',
    ];

    const generateCsvTemplate = useCallback((headers: string[]) => {
        const csvContent = `${headers.join(',')}\n`;
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', 'template.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }, []);
    const handleGenerateTemplateClick = () => {
        generateCsvTemplate(templateHeaders);
    };
    const textProps = useMemo<TypographyProps<any, any>>(() => {
        if (requestState === NetworkRequestState.Success)
            return { color: 'success.main', ...primaryTypography };
        if (requestState === NetworkRequestState.Error)
            return { color: 'error.main', ...primaryTypography };
        return { component: 'div', ...primaryTypography };
    }, [requestState]);
    const onClose = () => {
        pcaState.setFalse();
        setFiles(null);
        setEnvironment(null);
        resetNetworkState();
        downloadReset();
        setProgress(null);
    };

    return (
        <>
            <DialogV2
                onClose={onClose}
                open={pcaState.value === true}
                title="Modules.Main.Campaigns.Overview.Pca.title"
            >
                <Box>
                    <AutoGrid xs={5} gap={2} p={2}>
                        <>
                            <FormLabel>
                                {t('Modules.Main.Campaigns.Overview.Pca.environment')}
                            </FormLabel>
                            <SelectField
                                value={environment}
                                onChange={envHandler}
                                disabled={
                                    requestState === NetworkRequestState.InProgress ||
                                    downloadState === NetworkRequestState.InProgress
                                }
                                options={[
                                    {
                                        value: 'live',
                                        label: t('Modules.Main.Campaigns.Overview.Pca.client'),
                                    },
                                    {
                                        value: 'sandbox',
                                        label: t('Modules.Main.Campaigns.Overview.Pca.internal'),
                                    },
                                ]}
                            />
                        </>
                        <Box pt={3} display="flex" justifyContent="end">
                            <Button
                                type="submit"
                                disabled={
                                    downloadState === NetworkRequestState.InProgress ||
                                    requestState === NetworkRequestState.InProgress ||
                                    !environment
                                }
                                onClick={handleFormSubmit}
                            >
                                {t('Modules.Main.Campaigns.Overview.Pca.Download')}
                            </Button>
                        </Box>
                    </AutoGrid>
                    <Stack gap={1}>
                        <FileUploadDropzone
                            disabled={!environment}
                            fileAccept=".csv, .zip"
                            onFilesAdded={onFilesAdded}
                            dropzoneLabel="Modules.Main.Campaigns.Overview.BookingSummary.dropzoneText"
                        />
                        <Box justifyContent="center" display="flex">
                            <Button onClick={handleGenerateTemplateClick}>
                                {t('Modules.Main.Campaigns.Overview.Pca.template')}
                            </Button>
                        </Box>
                    </Stack>
                    {files && (
                        <ListItemText
                            primary={files[0].name}
                            primaryTypographyProps={textProps}
                            secondaryTypographyProps={textProps}
                            secondary={
                                <Stack gap={1} width="100%">
                                    <AutoGrid spacing={1}>
                                        {formatBytes(files[0]?.size)} | {files[0]?.type}
                                    </AutoGrid>
                                    <Box width="100%">
                                        <Stack direction="row" gap={1} alignItems="center">
                                            <Box flex={1}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={progressPercentage}
                                                    color={progressColor}
                                                />
                                            </Box>
                                            {requestState === NetworkRequestState.InProgress && (
                                                <Typography variant="caption">
                                                    {progressPercentage.toFixed(0)} %
                                                </Typography>
                                            )}
                                            {requestState === NetworkRequestState.Error && (
                                                <Tooltip title={String(error)}>
                                                    <Typography color="error.main">
                                                        <Error />
                                                    </Typography>
                                                </Tooltip>
                                            )}
                                            {requestState === NetworkRequestState.Success && (
                                                <Typography color="success.main">
                                                    <CheckCircle />
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Box>
                                </Stack>
                            }
                        />
                    )}
                    {requestState === NetworkRequestState.Error && (
                        <Box pb={2}>
                            <Alert severity="error" variant="outlined">
                                {String(error)}
                            </Alert>
                        </Box>
                    )}
                    {downloadState === NetworkRequestState.Error && (
                        <Box pb={2}>
                            <Alert severity="error" variant="outlined">
                                {String(downloadError)}
                            </Alert>
                        </Box>
                    )}
                    {downloadState === NetworkRequestState.Success && (
                        <Box pb={2}>
                            <Alert severity="success" variant="outlined">
                                {t('Modules.Main.Campaigns.Overview.Pca.downloadSuccess')}
                            </Alert>
                        </Box>
                    )}
                </Box>
            </DialogV2>
        </>
    );
};
