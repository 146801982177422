import React from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Checkbox, ControlledFormInput, DateRangePickerFormWrapper, TextField } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { CampaignFormNames } from 'c-main/Types';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PermissionName } from '@uniled/api-sdk';
import BuyerDropdown from './BuyerDropdown';
// eslint-disable-next-line import/no-cycle
import ReferenceInput from './ReferenceInput';

const DetailsStep = () => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const campaignLabel = useCommonTranslation('Modules.Main.Campaigns.Create.campaignNameLabel');
    const clientLabel = useCommonTranslation('Modules.Main.Campaigns.Create.clientDropdownLabel');
    const agencyLabel = useCommonTranslation(
        'Modules.Main.Campaigns.Create.agencyAssignedDropdownLabel',
    );
    const isDspLabel = useCommonTranslation('Modules.Main.Campaigns.Create.isVistar');

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={12}>
                        <ControlledFormInput
                            render={({ field }) => (
                                <TextField {...field} label={campaignLabel} required />
                            )}
                            name={CampaignFormNames.name}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={12}>
                        <BuyerDropdown />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={12}>
                        <ReferenceInput />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={12}>
                        <ControlledFormInput
                            name={CampaignFormNames.client_name}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    required
                                    label={clientLabel}
                                    placeholder={clientLabel}
                                />
                            )}
                        />
                        {/* <IfHasAllPermissions permissions={[PermissionName.UniledportalClientRead]}> */}
                        {/*    <ControlledFormInput */}
                        {/*        name={NewCampaignNames.client_id} */}
                        {/*        render={({ field }) => ( */}
                        {/*            <EntityAutocomplete */}
                        {/*                {...field} */}
                        {/*                entityName="Client" */}
                        {/*                textFieldProps={{ */}
                        {/*                    label: clientLabel, */}
                        {/*                }} */}
                        {/*                searchColumn="name" */}
                        {/*                labelColumn="name" */}
                        {/*            /> */}
                        {/*        )} */}
                        {/*    /> */}
                        {/* </IfHasAllPermissions> */}
                    </Grid>
                    <IfHasAllPermissions permissions={[PermissionName.Admin]}>
                        <Grid item xs={12} sm={6} md={6} lg={12}>
                            <ControlledFormInput
                                name={CampaignFormNames.is_vistar}
                                render={({ field }) => (
                                    <Box pl={1}>
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            label={isDspLabel}
                                        />
                                    </Box>
                                )}
                            />
                        </Grid>
                    </IfHasAllPermissions>
                    {/* <Grid item xs={12} sm={6} md={6} lg={12}> */}
                    {/* <ControlledFormInput */}
                    {/*    name={CampaignFormNames.agency_name} */}
                    {/*    render={({ field }) => ( */}
                    {/*        <TextField */}
                    {/*            {...field} */}
                    {/*            required */}
                    {/*            label={agencyLabel} */}
                    {/*            placeholder={agencyLabel} */}
                    {/*        /> */}
                    {/*    )} */}
                    {/* /> */}
                    {/* <IfHasAllPermissions permissions={[PermissionName.UniledportalAgencyRead]}> */}
                    {/*    <ControlledFormInput */}
                    {/*        name={NewCampaignNames.agency_id} */}
                    {/*        render={({ field }) => ( */}
                    {/*            <EntityAutocomplete */}
                    {/*                {...field} */}
                    {/*                entityName="Agency" */}
                    {/*                textFieldProps={{ label: agencyLabel }} */}
                    {/*                searchColumn="name" */}
                    {/*                labelColumn="name" */}
                    {/*                includes={['users']} */}
                    {/*            /> */}
                    {/*        )} */}
                    {/*    /> */}
                    {/* </IfHasAllPermissions> */}
                    {/* </Grid> */}
                </Grid>
            </Grid>
            <Grid item xs={12} lg={8} sx={{ overflow: 'auto' }}>
                <Box display="flex" justifyContent="flex-end">
                    <ControlledFormInput
                        render={({ field }) => (
                            <DateRangePickerFormWrapper
                                startDateKey={CampaignFormNames.start}
                                endDateKey={CampaignFormNames.end}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                direction={isMdDown ? 'vertical' : 'horizontal'}
                            />
                        )}
                        name="aaa"
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default DetailsStep;
