import React, { useMemo } from 'react';
import { TabWrapperSettings } from 'c-types';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { Translate, useCommonTranslation } from 'c-translation';
import { Checkbox, IconLinkButton, TabsWrapper } from 'c-components';
import RocketLogo from 'c-assets/rocket.png';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { PermissionName } from '@uniled/api-sdk';
import { useAtom, useAtomValue } from 'jotai';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { onClickStopPropagation } from 'c-lib';
import { Download } from '@mui/icons-material';
import apiClient from 'c-data/apiClient';
import CampaignOverviewPopShotsTab from './CampaignOverviewPopShotsTab';
import CampaignPopShotsDisplays from './CampaignPopShotsDisplays';
import PopShotUploadSelectLineItem from './PopShotUploadSelectLineItem';
import { atom_popShotHasProShots, atom_popShotShowPro } from './atoms';

type Props = {
    id: number;
    hasPopShots: boolean;
};

const TabListProps = {
    variant: 'standard',
    centered: false,
} as any;

const uploadPerms = [
    PermissionName.UniledportalFileCreate,
    PermissionName.UniledportalCampaign_pop_shotsCreate,
];

const downloadPerms = [
    PermissionName.UniledportalFileRead,
    PermissionName.UniledportalCampaign_pop_shotsRead,
];

const CampaignOverviewPopShotsParentTab: React.FC<Props> = ({ id, hasPopShots }) => {
    const tabs = useMemo<TabWrapperSettings['tabs']>(
        () => [
            {
                title: <Translate path="Modules.Main.Campaigns.Overview.Tabs.images" />,
                content: <CampaignOverviewPopShotsTab id={id} />,
                urlName: 'popimages',
                className: 'campaign-tab-pop-images',
            },
            {
                title: <Translate path="Modules.Main.Campaigns.Overview.Tabs.displays" />,
                content: <CampaignPopShotsDisplays id={id} />,
                urlName: 'displays',
                className: 'campaign-tab-displays-two',
            },
        ],
        [id],
    );

    const { hasAll } = useUserPermissions();
    const canUpload = useMemo(() => hasAll(uploadPerms), [hasAll]);

    const hasProPopShots = useAtomValue(atom_popShotHasProShots);
    const [showProPopShots, setShowProPopShots] = useAtom(atom_popShotShowPro);
    const t = useCommonTranslation();

    const downloadLink = useMemo(
        () => apiClient.Entities.Campaign.downloadLink(id, { include_pop_shots: true }),
        [id],
    );

    if (!canUpload && !hasPopShots) {
        return <PopImagesComingSoon />;
    }

    return (
        <>
            <Card elevation={0}>
                <CardContent>
                    <TabsWrapper
                        tabs={tabs}
                        type="tertiary"
                        urlStatePath="tab2"
                        tabListProps={TabListProps}
                        afterTabs={
                            <Stack
                                direction="row"
                                gap={2}
                                alignItems="center"
                                onClick={onClickStopPropagation}
                            >
                                <IfHasAllPermissions permissions={uploadPerms}>
                                    <PopShotUploadSelectLineItem campaignId={id} />
                                </IfHasAllPermissions>
                                <IfHasAllPermissions permissions={downloadPerms}>
                                    <IconLinkButton to={downloadLink} target="_blank">
                                        <Download />
                                    </IconLinkButton>
                                </IfHasAllPermissions>
                                {hasProPopShots && (
                                    <Checkbox
                                        onChange={setShowProPopShots}
                                        isBoolean
                                        value={showProPopShots}
                                        label={t(
                                            'Modules.Main.Campaigns.PopShots.table.showProPopShots',
                                        )}
                                    />
                                )}
                            </Stack>
                        }
                    />
                </CardContent>
            </Card>
        </>
    );
};

const PopImagesComingSoon: React.FC = () => (
    <Box textAlign="center" mt={8}>
        <Typography color="textSecondary" variant="h2">
            {useCommonTranslation('Modules.Main.Campaigns.Overview.popImagesComingSoon')}
        </Typography>
        <Box>
            <img src={RocketLogo} alt="pop images coming soon" height={250} />
        </Box>
    </Box>
);

export default CampaignOverviewPopShotsParentTab;
