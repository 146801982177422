import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { authThunks } from 'c-auth-module/Slices/Auth';

const useLogout = () => {
    const $history = useHistory();
    const dispatch = useDispatch();

    const logout = useCallback(async () => {
        await dispatch(authThunks.logoutLoggedInUser());
        $history.replace('/');
    }, [$history, dispatch]);

    return useMemo(() => ({ logout }), [logout]);
};

export default useLogout;
