import React, { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import LogFileResourceTable from './LogFileResourceTable';

type Props = {
    logFileImportId: number;
};

const LogFileImportDetails: React.FC<Props> = ({ logFileImportId }) => {
    const { start, data, reset, isLoading } = useAPIClientRequest(
        apiClient.Entities.PopDisplays_LogFileImport.get,
    );
    const loadData = useCallback(async () => {
        await to(start(logFileImportId, ['logFileResource']));
    }, [start, logFileImportId]);

    useEffect(() => {
        loadData();

        return () => {
            reset();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box display="flex" flex={1} flexDirection="column" py={2}>
            <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
                <Box display="flex" overflow="hidden" flexDirection="column" flex={1}>
                    <LogFileResourceTable
                        resources={data?.data?.data?.logFileResource ?? []}
                        loading={isLoading}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default LogFileImportDetails;
