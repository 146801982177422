import React, { useCallback, useEffect, useState } from 'react';
import { NetworkRequestState } from '@uniled/data-layer';
import { CircularProgress, InputAdornment, TextFieldProps, Tooltip } from '@mui/material';
import { Warning } from '@mui/icons-material';
import { usePrevious } from 'react-hanger';
import { TextField } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { useAPIClientRequest, useEntityPage } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { AddCreativeGroup } from 'c-main/Components/CreativeManagement/types';
import to from 'await-to-js';
import FolderStyledListItem from './GroupListItem/FolderStyledListItem';

type Props = {
    addGroup: AddCreativeGroup;
    closeNewGroup: () => void;
};

const NewGroup: React.FC<Props> = ({ addGroup, closeNewGroup }) => {
    const { id } = useEntityPage();

    const t = useCommonTranslation();
    const [groupName, setGroupName] = useState('');

    const {
        start: StartAddGroup,
        requestState: addGroupState,
        error: addGroupError,
    } = useAPIClientRequest(apiClient.Entities.CreativeGroup.create);

    const onAddGroupSubmit = useCallback(
        async (name: string) => {
            if (name?.trim()?.length === 0) return;

            const [, success] = await to(StartAddGroup({ name, campaign_id: id }));

            if (success && success?.data?.data?.id != null) {
                addGroup(success?.data?.data);
            }
        },
        [StartAddGroup, addGroup, id],
    );

    const onNameChange = useCallback(
        e => {
            if (addGroupState !== NetworkRequestState.InProgress) {
                setGroupName(e.target.value);
            }
        },
        [addGroupState],
    );

    const onNewGroupKeyUp = useCallback<TextFieldProps['onKeyDown']>(
        e => {
            if (e.key === 'Enter') {
                onAddGroupSubmit((e.target as HTMLInputElement).value);
            } else if (e.key === 'Escape') {
                (e.target as HTMLInputElement).blur();
            }
        },
        [onAddGroupSubmit],
    );

    const prevCreate = usePrevious(addGroupState);

    useEffect(() => {
        if (
            addGroupState === NetworkRequestState.Success &&
            prevCreate === NetworkRequestState.InProgress
        ) {
            closeNewGroup();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addGroupState, closeNewGroup]);

    return (
        <>
            <FolderStyledListItem>
                <TextField
                    variant="standard"
                    size="small"
                    placeholder={t('Modules.Main.CreativeManagement.groups.newGroupPlaceholder')}
                    value={groupName}
                    onChange={onNameChange}
                    onBlur={closeNewGroup}
                    autoFocus
                    fullWidth
                    onKeyUp={onNewGroupKeyUp}
                    error={addGroupState === NetworkRequestState.Error}
                    InputProps={{
                        endAdornment: (
                            <>
                                {addGroupState === NetworkRequestState.InProgress && (
                                    <InputAdornment position="end">
                                        <CircularProgress size={15} />
                                    </InputAdornment>
                                )}
                                {addGroupState === NetworkRequestState.Error && (
                                    <Tooltip title={addGroupError ?? ''}>
                                        <InputAdornment position="end">
                                            <Warning color="error" />
                                        </InputAdornment>
                                    </Tooltip>
                                )}
                            </>
                        ),
                    }}
                />
            </FolderStyledListItem>
        </>
    );
};

export default NewGroup;
