import { DeepPartial, Middleware, Reducer, StoreEnhancer } from '@reduxjs/toolkit';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { createStoreWithMiddleware, excludeReducerNetworkStateData } from '@uniled/data-layer';
import { sessionCheckReducer as sessionCheck } from 'c-auth-module/Slices/SessionCheck/session-check-slice';
import { authReducer as auth } from 'c-auth-module/Slices/Auth/auth-slice';
import { passwordResetReducer as passwordReset } from 'c-auth-module/Slices/PasswordReset/password-reset-slice';
import { uiReducer as ui } from 'c-wrapper/Slices/UI';
import { CommonState } from 'c-types';
import { PersistConfig } from 'redux-persist';
import { excludeProperties } from 'c-lib';
// import { reducerPersistTransformer as noteReducerPersistTransformer } from 'c-notes-module/Lib';

const commonEnhancers: StoreEnhancer[] = [];

const commonTransforms = [
    excludeReducerNetworkStateData({
        loadingById: true,
        updatingById: true,
        deletingById: true,
        create: true,
        newestCreatedId: true,
        pagination: true,
    }),
    // noteReducerPersistTransformer,
];
const commonReducers = {
    auth,
    sessionCheck,
    passwordReset,
    ui,
};

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        environment: import.meta.env.VITE_SENTRY_ENV,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        // tracesSampleRate: 1.0,
    });
    commonEnhancers.push(Sentry.createReduxEnhancer());
}

type CreateStoreOptions<RootState extends CommonState> = {
    version: string;
    reducers?: { [reducerName in keyof Partial<Omit<RootState, keyof CommonState>>]: Reducer };
    excludeReducers?: (keyof RootState)[];
    transforms?: PersistConfig<RootState>['transforms'];
    blacklist?: (keyof RootState)[];
    enhancers?: StoreEnhancer[];

    preloadedState?: DeepPartial<RootState>;
    middleware?: Middleware[];
};

const createStore = <RootState extends CommonState>({
    version,
    reducers,
    excludeReducers,
    transforms,
    blacklist = [],
    enhancers,
    ...options
}: CreateStoreOptions<RootState>) => {
    if (localStorage) {
        // 'persist:root-' is the prefix given to the local storage keys by `redux-persist`
        const reduxPersistPrefix = 'persist:root-';
        Object.keys(localStorage)
            .filter(key => key.startsWith(reduxPersistPrefix))
            .map(key => key.replace(reduxPersistPrefix, ''))
            .filter(key => key !== version)
            .forEach(key => localStorage.removeItem(`${reduxPersistPrefix}${key}`));
    }

    return createStoreWithMiddleware<RootState>({
        version,
        transforms: [...commonTransforms, ...(transforms ?? [])],
        reducers: excludeProperties({ ...reducers, ...commonReducers } as any, excludeReducers),
        blacklist: ['passwordReset', 'sessionCheck', ...(blacklist as string[])],
        enhancers: [...commonEnhancers, ...(enhancers ?? [])],
        ...options,
    });
};
export default createStore;
