import React, { forwardRef, Ref, useMemo } from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { OptionSchema } from '../formTypes';

type Props = {
    options: OptionSchema[];
    helperText?: string;
    greyBackdrop?: boolean;
    whiteBackdrop?: boolean;
    clearAll?: boolean;
} & MuiSelectProps;
const SelectField = (
    {
        options,
        value,
        multiple,
        fullWidth = true,
        label,
        error,
        helperText,
        id,
        greyBackdrop = false,
        whiteBackdrop = true,
        clearAll = false,
        ...rest
    }: Props,
    ref: Ref<any>,
) => {
    const selectOptions = useMemo(
        () =>
            options.map(opt => (
                <MenuItem key={`${opt.value}`} value={opt.value}>
                    {opt.label}
                </MenuItem>
            )),
        [options],
    );

    const selectValue = useMemo(() => {
        let $selectValue = value;

        if (multiple && !Array.isArray($selectValue)) {
            $selectValue = [];
        }

        return $selectValue ?? '';
    }, [value, multiple]);

    const labelId = useMemo(() => `${id}-label`, [id]);

    const TheSelectField = useMemo(() => {
        const handleClear = () => {
            (
                rest as Omit<MuiSelectProps, 'onChange'> & {
                    onChange: (
                        event: { target: { value: unknown } },
                        child: React.ReactNode,
                    ) => void;
                }
            ).onChange({ target: { value: multiple ? [] : '' } }, null);
        };
        return (
            <FormControl error={error != null ? error : undefined} fullWidth={fullWidth}>
                {label != null && <InputLabel id={labelId}>{label}</InputLabel>}
                <MuiSelect
                    labelId={labelId}
                    value={selectValue}
                    fullWidth={fullWidth}
                    label={label}
                    {...rest}
                    inputRef={ref}
                    id={id}
                    multiple={multiple}
                    IconComponent={clearAll ? () => null : undefined}
                    endAdornment={
                        clearAll ? (
                            <IconButton size="small" onClick={handleClear}>
                                <CloseIcon />
                            </IconButton>
                        ) : null
                    }
                >
                    {selectOptions}
                </MuiSelect>
                {error && <FormHelperText error>{helperText}</FormHelperText>}
            </FormControl>
        );
    }, [
        clearAll,
        error,
        fullWidth,
        helperText,
        id,
        label,
        labelId,
        multiple,
        ref,
        rest,
        selectOptions,
        selectValue,
    ]);

    if (whiteBackdrop && !greyBackdrop) {
        return (
            <Box bgcolor="white" width="100%">
                {TheSelectField}
            </Box>
        );
    }

    if (greyBackdrop) {
        return (
            <Box bgcolor="grey.200" width="100%">
                {TheSelectField}
            </Box>
        );
    }

    return TheSelectField;
};

// https://material-ui.com/guides/composition/#wrapping-components
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
SelectField.muiName = MuiSelect.muiName;

export default forwardRef(SelectField);
