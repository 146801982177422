import React, { useEffect, useMemo } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { PermissionName } from '@uniled/api-sdk';
import { analogueListIcon } from 'c-main/icons';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import { FilterableEntityTable, FilterableEntityTableRendererType } from 'c-pagination';
import { displayAnalogueColumns } from 'c-displays/Components/DisplaysEntityColumns';
import ReactGA from 'react-ga';
import {
    AllEntitiesDropdown,
    AutoGrid,
    ControlledFormInput,
    CreateEntityButton,
    SelectField,
    TextAreaField,
    TextField,
} from 'c-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCommonTranslation } from 'c-translation';
import * as yup from 'yup';
import { FormLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const textSearchCols = ['name'];
const paginationTag = 'displays-admin-analogue-list';
const AnaloguesListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const t = useCommonTranslation();
    const schema = yup.object().shape({
        owner_id: yup.number().required('Owner is required'),
        market_id: yup.number().required('Market is required'),
        name: yup.string().required('Name is required'),
    });
    return (
        <FullPageEntityTableWrapper
            title="Modules.Displays.Analogue.metaTitle"
            controls={useMemo(
                () => [
                    <CreateEntityButton
                        entityName="Displays_AnalogueScreen"
                        title="Modules.Displays.Analogue.createBtn"
                        form={<NewCountryForm />}
                        buttonLabel={t('Modules.Displays.Analogue.createBtn')}
                        submitLabel="Modules.Displays.Analogue.submitBtn"
                        key="new-analogueScreen-button"
                        permissions={[PermissionName.DisplaysAnalogue_screensCreate]}
                        redirectPath={PostAuthRoutes.Displays.DisplaysAnalogueScreenEdit}
                        formOptions={{
                            resolver: yupResolver(schema),
                        }}
                    />,
                ],
                [schema, t],
            )}
        >
            <FilterableEntityTable
                key={paginationTag}
                tag={paginationTag}
                columns={displayAnalogueColumns}
                baseEntityName="Displays_AnalogueScreen"
                textSearchColumns={textSearchCols}
                rendererType={FilterableEntityTableRendererType.Contained}
                direction="desc"
                routeTemplate={PostAuthRoutes.Displays.DisplaysAnalogueScreenEdit}
                showFilters
                includes={['owner', 'market']}
            />
        </FullPageEntityTableWrapper>
    );
};
const NewCountryForm = () => {
    const methods = useFormContext();
    const { watch } = methods;

    const t = useCommonTranslation();
    const market = watch('market_id');

    const ownerField = useMemo(
        () => (
            <ControlledFormInput
                name="owner_id"
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.DisplaysDisplayEdit.fields.mediaOwners')} *
                        </FormLabel>
                        <AllEntitiesDropdown
                            entityName="Displays_Owner"
                            labelField="name"
                            {...field}
                            useLocalEntityData
                            filter={{ 'filter.markets.id': [market] }}
                            includes={['market']}
                            loadingDisable
                            disabled={market == null}
                        />
                    </>
                )}
            />
        ),
        [market, t],
    );
    return (
        <AutoGrid xs={6} spacing={2} pb={2}>
            <ControlledFormInput
                name="name"
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.AnalgueEdit.fields.name')} *
                        </FormLabel>
                        <TextField {...field} />
                    </>
                )}
            />
            <ControlledFormInput
                name="market_id"
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.AnalgueEdit.fields.market')} *
                        </FormLabel>
                        <AllEntitiesDropdown
                            entityName="Market"
                            useLocalEntityData
                            labelField="name"
                            {...field}
                        />
                    </>
                )}
            />
            {ownerField}
            <ControlledFormInput
                name="env"
                render={({ field }) => (
                    <>
                        <FormLabel>{t('Modules.Displays.AnalgueEdit.fields.env')}</FormLabel>
                        <TextField {...field} />
                    </>
                )}
            />
            <ControlledFormInput
                name="size"
                render={({ field }) => (
                    <>
                        <FormLabel>{t('Modules.Displays.AnalgueEdit.fields.size')}</FormLabel>
                        <TextField {...field} />
                    </>
                )}
            />
            <ControlledFormInput
                name="status"
                render={({ field }) => (
                    <>
                        <FormLabel>{t('Modules.Displays.AnalgueEdit.fields.status')}</FormLabel>
                        <SelectField
                            value={field.value}
                            options={[
                                {
                                    value: 0,
                                    label: t('Modules.Displays.AnalgueEdit.fields.inactive'),
                                },
                                {
                                    value: 1,
                                    label: t('Modules.Displays.AnalgueEdit.fields.active'),
                                },
                            ]}
                            {...field}
                        />
                    </>
                )}
            />
            <ControlledFormInput
                name="desciption"
                render={({ field }) => (
                    <>
                        <FormLabel>
                            {t('Modules.Displays.AnalgueEdit.fields.description')}
                        </FormLabel>
                        <TextAreaField {...field} />
                    </>
                )}
            />
        </AutoGrid>
    );
};
const setup: PageConfig = {
    id: 'AnaloguesListPage',
    component: AnaloguesListPage,
    systemSearch: {
        title: 'Modules.Displays.Analogue.metaTitle',
        description: 'Modules.Displays.Analogue.metaTitle',
        icon: analogueListIcon,
        type: 'page',
        aliases: 'SystemSearch.aliases.displaysAnalogueDisplayList',
        route: PostAuthRoutes.Displays.AnaloguesList,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.Displays.AdminHome.metaTitle',
                link: PostAuthRoutes.Displays.DisplaysAdminHome,
            },
            {
                label: 'Modules.Displays.Analogue.metaTitle',
            },
        ],
        permissions: [PermissionName.Admin],
    },
};

export default setup;
