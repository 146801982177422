import { AllEntities, Permission } from '@uniled/api-sdk';

export type PermissionsDiff = { added: number[]; removed: number[] };

export type PermissionByCategory = {
    general: Permission[];
    namespaces: PermissionsByNamespace;
    rawPermissionsByNamespace: Record<PermissionNamespace, Permission[]>;
};

/**
 * <PermissionNamespace, PermissionsByEntity>
 */
export type PermissionsByNamespace = Record<PermissionNamespace, PermissionsByEntity>;

/**
 * <Entity Name,  EntityPermissions>
 */
export type PermissionsByEntity = Partial<Record<keyof AllEntities, EntityPermissions>>;

export type EntityPermissions = {
    create?: Permission;
    read?: Permission;
    update?: Permission;
    delete?: Permission;
};

export enum PermissionNamespace {
    Displays = 'displays',
    Pop = 'popdisplays',
    Portal = 'uniledportal',
    Reporting = 'reporting',
    Scoping = 'scoping',
}
