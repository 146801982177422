import {
    scheduleActions,
    scheduleAdapter,
    scheduleThunks as defaultThunks,
    scheduleSelectors as defaultSelectors,
    scheduleReducer,
} from './Schedule-slice';
import * as thunks from './Schedule-thunks';
import * as selectors from './Schedule-selectors';

const scheduleThunks = { ...defaultThunks, ...thunks };
const scheduleSelectors = { ...defaultSelectors, ...selectors };

export { default as ScheduleSlice } from './Schedule-slice';
export { scheduleSelectors, scheduleActions, scheduleAdapter, scheduleThunks, scheduleReducer };
