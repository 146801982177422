import React, { useMemo } from 'react';
import { AllEntities, BaseEntity } from '@uniled/api-sdk';
import { useEntityField } from 'c-hooks';
import { EntityListColumnRelationship } from 'c-pagination/types';

type Props<Entity extends BaseEntity> = {
    id: number;
    entityName: keyof AllEntities;
    field: keyof Entity;
    Render?: EntityListColumnRelationship<Entity>['Render'];
};

function EntityField<Entity extends BaseEntity>({ id, field, entityName, Render }: Props<Entity>) {
    const { text, entity } = useEntityField(id, entityName, field);
    return useMemo(() => {
        if (Render) {
            return <Render entityName={entityName} entity={entity} field={field} />;
        }

        return <>{text}</>;
    }, [Render, entity, text, field, entityName]);
}

export default EntityField;
