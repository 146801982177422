import React, { useMemo, useRef } from 'react';
import { omit } from 'ramda';
import { Box, Menu, MenuItem } from '@mui/material';
import { useBoolean } from 'react-hanger';
import { EsPCAReportField, PCAReportField } from '@uniled/api-sdk';
import { KeyboardArrowDown, ListAlt } from '@mui/icons-material';
import { Button } from 'c-components';
import { Translate, useCommonTranslation } from 'c-translation';
import { FieldSwitcherProps } from './types';
import { ReportSource, ReportView } from '../types';

const includedFieldsEs = [
    EsPCAReportField.DisplayTown,
    EsPCAReportField.DisplayTvRegion,
    EsPCAReportField.DisplayConurbation,
    EsPCAReportField.DisplayEnv,
    EsPCAReportField.DisplayFormat,
    EsPCAReportField.DisplayOwner,
    EsPCAReportField.FrameId,
    EsPCAReportField.DisplayLineItemName,
    EsPCAReportField.CreativeName,
    EsPCAReportField.Dow,
    EsPCAReportField.Hour,
    EsPCAReportField.DisplayScreenNameSummary,
    EsPCAReportField.StartDate,
];

const FieldSwitcher: React.FC<FieldSwitcherProps> = ({
    field,
    onFieldSwitch,
    view,
    onViewChange,
    source,

    removeOverallView = false,
}) => {
    const btnRef = useRef();
    const openState = useBoolean(false);
    const fieldsToUse = useMemo(() => includedFieldsEs, []);

    const t = useCommonTranslation();

    return (
        <Box>
            <Button
                ref={btnRef}
                onClick={openState.toggle}
                color={view === ReportView.Field ? 'primary' : 'secondary'}
                className="pca-group-by"
            >
                <ListAlt sx={{ mr: 0.5 }} />
                {useCommonTranslation('Modules.Main.Campaigns.Overview.Reports.byFieldButton')}
                <KeyboardArrowDown />
            </Button>

            <Menu anchorEl={btnRef.current} open={openState.value} onClose={openState.setFalse}>
                {!removeOverallView && (
                    <MenuItem
                        selected={view === ReportView.Overall}
                        onClick={() => onViewChange(ReportView.Overall)}
                    >
                        <Translate path="Modules.Main.Campaigns.Overview.Reports.overallTabTitle" />
                    </MenuItem>
                )}

                {Object.entries(fieldsToUse).map(([key, value]) => (
                    <MenuItem
                        key={`${key}-${value}`}
                        onClick={() => {
                            onFieldSwitch(value);
                        }}
                        selected={field === value && view === ReportView.Field}
                        className={`group-by-field-${field}`}
                    >
                        {t(`Modules.Main.Campaigns.Overview.Reports.fields.${value}`)}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default FieldSwitcher;
