import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Collapse, Grid, Typography } from '@mui/material';
import { Person } from '@mui/icons-material';
import Avatar from 'c-components/Avatar';
import Button from 'c-components/Forms/Button';
import FlashMessage from 'c-components/FlashMessage';
import MuiRouterLink from 'c-components/MuiRouterLink';
import { PostAuthRoutes } from 'c-routes';
import { userName } from 'c-admin/Lib';
import { uiSelectors } from 'c-wrapper/Slices/UI';
import { Translate, useCommonTranslation } from 'c-translation';
import { useLogout } from 'c-auth-module/Hooks';
import { useAuthenticatedUser, useUserImpersonation } from 'c-hooks';
import { makeStyles } from '@mui/styles';
import { NetworkRequestState } from '@uniled/data-layer';
import { useBoolean, usePrevious } from 'react-hanger';

const useStyles = makeStyles(() => ({
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64,
        fontSize: 52,
    },
}));

const LoggedInUser = () => {
    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const open = useSelector(uiSelectors.isDrawerOpen);
    const { logout } = useLogout();
    const { stop, isImpersonating, stopImpersonatingLoadingState } = useUserImpersonation();

    const userFullName = useMemo(() => userName(user), [user]);

    const prevState = usePrevious(stopImpersonatingLoadingState.state);
    const hasSuccessfullyStoppedImpersonating = useBoolean(false);

    useEffect(() => {
        if (
            prevState === NetworkRequestState.InProgress &&
            stopImpersonatingLoadingState.state === NetworkRequestState.Success
        ) {
            hasSuccessfullyStoppedImpersonating.setTrue();
        }
        if (
            prevState === NetworkRequestState.Success &&
            stopImpersonatingLoadingState.state === NetworkRequestState.Idle
        ) {
            hasSuccessfullyStoppedImpersonating.setFalse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stopImpersonatingLoadingState.state]);

    const t = useCommonTranslation();
    const showUserTypeTitle = useMemo(
        () => user?.availableUserTypes?.length > 0 || isImpersonating,
        [user, isImpersonating],
    );
    const userTypeTitle = useMemo(
        () => t(`Pages.Login.userTypes.${user?.chosenUserType}`),
        [t, user],
    );

    // const jobTitle = useMemo(() => 'Some Job Title', []);
    return (
        <Box>
            <Collapse in={open}>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    p={2}
                    overflow="hidden"
                >
                    <Avatar
                        className={classes.avatar}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        component={MuiRouterLink as any}
                        // @ts-ignore
                        to={PostAuthRoutes.Users.MyProfile}
                        id="navigation-drawer-my-profile"
                    >
                        <Person fontSize="inherit" />
                    </Avatar>
                    <Box py={1}>
                        <Box width="100%">
                            <Typography color="textPrimary" align="center" variant="h5" noWrap>
                                {userFullName}
                            </Typography>
                        </Box>
                        {showUserTypeTitle && (
                            <Typography color="textSecondary" align="center" variant="body2" noWrap>
                                {userTypeTitle}
                            </Typography>
                        )}
                        {/* <Typography color="textSecondary" align="center" variant="body2" noWrap> */}
                        {/*    {jobTitle} */}
                        {/* </Typography> */}
                    </Box>
                    {isImpersonating && (
                        <Button
                            variant="outlined"
                            onClick={stop}
                            id="navigation-drawer-sign-out"
                            sx={{ mb: 2 }}
                            disabled={
                                stopImpersonatingLoadingState.state ===
                                NetworkRequestState.InProgress
                            }
                        >
                            <Translate path="Navigation.adminStopImpersonateUser" />
                        </Button>
                    )}
                    {hasSuccessfullyStoppedImpersonating.value && (
                        <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                            <FlashMessage status="success">
                                <Translate path="Modules.Admin.ImpersonateUser.stopImpersonateSuccess" />
                            </FlashMessage>
                        </Grid>
                    )}
                    {!isImpersonating && (
                        <Button variant="outlined" onClick={logout} id="navigation-drawer-sign-out">
                            {t('Navigation.logoutLabel')}
                        </Button>
                    )}
                </Box>
            </Collapse>
        </Box>
    );
};

export default LoggedInUser;
