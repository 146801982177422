import React, { forwardRef, Ref, useCallback, useMemo } from 'react';
import { format, isValid, parse, parseISO } from 'date-fns';
import { TextFieldProps } from '@mui/material';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers-pro';
import { useDateUtils } from 'c-hooks';
import { TextField } from '../TextField';

type Props = {
    value: string;
    /**
     * This is the format that will be outputted when the form is submitted. This defaults to the displayFormat
     */
    outputFormat?: string;
    /** Set this to true if the input value is in ISO8601 format */
    inputFormatISO8601?: boolean;
    error?: boolean;
    label?: string;
    onBlur?: () => void;

    inputProps?: Partial<TextFieldProps>;

    onChange: (value: string) => void;
} & Omit<
    DateTimePickerProps<Date>,
    'error' | 'onChange' | 'value' | 'type' | 'label' | 'renderInput' | 'ToolbarComponent'
>;

const DateTimeField = (
    {
        label,
        onBlur,
        onChange,
        inputProps,
        value,
        outputFormat,
        inputFormatISO8601,
        error,
        ...props
    }: Props,
    ref: Ref<any>,
) => {
    const { dateTimePickerInputFormat, dayMonthYearHourMinuteApiFormat } = useDateUtils();

    const outputDateFormat = useMemo(
        () => outputFormat ?? dayMonthYearHourMinuteApiFormat,
        [outputFormat, dayMonthYearHourMinuteApiFormat],
    );

    // the format shown to users in the input field
    const inputDateFormat = useMemo(() => dateTimePickerInputFormat, [dateTimePickerInputFormat]);

    const datePickerValue = useMemo(() => {
        let actualValue: string | Date = value;

        if (typeof actualValue === 'string') {
            actualValue = inputFormatISO8601
                ? parseISO(actualValue)
                : parse(actualValue, outputDateFormat, new Date());
        }

        if (actualValue == null) {
            // setting to null because undefined results in today's date being selected
            actualValue = null;
        }

        return actualValue;
    }, [value, inputFormatISO8601, outputDateFormat]);

    const handleOnBlur = useCallback(() => {
        if (onBlur) {
            onBlur();
        }
    }, [onBlur]);

    const handleOnChange = useCallback(
        (date: Date) => {
            if (isValid(date)) onChange(format(date, outputDateFormat));
            else onChange(null);
        },
        [onChange, outputDateFormat],
    );

    return (
        <DateTimePicker
            label={label}
            format={inputDateFormat}
            onChange={handleOnChange}
            value={datePickerValue as any as Date}
            slots={useMemo(() => ({ textField: TextField as any }), [])}
            slotProps={useMemo(
                () => ({
                    textField: {
                        ref,
                        fullWidth: true,
                        error,
                        onBlur: handleOnBlur,
                        ...inputProps,
                    },
                    actionBar: {
                        actions: ['clear', 'accept'],
                    },
                }),
                [error, handleOnBlur, inputProps, ref],
            )}
            {...props}
        />
    );
};

export default forwardRef(DateTimeField);
