import React, { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import { FilterConfigEntityAutocomplete, FilterConfigRendererProps } from 'c-pagination';
import { useCommonTranslation } from 'c-translation';
import { AutocompleteProps, FilterdropdownEntityAutocomplete } from 'c-components';
import equal from 'fast-deep-equal';

type Props = FilterConfigRendererProps<FilterConfigEntityAutocomplete>;

const EntityAutocompleteFilter: React.FC<Props> = ({
    config,
    onChange,
    value,
    count,
    id,
    className,
}) => {
    const t = useCommonTranslation();

    const [localVal, setLocalVal] = useState(value ?? []);

    const onValueChange: AutocompleteProps['onChange'] = useCallback(
        (vals, reason) => {
            if (vals == null || reason === 'clear') {
                setLocalVal([]);
                onChange([]);
            } else {
                setLocalVal(Array.isArray(vals) ? vals : [vals]);
            }
        },
        [onChange],
    );

    useEffect(() => {
        setLocalVal(curr => (equal(curr, value) ? curr : value));
    }, [value]);

    const onClose = useCallback(() => {
        if (!equal(localVal, value)) {
            onChange(localVal);
        }
    }, [localVal, onChange, value]);

    return (
        <>
            <FilterdropdownEntityAutocomplete
                multiple
                onChange={onValueChange}
                value={localVal}
                entityName={config.entityName}
                placeholder={t(config.label)}
                onClose={onClose}
                searchColumns="name"
                labelColumn="name"
                orderByField="name"
                id={id}
                className={className}
            />
        </>
    );
};

export default EntityAutocompleteFilter;
