import React, { forwardRef, Ref, useCallback, useMemo } from 'react';
import { AutocompleteProps } from 'c-components/Forms/Autocomplete/Autocomplete';
import { useTheme } from '@mui/styles';
import { TextFieldProps } from '@mui/material';
import { EntityAutocompleteProps } from 'c-components/EntityAutocomplete/EntityAutocomplete';
import EntityAutocomplete from 'c-components/EntityAutocomplete';
import { formatPlaceholder, generateTextFieldProps } from './funcs';

type Props = {
    error?: boolean;
    textfieldVariant?: TextFieldProps['variant'];
    placeholder?: string;
} & Omit<EntityAutocompleteProps, 'textFieldProps'>;

const FilterdropdownEntityAutocomplete = (
    { placeholder, size = 'small', textfieldVariant = 'outlined', error, ...rest }: Props,
    ref: Ref<any>,
) => {
    const theme = useTheme();

    const textFieldProps = useMemo(
        () =>
            generateTextFieldProps({
                variant: textfieldVariant,
                size,
                placeholder,
                theme,
                error,
                value: rest.value,
                multiple: rest.multiple,
                options: [],
            }),
        [textfieldVariant, size, placeholder, theme, error, rest.value, rest.multiple],
    );
    const renderTags: AutocompleteProps['renderTags'] = useCallback(() => null, []);
    const $formatPlaceholder: AutocompleteProps['formatPlaceholder'] = useCallback(
        values => formatPlaceholder(values, rest.multiple, placeholder),
        [placeholder, rest.multiple],
    );

    return (
        <EntityAutocomplete
            renderTags={renderTags}
            renderOptionCheckboxes
            textFieldProps={textFieldProps}
            stayOpenOnSelect
            formatPlaceholder={$formatPlaceholder}
            ref={ref}
            {...rest}
        />
    );
};

export default forwardRef(FilterdropdownEntityAutocomplete);
