import React, { PropsWithChildren } from 'react';
import { Box, Hidden } from '@mui/material';

const CenterSmUp: React.FC<PropsWithChildren> = ({ children }) => (
    <>
        <Hidden smUp>{children}</Hidden>
        <Hidden smDown>
            <Box textAlign="center">{children}</Box>
        </Hidden>
    </>
);
export default CenterSmUp;
