import {
    displaysOwnerActions,
    displaysOwnerAdapter,
    displaysOwnerThunks as defaultThunks,
    displaysOwnerSelectors as defaultSelectors,
    displaysOwnerReducer,
} from './DisplaysOwner-slice';
import * as thunks from './DisplaysOwner-thunks';
import * as selectors from './DisplaysOwner-selectors';

const displaysOwnerThunks = { ...defaultThunks, ...thunks };
const displaysOwnerSelectors = { ...defaultSelectors, ...selectors };

export { default as DisplaysOwnerSlice } from './DisplaysOwner-slice';
export {
    displaysOwnerSelectors,
    displaysOwnerActions,
    displaysOwnerAdapter,
    displaysOwnerThunks,
    displaysOwnerReducer,
};
