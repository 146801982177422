import { AvatarProps } from '@mui/material';
import { User as APIUser } from '@uniled/api-sdk';
import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import Avatar from 'c-components/Avatar';
import { initials, userName } from 'c-admin/Lib';
import { User } from 'c-entity-types';

const useStyles = (size: number) =>
    makeStyles(theme => ({
        avatar: {
            width: theme.spacing(size),
            height: theme.spacing(size),
            fontSize: `${size / 4}em`,
        },
    }));

const UserAvatar = ({
    user,
    size = 5,
    sx = { bgcolor: 'primary.main' },
    ...rest
}: { user: User | APIUser; size?: number } & AvatarProps) => {
    const userNameString = useMemo(() => userName(user), [user]);
    const { avatar } = useStyles(size)();
    const userInitials = useMemo(() => initials(userNameString, 2), [userNameString]);
    return (
        <Avatar className={avatar} sx={sx} {...rest}>
            {userInitials}
        </Avatar>
    );
};

export default React.memo(UserAvatar);
