import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ErrorImg from 'c-assets/error-triangle.png';
import { TranslationPath, useCommonTranslation } from 'c-translation';
import { Button } from 'c-components/Forms';

type Props = {
    title: TranslationPath | React.ReactNode;
    description?: TranslationPath | React.ReactNode;
    retryLabel: TranslationPath | React.ReactNode;
    retry: () => void;
};

const retrySx = { alignSelf: 'center' };

const ErrorImageTryAgain: React.FC<Props> = ({ title, description, retryLabel, retry }) => {
    const t = useCommonTranslation();
    const titleContent = typeof title === 'string' ? t(title) : title;
    const descriptionContent = typeof description === 'string' ? t(description) : description;
    const retryLabelContent = typeof retryLabel === 'string' ? t(retryLabel) : retryLabel;

    return (
        <Stack spacing={6}>
            <Box textAlign="center">
                <img src={ErrorImg} alt="error triangle" height={100} />
            </Box>
            <Box>
                <Typography variant="h3" textAlign="center">
                    {titleContent}
                </Typography>
                {descriptionContent && (
                    <Typography variant="body1" textAlign="center">
                        {descriptionContent}
                    </Typography>
                )}
            </Box>
            <Button onClick={retry} variant="text" sx={retrySx}>
                {retryLabelContent}
            </Button>
        </Stack>
    );
};

export default ErrorImageTryAgain;
