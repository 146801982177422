import React, { useCallback, useEffect, useMemo } from 'react';
import { TabsWrapper, TransparentCard, UpdateEntityButton } from 'c-components';
import { Card, CardContent } from '@mui/material';
import { PostAuthRoutes } from 'c-routes';
import { PageEntityConfig } from 'c-config';
import { displaysIcon } from 'c-main/icons';
import { PermissionName, PopDisplays_Display } from '@uniled/api-sdk';
import { useDateUtils, useEntityPage } from 'c-hooks';
import { useEntityData } from 'c-data';
import { displayNameGen } from 'c-pop-displays/Lib';
import { TabWrapperSettings } from 'c-types';
import { DisplayDates, DisplayDetails, DisplayForm } from 'c-pop-displays/Components/Display';
import { useCommonTranslation } from 'c-translation';
import { isNil, pick, reject } from 'ramda';
import ReactGA from 'react-ga';

const DisplayOverviewPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { id } = useEntityPage();
    const { getById } = useEntityData<PopDisplays_Display>('PopDisplays_Display');
    const display = getById({ id });

    const { parseApiDayMonthYearHourMinuteSecondFormat } = useDateUtils();
    const minDate = useMemo(
        () =>
            parseApiDayMonthYearHourMinuteSecondFormat(
                display?.logFileParsed?.[0]?.earliest_log_file_parsed_date,
            ),
        [display?.logFileParsed, parseApiDayMonthYearHourMinuteSecondFormat],
    );
    const maxDate = useMemo(
        () =>
            parseApiDayMonthYearHourMinuteSecondFormat(
                display?.logFileParsed?.[0]?.latest_log_file_parsed_date,
            ),
        [display?.logFileParsed, parseApiDayMonthYearHourMinuteSecondFormat],
    );

    const tabs = useMemo<TabWrapperSettings['tabs']>(
        () => [
            {
                title: 'Modules.PopDisplays.Display.Overview.tabs.details',
                content: <DisplayDetails id={id} />,
                urlName: 'details',
            },
            {
                title: 'Modules.PopDisplays.Display.Overview.tabs.dates',
                content: (
                    <Card>
                        <CardContent>
                            <DisplayDates id={id} minDate={minDate} maxDate={maxDate} />
                        </CardContent>
                    </Card>
                ),
                urlName: 'dates',
            },
        ],
        [id, minDate, maxDate],
    );

    const displayName = useMemo(() => displayNameGen(display, id, null), [display, id]);
    const defaultData = useMemo(
        () =>
            reject(
                isNil,
                pick(['source', 'channel', 'frame', 'displays_portal_id', 'display_key'], display),
            ),
        [display],
    );

    const transformData = useCallback((data: Partial<PopDisplays_Display>) => {
        let theDisplayPortalId = data.displays_portal_id;
        if (String(theDisplayPortalId).trim() === '') theDisplayPortalId = null;

        return { ...data, displays_portal_id: theDisplayPortalId } as PopDisplays_Display;
    }, []);
    const t = useCommonTranslation();
    return (
        <TransparentCard
            title={displayNameGen(display, id, null)}
            controlsNextToTitle
            controls={useMemo(
                () => [
                    <UpdateEntityButton<PopDisplays_Display>
                        key="update-display-button"
                        entityName="PopDisplays_Display"
                        title={t('Modules.PopDisplays.Display.editDisplay.title', {
                            name: displayName,
                        })}
                        defaultData={defaultData}
                        form={<DisplayForm display={display} />}
                        entityId={id}
                        permissions={[PermissionName.Pop_displaysDisplaysUpdate]}
                        transformData={transformData}
                    />,
                ],
                [t, displayName, id, defaultData, display],
            )}
        >
            <CardContent>
                <TabsWrapper tabs={tabs} urlStatePath="t" />
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageEntityConfig<PopDisplays_Display> = {
    id: 'DisplayOverviewPage',
    component: DisplayOverviewPage,
    systemSearch: {
        title: undefined,
        icon: displaysIcon,
        type: 'entity',
        saveAsRecentPage: true,
        entityName: 'PopDisplays_Display',
        defaultIncludes: ['channelFrames', 'logFileParsed'],
        prefix: 'SystemSearch.prefixes.popdisplay',
        prefixDescription: 'SystemSearch.prefixDescriptions.popdisplay',
        nameGen: displayNameGen,
        searchColumn: ['channel'],
        route: PostAuthRoutes.PopDisplays.DisplayOverview,
        permissions: [
            PermissionName.Pop_displaysDisplaysRead,
            PermissionName.Pop_displaysDisplaysUpdate,
            PermissionName.Pop_displaysDisplaysCreate,
        ],
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.PopDisplays.AdminHome.metaTitle',
                link: PostAuthRoutes.PopDisplays.PopDisplaysHome,
            },
            {
                label: 'Modules.PopDisplays.Display.metaTitle',
                link: PostAuthRoutes.PopDisplays.DisplayList,
            },
        ],
    },
};

export default setup;
