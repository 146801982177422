import React, { useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Campaign } from '@uniled/api-sdk';
import { AutoGrid, NumberFormat } from 'c-components';
import { Box, Tooltip, Typography, TypographyProps } from '@mui/material';
import { Translate } from 'c-translation';

type Props = RenderColumnDataProps<Campaign> & {
    showMediaOwnersWithNoPca?: boolean;
};

const CampaignPlayoutsCell: React.FC<Props> = ({ entity, showMediaOwnersWithNoPca = false }) => {
    const byMediaOwnerContent = useMemo(() => {
        if (
            (entity.pca_last_run_at == null && !showMediaOwnersWithNoPca) ||
            !Array.isArray(entity.owners)
        ) {
            return '';
        }
        return (
            <Box>
                <Box mt={0.5}>
                    <Translate path="Modules.Main.Campaigns.List.table.playoutByMediaOwnerTooltip" />
                </Box>
                {entity.owners
                    .filter(o => o.owner?.id != null)
                    .map(o => (
                        <Box mt={0.5} key={o.owner_id}>
                            <Typography variant="body2" noWrap>
                                {`${o.owner?.name}`}
                                {o.playout_percentage != null && (
                                    <>
                                        : <NumberFormat value={o.playout_percentage} />%
                                    </>
                                )}
                            </Typography>
                        </Box>
                    ))}
            </Box>
        );
    }, [entity.owners, entity.pca_last_run_at, showMediaOwnersWithNoPca]);

    const color = useMemo<TypographyProps['color']>(() => {
        if (entity.pca_last_run_at == null) {
            return undefined;
        }

        if (Number(entity.playout_percentage) >= 100) {
            return 'success.main';
        }

        return 'error.main';
    }, [entity.pca_last_run_at, entity.playout_percentage]);

    const firstOwnerColor = useMemo<TypographyProps['color']>(() => {
        if (Number(entity?.owners?.[0]?.playout_percentage) >= 100) {
            return 'success.main';
        }

        return 'error.main';
    }, [entity.owners]);

    const overallContent = useMemo(
        () =>
            entity.playout_percentage != null && (
                <Box color={color}>
                    <NumberFormat value={entity.playout_percentage} />%
                </Box>
            ),
        [color, entity.playout_percentage],
    );

    if (entity.playout_percentage == null && !showMediaOwnersWithNoPca) {
        return null;
    }

    if (entity?.owners?.length === 1) {
        return (
            <Tooltip title={byMediaOwnerContent}>
                <Box color={firstOwnerColor}>
                    <NumberFormat value={entity.owners[0].playout_percentage} />%
                </Box>
            </Tooltip>
        );
    }

    return (
        <AutoGrid spacing={2} xs={12}>
            <Tooltip title={byMediaOwnerContent}>{overallContent}</Tooltip>
        </AutoGrid>
    );
};

export default CampaignPlayoutsCell;
