import { createSelector } from '@reduxjs/toolkit';
import { CommonState } from 'c-types';
import { OverrideSkinOptions } from 'c-wrapper/Types/Skins';
import { LANG_en_gb, LANG_en_us } from 'c-lib';

export const DEFAULT_SKIN_ID = 0;

export const getDisableDesktopContentScrolling = (state: CommonState) =>
    state.ui.disableDesktopContentScrolling;
export const currentSkin = (state: CommonState) => state.ui.previewTheme;
export const isDrawerOpen = (state: CommonState) => state.ui.drawerOpen;
export const metaTitle = (state: CommonState) => state.ui.metaTitle;
export const loggedInCrmMembers = (state: CommonState) => state.ui.crmWideMembers;
export const loggedInCrmMembersCount = (state: CommonState) => state.ui.crmWideMembers.length;
export const presenceChannelMembers = (state: CommonState) => state.ui.channelMembers;

export const pageStatus = (state: CommonState) => state.ui.pageStatus;

export const playAroundSkin = createSelector(
    (state: CommonState) => state.ui.playAroundTheme,
    playAroundSkin => playAroundSkin,
);

export const debugEnabled = createSelector(
    (state: CommonState) => state.ui.debugEnabled,
    enabled => enabled,
);

export const previewThemeRaw = createSelector(
    (state: CommonState) => state.ui.previewTheme,
    (state: CommonState) => state.ui.playAroundTheme,
    (skin, playAroundSkin) => playAroundSkin ?? skin,
);
export const previewTheme = createSelector(previewThemeRaw, skin => {
    if (!skin || skin?.id === DEFAULT_SKIN_ID) {
        return { theme: {} } as OverrideSkinOptions;
    }

    return {
        skin,
        theme: {
            palette: {
                custom: {
                    contentBgColor: skin.content_bg_color,
                    navigationBgColor: skin.nav_bg_color,
                },
                background: {
                    paper: skin.card_bg_color,
                },
                primary: {
                    main: skin.primary_color,
                },
                secondary: {
                    main: skin.secondary_color,
                },
                divider: skin.divider_color,
                text: {
                    primary: skin.primary_text_color,
                    secondary: skin.secondary_text_color,
                    disabled: skin.disabled_text_color,
                },
            },
        },
    } as OverrideSkinOptions;
});
