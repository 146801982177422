import React, { useCallback, useMemo } from 'react';
import { FilterConfigDate, FilterConfigRendererProps } from 'c-pagination';
import { useCommonTranslation } from 'c-translation';
import { DateField } from 'c-components';
import { splitValueAndOperator } from './utils';

type Props = FilterConfigRendererProps<FilterConfigDate>;

const DateFilter: React.FC<Props> = ({ config, onChange, value, id, className }) => {
    const t = useCommonTranslation();

    /**
     * We're assuming that each date filter will only have a maximum of a single value
     */
    const [actualValue] = useMemo(() => splitValueAndOperator(String(value?.[0] ?? '')), [value]);

    const onDateChange = useCallback(
        (newDate: string) => {
            if (newDate != null && actualValue != newDate) {
                onChange([`${config.operator ?? ''}${newDate}`]);
            }
            if (newDate === null) {
                // the date field fires an on change event on blur with an `undefined` value for invalid dates
                // we just want to change the value to nothing when a null date is first triggered
                onChange([]);
            }
        },
        [actualValue, onChange, config.operator],
    );

    return (
        <>
            <DateField
                value={actualValue === '' ? null : actualValue}
                label={t(config.label)}
                inputProps={{
                    size: 'small',
                    id,
                    className,
                }}
                onChange={onDateChange}
            />
        </>
    );
};

export default DateFilter;
