import {
    agencyActions,
    agencyAdapter,
    agencyThunks as defaultThunks,
    agencySelectors as defaultSelectors,
    agencyReducer,
} from './Agency-slice';
import * as thunks from './Agency-thunks';
import * as selectors from './Agency-selectors';

const agencyThunks = { ...defaultThunks, ...thunks };
const agencySelectors = { ...defaultSelectors, ...selectors };

export { default as AgencySlice } from './Agency-slice';
export { 
    agencySelectors,
    agencyActions,
    agencyAdapter,
    agencyThunks,
    agencyReducer,
};
