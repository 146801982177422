import React from 'react';
import { format } from 'date-fns';
import { Timer } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import { formatNumber, secondsToHms } from 'c-lib';
import { Translate } from 'c-translation';
import { TableReportCell } from 'c-reports/Types';
import { LogFileParsedAllCounts, LogFileParsedTotals } from './lib';

const stickySx = {
    position: 'sticky',
    left: 0,
    bgcolor: 'white',
};

const Counts: React.FC<LogFileParsedTotals> = ({ count, duration }) => {
    const hms = secondsToHms(duration);
    return (
        <Stack>
            <Typography variant="caption" component="div">
                {count ? formatNumber(count) : '-'}
            </Typography>
            <Tooltip
                title={
                    <Translate
                        path="Component.LogFileParsedDaily.timePlayedTotal"
                        data={{
                            hours: hms.h,
                            minutes: hms.m,
                            seconds: hms.s,
                        }}
                    />
                }
            >
                <Stack direction="row" alignItems="center">
                    <Typography variant="caption" component="div">
                        {duration ? formatNumber(duration) : '-'}{' '}
                    </Typography>

                    <Timer fontSize="small" color="primary" />
                </Stack>
            </Tooltip>
        </Stack>
    );
};

export const groupByMediaRows = (
    dates: Date[],
    data: LogFileParsedAllCounts,
    dateFormat: string, // one row for each media name
) => [
    {
        content: [
            {
                content: (
                    <Typography variant="caption">
                        <b>
                            <Translate path="Component.LogFileParsedDaily.totalsRow" />
                        </b>
                    </Typography>
                ),
                sx: stickySx,
            },
            ...dates.map(date => {
                const dateKey = format(date, dateFormat);
                return {
                    content: (
                        <Counts
                            count={data.day[dateKey]?.count}
                            duration={data.day[dateKey]?.duration}
                        />
                    ),
                };
            }),
        ],
    },
    ...data.uniqueMediaNames.map(mediaName => ({
        content: [
            {
                content: (
                    <Typography variant="caption">
                        <b>{mediaName}</b>
                    </Typography>
                ),
                sx: stickySx,
            },
            ...dates.map(date => {
                const dateKey = format(date, dateFormat);
                const count = data.media[mediaName]?.[dateKey]?.count;
                const duration = data.media[mediaName]?.[dateKey]?.duration;

                return {
                    content: <Counts count={count} duration={duration} />,
                };
            }),
        ],
    })),
];

function mapHours<T>(
    dates: Date[],
    data: LogFileParsedAllCounts,
    dateFormat: string,
    callback: (dateKey: string, hour: string) => T,
): T[] {
    return dates.reduce((cells, date) => {
        const dateKey = format(date, dateFormat);
        const hours = Object.keys(data.day[dateKey].hours).sort((a, b) => Number(a) - Number(b));

        return [...cells, ...hours.map(hour => callback(dateKey, hour))];
    }, []);
}
export const groupByMediaByHourRows = (
    dates: Date[],
    data: LogFileParsedAllCounts,
    dateFormat: string, // one row for each media name
) => {
    const hoursCols = mapHours<TableReportCell>(dates, data, dateFormat, (dateKey, hour) => ({
        content: (
            <Typography variant="caption">
                <b>{`${hour}:00`}</b>
            </Typography>
        ),
    }));

    const hoursTotalsCols = mapHours<TableReportCell>(dates, data, dateFormat, (dateKey, hour) => ({
        content: (
            <Counts
                count={data.day?.[dateKey]?.hours?.[hour]?.count}
                duration={data.day?.[dateKey]?.hours?.[hour]?.duration}
            />
        ),
    }));

    return [
        { content: [{ content: '' }, ...hoursCols] },
        {
            content: [
                {
                    content: (
                        <Typography variant="caption">
                            <b>
                                <Translate path="Component.LogFileParsedDaily.totalsRow" />
                            </b>
                        </Typography>
                    ),
                    sx: stickySx,
                },
                ...hoursTotalsCols,
            ],
        },
        ...data.uniqueMediaNames.map(mediaName => ({
            content: [
                {
                    content: (
                        <Typography variant="caption">
                            <b>{mediaName}</b>
                        </Typography>
                    ),
                    sx: stickySx,
                },
                ...mapHours<TableReportCell>(dates, data, dateFormat, (dateKey, hour) => {
                    const count = data.media?.[mediaName]?.[dateKey]?.hours?.[hour]?.count;
                    const duration = data.media?.[mediaName]?.[dateKey]?.hours?.[hour]?.duration;
                    return { content: <Counts count={count} duration={duration} /> };
                }),
            ],
        })),
    ];
};

export const groupByHourRows = (
    dates: Date[],
    data: LogFileParsedAllCounts,
    dateFormat: string, // one row for each media name
) => [
    {
        content: [
            {
                content: (
                    <Typography variant="caption">
                        <b>
                            <Translate path="Component.LogFileParsedDaily.totalsRow" />
                        </b>
                    </Typography>
                ),
                sx: stickySx,
            },
            ...dates.map(date => {
                const dateKey = format(date, dateFormat);
                return {
                    content: (
                        <Counts
                            count={data.day[dateKey]?.count}
                            duration={data.day[dateKey]?.duration}
                        />
                    ),
                };
            }),
        ],
    },
    ...data.uniqueHours
        .sort((a, b) => Number(a) - Number(b))
        .map(hour => ({
            content: [
                {
                    content: (
                        <Typography variant="caption">
                            <b>{`${hour}:00`}</b>
                        </Typography>
                    ),
                    sx: stickySx,
                },
                ...dates.map(date => {
                    const dateKey = format(date, dateFormat);
                    const count = data.day[dateKey]?.hours?.[hour]?.count;
                    const duration = data.day[dateKey]?.hours?.[hour]?.duration;

                    return {
                        content: <Counts count={count} duration={duration} />,
                    };
                }),
            ],
        })),
];
