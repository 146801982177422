import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    CircularProgress,
    Pagination,
    PaginationItem,
    PaginationProps,
    Typography,
} from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { AutoGrid, OptionSchema, SelectField, TextField } from 'c-components';
import { useDebouncedCallback } from 'use-debounce';

type Props = {
    totalPages: number;
    loadingPageNumber?: number;
    onPageChange: (e, page: number, perPage?: number) => void;
} & Omit<TablePaginationActionsProps, 'onPageChange' | 'getItemAriaLabel'>;

const perPageOptions: OptionSchema[] = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
];

const TablePagination: React.FC<Props> = ({
    count,
    totalPages,
    onPageChange,
    page,
    rowsPerPage,
    showFirstButton,
    showLastButton,
    loadingPageNumber,
}) => {
    const from = useMemo(
        () => (count === 0 ? 0 : (page - 1) * rowsPerPage + 1),
        [count, page, rowsPerPage],
    );

    const to = useMemo(
        () => (rowsPerPage === -1 ? count : Math.min(count, page * rowsPerPage)),
        // () => (count !== -1 ? Math.min(count, page * rowsPerPage) : page * rowsPerPage),
        [count, page, rowsPerPage],
    );

    const [skipValue, setSkipValue] = useState('');
    const onSkipUpdated = useCallback(
        e => {
            setSkipValue(curr => {
                if (+curr !== +e.target.value) {
                    return e.target.value;
                }
                return curr;
            });
        },
        [setSkipValue],
    );

    const debouncedSkipToPage = useDebouncedCallback((callback: () => void) => {
        callback();
    }, 1000);

    useEffect(() => {
        if (skipValue?.trim()?.length > 0) {
            const newPage = +skipValue;

            debouncedSkipToPage(() => {
                if (newPage >= 1 && newPage !== page && newPage <= totalPages) {
                    onPageChange(null, newPage);
                    setSkipValue('');
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skipValue]);

    useEffect(() => {
        setSkipValue('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalPages]);

    const skipPageTextfield = useMemo(
        () => (
            <Box minWidth="1em" maxWidth="3em" width="auto">
                <TextField
                    variant="standard"
                    placeholder="#"
                    whiteBackdrop={false}
                    greyBackdrop={false}
                    value={skipValue}
                    onChange={onSkipUpdated}
                />
            </Box>
        ),
        [onSkipUpdated, skipValue],
    );

    const loadingIcon = useMemo(
        () => (
            <CircularProgress
                size={15}
                color={page === loadingPageNumber ? 'primary' : 'secondary'}
            />
        ),
        [page, loadingPageNumber],
    );
    const renderPaginationItem = useCallback<PaginationProps['renderItem']>(
        props => {
            if (props.type === 'page') {
                return (
                    <PaginationItem
                        {...props}
                        selected={props.page === page}
                        page={props.page === loadingPageNumber ? loadingIcon : props.page}
                    />
                );
            }

            return <PaginationItem {...props} />;
        },
        [loadingIcon, loadingPageNumber, page],
    );

    const onPerPageChanged = useCallback(
        e => {
            onPageChange(null, 1, +e.target.value);
        },
        [onPageChange],
    );

    return (
        <Box className="custom-table-pagination" display="flex" py={2}>
            <AutoGrid
                spacing={2}
                sx={{ ml: 'auto', alignItems: 'center', justifyContent: 'flex-end' }}
            >
                <Typography variant="body2" color="inherit">
                    {from} - {to} of {count}
                </Typography>
                <Pagination
                    page={page}
                    showFirstButton={showFirstButton}
                    showLastButton={showLastButton}
                    count={totalPages}
                    color="primary"
                    // size="small"
                    renderItem={renderPaginationItem}
                    onChange={onPageChange as any}
                />
                {skipPageTextfield}
                <SelectField
                    options={perPageOptions}
                    value={rowsPerPage === 0 ? '' : rowsPerPage}
                    onChange={onPerPageChanged}
                />
            </AutoGrid>
        </Box>
    );
};

export default TablePagination;
