import React, { Ref } from 'react';
import { Box } from '@mui/material';

type Props = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> &
    Required<{
        id: string;
        label: string;
    }>;

const CheckboxRaw = ({ label, id, ...rest }: Props, ref: Ref<any>) => (
    <Box sx={{ cursor: 'pointer' }}>
        <input id={id} type="checkbox" ref={ref} style={{ cursor: 'pointer' }} {...rest} />
        <label htmlFor={id} style={{ cursor: 'pointer' }}>
            {' '}
            {label}
        </label>
    </Box>
);

export default React.forwardRef(CheckboxRaw);
