import {
    displays_AnalogueScreenActions,
    displays_AnalogueScreenAdapter,
    displays_AnalogueScreenThunks as defaultThunks,
    displays_AnalogueScreenSelectors as defaultSelectors,
    displays_AnalogueScreenReducer,
} from './Displays_AnalogueScreen-slice';
import * as thunks from './Displays_AnalogueScreen-thunks';
import * as selectors from './Displays_AnalogueScreen-selectors';

const displays_AnalogueScreenThunks = { ...defaultThunks, ...thunks };
const displays_AnalogueScreenSelectors = { ...defaultSelectors, ...selectors };

export { default as Displays_AnalogueScreenSlice } from './Displays_AnalogueScreen-slice';
export {
    displays_AnalogueScreenSelectors,
    displays_AnalogueScreenActions,
    displays_AnalogueScreenAdapter,
    displays_AnalogueScreenThunks,
    displays_AnalogueScreenReducer,
};
