import { PopDisplays_LogFileImport } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { PopDisplays_LogFileImportState } from './LogFileImport-types';

const initialState: PopDisplays_LogFileImportState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: LogFileImportState, action: PayloadAction<string>): LogFileImportState => state,
};

const slice = createBaseSlice<
    PopDisplays_LogFileImport,
    PopDisplays_LogFileImportState,
    typeof additionalReducers
>({
    name: 'logFileImport',
    entityName: 'PopDisplays_LogFileImport',
    initialState,
    reducers: additionalReducers,
});

const logFileImportSlice = {
    logFileImportActions: slice.slice.actions,
    logFileImportReducer: slice.slice.reducer,
    logFileImportAdapter: slice.adapter,
    logFileImportSelectors: slice.selectors,
    logFileImportThunks: slice.thunks,
};

export default logFileImportSlice;
export const {
    logFileImportReducer,
    logFileImportActions,
    logFileImportAdapter,
    logFileImportSelectors,
    logFileImportThunks,
} = logFileImportSlice;
