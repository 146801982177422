import React, { forwardRef, Ref, useCallback, useMemo } from 'react';
import { format as $format, isValid, parse } from 'date-fns';
import { TextFieldProps } from '@mui/material';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers-pro';

import { TextField } from '../TextField';

type Props = {
    value: string;
    sx?: any;
    inputProps?: Partial<TextFieldProps>;
    onChange: (value: string) => void;
    error?: boolean;
} & Omit<TimePickerProps<Date>, 'onChange' | 'value'>;

const TimePickerField = (
    { onChange, inputProps, value, sx, error, format, ...props }: Props,
    ref: Ref<any>,
) => {
    const handleOnChange = useCallback(
        (date: Date) => {
            if (isValid(date)) onChange($format(date, format));
            else onChange(null);
        },
        [format, onChange],
    );

    const datePickerValue = useMemo(() => {
        if (value != null) {
            const date = parse(value, format, new Date());
            if (isValid(date)) return date;
        }

        return null;
    }, [value, format]);

    return (
        <TimePicker
            format={format}
            onChange={handleOnChange}
            value={datePickerValue as any as Date}
            slots={useMemo(() => ({ textField: TextField as any }), [])}
            slotProps={useMemo(
                () => ({
                    textField: {
                        ref,
                        fullWidth: true,
                        error,
                        ...inputProps,
                    },
                    digitalClockSectionItem: {
                        sx,
                    },
                    actionBar: {
                        actions: ['clear', 'accept'],
                    },
                }),
                [error, inputProps, ref, sx],
            )}
            {...props}
        />
    );
};

export default forwardRef(TimePickerField);
