import React, { useMemo } from 'react';
import { CampaignStats } from '@uniled/api-sdk';
import { AutoGrid } from 'c-components';
import { formatNumber, formatNumberWhole } from 'c-lib';
import HoursMins from 'c-main/Components/Campaign/CampaignReports/HoursMins';
import { Box, Card, CardContent, Divider, Typography, TypographyProps } from '@mui/material';
import { Translate, TranslationPath } from 'c-translation';

type Props = {
    stats: CampaignStats;
};

const PCATotalsByMetric: React.FC<Props> = ({ stats }) => {
    return (
        <AutoGrid spacing={4} xs={12} lg={4}>
            <TotalsTile
                title="Modules.Main.Campaigns.Overview.Analytics.impactsTitle"
                actual={stats.impacts}
                expected={stats.impacts_expected}
                performance={+stats.impacts_percentage}
                inSchedule={stats.impacts_in_schedule}
                outSchedule={stats.impacts_out_schedule}
                unbooked={stats.impacts_unbooked}
            />
            <TotalsTile
                title="Modules.Main.Campaigns.Overview.Analytics.playsTitle"
                actual={stats.plays}
                expected={stats.plays_expected}
                performance={+stats.plays_percentage}
                inSchedule={stats.plays_in_schedule}
                outSchedule={stats.plays_out_schedule}
                unbooked={stats.plays_unbooked}
            />
            <TotalsTile
                title="Modules.Main.Campaigns.Overview.Analytics.timeTitle"
                asTime
                actual={stats.time}
                expected={stats.time_expected}
                performance={+stats.time_percentage}
                inSchedule={stats.time_in_schedule}
                outSchedule={stats.time_out_schedule}
                unbooked={stats.time_unbooked}
            />
        </AutoGrid>
    );
};

type TileProps = {
    title: TranslationPath;
    actual: number;
    expected: number;
    performance: number;

    inSchedule: number;
    outSchedule: number;
    unbooked: number;

    asTime?: boolean;
};
const TotalsTile: React.FC<TileProps> = ({
    title,
    actual,
    expected,
    performance,
    asTime,
    inSchedule,
    outSchedule,
    unbooked,
}) => {
    const performanceColour = useMemo(() => {
        if (expected <= 0) return undefined;

        return performance >= 100 ? 'success.main' : 'warning.main';
    }, [expected, performance]);

    return (
        <Card>
            <CardContent>
                <Typography variant="h4" color="textPrimary">
                    <Translate path={title} />
                </Typography>
                <Divider sx={{ borderBottomWidth: 2, my: 1 }} />
                <AutoGrid spacing={0} xs={12}>
                    <FormatCount
                        asTime={false}
                        count={expected > 0 ? performance : null}
                        countSuffix={expected > 0 ? '%' : ''}
                        title="Modules.Main.Campaigns.Overview.Analytics.performance"
                        countColor={performanceColour}
                        countVariant="h4"
                        asWholeNumber={false}
                    />
                    <FormatCount
                        count={actual}
                        asTime={asTime === true}
                        title="Modules.Main.Campaigns.Overview.Analytics.actual"
                    />
                    <FormatCount
                        count={expected}
                        asTime={asTime === true}
                        title="Modules.Main.Campaigns.Overview.Analytics.expected"
                    />
                    <FormatCount
                        count={inSchedule}
                        asTime={asTime === true}
                        title="Modules.Main.Campaigns.Overview.Analytics.inSchedule"
                        titleVariant="body2"
                        countVariant="body2"
                    />
                    <FormatCount
                        count={outSchedule}
                        asTime={asTime === true}
                        title="Modules.Main.Campaigns.Overview.Analytics.outSchedule"
                        titleVariant="body2"
                        countVariant="body2"
                    />
                    <FormatCount
                        asTime={asTime === true}
                        count={unbooked}
                        title="Modules.Main.Campaigns.Overview.Analytics.unbooked"
                        titleVariant="body2"
                        countVariant="body2"
                    />
                </AutoGrid>
            </CardContent>
        </Card>
    );
};

const FormatCount = React.memo<{
    count?: number;
    countSuffix?: React.ReactNode;
    asTime: boolean;
    asWholeNumber?: boolean;
    title: TranslationPath;
    titleColor?: TypographyProps['color'];
    titleVariant?: TypographyProps['variant'];

    countColor?: TypographyProps['color'];
    countVariant?: TypographyProps['variant'];
}>(
    ({
        count,
        countSuffix,
        asTime,
        asWholeNumber = true,
        title,
        titleColor = 'textSecondary',
        titleVariant = 'subtitle1',
        countColor = 'textPrimary',
        countVariant = 'subtitle1',
    }) => (
        <AutoGrid spacing={1} justifyContent="space-between">
            <Box display="flex" alignItems="center">
                <Typography variant={titleVariant} color={titleColor}>
                    <Translate path={title} />
                </Typography>
            </Box>
            {asTime ? (
                <HoursMins color={countColor} seconds={count} variant={countVariant} />
            ) : (
                <Box display="flex" alignItems="center">
                    <Typography variant={countVariant} color={countColor}>
                        {count === null
                            ? '-'
                            : asWholeNumber
                            ? formatNumberWhole(count)
                            : formatNumber(count)}
                        {countSuffix}
                    </Typography>
                </Box>
            )}
        </AutoGrid>
    ),
);

export default PCATotalsByMetric;
