import React, { useCallback, useEffect, useMemo } from 'react';
import { DialogV2, FlashMessage, ListItemText } from 'c-components';
import apiClient from 'c-data/apiClient';
import { useCommonTranslation } from 'c-translation';
import { useAPIClientRequest, useAutoResetBoolean } from 'c-hooks';
import {
    Box,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import to from 'await-to-js';
import { ContentCopy } from '@mui/icons-material';
import { IfUserIsOneOfTypes } from 'c-auth-module/Components';
import { UserType } from '@uniled/api-sdk';
import { userName } from 'c-admin/Lib';
import { UserAvatar } from 'c-admin/Components';
import { copyTextToClipboard } from 'c-lib';

type Props = {
    open: boolean;
    onClose: () => void;
    campaignId: number;
};

const CampaignShareDialog: React.FC<Props> = ({ campaignId, open, onClose }) => {
    const { start, data, isLoading, error, hasFailed, hasSucceeded } = useAPIClientRequest(
        apiClient.Entities.Campaign.shareUrl,
    );
    const fetchShareLink = useCallback(async () => {
        if (data != null && !hasSucceeded) {
            // just using the never changing legacy url for now
            return;
        }

        await to(start(campaignId));
    }, [campaignId, start, data, hasSucceeded]);

    useEffect(() => {
        if (open) {
            fetchShareLink();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <DialogV2
            open={open}
            onClose={onClose}
            title="Modules.Main.Campaigns.Overview.ActionLabels.share"
            MuiDialogProps={{ id: 'campaign-share-dialog' }}
            maxWidth="md"
        >
            <>
                <Box display="flex" justifyContent="center">
                    {isLoading && <CircularProgress />}
                    {hasFailed && <FlashMessage status="error">{error}</FlashMessage>}
                    {hasSucceeded && (
                        <Stack spacing={2}>
                            <CopyableShareLink url={data?.data?.url} />
                        </Stack>
                    )}
                </Box>
                <IfUserIsOneOfTypes roles={[UserType.Admin]}>
                    {open && <SharedWithUsers id={campaignId} />}
                </IfUserIsOneOfTypes>
            </>
        </DialogV2>
    );
};

const CopyableShareLink: React.FC<{ url: string }> = ({ url }) => {
    const { setTrue, value: CopySuccess } = useAutoResetBoolean(false, 2500);
    const onCopy = useCallback(async () => {
        const [err] = await to(copyTextToClipboard(url));

        if (!err) setTrue();
    }, [url, setTrue]);

    const t = useCommonTranslation();

    return (
        <Stack>
            <Box display="flex" flexWrap="nowrap" alignItems="center" justifyContent="center">
                <Typography
                    variant="body1"
                    align="center"
                    whiteSpace="pre-line"
                    sx={{ wordBreak: 'break-all' }}
                >
                    {url}
                </Typography>
                <Tooltip
                    title={t('Modules.Main.Campaigns.Overview.ShareDialog.shareCopied')}
                    open={CopySuccess}
                >
                    <IconButton
                        onClick={onCopy}
                        size="medium"
                        color={CopySuccess ? 'success' : undefined}
                    >
                        <ContentCopy fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </Box>
        </Stack>
    );
};

const SharedWithUsers: React.FC<{ id: number }> = ({ id }) => {
    const t = useCommonTranslation();
    const { start, data, isLoading, error, hasFailed, hasSucceeded } = useAPIClientRequest(
        apiClient.Entities.Campaign.get,
    );

    const fetchCampaignUsers = useCallback(async () => {
        await to(start(id, ['users']));
    }, [id, start]);

    useEffect(() => {
        fetchCampaignUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const users = useMemo(() => data?.data?.data?.users ?? [], [data]);
    const sharedWithTitle = useMemo(
        () =>
            t('Modules.Main.Campaigns.Overview.ShareDialog.sharedWithUsers', {
                count: users.length,
            }),
        [t, users.length],
    );

    return (
        <>
            <Box>
                {isLoading && (
                    <Box textAlign="center">
                        <CircularProgress size={25} />
                    </Box>
                )}
                {hasSucceeded && (
                    <Stack justifyContent="center" alignItems="center">
                        <Typography variant="h4" align="center">
                            {sharedWithTitle}
                        </Typography>
                        <List disablePadding dense>
                            {users.map(user => (
                                <ListItem key={user.id} dense disableGutters>
                                    <ListItemAvatar>
                                        <UserAvatar user={user} />
                                    </ListItemAvatar>
                                    <ListItemText primary={userName(user)} secondary={user.email} />
                                </ListItem>
                            ))}
                        </List>
                    </Stack>
                )}
            </Box>
        </>
    );
};

export default CampaignShareDialog;
