import React, { forwardRef, Ref, useCallback, useMemo } from 'react';
import { AutocompleteProps } from 'c-components/Forms/Autocomplete/Autocomplete';
import { useTheme } from '@mui/styles';
// eslint-disable-next-line import/no-cycle
import { Autocomplete } from 'c-components/Forms';
import { TextFieldProps } from '@mui/material';
import { formatPlaceholder, generateTextFieldProps } from './funcs';

type Props = {
    error?: boolean;
    textfieldVariant?: TextFieldProps['variant'];
    placeholder?: string;
} & Omit<AutocompleteProps, 'textFieldProps'>;

const Filterdropdown = (
    { placeholder, size = 'small', textfieldVariant = 'outlined', error, ...rest }: Props,
    ref: Ref<any>,
) => {
    const theme = useTheme();

    const textFieldProps = useMemo(
        () =>
            generateTextFieldProps({
                variant: textfieldVariant,
                size,
                placeholder,
                theme,
                error,
                value: rest.value,
                multiple: rest.multiple,
                options: rest.options,
            }),
        [
            textfieldVariant,
            size,
            placeholder,
            theme,
            error,
            rest.value,
            rest.multiple,
            rest.options,
        ],
    );
    const renderTags: AutocompleteProps['renderTags'] = useCallback(() => null, []);
    const $formatPlaceholder: AutocompleteProps['formatPlaceholder'] = useCallback(
        values => formatPlaceholder(values, rest.multiple, placeholder),
        [placeholder, rest.multiple],
    );

    return (
        <Autocomplete
            renderTags={renderTags}
            renderOptionCheckboxes
            textFieldProps={textFieldProps}
            stayOpenOnSelect
            formatPlaceholder={$formatPlaceholder}
            ref={ref}
            {...rest}
        />
    );
};

export default forwardRef(Filterdropdown);
