import React from 'react';
import { BoxProps, Box } from '@mui/material';

export type HCFLayoutProps = React.PropsWithChildren<BoxProps> & { component?: React.ElementType };

const HCFLayout = ({ children, ...boxProps }: HCFLayoutProps) => (
    <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        minHeight="100vh"
        maxHeight={{
            // xs: `calc(100vh - 110px)`,
            xs: `100vh`,
        }}
        {...boxProps}
    >
        {children}
    </Box>
);

const HCFLayoutHeader = ({ children, ...boxProps }: HCFLayoutProps) => (
    <Box {...boxProps}>{children}</Box>
);

const HCFLayoutContent = ({ children, ...boxProps }: HCFLayoutProps) => (
    <Box flex={1} justifyContent="center" {...boxProps}>
        {children}
    </Box>
);

const HCFLayoutFooter = ({ children, ...boxProps }: HCFLayoutProps) => (
    <Box {...boxProps}>{children}</Box>
);

HCFLayout.Header = HCFLayoutHeader;
HCFLayout.Content = HCFLayoutContent;
HCFLayout.Footer = HCFLayoutFooter;

export default HCFLayout;
