import React, { useCallback } from 'react';
import { User } from 'c-entity-types';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { Alert, Button } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { CircularProgress, Stack } from '@mui/material';

type Props = {
    user: User;
};

const UnverifiedUser: React.FC<Props> = ({ user }) => {
    const { start, isLoading, hasFailed, error, data, hasSucceeded } = useAPIClientRequest(
        apiClient.Entities.User.resendInvitation,
    );

    const resend = useCallback(async () => {
        await to(start(user.id));
    }, [start, user]);

    const t = useCommonTranslation();

    return (
        <Stack gap={2}>
            <Alert
                color="info"
                variant="outlined"
                action={
                    <Button
                        variant="text"
                        disabled={isLoading}
                        onClick={resend}
                        iconChild={isLoading}
                    >
                        {isLoading ? (
                            <CircularProgress size={15} />
                        ) : (
                            t('Modules.Admin.UserEdit.resendInvitationBtn')
                        )}
                    </Button>
                }
            >
                {t('Modules.Admin.UserEdit.resendInvitationDescription')}
            </Alert>
            {hasFailed && (
                <Alert severity="error" variant="outlined">
                    {String(error)}
                </Alert>
            )}
            {hasSucceeded && (
                <Alert severity="success" variant="outlined">
                    <pre>{JSON.stringify(data?.data?.data, null, 2)}</pre>
                </Alert>
            )}
        </Stack>
    );
};

export default UnverifiedUser;
