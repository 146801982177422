import React, { useContext, useMemo } from 'react';
import cx from 'classnames';
import merge from 'deepmerge';
import {
    Box,
    TableCell as MuiTableCell,
    TableCellProps,
    Typography,
    TypographyProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TableContext } from 'c-components/Tables';

type Props = TableCellProps & {
    isHeader?: boolean;
    contentBg?: boolean;
    noWrap?: boolean;
    typographyProps?: TypographyProps;
    collapsed?: boolean;
    disabledRowDividers?: boolean;
    dense?: boolean;
};

const useStyles = makeStyles(theme => ({
    header: {
        // borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    noTextWrap: {
        whiteSpace: 'nowrap',
    },
    contentBg: {
        backgroundColor: theme.palette.custom.contentBgColor,
    },
    collapsed: {
        paddingTop: 0,
        paddingBottom: 0,
        transition: 'all 400ms',
        border: 'none',
    },
    noDivider: {
        border: 'none',
    },
}));

const TableCell: React.FC<Props> = ({
    children,
    className,
    isHeader = false,
    noWrap = false,
    contentBg = false,
    typographyProps,
    collapsed = false,
    disabledRowDividers = false,
    dense = false,
    sx,
    ...rest
}) => {
    const { spacedOutRows } = useContext(TableContext);
    const actuallyDisabledRowDividers = disabledRowDividers || spacedOutRows;

    const actualCellSx = useMemo<TableCellProps['sx']>(
        () =>
            spacedOutRows && isHeader
                ? (merge(
                      {
                          pb: 0,
                      },
                      sx ?? ({} as any),
                  ) as TableCellProps['sx'])
                : sx,
        [sx, spacedOutRows, isHeader],
    );

    const {
        header,
        noTextWrap,
        contentBg: ContentBgClass,
        collapsed: CollapsedStyles,
        noDivider,
    } = useStyles();
    const classNameMemo = useMemo(
        () =>
            cx(className, {
                [header]: isHeader,
                [noTextWrap]: noWrap,
                [ContentBgClass]: contentBg,
                [CollapsedStyles]: collapsed,
                [noDivider]: actuallyDisabledRowDividers,
            }),
        [
            CollapsedStyles,
            ContentBgClass,
            className,
            collapsed,
            contentBg,
            actuallyDisabledRowDividers,
            header,
            isHeader,
            noDivider,
            noTextWrap,
            noWrap,
        ],
    );

    const actualTypographyProps = useMemo<TypographyProps>(
        () => ({ component: 'div', ...typographyProps }),
        [typographyProps],
    );

    return (
        <MuiTableCell
            className={classNameMemo}
            sx={actualCellSx}
            padding={dense ? 'none' : undefined}
            {...rest}
        >
            <Box height="100%" sx={{ display: isHeader ? 'flex' : undefined }}>
                {typographyProps ? (
                    <Typography {...actualTypographyProps}>{children}</Typography>
                ) : (
                    children
                )}
            </Box>
        </MuiTableCell>
    );
};

export default TableCell;
