import React, { useMemo } from 'react';
import { Grid, List } from '@mui/material';
import { ViewOptionProps } from '../types';
import FilePreview from '../FilePreview';
import ListViewItem from './ListViewItem';

type Props = ViewOptionProps;

const FileBrowserListView: React.FC<Props> = ({
    files,
    ListWrapper,
    onFileClick,
    focusedFile,
    filters,
    selectedFiles,
    onFileToggled,
}) => {
    const searchTerm = useMemo(() => filters?.term ?? '', [filters?.term]);
    return (
        <Grid container spacing={2} flex={1} overflow="hidden">
            <Grid item xs={focusedFile ? true : 12} overflow="auto" flex={1} height="100%">
                <ListWrapper>
                    <List sx={{ width: '100%' }}>
                        {files.map((file, fi) => (
                            <ListViewItem
                                isFocused={focusedFile?.id === file.id}
                                isSelected={selectedFiles.some(f => f.id === file.id)}
                                forceShowCheckboxes={selectedFiles.length > 0}
                                isAnyFileFocused={focusedFile != null}
                                key={file.id}
                                onClick={onFileClick}
                                filters={filters}
                                file={file}
                                index={fi}
                                searchTerm={searchTerm}
                                onFileToggled={onFileToggled}
                            />
                        ))}
                    </List>
                </ListWrapper>
            </Grid>
            {focusedFile && (
                <Grid item md={6} display="flex" position="relative">
                    <FilePreview file={focusedFile} />
                </Grid>
            )}
        </Grid>
    );
};

export default FileBrowserListView;
