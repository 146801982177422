import {
    logImportSourceActions,
    logImportSourceAdapter,
    logImportSourceThunks as defaultThunks,
    logImportSourceSelectors as defaultSelectors,
    logImportSourceReducer,
} from './LogImportSource-slice';
import * as thunks from './LogImportSource-thunks';
import * as selectors from './LogImportSource-selectors';

const logImportSourceThunks = { ...defaultThunks, ...thunks };
const logImportSourceSelectors = { ...defaultSelectors, ...selectors };

export { default as LogImportSourceSlice } from './LogImportSource-slice';
export {
    logImportSourceSelectors,
    logImportSourceActions,
    logImportSourceAdapter,
    logImportSourceThunks,
    logImportSourceReducer,
};
