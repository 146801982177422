import React, { useCallback, useMemo } from 'react';
import { Box, TableContainer } from '@mui/material';
import { BaseEntity } from '@uniled/api-sdk';
import {
    EntityColumnHeader,
    EntityDataColumn,
    EntityListColumn,
    PaginatedEntityRow,
    PaginatedTableProps,
} from 'c-pagination';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'c-components';
import { paginatedDataToSearchPayload } from '@uniled/data-layer';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { IfHasAllPermissions, IfUserIsOneOfTypes } from 'c-auth-module/Components';
import { useAuthenticatedUser } from 'c-hooks';

function PaginatedEntityList<Entity extends BaseEntity>({
    paginationData,
    columns,
    rows,
    RenderColumnData = EntityDataColumn,
    onSearch,
    tag,
    tableContainerProps,
    tableProps,
    baseEntityName,
    generateRowSx,
    disabledRowDividers,
    dense,
}: PaginatedTableProps<Entity>) {
    const onOrderByChange = useCallback(
        (column: EntityListColumn<Entity>, orderByKey: string) => {
            const currentDirection = paginationData.direction;
            const currentOrderBy = paginationData.orderBy;
            let newDirection = currentDirection;

            let flip = false;
            if (currentOrderBy === orderByKey) {
                flip = true;
            }

            if (flip) {
                newDirection = currentDirection === 'asc' ? 'desc' : 'asc';
            } else {
                newDirection = 'asc';
            }

            onSearch(
                paginationData.meta.pagination.current_page,
                paginatedDataToSearchPayload(paginationData, {
                    orderBy: orderByKey,
                    direction: newDirection,
                }),
            );
        },
        [onSearch, paginationData],
    );

    const { hasAll } = useUserPermissions();
    const { chosenUserType, isAdmin } = useAuthenticatedUser();
    const actualColumns = useMemo(
        () =>
            columns.filter(col => {
                const typeCheck =
                    isAdmin ||
                    (col.userTypes ?? []).length === 0 ||
                    (col.userTypes ?? []).indexOf(chosenUserType) !== -1;
                const permCheck = hasAll(col.permissions ?? []);

                return typeCheck && permCheck;
            }),
        [columns, hasAll, chosenUserType, isAdmin],
    );

    const table = (
        <Table {...tableProps} sx={{ borderCollapse: 'separate' }}>
            <TableHead>
                <TableRow isHeader dense={dense}>
                    {actualColumns.map((col, colIdx) => (
                        <TableCell
                            key={`${col.entityName}-${col.field}-${col.headerTitle}`}
                            isHeader
                        >
                            <IfUserIsOneOfTypes roles={col.userTypes}>
                                <IfHasAllPermissions permissions={col.permissions}>
                                    <EntityColumnHeader
                                        column={col}
                                        paginationData={paginationData}
                                        onOrderByClicked={onOrderByChange}
                                    />
                                </IfHasAllPermissions>
                            </IfUserIsOneOfTypes>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map(row => (
                    <PaginatedEntityRow
                        key={row.id}
                        row={row}
                        columns={actualColumns}
                        RenderColumnData={RenderColumnData}
                        tag={tag}
                        onClick={row.onClick}
                        paginationData={paginationData}
                        baseEntityName={baseEntityName}
                        generateSx={generateRowSx}
                        disabledRowDividers={disabledRowDividers}
                        dense={dense}
                    />
                ))}
            </TableBody>
        </Table>
    );
    return (
        <TableContainer component={Box} {...tableContainerProps}>
            {table}
        </TableContainer>
    );
}

export default PaginatedEntityList;
