import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAuthenticatedUser } from 'c-hooks';
import { userName } from 'c-admin/Lib';

function SentryInitialiser(): null {
    const { user } = useAuthenticatedUser();

    useEffect(() => {
        if (user) {
            Sentry.setUser({ id: String(user.id), email: user.email, username: userName(user) });
        }
    }, [user]);
    return null;
}

export default SentryInitialiser;
