import { Config } from 'src/config';
import { mergeSystemSearchConfig as mergeFunction } from 'c-system-search';
import * as pages from './Pages';
import * as slices from './Slices';

export const config: Config = {
    mergeFunction,
    reducers: {
        displays_Screen: slices.displays_ScreenReducer,
        displays_Owner: slices.displays_OwnerReducer,
        displays_AnalogueScreen: slices.displays_AnalogueScreenReducer,
        displays_Country: slices.displays_CountryReducer,
    },
    EntitySchemaActions: {
        Displays_Screen: {
            actions: slices.displays_ScreenActions as any,
            entityName: 'Displays_Screen',
            selectors: slices.displays_ScreenSelectors,
            thunks: slices.displays_ScreenThunks,
        },
        Displays_Owner: {
            actions: slices.displays_OwnerActions as any,
            entityName: 'Displays_Owner',
            selectors: slices.displays_OwnerSelectors,
            thunks: slices.displays_OwnerThunks,
        },
        Displays_AnalogueScreen: {
            actions: slices.displays_AnalogueScreenActions as any,
            entityName: 'Displays_AnalogueScreen',
            selectors: slices.displays_AnalogueScreenSelectors,
            thunks: slices.displays_AnalogueScreenThunks,
        },
        Displays_Country: {
            actions: slices.displays_CountryActions as any,
            entityName: 'Displays_Country',
            selectors: slices.displays_CountrySelectors,
            thunks: slices.displays_CountryThunks,
        },
    },
    navigation: {
        groups: {
            2: {
                items: {
                    1: {
                        items: {
                            100: {
                                id: 'displays-admin-home',
                                label: 'Navigation.displaysAdmin',
                                icon: pages.DisplaysAdminHomePage.systemSearch.icon,
                                link: pages.DisplaysAdminHomePage.systemSearch.route,
                                hideIfNoChildren: true,
                                permissions:
                                    pages.DisplaysAdminHomePage.systemSearch?.permissions ?? [],
                                items: {
                                    1: {
                                        id: 'displays-displays-list',
                                        icon: pages.DisplaysListPage.systemSearch.icon,
                                        label: 'Navigation.displaysDisplayList',
                                        link: pages.DisplaysListPage.systemSearch.route,
                                        permissions:
                                            pages.DisplaysListPage.systemSearch?.permissions ?? [],
                                    },
                                    2: {
                                        id: 'displays-packs-list',
                                        icon: pages.PackListPage.systemSearch.icon,
                                        label: 'Navigation.displaysPacks',
                                        link: pages.PackListPage.systemSearch.route,
                                        permissions:
                                            pages.PackListPage.systemSearch?.permissions ?? [],
                                    },
                                    3: {
                                        id: 'displays-owners-list',
                                        icon: pages.OwnerListPage.systemSearch.icon,
                                        label: 'Navigation.displaysOwnerList',
                                        link: pages.OwnerListPage.systemSearch.route,
                                        permissions:
                                            pages.OwnerListPage.systemSearch?.permissions ?? [],
                                    },
                                    4: {
                                        id: 'displays-analogue-list',
                                        icon: pages.AnaloguesListPage.systemSearch.icon,
                                        label: 'Navigation.displaysAnalogue',
                                        link: pages.AnaloguesListPage.systemSearch.route,
                                        permissions:
                                            pages.AnaloguesListPage.systemSearch?.permissions ?? [],
                                    },
                                    5: {
                                        id: 'displays-tag-list',
                                        icon: pages.TagsListPage.systemSearch.icon,
                                        label: 'Navigation.displaysTag',
                                        link: pages.TagsListPage.systemSearch.route,
                                        permissions:
                                            pages.TagsListPage.systemSearch?.permissions ?? [],
                                    },
                                    6: {
                                        id: 'displays-countries-list',
                                        icon: pages.CountryListPage.systemSearch.icon,
                                        label: 'Navigation.displaysCountry',
                                        link: pages.CountryListPage.systemSearch.route,
                                        permissions:
                                            pages.CountryListPage.systemSearch?.permissions ?? [],
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    pages: [
        pages.DisplaysListPage,
        pages.DisplaysAdminHomePage,
        pages.PackListPage,
        pages.OwnerListPage,
        pages.AnaloguesListPage,
        pages.DisplaysDisplayEditPage,
        pages.TagsListPage,
        pages.DisplaysTagEditPage,
        pages.DisplaysOwnerEditPage,
        pages.CountryListPage,
        pages.DisplaysPackEditPage,
        pages.DisplaysCountryEditPage,
        pages.DisplaysAnalogueScreenEditPage,
    ],
    systemSearch: {},
    widgets: [],
};
