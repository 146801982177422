import React, { useMemo } from 'react';
import { useCommonTranslation } from 'c-translation';
import { secondsToDhms } from 'c-lib';
import { FileBrowserFile } from './types';

type Props = {
    file: FileBrowserFile;
};

const FileDuration: React.FC<Props> = ({ file }) => {
    const t = useCommonTranslation();

    const duration = useMemo(() => {
        if (typeof file?.durationMs === 'number' && file?.durationMs > 0) {
            const hms = secondsToDhms(file.durationMs / 1000);

            const parts: string[] = [];

            if (hms.h > 0) parts.push(t('Duration.hours', { count: hms.h }));
            if (hms.m > 0) parts.push(t('Duration.minutes', { count: hms.m }));
            if (hms.s > 0) parts.push(t('Duration.seconds', { count: hms.s }));

            return parts.join(' ');
        }

        return '';
    }, [file, t]);

    return <>{duration}</>;
};

export default FileDuration;
