import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Box, Typography, TypographyProps } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { useBoolean, usePrevious } from 'react-hanger';
import { ReValidateCampaign, UpdateCreatives } from 'c-main/Components/CreativeManagement/types';
import UploadDialog from 'c-main/Components/CreativeManagement/Components/Creatives/UploadDialog';

type Props = {
    openGroup?: number;
    updateCreatives: UpdateCreatives;
    reValidateCampaign: ReValidateCampaign;
    existingFilesArray: string[];
};

const CreativeListDropZone: React.FC<Props> = ({
    openGroup,
    updateCreatives,
    reValidateCampaign,
    existingFilesArray,
}) => {
    const dialogState = useBoolean(false);
    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

    const prevDialogState = usePrevious(dialogState.value);
    useEffect(() => {
        if (prevDialogState === true && dialogState.value === false) {
            // revalidate on close if at least 1 file was uploaded
            if (filesToUpload?.length > 0) {
                reValidateCampaign();
            }

            setFilesToUpload([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogState.value]);

    const onDrop = useCallback(
        (data: { files: File[] }) => {
            setFilesToUpload(data.files);
            dialogState.setTrue();
        },
        [dialogState],
    );

    const [{ canDrop, isOver }, drop] = useDrop(
        {
            accept: NativeTypes.FILE,
            drop: onDrop,
            collect: monitor => ({ canDrop: monitor.canDrop(), isOver: monitor.isOver() }),
        },
        [onDrop],
    );
    const color = useMemo<TypographyProps['color']>(
        () => (isOver && canDrop ? 'success.main' : 'primary.main'),
        [canDrop, isOver],
    );

    return (
        <>
            {dialogState.value && (
                <UploadDialog
                    closeDialog={dialogState.setFalse}
                    isDialogOpen={dialogState.value}
                    updateCreatives={updateCreatives}
                    reValidateCampaign={reValidateCampaign}
                    initialFilesToUpload={filesToUpload}
                    existingFilesArray={existingFilesArray}
                />
            )}
            <Box
                ref={drop}
                zIndex={999}
                position="absolute"
                top={0}
                right={0}
                bottom={0}
                left={0}
                sx={{
                    background: 'rgba(255,255,255,0.7)',
                    borderWidth: 4,
                    borderStyle: 'dashed',
                    borderColor: color,
                    visibility: canDrop ? 'visible' : 'hidden',
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="h2" color={color}>
                    {useCommonTranslation(
                        'Modules.Main.CreativeManagement.creatives.table.dropMessage',
                    )}
                </Typography>
            </Box>
        </>
    );
};

export default CreativeListDropZone;
