import React, { useMemo } from 'react';
import { MediaOwnerIssues } from 'c-main/Types';
import { Box, BoxProps, Collapse, TableContainer, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { cssRotation } from 'c-lib';
import { useBoolean } from 'react-hanger';
import { Table, TableCell, TableHead, TableRow } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import MediaOwnerDisplayIssueEntry from './MediaOwnerDisplayIssueEntry';

type Props = {
    details: MediaOwnerIssues;
};

const MediaOwnerRow: React.FC<Props> = ({ details }) => {
    const { enabled, mediaOwnerName, issues, mediaOwnerId } = details;
    const colour = useMemo<BoxProps['bgcolor']>(
        () => (enabled ? 'grey.500' : 'grey.400'),
        [enabled],
    );

    const open = useBoolean(false);
    const openable = useMemo(() => enabled && issues.length > 0, [enabled, issues.length]);

    return (
        <>
            <Box
                bgcolor={colour}
                py={1}
                px={2}
                borderRadius={1}
                color="white"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={openable ? { cursor: 'pointer' } : undefined}
                onClick={openable ? open.toggle : undefined}
            >
                <Typography variant="subtitle1">
                    {mediaOwnerName} {enabled && ` (${issues.length})`}
                </Typography>
                {openable && <KeyboardArrowDown css={cssRotation({ rotate: open.value })} />}
            </Box>
            <Collapse in={open.value}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow isHeader>
                                <TableCell isHeader>
                                    {useCommonTranslation(
                                        'Modules.Main.DisplayIssues.table.displayNameCol',
                                    )}
                                </TableCell>
                                <TableCell isHeader>
                                    {useCommonTranslation(
                                        'Modules.Main.DisplayIssues.table.startDateCol',
                                    )}
                                </TableCell>
                                <TableCell isHeader>
                                    {useCommonTranslation(
                                        'Modules.Main.DisplayIssues.table.startTimeCol',
                                    )}
                                </TableCell>
                                <TableCell isHeader>
                                    {useCommonTranslation(
                                        'Modules.Main.DisplayIssues.table.endDateCol',
                                    )}
                                </TableCell>
                                <TableCell isHeader>
                                    {useCommonTranslation(
                                        'Modules.Main.DisplayIssues.table.endTimeCol',
                                    )}
                                </TableCell>
                                <TableCell isHeader>
                                    {useCommonTranslation(
                                        'Modules.Main.DisplayIssues.table.downtimeCol',
                                    )}
                                </TableCell>
                                <TableCell isHeader>
                                    {useCommonTranslation(
                                        'Modules.Main.DisplayIssues.table.incidentIdCol',
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {issues.map(issue => (
                            <TableRow
                                key={`${issue.displayId}-${issue.incidentId}-${mediaOwnerId}`}
                            >
                                <MediaOwnerDisplayIssueEntry issue={issue} />
                            </TableRow>
                        ))}
                    </Table>
                </TableContainer>
            </Collapse>
        </>
    );
};

export default MediaOwnerRow;
