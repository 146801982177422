import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { uiSelectors } from 'c-wrapper/Slices/UI';
import SentryInitialiser from 'c-wrapper/Components/SentryInitialiser';
import MetaLayout from 'c-wrapper/Components/MetaLayout';
import PostAuthLayout from 'c-wrapper/Components/PostAuthLayout';
import config, { PageEntityConfig } from 'c-config';
import { TranslationPath } from 'c-translation';
import { BadRequest } from 'c-components';
import { PageContext } from 'c-wrapper/constants';
import GetPage from './GetPage';
import GetEntityPage from './GetEntityPage';

const PostAuth: React.FC = () => {
    const metaTitle = useSelector(uiSelectors.metaTitle);

    const crashPage = useMemo(() => <BadRequest statusCode={500} />, []);
    return (
        <>
            {import.meta.env.VITE_SENTRY_DSN && <SentryInitialiser />}
            {/* <GlobalDataListener */}
            {/*    entitySchemas={commonSchemas} */}
            {/*    getChannelName={authSelectors.privateChannelName} */}
            {/* /> */}
            <MetaLayout metaTitle={metaTitle as TranslationPath}>
                <PostAuthLayout>
                    <Switch>
                        {Object.entries(config.pages).map(([, page]) => (
                            <Route
                                key={page.id}
                                path={page.systemSearch.route}
                                exact={page.systemSearch.routeExact ?? true}
                            >
                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                {/* @ts-ignore */}
                                <Sentry.ErrorBoundary fallback={crashPage}>
                                    {page.systemSearch.type === 'entity' ? (
                                        <PageContext.Provider
                                            value={{
                                                page,
                                                entityPageConfig: page as PageEntityConfig<any>,
                                            }}
                                        >
                                            <GetEntityPage page={page as PageEntityConfig<any>} />
                                        </PageContext.Provider>
                                    ) : (
                                        <PageContext.Provider
                                            value={{
                                                page,
                                            }}
                                        >
                                            <GetPage page={page} />
                                        </PageContext.Provider>
                                    )}
                                </Sentry.ErrorBoundary>
                            </Route>
                        ))}
                        <Route path="/*">
                            <BadRequest statusCode={404} />
                        </Route>
                    </Switch>
                </PostAuthLayout>
            </MetaLayout>
        </>
    );
};

export default PostAuth;
