import React, { useCallback, useMemo } from 'react';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { useBoolean } from 'react-hanger';
import to from 'await-to-js';
import { Translate, TranslationPath, useCommonTranslation } from 'c-translation';
import { NetworkRequestState } from '@uniled/data-layer';
import useCampaignWrapperState from 'c-main/Components/Campaign/CampaignReports/useCampaignWrapperState';
import { Box, DialogContentText, IconButton } from '@mui/material';
import { Download } from '@mui/icons-material';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { AutoGrid, Button, Dialog, FlashMessage } from 'c-components';
import { PermissionName } from '@uniled/api-sdk';
import PCASettingsDialog from './PCASettingsDialog';

const reportsDownloadPerms = [PermissionName.ReportingPcareportDownload];

const PCATabControls: React.FC<{ id: number }> = ({ id }) => {
    const { start, isLoading, hasSucceeded, hasFailed, requestState, reset } = useAPIClientRequest(
        apiClient.Entities.Campaign.downloadPCA,
    );
    const { toggle, value: ShowDialog, setFalse } = useBoolean(false);

    const onDlOpenClick = useCallback(
        e => {
            e.stopPropagation();
            toggle();
        },
        [toggle],
    );
    const onDlClick = useCallback(
        async e => {
            e.stopPropagation();
            await to(start(id));
        },
        [start, id],
    );
    const onClose = useCallback(
        e => {
            e.stopPropagation();
            setFalse();
            reset();
        },
        [reset, setFalse],
    );
    const onBoxClick = useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const downloadBtnPath = useMemo<TranslationPath>(() => {
        switch (requestState) {
            case NetworkRequestState.Error:
                return 'Modules.Main.Campaigns.Overview.Reports.exportPca.exportPcaBtnLabelFailed';
            case NetworkRequestState.InProgress:
                return 'Modules.Main.Campaigns.Overview.Reports.exportPca.exportPcaBtnLabelLoading';
            case NetworkRequestState.Idle:
            default:
                return 'Modules.Main.Campaigns.Overview.Reports.exportPca.exportPcaBtnLabel';
        }
    }, [requestState]);

    const { sandboxPermissions, hasPCAData } = useCampaignWrapperState(id);

    return (
        <Box display="flex" alignItems="center">
            {useCommonTranslation('Modules.Main.Campaigns.Overview.Tabs.reporting')}
            <Box onClick={onBoxClick}>
                {hasPCAData && (
                    <IfHasAllPermissions permissions={reportsDownloadPerms}>
                        <IconButton onClick={onDlOpenClick}>
                            <Download />
                        </IconButton>
                    </IfHasAllPermissions>
                )}
                <IfHasAllPermissions permissions={[PermissionName.ReportingPcareportRead]}>
                    <PCASettingsDialog id={id} />
                </IfHasAllPermissions>
                <Dialog
                    title="Modules.Main.Campaigns.Overview.Reports.exportPca.exportPcaTitle"
                    onClose={onClose}
                    show={ShowDialog}
                    content={
                        <>
                            <DialogContentText>
                                {useCommonTranslation(
                                    'Modules.Main.Campaigns.Overview.Reports.exportPca.exportPcaDescription',
                                )}
                            </DialogContentText>
                            <DialogContentText>
                                {useCommonTranslation(
                                    'Modules.Main.Campaigns.Overview.Reports.exportPca.exportPcaDescription2',
                                )}
                            </DialogContentText>
                            <AutoGrid spacing={2} xs={12} sx={{ mt: 2 }}>
                                {hasFailed && (
                                    <FlashMessage status="error" textAlign="center">
                                        <Translate path="Modules.Main.Campaigns.Overview.Reports.exportPca.exportFailed" />
                                    </FlashMessage>
                                )}
                                {hasSucceeded && (
                                    <FlashMessage status="success" textAlign="center">
                                        <Translate path="Modules.Main.Campaigns.Overview.Reports.exportPca.exportSuccess" />
                                    </FlashMessage>
                                )}
                                <Box textAlign="center">
                                    {!hasSucceeded && (
                                        <Button onClick={onDlClick} disabled={isLoading}>
                                            <Translate path={downloadBtnPath} />
                                        </Button>
                                    )}
                                    {hasSucceeded && (
                                        <Button onClick={onClose} disabled={isLoading}>
                                            <Translate path="Modules.Main.Campaigns.Overview.Reports.exportPca.exportPcaBtnLabelClose" />
                                        </Button>
                                    )}
                                </Box>
                            </AutoGrid>
                        </>
                    }
                    removeForm
                />
            </Box>
        </Box>
    );
};

export default PCATabControls;
