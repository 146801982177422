import React from 'react';
import { Box } from '@mui/material';
import { formatBytes } from 'c-lib';

type Props = { value: string };
const EntityDataFileSizeColumn: React.FC<Props> = ({ value }) => {
    return <Box>{formatBytes(+value)}</Box>;
};

export default EntityDataFileSizeColumn;
