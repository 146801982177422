import React, { useCallback, useMemo, useRef } from 'react';
import {
    Box,
    Chip,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import cx from 'classnames';
import { Close, Menu as MenuIcon, Search } from '@mui/icons-material';
import { initials, userName } from 'c-admin/Lib';
import { useDispatch, useSelector } from 'react-redux';
import { useCommonTranslation } from 'c-translation';
import { Avatar, IconTopBarBrandLogo, MuiRouterLink, TextField } from 'c-components';
import { PostAuthRoutes } from 'c-routes';
import { useLogout } from 'c-auth-module/Hooks';
import { usePostAuthHome } from 'c-wrapper/Components/CommonWrapper';
import { useUserImpersonation } from 'c-hooks';
import { useHistory } from 'react-router-dom';
import { isRunningOnMac, isRunningStorybook, isRunningTests } from 'c-is-running';
import { authSelectors } from 'c-auth-module/Slices';
import TopBarSystemSearch from './TopBarSystemSearch';
import { systemSearchSelectors, systemSearchThunks } from '../../../Modules/SystemSearch';

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    avatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        cursor: 'pointer',
    },
    absoluteDrawerIcon: {
        position: 'absolute',
    },
    invisibleSystemSearch: {
        visibility: 'hidden',
    },
}));

type Props = {
    open: boolean;
    handleOpen: () => void;
    mobileDrawer?: boolean;
};

const AppBarToolbar: React.FC<Props> = ({ open, handleOpen, mobileDrawer = false }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const $user = useSelector(authSelectors.authenticatedUser);
    const isSystemSearchOpen = useSelector(systemSearchSelectors.isOpen);
    const searchTerm = useSelector(systemSearchSelectors.searchTerm);
    const defaultSearchPlaceholder = useCommonTranslation('SystemSearch.defaultSearchPlaceholder');
    const { logout } = useLogout();
    const postAuthHome = usePostAuthHome();
    const { isImpersonating } = useUserImpersonation();

    const inputRef = useRef<HTMLInputElement>();

    const userNameString = useMemo(() => userName($user), [$user]);
    const userInitials = useMemo(() => {
        return initials(userNameString).substr(0, 2);
    }, [userNameString]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl],
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const signOut = useCallback(() => {
        setAnchorEl(null);
        logout();
    }, [setAnchorEl, logout]);

    const myProfileClick = useCallback(() => {
        history.push(PostAuthRoutes.Users.MyProfile);
    }, [history]);

    const openSystemSearch = useCallback(() => {
        if (!isSystemSearchOpen) {
            if (inputRef?.current) {
                inputRef.current?.blur();
            }
            dispatch(systemSearchThunks.open());
        }
    }, [dispatch, isSystemSearchOpen, inputRef]);

    const closeSystemSearch = useCallback(() => {
        if (isSystemSearchOpen) {
            dispatch(systemSearchThunks.close());
        }
    }, [dispatch, isSystemSearchOpen]);
    const closeThen = useCallback(
        (afterClose: () => void) => () => {
            handleClose();
            afterClose();
        },
        [handleClose],
    );
    const t = useCommonTranslation();

    return (
        <>
            <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%" position="relative">
                    <Box
                        display="flex"
                        alignItems="center"
                        className={cx({ [classes.absoluteDrawerIcon]: mobileDrawer })}
                        pl={!mobileDrawer ? 1.5 : undefined}
                    >
                        {!open && (
                            <IconButton
                                id="toggle-navigation-drawer"
                                color="secondary"
                                onClick={handleOpen}
                                edge="start"
                                className={cx(classes.menuButton, 'navigation-drawer-closed')}
                            >
                                <MenuIcon color="inherit" />
                            </IconButton>
                        )}
                    </Box>
                    {mobileDrawer && (
                        <>
                            <Box
                                mx="auto"
                                className={cx({
                                    [classes.hide]: open,
                                })}
                            >
                                <MuiRouterLink to={postAuthHome}>
                                    <IconTopBarBrandLogo />
                                </MuiRouterLink>
                            </Box>
                            <Box
                                position="absolute"
                                right={0}
                                height="100%"
                                display="flex"
                                alignItems="center"
                            >
                                <Box>
                                    <IconButton
                                        onClick={
                                            !isSystemSearchOpen
                                                ? openSystemSearch
                                                : closeSystemSearch
                                        }
                                    >
                                        {!isSystemSearchOpen && <Search />}
                                        {isSystemSearchOpen && <Close />}
                                    </IconButton>
                                </Box>
                                {/* <UserPagePresence /> */}
                            </Box>
                        </>
                    )}
                    {!mobileDrawer && !isSystemSearchOpen && (
                        <Box display="flex" alignItems="center">
                            <Box mr={2}>
                                <TextField
                                    id="system-search"
                                    variant="standard"
                                    whiteBackdrop={false}
                                    placeholder={
                                        searchTerm.length > 0
                                            ? searchTerm
                                            : defaultSearchPlaceholder
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Chip
                                                    label={isRunningOnMac ? '⌘K' : 'Ctrl+K'}
                                                    size="small"
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onFocus={openSystemSearch}
                                    ref={inputRef}
                                    className={cx({
                                        [classes.invisibleSystemSearch]: isSystemSearchOpen,
                                    })}
                                />
                            </Box>
                            {/* <Box */}
                            {/*    color="secondary.main" */}
                            {/* > */}
                            {/*    <OpenCRMTour /> */}
                            {/* </Box> */}
                            {/* <UserPagePresence /> */}
                            <Avatar
                                id="user-menu"
                                className={cx([
                                    classes.avatar,
                                    { open: !!anchorEl, closed: !anchorEl },
                                ])}
                                onClick={handleClick}
                            >
                                <Typography variant="body1">{userInitials}</Typography>
                            </Avatar>
                        </Box>
                    )}
                </Box>
            </Toolbar>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                // getContentAnchorEl={null}
                onClose={handleClose}
                // https://app.clickup.com/t/nz80qw
                container={isRunningTests || isRunningStorybook ? () => anchorEl : undefined}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
            >
                <MenuItem onClick={closeThen(myProfileClick)} className="my-profile">
                    {useCommonTranslation('Pages.MyProfile.title')}
                </MenuItem>
                {!isImpersonating && (
                    <MenuItem onClick={closeThen(signOut)} className="sign-out">
                        {t('Navigation.logoutLabel')}
                    </MenuItem>
                )}
            </Menu>
            <TopBarSystemSearch />
        </>
    );
};

export default AppBarToolbar;
