import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { numberOfDays } from 'c-lib';
import { AutoGrid, CurrencyFormat } from 'c-components';
import { useCampaignLineItemCost } from 'c-main/Hooks';
import { Typography, TypographyProps } from '@mui/material';
import { Translate } from 'c-translation';
import { CampaignFormNames } from 'c-main/Types';

type Props = {
    lineItemIds: number[];
    hideIfZero?: boolean;
    showDiff?: boolean;
    originalPrice?: number;
};

const TotalCost: React.FC<Props> = ({
    lineItemIds,
    hideIfZero = false,
    showDiff = false,
    originalPrice,
}) => {
    const { watch } = useFormContext();
    const days = watch(CampaignFormNames.lineItemDays);
    const buyerId = watch(CampaignFormNames.buyer);
    const startDate = watch(CampaignFormNames.start);
    const endDate = watch(CampaignFormNames.end);
    const noOfDays = useMemo(
        () => numberOfDays(new Date(startDate), new Date(endDate)),
        [startDate, endDate],
    );

    const { getCostMultiple } = useCampaignLineItemCost(+buyerId);
    const { total, capped } = getCostMultiple(lineItemIds, days, noOfDays);

    const priceDiff = useMemo(() => {
        if (!showDiff || originalPrice == null) {
            return 0;
        }

        return capped - originalPrice;
    }, [showDiff, originalPrice, capped]);

    const priceDiffColor = useMemo<TypographyProps['color']>(() => {
        if (priceDiff > 0) return 'success.main';
        if (priceDiff < 0) return 'error.main';

        return null;
    }, [priceDiff]);

    if (total === 0 && hideIfZero) {
        return null;
    }

    return (
        <AutoGrid columnGap={1} spacing={0} justifyContent="flex-end" sx={{ pt: 2 }}>
            <Typography>
                <Translate path="Modules.Main.Campaigns.Create.Costing.total" />
            </Typography>
            {total > capped && (
                <Typography sx={{ textDecoration: 'line-through' }}>
                    <CurrencyFormat value={total} />
                </Typography>
            )}
            <Typography>
                <CurrencyFormat value={capped} />
            </Typography>

            {priceDiff !== 0 && (
                <>
                    <Typography color={priceDiffColor}>
                        <CurrencyFormat value={priceDiff} />
                    </Typography>
                </>
            )}
        </AutoGrid>
    );
};

export default TotalCost;
