import React, { useCallback, useEffect, useState } from 'react';
import { Box, CardContent } from '@mui/material';
import { PageEntityConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { Buyer, PermissionName } from '@uniled/api-sdk';
import { buyerIcon } from 'c-main/icons';
import { useAPIClientRequest, useEntityPage } from 'c-hooks';
import {
    Alert,
    AllEntitiesDropdown,
    AutoGrid,
    Button,
    LoadingSpinner,
    TransparentCard,
} from 'c-components';
import { BuyerOverviewDetailTabs, BuyerOverviewEdit } from 'c-main/Components';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { useEntityData } from 'c-data';
import ReactGA from 'react-ga';
import { useCommonTranslation } from 'c-translation';
import ApiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { NetworkRequestState } from '@uniled/data-layer';

const BuyerOverviewPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { id } = useEntityPage();
    const { getById } = useEntityData<Buyer>('Buyer');
    const buyer = getById({ id });
    const [audienceSource, setAudienceSource] = useState<number | null>(
        buyer && buyer.audienceSource ? buyer.audienceSource.id : null,
    );
    const t = useCommonTranslation();
    const { start, requestState, error } = useAPIClientRequest(
        ApiClient.Entities.Buyer.attachAudienceSource,
    );
    const attachAudienceSource = useCallback(async () => {
        await to(start(id, audienceSource));
    }, [audienceSource, id, start]);
    const handleAudienceSourceChange = useCallback(
        (value: number | null) => {
            setAudienceSource(value);
        },
        [setAudienceSource],
    );
    return (
        <TransparentCard
            title={
                <AutoGrid spacing={1}>
                    {buyer.name}
                    <IfHasAllPermissions permissions={[PermissionName.UniledportalBuyerUpdate]}>
                        <BuyerOverviewEdit id={id} />
                    </IfHasAllPermissions>
                </AutoGrid>
            }
            subTitle={buyer.market.name}
        >
            <AutoGrid xs={4} gap={2}>
                <AllEntitiesDropdown
                    entityName="Displays_AudienceSource"
                    labelField="name"
                    useLocalEntityData
                    onChange={handleAudienceSourceChange}
                    value={audienceSource}
                    valueField="id"
                    label={t('Modules.Main.Buyer.audienceSourceLabel')}
                    disabled={requestState === NetworkRequestState.InProgress}
                />
                <Box pt={1}>
                    <Button
                        disabled={
                            requestState === NetworkRequestState.InProgress ||
                            !audienceSource ||
                            audienceSource === buyer.audienceSource?.id
                        }
                        onClick={attachAudienceSource}
                    >
                        {requestState === NetworkRequestState.InProgress ? (
                            <LoadingSpinner />
                        ) : (
                            t('Modules.Main.Buyer.save')
                        )}
                    </Button>
                </Box>
            </AutoGrid>
            {error && <Alert severity="error">{String(error)}</Alert>}
            <CardContent>
                <BuyerOverviewDetailTabs id={id} />
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageEntityConfig<Buyer> = {
    id: 'BuyerOverviewPage',
    component: BuyerOverviewPage,
    systemSearch: {
        title: undefined,
        type: 'entity',
        icon: buyerIcon,
        route: PostAuthRoutes.Buyer.BuyerOverview,
        prefix: 'SystemSearch.prefixes.buyer',
        prefixDescription: 'SystemSearch.prefixDescriptions.buyer',
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Main.Buyer.List.metaTitle', link: PostAuthRoutes.Buyer.BuyerList },
        ],
        entityName: 'Buyer',
        searchColumn: ['name'],
        defaultIncludes: ['market', 'audienceSource'],
        nameGen: (entity, id) => entity?.name ?? String(id),
        permissions: [
            PermissionName.UniledportalBuyerRead,
            PermissionName.UniledportalBuyerUpdate,
            PermissionName.UniledportalBuyerCreate,
        ],
    },
};

export default setup;
