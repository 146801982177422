import React from 'react';
import { Box } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { useIsMobileView } from 'c-hooks';
import { PasswordRequirements, PasswordStrengthErrors } from '../../Types';
import PasswordStrengthLine from './PasswordStrengthLine';

type Props = {
    errors: PasswordStrengthErrors;
};

const PasswordStrength: React.FunctionComponent<Props> = ({ errors }) => {
    const matches = !useIsMobileView();
    const lenOk = errors.indexOf(PasswordRequirements.Length) === -1;
    const matchOk = errors.indexOf(PasswordRequirements.Match) === -1;
    const numberOk = errors.indexOf(PasswordRequirements.Number) === -1;
    const lowerOk = errors.indexOf(PasswordRequirements.LowerCase) === -1;
    const upperOk = errors.indexOf(PasswordRequirements.UpperCase) === -1;
    const specialOk = errors.indexOf(PasswordRequirements.SpecialCharacter) === -1;

    return (
        <Box
            display="flex"
            flexDirection="column"
            mt={4}
            minWidth="32.4"
            mx={matches ? 'auto' : '0px'}
        >
            <PasswordStrengthLine
                passed={matchOk}
                text={useCommonTranslation('PasswordStrength.match')}
            />
            <PasswordStrengthLine
                passed={lenOk}
                text={useCommonTranslation('PasswordStrength.minLength', {
                    minLength: import.meta.env.VITE_MIN_PASSWORD_LENGTH,
                })}
            />
            <PasswordStrengthLine
                passed={numberOk}
                text={useCommonTranslation('PasswordStrength.number')}
            />
            <PasswordStrengthLine
                passed={lowerOk && upperOk}
                text={useCommonTranslation('PasswordStrength.case')}
            />
            <PasswordStrengthLine
                passed={specialOk}
                text={useCommonTranslation('PasswordStrength.specialChar')}
            />
        </Box>
    );
};

export default PasswordStrength;
