import React, { PropsWithChildren, useMemo } from 'react';
import { PermissionName } from '@uniled/api-sdk';
import { useUserPermissions } from 'c-auth-module/Hooks';
import ConditionalDebugWrapper from 'c-auth-module/Components/ConditionalDebugWrapper';
import { ConditionalDebugWrapperProps } from '../ConditionalDebugWrapper/ConditionalDebugWrapper';

type Props = PropsWithChildren<
    {
        permissions?: PermissionName[];
        // Only use if children are already conditionally shown depending on permissions.
        forceShow?: boolean;
    } & Omit<ConditionalDebugWrapperProps, 'show' | 'tooltipLines'>
>;

const IfHasSomePermissions: React.FC<Props> = ({ permissions, forceShow, ...rest }) => {
    const { hasSome } = useUserPermissions();
    const show = useMemo(
        () => forceShow || permissions == null || permissions?.length <= 0 || hasSome(permissions),

        [hasSome, permissions, forceShow],
    );

    const lines = useMemo(() => ['Has One Of:', ...(permissions ?? []).map(p => p)], [permissions]);

    const hideDebugWrapper = useMemo(
        () => permissions == null || permissions?.length <= 0,
        [permissions],
    );
    if (hideDebugWrapper) return <>{rest.children}</>;

    return <ConditionalDebugWrapper show={show} tooltipLines={lines} {...rest} />;
};

export default React.memo(IfHasSomePermissions);
