import React, { useMemo } from 'react';
import { BaseEntity, PermissionName } from '@uniled/api-sdk';
import { useBoolean } from 'react-hanger';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import CreateEntityDialog from './CreateEntityDialog';
import { EntityDialogProps } from '../EntityDialog/EntityDialog';
import Button from '../../Forms/Button';

const CreateEntityButton = <Entity extends BaseEntity>({
    buttonLabel,
    show,
    onClose,
    permissions,
    ...props
}: Omit<EntityDialogProps<Entity>, 'show'> & {
    buttonLabel: React.ReactNode;
    show?: boolean;
    permissions?: PermissionName[];
}) => {
    const { hasAll } = useUserPermissions();
    const addEntity = useBoolean(false);

    const hasPerms = useMemo(() => hasAll(permissions), [hasAll, permissions]);

    if (!hasPerms) return null;

    return (
        <>
            <IfHasAllPermissions permissions={permissions}>
                <Button
                    size="small"
                    onClick={addEntity.toggle}
                    id={`new-${props.entityName as string}`}
                >
                    {buttonLabel}
                </Button>
            </IfHasAllPermissions>
            <CreateEntityDialog<Entity>
                {...props}
                onClose={onClose ?? addEntity.setFalse}
                show={show ?? addEntity.value}
            />
        </>
    );
};

export default CreateEntityButton;
