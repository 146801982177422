import { formatISO } from 'date-fns';
import { BaseSliceThunks } from 'c-data';
import { SpotlightRecentResource } from '@uniled/api-sdk';
import { normaliseDataThunks, PaginatedEntities, SearchPayload } from '@uniled/data-layer';
import { commonSchemas } from 'c-data';
import { CommonThunk } from 'c-types';
import { SystemSearchRecentResourceActions } from './SystemSearchRecentResource-slice';

const LIST_KEY = 'SpotlightRecentResource_LIST';

const getList = (): SpotlightRecentResource[] => {
    const list = window.localStorage.getItem(LIST_KEY);

    if (typeof list === 'string') {
        try {
            const parsed = JSON.parse(list);
            if (Array.isArray(parsed)) {
                return parsed;
            }
            // eslint-disable-next-line no-empty
        } catch (e) {}
    }
    return [];
};

const setList = (list: SpotlightRecentResource[]) => {
    window.localStorage.setItem(LIST_KEY, JSON.stringify(list));
    return list;
};

export default {
    clearHistory: (): CommonThunk => async dispatch => {
        const list = getList();
        list?.forEach(item => {
            dispatch(SystemSearchRecentResourceActions.finishedDeletingById({ id: item.id }));
        });
        setList([]);
    },
    create:
        (data: Partial<SpotlightRecentResource>): CommonThunk =>
        async dispatch => {
            const entityData: SpotlightRecentResource = {
                ...(data as SpotlightRecentResource),
                id: Math.random(),
                created_at: formatISO(new Date()),
            };

            if (entityData.type === 'entity') {
                entityData.resourceIdentifier = `${entityData.resourceIdentifier}-${entityData.entityName}${entityData.entityId}`;
            }

            const list = getList().filter(
                res => res.resourceIdentifier !== entityData.resourceIdentifier,
            );
            list.unshift(entityData);

            setList(list.slice(0, 30));

            dispatch(
                normaliseDataThunks.updateWithNormalisedData(
                    commonSchemas.SpotlightRecentResource,
                    entityData,
                ),
            );
            dispatch(SystemSearchRecentResourceActions.finishedCreating(entityData));
        },
    deleteById:
        (id: number): CommonThunk =>
        async dispatch => {
            const list = getList().filter(res => res.id !== id);
            setList(list);

            dispatch(SystemSearchRecentResourceActions.finishedDeletingById({ id }));
        },
    getPaginatedList:
        (tag: string, searchPayload: SearchPayload<SpotlightRecentResource>): CommonThunk =>
        async dispatch => {
            const list = getList();
            dispatch(
                normaliseDataThunks.updateWithNormalisedData(
                    [commonSchemas.SpotlightRecentResource],
                    list,
                ),
            );
            const response: PaginatedEntities<SpotlightRecentResource> = {
                data: list.slice(0, searchPayload.perPage),
                meta: {
                    pagination: {
                        total: list.length,
                        total_pages: 1,
                        current_page: searchPayload.page,
                        per_page: searchPayload.perPage,
                        count: list.length,
                    },
                },
            };
            dispatch(
                SystemSearchRecentResourceActions.finishedLoadingPaginatedList({
                    ...response,
                    tag,
                    wasCursorRequest: false,
                }),
            );
        },
} as Partial<BaseSliceThunks<SpotlightRecentResource>> & any;
