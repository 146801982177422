import React from 'react';
import { ListItemIcon as MuiListItemIcon, ListItemIconProps } from '@mui/material';

type Props = ListItemIconProps;

const ListItemIcon: React.FC<Props> = ({ sx, ...props }) => (
    <MuiListItemIcon sx={{ color: 'text.primary', ...sx }} {...props} />
);

export default ListItemIcon;
