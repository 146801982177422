import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
    ElasticSearchHistogramInterval,
    ElasticSearchHistogramResponse,
    EsPCAReportField,
    EsPCAReportFilterField,
    PCAFrameDetails,
    PCAReportMetric,
} from '@uniled/api-sdk';
import {
    ReportConfig,
    ReportDateTimeframe,
    ReportProps,
    ReportType,
    ReportWrapperProps,
    TableReportCell,
    TableReportHeader,
    TableReportRow,
} from 'c-reports/Types';
import { apiClient } from 'c-data';
import {
    differenceInDays,
    format,
    isAfter,
    isBefore,
    isSameDay,
    subWeeks,
    startOfDay,
} from 'date-fns';
import { Box, Tooltip, Typography, TypographyProps } from '@mui/material';
import { ReportWrapper } from 'c-reports/Components';
import { formatHour, secondsToHms } from 'c-lib';
import { datesInRange } from 'c-main/Lib';
import { useCommonTranslation } from 'c-translation';
import { Timer } from '@mui/icons-material';
import { useDateUtils } from 'c-hooks';
import { NumberFormatWhole } from 'c-components';
import { hourlyHistogramToOldDataStructure } from 'c-main/Components/Campaign/CampaignReports/ElasticSearch/HistogramTransformers';
import { useAtom } from 'jotai/index';
import { atom_DateRange } from 'c-displays/atoms';

type Props = {
    campaignId: number;
    metric: PCAReportMetric;
    field: string;
    frame: PCAFrameDetails;
    minDate: Date;
    maxDate: Date;
    environment: string;
};

const hours = Array.from(Array(24).keys());
const dayDateFormat = 'yyyy-MM-dd';

type Totals = {
    actual: number;
    expected: number;
    receivedOutOfSchedule: number;
    receivedUnbooked: number;
};

const EsFrameByHourWeekly: React.FC<Props> = ({
    frame,
    metric,
    maxDate,
    minDate,
    campaignId,
    field,
    environment,
}) => {
    const { formatDateString } = useDateUtils();
    const [data, setData] = useState<ElasticSearchHistogramResponse>(null);
    const [currentStartDate, setCurrentStart] = useState<Date>(null);
    const [currentEndDate, setCurrentEnd] = useState<Date>(null);
    const t = useCommonTranslation();
    const transformedData = useMemo(
        () =>
            hourlyHistogramToOldDataStructure({
                metric,
                data,
                groupByField: false,
                onlyHourNumber: true,
            }),
        [metric, data],
    );
    const fetchData: ReportConfig<ElasticSearchHistogramResponse>['fetchData'] = useCallback(
        (start, end) =>
            apiClient.ReportsElasticSearch.histogram({
                campaignId,
                interval: ElasticSearchHistogramInterval.Hour,
                startDate: `${format(start, 'yyyy-MM-dd')} 00:00:00`,
                endDate: `${format(end, 'yyyy-MM-dd')} 23:59:59`,
                field: EsPCAReportField.FrameId,
                environment,
                fieldFilters: { [EsPCAReportFilterField.FrameId]: [frame?.frameId] },
            }),
        [campaignId, frame?.frameId, environment],
    );
    const onDataUpdated: ReportConfig<ElasticSearchHistogramResponse>['onDataUpdated'] =
        useCallback((data, start, end) => {
            setData(data);

            // make sure we don't needlessly update the references to the date objects
            setCurrentStart(current => (!isSameDay(current, start) ? start : current));
            setCurrentEnd(current => (!isSameDay(current, end) ? end : current));
        }, []);

    const initialStartDate = useMemo(() => {
        const now = new Date();

        // if now is before the campaign start
        if (isBefore(now, minDate)) return minDate;

        // if now is after the max date (campaign has ended)
        if (isAfter(now, maxDate)) return maxDate;

        /**
         *  we want two default to seeing a two week period
         *  The current week should be the second week shown
         */

        const weekAgo = subWeeks(now, 1);

        if (isBefore(weekAgo, minDate)) return minDate;

        return weekAgo;
    }, [minDate, maxDate]);

    const [, setAtomTimeFrameValue] = useAtom(atom_DateRange);
    useEffect(() => {
        if (currentStartDate && currentEndDate) {
            setAtomTimeFrameValue({ startDate: currentStartDate, endDate: currentEndDate });
        }
    }, [currentStartDate, currentEndDate, setAtomTimeFrameValue]);

    const reportWrapperProps = useMemo<ReportWrapperProps<any>>(
        () => ({
            fetchData,
            removeLoadingBar: true,
            onDataUpdated,
            initialStartDate,
            initialEndDate: maxDate,
            reportChartWrapperProps: { height: 500 },
            dateSettings: {
                timeframe: ReportDateTimeframe.BiWeekly,
                minDate,
                maxDate,
                availableTimeframes: [
                    ReportDateTimeframe.Weekly,
                    ReportDateTimeframe.BiWeekly,
                    ReportDateTimeframe.Monthly,
                    ReportDateTimeframe.Range,
                ],
                onDatesUpdated: (timeframe, start, end) => {
                    setAtomTimeFrameValue({ startDate: start, endDate: end });
                },
            },
        }),
        [fetchData, onDataUpdated, initialStartDate, maxDate, minDate, setAtomTimeFrameValue],
    );
    const allDatesInRange = useMemo(
        () =>
            datesInRange({
                start: currentStartDate,
                end: currentEndDate,
                returnAsDate: true,
            }) as Date[],
        [currentStartDate, currentEndDate],
    );
    const currentDate = useMemo(() => new Date(), []);

    const tableHeaders = useMemo<TableReportHeader[]>(
        () => [
            { content: '', sx: {} },
            ...allDatesInRange.map(day => ({
                content: (
                    <Box fontSize=".8em" lineHeight="1.2em">
                        <Box sx={{ textTransform: 'uppercase' }}>
                            {formatDateString({ date: day, dateFormatString: 'EEE' })}
                        </Box>
                        <Box sx={{ whiteSpace: 'nowrap' }}>
                            {formatDateString({ date: day, dateFormatString: 'do MMM' })}
                        </Box>
                    </Box>
                ),
                sx: { borderStyle: 'ridge' },
            })),
        ],
        [allDatesInRange, formatDateString],
    );

    const tableHeadersStickers = useMemo<TableReportHeader[]>(
        () => [
            { content: '' },
            ...allDatesInRange.map(day => {
                const weeks =
                    differenceInDays(startOfDay(day), startOfDay(minDate)) % 7 == 0 &&
                    differenceInDays(startOfDay(day), startOfDay(minDate)) >= 0;
                const isToday = differenceInDays(startOfDay(currentDate), startOfDay(day)) === 0;
                const weekNumber = 1 + differenceInDays(startOfDay(day), startOfDay(minDate)) / 7;

                const stickerSx = {
                    backgroundColor: isToday ? 'primary.main' : weeks ? 'grey.300' : '',
                    color: isToday ? 'white' : 'primary.main',
                    borderTopLeftRadius: '15px 15px',
                    borderTopRightRadius: '15px 15px',
                };

                return {
                    content: (
                        <Box display="flex" justifyContent="center" sx={stickerSx} fontSize="0.8em">
                            {isToday
                                ? t('Modules.Main.Campaigns.Overview.Reports.stickers.today')
                                : weeks
                                ? `${t(
                                      'Modules.Main.Campaigns.Overview.Reports.stickers.week',
                                  )} ${weekNumber}`
                                : ''}
                        </Box>
                    ),
                };
            }),
        ],
        [allDatesInRange, currentDate, minDate, t],
    );

    /**
     * Just making a simple lookup object for the total number of actual + expected plays per day
     *
     * {
     *      '2020-01-01': {actual: 1234, expected: 1000}
     * }
     */
    const totalByDay = useMemo(
        () =>
            Object.entries(transformedData ?? {}).reduce((acc, [day, dayData]) => {
                acc[day] = Object.entries(dayData ?? {}).reduce(
                    (dayAcc, [_, hourData]) => {
                        const [
                            actual,
                            expected, // Skipping the performance value // Skipping received in schedule
                            ,
                            ,
                            receivedOutOfSchedule,
                            receivedUnbooked,
                        ] = hourData;

                        return {
                            ...dayAcc,
                            actual: dayAcc.actual + actual,
                            expected: dayAcc.expected + expected,
                            receivedOutOfSchedule:
                                dayAcc.receivedOutOfSchedule + receivedOutOfSchedule,
                            receivedUnbooked: dayAcc.receivedUnbooked + receivedUnbooked,
                        };
                    },
                    {
                        actual: 0,
                        expected: 0,
                        receivedOutOfSchedule: 0,
                        receivedUnbooked: 0,
                    },
                );
                return acc;
            }, {}),
        [transformedData],
    );

    /**
     * Just making a simple lookup object for numbers on each hour of the days we have data for
     * {
     *     Hours are 0-23
     *     // {date}:{hour}
     *     '2020-01-01:10': {actual: 123, expected: 100}
     * }
     */
    const totalByDayByHour = useMemo(
        () =>
            Object.entries(transformedData).reduce((acc, [day, dayData]) => {
                Object.entries(dayData ?? {}).forEach(([hour, hourData]) => {
                    const [
                        actual,
                        expected, // Skipping the performance value // Skipping received in schedule
                        ,
                        ,
                        receivedOutOfSchedule,
                        receivedUnbooked,
                    ] = hourData;

                    acc[`${day}:${hour}`] = {
                        actual,
                        expected,
                        receivedOutOfSchedule,
                        receivedUnbooked,
                    };
                });
                return acc;
            }, {}),
        [transformedData],
    );

    const tableRows = useMemo<TableReportRow[]>(
        () => [
            {
                content: [
                    // totals per day row
                    {
                        content: (
                            <Typography variant="caption">
                                <strong>
                                    {t(
                                        `Modules.Main.Campaigns.Overview.Reports.frameList.dayOverall`,
                                    )}
                                </strong>
                            </Typography>
                        ),
                        sx: { position: 'sticky', left: 0, bgcolor: 'white' },
                    },
                    ...allDatesInRange.map(day => {
                        const key = format(day, dayDateFormat);
                        return {
                            content: <TotalsContent totals={totalByDay[key]} metric={metric} />,
                            sx: { borderStyle: 'ridge' },
                        };
                    }),
                ],
                sx: {
                    '&:hover': {
                        backgroundColor: 'rgb(241, 246, 251)',
                    },
                },
            },
            // per hour rows
            ...hours.reduce((acc, hour) => {
                const cells: TableReportCell[] = [
                    {
                        content: (
                            <Typography variant="caption" noWrap>
                                <strong>{formatHour(hour)}</strong>
                            </Typography>
                        ),
                        sx: { position: 'sticky', left: 0, bgcolor: 'white' },
                    },
                ];
                allDatesInRange.forEach(day => {
                    const key = `${format(day, dayDateFormat)}:${hour}`;

                    cells.push({
                        content: <TotalsContent totals={totalByDayByHour[key]} metric={metric} />,
                        sx: { borderStyle: 'ridge' },
                    });
                });
                acc.push({
                    content: cells,
                    sx: {
                        '&:hover': {
                            backgroundColor: 'rgb(241, 246, 251)',
                        },
                    },
                });

                return acc;
            }, [] as TableReportRow[]),
        ],
        [allDatesInRange, t, totalByDay, totalByDayByHour, metric],
    );

    const rawData = useMemo(() => {
        const days = allDatesInRange.reduce((acc, curr) => {
            acc.push(format(curr, dayDateFormat));
            return acc;
        }, []);

        const dailyTotals = allDatesInRange.reduce((acc, curr) => {
            const dayKey = format(curr, dayDateFormat);
            const dayTotal = totalByDay[dayKey];

            if (dayTotal) {
                acc.push(`${dayTotal.actual} / ${dayTotal.expected}`);
            } else {
                acc.push('-');
            }
            return acc;
        }, []);

        const byHour = hours.reduce((acc, hour) => {
            const dayData = [formatHour(hour)];
            allDatesInRange.forEach(date => {
                const dateHourKey = `${format(date, dayDateFormat)}:${hour}`;
                const dayHourData = totalByDayByHour?.[dateHourKey];
                if (dayHourData) {
                    dayData.push(
                        `${String(dayHourData.actual ?? '-')} / ${String(
                            dayHourData.expected ?? '-',
                        )}`,
                    );
                } else {
                    dayData.push('-');
                }
            });

            acc.push(dayData);
            return acc;
        }, []);

        return [
            ['', ...days],
            [t(`Modules.Main.Campaigns.Overview.Reports.frameList.dayOverall`), ...dailyTotals],
            ...byHour,
        ];
    }, [allDatesInRange, totalByDayByHour, totalByDay, t]);

    const reportProps = useMemo<ReportProps<unknown>[]>(
        () => [
            {
                type: ReportType.Table,
                namePrefix: `${campaignId}_${metric}_${field}_leaderboard`,
                data: rawData,
                rows: tableRows,
                headerStickers: tableHeadersStickers,
                // rows: [],
                headers: tableHeaders,
                disableVirtualization: true,
                dense: true,
            },
            // } as TableReportProps<any>,
        ],
        [campaignId, field, metric, rawData, tableHeaders, tableHeadersStickers, tableRows],
    );

    return (
        <>
            <Box flex={0.98} overflow="hidden" display="flex" width="100%">
                <ReportWrapper
                    {...reportWrapperProps}
                    reportProps={reportProps}
                    wrapperProps={{
                        sx: {
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            overflow: 'hidden',
                        },
                    }}
                    reportWrapperProps={{ sx: { flex: 1, overflow: 'auto' } }}
                />
            </Box>
        </>
    );
};
const TotalsContent: React.FC<{ totals?: Totals; metric: PCAReportMetric }> = ({
    totals,
    metric,
}) => {
    const t = useCommonTranslation();

    const textColour = useMemo<TypographyProps['color']>(() => {
        if (!totals) return undefined;

        return Math.round(totals.expected) > 0
            ? Math.round(totals.actual) < Math.round(totals.expected)
                ? 'warning.main'
                : 'success.main'
            : Math.round(totals.receivedOutOfSchedule) > 0
            ? '#0185CD'
            : Math.round(totals.receivedUnbooked) > 0
            ? '#848081'
            : Math.round(totals.actual) < Math.round(totals.expected)
            ? 'warning.main'
            : 'success.main';
    }, [totals]);

    const timeTooltip = useMemo(() => {
        if (totals != null && metric === PCAReportMetric.Time) {
            const hmsActual = secondsToHms(totals.actual);
            const hmsExpected = secondsToHms(totals.expected);
            const hmsUnbooked = secondsToHms(totals.receivedUnbooked);
            const hmsUnscheduled = secondsToHms(totals.receivedOutOfSchedule);

            return (
                <Tooltip
                    title={
                        <>
                            <Box>
                                {t(
                                    'Modules.Main.Campaigns.Overview.Reports.frameList.timePlayedTotal',
                                    {
                                        hours: hmsActual.h,
                                        minutes: hmsActual.m,
                                        seconds: hmsActual.s,
                                    },
                                )}
                            </Box>
                            <Box>
                                {t(
                                    'Modules.Main.Campaigns.Overview.Reports.frameList.timePlayedExpected',
                                    {
                                        hours: hmsExpected.h,
                                        minutes: hmsExpected.m,
                                        seconds: hmsExpected.s,
                                    },
                                )}
                            </Box>
                            <Box>
                                {t(
                                    'Modules.Main.Campaigns.Overview.Reports.frameList.timePlayedOutOfSchedule',
                                    {
                                        hours: hmsUnscheduled.h,
                                        minutes: hmsUnscheduled.m,
                                        seconds: hmsUnscheduled.s,
                                    },
                                )}
                            </Box>
                            <Box>
                                {t(
                                    'Modules.Main.Campaigns.Overview.Reports.frameList.timePlayedOutOFHours',
                                    {
                                        hours: hmsUnbooked.h,
                                        minutes: hmsUnbooked.m,
                                        seconds: hmsUnbooked.s,
                                    },
                                )}
                            </Box>
                        </>
                    }
                >
                    <Timer />
                </Tooltip>
            );
        }

        return null;
    }, [metric, t, totals]);
    if (!totals) {
        return (
            <Typography variant="body1">
                {t('Modules.Main.Campaigns.Overview.Reports.frameList.noHourData')}
            </Typography>
        );
    }
    return (
        <Typography
            variant="caption"
            color={textColour}
            sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: '700',
                justifyContent: 'space-between',
            }}
            noWrap
        >
            <>
                <NumberFormatWhole value={totals.actual} /> /{' '}
                <NumberFormatWhole value={totals.expected} />
            </>
            {timeTooltip}
        </Typography>
    );
};

export default EsFrameByHourWeekly;
