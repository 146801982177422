import React from 'react';
import { DataGridProProps, GridColDef } from '@mui/x-data-grid-pro';
import { Typography } from '@mui/material';

export const DataGridsRenderingSpacedOut: Partial<GridColDef> = {
    renderHeader: params => (
        <Typography color="grey.500" variant="body2">
            {params.colDef.headerName}
        </Typography>
    ),
};
export const DataGridsPropsSpacedOut = (props: Partial<DataGridProProps>): DataGridProProps =>
    ({
        ...props,
        columns: [
            ...props.columns,

            /**
             * need this because without it, the right edge of each row will be cut off
             */
            {
                field: '__SOME__SPACER__FIELD__',
                renderCell: () => <div />,
                headerName: '',
                width: 10,
                sortable: false,
                disableColumnMenu: true,
                disableExport: true,
                disableReorder: true,
            } as GridColDef,
        ],
        sx: {
            border: 'none',
            '& .MuiDataGrid-columnHeaders': {
                px: 2,
            },
            '& .MuiDataGrid-cell, .MuiDataGrid-columnHeaders': {
                border: 'none',
                outline: 'none !important',
            },
            '& .MuiDataGrid-row': {
                boxShadow: 4,
                borderRadius: 1,
                ml: 2,
                width: '95%',
            },
            '& .MuiDataGrid-columnSeparator': {
                visibility:
                    props.disableColumnResize || props.disableColumnResize == null
                        ? 'hidden'
                        : undefined,
            },
            '& .MuiDataGrid-virtualScroller': {
                pr: 2,
            },
        },
        getRowSpacing: params => ({
            top: params.isFirstVisible ? 10 : 0,
            // bottom: params.isLastVisible ? 20 : 0,
            // top: 0,
            bottom: 10,
        }),
        disableColumnSelector: true,
        disableRowSelectionOnClick: true,
        disableColumnReorder: true,
        disableColumnPinning: true,
        disableColumnResize: true,
        disableColumnFilter: true,
        hideFooter: true,
        ...props,
    } as unknown as DataGridProProps);

export const dataGridColumnsWithSpacedOutRendering = (columns: DataGridProProps['columns']) =>
    columns.map(col => ({ ...col, ...DataGridsRenderingSpacedOut }));
