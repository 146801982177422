import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { assocPath, path } from 'ramda';
import { atom, useAtom } from 'jotai';
import JSURL from 'jsurl';
import equal from 'fast-deep-equal';
import { LocationDescriptor } from 'history';

function getUrlParamValues(searchString: string) {
    return Object.fromEntries(new URLSearchParams(searchString).entries());
}

const urlData = () =>
    JSURL.tryParse(getUrlParamValues(window.location.search)?.[rootDataKey] ?? {}, {});

const rootDataKey = 'state';

const newLocation = (pathname: string, newVal): LocationDescriptor => ({
    pathname,
    search: `?${rootDataKey}=${JSURL.stringify(newVal)}`,
});

const getValue = (data: Record<string, any>, keyPath: string, defaultValue = null) =>
    path(keyPath.split('.'), data) ?? defaultValue;

// its impossible to listen to browser location 'search' changes so just putting this little hack in
// incrementing the count every time we push new state in and resetting when the path changes
const counter = atom(0);

function useUrlState() {
    const $history = useHistory();
    const { pathname } = useLocation();
    const [count, setCount] = useAtom(counter);

    useEffect(() => {
        setCount(0);
    }, [pathname, setCount]);

    const get = useCallback(
        (keyPath: string, defaultValue = null) => getValue(urlData(), keyPath, defaultValue),
        // want to invalidate the callback when the count changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [count],
    );

    const replace = useCallback(
        (keyPath: string, value) => {
            const pathParts = keyPath.split('.');
            const newVal = assocPath(pathParts, value, urlData());

            $history.replace(newLocation(pathname, newVal));
        },
        // want to invalidate the callback when the count changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [$history, pathname, count],
    );

    const pushMany = useCallback(
        (data: Record<string, any>) => {
            const newLocationData = Object.entries(data).reduce((acc, [keyPath, value]) => {
                if (!equal(getValue(acc, keyPath), value)) {
                    const pathParts = keyPath.split('.');
                    return assocPath(pathParts, value, acc);
                }

                return acc;
            }, urlData());

            if (!equal(urlData(), newLocationData)) {
                $history.push(newLocation(pathname, newLocationData));
            }
            setCount(count + 1);
        },
        [$history, pathname, setCount, count],
    );

    const push = useCallback(
        (keyPath: string, value) => {
            pushMany({ [keyPath]: value });
        },
        [pushMany],
    );

    return useMemo(() => ({ push, pushMany, replace, get }), [get, push, pushMany, replace]);
}

export default useUrlState;
