import React from 'react';
import { Box, Collapse } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { cssRotation } from 'c-lib';
import { AutoGrid, FlashMessage } from 'c-components';
import { useBoolean } from 'react-hanger';
import { useCommonTranslation } from 'c-translation';

type Props = {
    invalidFrames: string[];
};

const BulkAttachInvalidFrames: React.FC<Props> = ({ invalidFrames }) => {
    const invalidListState = useBoolean(false);
    return (
        <FlashMessage status="error">
            <Box
                sx={{
                    cursor: 'pointer',
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={invalidListState.toggle}
            >
                {useCommonTranslation(
                    'Modules.Main.Campaigns.Create.bulkAttachFramesInvalidError',
                    {
                        count: invalidFrames.length,
                    },
                )}
                <KeyboardArrowDown css={cssRotation({ rotate: invalidListState.value })} />
            </Box>
            <Collapse in={invalidListState.value}>
                <AutoGrid spacing={0} xs={12}>
                    {invalidFrames.map(frame => (
                        <Box key={frame}>{frame}</Box>
                    ))}
                </AutoGrid>
            </Collapse>
        </FlashMessage>
    );
};

export default BulkAttachInvalidFrames;
