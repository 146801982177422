import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import AutoGrid from 'c-components/AutoGrid';
import { ControlledFormInput, FormWrapper, OptionSchema, TextField } from 'c-components/Forms';
import { Search } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { useCommonTranslation } from 'c-translation';
import { extractTagsFromFiles, filterTaggedFiles } from './lib';
import FileBrowserTagOptions from './FileBrowserTagOptions';
import {
    FileBrowserFile,
    FileBrowserLocalSearchFormData,
    FileFilterStats,
    FileTagsGroups,
} from './types';

type Props = {
    files: FileBrowserFile[];
    stats: FileFilterStats;
    onChange: (visibleFileIds: number[], data: FileBrowserLocalSearchFormData) => void;
};

const SearchTermKey = 'term';
const FileTypesFilterKey = 'fileTypes';
const ResolutionsFilterKey = 'resolutions';
const AspectRatiosFilterKey = 'aspectRatios';

const FileBrowserTagFilter: React.FC<Props> = ({ files, onChange, stats }) => {
    const tags = useMemo<FileTagsGroups>(() => extractTagsFromFiles(files), [files]);
    const searchLabel = useCommonTranslation('FileBrowser.filePreview.searchLabel');
    const filterCountLabel = useCommonTranslation('FileBrowser.filePreview.showCount', {
        count: stats.shown,
        total: stats.total,
    });

    const fileTypeOptions = useMemo(
        () =>
            Object.entries(tags?.fileType ?? {}).reduce((acc, [fileType, fileIds]) => {
                return [
                    ...acc,
                    { label: `${fileType} (${fileIds.length})`, value: fileType } as OptionSchema,
                ];
            }, [] as OptionSchema[]),
        [tags],
    );
    const resolutionOptions = useMemo(
        () =>
            Object.entries(tags?.resolution ?? {}).reduce((acc, [res, fileIds]) => {
                return [
                    ...acc,
                    { label: `${res} (${fileIds.length})`, value: res } as OptionSchema,
                ];
            }, [] as OptionSchema[]),
        [tags],
    );
    const aspectRatiosOptions = useMemo(
        () =>
            Object.entries(tags?.aspectRatios ?? {}).reduce((acc, [aspectRatio, fileIds]) => {
                return [
                    ...acc,
                    {
                        label: `${aspectRatio} (${fileIds.length})`,
                        value: aspectRatio,
                    } as OptionSchema,
                ];
            }, [] as OptionSchema[]),
        [tags],
    );

    const showTagWrapper = useMemo(
        () =>
            resolutionOptions.length > 1 ||
            fileTypeOptions.length > 1 ||
            aspectRatiosOptions.length > 1,
        [resolutionOptions, fileTypeOptions, aspectRatiosOptions],
    );

    const resolutionsDropdown = useMemo(
        () => resolutionOptions?.length > 1,
        [resolutionOptions?.length],
    );

    const aspectRatiosDropdown = useMemo(
        () => aspectRatiosOptions?.length > 1,
        [aspectRatiosOptions?.length],
    );

    const searchInput = useMemo(
        () => (
            <>
                <ControlledFormInput
                    name={SearchTermKey}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            size="small"
                            variant="standard"
                            whiteBackdrop={false}
                            placeholder={searchLabel}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                />

                {stats.total !== stats.shown && (
                    <Typography variant="body2" color="textSecondary">
                        {filterCountLabel}
                    </Typography>
                )}
            </>
        ),
        [filterCountLabel, searchLabel, stats.shown, stats.total],
    );

    return (
        <>
            <DataListener files={files} onChange={onChange} />
            <Box>
                {!showTagWrapper && (
                    <AutoGrid spacing={1} sx={{ mb: 2 }}>
                        <Box>{searchInput}</Box>
                    </AutoGrid>
                )}
                {showTagWrapper && (
                    <AutoGrid spacing={2} xs={12} sm={6} md={4} lg={3} xl={2}>
                        {searchInput}
                        {resolutionOptions.length > 0 && (
                            <FileBrowserTagOptions
                                filterName={ResolutionsFilterKey}
                                options={resolutionOptions}
                                asDropdown={resolutionsDropdown}
                                label="FileBrowser.filePreview.resolutionDropdownLabel"
                            />
                        )}
                        {aspectRatiosOptions.length > 0 && (
                            <FileBrowserTagOptions
                                filterName={AspectRatiosFilterKey}
                                options={aspectRatiosOptions}
                                asDropdown={aspectRatiosDropdown}
                                label="FileBrowser.filePreview.aspectRatioDropdownLabel"
                            />
                        )}
                        {fileTypeOptions.length > 1 && (
                            <FileBrowserTagOptions
                                filterName={FileTypesFilterKey}
                                options={fileTypeOptions}
                                asDropdown
                                label="FileBrowser.filePreview.fileTypeDropdownLabel"
                            />
                        )}
                    </AutoGrid>
                )}
            </Box>
        </>
    );
};

const DataListener = React.memo<{
    files: FileBrowserFile[];
    onChange: Props['onChange'];
}>(({ files, onChange }) => {
    const { watch } = useFormContext<FileBrowserLocalSearchFormData>();
    const data = watch();

    const debouncedFilter = useDebouncedCallback((callback: () => void) => {
        callback();
    }, 500);

    useEffect(() => {
        const theData = data;
        debouncedFilter(() => {
            onChange(filterTaggedFiles(files, theData), theData);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, files]);

    return null;
});

const FileBrowserTagFilterWrapper: React.FC<Props> = props => {
    const onSubmit = useCallback(() => {
        //
    }, []);
    return (
        <Grid item sx={{ mr: 'auto', my: 'auto', width: '100%' }}>
            <FormWrapper onSubmit={onSubmit}>
                <FileBrowserTagFilter {...props} />
            </FormWrapper>
        </Grid>
    );
};

export default FileBrowserTagFilterWrapper;
