import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    box: {
        backgroundColor: alpha(theme.palette.background.default, 0.5),
    },
}));

const LoadingSpinner = () => {
    const { box } = useStyles();
    return (
        <Box
            className={box}
            zIndex={10}
            position="absolute"
            height="100%"
            width="100%"
            color="secondary.contrastText"
            bgcolor="background.default"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
            fontSize="12rem"
        >
            <CircularProgress />
        </Box>
    );
};

export default LoadingSpinner;
