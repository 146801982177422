import { XYCoord } from 'react-dnd';

type Size = {
    height: number;
    width: number;
};

function snapToGridAdjust(x: number, y: number, gridSize: number): [number, number] {
    const snappedX = Math.round(x / gridSize) * gridSize;
    const snappedY = Math.round(y / gridSize) * gridSize;
    return [snappedX, snappedY];
}

export enum DraggableMouseAdjustment {
    TopLeft,
    Top,
    TopRight,

    CenterLeft,
    Center,
    CenterRight,

    BottomLeft,
    Bottom,
    BottomRight,
}

export type DraggableStyleOptions = {
    initialOffset: XYCoord | null;
    currentOffset: XYCoord | null;

    // required if want to adjust position
    size?: Size;

    // Default DraggableMouseAdjustment.TopLeft
    adjustPosition?: DraggableMouseAdjustment;

    snapToGrid?: boolean;
    gridSize?: number;
};

export function getDraggableStyles({
    initialOffset,
    currentOffset,
    size,
    adjustPosition,
    snapToGrid = false,
    gridSize = 32,
}: DraggableStyleOptions) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }

    let { x, y } = currentOffset;

    if (adjustPosition != null && size != null) {
        const { width, height } = size;
        switch (adjustPosition) {
            case DraggableMouseAdjustment.Top:
                x -= width / 2;
                break;
            case DraggableMouseAdjustment.TopRight:
                x -= width;
                break;
            case DraggableMouseAdjustment.CenterLeft:
                y -= height / 2;
                break;
            case DraggableMouseAdjustment.Center:
                y -= height / 2;
                x -= width / 2;
                break;
            case DraggableMouseAdjustment.CenterRight:
                y -= height / 2;
                x -= width;
                break;
            case DraggableMouseAdjustment.BottomLeft:
                y -= height;
                break;
            case DraggableMouseAdjustment.Bottom:
                y -= height;
                x -= width / 2;
                break;
            case DraggableMouseAdjustment.BottomRight:
                y -= height;
                x -= width;
                break;
            default:
                break;
        }
    }

    if (snapToGrid) {
        x -= initialOffset.x;
        y -= initialOffset.y;
        [x, y] = snapToGridAdjust(x, y, gridSize);
        x += initialOffset.x;
        y += initialOffset.y;
    }

    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}
