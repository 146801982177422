import React, { useCallback, useMemo } from 'react';
import { Box, Button, Select, MenuItem, Typography, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';

const CustomFooter = ({
    campaignId,
    paginationType = 'lineItems',
}: {
    campaignId: number;
    paginationType?: 'lineItems' | 'screens' | 'schedules';
}) => {
    const {
        currentPage,
        totalPages,
        rowCount,
        perPage,
        handlePageChange,
        handlePageSizeChange: onPageSizeChange,
        loading,
        screensCurrentPage,
        screensTotalPages,
        screensRowCount,
        screensPerPage,
        handleScreensPageChange,
        handleScreensPageSizeChange: onScreensPageSizeChange,
        schedulesCurrentPage,
        schedulesTotalPages,
        schedulesRowCount,
        schedulesPerPage,
        handleSchedulesPageChange,
        handleSchedulesPageSizeChange: onSchedulesPageSizeChange,
        screenLoading,
        scheduleLoading,
    } = useNewCampaignSchedule(campaignId);

    const handleLoading = useMemo(() => {
        switch (paginationType) {
            case 'screens':
                return screenLoading;
            case 'schedules':
                return scheduleLoading;
            default:
                return loading;
        }
    }, [loading, paginationType, scheduleLoading, screenLoading]);

    const handlePageSizeChange = useCallback(
        event => {
            switch (paginationType) {
                case 'screens':
                    onScreensPageSizeChange(event.target.value);
                    break;
                case 'schedules':
                    onSchedulesPageSizeChange(event.target.value);
                    break;
                default:
                    onPageSizeChange(event.target.value);
                    break;
            }
        },
        [onPageSizeChange, onScreensPageSizeChange, onSchedulesPageSizeChange, paginationType],
    );

    const handlePageNumberClick = useCallback(
        page => {
            switch (paginationType) {
                case 'screens':
                    handleScreensPageChange(page);
                    break;
                case 'schedules':
                    handleSchedulesPageChange(page);
                    break;
                default:
                    handlePageChange(page);
                    break;
            }
        },
        [handlePageChange, handleScreensPageChange, handleSchedulesPageChange, paginationType],
    );

    const renderPageNumbers = useMemo(() => {
        const pageNumbers = [];
        let maxPagesToShow = 6;
        let total = totalPages;
        let current = currentPage;

        switch (paginationType) {
            case 'screens':
                total = screensTotalPages;
                current = screensCurrentPage;
                break;
            case 'schedules':
                total = schedulesTotalPages;
                current = schedulesCurrentPage;
                maxPagesToShow = 3; // Show only up to 3 numbers for schedules
                break;
            default:
                break;
        }

        if (total <= maxPagesToShow) {
            for (let i = 1; i <= total; i += 1) {
                pageNumbers.push(
                    <Button
                        key={i}
                        onClick={() => handlePageNumberClick(i)}
                        disabled={handleLoading}
                        variant={i === current ? 'contained' : 'text'}
                        size="small"
                    >
                        {i}
                    </Button>,
                );
            }
        } else {
            pageNumbers.push(
                <Button
                    key={1}
                    onClick={() => handlePageNumberClick(1)}
                    disabled={handleLoading}
                    variant={current === 1 ? 'contained' : 'text'}
                    size="small"
                >
                    1
                </Button>,
            );

            if (current > 2 && current < total - 1) {
                if (current > 3) {
                    pageNumbers.push(<span key="dots1">...</span>);
                }

                pageNumbers.push(
                    <Button
                        key={current}
                        onClick={() => handlePageNumberClick(current)}
                        disabled={handleLoading}
                        variant="contained"
                        size="small"
                    >
                        {current}
                    </Button>,
                );

                if (current < total - 2) {
                    pageNumbers.push(<span key="dots2">...</span>);
                }
            } else if (current >= total - 1) {
                pageNumbers.push(<span key="dots3">...</span>);
                pageNumbers.push(
                    <Button
                        key={total - 1}
                        onClick={() => handlePageNumberClick(total - 1)}
                        disabled={handleLoading}
                        variant={current === total - 1 ? 'contained' : 'text'}
                        size="small"
                    >
                        {total - 1}
                    </Button>,
                );
            } else {
                pageNumbers.push(
                    <Button
                        key={2}
                        onClick={() => handlePageNumberClick(2)}
                        disabled={handleLoading}
                        variant={current === 2 ? 'contained' : 'text'}
                        size="small"
                    >
                        2
                    </Button>,
                );
                pageNumbers.push(<span key="dots4">...</span>);
            }

            pageNumbers.push(
                <Button
                    key={total}
                    onClick={() => handlePageNumberClick(total)}
                    disabled={handleLoading}
                    variant={total === current ? 'contained' : 'text'}
                    size="small"
                >
                    {total}
                </Button>,
            );
        }

        return pageNumbers;
    }, [
        totalPages,
        currentPage,
        paginationType,
        screensTotalPages,
        screensCurrentPage,
        schedulesTotalPages,
        schedulesCurrentPage,
        handleLoading,
        handlePageNumberClick,
    ]);

    const getCurrentPage = useCallback(() => {
        switch (paginationType) {
            case 'screens':
                return screensCurrentPage;
            case 'schedules':
                return schedulesCurrentPage;
            default:
                return currentPage;
        }
    }, [paginationType, screensCurrentPage, schedulesCurrentPage, currentPage]);

    const getTotalPages = useCallback(() => {
        switch (paginationType) {
            case 'screens':
                return screensTotalPages;
            case 'schedules':
                return schedulesTotalPages;
            default:
                return totalPages;
        }
    }, [paginationType, screensTotalPages, schedulesTotalPages, totalPages]);

    const getRowCount = useCallback(() => {
        switch (paginationType) {
            case 'screens':
                return screensRowCount;
            case 'schedules':
                return schedulesRowCount;
            default:
                return rowCount;
        }
    }, [paginationType, screensRowCount, schedulesRowCount, rowCount]);

    const getPerPage = useCallback(() => {
        switch (paginationType) {
            case 'screens':
                return screensPerPage;
            case 'schedules':
                return schedulesPerPage;
            default:
                return perPage;
        }
    }, [paginationType, screensPerPage, schedulesPerPage, perPage]);

    const handlePrevPageChange = useCallback(() => {
        switch (paginationType) {
            case 'screens':
                handleScreensPageChange(screensCurrentPage - 1);
                break;
            case 'schedules':
                handleSchedulesPageChange(schedulesCurrentPage - 1);
                break;
            default:
                handlePageChange(currentPage - 1);
                break;
        }
    }, [
        handlePageChange,
        handleScreensPageChange,
        handleSchedulesPageChange,
        paginationType,
        screensCurrentPage,
        schedulesCurrentPage,
        currentPage,
    ]);

    const handleNextPageChange = useCallback(() => {
        switch (paginationType) {
            case 'screens':
                handleScreensPageChange(screensCurrentPage + 1);
                break;
            case 'schedules':
                handleSchedulesPageChange(schedulesCurrentPage + 1);
                break;
            default:
                handlePageChange(currentPage + 1);
                break;
        }
    }, [
        handlePageChange,
        handleScreensPageChange,
        handleSchedulesPageChange,
        paginationType,
        screensCurrentPage,
        schedulesCurrentPage,
        currentPage,
    ]);

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={2}
            sx={{ flexWrap: 'wrap' }}
        >
            <Box display="flex" alignItems="center" sx={{ flex: '1 1 auto', minWidth: '200px' }}>
                <Typography variant="body2" style={{ marginRight: 8 }}>
                    Rows per page:
                </Typography>
                <Select
                    value={getPerPage()}
                    onChange={handlePageSizeChange}
                    disabled={handleLoading}
                    style={{ marginRight: 16 }}
                    size="small"
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    {paginationType === 'screens' && <MenuItem value={500}>500</MenuItem>}
                    {paginationType === 'screens' && <MenuItem value={1000}>1000</MenuItem>}
                    {paginationType === 'screens' && <MenuItem value={5000}>5000</MenuItem>}
                </Select>
                <Typography variant="body2">
                    {`Page ${getCurrentPage()} of ${getTotalPages()} (${getRowCount()} rows)`}
                </Typography>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                sx={{ flex: '1 1 auto', minWidth: '200px', justifyContent: 'flex-end' }}
            >
                <IconButton
                    onClick={handlePrevPageChange}
                    disabled={getCurrentPage() === 1 || handleLoading}
                    size="small"
                >
                    <ArrowBack />
                </IconButton>
                {renderPageNumbers}
                <IconButton
                    onClick={handleNextPageChange}
                    disabled={getCurrentPage() === getTotalPages() || handleLoading}
                    size="small"
                >
                    <ArrowForward />
                </IconButton>
            </Box>
        </Box>
    );
};

export default CustomFooter;
