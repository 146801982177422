import React from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';

import { Button } from 'c-components';

const DetachDialog = ({ detach, detachState, setDetach }) => {
    const handleRemoveSchedule = scheduleId => {
        setDetach(prevDetach => ({
            ...prevDetach,
            schedules: prevDetach.schedules.filter(schedule => schedule.id !== scheduleId),
        }));
    };

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Schedule ID</TableCell>
                            <TableCell>Schedule Name</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {detach.schedules.map(schedule => (
                            <TableRow key={schedule.id}>
                                <TableCell>{schedule.id}</TableCell>
                                <TableCell>{schedule.name}</TableCell>
                                <TableCell align="right">
                                    <Button
                                        variant="text"
                                        color="error"
                                        onClick={() => handleRemoveSchedule(schedule.id)}
                                    >
                                        Remove
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default DetachDialog;
