import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import to from 'await-to-js';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import { Alert, Autocomplete } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { NetworkRequestState } from '@uniled/data-layer';

const AttachTarget = ({ target, id, attachBool }) => {
    const { start, error, requestState } = useAPIClientRequest(
        ApiClient.Entities.Campaign.attachTargetToSchedules,
    );
    const { schedules, setTargets } = useNewCampaignSchedule(id);
    const targetId = useMemo(() => target?.id, [target]);

    const scheduleOptions = useMemo(
        () =>
            schedules.map(schedule => ({
                value: schedule.id,
                label: schedule.name,
            })),
        [schedules],
    );

    const { control, handleSubmit, setValue } = useForm({
        defaultValues: {
            schedules: target?.schedules?.map(schedule => schedule?.id) ?? [],
        },
    });

    const attachTargetToSchedules = useCallback(
        async data => {
            const [err, success] = await to(start(id, targetId, { schedules: data.schedules }));
            if (!err && success && success?.data?.success) {
                const newSchedules = data.schedules.map(scheduleId =>
                    schedules.find(schedule => schedule.id === scheduleId),
                );

                const updatedSchedules = [...target.schedules];
                newSchedules.forEach(newSchedule => {
                    if (!updatedSchedules.some(schedule => schedule.id === newSchedule.id)) {
                        updatedSchedules.push(newSchedule);
                    }
                });

                setTargets(prevTargets =>
                    prevTargets.map(t =>
                        t.id === targetId ? { ...t, schedules: updatedSchedules } : t,
                    ),
                );

                attachBool.setFalse();
            }
        },
        [start, id, targetId, attachBool, setTargets, schedules, target.schedules],
    );

    const t = useCommonTranslation();

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(attachTargetToSchedules)}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
        >
            <Controller
                name="schedules"
                control={control}
                rules={{
                    required: t(
                        'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.verification.atLeastOneSelected',
                    ),
                }}
                render={({ field, fieldState: { error } }) => (
                    <Stack direction="row" gap={2} alignItems="center">
                        <Box flexGrow={1}>
                            <Typography variant="caption">
                                {t(
                                    'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.attachTarget',
                                )}
                            </Typography>
                            <Autocomplete
                                value={scheduleOptions.filter(option =>
                                    field.value.includes(option.value),
                                )}
                                options={scheduleOptions}
                                {...field}
                                multiple
                                textFieldProps={{
                                    error: !!error,
                                    helperText: error
                                        ? error.message
                                        : t(
                                              'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.verification.schedulesSelect',
                                          ),
                                }}
                                disabled={requestState === NetworkRequestState.InProgress}
                                fullWidth
                            />
                        </Box>
                        <Box width="auto" flexShrink={0}>
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={() =>
                                    setValue(
                                        'schedules',
                                        scheduleOptions.map(option => option.value),
                                    )
                                }
                                disabled={requestState === NetworkRequestState.InProgress}
                            >
                                {t('Forms.autocomplete.selectAllOptionLabel')}
                            </Button>
                        </Box>
                    </Stack>
                )}
            />
            <Button
                type="submit"
                disabled={requestState === NetworkRequestState.InProgress}
                variant="contained"
                color="primary"
            >
                {t('Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.attachTarget')}
            </Button>
            {error && (
                <Alert severity="error" variant="outlined">
                    {String(error)}
                </Alert>
            )}
        </Box>
    );
};

export default AttachTarget;
