import React from 'react';
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

type Props = {
    text: string;
    passed: boolean;
};
const PasswordStrengthLine: React.FC<Props> = ({ text, passed }) => {
    const color = passed ? 'primary' : 'textSecondary';

    return (
        <Box display="flex" alignItems="center" pb={1.5}>
            <Box display="inline-flex" alignItems="center" fontSize="2.1rem">
                {passed ? (
                    <CheckCircleOutline color="primary" fontSize="inherit" />
                ) : (
                    <HighlightOff color="disabled" fontSize="inherit" />
                )}
            </Box>
            <Box display="flex" alignItems="center" ml={1.5}>
                <Typography color={color}>{text}</Typography>
            </Box>
        </Box>
    );
};

export default PasswordStrengthLine;
