import React, { useCallback, useMemo } from 'react';
import { useNumber } from 'react-hanger';
import { useFormContext } from 'react-hook-form';
import { Campaign } from '@uniled/api-sdk';
import { Translate, TranslationPath, useCommonTranslation } from 'c-translation';
import { Box, CardActions, CardContent, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { Button, TransparentCard } from 'c-components';
import CampaignLineItemPicker from 'c-main/Components/Campaign/CampaignLineItems/CampaignLineItemPicker/CampaignLineItemPicker';
import StepContent from './StepContent';
import { CostingStep, DetailsStep, SummaryStep } from './Steps';

type Props = {
    //
    campaign: Campaign;
};

enum Steps {
    Details,
    ChooseDisplays,
    Schedule,
    Summary,
}

const stepMaxWidths = {
    [Steps.Details]: 800,
    [Steps.ChooseDisplays]: 1200,
    [Steps.Schedule]: 1200,
    [Steps.Summary]: 600,
};

const stepTitles: Record<Steps, TranslationPath> = {
    [Steps.Details]: 'Modules.Main.Campaigns.EditLineItems.stepDetailsTitle',
    [Steps.ChooseDisplays]: 'Modules.Main.Campaigns.EditLineItems.stepChooseDisplaysTitle',
    [Steps.Schedule]: 'Modules.Main.Campaigns.EditLineItems.stepScheduleTitle',
    [Steps.Summary]: 'Modules.Main.Campaigns.EditLineItems.stepSummaryTitle',
};

const totalSteps = 4;

const EditCampaignFormStepper: React.FC<Props> = ({ campaign }) => {
    const { increase: goForward, decrease: goBack, value: currentStep } = useNumber(0);
    const { formState } = useFormContext();

    const showLast = useMemo(() => currentStep === totalSteps - 1, [currentStep]);
    const hasNext = useMemo(() => currentStep < totalSteps - 1, [currentStep]);
    const hasPrev = useMemo(() => currentStep > 0, [currentStep]);

    const goNext = useCallback(() => {
        if (hasNext) {
            goForward();
        }
    }, [goForward, hasNext]);

    const goPrev = useCallback(() => {
        if (hasPrev) {
            goBack();
        }
    }, [goBack, hasPrev]);

    const visibleStep = useMemo<Steps>(() => {
        switch (currentStep) {
            case 0:
                return Steps.Details;
            case 1:
                return Steps.ChooseDisplays;
            case 2:
                return Steps.Schedule;
            case 3:
                return Steps.Summary;
            default:
                return Steps.Details;
        }
    }, [currentStep]);

    const stepTitle = useCommonTranslation(stepTitles[visibleStep]);

    const stepLabel = useCommonTranslation('Modules.Main.Campaigns.Create.currentStepLabel', {
        current: currentStep + 1,
        total: totalSteps,
    });
    const stepCountTitle = useMemo(
        () => [<Typography variant="h4">{stepLabel}</Typography>],
        [stepLabel],
    );

    return (
        <>
            <Box
                minHeight={200}
                maxWidth={stepMaxWidths[visibleStep]}
                width="100%"
                mx="auto"
                position="relative"
                mt={2}
            >
                <TransparentCard
                    title={stepTitle}
                    titleTypographyProps={{ variant: 'h3' }}
                    controls={stepCountTitle}
                    controlsUnderTitle
                >
                    <CardContent>
                        <Box width="100%" display="flex">
                            <Box width="100%">
                                <Stepper alternativeLabel activeStep={currentStep} sx={{ py: 2 }}>
                                    <Step>
                                        <StepLabel>
                                            <Translate path="Modules.Main.Campaigns.EditLineItems.detailsStepLabel" />
                                        </StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>
                                            <Translate path="Modules.Main.Campaigns.EditLineItems.chooseDisplaysStepLabel" />
                                        </StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>
                                            <Translate path="Modules.Main.Campaigns.EditLineItems.scheduleStepLabel" />
                                        </StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>
                                            <Translate path="Modules.Main.Campaigns.EditLineItems.summaryStepLabel" />
                                        </StepLabel>
                                    </Step>
                                </Stepper>

                                {visibleStep === Steps.Details && (
                                    <StepContent visible>
                                        <DetailsStep />
                                    </StepContent>
                                )}
                                {visibleStep === Steps.ChooseDisplays && (
                                    <StepContent visible>
                                        <CampaignLineItemPicker />
                                    </StepContent>
                                )}
                                {visibleStep === Steps.Schedule && (
                                    <StepContent visible>
                                        <CostingStep campaign={campaign} />
                                    </StepContent>
                                )}
                                {visibleStep === Steps.Summary && (
                                    <StepContent visible>
                                        <SummaryStep campaign={campaign} />
                                    </StepContent>
                                )}

                                <CardActions sx={{ pt: 2, px: 0 }}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={goPrev}
                                            disabled={!hasPrev}
                                        >
                                            <Translate path="Modules.Main.Campaigns.EditLineItems.prevStepLabel" />
                                        </Button>
                                        {!showLast && (
                                            <Button
                                                onClick={goNext}
                                                disabled={!hasNext || !formState.isValid}
                                            >
                                                <Translate path="Modules.Main.Campaigns.EditLineItems.nextStepLabel" />
                                            </Button>
                                        )}
                                        {showLast && (
                                            <Button type="submit">
                                                <Translate path="Modules.Main.Campaigns.EditLineItems.saveCampaignLabel" />
                                            </Button>
                                        )}
                                    </Box>
                                </CardActions>
                            </Box>
                        </Box>
                    </CardContent>
                </TransparentCard>
            </Box>
        </>
    );
};

export default EditCampaignFormStepper;
