import React, { useCallback, useEffect, useMemo } from 'react';
import { useEntityData, User, useUpdateEntityData } from 'c-data';
import { UseFormProps } from 'react-hook-form';
import equal from 'fast-deep-equal';
import { Translate, useCommonTranslation } from 'c-translation';
import { Box, CardContent, Typography, Card } from '@mui/material';
import {
    AutoGrid,
    Button,
    Checkbox,
    ControlledFormInput,
    FlashMessage,
    FormWrapper,
    TextField,
    ToggleButtonGroup,
    TransparentCard,
} from 'c-components';
import { IfHasAllPermissions, IfHasSomePermissions } from 'c-auth-module/Components';
import { PermissionName } from '@uniled/api-sdk';
import UserAssociationAssignment from 'c-admin/Components/User/UserAssociationAssignment';
import { NetworkRequestState } from '@uniled/data-layer';
// eslint-disable-next-line import/no-cycle
import { EditUserPage } from 'c-admin/Pages/EditUserPage';
import { LANG_en_gb, LANG_en_us } from 'c-lib';
import { useUserPermissions } from 'c-auth-module/Hooks';
import ImpersonateUserSection from 'c-admin/Pages/EditUserPage/Components/ImpersonateUserSection';
import UserLegacyAssociations from './UserLegacyAssociations';
import UnverifiedUser from './UnverifiedUser';

type Props = {
    id: number;
};

const UserEditWrapper: React.FC<Props> = ({ id }) => {
    const { getById } = useEntityData<User>('User');
    const user = getById({ id });
    const { update, getUpdatingById, resetUpdatingById } = useUpdateEntityData('User');
    const loadingState = getUpdatingById(user.id);
    const { hasAdminPermission } = useUserPermissions();

    const formOptions = useMemo<UseFormProps<User>>(
        () => ({
            defaultValues: {
                email: user.email,
                name_first: user.name_first,
                name_last: user.name_last,
                locale: user.locale,
                active: user.active,
                buyers: user.buyers,
                agencies: user.agencies,
                clients: user.clients,
                owners: user.owners,
                roles: user.roles,
                old_portal_disable: user.old_portal_disable,
                profiling: user.profiling,
            },
        }),
        [user],
    );

    const onSubmit = useCallback(
        (data: User) => {
            const actualData = {} as User;

            if (user.name_first !== data.name_first) actualData.name_first = data.name_first;

            if (user.email !== data.email) actualData.email = data.email;

            if (user.name_last !== data.name_last) actualData.name_last = data.name_last;

            if (user.locale !== data.locale) actualData.locale = data.locale;

            if (user.active !== data.active) actualData.active = data.active;

            if (!equal(user.buyers, data.buyers) && Array.isArray(data.buyers))
                actualData.buyers = data.buyers;

            if (!equal(user.agencies, data.agencies) && Array.isArray(data.agencies))
                actualData.agencies = data.agencies;

            if (!equal(user.clients, data.clients) && Array.isArray(data.clients))
                actualData.clients = data.clients;

            if (!equal(user.owners, data.owners) && Array.isArray(data.owners))
                actualData.owners = data.owners;

            if (!equal(user.roles, data.roles) && Array.isArray(data.roles))
                actualData.roles = data.roles;

            // only put it in the update data if the user is an admin and it has changed
            if (hasAdminPermission && user.old_portal_disable !== data.old_portal_disable)
                actualData.old_portal_disable = data.old_portal_disable;

            // only put it in the update data if the user is an admin and it has changed
            if (hasAdminPermission && user.profiling !== data.profiling)
                actualData.profiling = data.profiling;

            if (Object.keys(actualData).length > 0) {
                update(user.id, actualData, EditUserPage.systemSearch.defaultIncludes);
            }
        },
        [user, update, hasAdminPermission],
    );

    useEffect(() => {
        // reset the update state on mount and unmount
        resetUpdatingById(user.id);
        return () => {
            resetUpdatingById(user.id);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const t = useCommonTranslation();

    const showLegacyAssocs = useMemo(
        () =>
            user.buyer_id != null ||
            user.agency_id != null ||
            user.client_id != null ||
            user.owner != null,
        [user],
    );

    return (
        <Box mb={4}>
            {user.properties.verified === false && (
                <IfHasAllPermissions permissions={[PermissionName.Admin]}>
                    <UnverifiedUser user={user} />
                </IfHasAllPermissions>
            )}
            <IfHasAllPermissions permissions={[PermissionName.Admin]}>
                <TransparentCard
                    title={t('Modules.Admin.UserEdit.impersonateUserHeader', {
                        name: user.name,
                    })}
                >
                    <Card>
                        <CardContent>
                            <ImpersonateUserSection userId={id} success={loadingState.state} />
                        </CardContent>
                    </Card>
                </TransparentCard>
            </IfHasAllPermissions>
            <FormWrapper<User> onSubmit={onSubmit} formOptions={formOptions}>
                <TransparentCard title={t('Modules.Admin.UserEdit.pageTitle', { name: user.name })}>
                    <CardContent>
                        <AutoGrid spacing={2} xs={12}>
                            <Card>
                                <Box p={2}>
                                    <AutoGrid spacing={2} xs={12}>
                                        <AutoGrid spacing={2} xs={12}>
                                            <Typography variant="h3" color="textSecondary">
                                                <Translate path="Modules.Admin.UserEdit.detailsHeader" />
                                            </Typography>
                                        </AutoGrid>
                                        <AutoGrid spacing={2} xs={12} lg={6}>
                                            <ControlledFormInput
                                                name="email"
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        type="email"
                                                        label={t('Modules.Admin.UserForm.email')}
                                                    />
                                                )}
                                            />
                                        </AutoGrid>
                                        <AutoGrid spacing={2} xs={12} sm={6} md={4} lg={3}>
                                            <ControlledFormInput
                                                name="name_first"
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        label={t(
                                                            'Modules.Admin.UserForm.firstName',
                                                        )}
                                                    />
                                                )}
                                            />
                                            <ControlledFormInput
                                                name="name_last"
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        label={t('Modules.Admin.UserForm.lastName')}
                                                    />
                                                )}
                                            />
                                        </AutoGrid>

                                        <AutoGrid gap={2} alignItems="center">
                                            <ControlledFormInput
                                                name="locale"
                                                render={({ field }) => (
                                                    <ToggleButtonGroup
                                                        {...field}
                                                        exclusive
                                                        options={[
                                                            {
                                                                label: LANG_en_gb,
                                                                value: LANG_en_gb,
                                                            },
                                                            {
                                                                label: LANG_en_us,
                                                                value: LANG_en_us,
                                                            },
                                                        ]}
                                                        label={t('Modules.Admin.UserForm.language')}
                                                    />
                                                )}
                                            />

                                            <Box display="flex" alignItems="center">
                                                <ControlledFormInput
                                                    name="active"
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            isBoolean
                                                            label={t(
                                                                'Modules.Admin.UserForm.active',
                                                            )}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        </AutoGrid>
                                    </AutoGrid>
                                </Box>
                            </Card>
                            <IfHasAllPermissions permissions={[PermissionName.Admin]}>
                                <UserAssociationAssignment id={id} />
                            </IfHasAllPermissions>
                            {loadingState.state === NetworkRequestState.Error && (
                                <Box>
                                    <FlashMessage status="error">
                                        {loadingState.error ??
                                            t('Modules.Admin.UserForm.failedToSave')}
                                    </FlashMessage>
                                </Box>
                            )}
                            {loadingState.state === NetworkRequestState.Success && (
                                <Box>
                                    <FlashMessage status="success">
                                        {t('Modules.Admin.UserForm.saveSuccess')}
                                    </FlashMessage>
                                </Box>
                            )}
                            <Button
                                sx={{ mt: 2 }}
                                disabled={loadingState.state === NetworkRequestState.InProgress}
                                type="submit"
                            >
                                {t('Modules.Admin.UserForm.saveBtn')}
                            </Button>
                        </AutoGrid>
                    </CardContent>
                </TransparentCard>
            </FormWrapper>
            <IfHasSomePermissions
                permissions={[
                    PermissionName.UniledportalBuyerRead,
                    PermissionName.UniledportalAgencyRead,
                    PermissionName.UniledportalClientRead,
                    PermissionName.UniledportalOwnerRead,
                ]}
            >
                {showLegacyAssocs && (
                    <TransparentCard
                        title={t('Modules.Admin.UserEdit.legacyAssociationsHeader')}
                        titleTypographyProps={{ variant: 'h3' }}
                        subTitle={t('Modules.Admin.UserEdit.legacyAssociationsDescription')}
                    >
                        <UserLegacyAssociations user={user} />
                    </TransparentCard>
                )}
            </IfHasSomePermissions>
        </Box>
    );
};

export default UserEditWrapper;
