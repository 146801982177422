import React, { useCallback, useMemo } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { OpenInFull } from '@mui/icons-material';
import { useBoolean } from 'react-hanger';
import { Translate } from 'c-translation';
import { FileBrowserFile } from './types';
import FilePreviewDialog from './FilePreviewDialog';

type Props = {
    file: FileBrowserFile;
};

const OpenFilePreviewDialog: React.FC<Props> = ({ file }) => {
    const downloadable = useMemo(() => file?.urls?.download, [file]);
    const expanded = useBoolean(false);
    const onClick = useCallback(
        e => {
            e.stopPropagation();
            expanded.setTrue();
        },
        [expanded.setTrue],
    );

    return useMemo(
        () => (
            <>
                {downloadable && (
                    <>
                        <Tooltip title={<Translate path="FileBrowser.filePreview.expand" />}>
                            <IconButton onClick={onClick}>
                                <OpenInFull />
                            </IconButton>
                        </Tooltip>
                        <FilePreviewDialog
                            file={file}
                            open={expanded.value}
                            close={expanded.setFalse}
                        />
                    </>
                )}
            </>
        ),
        [expanded.setFalse, expanded.setTrue, expanded.value, file],
    );
};

export default OpenFilePreviewDialog;
