import React, { useMemo } from 'react';
import { Button, DialogV2 } from 'c-components';

import { useCommonTranslation } from 'c-translation';
import { useBoolean } from 'react-hanger';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { MissingDisplay, PermissionName } from '@uniled/api-sdk';
import {
    FilterableEntityTable,
    FilterableEntityTableRendererType,
    generateColumn,
} from 'c-pagination';
import { FullPageEntityTableWrapper } from 'c-wrapper';

interface Props {
    id: number;
    count: number;
}
const adminPerms: PermissionName[] = [PermissionName.Admin];
const textSearchCols = ['media_name'];

const columns = [
    generateColumn<MissingDisplay>({
        headerTitle: 'Modules.Main.Campaigns.Overview.missingDisplay.mediaNameTitle',
        field: 'media_name',
        entityName: 'MissingDisplay',
    }),
    generateColumn<MissingDisplay>({
        headerTitle: 'Modules.Main.Campaigns.Overview.missingDisplay.formatTitle',
        field: 'screen.frame_id',
        entityName: 'MissingDisplay',
    }),
    generateColumn<MissingDisplay>({
        headerTitle: 'Modules.Main.Campaigns.Overview.missingDisplay.mediaOwnerTitle',
        field: 'display.owner',
        entityName: 'MissingDisplay',
    }),
    generateColumn<MissingDisplay>({
        headerTitle: 'Modules.Main.Campaigns.Overview.missingDisplay.screenNameSummaryTitle',
        field: 'screen.address',
        entityName: 'MissingDisplay',
    }),
    generateColumn<MissingDisplay>({
        headerTitle: 'Modules.Main.Campaigns.Overview.missingDisplay.environmentTitle',
        field: 'display.environment',
        entityName: 'MissingDisplay',
    }),
    generateColumn<MissingDisplay>({
        headerTitle: 'Modules.Main.Campaigns.Overview.missingDisplay.formatTitle',
        field: 'screen.size',
        entityName: 'MissingDisplay',
    }),
    generateColumn<MissingDisplay>({
        headerTitle: 'Modules.Main.Campaigns.Overview.missingDisplay.lineSpaceItemTitle',
        field: 'screen.packs.0.name',
        entityName: 'MissingDisplay',
        RenderCell: ({ entity }) => (
            <>{entity.screen?.packs?.[0]?.name ?? entity?.screen?.address}</>
        ),
    }),
];

const MissingDisplaysDataTable: React.FC<Props> = ({ id, count }) => {
    const setDialogOpen = useBoolean(false);
    const t = useCommonTranslation();
    const missingDisplaysBtn = useMemo(
        () => (
            <IfHasAllPermissions permissions={adminPerms}>
                <Button
                    onClick={setDialogOpen.setTrue}
                    variant="outlined"
                    size="small"
                    color="warning"
                >
                    {count} {t('Modules.Main.Campaigns.Overview.missingDisplay.title')}
                </Button>
            </IfHasAllPermissions>
        ),
        [setDialogOpen.setTrue, t, count],
    );

    const paginationTag = id.toString();

    const mdDialog = useMemo(() => {
        if (!setDialogOpen.value) {
            return null;
        }

        return (
            <>
                <DialogV2 onClose={setDialogOpen.setFalse} open={setDialogOpen.value} maxWidth="lg">
                    <FullPageEntityTableWrapper title="Modules.Main.Campaigns.Overview.missingDisplay.title">
                        <FilterableEntityTable
                            key={paginationTag}
                            tag={paginationTag}
                            columns={columns}
                            textSearchColumns={textSearchCols}
                            baseEntityName="MissingDisplay"
                            rendererType={FilterableEntityTableRendererType.Contained}
                            includes={['display', 'screen', 'screen.packs']}
                            direction="desc"
                            showFilters
                            filters={{ 'filter.campaign_id': [id] }}
                            revertToIdSearchOnNumberOnlyInput={false}
                        />
                    </FullPageEntityTableWrapper>
                </DialogV2>
            </>
        );
    }, [id, paginationTag, setDialogOpen.setFalse, setDialogOpen.value]);

    return (
        <>
            {missingDisplaysBtn}
            {mdDialog}
        </>
    );
};

export default MissingDisplaysDataTable;
