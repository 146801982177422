import { withStyles } from '@mui/styles';

const GlobalCss = withStyles({
    // @global is handled by jss-plugin-global.
    '@global': {
        'body, html': {
            // background: '#fff',
            margin: 0,
            padding: 0,
            // position: 'absolute',
            // top: 0,
            // right: 0,
            // left: 0,
            // bottom: 0,
        },
        '#root': {
            // height: '100%',
        },
    },
})(() => null);

export default GlobalCss;
