import {
    displaysPackActions,
    displaysPackAdapter,
    displaysPackThunks as defaultThunks,
    displaysPackSelectors as defaultSelectors,
    displaysPackReducer,
} from './DisplaysPack-slice';
import * as thunks from './DisplaysPack-thunks';
import * as selectors from './DisplaysPack-selectors';

const displaysPackThunks = { ...defaultThunks, ...thunks };
const displaysPackSelectors = { ...defaultSelectors, ...selectors };

export { default as DisplaysPackSlice } from './DisplaysPack-slice';
export {
    displaysPackSelectors,
    displaysPackActions,
    displaysPackAdapter,
    displaysPackThunks,
    displaysPackReducer,
};
