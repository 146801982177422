import {
    resolutionActions,
    resolutionAdapter,
    resolutionThunks as defaultThunks,
    resolutionSelectors as defaultSelectors,
    resolutionReducer,
} from './Resolution-slice';
import * as thunks from './Resolution-thunks';
import * as selectors from './Resolution-selectors';

const resolutionThunks = { ...defaultThunks, ...thunks };
const resolutionSelectors = { ...defaultSelectors, ...selectors };

export { default as ResolutionSlice } from './Resolution-slice';
export {
    resolutionSelectors,
    resolutionActions,
    resolutionAdapter,
    resolutionThunks,
    resolutionReducer,
};
