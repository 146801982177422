import React, { useMemo } from 'react';
import { Box, capitalize, List } from '@mui/material';
import { useCommonTranslation, TranslationPath } from 'c-translation';
import { useEntityData } from 'c-data';
import {
    SystemSearchEntityResource,
    SystemSearchEntitySearchResult,
    SystemSearchResource,
    SystemSearchResourceOrEntity,
    SystemSearchSelectedItemGrid,
} from '../../types';
import { SystemSearchResult } from '..';

type Props = {
    results: SystemSearchResourceOrEntity[];
    entityResults: SystemSearchEntitySearchResult[];
    searchTerm: string;
    selectedIndex: number;
    onClick: (index: number) => void;
    setHighlighted: (selected: SystemSearchSelectedItemGrid) => void;
};
type ResultWrapperProps<T> = T & {
    highlighted: boolean;
    searchTerm: string;
    index: number;
    onClick: (index: number) => void;
    setHighlighted: (selected: SystemSearchSelectedItemGrid) => void;
};
type EntityResultWrapperProps = ResultWrapperProps<SystemSearchEntityResource> & {
    entityId: number;
};

const PageResultWrapper = ({
    highlighted,
    title,
    description,
    Content,
    contentTimeout,
    tag,
    searchTerm,
    route,
    breadcrumbs,
    icon,
    index,
    onClick,
    setHighlighted,
    type,
}: ResultWrapperProps<SystemSearchResource>) => {
    const tagLabelTranslated = useCommonTranslation(tag);

    const tagLabel = useMemo(() => {
        if (tagLabelTranslated == null || tagLabelTranslated === '') {
            return capitalize(type);
        }
        return tagLabelTranslated;
    }, [tagLabelTranslated, type]);

    const breadCrumbsList = useMemo(() => breadcrumbs?.map(b => b.label), [breadcrumbs]);

    return (
        <SystemSearchResult
            highlighted={highlighted}
            setHighlighted={setHighlighted}
            title={useCommonTranslation(title)}
            description={useCommonTranslation(description)}
            Content={Content}
            contentTimeout={contentTimeout}
            tag={tagLabel}
            route={route}
            searchTerm={searchTerm}
            breadCrumbs={breadCrumbsList}
            pageIcon={icon}
            index={index}
            onClick={onClick}
        />
    );
};

const EntityResultWrapper = ({
    highlighted,
    description,
    Content,
    contentTimeout,
    tag,
    searchTerm,
    route,
    breadcrumbs,
    entityName,
    entityId,
    nameGen,
    icon,
    index,
    onClick,
    setHighlighted,
}: EntityResultWrapperProps) => {
    const tagLabelTranslated = useCommonTranslation(tag);

    const tagLabel = useMemo(() => {
        if (tagLabelTranslated == null || tagLabelTranslated === '') {
            return entityName;
        }
        return tagLabelTranslated;
    }, [tagLabelTranslated, entityName]);

    const { getById } = useEntityData(entityName);
    const entity = getById({ id: entityId });
    const t = useCommonTranslation();
    const breadCrumbsList = useMemo(
        () =>
            breadcrumbs
                ?.map(b => b.label)
                .concat(nameGen(entity as any, entityId, t) as TranslationPath),
        [breadcrumbs, nameGen, entity, entityId, t],
    );

    const resultTitle = useMemo(
        () => nameGen(entity as any, entityId, t),
        [entity, entityId, nameGen, t],
    );

    return (
        <SystemSearchResult
            highlighted={highlighted}
            setHighlighted={setHighlighted}
            title={resultTitle}
            description={useCommonTranslation(description)}
            Content={Content}
            contentTimeout={contentTimeout}
            tag={tagLabel}
            route={route}
            searchTerm={searchTerm}
            breadCrumbs={breadCrumbsList}
            pageIcon={icon}
            index={index}
            onClick={onClick}
        />
    );
};

const SystemSearchResultList: React.FC<Props> = ({
    results,
    entityResults,
    searchTerm,
    selectedIndex,
    onClick,
    setHighlighted,
}) => (
    <Box>
        <List>
            {results.map((res, i) => {
                const key = `${res.route}-${res.title}-${res.type}`;

                if (res.type === 'entity') {
                    const entityResult = entityResults[i];

                    if (entityResult != null) {
                        return (
                            <EntityResultWrapper
                                key={key}
                                {...(res as SystemSearchEntityResource)}
                                searchTerm={searchTerm}
                                highlighted={selectedIndex === i}
                                entityId={entityResult.id}
                                index={i}
                                onClick={onClick}
                                setHighlighted={setHighlighted}
                            />
                        );
                    }

                    return null;
                }

                return (
                    <PageResultWrapper
                        key={key}
                        {...res}
                        searchTerm={searchTerm}
                        highlighted={selectedIndex === i}
                        index={i}
                        onClick={onClick}
                        setHighlighted={setHighlighted}
                    />
                );
            })}
        </List>
    </Box>
);

export default SystemSearchResultList;
