import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsMobileView } from 'c-hooks';
import { useBoolean } from 'react-hanger';
import { Box, Collapse, InputAdornment, Typography } from '@mui/material';
import {
    AutoGrid,
    Button,
    EntityAutocomplete,
    OptionSchema,
    SelectField,
    TextField,
} from 'c-components';
import { Translate, useCommonTranslation } from 'c-translation';
import { campaignName } from 'c-main/Lib';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { CreativeType, PermissionName } from '@uniled/api-sdk';
import { CreativeSearchFilterPayload } from 'c-main/Types';
import { useDebouncedCallback } from 'use-debounce';
import { KeyboardArrowDown } from '@mui/icons-material';

type Props = {
    showCampaignFilter: boolean;
    showAgencyFilter: boolean;

    filters: CreativeSearchFilterPayload;
    onFilterChange: (filters: CreativeSearchFilterPayload) => void;
    reset: () => void;
};

const CreativeSearchFilters: React.FC<Props> = ({
    showCampaignFilter,
    showAgencyFilter,
    filters,
    onFilterChange,
    reset,
}) => {
    const isMobile = useIsMobileView();
    const filterOpenState = useBoolean(false);
    const [name, setName] = useState<string | undefined>(filters.name);
    const [selectedCampaign, setSelectedCampaign] = useState<number[]>(filters.campaign);
    const [selectedAgencies, setSelectedAgencies] = useState<number[]>(filters.agencies);
    const [selectedResolutions, setSelectedResolutions] = useState<number[]>(filters.resolutions);
    const [selectedTypes, setSelectedTypes] = useState<CreativeType[]>(filters.creativeTypes);
    // const [selectedClient, setSelectedClient] = useState<number[]>(filters.clients);
    // const [selectedBuyer, setSelectedBuyer] = useState<number[]>(filters.buyers);

    const t = useCommonTranslation();
    const creativeTypeOptions: OptionSchema[] = useMemo(
        () => [
            {
                label: t(`Modules.Main.Creative.SearchPage.creativeTypes.${CreativeType.Image}`),
                value: CreativeType.Image,
            },
            {
                label: t(`Modules.Main.Creative.SearchPage.creativeTypes.${CreativeType.Video}`),
                value: CreativeType.Video,
            },
            {
                label: t(`Modules.Main.Creative.SearchPage.creativeTypes.${CreativeType.File}`),
                value: CreativeType.File,
            },
        ],
        [t],
    );

    const filtersOpen = useMemo(
        () => !isMobile || (isMobile && filterOpenState.value === true),
        [filterOpenState.value, isMobile],
    );

    const memoFilters = useMemo<CreativeSearchFilterPayload>(
        () => ({
            name,
            agencies: selectedAgencies,
            campaign: selectedCampaign,
            resolutions: selectedResolutions,
            creativeTypes: selectedTypes,
        }),
        [selectedAgencies, selectedCampaign, selectedResolutions, selectedTypes, name],
    );

    const delayedUpdate = useDebouncedCallback((callback: () => void) => {
        callback();
    }, 1000);

    useEffect(() => {
        delayedUpdate(() => {
            onFilterChange(memoFilters);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoFilters]);

    const onNameUpdated = useCallback(
        e => {
            setName(e.target.value);
        },
        [setName],
    );

    const onCreativeTypeChange = useCallback(
        e => {
            setSelectedTypes(e.target.value);
        },
        [setSelectedTypes],
    );

    const resetButton = useMemo(
        () => (
            <Button size="small" onClick={reset}>
                <Typography variant="caption">
                    <Translate path="Pagination.resetFilters" />
                </Typography>
            </Button>
        ),
        [reset],
    );

    return (
        <>
            {isMobile && (
                <Box>
                    <Button variant="outlined" onClick={filterOpenState.toggle}>
                        <Translate path="Modules.Main.Creative.SearchPage.filterExpandButtonLabel" />{' '}
                        <KeyboardArrowDown />
                    </Button>
                </Box>
            )}
            <Collapse in={filtersOpen}>
                <AutoGrid spacing={2} xs={12} sm={6} md={4} lg sx={{ mt: 1 }}>
                    <TextField
                        className="creative-name-search"
                        onChange={onNameUpdated}
                        value={name}
                        label={useCommonTranslation(
                            'Modules.Main.Creative.SearchPage.nameSearchLabel',
                        )}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">{resetButton}</InputAdornment>
                            ),
                        }}
                    />
                    {showCampaignFilter && (
                        <IfHasAllPermissions
                            permissions={[PermissionName.UniledportalCampaignRead]}
                        >
                            <EntityAutocomplete
                                className="creative-campaign-filter"
                                onChange={setSelectedCampaign}
                                multiple
                                value={selectedCampaign}
                                entityName="Campaign"
                                textFieldProps={{
                                    label: t(
                                        'Modules.Main.Creative.SearchPage.campaignSearchLabel',
                                    ),
                                }}
                                searchColumns="name"
                                labelColumn="name"
                                labelGen={campaignName}
                                orderByField="id"
                                orderByDirection="desc"
                            />
                        </IfHasAllPermissions>
                    )}
                    {showAgencyFilter && (
                        <IfHasAllPermissions permissions={[PermissionName.UniledportalAgencyRead]}>
                            <EntityAutocomplete
                                onChange={setSelectedAgencies}
                                className="creative-agency-filter"
                                multiple
                                value={selectedAgencies}
                                entityName="Agency"
                                textFieldProps={{
                                    label: t('Modules.Main.Creative.SearchPage.agencySearchLabel'),
                                }}
                                searchColumns="name"
                                labelColumn="name"
                            />
                        </IfHasAllPermissions>
                    )}
                    <IfHasAllPermissions permissions={[PermissionName.UniledportalResolutionRead]}>
                        <EntityAutocomplete
                            className="creative-resolution-filter"
                            onChange={setSelectedResolutions}
                            multiple
                            value={selectedResolutions}
                            entityName="Resolution"
                            textFieldProps={{
                                label: t('Modules.Main.Creative.SearchPage.resolutionSearchLabel'),
                            }}
                            searchColumns="name"
                            labelColumn="name"
                            disableSearchFieldIdFallback
                        />
                    </IfHasAllPermissions>
                    <SelectField
                        className="creative-file-type-filter"
                        onChange={onCreativeTypeChange}
                        multiple
                        label={t('Modules.Main.Creative.SearchPage.creativeTypeSearchLabel')}
                        value={selectedTypes}
                        options={creativeTypeOptions}
                    />
                    {/* <IfHasAllPermissions permissions={[PermissionName.UniledportalClientRead]}> */}
                    {/*    <EntityAutocomplete */}
                    {/*        onChange={setSelectedClient} */}
                    {/*        multiple */}
                    {/*        value={selectedClient} */}
                    {/*        entityName="Client" */}
                    {/*        textFieldProps={{ */}
                    {/*            label: useCommonTranslation( */}
                    {/*                'Modules.Main.Creative.SearchPage.brandSearchLabel', */}
                    {/*            ), */}
                    {/*        }} */}
                    {/*        searchColumn="name" */}
                    {/*        labelColumn="name" */}
                    {/*    /> */}
                    {/* </IfHasAllPermissions> */}
                    {/* <IfHasAllPermissions permissions={[PermissionName.UniledportalBuyerRead]}> */}
                    {/*    <EntityAutocomplete */}
                    {/*        onChange={setSelectedBuyer} */}
                    {/*        multiple */}
                    {/*        value={selectedBuyer} */}
                    {/*        entityName="Buyer" */}
                    {/*        textFieldProps={{ */}
                    {/*            label: useCommonTranslation( */}
                    {/*                'Modules.Main.Creative.SearchPage.buyerSearchLabel', */}
                    {/*            ), */}
                    {/*        }} */}
                    {/*        searchColumn="name" */}
                    {/*        labelColumn="name" */}
                    {/*    /> */}
                    {/* </IfHasAllPermissions> */}
                </AutoGrid>
            </Collapse>
        </>
    );
};

export default CreativeSearchFilters;
