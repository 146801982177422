import React from 'react';
import {
    AutoGrid,
    Checkbox,
    ControlledFormInput,
    EntityAutocomplete,
    TransparentCard,
} from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PermissionName } from '@uniled/api-sdk';
import Card from '@mui/material/Card';
import { Box } from '@mui/material';
import { useEntityData, User } from 'c-data';

type Props = {
    id?: number;
};

const UserAssociationAssignment: React.FC<Props> = ({ id }) => {
    const t = useCommonTranslation();
    const { getById } = useEntityData<User>('User');
    const user = getById({ id });
    return (
        <>
            <Card>
                <Box p={2}>
                    <TransparentCard
                        title={t('Modules.Admin.UserForm.assignAssociations')}
                        titleTypographyProps={{ variant: 'h3' }}
                        subTitle={t('Modules.Admin.UserForm.associationsDescription')}
                    >
                        <AutoGrid spacing={2} xs={12} sm={6} md={4} lg={3}>
                            <IfHasAllPermissions
                                permissions={[
                                    PermissionName.UniledportalBuyerRead,
                                    PermissionName.UniledportalBuyer_userCreate,
                                ]}
                            >
                                <ControlledFormInput
                                    name="buyers"
                                    render={({ field }) => (
                                        <EntityAutocomplete
                                            {...field}
                                            multiple
                                            entityName="Buyer"
                                            textFieldProps={{
                                                label: t('Modules.Admin.UserForm.buyers'),
                                            }}
                                            searchColumns="name"
                                            labelColumn="name"
                                        />
                                    )}
                                />
                            </IfHasAllPermissions>
                            <IfHasAllPermissions
                                permissions={[
                                    PermissionName.UniledportalAgencyRead,
                                    PermissionName.UniledportalAgency_userCreate,
                                ]}
                            >
                                <ControlledFormInput
                                    name="agencies"
                                    render={({ field }) => (
                                        <EntityAutocomplete
                                            {...field}
                                            multiple
                                            entityName="Agency"
                                            textFieldProps={{
                                                label: t('Modules.Admin.UserForm.agencies'),
                                            }}
                                            searchColumns="name"
                                            labelColumn="name"
                                        />
                                    )}
                                />
                            </IfHasAllPermissions>
                            <IfHasAllPermissions
                                permissions={[
                                    PermissionName.UniledportalClientRead,
                                    PermissionName.UniledportalClient_userCreate,
                                ]}
                            >
                                <ControlledFormInput
                                    name="clients"
                                    render={({ field }) => (
                                        <EntityAutocomplete
                                            {...field}
                                            multiple
                                            entityName="Client"
                                            textFieldProps={{
                                                label: t('Modules.Admin.UserForm.brands'),
                                            }}
                                            searchColumns="name"
                                            labelColumn="name"
                                        />
                                    )}
                                />
                            </IfHasAllPermissions>
                            <IfHasAllPermissions
                                permissions={[
                                    PermissionName.UniledportalOwnerRead,
                                    PermissionName.UniledportalOwner_userCreate,
                                ]}
                            >
                                <ControlledFormInput
                                    name="owners"
                                    render={({ field }) => (
                                        <EntityAutocomplete
                                            {...field}
                                            multiple
                                            entityName="Displays_Owner"
                                            textFieldProps={{
                                                label: t('Modules.Admin.UserForm.owners'),
                                            }}
                                            searchColumns="name"
                                            labelColumn="name"
                                        />
                                    )}
                                />
                            </IfHasAllPermissions>
                        </AutoGrid>
                    </TransparentCard>
                </Box>
            </Card>
            <IfHasAllPermissions permissions={[PermissionName.UniledportalRoleRead]}>
                <Box pt={2}>
                    <Card>
                        <Box p={2}>
                            <TransparentCard
                                title={t('Modules.Admin.UserForm.roles')}
                                titleTypographyProps={{ variant: 'h3' }}
                                subTitle={t('Modules.Admin.UserForm.roleDescription')}
                            >
                                <AutoGrid spacing={2} xs={12}>
                                    <ControlledFormInput
                                        name="roles"
                                        render={({ field }) => (
                                            <EntityAutocomplete
                                                {...field}
                                                multiple
                                                entityName="Role"
                                                textFieldProps={{
                                                    label: t('Modules.Admin.UserForm.roles'),
                                                }}
                                                searchColumns="name"
                                                labelColumn="name"
                                            />
                                        )}
                                    />
                                </AutoGrid>
                            </TransparentCard>
                            <IfHasAllPermissions
                                permissions={[PermissionName.Admin]}
                                otherwise={
                                    <Checkbox
                                        isBoolean
                                        label={t('Modules.Admin.UserForm.oldPortalDisabled')}
                                        value={user?.old_portal_disable}
                                        disabled
                                        onChange={() => {}}
                                    />
                                }
                            >
                                <ControlledFormInput
                                    name="old_portal_disable"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            isBoolean
                                            label={t('Modules.Admin.UserForm.oldPortalDisabled')}
                                        />
                                    )}
                                />
                            </IfHasAllPermissions>{' '}
                            <IfHasAllPermissions
                                permissions={[PermissionName.Admin]}
                                otherwise={
                                    <Checkbox
                                        isBoolean
                                        label={t('Modules.Admin.UserForm.profilingEnabled')}
                                        value={user?.profiling}
                                        disabled
                                        onChange={() => {}}
                                    />
                                }
                            >
                                <ControlledFormInput
                                    name="profiling"
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            isBoolean
                                            label={t('Modules.Admin.UserForm.profilingEnabled')}
                                        />
                                    )}
                                />
                            </IfHasAllPermissions>
                        </Box>
                    </Card>
                </Box>
            </IfHasAllPermissions>
        </>
    );
};
export default UserAssociationAssignment;
