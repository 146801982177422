import React, { useCallback, useMemo } from 'react';
import { AutoGrid, Button, ControlledFormInput, RgbaColorPicker, TextField } from 'c-components';
import { useFieldArray, useWatch } from 'react-hook-form';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useCommonTranslation } from 'c-translation';
import { useBoolean } from 'react-hanger';
import { LineChartFormDataSchema } from '../types';

type Props = {
    //
};

const BarChartSegments: React.FC<Props> = () => {
    const { replace } = useFieldArray<LineChartFormDataSchema>({ name: 'segments' });

    const segments = useWatch({ name: 'segments' }) as LineChartFormDataSchema['segments'];

    const onShowAll = useCallback(() => {
        replace([...segments].map(d => ({ ...d, visible: true })));
    }, [replace, segments]);

    const onHideAll = useCallback(() => {
        replace([...segments].map(d => ({ ...d, visible: false })));
    }, [replace, segments]);

    const showHidden = useBoolean(true);
    const onToggleHidden = useCallback(() => {
        showHidden.toggle();
    }, [showHidden]);

    const visibleSegments = useMemo(
        () => (showHidden.value ? segments : segments.filter(d => d.visible)),
        [segments, showHidden.value],
    );

    return (
        <Box>
            <Typography variant="subtitle2">
                {useCommonTranslation('Reporting.exporting.form.segmentsHeader')}
            </Typography>
            <AutoGrid spacing={1}>
                <Button variant="text" onClick={onShowAll}>
                    {useCommonTranslation('Reporting.exporting.form.segmentsShowAll')}
                </Button>
                <Button variant="text" onClick={onHideAll}>
                    {useCommonTranslation('Reporting.exporting.form.segmentsHideAll')}
                </Button>
                <Button variant="text" onClick={onToggleHidden}>
                    {useCommonTranslation('Reporting.exporting.form.segmentsToggleHidden')}{' '}
                    {showHidden.value ? <Visibility /> : <VisibilityOff />}
                </Button>
            </AutoGrid>
            <Grid container rowSpacing={2}>
                {visibleSegments.map((f, i) => (
                    <Grid key={f.originalLabel} item xs={12}>
                        <DataRow index={i} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

type SegmentColProps = {
    index: number;
};

const DataRow: React.FC<SegmentColProps> = ({ index }) => {
    return (
        <AutoGrid spacing={2} xs={4} md={4} lg={2} alignItems="center">
            <ControlledFormInput<LineChartFormDataSchema>
                render={({ field }) => <TextField {...field} variant="standard" size="small" />}
                name={`segments.${index}.label`}
            />
            <AutoGrid spacing={2} alignItems="center">
                <AutoGrid spacing={2} alignItems="center">
                    <ControlledFormInput<LineChartFormDataSchema>
                        render={({ field }) => (
                            <RgbaColorPicker {...field} asDropdown dropdownWidth={30} />
                        )}
                        name={`segments.${index}.color`}
                    />
                </AutoGrid>
                <ControlledFormInput<LineChartFormDataSchema>
                    render={({ field }) => {
                        const isVisible = field.value as boolean;
                        if (isVisible) {
                            return (
                                <IconButton onClick={() => field.onChange(false)}>
                                    <Visibility />
                                </IconButton>
                            );
                        }
                        return (
                            <IconButton onClick={() => field.onChange(true)}>
                                <VisibilityOff />
                            </IconButton>
                        );
                    }}
                    name={`segments.${index}.visible`}
                />
            </AutoGrid>
        </AutoGrid>
    );
};

export default BarChartSegments;
