import { PopDisplays_LogImportSource } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { PopDisplays_LogImportSourceState } from './LogImportSource-types';

const initialState: PopDisplays_LogImportSourceState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: PopDisplays_LogImportSourceState, action: PayloadAction<string>): PopDisplays_LogImportSourceState => state,
};

const slice = createBaseSlice<
    PopDisplays_LogImportSource,
    PopDisplays_LogImportSourceState,
    typeof additionalReducers
>({
    name: 'logImportSource',
    entityName: 'PopDisplays_LogImportSource',

    initialState,
    reducers: additionalReducers,
});

const logImportSourceSlice = {
    logImportSourceActions: slice.slice.actions,
    logImportSourceReducer: slice.slice.reducer,
    logImportSourceAdapter: slice.adapter,
    logImportSourceSelectors: slice.selectors,
    logImportSourceThunks: slice.thunks,
};

export default logImportSourceSlice;
export const {
    logImportSourceReducer,
    logImportSourceActions,
    logImportSourceAdapter,
    logImportSourceSelectors,
    logImportSourceThunks,
} = logImportSourceSlice;
