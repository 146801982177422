import { css } from '@emotion/react';

type Props = {
    rotate: boolean;
    speed?: string;
    rotationAmount?: string;
};

const cssRotation = ({ rotate, speed = '0.5s', rotationAmount = '-180deg' }: Props) =>
    css({
        transition: `${speed} transform`,
        transform: rotate && `rotate(${rotationAmount})`,
    });

export default cssRotation;
