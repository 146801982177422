import { useFormContext } from 'react-hook-form';
import { CampaignFormNames } from 'c-main/Types';
import { useEntityData } from 'c-data';
import { Buyer } from '@uniled/api-sdk';
import { useCommonTranslation } from 'c-translation';
import { usePrevious } from 'react-hanger';
import React, { useEffect, useMemo } from 'react';
import { ControlledFormInput } from 'c-components';
import { BuyerReferenceInput } from 'c-main/Components';

const ReferenceInput = () => {
    const { watch, setValue } = useFormContext();
    const buyerId = watch(CampaignFormNames.buyer);

    const { getById: GetBuyer } = useEntityData<Buyer>('Buyer');
    const referenceLabel = useCommonTranslation('Modules.Main.Campaigns.Create.referenceLabel');

    const buyer = GetBuyer({ id: buyerId });

    const prevBuyerId = usePrevious(buyerId);
    useEffect(() => {
        if (prevBuyerId != null) {
            setValue(CampaignFormNames.reference, '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyerId]);
    const disabled = useMemo(() => buyer == null, [buyer]);
    return (
        <ControlledFormInput
            render={({ field, fieldState }) => (
                <BuyerReferenceInput
                    buyer={buyer}
                    {...field}
                    fieldState={fieldState}
                    label={referenceLabel}
                    disabled={disabled}
                    required
                />
            )}
            name={CampaignFormNames.reference}
        />
    );
};

export default ReferenceInput;
