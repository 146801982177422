import React, { Ref, useMemo } from 'react';
import { TableReportHeader, TableReportProps, TableReportRow } from 'c-reports/Types';
import ErrorImg from 'c-assets/error-triangle.png';
import { Box, TableBody, TableCellProps, TableHead, Typography } from '@mui/material';
import { Button, LoadingSpinner, Table as MuiTable, TableCell, TableRow } from 'c-components';
import { NetworkRequestState } from '@uniled/data-layer';
import { Translate, useCommonTranslation } from 'c-translation';

type Props = TableReportProps<any> & {
    loadingState: NetworkRequestState;
    disableInteraction?: boolean;
    addLoadingBar?: boolean;
    refreshData?: () => void;
};

const disabledSx: TableCellProps['sx'] = { pointerEvents: 'none' };

const RenderStickers = ({ theHeadersStickers, colSx }) => {
    if (theHeadersStickers.length === 0) {
        return null;
    }
    return (
        <TableRow sx={colSx}>
            {theHeadersStickers.map((hs, hsi) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell key={hsi} sx={hs?.content?.props?.sx} {...hs.tableCellProps}>
                    {hs.content}
                </TableCell>
            ))}
        </TableRow>
    );
};
const ReportTableWrapper = (
    {
        headerStickers,
        rows,
        headers,
        loadingState,
        disableInteraction = false,
        dense = false,
        addLoadingBar = false,
        refreshData,
    }: Props,
    ref: Ref<any>,
) => {
    const theHeaders = useMemo<TableReportHeader[]>(
        () => (Array.isArray(headers) ? headers : []),
        [headers],
    );
    const t = useCommonTranslation();
    const theHeadersStickers = useMemo(
        () => (Array.isArray(headerStickers) ? headerStickers : []),
        [headerStickers],
    );
    const actualRows = useMemo<TableReportRow[]>(() => (Array.isArray(rows) ? rows : []), [rows]);
    const noData = useMemo(() => {
        if (loadingState === NetworkRequestState.Error) return true;

        if (loadingState === NetworkRequestState.Success)
            return Array.isArray(actualRows) && actualRows.length === 0;

        return false;
    }, [loadingState, actualRows]);

    const colSx = useMemo(() => (disableInteraction ? disabledSx : {}), [disableInteraction]);

    return (
        <>
            {loadingState === NetworkRequestState.InProgress && addLoadingBar && (
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', zIndex: 1 }}>
                    <LoadingSpinner />
                </Box>
            )}
            {(!addLoadingBar || loadingState === NetworkRequestState.Success) && (
                <MuiTable stickyHeader ref={ref} size={dense ? 'small' : undefined}>
                    <TableHead>
                        <RenderStickers theHeadersStickers={theHeadersStickers} colSx={colSx} />
                        <TableRow sx={colSx}>
                            {theHeaders.map((h, hi) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <TableCell key={hi} {...h.tableCellProps} sx={h.sx}>
                                    {h.content}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {actualRows.map((r, ri) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <TableRow sx={r.sx} key={ri}>
                                {r.content.map((c, ci) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <TableCell key={ci} sx={{ ...colSx, ...c.sx } as any}>
                                        {c.content}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </MuiTable>
            )}
            {noData && (
                <>
                    {refreshData ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            mt="10%"
                            gap={2}
                        >
                            <Box textAlign="center">
                                <img src={ErrorImg} alt="error triangle" height={100} />
                            </Box>
                            <Typography fontWeight={500}>
                                {t('Modules.Main.Campaigns.Overview.Reports.errorTitle')}
                            </Typography>
                            <Typography color="grey.500">
                                {t('Modules.Main.Campaigns.Overview.Reports.errorDescription')}
                            </Typography>
                            <Button onClick={refreshData} variant="text">
                                {t('Modules.Main.Campaigns.Overview.Reports.refresh')}
                            </Button>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                color: 'grey.500',
                                mt: 5,
                            }}
                        >
                            <Typography variant="h2">
                                <Translate path="Reporting.noData" />
                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};

export default React.forwardRef(ReportTableWrapper);
