import React, { useEffect } from 'react';
import { CardContent, Grid, Typography } from '@mui/material';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { PermissionName } from '@uniled/api-sdk';
import { Translate, useCommonTranslation } from 'c-translation';
import { IconLinkButton, TransparentCard } from 'c-components';
import { DashboardTileSize } from 'c-dashboard-module/types';
import RoleListPage from 'c-admin/Pages/RoleListPage';
import SkinListPage from 'c-admin/Pages/SkinListPage';
import UserListPage from 'c-admin/Pages/UserListPage';
import MarketListPage from 'c-admin/Pages/EditMarketPage/MarketListPage';
import ImpersonateUserPage from 'c-admin/Pages/ImpersonateUserPage/ImpersonateUserPage';
import FrameSearchPage from 'c-admin/Pages/FrameSearchPage/FrameSearchPage';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { adminPanelIcon } from 'c-main/icons';
import DashboardTile from 'c-admin/Components/DashboardTile';
import { PopDisplaysAdminTiles } from 'c-pop-displays/Components';
import { DisplaysAdminTiles } from 'c-displays/Components';
import { PopDisplaysHomePage } from 'c-pop-displays/Pages';
import { DisplaysAdminHomePage } from 'c-displays/Pages';
import { ArrowForward } from '@mui/icons-material';
import ReactGA from 'react-ga';
import TaskQueuePage from 'c-admin/Pages/TaskQueuePage/TaskQueuePage';

const AdminHomePage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <TransparentCard title={useCommonTranslation('Modules.Admin.Home.metaTitle')}>
            <CardContent>
                <Grid container gap={4}>
                    <IfHasAllPermissions
                        permissions={SkinListPage.systemSearch.permissions}
                        disableDebugWrap
                    >
                        <DashboardTile
                            id="admin-skins"
                            size={DashboardTileSize.Medium}
                            title={(<Translate path="Navigation.adminSkins" />) as any}
                            titleIcon={SkinListPage.systemSearch.icon}
                            route={SkinListPage.systemSearch.route}
                            permissions={SkinListPage.systemSearch.permissions}
                        />
                    </IfHasAllPermissions>
                    <IfHasAllPermissions
                        permissions={MarketListPage.systemSearch.permissions}
                        disableDebugWrap
                    >
                        <DashboardTile
                            id="admin-markets"
                            size={DashboardTileSize.Medium}
                            title={(<Translate path="Navigation.adminMarkets" />) as any}
                            titleIcon={MarketListPage.systemSearch.icon}
                            route={MarketListPage.systemSearch.route}
                            permissions={MarketListPage.systemSearch.permissions}
                        />
                    </IfHasAllPermissions>
                    <IfHasAllPermissions
                        permissions={UserListPage.systemSearch.permissions}
                        disableDebugWrap
                    >
                        <DashboardTile
                            id="admin-users"
                            size={DashboardTileSize.Medium}
                            title={(<Translate path="Navigation.adminUsers" />) as any}
                            titleIcon={UserListPage.systemSearch.icon}
                            route={UserListPage.systemSearch.route}
                            permissions={UserListPage.systemSearch.permissions}
                        />
                    </IfHasAllPermissions>
                    <IfHasAllPermissions
                        permissions={RoleListPage.systemSearch.permissions}
                        disableDebugWrap
                    >
                        <DashboardTile
                            id="admin-roles"
                            size={DashboardTileSize.Medium}
                            title={(<Translate path="Navigation.adminRoles" />) as any}
                            titleIcon={RoleListPage.systemSearch.icon}
                            route={RoleListPage.systemSearch.route}
                            permissions={RoleListPage.systemSearch.permissions}
                        />
                    </IfHasAllPermissions>
                    <IfHasAllPermissions
                        permissions={ImpersonateUserPage.systemSearch.permissions}
                        disableDebugWrap
                    >
                        <DashboardTile
                            id="admin-impersonate"
                            size={DashboardTileSize.Medium}
                            title={(<Translate path="Navigation.adminImpersonateUser" />) as any}
                            titleIcon={ImpersonateUserPage.systemSearch.icon}
                            route={ImpersonateUserPage.systemSearch.route}
                            permissions={ImpersonateUserPage.systemSearch.permissions}
                        />
                    </IfHasAllPermissions>
                    <IfHasAllPermissions
                        permissions={FrameSearchPage.systemSearch.permissions}
                        disableDebugWrap
                    >
                        <DashboardTile
                            id="admin-frame-search"
                            size={DashboardTileSize.Medium}
                            title={(<Translate path="Navigation.adminFrameSearch" />) as any}
                            titleIcon={FrameSearchPage.systemSearch.icon}
                            route={FrameSearchPage.systemSearch.route}
                            permissions={FrameSearchPage.systemSearch.permissions}
                        />
                    </IfHasAllPermissions>
                    <IfHasAllPermissions
                        permissions={TaskQueuePage.systemSearch.permissions}
                        disableDebugWrap
                    >
                        <DashboardTile
                            id="admin-pop-task-queue"
                            size={DashboardTileSize.Medium}
                            title={(<Translate path="Navigation.popDisplaysTaskQueue" />) as any}
                            titleIcon={TaskQueuePage.systemSearch.icon}
                            route={TaskQueuePage.systemSearch.route}
                            permissions={TaskQueuePage.systemSearch.permissions}
                        />
                    </IfHasAllPermissions>
                </Grid>
                <Typography variant="h3" color="textSecondary" my={2}>
                    {useCommonTranslation('Modules.PopDisplays.adminHomeSectionTitle')}
                    <IconLinkButton to={PopDisplaysHomePage.systemSearch.route}>
                        <ArrowForward fontSize="inherit" color="primary" />
                    </IconLinkButton>
                </Typography>
                <PopDisplaysAdminTiles />
                <Typography variant="h3" color="textSecondary" my={2}>
                    {useCommonTranslation('Modules.Displays.adminHomeSectionTitle')}
                    <IconLinkButton to={DisplaysAdminHomePage.systemSearch.route}>
                        <ArrowForward fontSize="inherit" color="primary" />
                    </IconLinkButton>
                </Typography>
                <DisplaysAdminTiles />
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageConfig = {
    id: 'AdminHomePage',
    component: AdminHomePage,
    systemSearch: {
        title: 'Modules.Admin.Home.metaTitle',
        description: 'Modules.Admin.Home.description',
        icon: adminPanelIcon,
        type: 'page',
        aliases: 'SystemSearch.aliases.adminHomePage',
        route: PostAuthRoutes.Admin.AdminHome,
        saveAsRecentPage: true,
        breadcrumbs: [],
        permissions: [PermissionName.Admin],
    },
};

export default setup;
