import React, { useMemo } from 'react';
import { CardContent, Divider, List, ListItem } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ListItemText, TransparentCard } from 'c-components';
import { Buyer, Campaign } from '@uniled/api-sdk';
import { useCommonTranslation } from 'c-translation';
import { numberOfDays } from 'c-lib';
import { useEntityData } from 'c-data';
import { useDateUtils } from 'c-hooks';
import TotalCost from 'c-main/Components/Campaign/CampaignLineItems/Costing/TotalCost';
import { CampaignFormNames } from 'c-main/Types';
import useSelectedLineItems from '../../CampaignLineItems/CampaignLineItemPicker/useSelectedLineItems';
import SummaryDisplaysDiff from './SummaryDisplaysDiff';

const SummaryStep: React.FC<{ campaign: Campaign }> = ({ campaign }) => {
    const { formatDateString } = useDateUtils();
    const { watch } = useFormContext();
    const days = watch(CampaignFormNames.lineItemDays);
    const startDate = watch(CampaignFormNames.start);
    const endDate = watch(CampaignFormNames.end);
    const noOfDays = useMemo(
        () => numberOfDays(new Date(startDate), new Date(endDate)),
        [startDate, endDate],
    );

    const { value: SelectedLineItems } = useSelectedLineItems();
    const t = useCommonTranslation();

    const buyer = watch(CampaignFormNames.buyer);

    const { getById } = useEntityData<Buyer>('Buyer');
    const buyerEntity = getById({ id: buyer });

    const totalDays = useMemo(
        () =>
            SelectedLineItems.reduce(
                (total, lineItemId) => total + Number(days?.[lineItemId] ?? noOfDays),
                0,
            ),
        [SelectedLineItems, days, noOfDays],
    );

    return (
        <TransparentCard title={null}>
            <CardContent sx={{ py: 0 }}>
                <List>
                    <Block
                        title={useCommonTranslation(
                            'Modules.Main.Campaigns.Create.Summary.campaignDates',
                        )}
                        value={useCommonTranslation(
                            'Modules.Main.Campaigns.Create.Summary.campaignDatesValue',
                            {
                                start: formatDateString({
                                    date: startDate,
                                }),
                                end: formatDateString({
                                    date: endDate,
                                }),
                                days: noOfDays,
                            },
                        )}
                    />
                    <Divider />
                    <SummaryDisplaysDiff />

                    {buyerEntity?.latest_cost_id != null && (
                        <>
                            <ListItem>
                                <TotalCost
                                    hideIfZero
                                    lineItemIds={SelectedLineItems}
                                    showDiff
                                    originalPrice={campaign?.budgetSummary?.total}
                                />
                            </ListItem>
                        </>
                    )}
                </List>
            </CardContent>
        </TransparentCard>
    );
};

const Block: React.FC<{ title: React.ReactNode; value: React.ReactNode }> = ({ title, value }) => (
    <ListItem disableGutters disablePadding>
        <ListItemText primary={title} secondary={value} />
    </ListItem>
);

export default SummaryStep;
