import React, { Ref, useCallback, useMemo } from 'react';
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import { ChartReportProps, PartType } from 'c-reports/Types';
import { useTheme } from '@mui/styles';
import { generateBrush } from 'c-reports/Generators';

type Props = Omit<ChartReportProps<any>, 'onDataUpdated'>;

const ReportComposedChart = (
    {
        data,
        chartProps,
        brushProps,
        parts,
        xAxisDataKey,
        yAxisDataKey,
        renderTooltipLabel,
        yAxisTickFormatter,
        hideLegend,
        hideTooltip,
        hideBrush,
        yAxisProps,
        xAxisProps,
        chartChildren,
    }: Props,
    ref: Ref<any>,
) => {
    const CustomTooltip = useCallback(
        (tooltipProps: TooltipProps<any, any>) => {
            if (
                renderTooltipLabel &&
                tooltipProps.active &&
                tooltipProps.payload &&
                tooltipProps.payload.length
            ) {
                return renderTooltipLabel(tooltipProps);
            }

            return null;
        },
        [renderTooltipLabel],
    );

    const chartParts = useMemo(
        () =>
            parts
                .map(p => {
                    if (p.partType === PartType.Line) {
                        return (
                            <Line
                                key={p.dataKey}
                                type="monotone"
                                dataKey={p.dataKey}
                                {...(p.lineProps as any)}
                            />
                        );
                    }

                    if (p.partType === PartType.Bar) {
                        return <Bar key={p.dataKey} dataKey={p.dataKey} {...(p.barProps as any)} />;
                    }

                    return null;
                })
                .filter(p => p != null),
        [parts],
    );
    const theme = useTheme();

    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                ref={ref}
                data={data}
                margin={{
                    top: 25,
                    right: 50,
                    left: 40,
                    bottom: 5,
                }}
                {...chartProps}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={xAxisDataKey} {...xAxisProps} />
                <YAxis dataKey={yAxisDataKey} tickFormatter={yAxisTickFormatter} {...yAxisProps} />
                {!hideTooltip && (
                    <Tooltip content={renderTooltipLabel ? <CustomTooltip /> : undefined} />
                )}
                {!hideLegend && (
                    <Legend verticalAlign="top" align="right" wrapperStyle={{ top: 5 }} />
                )}

                {!hideBrush &&
                    generateBrush({
                        brushProps,
                        startIndex: 0,
                        // endIndex: data.length - 1,
                        dataKey: xAxisDataKey,
                        theme,
                    })}
                {chartParts}
                {[...(chartChildren ?? [])]}
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default React.forwardRef(ReportComposedChart);
