import React, { useCallback, useMemo, useState } from 'react';
import { CampaignCreativesResponse, Displays_LineItem } from '@uniled/api-sdk';
import { useCommonTranslation } from 'c-translation';
import {
    AutoGrid,
    Checkbox,
    FileBrowserOrderBy,
    FileBrowserOrderByDirection,
    Filterdropdown,
    OptionSchema,
    TextField,
} from 'c-components';
import { creativesToFileBrowserFiles, sortResolutions } from 'c-main/Lib';
import { Box, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';
import {
    filterTaggedFiles,
    generateFileResolution,
    sortFilesBy,
} from 'c-components/FileBrowser/lib';
import { UseListSelection, useListSelection } from 'c-hooks';
import { sortAspectRatios, stringOnlyNumbers } from 'c-lib';
import { uniq } from 'ramda';
import CreativesTable from 'c-main/Components/CreativeManagement/Components/Creatives/CreativesTable';
import LineItemAssignCreativeListItem from './LineItemAssignCreativeListItem';

type Props = {
    // has the permission to assign/unassign
    canAction: boolean;
    lineItem: Displays_LineItem;
    creatives: CampaignCreativesResponse;
    onSelectionUpdated: (ids: number[]) => void;
    selectedCreatives: number[];
    alreadyAssignedCreatives?: number[];

    unAssignMode?: boolean;
};

const emptyAlreadyAssigned = [];

const LineItemCreativeAssignGridTwo: React.FC<Props> = ({
    canAction,
    lineItem,
    creatives,
    onSelectionUpdated,
    selectedCreatives,
    alreadyAssignedCreatives = emptyAlreadyAssigned,
    unAssignMode = false,
}) => {
    const t = useCommonTranslation();
    const files = useMemo(
        () => creativesToFileBrowserFiles(creatives?.creatives ?? []),
        [creatives?.creatives],
    );

    const [search, setSearch] = useState('');
    const [duration, setDuration] = useState('');
    const [resolutions, setResolutions] = useState([]);
    const [ratios, setRatios] = useState([]);
    const [types, setTypes] = useState([]);
    const [groups, setGroups] = useState([]);

    const creativeResolutions = useMemo(
        () => uniq(sortResolutions(files.map(f => generateFileResolution(f)))),
        [files],
    );
    const creativeTypes = useMemo(() => uniq(files.map(f => f.fileTypeSimple)), [files]);
    const creativeRatios = useMemo(
        () => uniq(files.map(f => f.aspectRatio)).sort(sortAspectRatios),
        [files],
    );

    const groupOptions = useMemo<OptionSchema[]>(
        () => uniq(creatives.creativeGroups.map(group => ({ label: group.name, value: group.id }))),
        [creatives.creativeGroups],
    );
    const typeOptions = useMemo<OptionSchema[]>(
        () => creativeTypes.map(type => ({ label: type, value: type })),
        [creativeTypes],
    );
    const resolutionOptions = useMemo<OptionSchema[]>(
        () => creativeResolutions.map(res => ({ label: res, value: res })),
        [creativeResolutions],
    );
    const ratiosOptions = useMemo<OptionSchema[]>(
        () => uniq(creativeRatios.map(ratio => ({ label: ratio, value: ratio }))),
        [creativeRatios],
    );

    const onSearchChange = useCallback(e => {
        setSearch(e.target.value);
    }, []);
    const onDurationChange = useCallback(e => {
        setDuration(e.target.value);
    }, []);

    const [orderByDirection, setOrderByDirection] = useState<FileBrowserOrderByDirection>('desc');
    const [orderByField, setOrderByField] = useState<FileBrowserOrderBy>(
        FileBrowserOrderBy.UploadDate,
    );

    const onOrderBy = useCallback(
        (field: FileBrowserOrderBy, direction: FileBrowserOrderByDirection) => {
            setOrderByField(field);
            setOrderByDirection(direction);
        },
        [],
    );

    const visibleFiles = useMemo(() => {
        const filteredFilesIds = filterTaggedFiles(files, {
            term: search,
            fileTypes: types,
            resolutions,
            aspectRatios: ratios,
            groupIds: groups,
            duration: stringOnlyNumbers(duration) ? Number(duration) : null,
        });

        return sortFilesBy(
            files.filter(f => filteredFilesIds.indexOf(f.id) !== -1),
            orderByField,
            orderByDirection,
        );
    }, [
        files,
        orderByDirection,
        orderByField,
        ratios,
        resolutions,
        search,
        types,
        groups,
        duration,
    ]);

    const selectableIds = useMemo(() => {
        // when not unassigning, you can't select already assigned creatives
        if (!unAssignMode)
            return files
                .map(f => f.downloadId)
                .filter(id => alreadyAssignedCreatives.indexOf(id) === -1);

        return files.map(f => f.downloadId);
    }, [unAssignMode, files, alreadyAssignedCreatives]);

    const listSelectionProps = useMemo<UseListSelection>(
        () => ({
            itemIds: files.map(f => f.downloadId).filter(id => selectableIds.indexOf(id) !== -1),
            visibleItemIds: visibleFiles
                .map(f => f.downloadId)
                .filter(id => selectableIds.indexOf(id) !== -1),

            onChange: onSelectionUpdated as any,
            value: selectedCreatives,
        }),
        [files, onSelectionUpdated, selectedCreatives, visibleFiles, selectableIds],
    );

    const {
        onSelectAllToggle,
        onItemToggled,
        selectedIds,
        selectAllChecked,
        selectAllIndeterminate,
    } = useListSelection(listSelectionProps);

    const selectAllCheckbox = useMemo(
        () => (
            <Checkbox
                onClick={onSelectAllToggle}
                checked={selectAllChecked}
                indeterminate={selectAllIndeterminate}
                label=""
                className="creatives-select-all"
                formControlLabelSx={{ mr: 0 }}
            />
        ),
        [onSelectAllToggle, selectAllChecked, selectAllIndeterminate],
    );

    const memoListItems = useMemo(
        () =>
            visibleFiles.map(f => (
                <React.Fragment key={f.id}>
                    <LineItemAssignCreativeListItem
                        canAction={canAction}
                        file={f}
                        isSelected={selectedIds.indexOf(f.downloadId) !== -1}
                        onCheckboxChange={e => onItemToggled(f.downloadId, e)}
                        alreadyAssigned={alreadyAssignedCreatives.indexOf(f.downloadId) !== -1}
                        lineItem={lineItem}
                        unAssignMode={unAssignMode}
                        groups={creatives.creativeGroups.filter(
                            group => f.groupIds.indexOf(group.id) !== -1,
                        )}
                    />
                </React.Fragment>
            )),
        [
            canAction,
            visibleFiles,
            selectedIds,
            alreadyAssignedCreatives,
            lineItem,
            unAssignMode,
            creatives.creativeGroups,
            onItemToggled,
        ],
    );

    return (
        <Box flex={1} height="100%" display="flex" flexDirection="column" overflow="hidden">
            <Box mt={1}>
                <AutoGrid gap={2} xs={12} md={4} lg>
                    <TextField
                        value={search}
                        size="small"
                        variant="outlined"
                        onChange={onSearchChange}
                        placeholder={t(
                            'Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.searchLabel',
                        )}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search fontSize="inherit" />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Filterdropdown
                        selectAllOption
                        multiple
                        options={groupOptions}
                        onChange={setGroups}
                        value={groups}
                        placeholder={t(
                            'Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.groupDropdownLabel',
                        )}
                    />
                    <Filterdropdown
                        selectAllOption
                        multiple
                        options={typeOptions}
                        onChange={setTypes}
                        value={types}
                        placeholder={t(
                            'Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.typeDropdownLabel',
                        )}
                    />
                    <TextField
                        value={duration}
                        size="small"
                        variant="outlined"
                        type="number"
                        onChange={onDurationChange}
                        placeholder={t(
                            'Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.durationLabel',
                        )}
                    />
                    <Filterdropdown
                        selectAllOption
                        multiple
                        options={resolutionOptions}
                        onChange={setResolutions}
                        value={resolutions}
                        placeholder={t(
                            'Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.resolutionDropdownLabel',
                        )}
                    />
                    <Filterdropdown
                        selectAllOption
                        multiple
                        options={ratiosOptions}
                        onChange={setRatios}
                        value={ratios}
                        placeholder={t(
                            'Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.ratioDropdownLabel',
                        )}
                    />
                </AutoGrid>
            </Box>
            <Box flex={1} overflow="hidden" display="flex">
                <CreativesTable
                    id="assign-creatives-table"
                    files={files}
                    selectedCreatives={selectedCreatives}
                    selectAllFiles={selectAllCheckbox}
                    rows={memoListItems}
                    orderByField={orderByField}
                    direction={orderByDirection}
                    onOrderBy={onOrderBy}
                    hideSelectColumn={!canAction}
                    hideLiveDate
                    hideEndDate
                    hideActions
                />
            </Box>
        </Box>
    );
};

export default LineItemCreativeAssignGridTwo;
