import React, { useCallback, useEffect, useMemo } from 'react';
import { alpha } from '@mui/material';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { CampaignRagStatus, PermissionName, Campaign } from '@uniled/api-sdk';
import { dataStatusIcon } from 'c-main/icons';
import {
    FilterableEntityTable,
    FilterableEntityTableRendererType,
    generateColumn,
    RenderRowProps,
} from 'c-pagination';
import {
    CampaignBookingSummaryValidatedFilterConfig,
    CampaignEndDateConfig,
    CampaignHighPriorityFilterConfig,
    CampaignMediaOwnersConfig,
    CampaignPlayoutPercentConfig,
    CampaignScopes,
    CampaignSignOffFilterConfig,
    CampaignStartDateConfig,
    CampaignStatusFilterConfigDataStatusPage,
    DataStatusPaginationTag,
} from 'c-main/entityColumns';
import {
    CampaignBookingSummaryTableCell,
    CampaignDataStatusReportingCell,
    CampaignHighPriorityCell,
    CampaignNotesCell,
    CampaignNoticesCell,
    CampaignPlannerAwareCell,
    CampaignRagCell,
    CampaignRefRedirectCell,
    CampaignSignOffCell,
    CampaignStatusTableCell,
    CampaignUserCell,
} from 'c-main/Components/Campaign/CampaignTableCells';
import AdditionalUsers from 'c-main/Components/Campaign/CampaignTableCells/AdditionalUsers/AdditionalUsers';
import { useEntityData } from 'c-data';
import { useTheme } from '@mui/styles';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import ReactGA from 'react-ga';

const columns = [
    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.idCol',
        field: 'id',
        entityName: 'Campaign',
        orderable: true,
    }),
    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.referenceCol',
        field: 'reference',
        entityName: 'Campaign',
        orderable: true,
        RenderCell: CampaignRefRedirectCell,
        contentWrapperSx: { wordBreak: 'break-word' },
    }),
    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.nameCol',
        field: 'name',
        entityName: 'Campaign',
        orderable: true,
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.Create.additionalContactsDropdownLabel',
        field: 'additionalContacts',
        entityName: 'Campaign',
        orderable: true,
        RenderCell: AdditionalUsers,
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.DataStatus.table.bookerCol',
        field: 'user.email',
        entityName: 'Campaign',
        permissions: [PermissionName.UniledportalUserRead],
        contentWrapperSx: { wordBreak: 'break-all' },
        wrapContentInTooltip: true,
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.buyerCol',
        field: 'buyer.name',
        entityName: 'Campaign',
        orderable: true,
        orderByKey: 'buyer.name',
        filterKeyPath: 'buyer.id',
        permissions: [PermissionName.UniledportalBuyerRead],
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.statusCol',
        field: 'status',
        entityName: 'Campaign',
        RenderCell: CampaignStatusTableCell,
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.startDateCol',
        field: 'date.date_start',
        entityName: 'Campaign',
        dataType: 'date',
        orderable: true,
        orderByKey: 'date.date_start',
        permissions: [PermissionName.UniledportalDateRead],
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.endDateCol',
        field: 'date.date_end',
        entityName: 'Campaign',
        dataType: 'date',
        orderable: true,
        orderByKey: 'date.date_end',
        permissions: [PermissionName.UniledportalDateRead],
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.DataStatus.table.campaignOwnerCol',
        field: 'internal_owner_id',
        entityName: 'Campaign',
        permissions: [PermissionName.UniledportalUserRead],
        RenderCell: props => <CampaignUserCell {...props} filterKey="filter.internalAssignee.id" />,
    }),
    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.DataStatus.table.campaignAssigneeCol',
        field: 'internal_assignee_id',
        entityName: 'Campaign',
        permissions: [PermissionName.UniledportalUserRead],
        RenderCell: props => <CampaignUserCell {...props} filterKey="filter.internalOwner.id" />,
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.DataStatus.table.bookingSummaryCol',
        field: 'booking_summaries',
        entityName: 'Campaign',
        RenderCell: CampaignBookingSummaryTableCell,
        permissions: [PermissionName.UniledportalCampaign_booking_summariesRead],
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.DataStatus.table.signOffCol',
        field: 'sign_off',
        entityName: 'Campaign',
        RenderCell: CampaignSignOffCell,
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.DataStatus.table.highPriorityCol',
        field: 'high_priority',
        entityName: 'Campaign',
        RenderCell: CampaignHighPriorityCell,
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.DataStatus.table.notesCol',
        field: 'internal_notes',
        entityName: 'Campaign',
        RenderCell: CampaignNotesCell,
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.DataStatus.table.plannerAwareCol',
        field: 'internal_planner_aware',
        entityName: 'Campaign',
        RenderCell: CampaignPlannerAwareCell,
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.DataStatus.table.ragCol',
        field: 'internal_rag_status',
        entityName: 'Campaign',
        RenderCell: CampaignRagCell,
        permissions: [PermissionName.Admin],
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.DataStatus.table.reportingCol',
        field: 'owners',
        orderable: true,
        orderByKey: 'playout_percentage',
        entityName: 'Campaign',
        RenderCell: CampaignDataStatusReportingCell,
    }),

    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.DataStatus.table.dataStatusCol',
        field: 'notices',
        entityName: 'Campaign',
        RenderCell: CampaignNoticesCell,
    }),
];

// const defaultFilters = { 'filter.displays.notices': ['!=0'] };
const includes = [
    'notices',
    'user',
    'internalAssignee',
    'internalOwner',
    'date',
    'owners',
    'owners.owner',
    'bookingSummaries',
    'signOffUser',
    'bookingSummaryValidatedUser',
    'internalNotesUser',
    'additionalContacts',
    'missingDisplaysCount',
    // 'ownerContactedDates',
    // 'ownerContactedDates.user',
];
const textSearchCols = ['name', 'reference'];
// const includeFilters = ['filter.internalOwner.id', 'filter.internalAssignee.id'];
const customFilterConfig = [
    CampaignStatusFilterConfigDataStatusPage,
    CampaignMediaOwnersConfig,
    CampaignStartDateConfig,
    CampaignEndDateConfig,
    CampaignPlayoutPercentConfig,
    CampaignSignOffFilterConfig,
    CampaignBookingSummaryValidatedFilterConfig,
    CampaignHighPriorityFilterConfig,
];

const colors = {
    [CampaignRagStatus.Green]: 'success',
    [CampaignRagStatus.Amber]: 'warning',
    [CampaignRagStatus.Red]: 'error',
};

const DataStatusPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { keyedEntities } = useEntityData<Campaign>('Campaign');
    const theme = useTheme();

    const generateSx = useCallback<RenderRowProps<any>['generateSx']>(
        (row, id) => {
            const color = theme.palette[colors[keyedEntities?.[id]?.internal_rag_status]]?.light;
            if (!color) return {};
            return {
                bgcolor: alpha(color as string, 0.25),
            };
        },
        [keyedEntities, theme],
    );
    return (
        <>
            <FullPageEntityTableWrapper title="Modules.Main.Campaigns.DataStatus.metaTitle">
                <FilterableEntityTable
                    tag={DataStatusPaginationTag}
                    baseEntityName="Campaign"
                    resetOnUnmount={false}
                    // routeTemplate={PostAuthRoutes.Campaigns.CampaignOverview}
                    textSearchColumns={textSearchCols}
                    columns={columns}
                    includes={includes}
                    // hide archived by default
                    filters={useMemo(
                        () => ({ [CampaignScopes.scopeKey]: [CampaignScopes.notArchived] }),
                        [],
                    )}
                    // onlyIncludeFilterKeys={includeFilters}
                    customFilterConfig={customFilterConfig}
                    generateRowSx={generateSx}
                    showFilters
                    rendererType={FilterableEntityTableRendererType.Contained}
                    filterApplyButton
                    // wrapInTableContainer={false}
                    // dense
                />
            </FullPageEntityTableWrapper>
        </>
    );
};

const setup: PageConfig = {
    id: 'DataStatusPage',
    component: DataStatusPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        title: 'Modules.Main.Campaigns.DataStatus.metaTitle',
        description: 'Modules.Main.Campaigns.DataStatus.description',
        icon: dataStatusIcon,
        type: 'page',
        aliases: 'SystemSearch.aliases.dataStatusPage',
        route: PostAuthRoutes.Campaigns.DataStatus,
        saveAsRecentPage: true,
        breadcrumbs: [],
        permissions: [PermissionName.AdminCan_set_display_notices],
    },
};

export default setup;
