import { Box, Popover, Stack, TableCell, TableRow } from '@mui/material';
import React, { useMemo, useState } from 'react';

import { getTimeRanges } from 'c-main/Components/Schedule/lib';
import { createDateRangeArray } from './GantChartHeaders';

const GanttChartBar = ({ startDate, endDate, row, t }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [hoverSchedule, setHoverSchedule] = useState(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, schedule) => {
        setAnchorEl(event.currentTarget);
        setHoverSchedule(schedule);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
        setHoverSchedule(null);
    };
    const scheduleColors = useMemo(
        () =>
            row.schedules.reduce((acc, schedule) => {
                acc[schedule.id] = stringToColor(schedule); // Generate color based on the schedule object
                return acc;
            }, {}),
        [row.schedules],
    );
    if (row?.schedules?.length === 0) {
        return null;
    }

    const dateArray = createDateRangeArray(new Date(startDate), new Date(endDate));

    const schedules = row?.schedules;

    return (
        <Stack gap={1} pt={1} pb={1}>
            {schedules.map(schedule => (
                <TableRow
                    key={schedule.id}
                    style={{ paddingLeft: '2.2rem' }}
                    onMouseEnter={e => handlePopoverOpen(e, schedule)}
                    onMouseLeave={handlePopoverClose}
                >
                    {dateArray.map((date, i) => {
                        const active = isDateInSchedule(date, schedule, startDate, endDate);
                        const color = scheduleColors[schedule.id];
                        const previousDay = new Date(date);
                        previousDay.setDate(date.getDate() - 1);
                        const nextDay = new Date(date);
                        nextDay.setDate(date.getDate() + 1);
                        const isStartOfSchedule = !isDateInSchedule(
                            previousDay,
                            schedule,
                            startDate,
                            endDate,
                        );
                        const isPreviousDayActive = isDateInSchedule(
                            previousDay,
                            schedule,
                            startDate,
                            endDate,
                            true,
                        );
                        const isNextDayActive = isDateInSchedule(
                            nextDay,
                            schedule,
                            startDate,
                            endDate,
                            true,
                        );
                        return (
                            <TableCell
                                key={date}
                                style={{
                                    backgroundColor: active ? color : 'transparent',
                                    color: active ? 'white' : 'black',
                                    width: '3.947rem',
                                    paddingLeft: '1rem',
                                    paddingRight: '1rem',
                                    paddingBottom: '7px',
                                    paddingTop: '7px',
                                    borderBottom: 'none',
                                    position: 'relative', // Ensure position context for the absolute positioning of the label
                                    borderTopLeftRadius: !isPreviousDayActive
                                        ? active
                                            ? '15px'
                                            : '0'
                                        : '0',
                                    borderBottomLeftRadius: !isPreviousDayActive
                                        ? active
                                            ? '15px'
                                            : '0'
                                        : '0',
                                    borderTopRightRadius: !isNextDayActive
                                        ? active
                                            ? '15px'
                                            : '0'
                                        : '0',
                                    borderBottomRightRadius: !isNextDayActive
                                        ? active
                                            ? '15px'
                                            : '0'
                                        : '0',
                                }}
                            >
                                {isStartOfSchedule && active && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '-20px',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            whiteSpace: 'nowrap',
                                            zIndex: 10, // Ensure this is above all other styled elements
                                            background: color, // Optional: add background to the label for better readability
                                            color: 'white',
                                            padding: '0 5px',
                                            borderRadius: '10px',
                                        }}
                                    >
                                        {schedule.name}
                                    </Box>
                                )}
                            </TableCell>
                        );
                    })}
                </TableRow>
            ))}
            {hoverSchedule && (
                <Popover
                    id={`mouse-pop-over-${row.name}-${hoverSchedule?.name}`}
                    sx={{
                        pointerEvents: 'none',
                        maxWidth: '1200px',
                    }}
                    open
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Box>
                        <Stack gap={2} p={2}>
                            <Box>
                                {t(
                                    'Modules.Main.Campaigns.CampaignSchedule.ganttPopup.scheduleName',
                                )}
                                : {hoverSchedule?.name}
                            </Box>
                            <Box>
                                {t('Modules.Main.Campaigns.CampaignSchedule.ganttPopup.hours')}:{' '}
                                {listActiveHours(hoverSchedule.rules[0].hours_of_day)}
                            </Box>
                            <Box>
                                {t('Modules.Main.Campaigns.CampaignSchedule.ganttPopup.days')}:{' '}
                                {listActiveDays(hoverSchedule.rules[0].days_of_week)}
                            </Box>
                            <Box>
                                {t('Modules.Main.Campaigns.CampaignSchedule.ganttPopup.dateRange')}:{' '}
                                {`${hoverSchedule.rules[0].start_date} -> ${hoverSchedule.rules[0].end_date}`}
                            </Box>
                        </Stack>
                    </Box>
                </Popover>
            )}
        </Stack>
    );
};

export default GanttChartBar;

const formatDayName = date => date.toLocaleDateString('en-GB', { weekday: 'long' }).toLowerCase();
const listActiveHours = hours => getTimeRanges(hours);

const listActiveDays = days => days.join(', ');
const isDateInSchedule = (date, schedule, startDate, endDate, prevNext = false) => {
    const dayName = formatDayName(date);
    const rule = schedule?.rules?.[0];

    const scheduleStartDate = new Date(rule?.start_date);
    scheduleStartDate?.setHours(0, 0, 0, 0);
    const scheduleEndDate = new Date(rule?.end_date);
    scheduleEndDate?.setHours(0, 0, 0, 0);

    const checkDate = new Date(date);
    checkDate?.setHours(0, 0, 0, 0);

    const isDateInRange = checkDate >= scheduleStartDate && checkDate <= scheduleEndDate;

    const isDayActive = rule?.days_of_week?.includes(dayName);

    if (!prevNext) {
        return isDateInRange && isDayActive;
    }

    const overallStartDate = new Date(startDate);
    overallStartDate?.setHours(0, 0, 0, 0);
    const overallEndDate = new Date(endDate);
    overallEndDate?.setHours(0, 0, 0, 0);

    const isOverallDateInRange = checkDate >= overallStartDate && checkDate <= overallEndDate;

    return isOverallDateInRange && isDateInRange && isDayActive;
};
function stringToColor(schedule) {
    const str = `${schedule?.id}-${schedule?.name}-${schedule?.campaign_id}-${schedule?.created_at}`;
    let hash = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < str.length; i++) {
        // eslint-disable-next-line no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 3; i++) {
        // eslint-disable-next-line no-bitwise
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    return color;
}
