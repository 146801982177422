import React, { Ref, useMemo } from 'react';
import MaskedTextField, {
    MaskedTextFieldProps,
} from 'c-components/Forms/TextField/MaskedTextField';
import { TextField } from 'c-components';
import { CustomTextFieldProps } from 'c-components/Forms/TextField/TextField';
import { BuyerReferenceType, Buyer } from '@uniled/api-sdk';

type Props = {
    buyer?: Buyer;
    value?: string;
} & Partial<Omit<MaskedTextFieldProps, 'prefix' | 'maxLength' | 'type' | 'value'>>;

const BuyerReferenceInput = ({ buyer, fieldState, ...rest }: Props, ref: Ref<any>) => {
    const showMaskedInput = useMemo(() => {
        if (
            buyer?.reference_type === BuyerReferenceType.Any &&
            buyer?.reference_max_length == null &&
            buyer?.reference_min_length == null &&
            buyer?.reference_prefix == null
        ) {
            return false;
        }

        return (
            buyer?.reference_min_length != null ||
            buyer?.reference_max_length != null ||
            buyer?.reference_prefix?.length > 0 ||
            buyer?.reference_type?.length > 0
        );
    }, [buyer]);

    if (!showMaskedInput) {
        return <TextField {...(rest as unknown as CustomTextFieldProps)} />;
    }
    return (
        <MaskedTextField
            ref={ref}
            prefix={buyer.reference_prefix ?? ''}
            type={buyer.reference_type}
            maxLength={buyer.reference_max_length ?? 50}
            fieldState={fieldState}
            {...rest}
        />
    );
};

export default React.forwardRef(BuyerReferenceInput);
