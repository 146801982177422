/* eslint-disable no-param-reassign */
import { ReactNode } from 'react';
import { Config } from 'c-config';

import {
    ParsedUnTranslatedSystemSearchConfiguration,
    SystemSearchResourceOrEntity,
    UnTranslatedSystemSearchResourceOrEntity,
} from '../types';

const systemSearchData = (
    resources: UnTranslatedSystemSearchResourceOrEntity<SystemSearchResourceOrEntity>[],
    moduleIcon?: ReactNode,
): {
    prefixes: ParsedUnTranslatedSystemSearchConfiguration['prefixes'];
    aliases: ParsedUnTranslatedSystemSearchConfiguration['aliases'];
} => {
    const prefixes: ParsedUnTranslatedSystemSearchConfiguration['prefixes'] = {
        page: [],
        widgets: [],
    };
    const aliases: ParsedUnTranslatedSystemSearchConfiguration['aliases'] = {};

    resources.forEach(resource => {
        if (resource.excludeFromSystemSearch !== true) {
            if (resource.icon == null && moduleIcon != null) {
                // override with module's icon if one isn't set for the page
                // eslint-disable-next-line no-param-reassign
                resource.icon = moduleIcon;
            }
            if (resource.prefix != null) {
                // const shortcutLower = resource.prefix.toLowerCase();
                prefixes[resource.prefix] = prefixes[resource.prefix] ?? [];
                prefixes[resource.prefix].push(resource);
            }

            if (resource.type === 'page') {
                prefixes.page.push(resource);
            }

            if (resource.type === 'widget') {
                prefixes.widgets.push(resource);
            }

            if (resource.aliases) {
                // resource.aliases.forEach(alias => {
                //     const aliasLower = alias.toLowerCase();
                if (!aliases[resource.aliases]) {
                    aliases[resource.aliases] = [];
                }
                aliases[resource.aliases].push(resource);
                // });
            }
        }
    });
    return { prefixes, aliases };
};

const mergeSystemSearchConfig = (config: Config, config2Merge: Config) => {
    const moduleIcon = config2Merge.systemSearch.moduleIcon;
    const { aliases, prefixes } = systemSearchData(
        config2Merge.pages.map(p => p.systemSearch),
        // .concat((config2Merge.widgets ?? []).map(w => w.systemSearch)),
        moduleIcon,
    );
    if (!config.systemSearchParsed) {
        config.systemSearchParsed = { aliases: {}, prefixes: {} };
    }
    Object.keys(aliases).forEach(key => {
        if (!config.systemSearchParsed.aliases[key]) {
            config.systemSearchParsed.aliases[key] = [];
        }
        aliases[key].forEach(r => {
            config.systemSearchParsed.aliases[key].push(r);
        });
    });
    Object.keys(prefixes).forEach(key => {
        if (!config.systemSearchParsed.prefixes[key]) {
            config.systemSearchParsed.prefixes[key] = [];
        }
        prefixes[key].forEach(r => {
            config.systemSearchParsed.prefixes[key].push(r);
        });
    });
    return config;
};

export default mergeSystemSearchConfig;
