import { User } from 'c-entity-types';
import { baseInitialState, createBaseSlice } from 'c-data';
import { FakeUsers } from 'c-fake-entities';
import { UserState } from './user-types';

const initialState: UserState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: UserState, action: PayloadAction<string>): UserState => state,
};

const slice = createBaseSlice<User, UserState, typeof additionalReducers>(
    {
        name: 'user',
        entityName: 'User',
        initialState,
        reducers: additionalReducers,
    },
    FakeUsers,
);

const userSlice = {
    userActions: slice.slice.actions,
    userReducer: slice.slice.reducer,
    userAdapter: slice.adapter,
    userSelectors: slice.selectors,
    userThunks: slice.thunks,
};

export default userSlice;
export const { userReducer, userActions, userAdapter, userSelectors, userThunks } = userSlice;
