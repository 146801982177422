import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { PermissionName, Buyer } from '@uniled/api-sdk';
import { buyerIcon } from 'c-main/icons';
import {
    FilterableEntityTable,
    FilterableEntityTableRefAPI,
    FilterableEntityTableRendererType,
    generateColumn,
} from 'c-pagination';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import {
    AllEntitiesDropdown,
    ControlledFormInput,
    CreateEntityButton,
    TextField,
} from 'c-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCommonTranslation } from 'c-translation';
import { Stack } from '@mui/material';
import * as yup from 'yup';
import ReactGA from 'react-ga';

const paginationTag = 'buyer-list-home-search';

const columns = [
    generateColumn<Buyer>({
        headerTitle: 'Modules.Main.Buyer.List.table.idCol',
        field: 'id',
        entityName: 'Buyer',
        orderable: true,
    }),
    generateColumn<Buyer>({
        headerTitle: 'Modules.Main.Buyer.List.table.nameCol',
        field: 'name',
        entityName: 'Buyer',
        orderable: true,
    }),
    generateColumn<Buyer>({
        headerTitle: 'Modules.Main.Buyer.List.table.campaignCount',
        field: 'campaignsCount',
        entityName: 'Buyer',
        orderable: true,
        permissions: [PermissionName.UniledportalCampaignRead],
    }),
    generateColumn<Buyer>({
        headerTitle: 'Modules.Main.Buyer.List.table.marketCol',
        field: 'market.name',
        entityName: 'Buyer',
        filterKeyPath: 'market.id',
        permissions: [PermissionName.UniledportalMarketRead],
    }),
];

const includes = ['campaignsCount', 'market'];
const textSearchCols = ['name'];
const schema = yup.object({
    name: yup.string().required(),
    market_id: yup.number().required(),
});

const BuyerListPage = () => {
    useEffect(() => {
        // Track a page view
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const ref = useRef<FilterableEntityTableRefAPI>();
    const onBuyerCreated = useCallback(() => {
        ref?.current?.search();
    }, []);
    const t = useCommonTranslation();
    return (
        <FullPageEntityTableWrapper
            title="Modules.Main.Buyer.List.listTitle"
            controls={useMemo(
                () => [
                    <CreateEntityButton<Buyer>
                        key="new-buyer-btn"
                        entityName="Buyer"
                        title="Modules.Main.Buyer.List.newBuyerButton"
                        buttonLabel={t('Modules.Main.Buyer.List.newBuyerButton')}
                        submitLabel="Modules.Main.Buyer.List.newBuyerSubmit"
                        form={<NewBuyerForm />}
                        formOptions={{
                            resolver: yupResolver(schema),
                        }}
                        permissions={[PermissionName.UniledportalBuyerCreate]}
                        redirectPath={PostAuthRoutes.Buyer.BuyerOverview}
                        onSuccess={onBuyerCreated}
                    />,
                ],
                [onBuyerCreated, t],
            )}
        >
            <FilterableEntityTable
                ref={ref}
                key={paginationTag}
                tag={paginationTag}
                columns={columns}
                baseEntityName="Buyer"
                includes={includes}
                showFilters
                routeTemplate={PostAuthRoutes.Buyer.BuyerOverview}
                textSearchColumns={textSearchCols}
                rendererType={FilterableEntityTableRendererType.Contained}
            />
        </FullPageEntityTableWrapper>
    );
};

const NewBuyerForm = () => {
    const t = useCommonTranslation();
    return (
        <Stack gap={2}>
            <ControlledFormInput
                render={({ field }) => (
                    <TextField
                        label={t('Modules.Main.Buyer.List.create.nameLabel')}
                        {...field}
                        autoFocus
                    />
                )}
                name="name"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <AllEntitiesDropdown
                        entityName="Market"
                        labelField="name"
                        label={t('Modules.Main.Buyer.List.create.marketLabel')}
                        {...field}
                    />
                )}
                name="market_id"
            />
        </Stack>
    );
};

const setup: PageConfig = {
    id: 'BuyerListPage',
    component: BuyerListPage,
    disableDesktopContentScrolling: true,
    systemSearch: {
        icon: buyerIcon,
        title: 'Modules.Main.Buyer.List.metaTitle',
        description: 'Modules.Main.Buyer.List.description',
        type: 'page',
        aliases: 'SystemSearch.aliases.buyerListPage',
        route: PostAuthRoutes.Buyer.BuyerList,
        saveAsRecentPage: true,
        breadcrumbs: [],
        permissions: [
            PermissionName.UniledportalBuyerRead,
            PermissionName.UniledportalBuyerCreate,
            PermissionName.UniledportalBuyerUpdate,
        ],
    },
};

export default setup;
