import React, { useMemo } from 'react';
import { CampaignPopShot } from '@uniled/api-sdk';
import { popShotToFile } from 'c-main/Lib';
import RawFilePreview from 'c-components/FileBrowser/RawFilePreview';

type Props = {
    //
    popShot: CampaignPopShot;
};

const imgStyles = { borderRadius: '4px', height: 35, width: 35 };
const CampaignPopShotThumbnail: React.FC<Props> = ({ popShot }) => {
    const file = useMemo(() => popShotToFile(popShot), [popShot]);
    return (
        <RawFilePreview
            file={file}
            maxHeight={35}
            maxWidth={35}
            showVideoPreview={false}
            preferredSize="medium"
            imgStyles={imgStyles}
        />
    );
};

export default CampaignPopShotThumbnail;
