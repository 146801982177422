import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useCommonTranslation } from 'c-translation';
import {
    Alert,
    AllEntitiesDropdown,
    AutoGrid,
    Button,
    ControlledFormInput,
    SelectField,
    TextAreaField,
    TextField,
} from 'c-components';
import { Controller, useFieldArray } from 'react-hook-form';
import { Box, FormLabel, IconButton, Stack } from '@mui/material';

import { NetworkRequestState } from '@uniled/data-layer';
import { Redirect } from 'react-router-dom';
import { PostAuthRoutes } from 'c-routes';
import { DisplayPackType, Displays_ScreenAnimationType } from '@uniled/api-sdk';
import { useBrochureEditHandling } from 'c-displays/Pages/utils';
import { useBoolean } from 'react-hanger';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

export const DisplayPackForm = ({ control, state, error, id, setFormValue, getValues, watch }) => {
    const dialogState = useBoolean(false);
    const delDialogState = useBoolean(false);
    const t = useCommonTranslation();
    const { append, fields, remove } = useFieldArray({
        control,
        name: 'resolutions',
    });
    const market = watch('market_id');
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            setFormValue('owner_id', null);
        }
    }, [market, setFormValue]);
    useEffect(() => {
        if (market == null) {
            setFormValue('market_id', null);
        }
    }, [market, setFormValue]);
    const brochureDownload = getValues('brochure');
    const SubmitButton = useMemo(
        () => (
            <Button type="submit" disabled={state === NetworkRequestState.InProgress}>
                {t('Modules.Displays.DisplaysPackEdit.submit')}
            </Button>
        ),
        [state, t],
    );
    const { dialogBrochureFileDelete, dialogBrochureFileUpdate, handleBrochureDownload } =
        useBrochureEditHandling(
            id,
            setFormValue,
            'Displays_Pack',
            brochureDownload,
            dialogState,
            delDialogState,
        );
    const handleSlotsChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const slotsValue = e.target.value;
            if (slotsValue != null) {
                const calculatedSov = slotsValue !== '' ? 100 / (slotsValue as any) : null;
                setFormValue('slots', slotsValue);
                setFormValue('sov', Number(calculatedSov).toFixed(10));
            }
        },
        [setFormValue],
    );

    const handleSovChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const sovValue = e.target.value;
            if (sovValue != null) {
                const calculatedSlots = sovValue !== '' ? 100 / (sovValue as any) : null;
                setFormValue('sov', sovValue);
                setFormValue('slots', Number(calculatedSlots).toFixed(10));
            }
        },
        [setFormValue],
    );

    const SuccessAlert = useMemo(() => {
        if (state === NetworkRequestState.Success) {
            return (
                <>
                    <Alert severity="success">
                        {t('Modules.Displays.DisplaysPackEdit.success')}
                    </Alert>
                    <Redirect to={PostAuthRoutes.Displays.PackList} />
                </>
            );
        }
        return null;
    }, [state, t]);
    const ownerField = useMemo(
        () => (
            <Controller
                control={control}
                name="owner_id"
                render={({ field }) => (
                    <>
                        <FormLabel sx={{ color: 'red' }}>
                            {t('Modules.Displays.DisplaysDisplayEdit.fields.mediaOwners')} *
                        </FormLabel>
                        <AllEntitiesDropdown
                            entityName="Displays_Owner"
                            labelField="name"
                            {...field}
                            useLocalEntityData
                            filter={{ 'filter.markets.id': [market] }}
                            includes={['market']}
                            loadingDisable
                            disabled={market == null}
                        />
                    </>
                )}
            />
        ),
        [control, market, t],
    );

    return (
        <Box pt={2}>
            <Box pb={2} />
            <AutoGrid xs={5} gap={2} pb={2} justifyContent="center">
                <Controller
                    control={control}
                    name="pack_type"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysPackEdit.fields.type')} *
                            </FormLabel>
                            <SelectField
                                value={field.value}
                                options={[
                                    {
                                        value: DisplayPackType.Pack,
                                        label: t('Modules.Displays.DisplaysPackEdit.fields.pack'),
                                    },
                                    {
                                        value: DisplayPackType.Site,
                                        label: t('Modules.Displays.DisplaysPackEdit.fields.site'),
                                    },
                                ]}
                                {...field}
                            />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysPackEdit.fields.name')} *
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="market_id"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysPackEdit.fields.market')}
                            </FormLabel>
                            <AllEntitiesDropdown
                                entityName="Market"
                                labelField="name"
                                useLocalEntityData
                                {...field}
                            />
                        </>
                    )}
                />
                {ownerField}
                <Controller
                    control={control}
                    name="env"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysPackEdit.fields.env')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="animation"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysPackEdit.fields.animation')}
                            </FormLabel>
                            <SelectField
                                value={field.value}
                                options={[
                                    {
                                        value: Displays_ScreenAnimationType.Static,
                                        label: t('Modules.Displays.DisplaysPackEdit.fields.static'),
                                    },
                                    {
                                        value: Displays_ScreenAnimationType.Motion,
                                        label: t('Modules.Displays.DisplaysPackEdit.fields.motion'),
                                    },
                                    {
                                        value: Displays_ScreenAnimationType.Subtle,
                                        label: t('Modules.Displays.DisplaysPackEdit.fields.subtle'),
                                    },
                                ]}
                                {...field}
                            />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="size"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysPackEdit.fields.size')} *
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="slot_length"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysPackEdit.fields.slotLength')} *
                            </FormLabel>
                            <TextField type="number" {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="slots"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysPackEdit.fields.slots')} *
                            </FormLabel>
                            <TextField {...field} onChange={handleSlotsChange} type="number" />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="sov"
                    render={({ field }) => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysPackEdit.fields.sov')} *
                            </FormLabel>
                            <TextField type="number" {...field} onChange={handleSovChange} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="filetypes"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysPackEdit.fields.fileTypes')}
                            </FormLabel>
                            <AllEntitiesDropdown
                                entityName="Displays_FileType"
                                labelField="type"
                                valueField="type"
                                multi
                                {...field}
                                useLocalEntityData
                            />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="description"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysPackEdit.fields.description')}
                            </FormLabel>
                            <TextAreaField {...field} />
                        </>
                    )}
                />
            </AutoGrid>
            <AutoGrid xs={5} gap={2} pb={2} justifyContent="center">
                <Controller
                    control={control}
                    name="resolutions"
                    render={() => (
                        <>
                            <FormLabel sx={{ color: 'red' }}>
                                {t('Modules.Displays.DisplaysPackEdit.fields.resolutions')} * :
                            </FormLabel>
                            {fields.map((resolution, index) => (
                                <Stack key={resolution.id} direction="row" gap={1} pb={2}>
                                    <Box>
                                        <ControlledFormInput
                                            control={control}
                                            name={`resolutions[${index}].width`}
                                            render={({ field }) => (
                                                <>
                                                    <FormLabel>
                                                        {t(
                                                            'Modules.Displays.DisplaysPackEdit.fields.resolutionWidth',
                                                        )}
                                                    </FormLabel>
                                                    <TextField {...field} />
                                                </>
                                            )}
                                        />
                                    </Box>
                                    <Box>
                                        <ControlledFormInput
                                            control={control}
                                            name={`resolutions[${index}].height`}
                                            render={({ field }) => (
                                                <>
                                                    <FormLabel>
                                                        {t(
                                                            'Modules.Displays.DisplaysPackEdit.fields.resolutionHeight',
                                                        )}
                                                    </FormLabel>
                                                    <TextField {...field} />
                                                </>
                                            )}
                                        />
                                    </Box>
                                    <Box p={1}>
                                        <IconButton
                                            size="small"
                                            sx={{ marginTop: '100%' }}
                                            onClick={() => remove(index)}
                                        >
                                            <RemoveIcon fontSize="inherit" />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            ))}
                            <Box>
                                <IconButton
                                    size="small"
                                    sx={{ marginRight: '73%', marginLeft: '27%', p: 1 }}
                                    onClick={() => append({ width: '', height: '' })}
                                >
                                    <AddIcon fontSize="inherit" />
                                </IconButton>
                            </Box>
                        </>
                    )}
                />
                <Box />
            </AutoGrid>
            <AutoGrid xs={5} gap={2} pb={2} justifyContent="center">
                <Controller
                    control={control}
                    name="brochurelink"
                    render={({ field }) => (
                        <>
                            <FormLabel>
                                {t('Modules.Displays.DisplaysPackEdit.fields.brochureLink')}
                            </FormLabel>
                            <TextField {...field} />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="brochure"
                    render={({ field }) => (
                        <>
                            <AutoGrid xs={3} gap={4}>
                                {brochureDownload != null && (
                                    <Stack gap={1}>
                                        <FormLabel>
                                            {t(
                                                'Modules.Displays.DisplaysPackEdit.fields.downloadButtonLabel',
                                            )}
                                        </FormLabel>
                                        <Button
                                            onClick={e => [
                                                e.preventDefault(),
                                                handleBrochureDownload(field.value),
                                            ]}
                                        >
                                            {t(
                                                'Modules.Displays.DisplaysPackEdit.fields.downloadButtonLabel',
                                            )}
                                        </Button>
                                    </Stack>
                                )}
                                {brochureDownload != null && (
                                    <Stack gap={1}>
                                        <FormLabel>
                                            {t(
                                                'Modules.Displays.DisplaysPackEdit.fields.deleteButtonLabel',
                                            )}
                                        </FormLabel>
                                        <Button
                                            color="error"
                                            onClick={() => delDialogState.setTrue()}
                                        >
                                            {t(
                                                'Modules.Displays.DisplaysPackEdit.fields.deleteButtonLabel',
                                            )}
                                        </Button>
                                    </Stack>
                                )}
                                {dialogBrochureFileUpdate}
                            </AutoGrid>
                        </>
                    )}
                />
                {dialogBrochureFileDelete}
            </AutoGrid>

            {state === NetworkRequestState.Error && (
                <Box pb={2}>
                    <Alert severity="error" variant="outlined">
                        {String(error)}
                    </Alert>
                </Box>
            )}
            {SubmitButton}
            {SuccessAlert}
        </Box>
    );
};
