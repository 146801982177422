import React, { PropsWithChildren, useEffect } from 'react';
import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

const GlobalAppTranslation: React.FC<PropsWithChildren<InitOptions>> = ({
    children,
    ...options
}) => {
    useEffect(() => {
        i18n.use(initReactI18next).init({
            interpolation: { escapeValue: false },
            returnObjects: true,
            ...options,
        });
    }, [options]);
    if (!children) {
        return null;
    }
    return <>{children}</>;
};

export default GlobalAppTranslation;
