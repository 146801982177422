import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Translate } from 'c-translation';
import { useSystemSearch } from 'c-system-search/Hooks';
import SystemSearchFilter from './SystemSearchFilter';
import { Select } from '../../types';

const SystemSearchFilters = React.memo(({ onSelect }: { onSelect: Select }) => {
    const { filters } = useSystemSearch();

    if (filters.length === 0) {
        return null;
    }

    return (
        <Box>
            <Box>
                <Typography variant="h4" color="textPrimary">
                    <Translate path="SystemSearch.filters" />
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {filters.map(filter => (
                    <Grid item key={filter.prefix}>
                        <SystemSearchFilter key={filter.prefix} {...{ filter, onSelect }} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
});

export default SystemSearchFilters;
