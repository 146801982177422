import React, { useMemo } from 'react';
import { Box, Breadcrumbs as MuiBreadCrumbs, Typography } from '@mui/material';
import { MuiRouterLink } from 'c-components';
import { BreadCrumbPart } from '../../Types';
import generateBreadcrumbId from './generateBreadcrumbId';

type Props = { breadcrumbs: BreadCrumbPart[] };

const BreadcrumbList: React.FC<Props> = ({ breadcrumbs }) => {
    const linkCount = useMemo(() => {
        return breadcrumbs.filter(crumb => crumb.link != null).length;
    }, [breadcrumbs]);

    if (linkCount === 0) {
        return null;
    }
    return (
        <Box>
            <MuiBreadCrumbs>
                {breadcrumbs.map(({ link, label, id }) => {
                    if (link) {
                        const generatedId = id || generateBreadcrumbId(link);
                        return (
                            <Typography
                                key={`${link}-${label}`}
                                color="textSecondary"
                                variant="body2"
                            >
                                <MuiRouterLink to={link} color="inherit" id={generatedId}>
                                    {label}
                                </MuiRouterLink>
                            </Typography>
                        );
                    }
                    return (
                        <Typography
                            key={`${link}-${label}`}
                            color="textSecondary"
                            variant="body2"
                            id={id}
                        >
                            {label}
                        </Typography>
                    );
                })}
            </MuiBreadCrumbs>
        </Box>
    );
};

export default BreadcrumbList;
