import { PasswordRequirements, PasswordStrengthErrors } from 'c-auth-module/Types';

const passwordStrength = (password: string, confirmPassword: string): PasswordStrengthErrors => {
    const minLength = Number(import.meta.env.VITE_MIN_PASSWORD_LENGTH);
    const errors: PasswordStrengthErrors = [];

    if (password.length < minLength) {
        errors.push(PasswordRequirements.Length);
    }

    if (password === '' || confirmPassword === '' || password !== confirmPassword) {
        errors.push(PasswordRequirements.Match);
    }

    if (!/[0-9]/.test(password)) {
        errors.push(PasswordRequirements.Number);
    }

    if (!/[A-Z]/.test(password)) {
        errors.push(PasswordRequirements.UpperCase);
    }

    if (!/[a-z]/.test(password)) {
        errors.push(PasswordRequirements.LowerCase);
    }

    // if we strip out all alphanumeric and whitespace then anything left must be a special character
    const stripped = password
        .replace(/[\s]/g, '')
        .replace(/[A-Z]/g, '')
        .replace(/[a-z]/g, '')
        .replace(/[0-9]/g, '');

    if (stripped.length === 0) {
        errors.push(PasswordRequirements.SpecialCharacter);
    }

    return errors;
};

export default passwordStrength;
