import React, { MouseEventHandler, useCallback } from 'react';
import { useAutoResetBoolean } from 'c-hooks';
import to from 'await-to-js';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { useCommonTranslation } from 'c-translation';
import { copyTextToClipboard } from 'c-lib';

type Props = {
    copyContent: string;
    size?: IconButtonProps['size'];
    icon?: React.ReactNode;
} & IconButtonProps;

const CopyButton: React.FC<Props> = ({ copyContent, icon, size = 'small', ...rest }) => {
    const { setTrue, value: CopySuccess } = useAutoResetBoolean(false, 2500);
    const onCopy = useCallback<MouseEventHandler>(
        async e => {
            const [err] = await to(copyTextToClipboard(copyContent, e.shiftKey));

            if (!err) setTrue();
        },
        [copyContent, setTrue],
    );

    return (
        <>
            <Tooltip title={useCommonTranslation('Component.CopyButton.copied')} open={CopySuccess}>
                <IconButton onClick={onCopy} size={size} {...rest}>
                    {icon ?? <ContentCopy fontSize="inherit" />}
                </IconButton>
            </Tooltip>
        </>
    );
};

export default CopyButton;
