import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roleSelectors, roleThunks } from 'c-admin/Slices';
import { Permission } from '@uniled/api-sdk';
import { CheckboxRaw } from 'c-components';

type Props = {
    label: string;
    permission: Permission;
};
const PermissionCheckbox: React.FC<Props> = ({ label, permission }) => {
    const dispatch = useDispatch();
    const state = useSelector(roleSelectors.getPermissionStateById)(permission.id);

    const onChange = useCallback(() => {
        dispatch(roleThunks.setRoleEditingPermissionState(permission.id, !state));
    }, [dispatch, permission, state]);

    return useMemo(
        () => (
            <CheckboxRaw
                id={`${permission.name}-${permission.id}`}
                label={label}
                checked={state === true}
                onChange={onChange}
            />
        ),
        [label, onChange, permission.id, permission.name, state],
    );
};

export default React.memo(PermissionCheckbox);
