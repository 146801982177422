import React from 'react';
import { Hidden } from '@mui/material';

type Props = {
    desktopContent: React.ReactNode;
    mobileContent: React.ReactNode;
};

const DesktopMobileSwitcher: React.FC<Props> = ({ desktopContent, mobileContent }) => (
    <>
        <Hidden mdUp>{mobileContent}</Hidden>
        <Hidden mdDown>{desktopContent}</Hidden>
    </>
);

export default React.memo(DesktopMobileSwitcher);
