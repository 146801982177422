import React from 'react';
import { Tab, TabProps, Typography } from '@mui/material';
import { TabWrapperSettings } from 'c-types';

type Props = TabProps & {
    type: TabWrapperSettings['type'];
    selected?: boolean;
};

type TabLabelProps = { selected: boolean; label: React.ReactNode };

const PrimaryLabel: React.FC<TabLabelProps> = ({ selected, label }) => (
    <Typography variant="h5" color={!selected ? 'grey.400' : undefined}>
        {label}
    </Typography>
);

const SecondaryLabel: React.FC<TabLabelProps> = ({ selected, label }) => (
    <Typography variant="h6">{label}</Typography>
);

const TertiaryLabel: React.FC<TabLabelProps> = ({ selected, label }) => (
    <Typography variant="body1" color={selected ? 'black' : 'grey.500'}>
        {selected ? <b>{label}</b> : label}
    </Typography>
);

const TabLabelComponent = {
    primary: PrimaryLabel,
    secondary: SecondaryLabel,
    tertiary: TertiaryLabel,
};

const TabLabelSx = {
    primary: {},
    secondary: {},
    tertiary: { py: 0, justifyContent: 'flex-end' },
};

const StyledTab: React.FC<Props> = ({ sx, label, type, selected = false, ...props }) => {
    const Component = TabLabelComponent[type];
    return (
        <Tab
            label={<Component label={label} selected={selected} />}
            sx={TabLabelSx[type]}
            {...props}
        />
    );
};

export default StyledTab;
