import React, { useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { Campaign } from '@uniled/api-sdk';
import { AutoGrid, IconLinkButton } from 'c-components';
import { Typography } from '@mui/material';
import { generatePath } from 'react-router-dom';
import { PostAuthRoutes } from 'c-routes';
import { OpenInNew } from '@mui/icons-material';
import { editIcon } from 'c-main/icons';
import { useDateUtils } from 'c-hooks';

import { useCommonTranslation } from 'c-translation';

type Props = RenderColumnDataProps<Campaign>;

const CampaignRefRedirectCell: React.FC<Props> = ({ entity, defaultContent }) => {
    const t = useCommonTranslation();
    const { formatDateString } = useDateUtils();
    const updatedAt = useMemo(
        () => formatDateString({ date: entity?.updated_at, removeTime: false }),
        [entity?.updated_at, formatDateString],
    );
    return (
        <>
            <AutoGrid columnGap={1} flexWrap="nowrap" alignItems="center">
                <IconLinkButton
                    size="small"
                    to={generatePath(PostAuthRoutes.Campaigns.CampaignOverview, {
                        id: entity?.id,
                    })}
                >
                    <OpenInNew fontSize="small" />
                </IconLinkButton>
                {defaultContent}
            </AutoGrid>
            {entity.updated_at != null && (
                <Typography variant="caption" noWrap>
                    {editIcon} {updatedAt}
                </Typography>
            )}
            {/* {entity.missingDisplaysCount > 0 && ( */}
            {/*    <MissingDisplaysDataTable id={entity.id} count={entity.missingDisplaysCount} /> */}
            {/* )} */}
        </>
    );
};

export default CampaignRefRedirectCell;
