import React from 'react';

type Props = { text: string; searchTerm: string };

const HighlightWord: React.FC<Props> = ({ text, searchTerm }) => {
    try {
        const splitResult = text.split(new RegExp(`(?=${searchTerm})|(?<=${searchTerm})`, 'i'));

        const upperSearchTerm = searchTerm.toUpperCase();
        return (
            <>
                {splitResult.map((val, i) =>
                    val.toUpperCase() === upperSearchTerm ? (
                        // eslint-disable-next-line react/no-array-index-key
                        <b key={`${val}-${i}`}>{val}</b>
                    ) : (
                        // eslint-disable-next-line react/no-array-index-key
                        <React.Fragment key={`${val}-${i}`}>{val}</React.Fragment>
                    ),
                )}
            </>
        );
    } catch (e) {
        return <>{text}</>;
    }
};

export default React.memo(HighlightWord);
