import React, {
    Ref,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { callApi, NetworkRequest, NetworkRequestState } from '@uniled/data-layer';
import { addDays, endOfMonth, format, isAfter, isBefore, startOfMonth } from 'date-fns';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import {
    ReportChartWrapper,
    ReportControlsWrapper,
    ReportDateSwitcher,
    ReportTableWrapper,
} from 'c-reports/Components';
import {
    ChartReportProps,
    ReportDateSettings,
    ReportDateTimeframe,
    ReportType,
    ReportWeekDay,
    ReportWrapperProps,
    ReportWrapperRefAPI,
    TableReportProps,
} from 'c-reports/Types';
import { Box } from '@mui/material';
import { getClosestDayOfCurrentWeek } from 'c-lib';
import { LoadingSpinner } from 'c-components';
import { ChartDataKeyDateFormat } from 'c-reports/Generators';

type Props<DataStructure> = ReportWrapperProps<DataStructure> & {
    removeLoadingBar?: boolean;
};

const figureOutStartDate = (
    { minDate, maxDate, timeframe, firstDayOfWeek }: ReportDateSettings,
    initialStartDate?: Date,
) => {
    let theDate = initialStartDate;
    if (theDate == null) {
        theDate = minDate;
    }

    if (timeframe === ReportDateTimeframe.Weekly || timeframe === ReportDateTimeframe.BiWeekly) {
        return getClosestDayOfCurrentWeek(firstDayOfWeek, theDate);
    }

    if (timeframe === ReportDateTimeframe.Monthly) {
        return startOfMonth(theDate);
    }

    /**
     * initial start date is after the max date, return the max date
     */
    if (isAfter(initialStartDate, maxDate)) {
        return maxDate;
    }

    /**
     * if the initial start date is before the min date then correct it and use the min date instead
     */
    return isBefore(initialStartDate, minDate) ? minDate : initialStartDate;
};

const figureOutEndDate = (
    { maxDate, timeframe }: ReportDateSettings,
    initialEndDate: Date,
    startDate: Date,
) => {
    if (timeframe === ReportDateTimeframe.Daily) {
        return startDate;
    }

    if (timeframe === ReportDateTimeframe.Weekly) {
        return addDays(startDate, 6);
    }

    if (timeframe === ReportDateTimeframe.BiWeekly) {
        return addDays(startDate, 13);
    }

    if (timeframe === ReportDateTimeframe.Monthly) {
        return endOfMonth(startDate);
    }

    return !isAfter(initialEndDate, maxDate) ? initialEndDate : maxDate;
};

const generateRequestKey = (
    prefix: string,
    startDate: Date,
    endDate: Date,
    timeframe: ReportDateTimeframe,
) =>
    `${prefix}-${format(startDate, ChartDataKeyDateFormat)}-${format(
        endDate,
        ChartDataKeyDateFormat,
    )}-${timeframe}`;

function ReportWrapper<DataStructure>(
    {
        initialStartDate,
        initialEndDate,
        fetchData,
        onDataUpdated,
        dateSettings,
        wrapperProps,
        reportWrapperProps,
        reportChartWrapperProps,
        additionalDateControls,
        additionalDateControlsPosition,
        beforeChartComponent,
        afterChartComponent,
        reportProps,
        ReportRender,
        onLoadingStateUpdated,
        requestKeyPrefix = '',
        removeLoadingBar = false,
    }: Props<DataStructure>,
    ref: Ref<ReportWrapperRefAPI>,
) {
    const dispatch = useDispatch();
    const [timeframe, setTimeframe] = useState<ReportDateTimeframe>(
        dateSettings?.timeframe ?? ReportDateTimeframe.Weekly,
    );

    const mountedRef = useRef(false);
    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const actualDateSettings = useMemo<ReportDateSettings>(() => {
        //
        const defaults: ReportDateSettings = {
            timeframe,
            minDate: initialStartDate ?? new Date(),
            maxDate: initialEndDate ?? new Date(),
            firstDayOfWeek: ReportWeekDay.Monday,
        };

        return { ...defaults, ...dateSettings, timeframe };
    }, [dateSettings, initialStartDate, initialEndDate, timeframe]);

    const [startDate, setStartDate] = useState(
        figureOutStartDate(actualDateSettings, initialStartDate),
    );
    const [endDate, setEndDate] = useState(
        figureOutEndDate(actualDateSettings, initialEndDate, startDate),
    );

    const [loading, setLoading] = useState<Record<string, NetworkRequest>>({});
    const [data, setData] = useState<Record<string, any>>({});
    const currentKey = useMemo(
        () => generateRequestKey(requestKeyPrefix, startDate, endDate, timeframe),
        [startDate, endDate, timeframe, requestKeyPrefix],
    );

    const abortControllerRef = useRef<AbortController>();

    const getData = useCallback(
        async (newTimeframe: ReportDateTimeframe, start?: Date, end?: Date) => {
            const requestStartDate = start ?? startDate;
            const requestEndDate = end ?? endDate;
            const requestKey = generateRequestKey(
                requestKeyPrefix,
                requestStartDate,
                requestEndDate,
                newTimeframe,
            );

            if (
                loading[requestKey]?.state === NetworkRequestState.InProgress &&
                abortControllerRef?.current != null
            ) {
                // console.log('in progress', requestKey);
                abortControllerRef.current.abort();
                // return;
            }

            setLoading({
                ...loading,
                [requestKey]: { state: NetworkRequestState.InProgress },
            });

            const { request, controller } = fetchData(
                requestStartDate,
                requestEndDate,
                newTimeframe,
            );

            abortControllerRef.current = controller;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const [error, responseData] = await dispatch(callApi(request));

            if (mountedRef.current === false) {
                return;
            }

            if (!error) {
                setData({ ...data, [requestKey]: responseData });

                setTimeout(() => {
                    // don't judge I just want to delay the success loading state by a few frames
                    // so we don't get the dreaded "No Data" labels
                    setLoading({
                        ...loading,
                        [requestKey]: { state: NetworkRequestState.Success },
                    });
                }, 10);
            } else {
                setLoading({
                    ...loading,
                    [requestKey]: { state: NetworkRequestState.Error },
                });
            }
        },
        [startDate, endDate, requestKeyPrefix, loading, dispatch, fetchData, data],
    );

    const onDateChange = useCallback(
        (timeframe: ReportDateTimeframe, start: Date, end: Date, calledFromRef = false) => {
            setStartDate(start);
            setEndDate(end);

            if (!calledFromRef) {
                // if the dates were changed via the ref function we don't want to trigger this again
                // as it causes a bit of a loop.
                actualDateSettings?.onDatesUpdated?.(timeframe, start, end);
            }

            getData(timeframe, start, end);
        },
        [getData, actualDateSettings],
    );

    const requestState = useMemo(
        () => loading[currentKey]?.state ?? NetworkRequestState.Idle,
        [currentKey, loading],
    );

    // to test error states uncomment below and comment above
    // const requestState = NetworkRequestState.Error;

    const currentData = useMemo(() => data[currentKey] ?? null, [currentKey, data]);

    useEffect(
        () => {
            onDataUpdated?.(currentData, startDate, endDate, timeframe);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentData, onDataUpdated],
    );

    useEffect(
        () => {
            if (onLoadingStateUpdated) {
                onLoadingStateUpdated(loading[currentKey]);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onLoadingStateUpdated, loading, currentKey],
    );

    useEffect(() => {
        getData(timeframe);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSwitchTimeframe = useCallback(
        ($timeframe: ReportDateTimeframe, start?: Date, end?: Date, calledFromRef = false) => {
            // const newTimeframe =
            //     $timeframe === ReportDateTimeframe.All ? ReportDateTimeframe.Range : $timeframe;

            if ($timeframe === ReportDateTimeframe.All) {
                onDateChange(
                    $timeframe,
                    actualDateSettings.minDate,
                    actualDateSettings.maxDate,
                    calledFromRef,
                );
            } else {
                const newDateSettings = { ...actualDateSettings, timeframe: $timeframe };
                const newStartDate = figureOutStartDate(newDateSettings, start ?? startDate);
                const newEndDate = figureOutEndDate(
                    newDateSettings,
                    end ?? endDate,
                    start ?? startDate,
                );
                onDateChange($timeframe, newStartDate, newEndDate, calledFromRef);
            }
            setTimeframe($timeframe);
        },
        [actualDateSettings, endDate, onDateChange, startDate],
    );

    const refreshData = useCallback(() => getData(timeframe), [getData, timeframe]);
    /**
     * provide a custom ref (useRef) API
     */
    useImperativeHandle(
        ref,
        () => ({
            refreshData,
            setDates: (timeframe, startDate, endDate) => {
                onSwitchTimeframe(timeframe, startDate, endDate, true);
            },
        }),
        [refreshData, onSwitchTimeframe],
    );

    const childReports = useMemo(
        () =>
            reportProps
                ?.map(({ RenderReport, ...rep }, ri) => {
                    if (RenderReport != null) {
                        // eslint-disable-next-line react/no-array-index-key
                        return <RenderReport {...rep} key={ri} />;
                    }
                    if (rep.type === ReportType.Table) {
                        return (
                            <ReportControlsWrapper
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${rep.type}-${ri}`}
                                tableProps={{ ...(rep as TableReportProps<DataStructure>) }}
                                type={rep.type}
                                namePrefix={rep.namePrefix}
                                startDate={startDate}
                                endDate={endDate}
                            >
                                <ReportTableWrapper
                                    addLoadingBar={removeLoadingBar}
                                    {...(rep as TableReportProps<DataStructure>)}
                                    loadingState={requestState}
                                    refreshData={refreshData}
                                />
                            </ReportControlsWrapper>
                        );
                    }
                    if (
                        [ReportType.Line, ReportType.Bar, ReportType.Pie, ReportType.Mixed].indexOf(
                            rep.type,
                        ) !== -1
                    ) {
                        return (
                            <Box
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${rep.type}-${ri}`}
                                id={rep.namePrefix.replace(/^\d+_/, '')}
                                height={200}
                                width="100%"
                                {...reportChartWrapperProps}
                            >
                                <ReportControlsWrapper
                                    chartProps={{ ...(rep as ChartReportProps<DataStructure>) }}
                                    type={rep.type}
                                    namePrefix={rep.namePrefix}
                                    startDate={startDate}
                                    endDate={endDate}
                                >
                                    <ReportChartWrapper
                                        {...(rep as ChartReportProps<DataStructure>)}
                                        type={rep.type}
                                        loadingState={requestState}
                                    />
                                </ReportControlsWrapper>
                            </Box>
                        );
                    }

                    return null;
                })
                .filter(rep => rep != null),
        [
            endDate,
            refreshData,
            removeLoadingBar,
            reportChartWrapperProps,
            reportProps,
            requestState,
            startDate,
        ],
    );

    const reports = useMemo(
        () =>
            ReportRender ? (
                <ReportRender reports={childReports} />
            ) : (
                childReports?.map((rep, ri) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={`${rep.type}-${ri}`}>{rep}</React.Fragment>
                )) ?? []
            ),
        [ReportRender, childReports],
    );

    return (
        <Box {...wrapperProps}>
            {actualDateSettings.timeframe != null && (
                <Box sx={{ display: 'flex', py: 1 }}>
                    {additionalDateControls &&
                        additionalDateControlsPosition === 'start' &&
                        additionalDateControls}
                    <ReportDateSwitcher
                        startDate={startDate}
                        endDate={endDate}
                        onChange={onDateChange}
                        {...actualDateSettings}
                        onSwitchTimeframe={onSwitchTimeframe}
                        timeframe={timeframe}
                    />
                    {additionalDateControls &&
                        additionalDateControlsPosition === 'end' &&
                        additionalDateControls}
                </Box>
            )}
            {beforeChartComponent && beforeChartComponent}
            <Box position="relative" minHeight={400} {...reportWrapperProps}>
                {requestState === NetworkRequestState.InProgress && !removeLoadingBar && (
                    <Box sx={{ position: 'absolute', top: 100, left: '50%', zIndex: 1 }}>
                        <LoadingSpinner />
                    </Box>
                )}
                {reports}
            </Box>
            {afterChartComponent && afterChartComponent}
        </Box>
    );
}

export default React.forwardRef(ReportWrapper);
