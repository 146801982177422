import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uiSelectors, uiThunks } from 'c-wrapper/Slices/UI';
import { DEFAULT_SKIN_ID } from 'c-wrapper/Slices/UI/ui-selectors';
import { useEntityData } from 'c-data/slices';
import { Skin } from 'c-entity-types';

function useSkinSwitcher() {
    const dispatch = useDispatch();
    const currentSkin = useSelector(uiSelectors.previewThemeRaw);
    const { getById } = useEntityData<Skin>('Skin');
    const skin = getById({ id: currentSkin?.id ?? undefined });
    const switchSkin = useCallback(
        (skinId: number) => {
            dispatch(uiThunks.setPreviewTheme(getById({ id: skinId }), true));
        },
        [dispatch, getById],
    );
    const switchSkinToDefault = useCallback(() => {
        dispatch(uiThunks.setPreviewTheme({ id: DEFAULT_SKIN_ID } as Skin, true));
    }, [dispatch]);

    const refreshSkinById = useCallback(
        (id: number) => {
            getById({ id, forceRefresh: true });
        },
        [getById],
    );

    return useMemo(
        () => ({
            currentSkinId: currentSkin?.id,
            isCustomSkinSelected: skin != null,
            selectedSkin: skin,
            switchSkin,
            switchSkinToDefault,
            getSkinById: refreshSkinById,
        }),
        [currentSkin?.id, refreshSkinById, skin, switchSkin, switchSkinToDefault],
    );
}

export default useSkinSwitcher;
