import React from 'react';
import { EntityPermissions } from 'c-admin';
import { AllEntities } from '@uniled/api-sdk';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import PermissionCheckbox from './PermissionCheckbox';

type Props = {
    entityName: keyof AllEntities;
    permissions: EntityPermissions;
};

const EntityPermissionTile: React.FC<Props> = ({ entityName, permissions }) => (
    <Card elevation={2}>
        <CardHeader title={entityName} titleTypographyProps={{ sx: { wordBreak: 'break-all' } }} />
        <CardContent>
            <Grid container spacing={2}>
                {permissions.create && (
                    <Grid item xs={3}>
                        <PermissionCheckbox permission={permissions.create} label="C" />
                    </Grid>
                )}
                {permissions.read && (
                    <Grid item xs={3}>
                        <PermissionCheckbox permission={permissions.read} label="R" />
                    </Grid>
                )}
                {permissions.update && (
                    <Grid item xs={3}>
                        <PermissionCheckbox permission={permissions.update} label="U" />
                    </Grid>
                )}
                {permissions.delete && (
                    <Grid item xs={3}>
                        <PermissionCheckbox permission={permissions.delete} label="D" />
                    </Grid>
                )}
            </Grid>
        </CardContent>
    </Card>
);

export default EntityPermissionTile;
