import React from 'react';
import {
    AllEntities,
    BaseEntity,
    ListSearchOptions,
    PermissionName,
    SpotlightResourceType,
} from '@uniled/api-sdk';
import { BreadCrumbPart } from 'c-wrapper/Types';
import { NetworkRequestState } from '@uniled/data-layer';
import { AppTranslationFunction, TranslationPath as CommonTranslationPath } from 'c-translation';
import { BoxProps, TypographyProps } from '@mui/material';

export type Filter = { prefix: string; description: CommonTranslationPath };
export type Select = (prefix: string) => void;
export enum SearchResultStatus {
    Success,
    InvalidShortcut,
}

export type SystemSearchSearchResult = {
    results: SystemSearchResource[];
    status: SearchResultStatus;
    entities: SystemSearchEntityResource[];
} & SystemSearchSearchParts;

export type SystemSearchSearchParts = {
    prefix: string;
    term: string;
};

export type UnTranslatedSystemSearchResourceOrEntity<T> = Omit<T, 'aliases'> & {
    aliases?: CommonTranslationPath;
    prefix?: CommonTranslationPath;
};

export type SystemSearchResourceOrEntity = SystemSearchResource | SystemSearchEntityResource;

export type ParsedSystemSearchConfiguration = {
    prefixes: {
        [key: string]: SystemSearchResourceOrEntity[];
    };
    aliases: {
        [key: string]: SystemSearchResourceOrEntity[];
    };
    /**
     * an entity resource that should always be searched for.
     *
     * It will have to be set on the page data for said entity and only one can ultimately be set
     * because only one entity can be searched for at a time.
     */
    entityAlways?: SystemSearchEntityResource;
};

export type ParsedUnTranslatedSystemSearchConfiguration = {
    prefixes: {
        [key: string]: UnTranslatedSystemSearchResourceOrEntity<SystemSearchResourceOrEntity>[];
    };
    aliases: {
        [key: string]: UnTranslatedSystemSearchResourceOrEntity<SystemSearchResourceOrEntity>[];
    };
};

export type ModuleSystemSearchConfiguration = {
    moduleIcon?: React.ReactNode;
};

export type SystemSearchResource = {
    /**
     * if set to true, will save the page as a recently viewed page when it is opened.
     *
     * Can be useful if there is a page you want to exclude from the recent pages list.
     *
     * For example, you might have a page for creating a new entity, but don't want to
     * show it in recent pages.
     */
    saveAsRecentPage: boolean;

    /**
     * Allows you to exclude the page from system search results.
     */
    excludeFromSystemSearch?: boolean;

    // a unique identifier which can be used to link a 'RecentResource' to a current 'SystemSearchResource'
    type: SpotlightResourceType;

    // these strings will be matched against when searching
    aliases?: string[];

    /**
     * the prefix string used to filter to only this entity type
     *
     * Assumed to live under the 'page: ' shortcut if this is of type 'page'
     */
    prefix?: string;
    prefixDescription?: CommonTranslationPath;

    route: string;
    routeExact?: boolean; // default true

    /**
     * displayed as a badge in the result
     *
     * Assumed to be 'Page' if this is not set and this is of type 'page'
     */
    tag?: CommonTranslationPath;

    // a "static" set of breadcrumbs
    breadcrumbs?: (BreadCrumbPart & {
        label: CommonTranslationPath | React.ReactNode | string | any;
    })[];

    renderBreadcrumbs?: React.FC<{
        breadcrumbs: BreadCrumbPart[];
        defaultContent: React.ReactNode;
    }>;

    // update the breadcrumbs with dynamic ones
    generateBreadcrumbs?: (
        crumbs: BreadCrumbPart[],
        t: AppTranslationFunction,
    ) => SystemSearchResource['breadcrumbs'];

    title: CommonTranslationPath;
    description?: CommonTranslationPath;
    icon?: React.ReactNode;

    Content?: CustomResultContent;

    /**
     * Set a timeout (ms) on displaying the custom content.
     *
     * This is useful when displaying custom data which would trigger
     * a http request. If the resource is found as the user is typing
     * and is then hidden as the user keeps typing you don't necessarily want
     * to mount your content straight away and trigger the http request(s)
     */
    contentTimeout?: number;

    /**
     * Set of permissions a user must have to access this resource
     */
    permissions?: PermissionName[];
};

export type PageStatusSettings = {
    status: string;
    statusBoxProps?: BoxProps;
    statusTypographyProps?: TypographyProps;
};

export type SystemSearchEntityResource<Entity extends BaseEntity = BaseEntity> =
    SystemSearchResource & {
        type: 'entity';
        entityName: keyof AllEntities;
        nameGen: (entity: Entity | null, id: number, t: AppTranslationFunction) => string;

        // generate additional none static breadcrumbs based on the entity data for the page
        generateEntityBreadcrumbs?: (
            crumbs: SystemSearchEntityResource['breadcrumbs'],
            t: AppTranslationFunction,
            entity: Entity,
            entityId: number,
        ) => SystemSearchEntityResource['breadcrumbs'];

        renderEntityBreadcrumbs?: React.FC<{
            breadcrumbs: BreadCrumbPart[];
            defaultContent: React.ReactNode;
            entity: Entity;
        }>;

        pageStatus?: (
            entity: Entity | null,
            id: number,
            t: AppTranslationFunction,
        ) => PageStatusSettings;

        /**
         * The `search.` prefix is automatically applied
         *  [`search.${entity.searchColumn}`]
         */
        searchColumn?: string[];

        /**
         * the order by column.
         *
         * Default: `id`
         */
        orderByColumn?: string;

        orderByDirection?: ListSearchOptions['direction'];

        // "Type instantiation is excessively deep and possibly infinite."
        // defaultIncludes?: Paths<Entity>[];

        defaultIncludes?: string[];

        /**
         * If set to true, this entity will always be searched for if no other entities (via a prefix) has been searched for
         * Only one entity can be searched for at a time, so this can only really be set to true on a single entity page.
         */
        alwaysSearchForEntity?: boolean;
    };

export type CustomResultContent = React.FC<{
    highlighted: boolean;
    highlight: (e?: React.SyntheticEvent) => void;
}>;

/**
 * Prop type for the component which will display the systemSearch results
 */
export type SystemSearchResultProps = {
    highlighted: boolean;

    // to go into the badge
    tag: string;

    title: string;
    description: string;

    // to be used to highlight why the result had been selected
    searchTerm: string;

    // the page route to the resource this result represents
    route?: string;

    breadCrumbs?: CommonTranslationPath[];

    pageIcon?: React.ReactNode;

    Content?: CustomResultContent;

    /**
     * Set a timeout (ms) on displaying the custom content.
     *
     * This is useful when displaying custom data which would trigger
     * a http request. If the resource is found as the user is typing
     * and is then hidden as the user keeps typing you don't necessarily want
     * to mount your content straight away and trigger the http request(s)
     */
    contentTimeout?: number;
};

export type SystemSearchResultBreadcrumbsProps = {
    crumbs: CommonTranslationPath[];
    searchTerm: string;
};

export type SystemSearchSelectedItemGrid = {
    x: number;
    y: number;
};

export type SystemSearchEntityLoadingStates = { [searchTerm: string]: SystemSearchEntityLoading };

export type SystemSearchEntityLoading = {
    state: NetworkRequestState;
    results: SystemSearchEntitySearchResult[];
    searchTime?: number;
};
export type SystemSearchEntitySearchResult = {
    entityName: keyof AllEntities;
    id: number;
};
