import React, { useCallback, useEffect, useMemo, useState } from 'react';
import to from 'await-to-js';
import PreAuthLayout from 'c-wrapper/Components/PreAuthLayout';
import ContentLayout from 'c-wrapper/Components/ContentLayout';
import MetaLayout from 'c-wrapper/Components/MetaLayout';
import { useCommonTranslation } from 'c-translation';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { passwordStrength } from 'c-auth-module/Lib';
import { Button, ControlledFormInput, FlashMessage, TextField } from 'c-components';
import { PasswordStrengthErrors } from 'c-auth-module/Types';
import { Box } from '@mui/material';
import * as yup from 'yup';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import PasswordStrength from '../../Components/PasswordStrength';

type FormValues = {
    password_reset_token?: string;
    email?: string;
    password?: string;
    password_confirmation?: string;
    name_first: string;
    name_last: string;
    registration_code: string;
};

const schema = (emailError: string) =>
    yup.object({
        registration_code: yup.string().required(),
        email: yup.string().required().email(emailError),
        name_first: yup.string().required(),
        name_last: yup.string().required(),
    });

const InviteRegisterPage = () => {
    const t = useCommonTranslation();

    const token = useMemo(() => new URLSearchParams(window.location.search).get('token'), []);
    const invalidEmail = t('Pages.ForgotPasswordCode.invalidEmail');
    const { control, handleSubmit, formState } = useForm<FormValues>({
        defaultValues: { registration_code: token },
        resolver: yupResolver(schema(invalidEmail)) as any,
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const [passwordError, setPasswordError] = useState('');

    const [password, passwordConfirmation] = useWatch<FormValues>({
        control,
        name: ['password', 'password_confirmation'],
    });
    useWatch({ control });

    useEffect(() => {
        setPasswordError(passwordStrength('', '').join(','));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // https://github.com/react-hook-form/react-hook-form/issues/1211
        // have to do it like this instead of normal YUP validation
        // because field errors are cleared one by one. See github issue above.
        // I have commented on it.
        const passwordStr = passwordStrength(password ?? '', passwordConfirmation ?? '').join(',');

        if (passwordStr !== passwordError) {
            setPasswordError(passwordStr);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, passwordConfirmation]);

    const { start, isLoading, hasFailed, error, hasSucceeded } = useAPIClientRequest(
        apiClient.Auth.register,
    );
    const onSubmit = useCallback(
        async (formData: FormValues) => {
            if (isLoading) return;
            await to(
                start({
                    registration_code: formData.registration_code,
                    name_first: formData.name_first,
                    name_last: formData.name_last,
                    email: formData.email,
                    password: formData.password,
                }),
            );
        },
        [start, isLoading],
    );

    return (
        <MetaLayout metaTitle="Pages.AcceptInvite.metaTitle">
            <PreAuthLayout
            // title={t('Pages.AcceptInvite.title')}
            >
                {hasSucceeded && (
                    <FlashMessage status="success">
                        {t('Pages.AcceptInvite.registerSuccess')}
                    </FlashMessage>
                )}
                {!hasSucceeded && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ContentLayout.Form>
                            <ContentLayout.FormFields>
                                <ControlledFormInput
                                    defaultValue=""
                                    control={control}
                                    name="email"
                                    as={
                                        <TextField
                                            whiteBackdrop={false}
                                            label={t('Pages.AcceptInvite.emailInputLabel')}
                                            placeholder={t('Pages.AcceptInvite.emailInputLabel')}
                                            required
                                            type="email"
                                        />
                                    }
                                />
                                <ControlledFormInput
                                    defaultValue=""
                                    control={control}
                                    name="name_first"
                                    as={
                                        <TextField
                                            whiteBackdrop={false}
                                            label={t('Pages.AcceptInvite.registerFirstName')}
                                            placeholder={t('Pages.AcceptInvite.registerFirstName')}
                                            required
                                        />
                                    }
                                />
                                <ControlledFormInput
                                    defaultValue=""
                                    control={control}
                                    name="name_last"
                                    as={
                                        <TextField
                                            whiteBackdrop={false}
                                            label={t('Pages.AcceptInvite.registerLastName')}
                                            placeholder={t('Pages.AcceptInvite.registerLastName')}
                                            required
                                        />
                                    }
                                />
                                <ControlledFormInput
                                    defaultValue=""
                                    control={control}
                                    name="password"
                                    as={
                                        <TextField
                                            whiteBackdrop={false}
                                            label={t('Pages.AcceptInvite.passwordInputLabel')}
                                            placeholder={t('Pages.AcceptInvite.passwordInputLabel')}
                                            required
                                            type="password"
                                        />
                                    }
                                />
                                <ControlledFormInput
                                    defaultValue=""
                                    control={control}
                                    name="password_confirmation"
                                    as={
                                        <TextField
                                            whiteBackdrop={false}
                                            label={t(
                                                'Pages.AcceptInvite.passwordConfirmInputLabel',
                                            )}
                                            placeholder={t(
                                                'Pages.AcceptInvite.passwordConfirmInputLabel',
                                            )}
                                            required
                                            type="password"
                                        />
                                    }
                                />
                            </ContentLayout.FormFields>
                            <PasswordStrength
                                errors={(passwordError.split(',') as PasswordStrengthErrors) ?? []}
                            />
                            <Box
                                mt="5rem"
                                display="flex"
                                justifyContent="center"
                                flexDirection="column"
                            >
                                {hasFailed && (
                                    <Box mb={2} mx="auto">
                                        <FlashMessage status="error" textAlign="center">
                                            {String(error)}
                                        </FlashMessage>
                                    </Box>
                                )}
                                <Box mx="auto">
                                    <Button
                                        id="password-reset-submit"
                                        type="submit"
                                        disabled={
                                            isLoading ||
                                            !formState.isValid ||
                                            passwordError.length > 0
                                        }
                                    >
                                        {t('Pages.AcceptInvite.registerSubmit')}
                                        {/* {t(submitLabel)} */}
                                    </Button>
                                </Box>
                            </Box>
                        </ContentLayout.Form>
                    </form>
                )}
            </PreAuthLayout>
        </MetaLayout>
    );
};

export default InviteRegisterPage;
