import React, { Ref, useCallback, useMemo } from 'react';
import { NetworkRequestState } from '@uniled/data-layer';
import { ChartReportProps, ReportType } from 'c-reports/Types';
import {
    ReportBarChart,
    ReportComposedChart,
    ReportLineChart,
    ReportPieChart,
} from 'c-reports/Components';
import { Box, Typography } from '@mui/material';
import { Translate, useCommonTranslation } from 'c-translation';
import { formatNumber, formatNumberCompact, secondsToHms } from 'c-lib';

type Props = ChartReportProps<any> & {
    type: ReportType;
    loadingState: NetworkRequestState;
};

const ReportChartWrapper = (
    { type, loadingState, yAxisTickFormatterAsTime, parts, data, ...props }: Props,
    ref: Ref<any>,
) => {
    const t = useCommonTranslation();

    const tickFormatter = useCallback(
        value => {
            if (Number.isFinite(value)) {
                if (yAxisTickFormatterAsTime) {
                    const { h, m, s } = secondsToHms(value);
                    if (h > 0) {
                        return t('Reporting.ticks.hoursLabel', { count: formatNumber(h) });
                    }
                    if (m > 0) {
                        return t('Reporting.ticks.minutesLabel', { count: formatNumber(m) });
                    }
                    return t('Reporting.ticks.secondsLabel', { count: formatNumber(s ?? 0) });
                }
                return formatNumberCompact(value, 1_000_000);
            }
            return value;
        },
        [yAxisTickFormatterAsTime, t],
    );

    const anyLabelOverrides = useMemo(
        () =>
            parts.filter(p => p.labelOverride != null && p.labelOverride !== p.dataKey).length > 0,
        [parts],
    );

    const overridenData = useMemo(() => {
        if (anyLabelOverrides) {
            return data.reduce((acc, curr) => {
                const newData = { ...curr };
                parts.forEach(p => {
                    // override the data key with the override label. This will ensure all the tooltips
                    // legends, etc. all use the correct label.
                    if (p.labelOverride != null && p.labelOverride !== p.dataKey) {
                        newData[p.labelOverride] = newData[p.dataKey];
                        delete newData[p.dataKey];
                    }
                });

                acc.push(newData);
                return acc;
            }, []);
        }

        return data;
    }, [data, anyLabelOverrides, parts]);

    const overridenParts = useMemo(() => {
        if (anyLabelOverrides) {
            return parts.reduce((acc, curr) => {
                const newParts = { ...curr, dataKey: curr.labelOverride ?? curr.dataKey };
                acc.push(newParts);
                return acc;
            }, []);
        }

        return parts;
    }, [anyLabelOverrides, parts]);

    const chartComponentProps = useMemo<ChartReportProps<any>>(
        () => ({
            hideLegend: false,
            hideBrush: false,
            hideTooltip: false,
            parts: overridenParts,
            data: overridenData,
            ...props,
            yAxisTickFormatter: tickFormatter,
        }),
        [overridenData, overridenParts, props, tickFormatter],
    );

    const noData = useMemo(() => {
        if (loadingState === NetworkRequestState.Error) return true;

        if (loadingState === NetworkRequestState.Success) {
            return Array.isArray(chartComponentProps.data) && chartComponentProps.data.length === 0;
        }

        return false;
    }, [loadingState, chartComponentProps.data]);

    const chartChild = useMemo(() => {
        if (type === ReportType.Line) {
            return <ReportLineChart {...chartComponentProps} ref={ref} />;
        }
        if (type === ReportType.Bar) {
            return <ReportBarChart {...chartComponentProps} ref={ref} />;
        }

        if (type === ReportType.Mixed) {
            return <ReportComposedChart {...chartComponentProps} ref={ref} />;
        }

        if (type === ReportType.Pie) {
            return <ReportPieChart {...chartComponentProps} ref={ref} />;
        }

        return null;
    }, [chartComponentProps, ref, type]);

    /**
     * For some reason the responsive container has to be around the chart component
     * within the same component otherwise it won't work.
     */
    return (
        <>
            {noData && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        color: 'grey.500',
                    }}
                >
                    <Typography variant="h2">
                        <Translate path="Reporting.noData" />
                    </Typography>
                </Box>
            )}
            {Array.isArray(chartComponentProps.data) && chartComponentProps.data.length > 0 && (
                <Box height="100%" width="100%">
                    {chartChild}
                </Box>
            )}
        </>
    );
};

export default React.forwardRef(ReportChartWrapper);
