import React, { Ref, useCallback, useMemo, useState } from 'react';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import { ChartReportProps } from 'c-reports/Types';
import { ChartDataKeyDateFormat, generateBrush } from 'c-reports/Generators';
import { useTheme } from '@mui/styles';
import { isValid, parse } from 'date-fns';
import { useDateUtils } from 'c-hooks';

type Props = Omit<ChartReportProps<any>, 'onDataUpdated'>;

const ReportLineChart = (
    {
        data,
        chartProps,
        parts,
        xAxisDataKey,
        yAxisDataKey,
        renderTooltipLabel,
        yAxisTickFormatter,
        hideLegend,
        hideTooltip,
        lineChartProps,
        xAxisProps,
        yAxisProps,
        brushProps,
        hideBrush,
        chartChildren,
    }: Props,
    ref: Ref<any>,
) => {
    const [hoveredLegendKey, setHoveredLegendKey] = useState(null);
    const onLegendMouseEnter = useCallback<Legend['props']['onMouseEnter']>(
        payload => {
            if (payload.dataKey != null) {
                setHoveredLegendKey(payload.dataKey);
            }
        },
        [setHoveredLegendKey],
    );
    const onLegendMouseLeave = useCallback<Legend['props']['onMouseLeave']>(() => {
        setHoveredLegendKey(null);
    }, [setHoveredLegendKey]);

    const { accumulative } = useMemo<ChartReportProps<any>['lineChartProps']>(
        () => ({ accumulative: false, ...lineChartProps }),
        [lineChartProps],
    );

    const CustomTooltip = useCallback(
        (tooltipProps: TooltipProps<any, any>) => {
            if (
                renderTooltipLabel &&
                tooltipProps.active &&
                tooltipProps.payload &&
                tooltipProps.payload.length
            ) {
                return renderTooltipLabel(tooltipProps);
            }

            return null;
        },
        [renderTooltipLabel],
    );

    const allPartDataKeys = useMemo(() => parts.map(p => p.dataKey), [parts]);
    const { formatDateString } = useDateUtils();

    const chartData = useMemo(() => {
        let theData = data;
        if (isValid(parse(data?.[0]?.[xAxisDataKey], ChartDataKeyDateFormat, new Date()))) {
            theData = data.map(d => ({
                ...d,
                [xAxisDataKey]: formatDateString({
                    date: parse(d[xAxisDataKey], ChartDataKeyDateFormat, new Date()),
                }),
            }));
        }

        if (accumulative === true && Array.isArray(data)) {
            // keep reference to prev values so they can be added to next value
            const prev: Record<string, any> = {};

            return (theData as any[]).reduce((acc, currentLineData, idx) => {
                const newLineData = { ...currentLineData };
                allPartDataKeys.forEach(part => {
                    // add current value + prev value
                    const newVal = currentLineData[part] + (prev[part] ?? 0);
                    newLineData[part] = newVal;
                    // set prev value for next iteration
                    prev[part] = newVal;
                });

                acc[idx] = newLineData;
                return acc;
            }, []);
        }

        return theData;
    }, [data, xAxisDataKey, accumulative, formatDateString, allPartDataKeys]);

    const theme = useTheme();
    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                ref={ref}
                data={chartData}
                margin={{
                    top: 25,
                    right: 50,
                    left: 40,
                    bottom: 5,
                }}
                {...chartProps}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={xAxisDataKey} {...xAxisProps} />
                <YAxis dataKey={yAxisDataKey} tickFormatter={yAxisTickFormatter} {...yAxisProps} />
                {!hideTooltip && (
                    <Tooltip content={renderTooltipLabel ? <CustomTooltip /> : undefined} />
                )}
                {!hideLegend && (
                    <Legend
                        verticalAlign="top"
                        align="right"
                        wrapperStyle={{ top: 5 }}
                        onMouseEnter={onLegendMouseEnter}
                        onMouseLeave={onLegendMouseLeave}
                    />
                )}
                {parts.map(p => (
                    <Line
                        key={p.dataKey}
                        type="monotone"
                        dataKey={p.dataKey}
                        strokeWidth={hoveredLegendKey === p.dataKey ? 8 : 4}
                        activeDot={{ r: hoveredLegendKey === p.dataKey ? 16 : 8 }}
                        {...(p.lineProps as any)}
                    />
                ))}
                {!hideBrush &&
                    generateBrush({
                        brushProps,
                        startIndex: 0,
                        // endIndex: data.length - 1, //defaults to all when null/undefined
                        dataKey: xAxisDataKey,
                        theme,
                    })}
                {[...(chartChildren ?? [])]}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default React.forwardRef(ReportLineChart);
