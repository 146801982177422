import React, { useCallback, useMemo } from 'react';
import { Box, TextField } from '@mui/material';
import { User } from 'c-entity-types';
import { Avatar } from 'c-components';
import { userInitials } from 'c-admin/Lib';

import NewMessageControls from './NewMessageControls';
import { ChatMessageAttachment } from '../../types';

type Props = {
    localUser: User;
    newMessagePlaceholder: string;
    onSendMessage: () => void;

    newMessageLoading: boolean;
    newMessage: string;
    setNewMessage: (message: string) => void;

    rawAttachments: File[];
    addAttachments: (files: File[]) => void;
    removeAttachment: (index: number) => void;

    newMessageError?: string;
    showAttachments?: boolean;
};

const textareaSx = { height: '100%', flex: 1 };
const inputProps = {
    sx: { pb: 6 },
};

const avatarSx = { height: 35, width: 35, bgcolor: 'primary.dark', fontSize: '.8em' };

const NewMessage: React.FC<Props> = ({
    localUser,
    newMessagePlaceholder,
    onSendMessage,
    newMessageLoading,
    newMessage,
    setNewMessage,
    addAttachments,
    rawAttachments,
    removeAttachment,
    newMessageError,
    showAttachments = true,
}) => {
    const attachments = useMemo<ChatMessageAttachment[]>(
        () =>
            rawAttachments.map(attachment => ({
                downloadUrl: null,
                filesize: attachment.size,
                name: attachment.name,
                mimetype: attachment.type,
            })),
        [rawAttachments],
    );

    const onTextChange = useCallback(
        e => {
            setNewMessage(e.target.value);
        },
        [setNewMessage],
    );

    const sendDisabled = useMemo(
        () => newMessage.trim().length === 0 || newMessageLoading,
        [newMessage, newMessageLoading],
    );

    const onKeyDown = useCallback(
        e => {
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                if (!sendDisabled) {
                    onSendMessage();
                }
            }
        },
        [sendDisabled, onSendMessage],
    );

    return (
        <Box px={2} flex={1}>
            <Box flexWrap="nowrap" alignItems="center" display="flex">
                <Avatar sx={avatarSx}>{userInitials(localUser)}</Avatar>
                <Box flex={1} display="flex" position="relative" ml={2}>
                    <TextField
                        disabled={newMessageLoading}
                        multiline
                        fullWidth
                        sx={textareaSx}
                        value={newMessage}
                        onChange={onTextChange}
                        placeholder={newMessagePlaceholder}
                        minRows={1}
                        maxRows={10}
                        InputProps={inputProps}
                        onKeyDown={onKeyDown}
                    />
                    <Box position="absolute" bottom={0} left={0} right={0} mr={1.5} mb={0.5}>
                        <NewMessageControls
                            sendDisabled={sendDisabled}
                            onSend={onSendMessage}
                            onAttachmentAdded={addAttachments}
                            onAttachmentRemoved={removeAttachment}
                            attachments={attachments}
                            newMessageError={newMessageError}
                            showAttachments={showAttachments}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default NewMessage;
