import { Skin } from 'c-entity-types';

const SELECTED_SKIN_KEY = 'selected-fe-skin';
const SELECTED_PRE_IMPERSONATION_SKIN_KEY = 'selected-pre-impersonate-fe-skin';

export const getDefaultSkin = (): Skin | undefined => {
    if (localStorage != null) {
        try {
            return JSON.parse(localStorage.getItem(SELECTED_SKIN_KEY));
        } catch (e) {
            return undefined;
        }
    }
    return undefined;
};

export const getPreImpersonateSkin = (): Skin | undefined => {
    if (localStorage != null) {
        try {
            return JSON.parse(localStorage.getItem(SELECTED_PRE_IMPERSONATION_SKIN_KEY));
        } catch (e) {
            return undefined;
        }
    }
    return undefined;
};

export const storePreImpersonateSkin = (skin?: Skin) => {
    if (localStorage != null) {
        localStorage.setItem(SELECTED_PRE_IMPERSONATION_SKIN_KEY, JSON.stringify(skin));
    }
};

export const storeDefaultSkin = (skin: Skin) => {
    if (localStorage != null) {
        localStorage.setItem(SELECTED_SKIN_KEY, JSON.stringify(skin));
    }
};
