import {
    displays_ScreenActions,
    displays_ScreenAdapter,
    displays_ScreenThunks as defaultThunks,
    displays_ScreenSelectors as defaultSelectors,
    displays_ScreenReducer,
} from './Displays_Screen-slice';
import * as thunks from './Displays_Screen-thunks';
import * as selectors from './Displays_Screen-selectors';

const displays_ScreenThunks = { ...defaultThunks, ...thunks };
const displays_ScreenSelectors = { ...defaultSelectors, ...selectors };

export { default as Displays_ScreenSlice } from './Displays_Screen-slice';
export {
    displays_ScreenSelectors,
    displays_ScreenActions,
    displays_ScreenAdapter,
    displays_ScreenThunks,
    displays_ScreenReducer,
};
