import {
    campaignDisplayActions,
    campaignDisplayAdapter,
    campaignDisplayThunks as defaultThunks,
    campaignDisplaySelectors as defaultSelectors,
    campaignDisplayReducer,
} from './CampaignDisplay-slice';
import * as thunks from './CampaignDisplay-thunks';
import * as selectors from './CampaignDisplay-selectors';

const campaignDisplayThunks = { ...defaultThunks, ...thunks };
const campaignDisplaySelectors = { ...defaultSelectors, ...selectors };

export { default as CampaignDisplaySlice } from './CampaignDisplay-slice';
export {
    campaignDisplaySelectors,
    campaignDisplayActions,
    campaignDisplayAdapter,
    campaignDisplayThunks,
    campaignDisplayReducer,
};
