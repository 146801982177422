import { PayloadAction } from '@reduxjs/toolkit';
import { Role } from 'c-entity-types';
import { baseInitialState, createBaseSlice } from 'c-data';
import { RoleState } from './Role-types';

const initialState: RoleState = { ...baseInitialState, roleEditingPermissions: {} };

const additionalReducers = {
    // example: (state: RoleState, action: PayloadAction<string>): RoleState => state,
    toggleRoleEditingPermission: (
        state: RoleState,
        action: PayloadAction<{ permission: number; state: boolean }>,
    ) => {
        // eslint-disable-next-line no-param-reassign
        state.roleEditingPermissions[action.payload.permission] = action.payload.state;
    },
    toggleRoleEditingPermissionMulti: (
        state: RoleState,
        action: PayloadAction<Record<number, boolean>>,
    ) => {
        // eslint-disable-next-line no-param-reassign
        state.roleEditingPermissions = { ...state.roleEditingPermissions, ...action.payload };
    },
    setRoleEditingPermissions: (
        state: RoleState,
        action: PayloadAction<RoleState['roleEditingPermissions']>,
    ): RoleState => ({
        ...state,
        roleEditingPermissions: action.payload,
    }),
};

const slice = createBaseSlice<Role, RoleState, typeof additionalReducers>({
    name: 'role',
    entityName: 'Role',
    initialState,
    reducers: additionalReducers,
});

const roleSlice = {
    roleActions: slice.slice.actions,
    roleReducer: slice.slice.reducer,
    roleAdapter: slice.adapter,
    roleSelectors: slice.selectors,
    roleThunks: slice.thunks,
};

export default roleSlice;
export const { roleReducer, roleActions, roleAdapter, roleSelectors, roleThunks } = roleSlice;
