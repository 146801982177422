import React, { useCallback, useMemo } from 'react';
import { useBoolean } from 'react-hanger';
import { AutoGrid, Dialog, OptionSchema, SelectField } from 'c-components';
import useCampaignWrapperState from 'c-main/Components/Campaign/CampaignReports/useCampaignWrapperState';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { Box, IconButton, SelectChangeEvent, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material';
import { ReportSource } from 'c-main/Components/Campaign/CampaignReports/types';
import { PermissionName } from '@uniled/api-sdk';

type Props = {
    id: number;
};

const PCASettingsDialog: React.FC<Props> = ({ id }) => {
    const { value: isOpen, setTrue: open, setFalse: close } = useBoolean(false);
    const {
        environmentOptions,
        onEnvironmentModeChange,
        environmentMode,
        hasPCAData,
        urlEsSelfRefiningFiltersValue,
        onSelfRefiningFiltersToggle,
        source,
        onSourceToggle,
    } = useCampaignWrapperState(id);
    const environmentHandler = useCallback(
        (event: SelectChangeEvent<string>) => {
            const newVal = event.target.value;
            onEnvironmentModeChange(newVal);
        },
        [onEnvironmentModeChange],
    );

    const environmentOptionsObject = useMemo(
        () =>
            environmentOptions?.length > 0
                ? environmentOptions.map(option => ({ label: option, value: option }))
                : [],
        [environmentOptions],
    );

    const sourceOptions = useMemo<OptionSchema[]>(
        () => [
            { value: ReportSource.ElasticSearch, label: 'Elastic Search' },
            { value: ReportSource.Athena, label: 'Athena' },
        ],
        [],
    );

    const onOpenClicked = useCallback(
        e => {
            e.stopPropagation();
            open();
        },
        [open],
    );

    const onSelfRefiningFiltersChange = useCallback(
        (e, newValue: boolean) => {
            e.stopPropagation();
            onSelfRefiningFiltersToggle(newValue);
        },
        [onSelfRefiningFiltersToggle],
    );
    return (
        <>
            <>
                <IconButton onClick={onOpenClicked}>
                    <Settings fontSize="inherit" />
                </IconButton>
            </>
            <Dialog
                title=""
                onClose={close}
                show={isOpen}
                content={
                    <Box onClick={e => e.stopPropagation()}>
                        <IfHasAllPermissions permissions={[PermissionName.ReportingPcareportRead]}>
                            <AutoGrid xs={12} spacing={2}>
                                <SelectField
                                    options={environmentOptionsObject}
                                    disabled={!hasPCAData}
                                    onChange={environmentHandler}
                                    value={environmentMode}
                                    label="Environment"
                                />
                                <Box>
                                    <Typography variant="h4">In Development</Typography>
                                    <Typography variant="body2">
                                        These features are experimental.
                                    </Typography>
                                </Box>
                                {/* <ToggleButtonGroup */}
                                {/*    exclusive */}
                                {/*    options={sourceOptions} */}
                                {/*    onChange={onSourceToggle} */}
                                {/*    value={source} */}
                                {/*    label="Database" */}
                                {/* /> */}
                                {/* <FormGroup> */}
                                {/*    <FormControlLabel */}
                                {/*        control={ */}
                                {/*            <Switch */}
                                {/*                checked={ */}
                                {/*                    source === ReportSource.Athena */}
                                {/*                        ? false */}
                                {/*                        : urlEsSelfRefiningFiltersValue */}
                                {/*                } */}
                                {/*                onChange={onSelfRefiningFiltersChange} */}
                                {/*                disabled={source === ReportSource.Athena} */}
                                {/*            /> */}
                                {/*        } */}
                                {/*        label="Self Refining Filters (Elastic Search Only)" */}
                                {/*    /> */}
                                {/* </FormGroup> */}
                            </AutoGrid>
                        </IfHasAllPermissions>
                    </Box>
                }
                removeForm
            />
        </>
    );
};

export default PCASettingsDialog;
