import React, { InputHTMLAttributes, PropsWithChildren, useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { Alert } from 'c-components/MuiOverrides';
import Button from '../Button';
import { ButtonProps } from '../Button/Button';

type Props = PropsWithChildren<
    {
        id: string;
        onFileSelected: (file: File[]) => void;
        multiple?: boolean;
        accept?: InputHTMLAttributes<any>['accept'];
    } & ButtonProps
>;

const isFilenameValid = filename => {
    const disallowedCharsRegex = /[\\/:*?"<>|~]/;
    return !disallowedCharsRegex.test(filename);
};

const FileUploadButton: React.FC<Props> = ({
    id,
    onFileSelected,
    multiple = false,
    accept = 'image/*',
    children,
    ...rest
}) => {
    const [error, setError] = useState<string | null>(null);
    const onChange = useCallback(
        e => {
            setError(null);
            const files: FileList = e.target.files;

            if (files.length > 0 && files.item(0)) {
                const validFiles = Array.from(files).filter(file => isFilenameValid(file.name));
                const invalidFilesCount = files.length - validFiles.length;
                if (invalidFilesCount > 0) {
                    setError(
                        `${invalidFilesCount} file(s) were not added due to invalid characters in their names.   \n Invalid characters are: '\\', '/', ':', '*', '?', '"', '<', '>', '|', '~'`,
                    );
                }

                if (validFiles.length > 0) {
                    onFileSelected(validFiles);
                }
                e.target.value = '';
            }
        },
        [onFileSelected],
    );
    return (
        <>
            <label htmlFor={id}>
                <Box display="none">
                    <input
                        accept={accept}
                        type="file"
                        id={id}
                        multiple={multiple}
                        onChange={onChange}
                    />
                </Box>
                <Button
                    // just has to be a span to work for some reason
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    component="span"
                    {...rest}
                >
                    {children}
                </Button>
            </label>
            {error && <Alert severity="error">{error}</Alert>}
        </>
    );
};

export default FileUploadButton;
