import React, { useCallback, useMemo } from 'react';
import { Box, TableSortLabel } from '@mui/material';
import { BaseEntity } from '@uniled/api-sdk';
import { SlicePaginationData } from '@uniled/data-layer';
import { EntityListColumn } from 'c-pagination';
import { TranslationPath, useCommonTranslation } from 'c-translation';
import { useAuthenticatedUser } from 'c-hooks';

type Props<Entity extends BaseEntity> = {
    column: EntityListColumn<Entity>;
    paginationData: SlicePaginationData<Entity>;
    onOrderByClicked: (column: EntityListColumn<Entity>, orderByKey: string) => void;
};

function EntityColumnHeader<Entity extends BaseEntity>({
    column,
    paginationData,
    onOrderByClicked,
}: Props<Entity>) {
    const translatedTitle = useCommonTranslation(column.headerTitle as TranslationPath);
    const content = useMemo(() => {
        return (
            <>
                {column.RenderHeader ? (
                    <column.RenderHeader column={column} paginationData={paginationData} />
                ) : (
                    translatedTitle ?? column.headerTitle
                )}
            </>
        );
    }, [column, paginationData, translatedTitle]);

    const orderByKey = useMemo(() => {
        if (column.orderByKey != null) {
            return column.orderByKey;
        }
        return column.field;
    }, [column]);

    const direction = useMemo<'asc' | 'desc'>(() => {
        return paginationData.direction ?? 'asc';
    }, [paginationData.direction]);

    const active = useMemo(() => {
        return paginationData.orderBy === orderByKey;
    }, [paginationData.orderBy, orderByKey]);

    const orderByClicked = useCallback(() => {
        onOrderByClicked(column, orderByKey);
    }, [column, onOrderByClicked, orderByKey]);

    const { chosenUserType } = useAuthenticatedUser();

    const isActuallyOrderable = useMemo(() => {
        if (!column.orderable) return false;

        if (!Array.isArray(column.orderByAllowedUserType)) return column.orderable;

        return column.orderByAllowedUserType.indexOf(chosenUserType) !== -1;
    }, [column, chosenUserType]);

    return (
        <Box textAlign={column.textAlign}>
            {!isActuallyOrderable && content}
            {isActuallyOrderable === true && (
                <TableSortLabel direction={direction} active={active} onClick={orderByClicked}>
                    {content}
                </TableSortLabel>
            )}
        </Box>
    );
}

export default EntityColumnHeader;
