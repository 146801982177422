import { css } from '@emotion/react';
import { OptionSchema } from 'c-components/Forms/formTypes';
import { AutocompleteProps } from 'c-components/Forms/Autocomplete';
import { TextFieldProps } from '@mui/material';
import { DefaultTheme } from '@mui/styles';

export const generateTextFieldProps = ({
    variant,
    size,
    placeholder,
    error,
    theme,
    value,
    options,
    multiple,
}: {
    variant: TextFieldProps['variant'];
    size: TextFieldProps['size'];
    placeholder?: TextFieldProps['placeholder'];
    error?: boolean;
    theme: DefaultTheme;
    value: any | any[];
    options: OptionSchema[];
    multiple?: boolean;
}) => {
    let count = 0;

    if (multiple) count = options.filter(opt => (value as any[])?.indexOf(opt.value) !== -1).length;
    else count = options.filter(opt => opt.value === value).length;

    return {
        variant,
        size,
        placeholder,
        label: placeholder,
        // placeholder: `${placeholder ?? ''} ${count > 0 ? `(${count})` : ''}`,
        css: css(`
                    fieldset {
                        ${count > 0 && `border-top: 2px solid ${theme.palette.primary.main}`}
                    }
                `),
        InputProps: {
            // disableUnderline: true,
            sx: {
                bgcolor: 'white',
            },
        },
        whiteBackdrop: false,
        error,
    } as AutocompleteProps['textFieldProps'];
};

export const formatPlaceholder = (
    selectedOptions: OptionSchema[],
    multiple: boolean,
    defaultPlaceholder?: string,
) => {
    if (selectedOptions.length > 0) {
        // don't bother including the count for singular dropdowns
        if (!multiple) return selectedOptions.map(val => val.label).join(', ');
        return `(${selectedOptions.length}) ${selectedOptions.map(val => val.label).join(', ')}`;
    }

    return defaultPlaceholder;
};
