import React, { forwardRef, Ref, useMemo } from 'react';
import { Box, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { ControllerFieldState } from 'react-hook-form';
import cx from 'classnames';

export type CustomTextFieldProps = {
    greyBackdrop?: boolean;
    whiteBackdrop?: boolean;
    fieldState?: ControllerFieldState;
} & MuiTextFieldProps;

const TextField = (
    { greyBackdrop = false, whiteBackdrop = true, fieldState, ...props }: CustomTextFieldProps,
    ref: Ref<any>,
) => {
    const shrink = useMemo(
        () =>
            Boolean(
                ((typeof props.value === 'string' && props.value.length > 0) ||
                    (typeof props.inputProps?.value === 'string' &&
                        props.inputProps?.value?.length > 0) ||
                    (typeof props.value === 'number' && props.value.toString().length > 0)) &&
                    props.label,
            ),
        [props.label, props.value, props.inputProps?.value],
    );

    const TheTextField = useMemo(
        () => (
            <MuiTextField
                fullWidth
                variant="outlined"
                className={cx({ whiteBackdrop, greyBackdrop })}
                helperText={fieldState?.error?.message ?? undefined}
                error={fieldState?.invalid ?? false}
                {...props}
                value={props.value ?? ''}
                ref={ref}
                InputLabelProps={{
                    shrink,
                    ...props.InputLabelProps,
                }}
            />
        ),
        [greyBackdrop, props, ref, shrink, whiteBackdrop, fieldState],
    );

    if (whiteBackdrop && !greyBackdrop) {
        return (
            <Box bgcolor="white" width={props.fullWidth === true ? '100%' : undefined}>
                {TheTextField}
            </Box>
        );
    }

    if (greyBackdrop) {
        return (
            <Box bgcolor="grey.200" width={props.fullWidth === true ? '100%' : undefined}>
                {TheTextField}
            </Box>
        );
    }

    return TheTextField;
};

export default forwardRef<any, CustomTextFieldProps>(TextField);
