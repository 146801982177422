import React from 'react';
import { EntityAutocomplete } from 'c-components';
import { EntityAutocompleteProps } from 'c-components/EntityAutocomplete/EntityAutocomplete';
import { User } from 'c-entity-types';
import { userName } from 'c-admin/Lib/User';

const generateLabel = (user: User) => `#${user.id} ${userName(user)} - ${user.email}`;

const UserEntityAutocomplete: React.FC<Partial<EntityAutocompleteProps>> = props => (
    <EntityAutocomplete
        {...(props as EntityAutocompleteProps)}
        entityName="User"
        searchColumns={['name', 'email']}
        labelColumn="name"
        labelGen={generateLabel}
    />
);

export default UserEntityAutocomplete;
