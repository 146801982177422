import { Displays_Screen } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { Displays_ScreenState } from './Displays_Screen-types';

const initialState: Displays_ScreenState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: Displays_ScreenState, action: PayloadAction<string>): Displays_ScreenState => state,
};

const slice = createBaseSlice<Displays_Screen, Displays_ScreenState, typeof additionalReducers>({
    name: 'displays_Screen',
    entityName: 'Displays_Screen',
    initialState,
    reducers: additionalReducers,
});

const displays_ScreenSlice = {
    displays_ScreenActions: slice.slice.actions,
    displays_ScreenReducer: slice.slice.reducer,
    displays_ScreenAdapter: slice.adapter,
    displays_ScreenSelectors: slice.selectors,
    displays_ScreenThunks: slice.thunks,
};

export default displays_ScreenSlice;
export const {
    displays_ScreenReducer,
    displays_ScreenActions,
    displays_ScreenAdapter,
    displays_ScreenSelectors,
    displays_ScreenThunks,
} = displays_ScreenSlice;
