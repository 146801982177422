import React, { forwardRef, Ref, useCallback, useMemo } from 'react';
import { format, isValid, parse, parseISO } from 'date-fns';
import { IconButton, TextFieldProps } from '@mui/material';
import merge from 'deepmerge';
import { useDateUtils } from 'c-hooks';
import { DateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { Event } from '@mui/icons-material';
import { TextField } from '../TextField';

type Props = {
    value: [string, string];
    /**
     * This is the format that will be outputted when the form is submitted. This defaults to the displayFormat
     */
    outputFormat?: string;
    /** Set this to true if the input value is in ISO8601 format */
    inputFormatISO8601?: boolean;
    error?: boolean;
    label?: string;
    onBlur?: () => void;

    inputProps?: TextFieldProps;

    onChange: (value: [string, string]) => void;

    openOnFocus?: boolean;
} & Omit<
    DateRangePickerProps<Date>,
    'error' | 'onChange' | 'value' | 'type' | 'label' | 'renderInput' | 'ToolbarComponent'
>;

const DateRangeField = (
    {
        label,
        onBlur,
        onChange,
        inputProps,
        value,
        outputFormat,
        inputFormatISO8601,
        error,
        openOnFocus,
        slots,
        slotProps,
        ...props
    }: Props,
    ref: Ref<any>,
) => {
    const { datePickerInputFormat, dayMonthYearApiFormat } = useDateUtils();

    const outputDateFormat = useMemo(
        () => outputFormat ?? dayMonthYearApiFormat,
        [outputFormat, dayMonthYearApiFormat],
    );

    // the format shown to users in the input field
    const inputDateFormat = useMemo(() => datePickerInputFormat, [datePickerInputFormat]);

    const datePickerValue = useMemo(() => {
        if (!Array.isArray(value) || (Array.isArray(value) && value.length !== 2))
            return [null, null];

        return value.map(val => {
            let actualValue: string | Date = val;
            if (typeof actualValue === 'string') {
                actualValue = inputFormatISO8601
                    ? parseISO(actualValue)
                    : parse(actualValue, outputDateFormat, new Date());
            }

            // setting to null because undefined results in today's date being selected
            if (actualValue == null) actualValue = null;

            return isValid(actualValue) ? (actualValue as unknown as Date) : null;
        }) as [Date, Date];
    }, [value, inputFormatISO8601, outputDateFormat]);

    const handleOnChange = useCallback<DateRangePickerProps<Date>['onChange']>(
        (newDates: [Date | null, Date | null]) => {
            onChange(
                newDates.map(d =>
                    isValid(d) ? format(d, outputDateFormat) : null,
                ) as Props['value'],
            );
        },
        [onChange, outputDateFormat],
    );

    return (
        <DateRangePicker
            ref={ref}
            label={label}
            format={inputDateFormat}
            onChange={handleOnChange as any}
            value={datePickerValue as any as [Date, Date]}
            calendars={2}
            slots={useMemo(
                () =>
                    merge(
                        {
                            textField: TextField,
                            field: SingleInputDateRangeField,
                        },
                        { ...slots },
                    ),
                [slots],
            )}
            slotProps={useMemo(
                () =>
                    merge(
                        {
                            field: {
                                fullWidth: true,
                                error,
                                ...inputProps,
                            },
                            textField: {
                                fullWidth: true,
                                error,
                                InputProps: {
                                    endAdornment: (
                                        <IconButton size="small">
                                            <Event />
                                        </IconButton>
                                    ),
                                },
                                ...inputProps,
                            },
                            actionBar: {
                                actions: ['clear', 'accept'],
                            },
                            fieldSeparator: { sx: { my: 'auto' } },
                        },
                        { ...slotProps },
                    ),
                [error, inputProps, slotProps],
            )}
            {...props}
        />
    );
};

export default forwardRef(DateRangeField);
