import React, { useCallback, useMemo, useState } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Alert, AllEntitiesDropdown, Button, CopyButton, TextField } from 'c-components';
import { uniq } from 'ramda';
import { useCommonTranslation } from 'c-translation';
import { useAPIClientRequest } from 'c-hooks';
import { apiClient } from 'c-data';
import { NetworkRequestState } from '@uniled/data-layer';
import to from 'await-to-js';
import {
    DataGridPro,
    DataGridProProps,
    GridToolbarContainer,
    GridToolbarExport,
} from '@mui/x-data-grid-pro';
import {
    Displays_FileType,
    Displays_Owner,
    Displays_Pack,
    Displays_Resolution,
    Displays_Screen,
} from '@uniled/api-sdk';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyTextToClipboard } from 'c-lib';

type Props = {
    //
};

type ListedScreen = Omit<Displays_Screen, 'resolutions' | 'fileTypes' | 'packs'> & {
    resolutions: string;
    fileTypes: string;
    owners: string;
    packs: string;
};

type ListedPack = {
    id: string;
    resolution: string;
    owner: string;
    pack: string;
};

function CustomToolbarWithCopy({ onCopyFrameIds, t }) {
    return (
        <GridToolbarContainer>
            <GridToolbarExport csvOptions={{ allColumns: true }} />
            <IconButton size="small" onClick={onCopyFrameIds}>
                <ContentCopyIcon fontSize="small" />
                <Typography fontWeight="normal" fontSize="small" alignSelf="center">
                    {t('Modules.Admin.FrameSearch.copyFrameIds')}
                </Typography>
            </IconButton>
        </GridToolbarContainer>
    );
}
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}
const FrameSearchForm: React.FC<Props> = () => {
    const [inputValue, setInputValue] = useState('');
    const [markets, setMarkets] = useState<number>();

    const onTextChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value);
        },
        [setInputValue],
    );

    const onChangeMarket = useCallback(
        (market_id: number) => {
            setMarkets(market_id);
        },
        [setMarkets],
    );
    const uniqueIds = useMemo(
        () =>
            uniq(
                inputValue
                    .split('\n')
                    ?.map(id => id.trim())
                    .filter(id => id.length > 0),
            ),
        [inputValue],
    );
    const t = useCommonTranslation();

    const columns = useMemo<DataGridProProps['columns']>(
        () => [
            {
                field: 'vendor_id',
                headerName: t('Modules.Admin.FrameSearch.table.uploadedID'),
            },
            {
                field: 'frame_id',
                minWidth: 120,
                headerName: t('Modules.Admin.FrameSearch.table.frameIdCol'),
            },
            {
                field: 'screenId',
                minWidth: 130,
                headerName: t('Modules.Admin.FrameSearch.table.id'),
            },
            { field: 'status', headerName: t('Modules.Admin.FrameSearch.table.active') },
            {
                field: 'address',
                flex: 1,
                headerName: t('Modules.Admin.FrameSearch.table.name'),
            },
            {
                field: 'vendor_name',
                minWidth: 150,
                headerName: t('Modules.Admin.FrameSearch.table.idSource'),
            },
            {
                field: 'owners',
                minWidth: 150,
                headerName: t('Modules.Admin.FrameSearch.table.mediaOwnerCol'),
            },
            {
                field: 'line_item_name',
                minWidth: 120,
                headerName: t('Modules.Admin.FrameSearch.table.lineItemName'),
            },
            {
                field: 'resolutions',
                minWidth: 200,
                headerName: t('Modules.Admin.FrameSearch.table.resolutionCol'),
            },
            {
                field: 'packs',
                headerName: t('Modules.Admin.FrameSearch.table.packNameCol'),
            },
            { field: 'conflict', headerName: t('Modules.Admin.FrameSearch.table.conflict') },
        ],
        [t],
    );

    const columns2 = useMemo<DataGridProProps['columns']>(
        () => [
            {
                field: 'owner',
                flex: 1,
                headerName: t('Modules.Admin.FrameSearch.table.mediaOwnerCol'),
            },
            {
                field: 'pack',
                flex: 1,
                headerName: t('Modules.Admin.FrameSearch.table.packNameCol'),
            },
            {
                field: 'resolution',
                flex: 1,
                headerName: t('Modules.Admin.FrameSearch.table.resolutionCol'),
            },
        ],
        [t],
    );

    const { start, data, requestStateRef, isLoading } = useAPIClientRequest(
        apiClient.Entities.Displays_Screen.frameSearch,
    );
    const conflictedScreens = useMemo<ListedScreen[]>(() => {
        const conflictedScreens = data?.data?.conflicts.flatMap(conflict =>
            conflict.options.map(option => option),
        );
        return conflictedScreens?.map(screen => ({
            ...screen,
            conflict: t('Modules.Admin.FrameSearch.yes'),
            line_item_name: screen.line_item_name
                ? screen.line_item_name
                : t('Modules.Admin.FrameSearch.na'),
            resolutions:
                (screen as Displays_Screen)?.resolutions
                    .map((res: Displays_Resolution) => `${res.width}x${res.height}`)
                    .join(', ') ?? '',
            fileTypes:
                screen?.fileTypes.map((type: Displays_FileType) => type.type).join(', ') ?? '',
            owners: screen?.mediaOwners?.map((o: Displays_Owner) => o.name).join(', '),
            packs:
                screen?.packs.length > 0
                    ? screen?.packs?.map((p: Displays_Pack) => p.name).join(', ')
                    : t('Modules.Admin.FrameSearch.na'),
            status:
                screen?.status === 0
                    ? t('Modules.Admin.FrameSearch.inactive')
                    : t('Modules.Admin.FrameSearch.active'),
        }));
    }, [data, t]);

    const search = useCallback(async () => {
        if (requestStateRef.current !== NetworkRequestState.InProgress) {
            await to(
                start({
                    market_id: markets,
                    frames: uniqueIds.map(id => ({ vendor_id: id })),
                }),
            );
        }
    }, [requestStateRef, start, markets, uniqueIds]);

    const screens = useMemo<ListedScreen[]>(() => {
        const screenData = data?.data?.screens || [];
        return screenData?.map(screen => ({
            ...screen,
            conflict: t('Modules.Admin.FrameSearch.no'),
            line_item_name: screen.line_item_name
                ? screen.line_item_name
                : t('Modules.Admin.FrameSearch.na'),
            resolutions:
                screen?.resolutions
                    .map((res: Displays_Resolution) => `${res.width}x${res.height}`)
                    .join(', ') ?? '',
            fileTypes:
                screen?.fileTypes.map((type: Displays_FileType) => type.type).join(', ') ?? '',
            owners: screen?.mediaOwners?.map((o: Displays_Owner) => o.name).join(', '),
            packs:
                screen?.packs?.length > 0
                    ? screen?.packs?.map((p: Displays_Pack) => p.name).join(', ')
                    : t('Modules.Admin.FrameSearch.na'),
            status:
                screen?.status === 0
                    ? t('Modules.Admin.FrameSearch.inactive')
                    : t('Modules.Admin.FrameSearch.active'),
        }));
    }, [data, t]);

    const allScreens = useMemo(() => {
        const oldIdScreens = []
            .concat(screens, conflictedScreens)
            .sort((a, b) => a.uploadedID - b.uploadedID);
        // eslint-disable-next-line no-restricted-globals
        return (oldIdScreens as Displays_Screen[]).map((screen, index) => {
            const oldId = screen?.id;
            return {
                ...screen,
                id: index,
                screenId: oldId,
            };
        });
    }, [screens, conflictedScreens]);

    const uniqueScreenResolutions = useMemo(
        () =>
            uniq(
                (data as any)?.data?.screens?.data?.reduce(
                    (resolutions: any, screen: Displays_Screen) => [
                        ...resolutions,
                        ...screen?.resolutions?.map(r => `${r.width}x${r.height}`),
                    ],
                    [],
                ) ?? [],
            ),
        [data],
    );
    const handleCopyFrameIds = useCallback(() => {
        const frameIdsString = allScreens.map(screen => screen.frame_id).join('\n');
        const uniqueFrameIds = uniq(
            frameIdsString
                .split('\n')
                ?.map(id => id.trim())
                .filter(id => id.length > 0),
        );
        const uniqueFrameIdsString = uniqueFrameIds.join('\n');
        copyTextToClipboard(uniqueFrameIdsString);
    }, [allScreens]);
    const missingIds = useMemo(() => data?.data?.missing, [data]);
    const uniquePacks = useMemo(
        () =>
            (data?.data?.screens ?? []).reduce(
                (currentData: any, screen: Displays_Screen) => {
                    screen?.packs?.forEach(pack => {
                        pack?.resolutions?.forEach(res => {
                            const resolution = `${res.width}x${res.height}`;
                            const key = `${pack.name}.${pack?.owner.name}.${resolution}`;

                            if (
                                // only want to include pack resolutions for resolutions returned for frames
                                uniqueScreenResolutions.indexOf(resolution) !== -1 &&
                                currentData.combos.indexOf(key) === -1
                            ) {
                                currentData.combos.push(key);
                                currentData.packs.push({
                                    pack: pack.name,
                                    owner: pack?.owner.name,
                                    id: key,
                                    resolution,
                                });
                            }

                            if (currentData.packResolutions.indexOf(resolution) === -1)
                                currentData.packResolutions.push(resolution);
                        });
                    });

                    return currentData;
                },
                { combos: [], packs: [], packResolutions: [] } as {
                    combos: string[];
                    packs: ListedPack[];
                    packResolutions: string[];
                },
            ),
        [data, uniqueScreenResolutions],
    );

    const packlessUniqueResolutions = useMemo(
        () =>
            (data?.data?.screens ?? []).reduce(
                (currentData: any, screen: Displays_Screen) => {
                    screen?.resolutions?.forEach(res => {
                        const resolution = `${res.width}x${res.height}`;
                        const owners = `${screen.mediaOwners?.map(o => o.name)?.join(', ') ?? ''}`;
                        const key = `${owners}.${resolution}`;

                        if (
                            currentData.combos.indexOf(key) === -1 &&
                            uniquePacks.packResolutions.indexOf(resolution) === -1
                        ) {
                            currentData.combos.push(key);
                            currentData.packs.push({
                                pack: '',
                                owner: owners,
                                id: key,
                                resolution,
                            });
                        }
                    });
                    return currentData;
                },
                { combos: [], packs: [] } as { combos: string[]; packs: ListedPack[] },
            ),
        [data, uniquePacks.packResolutions],
    );
    const combinedUniqueResolutions = useMemo(
        () => [...uniquePacks?.packs, ...packlessUniqueResolutions?.packs],
        [packlessUniqueResolutions?.packs, uniquePacks?.packs],
    );

    const missingFramesText = useMemo(
        () =>
            t('Modules.Admin.FrameSearch.missingFrames', {
                count: missingIds?.length,
                frames: missingIds?.join(', '),
            }),
        [t, missingIds],
    );
    const missingIdCopyContent = useMemo(() => missingIds?.join('\n'), [missingIds]);
    return (
        <Box>
            <Stack gap={2}>
                <AllEntitiesDropdown
                    entityName="Market"
                    labelField="name"
                    value={markets}
                    onChange={onChangeMarket}
                    useLocalEntityData
                />

                <TextField
                    id="frame-search-ids"
                    maxRows={20}
                    multiline
                    value={inputValue}
                    autoFocus
                    onChange={onTextChange}
                    helperText={
                        uniqueIds.length > 0 &&
                        t('Modules.Admin.FrameSearch.frameCount', {
                            count: uniqueIds.length,
                        })
                    }
                />
                <Button disabled={isLoading || uniqueIds.length === 0} onClick={search}>
                    {t('Modules.Admin.FrameSearch.searchLabel')}
                </Button>

                {missingIds?.length > 0 && (
                    <Alert
                        severity="warning"
                        variant="outlined"
                        action={<CopyButton copyContent={missingIdCopyContent} />}
                    >
                        {missingFramesText}
                    </Alert>
                )}

                {(screens?.length > 0 || conflictedScreens?.length > 0) && (
                    <Stack gap={2}>
                        <Typography variant="h4">
                            {t('Modules.Admin.FrameSearch.frameExportReport')}
                        </Typography>
                        <Box height="65vh" width="100%" display="flex">
                            <DataGridPro
                                disableRowSelectionOnClick
                                disableColumnReorder
                                disableColumnSelector
                                disableColumnPinning
                                hideFooter
                                columns={columns}
                                rows={allScreens}
                                loading={isLoading}
                                slots={{ toolbar: CustomToolbarWithCopy }}
                                slotProps={{ toolbar: { onCopyFrameIds: handleCopyFrameIds, t } }}
                            />
                        </Box>
                        <Typography variant="h4">
                            {t('Modules.Admin.FrameSearch.uniqueResolutions')}
                        </Typography>
                        <Box height="65vh" width="100%" display="flex">
                            <DataGridPro
                                disableRowSelectionOnClick
                                disableColumnReorder
                                disableColumnSelector
                                disableColumnPinning
                                hideFooter
                                columns={columns2}
                                rows={combinedUniqueResolutions}
                                loading={isLoading}
                                slots={{ toolbar: CustomToolbar }}
                                slotProps={{ toolbar: { csvOptions: { allColumns: true } } }}
                            />
                        </Box>
                    </Stack>
                )}
            </Stack>
        </Box>
    );
};

export default FrameSearchForm;
