import { CampaignPopShot } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { CampaignPopShotState } from './CampaignPopShot-types';

const initialState: CampaignPopShotState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: CampaignPopShotState, action: PayloadAction<string>): CampaignPopShotState => state,
};

const slice = createBaseSlice<CampaignPopShot, CampaignPopShotState, typeof additionalReducers>({
    name: 'campaignPopShot',
    entityName: 'CampaignPopShot',
    initialState,
    reducers: additionalReducers,
});

const campaignPopShotSlice = {
    campaignPopShotActions: slice.slice.actions,
    campaignPopShotReducer: slice.slice.reducer,
    campaignPopShotAdapter: slice.adapter,
    campaignPopShotSelectors: slice.selectors,
    campaignPopShotThunks: slice.thunks,
};

export default campaignPopShotSlice;
export const {
    campaignPopShotReducer,
    campaignPopShotActions,
    campaignPopShotAdapter,
    campaignPopShotSelectors,
    campaignPopShotThunks,
} = campaignPopShotSlice;
