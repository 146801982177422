import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NetworkRequestState } from '@uniled/data-layer';
import { useCommonTranslation, TranslationPath } from 'c-translation';
import { MetaLayout, PreAuthLayout } from 'c-wrapper';
import { FlashMessage } from 'c-components';
import { passwordResetSelectors, passwordResetThunks } from '../Slices';
import RequestPasswordResetForm from '../Components/RequestPasswordResetForm';

const RequestPasswordResetPage = () => {
    const dispatch = useDispatch();
    const reqState = useSelector(passwordResetSelectors.passwordResetEmailRequestState);
    const reqStateError = useSelector(passwordResetSelectors.passwordResetEmailRequestError);
    const errorMessage = useCommonTranslation(reqStateError as TranslationPath);
    const successMessage = useCommonTranslation('Pages.ForgotPasswordCode.resetEmailSuccess');

    const flashMessage = useMemo(() => {
        if (reqState === NetworkRequestState.Error) {
            return <FlashMessage status="error">{errorMessage}</FlashMessage>;
        }
        if (reqState === NetworkRequestState.Success) {
            return <FlashMessage status="success">{successMessage}</FlashMessage>;
        }

        return null;
    }, [reqState, errorMessage, successMessage]);

    useEffect(() => {
        return () => {
            dispatch(passwordResetThunks.resetRequestResetEmail());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MetaLayout metaTitle="Pages.ForgotPasswordRequest.metaTitle">
            <PreAuthLayout
                flashMessage={flashMessage}
                title={useCommonTranslation('Pages.ForgotPasswordRequest.title')}
                subtitle={useCommonTranslation('Pages.ForgotPasswordRequest.subTitle')}
            >
                <RequestPasswordResetForm />
            </PreAuthLayout>
        </MetaLayout>
    );
};

export default RequestPasswordResetPage;
