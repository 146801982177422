import React, { useMemo } from 'react';
import { useDateUtils, useEntityField } from 'c-hooks';
import { User } from 'c-entity-types';
import { initials } from 'c-admin/Lib';
import AutoGrid from 'c-components/AutoGrid';
import { editIcon } from 'c-main/icons';
import UserProfilePopOver from 'c-admin/Components/User/UserProfilePopOver';
import { BoxProps, Typography } from '@mui/material';

type Props = {
    userId?: number;
    updatedAt?: string;
    showTime?: boolean;
    justifyContent?: BoxProps['justifyContent'];
};

const UpdatedByUserCaption: React.FC<Props> = ({
    userId,
    updatedAt,
    showTime = true,
    justifyContent = 'flex-end',
}) => {
    const { text } = useEntityField<User>(userId, 'User', 'name');

    const { formatDateString } = useDateUtils();
    const userInitials = useMemo(() => initials(text ?? ''), [text]);
    const editDate = useMemo(
        () =>
            formatDateString({
                date: updatedAt,
                removeTime: false,
            }),
        [formatDateString, updatedAt],
    );

    return (
        <Typography variant="caption" noWrap>
            <AutoGrid
                columnGap={0.5}
                flexWrap="nowrap"
                justifyContent={justifyContent}
                sx={{ pr: 1 }}
            >
                {editIcon}
                <UserProfilePopOver userId={userId}>
                    <>{userInitials}</>
                </UserProfilePopOver>
                {editDate}
            </AutoGrid>
        </Typography>
    );
};

export default UpdatedByUserCaption;
