import React from 'react';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Translate, useCommonTranslation } from 'c-translation';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as UniledLogo } from 'c-assets/uniled-black.svg';
import { drawerWidth } from 'c-wrapper/constants';
import SkinLangSwitcher from 'c-wrapper/Components/SkinLangSwitcher';

const currentYear = format(new Date(), 'yyyy');

const ContentFooter = () => {
    return (
        <Box
            mb={{ xs: 1.5, md: 0 }}
            // borderTop="1px solid rgba(2, 69, 85, 0.2)"
            py={2}
            px={2}
            whiteSpace="pre-line"
        >
            <Typography align="center" color="textSecondary" variant="body2" component={Box}>
                <Translate path="Meta.footerPoweredBy" />
                <Box textAlign="center">
                    <svg height={40} width={drawerWidth - 30}>
                        <UniledLogo />
                    </svg>
                </Box>
            </Typography>
            <Typography align="center" color="textSecondary" variant="body2">
                {`Copyright © ${currentYear} ${useCommonTranslation(
                    'Meta.footCopyrightCompany',
                )}. All rights reserved.`}
            </Typography>
            <SkinLangSwitcher />
        </Box>
    );
};

export default ContentFooter;
