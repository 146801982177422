import React from 'react';
import { Box } from '@mui/material';
import { useComponentOverrides } from 'c-wrapper/Components/CommonWrapper/CommonWrapper';
import { useSelector } from 'react-redux';
import { uiSelectors } from 'c-wrapper/Slices/UI';
import { drawerWidth } from 'c-wrapper/constants';

const IconTopBarBrandLogo: React.FC = () => {
    const { MiniBrandLogo } = useComponentOverrides();
    const isDrawerOpen = useSelector(uiSelectors.isDrawerOpen);
    return (
        <Box
            color="primary.main"
            display="flex"
            maxWidth={isDrawerOpen ? drawerWidth - 100 : 50}
            sx={{
                transition: 'all 300ms',
                height: 45,
            }}
        >
            {MiniBrandLogo}
        </Box>
    );
};

export default IconTopBarBrandLogo;
