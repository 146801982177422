import React from 'react';
import { Box } from '@mui/material';
import { TranslationPath as CommonTranslationPath, useCommonTranslation } from 'c-translation';
import { HighlightWord } from 'c-components';
import { useMemo } from 'react';
import { SystemSearchResultBreadcrumbsProps } from '../../types';

const BreadCrumb = ({
    crumb,
    searchTerm,
}: {
    crumb: CommonTranslationPath;
    searchTerm: string;
}) => {
    const translate = useCommonTranslation();
    return useMemo(
        () => <HighlightWord text={translate(crumb)} searchTerm={searchTerm} />,
        [crumb, searchTerm, translate],
    );
};

const SystemSearchResultBreadcrumbs = ({
    crumbs,
    searchTerm,
}: SystemSearchResultBreadcrumbsProps) =>
    useMemo(
        () => (
            <Box display="flex" alignItems="center">
                {crumbs.map((c, i) => {
                    const isLast = i >= crumbs.length - 1;
                    return (
                        <React.Fragment key={c}>
                            <Box
                                fontStyle="italic"
                                color="#6c6c6c"
                                mb={-1.5}
                                mt={1.4}
                                fontSize={13}
                            >
                                <BreadCrumb crumb={c} searchTerm={searchTerm} />
                                {!isLast && <> /&nbsp;</>}
                            </Box>
                        </React.Fragment>
                    );
                })}
            </Box>
        ),
        [crumbs, searchTerm],
    );

export default SystemSearchResultBreadcrumbs;
