import React from 'react';
import { Box } from '@mui/material';
import { AllEntitiesDropdown, OptionSchema } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { Skin } from 'c-entity-types';

type Props = {
    onChange: (skinId: number) => void;
    selectedSkinId: number;
    additionalOptions: OptionSchema[];
};

const AdminSkinSwitcher: React.FC<Props> = ({ onChange, selectedSkinId, additionalOptions }) => {
    return (
        <Box py={2}>
            <AllEntitiesDropdown
                entityName="Skin"
                labelField="name"
                label={useCommonTranslation('Modules.Admin.SkinSwitcher.dropdownLabel')}
                value={selectedSkinId}
                onChange={onChange as any}
                additionalOptions={additionalOptions}
            />
        </Box>
    );
};

export default AdminSkinSwitcher;
