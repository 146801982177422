import React, { useCallback, useMemo } from 'react';
import { SetSelectedCreatives } from 'c-main/Components/CreativeManagement/types';
import { Checkbox, FileBrowserSelectState } from 'c-components';
import { uniqWith } from 'ramda';
import { Box } from '@mui/material';

type Props = {
    selectedCreatives: number[];
    setSelectedCreatives: SetSelectedCreatives;
    visibleCreatives: number[];
};

const CreativesSelectAll: React.FC<Props> = ({
    selectedCreatives,
    setSelectedCreatives,
    visibleCreatives,
}) => {
    const selectAllState = useMemo(() => {
        if (selectedCreatives.length === 0) return FileBrowserSelectState.Unchecked;

        if (selectedCreatives.length === visibleCreatives.length)
            return FileBrowserSelectState.Checked;

        return FileBrowserSelectState.Indeterminate;
    }, [selectedCreatives, visibleCreatives]);

    const onSelectAllToggle = useCallback(() => {
        if (selectAllState === FileBrowserSelectState.Unchecked) {
            // select all current available files in browser
            setSelectedCreatives(
                uniqWith((a, b) => a === b, [...selectedCreatives, ...visibleCreatives]),
            );
        } else {
            setSelectedCreatives([]);
        }
    }, [selectAllState, selectedCreatives, setSelectedCreatives, visibleCreatives]);

    return (
        <Checkbox
            onClick={onSelectAllToggle}
            checked={selectAllState === FileBrowserSelectState.Checked}
            indeterminate={selectAllState === FileBrowserSelectState.Indeterminate}
            label=""
            className="creatives-select-all"
            formControlLabelSx={{ mr: 0 }}
        />
    );
};

export default CreativesSelectAll;
