import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import './RichTextEditor.css';

type Props = {
    content: string;
};

const RichTextEditorPreview: React.FC<Props> = ({ content }) => {
    const htmlContentProps = useMemo(() => ({ __html: content }), [content]);
    return (
        <Typography
            component="div"
            variant="caption"
            className="rte-preview"
            dangerouslySetInnerHTML={htmlContentProps}
        />
    );
};

export default RichTextEditorPreview;
