import { useBoolean } from 'react-hanger';
import { useEffect, useRef } from 'react';

/**
 * When you switch from false to true, it'll reset the value to false after a given reset time
 * @param defaultState - The boolean state to start in
 * @param resetTime - the reset time in ms
 */
function useAutoResetBoolean(defaultState: boolean, resetTime: number) {
    const boolState = useBoolean(defaultState);
    const timeoutRef = useRef();

    useEffect(() => {
        clearTimeout(timeoutRef.current);
        if (boolState.value !== defaultState) {
            timeoutRef.current = setTimeout(() => {
                boolState.setValue(defaultState);
            }, resetTime) as any;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boolState.value, defaultState]);

    return boolState;
}

export default useAutoResetBoolean;
