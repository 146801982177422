import { useCallback, useMemo } from 'react';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import { User, UserColorCorrection } from '@uniled/api-sdk';
import { daltonize, RGBColor } from 'daltonize';
import { colorStringToRgbaTuple } from 'c-lib';
import equal from 'fast-deep-equal';
import apiClient from 'c-data/apiClient';
import { useEntityData } from 'c-data';
import to from 'await-to-js';
import useAuthenticatedUser from './useAuthenticatedUser';
import useAPIClientRequest from './useAPIClientRequest';

function useColorCorrection() {
    const { user } = useAuthenticatedUser();
    const userColorCorrection = useMemo(() => user?.color_correction, [user?.color_correction]);
    const daltonizeColor = useCallback(
        (color: string) => {
            if (
                color != null &&
                color?.length > 0 &&
                userColorCorrection != null &&
                [
                    UserColorCorrection.Deuteranope,
                    UserColorCorrection.Protanope,
                    UserColorCorrection.Tritanope,
                ].includes(userColorCorrection)
            ) {
                const newColorParts = colorStringToRgbaTuple(color);

                if (equal(['0', '0', '0'], newColorParts.slice(0, 3))) {
                    // either totally black or couldn't parse so return original colour.
                    return color;
                }

                const rgb = daltonize(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    newColorParts.slice(0, 3).map(p => Number(p)) as RGBColor,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    userColorCorrection as string,
                );
                return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${newColorParts[3] ?? 1})`;
            }

            return color;
        },
        [userColorCorrection],
    );
    const daltonizeTheme = useCallback(
        (theme: Theme) =>
            createTheme({
                ...theme,
                palette: {
                    ...theme.palette,
                    custom: {
                        contentBgColor: daltonizeColor(
                            (theme as ThemeOptions)?.palette.custom?.contentBgColor,
                        ),
                        navigationBgColor: daltonizeColor(
                            (theme as ThemeOptions)?.palette.custom?.navigationBgColor,
                        ),
                    },
                    background: {
                        paper: daltonizeColor(theme.palette?.background?.paper),
                    },
                    primary: {
                        main: daltonizeColor(theme.palette?.primary?.main),
                    },
                    secondary: {
                        main: daltonizeColor(theme.palette?.secondary?.main),
                    },
                    divider: daltonizeColor(theme.palette?.divider),
                    text: {
                        primary: daltonizeColor(theme.palette?.text?.primary),
                        secondary: daltonizeColor(theme.palette?.text?.secondary),
                        disabled: daltonizeColor(theme.palette?.text?.disabled),
                    },
                    error: {
                        main: daltonizeColor(theme.palette?.error?.main),
                    },
                    info: {
                        main: daltonizeColor(theme.palette?.info?.main),
                    },
                    success: {
                        main: daltonizeColor(theme.palette?.success?.main),
                    },
                    warning: {
                        main: daltonizeColor(theme.palette?.warning?.main),
                    },
                },
            } as ThemeOptions),
        [daltonizeColor],
    );

    const { start, isLoading, hasFailed, hasSucceeded, error, reset } = useAPIClientRequest(
        apiClient.Entities.User.updateMe,
    );
    const { upsertEntity } = useEntityData<User>('User');
    const switchColorCorrection = useCallback(
        async (correction: UserColorCorrection) => {
            if (userColorCorrection === correction || !user) return;

            const [err, success] = await to(
                start({ id: user.id, color_correction: correction } as any),
            );

            if (!err && success?.data?.data?.id === user.id) {
                upsertEntity(success.data.data);
            }
        },
        [start, upsertEntity, user, userColorCorrection],
    );

    return useMemo(
        () => ({
            color_correction: userColorCorrection,
            daltonizeColor,
            daltonizeTheme,
            isSwitching: isLoading,
            hasFailedSwitch: hasFailed,
            hasSucceededSwitch: hasSucceeded,
            failedSwitchError: String(error),
            resetSwitch: reset,
            switchColorCorrection,
        }),
        [
            userColorCorrection,
            daltonizeColor,
            daltonizeTheme,
            isLoading,
            hasFailed,
            hasSucceeded,
            error,
            reset,
            switchColorCorrection,
        ],
    );
}

export default useColorCorrection;
