import React, { useCallback } from 'react';
import cx from 'classnames';
import { useBoolean } from 'react-hanger';
import { Accordion, AccordionSummary, Box, Chip, Typography } from '@mui/material';
import { HighlightWord } from 'c-components';
import { useTimeout } from 'c-hooks';
import { makeStyles } from '@mui/styles';
import { SystemSearchResultProps, SystemSearchSelectedItemGrid } from '../../types';
import { SystemSearchResultBreadcrumbs } from '..';

const useStyles = makeStyles(theme => ({
    item: {
        transition: 'background-color 300ms',
        borderBottom: `2px solid ${theme.palette.grey['500']}`,
    },
    active: {
        backgroundColor: theme.palette.grey['300'],
    },
    inactive: {
        backgroundColor: theme.palette.grey['100'],
    },
    cursorNormal: {
        cursor: 'default!important',
    },
    expandIcon: {
        transition: 'all .2s ease-in',
        backgroundColor: 'rgba(180,180,180,0)',
        width: '30px',
        height: '30px',
        borderRadius: '90px',
        textAlign: 'center',
        lineHeight: '35px',
        fontSize: '30px',
        cursor: 'pointer',
        margin: 'auto 0 -10px auto',
        '&:hover': {
            backgroundColor: 'rgba(180,180,180,1)',
        },
    },
}));

const SystemSearchResult = ({
    title,
    description,
    Content,
    contentTimeout = 0,
    tag,
    highlighted,
    breadCrumbs,
    searchTerm,
    pageIcon,
    onClick,
    index,
    setHighlighted,
}: SystemSearchResultProps & {
    searchTerm: string;
    onClick: (index: number) => void;
    index: number;
    setHighlighted: (selected: SystemSearchSelectedItemGrid) => void;
}) => {
    const { item, active, inactive, cursorNormal } = useStyles();
    const accordionOpen = useBoolean(false);
    const showContent = useBoolean(contentTimeout <= 0);
    useTimeout(showContent.setTrue, contentTimeout);

    // const prevHighlighted = usePrevious(highlighted);
    // useEffect(() => {
    //     if (prevHighlighted !== highlighted) {
    //         accordionOpen.setValue(highlighted);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [prevHighlighted, highlighted]);

    const onResultClick = useCallback(() => {
        onClick(index);
    }, [onClick, index]);

    const onHighlighted = useCallback(
        (e: React.SyntheticEvent) => {
            e?.stopPropagation();
            setHighlighted({ y: index, x: 0 });
        },
        [setHighlighted, index],
    );

    return (
        <Accordion
            className={cursorNormal}
            square
            expanded={accordionOpen.value}
            onChange={() => {}}
            onClick={onResultClick}
        >
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={cx(
                    item,
                    { [active]: highlighted, [inactive]: !highlighted },
                    cursorNormal,
                )}
            >
                {pageIcon && (
                    <Box display="flex" alignItems="center" pr={1} sx={{ fontSize: '2em' }}>
                        {pageIcon}
                    </Box>
                )}
                <Box display="flex" flexDirection="column">
                    <Box>
                        <Typography>
                            <HighlightWord text={title} searchTerm={searchTerm} />{' '}
                        </Typography>
                    </Box>
                    {description && (
                        <Box mt={1.2}>
                            {/* <HighlightWord text={description} searchTerm={searchTerm} /> */}
                            <Typography>{description}</Typography>
                        </Box>
                    )}
                    {showContent.value && Content && (
                        <Content highlighted={highlighted} highlight={onHighlighted} />
                    )}
                    {breadCrumbs && (
                        <Box mt="auto">
                            <Typography component="span">
                                <SystemSearchResultBreadcrumbs
                                    crumbs={breadCrumbs}
                                    searchTerm={searchTerm}
                                />
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box ml="auto" display="flex" flexDirection="column">
                    <Chip label={tag} color="primary" size="small" />
                    {/* <Box className={expandIcon} onClick={() => accordionOpen.toggle()}> */}
                    {/*    {accordionOpen.value ? <ExpandLess /> : <ExpandMore />} */}
                    {/* </Box> */}
                </Box>
            </AccordionSummary>
            {/* <AccordionDetails>{content}</AccordionDetails> */}
        </Accordion>
    );
};

export default SystemSearchResult;
