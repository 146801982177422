import React, { forwardRef, Ref, useMemo } from 'react';
import { Box, InputLabel, TextFieldProps, Typography } from '@mui/material';
import { TextField } from '../TextField';

const getCharacterCount = (value: any) => {
    if (value) {
        const parsedValue = `${value}`;
        const trimmed = parsedValue.trim();

        return trimmed.length === 0 ? 0 : parsedValue.length;
    }

    return 0;
};

const TextAreaField = (
    {
        label: inputLabel,
        showCharacterCount = true,
        ...props
    }: TextFieldProps & { label?: string; showCharacterCount?: boolean },
    ref: Ref<any>,
) => {
    const characterCount = useMemo(() => getCharacterCount(props.value), [props.value]);

    return (
        <Box>
            <Box mb={1}>
                <InputLabel htmlFor={props.id}>{inputLabel}</InputLabel>
            </Box>
            <TextField multiline variant="outlined" rows={4} {...props} ref={ref} />
            {showCharacterCount && characterCount > 0 && (
                <Box textAlign="right" pt={1}>
                    <Typography id={`${props.id}-character-count`} variant="caption">
                        {characterCount}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default forwardRef(TextAreaField);
