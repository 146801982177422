import React, { Ref, useMemo } from 'react';
import merge from 'deepmerge';
import { Table as MuiTable, TableProps as MuiTableProps } from '@mui/material';
import { TableContextValues, TableContext } from './TableContext';

export type TableProps = MuiTableProps & Partial<TableContextValues>;

const spacedRowsTableSx = {
    whiteSpace: 'nowrap',
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
    px: 1,
};

const Table = (
    { spacedOutRows = false, sx, children, ...tableProps }: TableProps,
    ref: Ref<any>,
) => {
    const tableContextVals = useMemo<TableContextValues>(
        () => ({ spacedOutRows }),
        [spacedOutRows],
    );

    const actualSx = useMemo(
        () =>
            (spacedOutRows
                ? (merge(spacedRowsTableSx, sx ?? ({} as any)) as TableProps['sx'])
                : sx) as TableProps['sx'],
        [sx, spacedOutRows],
    );

    return (
        <TableContext.Provider value={tableContextVals}>
            <MuiTable {...tableProps} sx={actualSx} ref={ref}>
                {children}
            </MuiTable>
        </TableContext.Provider>
    );
};

export default React.forwardRef(Table);
