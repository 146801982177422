import { useParams } from 'react-router-dom';
import { useContext, useMemo } from 'react';
import { BaseEntity } from '@uniled/api-sdk';
import { useEntityData } from 'c-data';
import { UseEntityPageContext } from 'c-wrapper/Components/PostAuth/Context';
import { PageEntityConfig } from 'c-config';

function useEntityPage<Entity extends BaseEntity = BaseEntity>() {
    const { id } = useParams<{ id: string }>();
    const { entityName, pageConfig } = useContext(UseEntityPageContext);
    const { getById } = useEntityData<Entity>(entityName);

    return useMemo(
        () => ({
            id: +id,
            entity: getById({ id: id as any }),
            pageConfig: pageConfig as PageEntityConfig<Entity>,
        }),
        [id, getById, pageConfig],
    );
}

export default useEntityPage;
