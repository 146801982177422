import { BuyerCost } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { BuyerCostState } from './BuyerCost-types';

const initialState: BuyerCostState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: BuyerCostState, action: PayloadAction<string>): BuyerCostState => state,
};

const slice = createBaseSlice<BuyerCost, BuyerCostState, typeof additionalReducers>({
    name: 'buyerCost',
    entityName: 'BuyerCost',
    initialState,
    reducers: additionalReducers,
});

const buyerCostSlice = {
    buyerCostActions: slice.slice.actions,
    buyerCostReducer: slice.slice.reducer,
    buyerCostAdapter: slice.adapter,
    buyerCostSelectors: slice.selectors,
    buyerCostThunks: slice.thunks,
};

export default buyerCostSlice;
export const {
    buyerCostReducer,
    buyerCostActions,
    buyerCostAdapter,
    buyerCostSelectors,
    buyerCostThunks,
} = buyerCostSlice;
