import React, { useCallback, useMemo } from 'react';
import { Box, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { Cancel, Check } from '@mui/icons-material';
import { css } from '@emotion/react';
import { Controller } from 'react-hook-form';
import { Alert, FormWrapper, TextField } from 'c-components';
import { FormWrapperProps } from 'c-components/Forms/FormWrapper/FormWrapper';
import { ScheduleFormData } from 'c-main/Components/Schedule/types';
import { emptyFormDataRule } from 'c-main/Components/Schedule/templates';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import {
    formRuleDataToRule,
    scheduleName,
    scheduleRuleToFormRuleData,
} from 'c-main/Components/Schedule/lib';
import to from 'await-to-js';
import { useHotkeys } from 'react-hotkeys-hook';
import { Campaign, Schedule, ScheduleGet } from '@uniled/api-sdk';
import { useCommonTranslation } from 'c-translation';
import { useEntityData } from 'c-data';
import { isoDateStringNoTimeDiff } from 'c-lib';
import { DateRangePickerProps } from '@mui/x-date-pickers-pro';
import RuleFormList from './RuleFormList';

type FormProps = {
    onClose: () => void;
    isLoading: boolean;
    error?: string;

    hideDates?: boolean;
    dateRangeSlotProps: DateRangePickerProps<any>['slotProps'];
};
const ScheduleForm: React.FC<FormProps> = ({
    onClose,
    isLoading,
    error,
    dateRangeSlotProps,
    hideDates,
}) => {
    const t = useCommonTranslation();
    return (
        <Box display="flex" overflow="hidden" flex={1} pt={2} flexDirection="column">
            <Stack gap={2} flex={1} overflow="auto">
                <Controller
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={t(
                                'Modules.Main.Campaigns.CampaignSchedule.Form.scheduleNameLabel',
                            )}
                            variant="standard"
                            size="small"
                        />
                    )}
                    name="name"
                />
                <RuleFormList dateRangeSlotProps={dateRangeSlotProps} hideDates={hideDates} />
            </Stack>
            <Box mt="auto" minHeight={40}>
                {error && (
                    <Box mt={2}>
                        <Alert variant="outlined" severity="error">
                            {String(error)}
                        </Alert>
                    </Box>
                )}
                <Box ml="auto" textAlign="right">
                    <IconButton color="error" onClick={onClose} disabled={isLoading}>
                        <Cancel />
                    </IconButton>
                    <IconButton color="primary" type="submit" disabled={isLoading}>
                        {isLoading ? <CircularProgress size={20} /> : <Check />}
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

type Props = {
    onClose: () => void;
    campaignId?: number;
    schedule?: ScheduleGet;
    updateSchedules: (schedules: ScheduleGet[] | Schedule) => void;
    hideDates?: boolean;
    template?: boolean;
};

const ScheduleFormWrapper: React.FC<Props> = ({
    campaignId,
    onClose,
    schedule,
    updateSchedules,
    hideDates = false,
    template = false,
}) => {
    useHotkeys('escape', onClose, [onClose]);

    const {
        start: createSchedule,
        isLoading: isCreating,
        error: createError,
    } = useAPIClientRequest(apiClient.Entities.Schedule.create);
    const {
        start: updateSchedule,
        isLoading: isUpdating,
        error: updateError,
    } = useAPIClientRequest(apiClient.Entities.Schedule.update);

    const formatTo10Dp = (data: ScheduleFormData) => ({
        ...data,
        rules: data.rules.map(rule => ({
            ...rule,
            sov: rule.sov ? parseFloat(rule.sov).toFixed(10) : null,
        })),
    });

    const onCreate = useCallback(
        async (data: ScheduleFormData) => {
            const scheduleFormData = formatTo10Dp(data);
            const [err, succ] = await to(
                createSchedule({
                    campaign_id: campaignId,
                    name: scheduleFormData.name ?? '',
                    rules: scheduleFormData.rules.map(rule => formRuleDataToRule(rule)),
                    includes: ['schedulables', 'rules'],
                    template,
                }),
            );

            if (!err && succ?.data?.data?.id != null) {
                updateSchedules([succ.data.data]);
                onClose();
            }
        },
        [createSchedule, updateSchedules, campaignId, onClose, template],
    );
    const onUpdate = useCallback(
        async (data: ScheduleFormData) => {
            const scheduleFormData = formatTo10Dp(data);
            const [err, succ] = await to(
                updateSchedule({
                    schedule_id: schedule?.id,
                    campaign_id: campaignId,
                    name: scheduleFormData.name ?? '',
                    rules: scheduleFormData.rules.map(rule => formRuleDataToRule(rule)),
                    includes: ['schedulables', 'rules'],
                }),
            );

            if (!err && succ?.data?.data?.id != null) {
                updateSchedules([succ.data.data]);
                onClose();
            }
        },
        [updateSchedule, schedule?.id, campaignId, updateSchedules, onClose],
    );

    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = useMemo(() => getById({ id: campaignId }), [getById, campaignId]);
    const formOpts = useMemo<FormWrapperProps<ScheduleFormData>['formOptions']>(
        () => ({
            defaultValues: {
                name: schedule?.name ?? '',
                rules: schedule
                    ? schedule.rules.map(rule => ({
                          ...scheduleRuleToFormRuleData(rule),
                          id: rule.id ?? undefined,
                      }))
                    : [emptyFormDataRule()],
                // campaign_start: campaign?.date?.date_start,
                // campaign_end: campaign?.date?.date_end,
            },
        }),
        [schedule],
    );
    const t = useCommonTranslation();
    const header = useMemo(
        () => (
            <Typography variant="subtitle2" color="primary">
                {!schedule
                    ? t('Modules.Main.Campaigns.CampaignSchedule.Form.newSchedule')
                    : t('Modules.Main.Campaigns.CampaignSchedule.Form.updateSchedule', {
                          name: scheduleName(schedule, t),
                      })}
            </Typography>
        ),
        [schedule, t],
    );

    const isLoading = useMemo(
        () => (schedule ? isUpdating : isCreating),
        [schedule, isUpdating, isCreating],
    );

    const error = useMemo(
        () => (schedule ? updateError : createError),
        [schedule, updateError, createError],
    );

    const dateRangeSlotProps = useMemo<DateRangePickerProps<Date>['slotProps']>(
        () => ({
            shortcuts: {
                items: [
                    {
                        label: t(
                            'Modules.Main.Campaigns.CampaignSchedule.Form.campaignDurationShortcut',
                        ),
                        getValue: () => [
                            new Date(isoDateStringNoTimeDiff(campaign?.date?.date_start)),
                            new Date(isoDateStringNoTimeDiff(campaign?.date?.date_end)),
                        ],
                    },
                ],
            },
        }),
        [campaign, t],
    );

    const formComponentChildren = useMemo(
        () => (
            <Box flex={1} display="flex" flexDirection="column" overflow="hidden" width="100%">
                {header}
                <ScheduleForm
                    onClose={onClose}
                    isLoading={isLoading}
                    error={error}
                    dateRangeSlotProps={dateRangeSlotProps}
                    hideDates={hideDates}
                />
            </Box>
        ),
        [header, onClose, isLoading, error, dateRangeSlotProps, hideDates],
    );

    const onSubmit = useMemo(() => {
        if (schedule == null || schedule != null) return onCreate;

        return onUpdate;
    }, [schedule, onCreate, onUpdate]);

    return (
        <Box flex={1} display="flex" overflow="hidden">
            <FormWrapper<ScheduleFormData>
                onSubmit={onSubmit}
                formOptions={formOpts}
                formProps={useMemo<any>(
                    () => ({ css: css('width:100%;display:flex;position:relative;') }),
                    [],
                )}
            >
                {formComponentChildren}
            </FormWrapper>
        </Box>
    );
};

export default ScheduleFormWrapper;
