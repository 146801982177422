import React, { useMemo } from 'react';
import { NetworkRequest } from '@uniled/data-layer';
import AutoGrid from 'c-components/AutoGrid';
import { Box, Typography } from '@mui/material';

type Props = {
    validationErrors?: NetworkRequest['validation'];
};

const ValidationErrors: React.FC<Props> = ({ validationErrors }) => {
    const errors = useMemo(
        () =>
            Object.entries(validationErrors ?? {}).reduce((acc, [field, errs]) => {
                // in case a single string set at the errors
                const fieldErrors = Array.isArray(errs) ? errs : [errs];
                acc.push(
                    <AutoGrid spacing={2} xs={12}>
                        <Box>
                            <Typography variant="subtitle1">{field}</Typography>
                            <Typography variant="caption">{fieldErrors?.join(', ')}</Typography>
                        </Box>
                    </AutoGrid>,
                );
                return acc;
            }, [] as React.ReactNode[]),
        [validationErrors],
    );

    if (validationErrors == null || Object.keys(validationErrors ?? {}).length === 0) {
        return null;
    }

    return (
        <>
            <AutoGrid spacing={2} xs={12}>
                {errors}
            </AutoGrid>
        </>
    );
};

export default ValidationErrors;
