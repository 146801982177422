import React, { useCallback, useMemo, useState } from 'react';
import { DisplayMediaBreakdownData, PopDisplays_ChannelFrameMediaSummary } from '@uniled/api-sdk';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { useCommonTranslation } from 'c-translation';
import { Button, DialogV2 } from 'c-components';
import { LogFileParsedDaily } from 'c-pop-displays/Components/LogFileParsed';
import { ReportConfig, ReportDateTimeframe } from 'c-reports/Types';
import { apiClient } from 'c-data';
import { format } from 'date-fns';
import { useDateUtils } from 'c-hooks';

type Props = {
    channelFrameId: number;
    mediaSummaries: PopDisplays_ChannelFrameMediaSummary[];
};

const MediaSummaryBreakdownTable: React.FC<Props> = ({ channelFrameId, mediaSummaries }) => {
    // data grid requires rows with unique ids
    const rowsWithId = useMemo(
        () =>
            mediaSummaries.map((summary, index) => ({
                ...summary,
                id: `${summary.media_name}-${index}`,
            })),
        [mediaSummaries],
    );

    const t = useCommonTranslation();
    const [showPlaybackSummaryMediaId, setShowPlaybackSummaryMediaId] = useState<string>(null);
    const closeDialog = useCallback(() => {
        setShowPlaybackSummaryMediaId(null);
    }, []);

    const dialogTitle = useMemo(
        () =>
            t('Modules.PopDisplays.ChannelFrameMediaSummary.table.playbackSummaryTitle', {
                name:
                    rowsWithId.find(row => row.id === showPlaybackSummaryMediaId)?.media_name ?? '',
            }),
        [t, showPlaybackSummaryMediaId, rowsWithId],
    );
    const columns = useMemo<DataGridProProps['columns']>(
        () => [
            {
                field: 'media_name',
                headerName: t('Modules.PopDisplays.ChannelFrameMediaSummary.table.mediaCol'),
                sortable: true,
                flex: 1,
            },
            {
                field: 'duration',
                headerName: t('Modules.PopDisplays.ChannelFrameMediaSummary.table.durationCol'),
                type: 'number',
                sortable: true,
            },
            {
                field: 'count',
                headerName: t('Modules.PopDisplays.ChannelFrameMediaSummary.table.countCol'),
                type: 'number',
                sortable: true,
            },
            {
                field: 'min_date',
                headerName: t('Modules.PopDisplays.ChannelFrameMediaSummary.table.minDateCol'),
                minWidth: 180,
            },
            {
                field: 'max_date',
                headerName: t('Modules.PopDisplays.ChannelFrameMediaSummary.table.maxDateCol'),
                minWidth: 180,
            },
            {
                field: 'id',
                // headerName: t('Modules.PopDisplays.ChannelFrameMediaSummary.table.playbackSummaryBtn'),
                minWidth: 180,
                renderCell: params => (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setShowPlaybackSummaryMediaId(String(params.id))}
                    >
                        {t('Modules.PopDisplays.ChannelFrameMediaSummary.table.playbackSummaryBtn')}
                    </Button>
                ),
            },
        ],
        [t],
    );

    const { parseApiDayMonthYearHourMinuteSecondFormat } = useDateUtils();
    const selectedMedia = useMemo(
        () => rowsWithId.find(sum => sum.id === showPlaybackSummaryMediaId),
        [rowsWithId, showPlaybackSummaryMediaId],
    );
    const minDate = useMemo(
        () =>
            selectedMedia
                ? parseApiDayMonthYearHourMinuteSecondFormat(selectedMedia.min_date)
                : new Date(),
        [parseApiDayMonthYearHourMinuteSecondFormat, selectedMedia],
    );
    const maxDate = useMemo(
        () =>
            selectedMedia
                ? parseApiDayMonthYearHourMinuteSecondFormat(selectedMedia.max_date)
                : new Date(),
        [parseApiDayMonthYearHourMinuteSecondFormat, selectedMedia],
    );

    const fetchData: ReportConfig<{ data: DisplayMediaBreakdownData[] }>['fetchData'] = useCallback(
        (start, end, timeframe) => {
            if (timeframe === ReportDateTimeframe.All) {
                apiClient.PopDisplays.channelFrameMediaBreakdown(channelFrameId, {
                    perPage: 9999999,
                    media_items: [selectedMedia?.media_name],
                });
            }
            return apiClient.PopDisplays.channelFrameMediaBreakdown(channelFrameId, {
                start_date: start ? `${format(start, 'yyyy-MM-dd')} 00:00:00` : undefined,
                end_date: end ? `${format(end, 'yyyy-MM-dd')} 23:59:59` : undefined,
                perPage: 9999999,
                media_items: [selectedMedia?.media_name],
            });
        },
        [channelFrameId, selectedMedia?.media_name],
    );

    return (
        <>
            <DialogV2
                maxWidth="xl"
                onClose={closeDialog}
                open={showPlaybackSummaryMediaId != null}
                title={dialogTitle}
                titleVariant="h4"
            >
                {selectedMedia && (
                    <LogFileParsedDaily
                        minDate={minDate}
                        maxDate={maxDate}
                        chartName={`${selectedMedia.id}-daily-totals`}
                        fetchData={fetchData}
                        defaultGroupByMedia={false}
                        showGroupByCheckboxes={false}
                    />
                )}
            </DialogV2>
            <DataGridPro
                disableRowSelectionOnClick
                disableColumnReorder
                disableColumnSelector
                disableColumnPinning
                hideFooter
                columns={columns}
                rows={rowsWithId}
            />
        </>
    );
};

export default MediaSummaryBreakdownTable;
