import {
    displays_CountryActions,
    displays_CountryAdapter,
    displays_CountryThunks as defaultThunks,
    displays_CountrySelectors as defaultSelectors,
    displays_CountryReducer,
} from './Displays_Country-slice';
import * as thunks from './Displays_Country-thunks';
import * as selectors from './Displays_Country-selectors';

const displays_CountryThunks = { ...defaultThunks, ...thunks };
const displays_CountrySelectors = { ...defaultSelectors, ...selectors };

export { default as Displays_CountrySlice } from './Displays_Country-slice';
export {
    displays_CountrySelectors,
    displays_CountryActions,
    displays_CountryAdapter,
    displays_CountryThunks,
    displays_CountryReducer,
};
