import React, { useMemo } from 'react';
import { PCAFrameDetails, PCAReportField, PCAReportMetric } from '@uniled/api-sdk';
import { useCommonTranslation } from 'c-translation';
import { Box } from '@mui/material';
import FrameTable from './FrameTable';

type Props = {
    field: string;
    frames: PCAFrameDetails[];
    onSelect: (frameId: string) => void;
    metric: PCAReportMetric;
};

const FilterableFrameList: React.FC<Props> = ({ field, frames, onSelect, metric }) => {
    const t = useCommonTranslation();
    const usableFields = useMemo(
        () =>
            [
                // PCAReportField.Town,
                PCAReportField.TvRegion,
                // PCAReportField.Conurbation,
                PCAReportField.Environment,
                PCAReportField.Format,
                PCAReportField.MediaOwner,
                PCAReportField.LineItemName,
                // PCAReportField.SurfaceArea,
                // PCAReportField.Resolution,
                PCAReportField.FrameId,
            ].filter(f => f !== field),
        [field],
    );
    const groupHeaders = useMemo(
        () =>
            usableFields.reduce((acc, field) => {
                acc[field] = t(`Modules.Main.Campaigns.Overview.Reports.fields.${field}`);
                return acc;
            }, {} as Record<PCAReportField, string>),
        [t, usableFields],
    );

    return (
        <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
            <Box flex={1} overflow="hidden" display="flex">
                <FrameTable
                    frames={frames}
                    columns={usableFields}
                    columnHeaders={groupHeaders}
                    onSelect={onSelect}
                    metric={metric}
                />
            </Box>
        </Box>
    );
};

export default FilterableFrameList;
