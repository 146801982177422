import React, { Ref, useCallback, useMemo } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker as ReactDateRangePicker, DateRangeProps, Range } from 'react-date-range';
import { Box } from '@mui/material';
import { css } from '@emotion/react';
import { useDateUtils } from 'c-hooks';

export type DateRangePickerProps = Omit<DateRangeProps, 'onChange'> & {
    onChange: (start: Date, end: Date) => void;
    startDate?: Date;
    endDate?: Date;
};

const empty = [];
const DateRangePicker = (
    { onChange, startDate, endDate, ...props }: DateRangePickerProps,
    ref: Ref<any>,
) => {
    const { dayMonthYearFormat } = useDateUtils();
    const onSelectionChange = useCallback<DateRangeProps['onChange']>(
        ({ selection, ...rest }) => {
            // if (selection?.startDate && selection?.endDate) {
            onChange(selection?.startDate, selection?.endDate);
            // }
        },
        [onChange],
    );

    const ranges = useMemo<Range[]>(
        () => [{ key: 'selection', startDate, endDate }],
        [startDate, endDate],
    );
    /**
     * Hide the helper selection menu (left side) of the picker
     * https://github.com/hypeserver/react-date-range/issues/362
     */
    return (
        <Box css={css({ '.rdrDefinedRangesWrapper': { display: 'none' } })}>
            <ReactDateRangePicker
                key={dayMonthYearFormat}
                ref={ref}
                onChange={onSelectionChange}
                weekStartsOn={1}
                dateDisplayFormat={dayMonthYearFormat}
                // showSelectionPreview
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={ranges}
                staticRanges={empty}
                // specialDays={[]}
                inputRanges={empty}
                direction="horizontal"
                {...props}
            />
        </Box>
    );
};

export default React.forwardRef(DateRangePicker);
