import React, { PropsWithChildren, useMemo } from 'react';
import { TranslationPath as CommonTranslationPath, useCommonTranslation } from 'c-translation';
import {
    Box,
    BoxProps,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentProps as MuiDialogContentProps,
    DialogContentText,
    DialogProps as MuiDialogProps,
    DialogTitle,
    DialogTitleProps,
    Divider,
    IconButton,
    Stack,
    Typography,
    TypographyProps,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import merge from 'deepmerge';

type Props = PropsWithChildren<{
    title?: CommonTranslationPath | React.ReactNode;
    titleVariant?: TypographyProps['variant'];
    description?: CommonTranslationPath | React.ReactNode;
    titleDivider?: boolean;

    titleSx?: DialogTitleProps['sx'];

    onClose: (e?: React.MouseEvent | React.KeyboardEvent) => void;
    open: boolean;
    maxWidth?: MuiDialogProps['maxWidth'];
    scroll?: MuiDialogProps['scroll'];

    MuiDialogProps?: Partial<MuiDialogProps>;
    MuiDialogContentProps?: Partial<MuiDialogContentProps>;

    contentHeight?: BoxProps['height'];

    actions?: React.ReactNode;
}>;

const PaperProps = { sx: { borderRadius: 2.5 } };
const dialogTitleSx = { position: 'relative', wordBreak: 'break-all' };
const actionsSx = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pt: 2,
    pb: 5,
    px: 5,
};
const descriptionSx = { textAlign: 'center', whiteSpace: 'pre-wrap' };

const DialogV2: React.FC<Props> = ({
    MuiDialogProps,
    MuiDialogContentProps,
    titleSx,
    contentHeight,
    onClose,
    open,
    maxWidth = 'sm',
    scroll = 'paper',
    title,
    titleVariant = 'h3',
    description,
    titleDivider = false,
    actions,
    children,
}) => {
    const t = useCommonTranslation();
    const titleContent = typeof title === 'string' ? t(title) : title;
    const descriptionContent = typeof description === 'string' ? t(description) : description;
    const mergedContentSx = useMemo(
        () =>
            merge(
                { display: 'flex', height: contentHeight },
                (MuiDialogContentProps?.sx ?? {}) as any,
            ),
        [MuiDialogContentProps, contentHeight],
    );
    const mergedTitleSx = useMemo<DialogTitleProps['sx']>(
        () => merge(dialogTitleSx, (titleSx ?? {}) as any),
        [titleSx],
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className="open-modal"
            maxWidth={maxWidth}
            fullWidth
            scroll={scroll}
            PaperProps={PaperProps}
            {...MuiDialogProps}
        >
            <DialogTitle sx={mergedTitleSx}>
                <Box position="absolute" right={0} top={0}>
                    <IconButton onClick={onClose}>
                        <Close fontSize="inherit" />
                    </IconButton>
                </Box>
                {titleContent && (
                    <Box mt={1}>
                        <Typography variant={titleVariant} textAlign="center">
                            {titleContent}
                        </Typography>
                    </Box>
                )}
            </DialogTitle>
            {titleDivider && <Divider />}

            <DialogContent {...MuiDialogContentProps} sx={mergedContentSx}>
                <Stack spacing={2} flex={1} maxWidth="100%">
                    {descriptionContent && (
                        <DialogContentText sx={descriptionSx}>
                            {descriptionContent}
                        </DialogContentText>
                    )}
                    {children}
                </Stack>
            </DialogContent>
            {actions && <DialogActions sx={actionsSx}>{actions}</DialogActions>}
        </Dialog>
    );
};

export default DialogV2;
