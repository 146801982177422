import { getISODay, addDays } from 'date-fns';

/**
 *
 * @param dayOfWeek - 1 is monday, 7 is sunday
 * @param fromDate
 */
function getClosestDayOfCurrentWeek(dayOfWeek: number, fromDate = new Date()) {
    // see tests for proof
    // -7 means last week
    // dayOfWeekMap[dayOfWeek] get the ISODay for the desired dayOfWeek

    // e.g. If today is Sunday, getISODay(fromDate) will returns 7
    // if the day we want to find is Thursday(4), apart from subtracting one week(-7),
    // we also need to account for the days between Sunday(7) and Thursday(4)
    // Hence we need to also subtract (getISODay(fromDate) - dayOfWeekMap[dayOfWeek])
    const fromIsoDay = getISODay(fromDate);
    const offsetDays = -7 - (fromIsoDay - dayOfWeek) + (dayOfWeek > fromIsoDay ? 0 : 7);

    return addDays(fromDate, offsetDays);
}

export default getClosestDayOfCurrentWeek;
