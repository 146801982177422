import React, { PropsWithChildren } from 'react';

export enum FileBrowserOrderBy {
    Name,
    UploadDate,
    UpdatedAt,
    LiveDate,
    EndDate,
    AspectRatio,
    FileSize,
    AssignedDisplays,
}

export type FileBrowserOrderByDirection = 'asc' | 'desc';

export type FileBrowserOrderState = {
    direction: FileBrowserOrderByDirection;
    column: FileBrowserOrderBy;
};

export type FileBrowserFile = {
    downloadId: number;
    id: number;
    name: string;

    // date string
    createdAt: string;
    createdAtDate: Date;
    createdBy?: number; // user id
    updatedAt?: string;
    updatedAtDate?: Date;

    liveDate?: string;
    endDate?: string;

    liveDateDate?: Date;
    endDateDate?: Date;

    durationMs: number;
    height: number;
    width: number;
    aspectRatio: string;

    // divide the right number by the left number
    aspectRatioNormalised: number;

    mimeType: string;

    /**
     * uniledportal.file.image_type
     *
     * select image_type , count(id) as count from file group by image_type;
     *
     *
     */
    fileType: 'jpeg' | 'png' | 'video' | '';
    fileTypeSimple: string;

    // in bytes
    size: number;

    assignedDisplays: number;

    groupIds: number[];

    urls?: {
        raw?: string;
        download?: string;

        small?: string;
        medium?: string;
        large?: string;

        carousel?: string;
        partner?: string;
        poster?: string;
        full?: string;
        'pdf-thumbnail'?: string;
        'video-thumbnail'?: string;
    };

    tags: {
        resolution: string;
        fileType: string;
        aspectRatio: string;
    };
};

export enum FileIconType {
    File,
    Image,
    Video,
    PDF,
}

/**
 * The value of each tag is the file ids
 */
export type FileTagsGroups = {
    fileType: Record<string, number[]>;
    resolution: Record<string, number[]>;
    aspectRatios: Record<string, number[]>;
};

export type FileFilterStats = {
    total: number;
    shown: number;
    hidden: number;
};

export enum FileBrowserSelectState {
    Unchecked,
    Indeterminate,
    Checked,
}

export type ViewOptionProps = {
    files: FileBrowserFile[];
    focusedFile?: FileBrowserFile;
    onFileClick: (fileId: number) => void;
    ListWrapper: React.FC<PropsWithChildren>;
    filters: FileBrowserLocalSearchFormData;
    onFileToggled: (file: FileBrowserFile, e: React.MouseEvent) => void;

    selectedFiles: FileBrowserFile[];
};

export type FileBrowserLocalSearchFormData = {
    term: string;
    resolutions: string[];
    fileTypes: string[];
    aspectRatios: string[];
};
