import React, { useCallback, useMemo } from 'react';
import { FileBrowserFile } from './types';
import { useCommonTranslation } from 'c-translation';
import { IconButton, Tooltip } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';

type Props = {
    file: FileBrowserFile;
};

const OpenFileNewTab: React.FC<Props> = ({ file }) => {
    const downloadable = useMemo(() => file?.urls?.raw, [file]);
    const openInTabLabel = useCommonTranslation('FileBrowser.filePreview.openInTab');

    const onClick = useCallback(e => {
        e.stopPropagation();
    }, []);

    return useMemo(
        () => (
            <>
                {downloadable && (
                    <Tooltip title={openInTabLabel}>
                        <IconButton target="_blank" href={file.urls.raw} onClick={onClick}>
                            <OpenInNew />
                        </IconButton>
                    </Tooltip>
                )}
            </>
        ),
        [downloadable, file?.urls?.raw, openInTabLabel],
    );
};

export default OpenFileNewTab;
