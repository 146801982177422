import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { NetworkRequestState } from '@uniled/data-layer';
import { ContentLayout } from 'c-wrapper/Components';
import {
    Button,
    ControlledFormInput,
    FlashMessage,
    TextField,
    ValidationErrors,
} from 'c-components';
import { TranslationPath, useCommonTranslation } from 'c-translation';

import { PasswordStrengthErrors } from '../../Types';
import PasswordStrength from '../PasswordStrength';
import { passwordStrength } from '../../Lib';
import { passwordResetSelectors, passwordResetThunks } from '../../Slices/PasswordReset';

type FormValues = {
    password_reset_token?: string;
    email?: string;
    password?: string;
    password_confirmation?: string;
};

const schema = (emailError: string) =>
    yup.object({
        password_reset_token: yup.string().required(),
        email: yup.string().required().email(emailError),
        // password: yup.string().test(passwordValidation),
        // password_confirmation: yup.string().test(passwordValidation),
    });

type Props = {
    submitLabel?: TranslationPath;
};

const PasswordResetCodeForm: React.FC<Props> = ({ submitLabel }) => {
    const invalidEmail = useCommonTranslation('Pages.ForgotPasswordCode.invalidEmail');
    const { control, handleSubmit, formState, setValue } = useForm<FormValues>({
        resolver: yupResolver(schema(invalidEmail)) as any,
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const dispatch = useDispatch();
    const resetState = useSelector(passwordResetSelectors.passwordRequestResetPasswordState);
    const resetError = useSelector(passwordResetSelectors.passwordResetPasswordRequestError);
    const resetValidation = useSelector(
        passwordResetSelectors.passwordResetPasswordRequestValidation,
    );
    const resetErrorTranslated = useCommonTranslation(resetError as TranslationPath);

    const [passwordError, setPasswordError] = useState('');

    const [password, passwordConfirmation] = useWatch<FormValues>({
        control,
        name: ['password', 'password_confirmation'],
    });
    useWatch({ control });

    useEffect(() => {
        setPasswordError(passwordStrength('', '').join(','));
        if (window && typeof URLSearchParams != null) {
            setValue(
                'password_reset_token',
                new URLSearchParams(window.location.search).get('token') ?? '',
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // https://github.com/react-hook-form/react-hook-form/issues/1211
        // have to do it like this instead of normal YUP validation
        // because field errors are cleared one by one. See github issue above.
        // I have commented on it.
        const passwordStr = passwordStrength(password ?? '', passwordConfirmation ?? '').join(',');

        if (passwordStr !== passwordError) {
            setPasswordError(passwordStr);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, passwordConfirmation]);

    const onSubmit = useCallback(
        async formData => {
            await dispatch(passwordResetThunks.requestPasswordReset(formData));
        },
        [dispatch],
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="none">
                <ControlledFormInput
                    defaultValue=""
                    control={control}
                    name="password_reset_token"
                    as={<TextField greyBackdrop required type="text" />}
                />
            </Box>
            <ContentLayout.Form>
                <ContentLayout.FormFields>
                    <ControlledFormInput
                        defaultValue=""
                        control={control}
                        name="email"
                        as={
                            <TextField
                                greyBackdrop
                                label={useCommonTranslation(
                                    'Pages.ForgotPasswordCode.emailInputLabel',
                                )}
                                placeholder={useCommonTranslation(
                                    'Pages.ForgotPasswordCode.emailInputPlaceholder',
                                )}
                                required
                                type="email"
                            />
                        }
                    />
                    <ControlledFormInput
                        defaultValue=""
                        control={control}
                        name="password"
                        as={
                            <TextField
                                greyBackdrop
                                label={useCommonTranslation(
                                    'Pages.ForgotPasswordCode.passwordInputLabel',
                                )}
                                placeholder={useCommonTranslation(
                                    'Pages.ForgotPasswordCode.passwordInputPlaceholder',
                                )}
                                required
                                type="password"
                            />
                        }
                    />
                    <ControlledFormInput
                        defaultValue=""
                        control={control}
                        name="password_confirmation"
                        as={
                            <TextField
                                greyBackdrop
                                label={useCommonTranslation(
                                    'Pages.ForgotPasswordCode.passwordConfirmationInputLabel',
                                )}
                                placeholder={useCommonTranslation(
                                    'Pages.ForgotPasswordCode.passwordConfirmationInputPlaceholder',
                                )}
                                required
                                type="password"
                            />
                        }
                    />
                </ContentLayout.FormFields>
                <PasswordStrength
                    errors={(passwordError.split(',') as PasswordStrengthErrors) ?? []}
                />
                <Box mt="5rem" display="flex" justifyContent="center" flexDirection="column">
                    {resetError && (
                        <Box mb={2}>
                            <FlashMessage
                                status="error"
                                textAlign="center"
                                id="password-reset-error"
                            >
                                {resetErrorTranslated}
                                <ValidationErrors validationErrors={resetValidation} />
                            </FlashMessage>
                        </Box>
                    )}
                    <Box mx="auto">
                        <Button
                            id="password-reset-submit"
                            type="submit"
                            disabled={
                                resetState.state === NetworkRequestState.InProgress ||
                                !formState.isValid ||
                                passwordError.length > 0
                            }
                        >
                            {useCommonTranslation(submitLabel)}
                        </Button>
                    </Box>
                </Box>
            </ContentLayout.Form>
        </form>
    );
};

export default PasswordResetCodeForm;
