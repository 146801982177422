import {
    ownerActions,
    ownerAdapter,
    ownerThunks as defaultThunks,
    ownerSelectors as defaultSelectors,
    ownerReducer,
} from './Owner-slice';
import * as thunks from './Owner-thunks';
import * as selectors from './Owner-selectors';

const ownerThunks = { ...defaultThunks, ...thunks };
const ownerSelectors = { ...defaultSelectors, ...selectors };

export { default as OwnerSlice } from './Owner-slice';
export { ownerSelectors, ownerActions, ownerAdapter, ownerThunks, ownerReducer };
