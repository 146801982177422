import React, { useCallback, useMemo } from 'react';
import { IconButton, TableSortLabel, Typography } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import { ToggleButtonGroup, OptionSchema } from 'c-components/Forms';
import AutoGrid from 'c-components/AutoGrid';
import { FileBrowserOrderState, FileBrowserOrderBy } from './types';

type Props = {
    state: FileBrowserOrderState;
    setState: (state: FileBrowserOrderState) => void;
    prependedControls: React.ReactNode;
};

const FileBrowserOrdering: React.FC<Props> = ({
    state: { direction, column },
    setState,
    prependedControls,
}) => {
    const onClickSort = useCallback(() => {
        setState({ column, direction: direction === 'desc' ? 'asc' : 'desc' });
    }, [column, setState, direction]);

    const onChange = useCallback(
        value => {
            setState({ column: value, direction });
        },
        [setState, direction],
    );

    const t = useCommonTranslation();
    const options = useMemo<OptionSchema[]>(
        () => [
            { label: t('FileBrowser.orderByUpload'), value: FileBrowserOrderBy.UploadDate },
            { label: t('FileBrowser.orderByName'), value: FileBrowserOrderBy.Name },
            { label: t('FileBrowser.orderByAspectRatio'), value: FileBrowserOrderBy.AspectRatio },
        ],
        [t],
    );
    return (
        <>
            <AutoGrid spacing={1} sx={{ alignItems: 'center' }}>
                {prependedControls}
                <Typography variant="body2">
                    {useCommonTranslation('FileBrowser.orderBy')}
                </Typography>
                <ToggleButtonGroup
                    exclusive
                    size="small"
                    options={options}
                    onChange={onChange}
                    value={column}
                />
                <IconButton onClick={onClickSort} size="small">
                    <TableSortLabel active direction={direction} />
                </IconButton>
            </AutoGrid>
        </>
    );
};

export default FileBrowserOrdering;
