import React, { useCallback, useEffect, useMemo } from 'react';
import {
    AutoGrid,
    Button,
    ControlledFormInput,
    OptionSchema,
    RgbaColorPicker,
    TextField,
    ToggleButtonGroup,
} from 'c-components';
import { useFormContext, useWatch } from 'react-hook-form';
import { compose, prop, sortBy } from 'ramda';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FieldPath } from 'react-hook-form/dist/types';
import { useCommonTranslation } from 'c-translation';
import { useBoolean, usePrevious } from 'react-hanger';
import { BarChartFormDataSchema } from '../types';

type Props = {
    //
};

const gridProps = { xs: 6, md: 4, lg: 2, xl: 2 };
const reverseVal = 'reverse';
const resetVal = 'reset';

const BarChartSegments: React.FC<Props> = () => {
    const { watch, setValue } = useFormContext<BarChartFormDataSchema>();

    const xAxisDataKey = watch('xAxisProps.dataKey');

    const segments = useWatch({ name: 'segments' }) as BarChartFormDataSchema['segments'];
    const theDataRows = useWatch({
        name: 'dataOverrides',
    }) as BarChartFormDataSchema['dataOverrides'];
    const originalData = useWatch({
        name: 'originalData',
    }) as BarChartFormDataSchema['originalData'];

    const t = useCommonTranslation();

    const orderByOpts = useMemo<OptionSchema[]>(
        () => [
            ...segments.map(seg => ({ label: seg.label, value: seg.dataKey })),
            { label: t('Reporting.exporting.form.segmentsReverse'), value: reverseVal },
            { label: t('Reporting.exporting.form.segmentsResetOrder'), value: resetVal },
        ],
        [segments, t],
    );

    const onOrderByChanged = useCallback(
        newVal => {
            if (newVal === resetVal) {
                const originalKeyOrder = originalData.map(d => d[xAxisDataKey]);
                setValue(
                    'dataOverrides',
                    originalKeyOrder.map(key =>
                        (theDataRows as unknown as BarChartFormDataSchema['dataOverrides']).find(
                            row => row.originalLabel === key,
                        ),
                    ),
                );
                return;
            }
            if (newVal === reverseVal) {
                setValue('dataOverrides', [...theDataRows].reverse());
                return;
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const newDataOrder = sortBy(compose(Number.parseFloat, prop(newVal as any)))([
                ...originalData,
            ]).reverse();
            // const newDataOrder = sortWith([descend(prop(newVal))])([...originalData]);
            const keys = newDataOrder.map(d => d[xAxisDataKey]);
            const newBarOrder = keys.map(key =>
                (theDataRows as unknown as BarChartFormDataSchema['dataOverrides']).find(
                    row => row.originalLabel === key,
                ),
            );
            setValue('dataOverrides', newBarOrder);
        },
        [setValue, originalData, xAxisDataKey, theDataRows],
    );

    const onShowAll = useCallback(() => {
        setValue(
            'dataOverrides',
            [...theDataRows].map(d => ({ ...d, visible: true })),
        );
    }, [setValue, theDataRows]);

    const onHideAll = useCallback(() => {
        setValue(
            'dataOverrides',
            [...theDataRows].map(d => ({ ...d, visible: false })),
        );
    }, [setValue, theDataRows]);

    const showHidden = useBoolean(true);
    const onToggleHidden = useCallback(() => {
        showHidden.toggle();
    }, [showHidden]);

    const visibleDataRows = useMemo(
        () => (showHidden.value ? theDataRows : theDataRows.filter(d => d.visible)),
        [theDataRows, showHidden.value],
    );

    return (
        <Box>
            <Typography variant="subtitle2">
                {useCommonTranslation('Reporting.exporting.form.segmentsHeader')}
            </Typography>
            <Grid container alignItems="center">
                {segments.map((f, i) => (
                    <Grid key={f.originalLabel} item {...gridProps}>
                        <SegmentCol index={i} />
                    </Grid>
                ))}
                <Grid item {...gridProps}>
                    <Box pb={2} display="flex" alignItems="flex-end">
                        <ToggleButtonGroup
                            exclusive
                            onChange={onOrderByChanged}
                            options={orderByOpts}
                            size="small"
                            label={useCommonTranslation('Reporting.exporting.form.segmentsSortBy')}
                        />
                    </Box>
                </Grid>
            </Grid>
            <AutoGrid spacing={1}>
                <Button variant="text" onClick={onShowAll}>
                    {useCommonTranslation('Reporting.exporting.form.segmentsShowAll')}
                </Button>
                <Button variant="text" onClick={onHideAll}>
                    {useCommonTranslation('Reporting.exporting.form.segmentsHideAll')}
                </Button>
                <Button variant="text" onClick={onToggleHidden}>
                    {useCommonTranslation('Reporting.exporting.form.segmentsToggleHidden')}{' '}
                    {showHidden.value ? <Visibility /> : <VisibilityOff />}
                </Button>
            </AutoGrid>
            <Grid container rowSpacing={2}>
                {visibleDataRows.map((f, i) => (
                    <Grid key={f.originalLabel} item xs={12}>
                        <DataRow index={i} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

type SegmentColProps = {
    index: number;
};

const SegmentCol: React.FC<SegmentColProps> = ({ index }) => {
    return (
        <AutoGrid spacing={2} alignItems="center">
            <ControlledFormInput<BarChartFormDataSchema>
                render={({ field }) => <TextField {...field} variant="standard" size="small" />}
                name={`segments.${index}.label`}
            />
            <ControlledFormInput<BarChartFormDataSchema>
                render={({ field }) => <RgbaColorPicker {...field} asDropdown dropdownWidth={30} />}
                name={`segments.${index}.color`}
            />
            <ControlledFormInput<BarChartFormDataSchema>
                render={({ field }) => {
                    const isVisible = field.value as boolean;
                    if (isVisible) {
                        return (
                            <IconButton onClick={() => field.onChange(false)}>
                                <Visibility />
                            </IconButton>
                        );
                    }
                    return (
                        <IconButton onClick={() => field.onChange(true)}>
                            <VisibilityOff />
                        </IconButton>
                    );
                }}
                name={`segments.${index}.visible`}
            />
        </AutoGrid>
    );
};

const DataRow: React.FC<SegmentColProps> = ({ index }) => {
    const Colors = useWatch({ name: `dataOverrides.${index}.partColors` });

    return (
        <AutoGrid spacing={2} xs={4} md={4} lg={2} alignItems="center">
            <ControlledFormInput<BarChartFormDataSchema>
                render={({ field }) => <TextField {...field} variant="standard" size="small" />}
                name={`dataOverrides.${index}.newLabel`}
                key={`dataOverrides.${index}.newLabel`}
            />
            <AutoGrid spacing={2} alignItems="center">
                <AutoGrid spacing={2} alignItems="center">
                    {Colors.map((col, ci) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <DataRowColor
                            key={`dataOverrides.${index}.colors`}
                            rowIndex={index}
                            colorIndex={ci}
                        />
                    ))}
                </AutoGrid>
                <ControlledFormInput<BarChartFormDataSchema>
                    render={({ field }) => {
                        const isVisible = field.value as boolean;
                        if (isVisible) {
                            return (
                                <IconButton onClick={() => field.onChange(false)}>
                                    <Visibility />
                                </IconButton>
                            );
                        }
                        return (
                            <IconButton onClick={() => field.onChange(true)}>
                                <VisibilityOff />
                            </IconButton>
                        );
                    }}
                    name={`dataOverrides.${index}.visible`}
                    key={`dataOverrides.${index}.visible`}
                />
            </AutoGrid>
        </AutoGrid>
    );
};

const DataRowColor: React.FC<{ rowIndex: number; colorIndex: number }> = ({
    rowIndex,
    colorIndex,
}) => {
    const { setValue } = useFormContext();
    const segmentColor = useWatch({ name: `segments.${colorIndex}.color` });
    const prevSegmentColor = usePrevious(segmentColor);

    const dataKey = useMemo<FieldPath<BarChartFormDataSchema>>(
        () => `dataOverrides.${rowIndex}.partColors.${colorIndex}`,
        [rowIndex, colorIndex],
    );
    useEffect(() => {
        if (prevSegmentColor != null && segmentColor !== prevSegmentColor) {
            // when the parent segment colour is updated, update all child colours
            setValue(dataKey, segmentColor);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [segmentColor]);

    return (
        <ControlledFormInput<BarChartFormDataSchema>
            render={({ field }) => <RgbaColorPicker {...field} asDropdown dropdownWidth={30} />}
            name={dataKey}
            key={dataKey}
        />
    );
};

export default BarChartSegments;
