import { Campaign, Creative, Displays_LineItem, PermissionName } from '@uniled/api-sdk';
import {
    CustomFilterConfigType,
    FilterableEntityTableProps,
    FilterConfig,
    FilterConfigDateRange,
    FilterConfigEntityAutocomplete,
    FilterConfigSelect,
    generateColumn,
} from 'c-pagination';
import { User } from 'c-entity-types';
import { PostAuthRoutes } from 'c-routes';
import { without } from 'ramda';
import LineItemResolutionsCell from 'c-main/Components/Campaign/CampaignOverviewDisplays/LineItemResolutionsCell';
import LineItemFileTypesCell from 'c-main/Components/Campaign/CampaignOverviewDisplays/LineItemFileTypesCell';
// eslint-disable-next-line import/no-cycle

export const DataStatusPaginationTag = 'data-status';

const campaignColumns = [
    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.idCol',
        field: 'id',
        entityName: 'Campaign',
        orderable: true,
    }),
    generateColumn<Campaign>({
        headerTitle: 'Modules.Main.Campaigns.List.table.nameCol',
        field: 'name',
        entityName: 'Campaign',
        orderable: true,
    }),
];

export const simpleCampaignEntityTableProps = {
    columns: campaignColumns,
    textSearchColumns: ['name', 'reference'],
    baseEntityName: 'Campaign',
    routeTemplate: PostAuthRoutes.Campaigns.CampaignOverview,
} as FilterableEntityTableProps;

export const creativeColumns = [
    generateColumn<Creative>({
        headerTitle: 'Modules.Main.Creative.List.table.idCol',
        field: 'id',
        entityName: 'Creative',
        orderable: true,
    }),
    generateColumn<Creative>({
        headerTitle: 'Modules.Main.Creative.List.table.nameCol',
        field: 'name',
        entityName: 'Creative',
        orderable: true,
    }),
];
const userColumns = [
    generateColumn<User>({
        headerTitle: 'Modules.Auth.User.table.idCol',
        field: 'id',
        entityName: 'User',
        orderable: true,
    }),
    generateColumn<User>({
        headerTitle: 'Modules.Auth.User.table.nameCol',
        field: 'name',
        entityName: 'User',
        orderable: true,
    }),
    generateColumn<User>({
        headerTitle: 'Modules.Auth.User.table.emailCol',
        field: 'email',
        entityName: 'User',
        orderable: true,
    }),
    generateColumn<User>({
        headerTitle: 'Modules.Auth.User.table.languageCol',
        field: 'locale',
        entityName: 'User',
    }),
    generateColumn<User>({
        headerTitle: 'Modules.Auth.User.table.rolesCol',
        field: 'roles',
        entityName: 'User',
        permissions: [PermissionName.UniledportalRoleRead, PermissionName.UniledportalRoleUpdate],
        dataType: 'relationList',
        relationListProps: {
            dialogTitle: 'Modules.Auth.User.table.roleDialogTitle',
            relationships: [
                {
                    relationIdKey: 'roles',
                    entityName: 'Role',
                    relationLabelField: 'name',
                },
            ],
        },
    }),
    generateColumn<User>({
        headerTitle: 'Modules.Auth.User.table.buyerCol',
        field: 'buyers',
        entityName: 'User',
        permissions: [PermissionName.UniledportalBuyerRead],
        dataType: 'relationList',
        relationListProps: {
            dialogTitle: 'Modules.Auth.User.table.buyerDialogTitle',
            relationships: [
                {
                    relationIdKey: 'buyer',
                    entityName: 'Buyer',
                    relationLabelField: 'name',
                },
                {
                    relationIdKey: 'buyers',
                    entityName: 'Buyer',
                    relationLabelField: 'name',
                },
            ],
        },
    }),
    generateColumn<User>({
        headerTitle: 'Modules.Auth.User.table.agencyCol',
        field: 'agencies',
        entityName: 'User',
        permissions: [PermissionName.UniledportalAgencyRead],
        dataType: 'relationList',
        relationListProps: {
            dialogTitle: 'Modules.Auth.User.table.agencyDialogTitle',
            relationships: [
                {
                    relationIdKey: 'agency',
                    entityName: 'Agency',
                    relationLabelField: 'name',
                },
                {
                    relationIdKey: 'agencies',
                    entityName: 'Agency',
                    relationLabelField: 'name',
                },
            ],
        },
    }),
    generateColumn<User>({
        headerTitle: 'Modules.Auth.User.table.ownerCol',
        field: 'owners',
        entityName: 'User',
        permissions: [PermissionName.UniledportalOwnerRead],
        dataType: 'relationList',
        relationListProps: {
            dialogTitle: 'Modules.Auth.User.table.ownerDialogTitle',
            relationships: [
                {
                    relationIdKey: 'owner',
                    entityName: 'Owner',
                    relationLabelField: 'name',
                },
                {
                    relationIdKey: 'owners',
                    entityName: 'Owner',
                    relationLabelField: 'name',
                },
            ],
        },
    }),
];

export const userEntityTableProps = {
    columns: userColumns,
    includes: ['buyer', 'buyers', 'roles', 'agency', 'agencies', 'owner', 'owners', 'locale'],
    textSearchColumns: ['name', 'email'],
    baseEntityName: 'User',
} as FilterableEntityTableProps;

export const displaysLineItemsColumns = [
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.nameHeader',
        field: 'name',
        entityName: 'Displays_LineItem',
        orderable: true,
    }),
    generateColumn<User>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.ownerHeader',
        field: 'owner.name',
        entityName: 'Displays_LineItem',
        filterKeyPath: 'owner.id',
        permissions: [PermissionName.UniledportalOwnerRead],
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.typeHeader',
        field: 'type',
        entityName: 'Displays_LineItem',
        // orderable: true,
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.environmentHeader',
        field: 'environment',
        entityName: 'Displays_LineItem',
        // orderable: true,
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.sizeHeader',
        field: 'size',
        entityName: 'Displays_LineItem',
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.resolutionHeader',
        field: 'resolutions',
        entityName: 'Displays_LineItem',
        RenderCell: LineItemResolutionsCell,
    }),
    generateColumn<Displays_LineItem>({
        headerTitle: 'Modules.Main.Campaigns.Create.LineItemsTable.fileTypesHeader',
        field: 'fileTypes',
        entityName: 'Displays_LineItem',
        RenderCell: LineItemFileTypesCell,
    }),
];

export const displaysLineItemsEntityTableProps = {
    columns: displaysLineItemsColumns,
    includes: ['owner', 'resolutions', 'fileTypes'],
    textSearchColumns: ['name'],
    orderBy: 'name',
    direction: 'asc',
    baseEntityName: 'Displays_LineItem',
    perPage: 25,

    revertToIdSearchOnNumberOnlyInput: false,
    showFilters: true,
} as FilterableEntityTableProps;

export const CampaignScopes = {
    archived: 'archived',
    notArchived: 'notArchived',
    upcoming: 'upcoming',
    live: 'live',
    past: 'past',
    hasErrors: 'hasErrors',
    awaitingData: 'awaitingData',
    inspectingData: 'inspectingData',
    awaitingBookingPlan: 'awaitingBookingPlan',
    inflightReportSent: 'inflightReportSent',
    pcaReportSent: 'pcaReportSent',
    scopeKey: 'scope',
};

export const CampaignStatusFilterConfig: FilterConfigSelect = {
    label: 'Modules.Main.Campaigns.List.statusDropdownLabel',
    insertAtIndex: 0,
    key: CampaignScopes.scopeKey,
    type: CustomFilterConfigType.Select,
    options: [
        { value: CampaignScopes.archived, label: 'Modules.Main.Campaigns.List.scopes.archived' },
        { value: CampaignScopes.upcoming, label: 'Modules.Main.Campaigns.List.scopes.upcoming' },
        { value: CampaignScopes.live, label: 'Modules.Main.Campaigns.List.scopes.live' },
        { value: CampaignScopes.past, label: 'Modules.Main.Campaigns.List.scopes.past' },
    ],
    filterCountOverride: (newCount, value) => {
        if (value.indexOf(CampaignScopes.notArchived) !== -1) {
            /**
             * We don't have a dropdown option for 'not archived', but set it as the default filter
             * so we don't want to add to the filter count when it is set.
             */
            return newCount - 1;
        }

        return newCount;
    },
    onChangeOverrideValue: newValue => {
        /**
         * basically defaulting to only show non archived campaigned
         */
        const showArchived = newValue.indexOf(CampaignScopes.archived) !== -1;
        const alteredValue = [
            ...without([CampaignScopes.archived, CampaignScopes.notArchived], newValue),
        ];
        alteredValue.push(showArchived ? CampaignScopes.archived : CampaignScopes.notArchived);

        return alteredValue;
    },
};

export const CampaignStatusFilterConfigDataStatusPage: FilterConfigSelect = {
    ...CampaignStatusFilterConfig,
    options: [
        ...CampaignStatusFilterConfig.options,
        { value: CampaignScopes.hasErrors, label: 'Modules.Main.Campaigns.List.scopes.hasErrors' },
        {
            value: CampaignScopes.awaitingData,
            label: 'Modules.Main.Campaigns.List.scopes.awaitingData',
        },
        {
            value: CampaignScopes.inspectingData,
            label: 'Modules.Main.Campaigns.List.scopes.inspectingData',
        },
        {
            value: CampaignScopes.awaitingBookingPlan,
            label: 'Modules.Main.Campaigns.List.scopes.awaitingBookingPlan',
        },
        {
            value: CampaignScopes.inflightReportSent,
            label: 'Modules.Main.Campaigns.List.scopes.inflightReportSent',
        },
        {
            value: CampaignScopes.pcaReportSent,
            label: 'Modules.Main.Campaigns.List.scopes.pcaReportSent',
        },
    ],
};

export const CampaignSignOffFilterConfig: FilterConfigSelect = {
    label: 'Modules.Main.Campaigns.List.signOffLabel',
    key: 'filter.sign_off',
    type: CustomFilterConfigType.Select,
    multiple: false,

    options: [
        {
            value: 1,
            label: 'Modules.Main.Campaigns.List.signedOffOptionLabel',
        },
        {
            value: 0,
            label: 'Modules.Main.Campaigns.List.notSignedOffOptionLabel',
        },
    ],
};

export const CampaignBookingSummaryValidatedFilterConfig: FilterConfigSelect = {
    label: 'Modules.Main.Campaigns.List.bookingSummarySignOffLabel',
    key: 'filter.booking_summary_validated',
    type: CustomFilterConfigType.Select,
    multiple: false,

    options: [
        {
            value: 1,
            label: 'Modules.Main.Campaigns.List.signedOffOptionLabel',
        },
        {
            value: 0,
            label: 'Modules.Main.Campaigns.List.notSignedOffOptionLabel',
        },
    ],
};

export const CampaignHighPriorityFilterConfig: FilterConfigSelect = {
    label: 'Modules.Main.Campaigns.List.highPriorityLabel',
    key: 'filter.high_priority',
    type: CustomFilterConfigType.Select,
    multiple: false,

    options: [
        {
            value: 1,
            label: 'Modules.Main.Campaigns.List.highPriorityOptionLabel',
        },
        {
            value: 0,
            label: 'Modules.Main.Campaigns.List.notHighPriorityOptionLabel',
        },
    ],
};

export const CampaignStartDateConfig: FilterConfigDateRange = {
    label: 'Modules.Main.Campaigns.List.startDateDropdownLabel',
    type: CustomFilterConfigType.DateRange,
    key: 'filter.date.date_start',
    permissions: [PermissionName.UniledportalDateRead],
};

export const CampaignEndDateConfig: FilterConfigDateRange = {
    label: 'Modules.Main.Campaigns.List.endDateDropdownLabel',
    type: CustomFilterConfigType.DateRange,
    key: 'filter.date.date_end',
    permissions: [PermissionName.UniledportalDateRead],
};

export const CampaignPlayoutPercentConfig: FilterConfig = {
    label: 'Modules.Main.Campaigns.List.reportingPercentageLabel',
    type: CustomFilterConfigType.Text,
    key: 'filter.playout_percentage',
};

export const CampaignMediaOwnersConfig: FilterConfigEntityAutocomplete = {
    label: 'Modules.Main.Campaigns.List.mediaOwnersDropdownLabel',
    type: CustomFilterConfigType.EntityAutocomplete,
    key: 'filter.owners.owner_id',
    entityName: 'Displays_Owner',
    permissions: [PermissionName.UniledportalCampaign_ownerRead],
};
