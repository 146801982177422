import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { TranslationPath as CommonTranslationPath, useCommonTranslation } from 'c-translation';
import { useTheme } from '@mui/styles';

const MetaLayout = <TranslationPath extends string = CommonTranslationPath>({
    metaTitle,
    withoutMetaTitlePrefix = false,
    children,
}: {
    metaTitle: TranslationPath;
    withoutMetaTitlePrefix?: boolean;
    children: ReactNode;
}) => {
    const prefix = useCommonTranslation('Meta.pageTitlePrefix');
    const theme = useTheme();
    return (
        <>
            <Helmet>
                <title>
                    {`${!withoutMetaTitlePrefix ? prefix : ''}${useCommonTranslation(metaTitle)}`}
                </title>

                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
                    rel="stylesheet"
                />

                <style>
                    {`
                    html, body {
                        font-family: ${theme.typography.fontFamily};
                        padding: 0;
                        margin: 0;
                        background: ${theme.palette.custom.contentBgColor};
                    }
                    .greyBackdrop input:-webkit-autofill,
                    .greyBackdrop input:-webkit-autofill:hover,
                    .greyBackdrop input:-webkit-autofill:focus {
                        background-color: transparent !important;
                        -webkit-box-shadow: 0 0 0 1000px ${theme.palette.grey['200']} inset !important;
                        filter: none;
                    }
                `}
                </style>
                <link
                    rel="icon"
                    type="image/png"
                    href="https://assets.uniliveportal.com/assets/20211220-053003/imgs/favicon.png"
                    sizes="16x16"
                />
            </Helmet>
            {children}
        </>
    );
};

export default MetaLayout;
