import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import { useAPIClientRequest, useEntityPage } from 'c-hooks';
import { AutoGrid, Button, LinkButton } from 'c-components';
import apiClient from 'c-data/apiClient';
import { downloadIcon } from 'c-main/icons';
import {
    CampaignCreativeAutoAssignRequest,
    PermissionName,
    Displays_LineItem,
} from '@uniled/api-sdk';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PlaylistAddCheck, PublishedWithChanges } from '@mui/icons-material';
import { omit } from 'ramda';
import to from 'await-to-js';
import { PaginatedEntityTableContext } from 'c-pagination/EntityPaginationContext';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { useSetAtom } from 'jotai';
import { useCommonTranslation } from 'c-translation';
import { atom_forceAssignLineItemIdDialog } from './atoms';
import { AssignPerms } from './constants';

type Props = RenderColumnDataProps<Displays_LineItem>;

const SpecsDownloadPermissions = [PermissionName.UniledportalCampaignSpecsDownload];

const LineItemsControlsCell: React.FC<Props> = ({ entity, paginationData }) => {
    const { id: campaign } = useEntityPage();

    const {
        start: AutoAssignLineItem,
        isLoading: IsAutoAssignLoading,
        hasSucceeded: AutoAssignSuccess,
        hasFailed: AutoAssignFailed,
        error: AutoAssignError,
        reset: ResetAutoAssign,
    } = useAPIClientRequest(apiClient.Entities.Campaign.autoAssignCreativesToLineItems);

    const autoAssignLineItem = useCallback(async () => {
        const assignData: CampaignCreativeAutoAssignRequest = { lineItems: [entity.id] };
        if (
            Array.isArray(paginationData?.filters?.creativeGroups) &&
            paginationData?.filters?.creativeGroups?.length > 0
        ) {
            assignData.creativeGroups = paginationData?.filters?.creativeGroups as number[];
        }
        assignData.filters = omit(['creativeGroups'], paginationData?.filters ?? {});
        assignData.searchables = paginationData.searchables;
        await to(AutoAssignLineItem(campaign, assignData));
    }, [AutoAssignLineItem, entity, campaign, paginationData]);

    const { search } = useContext(PaginatedEntityTableContext);
    useEffect(() => {
        if (AutoAssignSuccess) {
            ResetAutoAssign();
            // refresh the list
            search();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AutoAssignSuccess]);

    const t = useCommonTranslation();
    const autoAssignTooltipTitle = useMemo(() => {
        if (!AutoAssignFailed)
            return t('Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.autoAssign');

        if (String(AutoAssignError).length > 0) return String(AutoAssignError);

        return t('Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.autoAssignFailed');
    }, [AutoAssignFailed, AutoAssignError, t]);

    const setOpenForceAssignId = useSetAtom(atom_forceAssignLineItemIdDialog);

    const openForceAssign = useCallback(() => {
        setOpenForceAssignId(entity.id);
    }, [entity.id, setOpenForceAssignId]);

    const canAutoAssign = useMemo(
        () => !IsAutoAssignLoading && entity?.availableCreativesCount > 0,
        [IsAutoAssignLoading, entity?.availableCreativesCount],
    );

    const downloadLink = useMemo(
        () =>
            apiClient.Entities.Campaign.downloadLink(campaign, {
                line_item_ids: [entity.id],
                creative_ids: entity?.creatives?.map(c => c.id) ?? [],
                include_display_specs: true,
                include_creatives: true,
            }),
        [campaign, entity?.creatives, entity?.id],
    );

    return (
        <AutoGrid xs gap={1} flexWrap="nowrap">
            <IfHasAllPermissions permissions={AssignPerms}>
                <Tooltip title={autoAssignTooltipTitle}>
                    <Box>
                        <Button
                            iconChild
                            size="small"
                            onClick={autoAssignLineItem}
                            disabled={!canAutoAssign}
                            color={AutoAssignFailed ? 'error' : undefined}
                        >
                            {!IsAutoAssignLoading && <PublishedWithChanges fontSize="inherit" />}
                            {IsAutoAssignLoading && (
                                <Box display="flex" alignItems="center">
                                    <CircularProgress size={15} color="inherit" />
                                </Box>
                            )}
                        </Button>
                    </Box>
                </Tooltip>
            </IfHasAllPermissions>
            <IfHasAllPermissions permissions={AssignPerms}>
                <Tooltip
                    title={t(
                        'Modules.Main.Campaigns.Create.LineItemsTable.assignCreatives.manualAssign',
                    )}
                >
                    <Box>
                        <Button iconChild size="small" onClick={openForceAssign}>
                            <PlaylistAddCheck fontSize="inherit" />
                        </Button>
                    </Box>
                </Tooltip>
            </IfHasAllPermissions>
            <IfHasAllPermissions permissions={SpecsDownloadPermissions}>
                <LinkButton iconChild size="small" to={downloadLink} target="_blank">
                    {downloadIcon}
                </LinkButton>
            </IfHasAllPermissions>
        </AutoGrid>
    );
};

export default LineItemsControlsCell;
