import React, { useMemo } from 'react';
import HighlightWord from 'c-components/HighlightWord';

type Props = {
    filters?: string[];
    text: string;
};

const emptyArray = [];

const FileHighlightFilterText: React.FC<Props> = ({ text, filters = emptyArray }) => {
    const searchTerm = useMemo(
        () => filters?.find(fil => fil?.toLowerCase() === text?.toLowerCase()) ?? '',
        [filters, text],
    );

    return <HighlightWord text={text} searchTerm={searchTerm} />;
};

export default FileHighlightFilterText;
