import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { PCAMapReportResponse, Campaign } from '@uniled/api-sdk';
import { GoogleMap, HeatmapLayer, HeatmapLayerProps } from '@react-google-maps/api';
import {
    generateHeatMapData,
    generateMarkerLocations,
    getCampaignReportingMapCenter,
    getCampaignReportingMapZoom,
    MarkerLocation,
    ReportingMapOptions,
} from 'c-main/Lib';
import { MapDataType } from 'c-main/Components/Campaign/CampaignReports/types';
import { useEntityData } from 'c-data';

type Props = {
    id: number;
    data: PCAMapReportResponse['reportData'];
};

const containerStyle = {
    width: '100%',
    height: '80vh',
};

const ImpactsMapView: React.FC<Props> = ({ id, data }) => {
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });
    const mapRef = useRef<google.maps.Map | null>();
    const onMapLoad = useCallback(map => {
        mapRef.current = map;
    }, []);
    const onMapUnmount = useCallback(() => {
        mapRef.current = null;
    }, []);

    const heatMapData = useMemo<HeatmapLayerProps['data']>(
        () => generateHeatMapData(data, MapDataType.HeatActual),
        [data],
    );

    const markerLocations = useMemo<MarkerLocation[]>(
        () => generateMarkerLocations(data, MapDataType.HeatActual),
        [data],
    );
    const center = useMemo(() => getCampaignReportingMapCenter(campaign), [campaign]);
    const zoom = useMemo(() => getCampaignReportingMapZoom(campaign), [campaign]);
    const repositionedOnce = useRef(false);

    useEffect(() => {
        if (markerLocations.length > 0 && !repositionedOnce.current && mapRef?.current) {
            // basically repositioning the map on first load when the first set of markers (screens) are loaded
            const bounds = new google.maps.LatLngBounds();
            markerLocations.forEach(loc => {
                if (loc.location != null) bounds.extend(loc.location);
            });

            // setTimeout(() => mapRef?.current?.fitBounds?.(bounds), 1000);
            repositionedOnce.current = true;
        }
    }, [markerLocations]);

    const heatmapLayer = useMemo(() => <HeatmapLayer data={heatMapData} />, [heatMapData]);

    return (
        <>
            <GoogleMap
                ref={mapRef as any}
                onLoad={onMapLoad}
                onUnmount={onMapUnmount}
                zoom={zoom}
                center={center}
                mapContainerStyle={containerStyle}
                options={ReportingMapOptions}
            >
                {heatmapLayer}
            </GoogleMap>
        </>
    );
};

export default ImpactsMapView;
