import {
    skinActions,
    skinAdapter,
    skinThunks as defaultThunks,
    skinSelectors as defaultSelectors,
    skinReducer,
} from './Skin-slice';
import * as thunks from './Skin-thunks';
import * as selectors from './Skin-selectors';

const skinThunks = { ...defaultThunks, ...thunks };
const skinSelectors = { ...defaultSelectors, ...selectors };

export { default as SkinSlice } from './Skin-slice';
export { skinSelectors, skinActions, skinAdapter, skinThunks, skinReducer };
