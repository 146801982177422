import React, { useEffect } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import { frameSearchIcon } from 'c-main/icons';
import { PermissionName } from '@uniled/api-sdk';
import ReactGA from 'react-ga';
import FrameSearchForm from './FrameSearchForm';

const FrameSearchPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <Card>
            <CardHeader title={useCommonTranslation('Modules.Admin.FrameSearch.metaTitle')} />
            <CardContent>
                <FrameSearchForm />
            </CardContent>
        </Card>
    );
};

const setup: PageConfig = {
    id: 'FrameSearchPage',
    component: FrameSearchPage,
    systemSearch: {
        title: 'Modules.Admin.FrameSearch.metaTitle',
        description: 'Modules.Admin.FrameSearch.description',
        type: 'page',
        saveAsRecentPage: true,
        aliases: 'SystemSearch.aliases.frameSearchPage',
        icon: frameSearchIcon,
        route: PostAuthRoutes.Admin.FrameSearch,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            { label: 'Modules.Admin.FrameSearch.metaTitle' },
        ],
        permissions: [PermissionName.Admin],
    },
};

export default setup;
