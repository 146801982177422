import { ThemeOptions } from '@mui/material/styles';

export const FontWeights = {
    Regular: 300,
    Medium: 500,
    SemiBold: 600,
    Bold: 700,
};

const defaultTheme: ThemeOptions = {
    palette: {
        // primary: {
        //     main: '#020d1f',
        //     contrastText: '#fff',
        // },
        custom: { contentBgColor: '#f4f5fa' },
        secondary: {
            // main: '#53565a',
            main: 'rgb(117, 117, 117)',
        },
        // error: {
        //     main: '#FE2F7C',
        //     light: '#FFE9F3',
        // },
        success: {
            main: '#13A454',
        },
        text: {
            primary: '#2c2a29',
            // primary: '#787e9d',
        },
    },
    typography: {
        fontFamily: 'Rubik, sans-serif',
        h1: {
            fontWeight: FontWeights.Medium,
            fontSize: '2.4rem',
            lineHeight: '1em',
        },
        h2: {
            fontWeight: FontWeights.Medium,
            fontSize: '2.2rem',
            lineHeight: '1.2571em',
        },
        h3: {
            fontWeight: FontWeights.Medium,
            fontSize: '1.8rem',
            lineHeight: '1.42em',
        },
        h4: {
            fontWeight: FontWeights.Medium,
            fontSize: '1.4rem',
            lineHeight: '1.3em',
        },
        h5: {
            fontWeight: FontWeights.SemiBold,
            fontSize: '1.3rem',
            lineHeight: '1.25em',
        },
        h6: {
            fontWeight: FontWeights.Medium,
            fontSize: '1.1rem',
            lineHeight: '1.3889em',
        },
        subtitle1: {
            fontWeight: FontWeights.Regular,
            fontSize: '1.2rem',
            lineHeight: '1.7em',
            letterSpacing: '0.0015em',
        },
        subtitle2: {
            fontWeight: FontWeights.Medium,
            fontSize: '0.9rem',
            lineHeight: '1.7142em',
        },
        body1: {
            fontWeight: FontWeights.Regular,
            fontSize: '1rem',
            lineHeight: '1.625em',
        },
        body2: {
            fontWeight: FontWeights.Regular,
            fontSize: '0.9rem',
            lineHeight: '1.7142em',
        },
        button: {
            fontWeight: FontWeights.Regular,
            textTransform: 'none',
        },
        caption: {
            fontWeight: FontWeights.Regular,
            fontSize: '0.75rem',
            lineHeight: '1.3333em',
        },
        overline: {
            fontWeight: FontWeights.Medium,
            fontSize: '0.65rem',
            lineHeight: '1.6em',
            textTransform: 'uppercase',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '2rem',
                },
            },
        },
        // MuiPaper: {
        //     defaultProps: {
        //         // elevation: 0,
        //         // sx: { borderRadius: 1 },
        //     },
        // },
        // MuiAlert: {
        //     defaultProps: {
        //         sx: { borderWidth: 2, borderColor: 'warning.light', borderStyle: 'solid' },
        //     },
        // },
        // this will literally happen on all tables everywhere, so need to think of different solution
        // MuiTableCell: {
        //     styleOverrides: {
        //         stickyHeader: state => ({
        //             backgroundColor: state.theme.palette.custom.contentBgColor,
        //         }),
        //     },
        // },
    },
};

export default defaultTheme;
