import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Stack, TableContainer } from '@mui/material';
import {
    Alert,
    Button,
    Checkbox,
    DialogV2,
    EntityAutocomplete,
    ListItemText,
    MuiRouterLink,
    OptionSchema,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { generatePath } from 'react-router-dom';
import { PostAuthRoutes } from 'c-routes';
import { deleteIcon } from 'c-main/icons';
import { useBoolean } from 'react-hanger';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import { NetworkRequestState } from '@uniled/data-layer';
import to from 'await-to-js';
import { useUpdateEntityData } from 'c-data';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PermissionName, PopDisplays_ChannelFrame } from '@uniled/api-sdk';

type Props = {
    displayId: number;
    frames: PopDisplays_ChannelFrame[];
};

const attachPerms = [PermissionName.Pop_displaysDisplay_channel_framesCreate];
const detachPerms = [PermissionName.Pop_displaysDisplay_channel_framesDestroy];

const ChannelFramesDetailsTable: React.FC<Props> = ({ displayId, frames }) => {
    const [deleteFrameId, setDeleteFrameId] = useState<number>(null);

    const closeDeleteFrame = useCallback(() => {
        setDeleteFrameId(null);
    }, []);

    const t = useCommonTranslation();
    const deleteFrame = useMemo(
        () => frames.find(f => f.id === deleteFrameId),
        [frames, deleteFrameId],
    );
    const deleteFrameTitle = useMemo(
        () =>
            t('Modules.PopDisplays.ChannelFrame.table.removeFrameTitle', {
                name: deleteFrame?.channel,
            }),
        [t, deleteFrame],
    );
    const {
        start: DetachFrames,
        isLoading: IsDetaching,
        hasFailed: FailedDetaching,
        error: DetachError,
        requestStateRef,
    } = useAPIClientRequest(apiClient.PopDisplays.detachDisplayChannelFrames);

    const { upsert } = useUpdateEntityData('PopDisplays_Display');

    const detachFrames = useCallback(async () => {
        if (requestStateRef.current === NetworkRequestState.InProgress) return;

        const [err, succ] = await to(DetachFrames(displayId, [deleteFrameId], ['channelFrames']));

        if (!err && succ?.data?.data?.id === displayId) {
            upsert(succ.data.data);
            closeDeleteFrame();
        }
    }, [requestStateRef, DetachFrames, displayId, deleteFrameId, upsert, closeDeleteFrame]);

    return (
        <Stack gap={2}>
            <DialogV2
                onClose={closeDeleteFrame}
                open={deleteFrame != null}
                title={deleteFrameTitle}
                description="Modules.PopDisplays.ChannelFrame.table.removeFrameDescription"
                actions={
                    <>
                        {FailedDetaching && (
                            <Box mb={2}>
                                <Alert variant="outlined" severity="error">
                                    {String(DetachError)}
                                </Alert>
                            </Box>
                        )}
                        <Button color="error" onClick={detachFrames} disabled={IsDetaching}>
                            {t('Modules.PopDisplays.ChannelFrame.table.confirmRemoveFrame')}
                        </Button>
                    </>
                }
            />
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow isHeader>
                            <TableCell isHeader>
                                {t('Modules.PopDisplays.ChannelFrame.table.idCol')}
                            </TableCell>
                            <TableCell isHeader>
                                {t('Modules.PopDisplays.ChannelFrame.table.sourceCol')}
                            </TableCell>
                            <TableCell isHeader>
                                {t('Modules.PopDisplays.ChannelFrame.table.channelCol')}
                            </TableCell>
                            <TableCell isHeader>
                                {t('Modules.PopDisplays.ChannelFrame.table.frameCol')}
                            </TableCell>
                            <TableCell isHeader>
                                {t('Modules.PopDisplays.ChannelFrame.table.uuidCol')}
                            </TableCell>
                            <TableCell isHeader>
                                {t('Modules.PopDisplays.ChannelFrame.table.playerCol')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {frames.map(frame => (
                            <TableRow key={`${frame.id}-${frame.uuid}`}>
                                <TableCell>
                                    <Stack direction="row" gap={2} alignItems="center">
                                        <IfHasAllPermissions permissions={detachPerms}>
                                            <IconButton
                                                size="small"
                                                color="error"
                                                onClick={() => setDeleteFrameId(frame.id)}
                                            >
                                                {deleteIcon}
                                            </IconButton>
                                        </IfHasAllPermissions>
                                        <MuiRouterLink
                                            to={generatePath(
                                                PostAuthRoutes.PopDisplays.ChannelFrameOverview,
                                                {
                                                    id: frame.id,
                                                },
                                            )}
                                        >
                                            {frame.id}
                                        </MuiRouterLink>
                                    </Stack>
                                </TableCell>
                                <TableCell>{frame.source}</TableCell>
                                <TableCell>{frame.channel}</TableCell>
                                <TableCell>{frame.frame}</TableCell>
                                <TableCell>{frame.uuid}</TableCell>
                                <TableCell>{frame.player}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box>
                <IfHasAllPermissions permissions={attachPerms}>
                    <AddFrames displayId={displayId} />
                </IfHasAllPermissions>
            </Box>
        </Stack>
    );
};

const withoutDisplaysScope = ['withoutDisplays'];
const labelGen = (frame: PopDisplays_ChannelFrame) =>
    `${frame.source} | ${frame.channel} | ${frame.frame} | ${frame.uuid} | ${frame.player}`;

const renderOption = (optionProps, option: OptionSchema, frame: PopDisplays_ChannelFrame) => (
    <li {...optionProps}>
        <ListItemText
            primary={frame.channel}
            secondary={
                <>
                    <Box>
                        <Stack direction="row">
                            {frame.source} | {frame.frame} | {frame.player}
                        </Stack>
                    </Box>
                    <Box>{frame.uuid}</Box>
                </>
            }
        />
    </li>
);
const AddFrames: React.FC<{ displayId: number }> = ({ displayId }) => {
    const dialogState = useBoolean(false);
    const {
        start: AttachFrames,
        isLoading: IsAttaching,
        hasFailed: FailedAttaching,
        error: AttachError,
        requestStateRef,
    } = useAPIClientRequest(apiClient.PopDisplays.attachDisplayChannelFrames);

    const { upsert } = useUpdateEntityData('PopDisplays_Display');

    const t = useCommonTranslation();
    const scopeCheckboxState = useBoolean(true);
    const [frames, setFrames] = useState<number[]>([]);

    const attachFrames = useCallback(async () => {
        if (frames.length === 0 || requestStateRef.current === NetworkRequestState.InProgress)
            return;

        const [err, succ] = await to(AttachFrames(displayId, frames, ['channelFrames']));

        if (!err && succ?.data?.data?.id === displayId) {
            upsert(succ.data.data);
            dialogState.setFalse();
        }
    }, [AttachFrames, dialogState, displayId, requestStateRef, upsert, frames]);

    useEffect(() => {
        setFrames([]);
    }, [dialogState.value]);

    return (
        <>
            <Button onClick={dialogState.setTrue}>
                {t('Modules.PopDisplays.ChannelFrame.table.addFrameBtn')}
            </Button>
            <DialogV2
                onClose={dialogState.setFalse}
                open={dialogState.value}
                title="Modules.PopDisplays.ChannelFrame.table.addFrameTitle"
                actions={
                    <>
                        {FailedAttaching && (
                            <Box mb={2}>
                                <Alert variant="outlined" severity="error">
                                    {String(AttachError)}
                                </Alert>
                            </Box>
                        )}
                        <Button onClick={attachFrames} disabled={IsAttaching}>
                            {t('Modules.PopDisplays.ChannelFrame.table.addFrameBtn')}
                        </Button>
                    </>
                }
            >
                <Checkbox
                    isBoolean
                    value={scopeCheckboxState.value}
                    onChange={scopeCheckboxState.setValue}
                    label={t('Modules.PopDisplays.ChannelFrame.table.addFrameNoDisplayCheckbox')}
                />

                <EntityAutocomplete
                    key={scopeCheckboxState.value ? '1' : '0'}
                    value={frames}
                    onChange={setFrames}
                    entityName="PopDisplays_ChannelFrame"
                    multiple
                    textFieldProps={{
                        label: t('Modules.PopDisplays.ChannelFrame.table.addFrameDropdown'),
                    }}
                    searchColumns="channel"
                    labelColumn="channel"
                    labelGen={labelGen}
                    renderOption={renderOption}
                    scopes={scopeCheckboxState.value ? withoutDisplaysScope : undefined}
                />
            </DialogV2>
        </>
    );
};
export default ChannelFramesDetailsTable;
