import React, { useCallback, useEffect, useMemo } from 'react';
import {
    Box,
    IconButton,
    TableRowProps,
    Tooltip,
    Typography,
    TypographyProps,
} from '@mui/material';
import RawFilePreview from 'c-components/FileBrowser/RawFilePreview';
import Avatar from 'c-components/Avatar';
import FileIcon from 'c-components/FileBrowser/FileIcon';
import { useDrag } from 'react-dnd';
import {
    AutoGrid,
    Button,
    Checkbox,
    FileBrowserFile,
    FileDuration,
    FileName,
    LinkButton,
    TableCell,
    TableRow,
} from 'c-components';
import FileHighlightFilterText from 'c-components/FileBrowser/FileHighlightFilterText';
import { generateFileResolution } from 'c-components/FileBrowser/lib';
import {
    AllCreativeFilters,
    CreativeDraggableItem,
    CreativeDragItemType,
    OnCreativeChecked,
} from 'c-main/Components/CreativeManagement/types';
import { CheckboxProps } from 'c-components/Forms/Checkbox/Checkbox';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { uniq } from 'ramda';
import { useCampaignErrors } from 'c-main/Hooks';
import { CampaignErrorableType, PermissionName } from '@uniled/api-sdk';
import { useAuthenticatedUser, useDateUtils } from 'c-hooks';
import { deleteIcon, downloadIcon, editIcon } from 'c-main/icons';
import apiClient from 'c-data/apiClient';
import { onClickStopPropagation } from 'c-lib';
import { useCommonTranslation } from 'c-translation';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { Cancel, CheckCircle, Pending, PlaylistAddCheck } from '@mui/icons-material';
import { css } from '@emotion/react';

type Props = {
    campaignId: number;
    file: FileBrowserFile;
    isSelected: boolean;
    onChecked: OnCreativeChecked;
    filters: AllCreativeFilters;
    revalidateLoading: boolean;

    isBeingMultiDragged: boolean;
    allSelectedIds: number[];

    onOpenPreview: (id: number) => void;
    onAssignCreativeClicked: (id: number) => void;
    onUnAssignCreativeClicked: (id: number) => void;

    onRenameClicked: (id: number) => void;
    onDeleteClicked: (id: number) => void;

    canDragAndDropCreatives: boolean;
};
const checkboxSx = { mr: 0, mx: 0 };

const isDraggingColor = 'grey.100';

const renameClass = 'edit-creative-name';
const controlsCss = css(`
                .${renameClass} {
                    visibility: hidden;
                }
                &:hover .${renameClass} {
                    visibility: visible;
                }
            `);

const DeleteCreativesPerms: PermissionName[] = [PermissionName.UniledportalCreativeDestroy];

const AssignPerms: PermissionName[] = [
    PermissionName.DisplaysLine_itemsRead,
    PermissionName.UniledportalCreativeRead,
    PermissionName.UniledportalCreative_groupRead,
    PermissionName.UniledportalCampaign_display_creativeCreate,
    PermissionName.UniledportalCampaign_display_creativeUpdate,
    PermissionName.UniledportalCampaign_displayCreate,
    PermissionName.UniledportalCampaign_displayUpdate,
];

const RenamePerms: PermissionName[] = [PermissionName.UniledportalCreativeUpdate];

const tdProps = {
    dense: true,
    sx: { px: 1, py: 1.5 },
};
const nameTdProps = {
    ...tdProps,
    sx: { ...tdProps.sx, pl: 0, cursor: 'pointer' },
};
const greyTdProps = {
    ...tdProps,
    typographyProps: { variant: 'body2', color: 'grey.600' } as TypographyProps,
};

const PreviewIconSx = {
    '&:hover': { transform: 'scale(1.2)' },
    transition: '200ms all',
    cursor: 'pointer',
};

const CreativeListItem: React.FC<Props> = ({
    campaignId,
    file,
    isSelected,
    onChecked,
    filters,
    isBeingMultiDragged,
    allSelectedIds,
    revalidateLoading,
    onOpenPreview,
    onAssignCreativeClicked,
    onUnAssignCreativeClicked,
    onRenameClicked,
    canDragAndDropCreatives,
    onDeleteClicked,
}) => {
    const { formatDateString } = useDateUtils();

    const onClickId = useCallback(
        (callback: (id: number) => void) => {
            callback(file.downloadId);
        },
        [file.downloadId],
    );
    const openPreview = useCallback(() => onClickId(onOpenPreview), [onClickId, onOpenPreview]);
    const assignCreative = useCallback(
        () => onClickId(onAssignCreativeClicked),
        [onClickId, onAssignCreativeClicked],
    );
    const unAssignCreative = useCallback(
        () => onClickId(onUnAssignCreativeClicked),
        [onClickId, onUnAssignCreativeClicked],
    );
    const onRename = useCallback(() => onClickId(onRenameClicked), [onClickId, onRenameClicked]);
    const onDelete = useCallback(() => onClickId(onDeleteClicked), [onClickId, onDeleteClicked]);

    const onCheckboxChange = useCallback<CheckboxProps['onClick']>(
        e => {
            e.stopPropagation();
            e.preventDefault();
            onChecked(file.downloadId, e);
        },
        [file.downloadId, onChecked],
    );

    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: CreativeDragItemType.Creative,
            item: {
                creativeIds: uniq([...allSelectedIds, file.downloadId]),
            } as CreativeDraggableItem,
            collect: monitor => ({ isDragging: monitor.isDragging() }),
            canDrag: canDragAndDropCreatives,
        }),
        [allSelectedIds, file.downloadId, canDragAndDropCreatives],
    );
    const isActuallyBeingDragged = useMemo(
        () => isDragging || isBeingMultiDragged,
        [isDragging, isBeingMultiDragged],
    );

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getErrors } = useCampaignErrors(campaignId);

    const errors = useMemo(
        () =>
            getErrors({
                entityIds: [file.downloadId],
                entityTypes: [CampaignErrorableType.Creative],
            }),
        [file, getErrors],
    );

    const rowSx = useMemo<TableRowProps['sx']>(
        () => ({
            cursor: canDragAndDropCreatives ? 'move' : undefined,
            bgcolor: isActuallyBeingDragged ? isDraggingColor : undefined,
            '&:hover': {
                bgcolor: isDraggingColor,
            },
        }),
        [isActuallyBeingDragged, canDragAndDropCreatives],
    );

    const downloadLink = useMemo(
        () =>
            apiClient.Entities.Campaign.downloadLink(campaignId, {
                creative_ids: [file.downloadId],
                include_creatives: true,
            }),
        [file, campaignId],
    );

    const t = useCommonTranslation();

    const errorIndicator = useMemo(() => {
        let color = errors?.length > 0 ? 'error' : 'success.main';
        if (revalidateLoading) color = 'grey.400';

        let icon = <CheckCircle fontSize="inherit" />;
        if (errors?.length) icon = <Cancel fontSize="inherit" />;
        if (revalidateLoading) icon = <Pending fontSize="inherit" />;

        return (
            <Typography sx={{ mr: 1 }} color={color}>
                {icon}
            </Typography>
        );
    }, [errors?.length, revalidateLoading]);

    const onRenameBtnClick = useCallback(
        e => {
            e.stopPropagation();
            onRename();
        },
        [onRename],
    );

    const { isAdmin, user } = useAuthenticatedUser();
    const deletable = useMemo(
        () => isAdmin || (file.createdBy != null && user?.id === file.createdBy),
        [isAdmin, user, file],
    );

    return (
        <>
            <TableRow
                ref={drag}
                sx={rowSx}
                className={`creative-row-${file.downloadId}`}
                css={controlsCss}
            >
                {useMemo(
                    () => (
                        <TableCell {...tdProps} sx={{ width: 50, pr: 0 }}>
                            <Box
                                height={50}
                                alignItems="center"
                                justifyContent="center"
                                display="flex"
                            >
                                <Box mx="auto">
                                    <Checkbox
                                        className="toggle-check-creative"
                                        checked={isSelected}
                                        onClick={onCheckboxChange}
                                        formControlLabelSx={checkboxSx}
                                        label=""
                                    />
                                </Box>
                            </Box>
                        </TableCell>
                    ),
                    [isSelected, onCheckboxChange],
                )}
                {useMemo(
                    () => (
                        <TableCell {...nameTdProps}>
                            <Box display="flex" alignItems="center" onClick={openPreview}>
                                <Box
                                    height={35}
                                    width={35}
                                    mr={1.5}
                                    sx={PreviewIconSx}
                                    className="open-creative-preview"
                                >
                                    <RawFilePreview
                                        file={file}
                                        maxHeight={35}
                                        maxWidth={35}
                                        showVideoPreview={false}
                                        preferredSize="small"
                                        imgStyles={{ borderRadius: '4px' }}
                                        fallback={
                                            <Avatar>
                                                <FileIcon file={file} />
                                            </Avatar>
                                        }
                                    />
                                </Box>
                                <Box>
                                    <Tooltip title={file.name}>
                                        <Box display="flex" alignItems="center">
                                            {errorIndicator}
                                            <FileName name={file.name} />
                                            <IfHasAllPermissions permissions={RenamePerms}>
                                                <IconButton
                                                    size="small"
                                                    onClick={onRenameBtnClick}
                                                    className={renameClass}
                                                >
                                                    {editIcon}
                                                </IconButton>
                                            </IfHasAllPermissions>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </TableCell>
                    ),
                    [errorIndicator, file, onRenameBtnClick, openPreview],
                )}

                {useMemo(
                    () => (
                        <TableCell {...greyTdProps}>
                            <FileHighlightFilterText
                                filters={filters.fileTypes}
                                text={file.fileTypeSimple}
                            />
                        </TableCell>
                    ),
                    [file, filters.fileTypes],
                )}
                {useMemo(
                    () => (
                        <TableCell {...greyTdProps}>
                            {file.durationMs > 0 ? <FileDuration file={file} /> : '-'}
                        </TableCell>
                    ),
                    [file],
                )}
                {useMemo(
                    () => (
                        <TableCell {...greyTdProps}>
                            <FileHighlightFilterText
                                filters={filters.resolutions}
                                text={generateFileResolution(file)}
                            />
                        </TableCell>
                    ),
                    [file, filters.resolutions],
                )}
                {useMemo(
                    () => (
                        <TableCell {...greyTdProps}>
                            <FileHighlightFilterText
                                filters={filters.aspectRatios}
                                text={file.tags.aspectRatio}
                            />
                        </TableCell>
                    ),
                    [file.tags.aspectRatio, filters.aspectRatios],
                )}
                {/* <TableCell {...greyTdProps}>{formatBytes(file.size)}</TableCell> */}
                {useMemo(
                    () => (
                        <TableCell {...greyTdProps}>
                            {formatDateString({ date: file.liveDate })}
                        </TableCell>
                    ),
                    [file.liveDate, formatDateString],
                )}
                {useMemo(
                    () => (
                        <TableCell {...greyTdProps}>
                            {formatDateString({ date: file.endDate })}
                        </TableCell>
                    ),
                    [file.endDate, formatDateString],
                )}
                {useMemo(
                    () => (
                        <TableCell {...greyTdProps}>
                            {formatDateString({ date: file.createdAt, removeTime: false })}
                        </TableCell>
                    ),
                    [file.createdAt, formatDateString],
                )}
                {useMemo(
                    () => (
                        <TableCell {...greyTdProps}>
                            <Box textAlign="center">
                                {file.assignedDisplays === 0 ? (
                                    file.assignedDisplays
                                ) : (
                                    <Button variant="text" onClick={unAssignCreative}>
                                        {file.assignedDisplays}
                                    </Button>
                                )}
                            </Box>
                        </TableCell>
                    ),
                    [file.assignedDisplays, unAssignCreative],
                )}
                {useMemo(
                    () => (
                        <TableCell {...greyTdProps}>
                            <AutoGrid spacing={1} flexWrap="nowrap">
                                <Tooltip
                                    title={t(
                                        'Modules.Main.CreativeManagement.creatives.table.downloadAction',
                                    )}
                                >
                                    <LinkButton
                                        iconChild
                                        size="small"
                                        target="_blank"
                                        to={downloadLink}
                                        onClick={onClickStopPropagation}
                                        className="download-creative"
                                    >
                                        {downloadIcon}
                                    </LinkButton>
                                </Tooltip>

                                {file.downloadId != null && (
                                    <IfHasAllPermissions permissions={DeleteCreativesPerms}>
                                        <Tooltip
                                            title={t(
                                                `Modules.Main.CreativeManagement.creatives.table.${
                                                    deletable
                                                        ? 'deleteAction'
                                                        : 'deleteNoPermission'
                                                }`,
                                            )}
                                        >
                                            <Box>
                                                <Button
                                                    disabled={!deletable}
                                                    iconChild
                                                    size="small"
                                                    onClick={onDelete}
                                                    className="delete-creative"
                                                >
                                                    {deleteIcon}
                                                </Button>
                                            </Box>
                                        </Tooltip>
                                    </IfHasAllPermissions>
                                )}
                                <IfHasAllPermissions permissions={AssignPerms}>
                                    <Tooltip
                                        title={t(
                                            'Modules.Main.CreativeManagement.creatives.table.assignAction',
                                        )}
                                    >
                                        <Button
                                            iconChild
                                            size="small"
                                            onClick={assignCreative}
                                            className="assign-creative"
                                        >
                                            <PlaylistAddCheck fontSize="inherit" />
                                        </Button>
                                    </Tooltip>
                                </IfHasAllPermissions>
                            </AutoGrid>
                        </TableCell>
                    ),
                    [assignCreative, deletable, downloadLink, file.downloadId, onDelete, t],
                )}
            </TableRow>
        </>
    );
};

export default CreativeListItem;
