import React, { PropsWithChildren, useMemo } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import ContentLayout from 'c-wrapper/Components/ContentLayout';
import PageLayout, { PageLayoutProps } from 'c-wrapper/Components/PageLayout/PageLayout';
import TopAppBar from 'c-wrapper/Components/TopAppBar';
import { uiSelectors } from 'c-wrapper/Slices/UI';
import { useIsMobileView } from 'c-hooks';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
    contentBox: {
        transition: theme.transitions.create('padding', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));

export const PostAuthContentLayout: React.FC<PropsWithChildren> = ({ children }) => {
    const isOpen = useSelector(uiSelectors.isDrawerOpen);
    const classes = useStyles();
    const isMobileView = useIsMobileView();
    const disableDesktopScrolling = useSelector(uiSelectors.getDisableDesktopContentScrolling);

    const isMobile = useIsMobileView();
    const shouldDisableContentScrolling = useMemo(
        () => !isMobile && disableDesktopScrolling,
        [isMobile, disableDesktopScrolling],
    );
    const contentLayoutOverflow = useMemo(() => {
        if (isMobile) return undefined;

        if (!isMobile && !shouldDisableContentScrolling) return 'auto';

        return 'hidden';
    }, [isMobile, shouldDisableContentScrolling]);

    const innerContentLayoutProps = useMemo<BoxProps>(
        () => ({ overflow: shouldDisableContentScrolling ? 'hidden' : undefined }),
        [shouldDisableContentScrolling],
    );

    const paddingLeft = useMemo(() => {
        if (isMobileView) {
            return 0;
        }
        return isOpen ? 30 : 9;
    }, [isMobileView, isOpen]);

    const theme = useTheme();
    const width = useMemo(() => {
        if (isMobileView) {
            return '100%';
        }

        return `calc(100% - ${theme.spacing(paddingLeft)})`;
    }, [isMobileView, paddingLeft, theme]);

    const containerSx = useMemo(
        () => ({
            transition: theme.transitions.create(
                ['margin', 'margin-right', 'margin-left', 'width'],
                {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                },
            ),
        }),
        [theme],
    );

    const pageStatus = useSelector(uiSelectors.pageStatus);

    const pageStatusElement = useMemo(() => {
        if (pageStatus) {
            return (
                <Box
                    position="sticky"
                    top={0}
                    right={0}
                    id="page-status"
                    zIndex={10}
                    width="100%"
                    {...pageStatus.statusBoxProps}
                >
                    <Typography variant="body1" {...pageStatus.statusTypographyProps}>
                        {pageStatus.status}
                    </Typography>
                </Box>
            );
        }

        return null;
    }, [pageStatus]);

    return (
        <ContentLayout
            pb={{ xs: 0, sm: 0 }}
            // sx={{ bgcolor: 'custom.contentBgColor' }}
            id="app-content-container"
            overflow={contentLayoutOverflow}
            ml={paddingLeft}
            width={width}
            sx={containerSx}
            InnerBoxProps={innerContentLayoutProps}
        >
            {pageStatusElement}
            <Box
                flex={1}
                display="flex"
                flexDirection="column"
                className={cx(classes.contentBox, 'postauth-layout-box-inner')}
                px={isMobileView ? 2 : 4}
                alignSelf="normal"
                pt={!isMobileView ? 3 : 2}
                overflow={shouldDisableContentScrolling ? 'hidden' : undefined}
            >
                {children}
            </Box>
        </ContentLayout>
    );
};

const PostAuthLayout = (props: PageLayoutProps) => (
    <PageLayout headerComponent={TopAppBar} contentComponent={PostAuthContentLayout} {...props} />
);

export default PostAuthLayout;
