import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import {
    FileBrowserFile,
    FileBrowserOrderBy,
    FileBrowserOrderByDirection,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from 'c-components';
import to from 'await-to-js';
import { useCommonTranslation } from 'c-translation';
import {
    Box,
    IconButton,
    IconButtonProps,
    TableContainer,
    TableSortLabel,
    Tooltip,
    TypographyProps,
} from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { useAutoResetBoolean } from 'c-hooks';
import { copyTextToClipboard } from 'c-lib';

type Props = {
    id: string;
    files: FileBrowserFile[];
    selectedCreatives: number[];
    selectAllFiles: React.ReactNode;
    rows: React.ReactNode;
    orderByField: FileBrowserOrderBy;
    direction: FileBrowserOrderByDirection;
    onOrderBy: (field: FileBrowserOrderBy, direction: FileBrowserOrderByDirection) => void;

    hideSelectColumn?: boolean;
    hideGroupNames?: boolean;
    hideLiveDate?: boolean;
    hideEndDate?: boolean;
    hideActions?: boolean;
};

const tdProps = {
    dense: true,
    isHeader: true,
    sx: { px: 1 },
    typographyProps: { variant: 'body2', color: 'grey.700' } as TypographyProps,
};
const selectAllTdProps = {
    ...tdProps,
    sx: { px: 0 },
};
const nameTdProps = {
    ...tdProps,
    sx: { ...tdProps.sx, pl: 0 },
};

const CreativesTable: React.FC<Props> = ({
    id,
    files,
    selectedCreatives,
    selectAllFiles,
    rows,
    orderByField,
    direction,
    onOrderBy,

    hideSelectColumn = false,
    hideGroupNames = false,
    hideLiveDate = false,
    hideEndDate = false,
    hideActions = false,
}) => {
    const orderByClicked = useCallback(
        (field: FileBrowserOrderBy) => {
            let newDirection: FileBrowserOrderByDirection;

            let flip = false;
            // if same field, flip the ordering direction
            if (orderByField === field) {
                flip = true;
            }

            if (flip) {
                newDirection = direction === 'asc' ? 'desc' : 'asc';
            } else {
                newDirection = 'asc';
            }

            // if switching fields order descending
            if (orderByField !== field) {
                newDirection = 'desc';
            }

            onOrderBy(field, newDirection);
        },
        [direction, onOrderBy, orderByField],
    );

    const {
        value: FailedCopy,
        setFalse: ResetFailedCopy,
        setTrue: SetFailedCopy,
    } = useAutoResetBoolean(false, 5000);
    const { value: Copied, setTrue: SetHasCopied } = useAutoResetBoolean(false, 5000);
    const t = useCommonTranslation();

    const copySelectedCreatives = useCallback(async () => {
        const [err] = await to(
            copyTextToClipboard(
                files
                    .filter(f => selectedCreatives.indexOf(f.downloadId) !== -1)
                    .map(file => `${file.name} - ${file.tags.resolution}`)
                    .join('\n'),
            ),
        );

        if (!err) {
            SetHasCopied();
            ResetFailedCopy();
        } else {
            SetFailedCopy();
        }
    }, [files, selectedCreatives, SetHasCopied, ResetFailedCopy, SetFailedCopy]);

    const copyBtn = useMemo(() => {
        let btnLabel = t('Modules.Main.CreativeManagement.creatives.table.copyBtnNoSelected');
        let btnColor: IconButtonProps['color'] =
            selectedCreatives.length > 0 ? 'primary' : undefined;

        if (selectedCreatives.length > 0)
            btnLabel = t('Modules.Main.CreativeManagement.creatives.table.copyBtnDefault');
        if (FailedCopy) {
            btnLabel = t('Modules.Main.CreativeManagement.creatives.table.copyBtnFailed');
            btnColor = 'error';
        } else if (Copied)
            btnLabel = t('Modules.Main.CreativeManagement.creatives.table.copyBtnCopied');

        return (
            <Tooltip title={btnLabel}>
                <Box>
                    <IconButton
                        size="small"
                        sx={{ mx: 1 }}
                        onClick={copySelectedCreatives}
                        disabled={selectedCreatives.length === 0}
                        color={btnColor}
                    >
                        <ContentCopy fontSize="inherit" />
                    </IconButton>
                </Box>
            </Tooltip>
        );
    }, [t, FailedCopy, Copied, selectedCreatives.length, copySelectedCreatives]);

    return (
        <TableContainer>
            <Table stickyHeader spacedOutRows id={id}>
                <TableHead>
                    <TableRow isHeader>
                        {!hideSelectColumn && (
                            <TableCell {...selectAllTdProps}>
                                <Box pl={1.5}>{selectAllFiles}</Box>
                            </TableCell>
                        )}
                        <TableCell {...nameTdProps}>
                            <Box alignItems="center" display="flex">
                                {copyBtn}
                                <OrderableTableHeader
                                    onClick={orderByClicked}
                                    direction={direction}
                                    active={orderByField === FileBrowserOrderBy.Name}
                                    field={FileBrowserOrderBy.Name}
                                >
                                    {t('Modules.Main.CreativeManagement.creatives.table.nameCol')}
                                </OrderableTableHeader>
                            </Box>
                        </TableCell>
                        {!hideGroupNames && (
                            <TableCell {...tdProps}>
                                {t('Modules.Main.CreativeManagement.creatives.table.groupCol')}
                            </TableCell>
                        )}
                        <TableCell {...tdProps}>
                            {t('Modules.Main.CreativeManagement.creatives.table.typeCol')}
                        </TableCell>
                        <TableCell {...tdProps}>
                            {t('Modules.Main.CreativeManagement.creatives.table.durationCol')}
                        </TableCell>
                        <TableCell {...tdProps}>
                            {t('Modules.Main.CreativeManagement.creatives.table.resCol')}
                        </TableCell>
                        <TableCell {...tdProps}>
                            <OrderableTableHeader
                                onClick={orderByClicked}
                                direction={direction}
                                active={orderByField === FileBrowserOrderBy.AspectRatio}
                                field={FileBrowserOrderBy.AspectRatio}
                            >
                                {t(
                                    'Modules.Main.CreativeManagement.creatives.table.aspectRatioCol',
                                )}
                            </OrderableTableHeader>
                        </TableCell>
                        {/* <TableCell {...tdProps}> */}
                        {/*    <OrderableTableHeader */}
                        {/*        onClick={orderByClicked} */}
                        {/*        direction={direction} */}
                        {/*        active={orderByField === FileBrowserOrderBy.FileSize} */}
                        {/*        field={FileBrowserOrderBy.FileSize} */}
                        {/*    > */}
                        {/*        {t( */}
                        {/*            'Modules.Main.CreativeManagement.creatives.table.fileSizeCol', */}
                        {/*        )} */}
                        {/*    </OrderableTableHeader> */}
                        {/* </TableCell> */}
                        {!hideLiveDate && (
                            <TableCell {...tdProps}>
                                <OrderableTableHeader
                                    onClick={orderByClicked}
                                    direction={direction}
                                    active={orderByField === FileBrowserOrderBy.LiveDate}
                                    field={FileBrowserOrderBy.LiveDate}
                                >
                                    {t(
                                        'Modules.Main.CreativeManagement.creatives.table.liveDataCol',
                                    )}
                                </OrderableTableHeader>
                            </TableCell>
                        )}
                        {!hideEndDate && (
                            <TableCell {...tdProps}>
                                <OrderableTableHeader
                                    onClick={orderByClicked}
                                    direction={direction}
                                    active={orderByField === FileBrowserOrderBy.EndDate}
                                    field={FileBrowserOrderBy.EndDate}
                                >
                                    {t(
                                        'Modules.Main.CreativeManagement.creatives.table.endDataCol',
                                    )}
                                </OrderableTableHeader>
                            </TableCell>
                        )}
                        <TableCell {...tdProps}>
                            <OrderableTableHeader
                                onClick={orderByClicked}
                                direction={direction}
                                active={orderByField === FileBrowserOrderBy.UploadDate}
                                field={FileBrowserOrderBy.UploadDate}
                            >
                                {t('Modules.Main.CreativeManagement.creatives.table.uploadDateCol')}
                            </OrderableTableHeader>
                        </TableCell>
                        <TableCell {...tdProps}>
                            <OrderableTableHeader
                                onClick={orderByClicked}
                                direction={direction}
                                active={orderByField === FileBrowserOrderBy.AssignedDisplays}
                                field={FileBrowserOrderBy.AssignedDisplays}
                            >
                                {t('Modules.Main.CreativeManagement.creatives.table.assignedCol')}
                            </OrderableTableHeader>
                        </TableCell>
                        {!hideActions && (
                            <TableCell {...tdProps}>
                                {t('Modules.Main.CreativeManagement.creatives.table.actionsCol')}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
        </TableContainer>
    );
};

type HeaderProps = {
    field: FileBrowserOrderBy;
    active: boolean;
    direction: FileBrowserOrderByDirection;
    onClick: (field: FileBrowserOrderBy) => void;
};

const OrderableTableHeader: React.FC<PropsWithChildren<HeaderProps>> = ({
    field,
    active,
    direction,
    onClick,
    children,
}) => {
    const orderByClicked = useCallback(() => onClick(field), [onClick, field]);
    return (
        <TableSortLabel direction={direction} active={active} onClick={orderByClicked}>
            {children}
        </TableSortLabel>
    );
};

export default CreativesTable;
