import {
    buyerActions,
    buyerAdapter,
    buyerThunks as defaultThunks,
    buyerSelectors as defaultSelectors,
    buyerReducer,
} from './Buyer-slice';
import * as thunks from './Buyer-thunks';
import * as selectors from './Buyer-selectors';

const buyerThunks = { ...defaultThunks, ...thunks };
const buyerSelectors = { ...defaultSelectors, ...selectors };

export { default as BuyerSlice } from './Buyer-slice';
export { buyerSelectors, buyerActions, buyerAdapter, buyerThunks, buyerReducer };
