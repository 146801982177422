import React, { useMemo } from 'react';
import {
    AutoGrid,
    Checkbox,
    ControlledFormInput,
    OptionSchema,
    RadioGroupField,
    TextField,
} from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { Grid, Typography } from '@mui/material';
import { ReportType } from 'c-reports/Types';
import { CategoricalChartFormDataSchema, ChartLabelAnchor } from '../types';

type Props = {
    type: ReportType;
};

const ChartExportFormCategorical: React.FC<Props> = props => {
    const t = useCommonTranslation();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography variant="subtitle2">
                    {t('Reporting.exporting.form.marginHeader')}
                </Typography>
                <MarginInputs />
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={10}>
                <XAxisInputs {...props} />
            </Grid>
        </Grid>
    );
};

const MarginInputs = () => {
    const t = useCommonTranslation();
    return (
        <AutoGrid spacing={1} xs={6} sm={3}>
            <ControlledFormInput<CategoricalChartFormDataSchema>
                name="chartProps.margin.top"
                render={({ field }) => (
                    <TextField
                        {...field}
                        variant="standard"
                        type="number"
                        label={t('Reporting.exporting.form.marginTopLabel')}
                    />
                )}
            />
            <ControlledFormInput<CategoricalChartFormDataSchema>
                name="chartProps.margin.right"
                render={({ field }) => (
                    <TextField
                        {...field}
                        variant="standard"
                        type="number"
                        label={t('Reporting.exporting.form.marginRightLabel')}
                    />
                )}
            />
            <ControlledFormInput<CategoricalChartFormDataSchema>
                name="chartProps.margin.bottom"
                render={({ field }) => (
                    <TextField
                        {...field}
                        variant="standard"
                        type="number"
                        label={t('Reporting.exporting.form.marginBottomLabel')}
                    />
                )}
            />
            <ControlledFormInput<CategoricalChartFormDataSchema>
                name="chartProps.margin.left"
                render={({ field }) => (
                    <TextField
                        {...field}
                        variant="standard"
                        type="number"
                        label={t('Reporting.exporting.form.marginLeftLabel')}
                    />
                )}
            />
        </AutoGrid>
    );
};

const XAxisInputs: React.FC<Props> = ({ type }) => {
    const t = useCommonTranslation();

    const anchorOpts = useMemo<OptionSchema[]>(
        () => [
            {
                label: t(`Reporting.exporting.form.textAnchor.${ChartLabelAnchor.Start}`),
                value: ChartLabelAnchor.Start,
            },
            {
                label: t(`Reporting.exporting.form.textAnchor.${ChartLabelAnchor.Middle}`),
                value: ChartLabelAnchor.Middle,
            },
            {
                label: t(`Reporting.exporting.form.textAnchor.${ChartLabelAnchor.End}`),
                value: ChartLabelAnchor.End,
            },
        ],
        [t],
    );
    return (
        <>
            <Typography variant="subtitle2">{t('Reporting.exporting.form.xAxisHeader')}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                    <ControlledFormInput<CategoricalChartFormDataSchema>
                        name="xAxisProps.angle"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                variant="standard"
                                type="number"
                                label={t('Reporting.exporting.form.xAxisAngleLabel')}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                    <ControlledFormInput<CategoricalChartFormDataSchema>
                        name="xAxisProps.showAllLabels"
                        render={({ field }) => (
                            <Checkbox
                                {...field}
                                isBoolean
                                label={t('Reporting.exporting.form.xAxisAllLabels')}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <ControlledFormInput<CategoricalChartFormDataSchema>
                        name="xAxisProps.textAnchor"
                        render={({ field }) => (
                            <RadioGroupField
                                {...field}
                                row
                                options={anchorOpts}
                                label={t('Reporting.exporting.form.xAxisTextAnchor')}
                            />
                        )}
                    />
                </Grid>

                {type === ReportType.Line && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <ControlledFormInput<CategoricalChartFormDataSchema>
                            name="xAxisProps.dateFormat"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant="standard"
                                    label={t('Reporting.exporting.form.xAxisDateFormat')}
                                    helperText={
                                        <a
                                            href="https://date-fns.org/v2.28.0/docs/parse"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            {t('Reporting.exporting.form.xAxisDateFormatDocs')}
                                        </a>
                                    }
                                />
                            )}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default ChartExportFormCategorical;
