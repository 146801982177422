import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Alert,
    Avatar,
    Button,
    Dialog,
    FileUploadDropzone,
    FileUploadListItem,
    ListItemText,
    TextAreaField,
} from 'c-components';
import {
    Box,
    CircularProgress,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    Stack,
    Tooltip,
} from '@mui/material';
import { User } from 'c-entity-types';
import { Delete, Description, WarningAmber } from '@mui/icons-material';
import { useAPIClientRequest, useDateUtils, useEntityField } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { useEntityData } from 'c-data';
import { omit } from 'ramda';
import { usePrevious } from 'react-hanger';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { getCampaignBookingSummaries } from 'c-main/Lib/Campaign';
import { CampaignBookingSummary, PermissionName, Campaign } from '@uniled/api-sdk';
import { useCommonTranslation } from 'c-translation';
import { useUserPermissions } from 'c-auth-module/Hooks';

const uploadPermissions = [PermissionName.UniledportalCampaign_booking_summariesCreate];
const readPermissions = [PermissionName.UniledportalCampaign_booking_summariesRead];
const deletePermissions = [PermissionName.UniledportalCampaign_booking_summariesDestroy];
const readBookingSummaryNotesPermissions = [
    PermissionName.UniledportalCampaign_booking_summariesNotesRead,
];
const updateBookingSummaryNotesPermissions = [
    PermissionName.UniledPortalCampaign_booking_summariesNotesUpdate,
];

type Props = {
    open: boolean;
    onClose: () => void;
    campaign: Campaign;
};

const isFilenameValid = filename => {
    const disallowedCharsRegex = /[\\/:*?"<>|~]/;
    return !disallowedCharsRegex.test(filename);
};

const CampaignBookingSummaryDialog: React.FC<Props> = ({ campaign, open, onClose }) => {
    const [uploadFiles, setUploadFiles] = useState<Record<string, File>>({});
    const [error, setError] = useState<string | null>(null);
    const [text, setText] = useState(campaign.text);
    const prevOpen = usePrevious(open);
    const {
        start,
        isLoading,
        error: err,
    } = useAPIClientRequest(apiClient.Entities.Campaign.update);

    useEffect(() => {
        if (!open && prevOpen) {
            setUploadFiles({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const onFilesAdded = useCallback((files: File[]) => {
        setError(null);
        const validFiles = files.filter(file => isFilenameValid(file.name));
        const invalidFiles = files.length - validFiles.length;

        if (invalidFiles > 0) {
            setError(
                `${invalidFiles} file(s) were not added due to invalid characters in their names.   \n Invalid characters are: '\\', '/', ':', '*', '?', '"', '<', '>', '|', '~'`,
            );
        }
        setUploadFiles(val => {
            const newFiles: Record<string, File> = {};

            validFiles.forEach(file => {
                let uniqueId = null;
                while (uniqueId == null) {
                    const newId = String(Math.random());
                    if (val[newId] == null && newFiles[newId] == null) uniqueId = newId;
                }

                newFiles[uniqueId] = file;
            });

            return { ...val, ...newFiles };
        });
    }, []);

    const onFileUploaded = useCallback((id: string) => {
        setUploadFiles(curr => omit([id], curr));
    }, []);

    const handleTextChange = useCallback(
        e => {
            setText(e.target.value);
        },
        [setText],
    );

    const summaries = useMemo(() => getCampaignBookingSummaries(campaign), [campaign]);
    const handleUpdateCampaignText = useCallback(async () => {
        const [err, success] = await to(start(campaign.id, { text }));
    }, [campaign, text, start]);
    const t = useCommonTranslation();
    const { hasAll } = useUserPermissions();
    return (
        <Dialog
            removeForm
            onClose={onClose}
            show={open}
            title="Modules.Main.Campaigns.Overview.BookingSummary.title"
            content={
                <>
                    <IfHasAllPermissions permissions={uploadPermissions}>
                        <>
                            <FileUploadDropzone
                                fileAccept=""
                                onFilesAdded={onFilesAdded}
                                dropzoneLabel="Modules.Main.Campaigns.Overview.BookingSummary.dropzoneText"
                            />
                            <Box mt={2}>
                                <List disablePadding>
                                    {Object.entries(uploadFiles).map(([id, file]) => (
                                        <BookingSummaryFileUpload
                                            key={id}
                                            id={id}
                                            file={file}
                                            campaignId={campaign.id}
                                            onFileUploaded={onFileUploaded}
                                        />
                                    ))}
                                </List>
                            </Box>
                        </>
                    </IfHasAllPermissions>
                    <IfHasAllPermissions permissions={readPermissions}>
                        <Box mt={2}>
                            <List disablePadding>
                                {summaries?.map(sum => (
                                    <BookingSummaryFile key={sum.id} summary={sum} />
                                ))}
                            </List>
                        </Box>
                    </IfHasAllPermissions>
                    <IfHasAllPermissions permissions={readBookingSummaryNotesPermissions}>
                        <Box mt={2} position="relative">
                            <Stack gap={1}>
                                <TextAreaField
                                    value={text}
                                    onChange={handleTextChange}
                                    disabled={
                                        isLoading || !hasAll(updateBookingSummaryNotesPermissions)
                                    }
                                />
                                {err && <Alert severity="error">{err}</Alert>}
                                <IfHasAllPermissions
                                    permissions={updateBookingSummaryNotesPermissions}
                                >
                                    <Button onClick={handleUpdateCampaignText} disabled={isLoading}>
                                        {t('Modules.Main.Campaigns.Overview.BookingSummary.save')}
                                    </Button>
                                </IfHasAllPermissions>
                            </Stack>
                            {isLoading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: 1,
                                    }}
                                />
                            )}
                        </Box>
                    </IfHasAllPermissions>
                    {error && <Alert severity="error">{error}</Alert>}
                </>
            }
        />
    );
};

type UploadProps = {
    id: string;
    file: File;
    campaignId: number;
    onFileUploaded: (id: string) => void;
};

const BookingSummaryFileUpload: React.FC<UploadProps> = ({
    id,
    file,
    campaignId,
    onFileUploaded,
}) => {
    const { start, requestState, error } = useAPIClientRequest(
        apiClient.Entities.Campaign.uploadBookingSummary,
    );
    const { upsertEntity } = useEntityData<Campaign>('Campaign');

    const uploadFile = useCallback(async () => {
        const formData = new FormData();
        formData.set('file', file);
        const [err, success] = await to(start(campaignId, { formData }, ['bookingSummaries']));

        if (!err && success?.data?.data?.id === campaignId) {
            upsertEntity(success.data.data as unknown as Campaign);
            onFileUploaded(id);
        }
    }, [campaignId, file, onFileUploaded, start, upsertEntity, id]);

    useEffect(() => {
        uploadFile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <FileUploadListItem file={file} error={error} requestState={requestState} />;
};

type ItemProps = {
    summary: CampaignBookingSummary;
};

const BookingSummaryFile: React.FC<ItemProps> = ({ summary }) => {
    const { name, path, created_at, user_id } = summary;
    const { text } = useEntityField<User>(user_id, 'User', 'name');
    const { formatDateString } = useDateUtils();
    const secondaryData = useMemo(() => {
        if (created_at == null) {
            // this is an old booking summary which was migrated over before the dedicated table
            return undefined;
        }

        return (
            <>
                {formatDateString({ date: created_at, removeTime: false })} | {text}
            </>
        );
    }, [text, created_at, formatDateString]);

    return (
        <ListItem>
            <ListItemButton component={Link} href={path} target="_blank">
                <ListItemAvatar sx={{ minWidth: 45 }}>
                    <Avatar sx={{ height: 35, width: 35 }}>
                        <Description fontSize="inherit" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={name} secondary={secondaryData} />
            </ListItemButton>
            <IfHasAllPermissions permissions={deletePermissions}>
                <DeleteSummaryNew summary={summary} />
            </IfHasAllPermissions>
        </ListItem>
    );
};

const DeleteSummaryNew: React.FC<ItemProps> = ({ summary }) => {
    const { upsertEntity } = useEntityData<Campaign>('Campaign');
    const { start, isLoading, error, hasFailed } = useAPIClientRequest(
        apiClient.Entities.Campaign.deleteBookingSummary,
    );
    const deleteSummary = useCallback(async () => {
        const [err, success] = await to(
            start(summary.campaign_id, summary.id, ['bookingSummaries']),
        );

        if (!err && success?.data?.data?.id === summary.campaign_id) {
            upsertEntity(success.data.data as unknown as Campaign);
        }
    }, [summary, start, upsertEntity]);

    return (
        <DeleteButton
            onClick={deleteSummary}
            loading={isLoading}
            error={error}
            failed={hasFailed}
        />
    );
};

type DeleteButtonProps = {
    onClick: () => void;
    loading: boolean;
    failed: boolean;
    error?: string;
};
const DeleteButton: React.FC<DeleteButtonProps> = ({ loading, onClick, failed, error }) => {
    const icon = useMemo(() => {
        if (failed)
            return (
                <Tooltip title={String(error ?? '')}>
                    <WarningAmber fontSize="inherit" />
                </Tooltip>
            );
        if (loading) return <CircularProgress size={15} />;

        return <Delete fontSize="inherit" />;
    }, [error, failed, loading]);

    return (
        <IconButton disabled={loading} onClick={onClick}>
            {icon}
        </IconButton>
    );
};

export default CampaignBookingSummaryDialog;
