import React, { useMemo } from 'react';
import { Typography, TypographyProps } from '@mui/material';

const defaultWidth = 250;
const fileNameTypographyProps: TypographyProps<any> = {
    variant: 'body2',
    sx: {
        wordBreak: 'break-all',
        display: '-webkit-box',
        width: defaultWidth,
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        whiteSpace: 'pre-line',
    },
    component: 'div',
    fontWeight: 'bold',
};

const maxFileNameLength = 74;
const defaultCutOffString = '[...].';

type Props = { name: string; cutOffString?: string; maxNameLength?: number; width?: number };

const FileName: React.FC<Props> = ({
    name,
    cutOffString = defaultCutOffString,
    maxNameLength = maxFileNameLength,
    width = defaultWidth,
}) => {
    const fileName = useMemo(() => {
        const ext = name.split('.').pop();
        const extLen = ext?.length ?? 0;
        const nameWithoutExt = name.slice(0, name.length - extLen - 1);
        if (nameWithoutExt.length + extLen - cutOffString.length > maxNameLength) {
            return `${name.slice(0, maxNameLength - 1)}${cutOffString}${ext}`;
        }

        return name;
    }, [name, maxNameLength, cutOffString]);

    const typographyProps = useMemo<Partial<TypographyProps>>(
        () => ({
            ...fileNameTypographyProps,
            sx: { ...fileNameTypographyProps.sx, width },
        }),
        [width],
    );
    return <Typography {...typographyProps}>{fileName}</Typography>;
};

export default FileName;
