import React, { useEffect, useMemo } from 'react';
import { FilterableEntityTable } from 'c-pagination';
import { simpleCampaignEntityTableProps, userEntityTableProps } from 'c-main/entityColumns';
import { campaignThunks } from 'c-main/Slices';
import { useDispatch } from 'react-redux';
import { TabsWrapper } from 'c-components';
import { userThunks } from 'c-admin/Slices';
import { TabWrapperSettings } from 'c-types';
import BuyerCostsTabContent from './BuyerCostsTabContent';

type Props = {
    id: number;
};

const BuyerOverviewDetailTabs: React.FC<Props> = ({ id }) => {
    const dispatch = useDispatch();
    const campaignsPaginationTag = useMemo(() => `buyer-campaigns-${id}`, [id]);
    const usersPaginationTag = useMemo(() => `buyer-users-${id}`, [id]);

    useEffect(
        () => () => {
            dispatch(campaignThunks.resetPaginatedList(campaignsPaginationTag));
            dispatch(userThunks.resetPaginatedList(usersPaginationTag));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const tabs = useMemo<TabWrapperSettings['tabs']>(
        () => [
            {
                title: 'Modules.Main.Buyer.Overview.Tabs.campaigns',
                content: (
                    <FilterableEntityTable
                        key={campaignsPaginationTag}
                        tag={campaignsPaginationTag}
                        filters={{ 'filter.buyer.id': [id] }}
                        resetOnUnmount={false}
                        refreshOnMount={false}
                        {...simpleCampaignEntityTableProps}
                    />
                ),
            },
            {
                title: 'Modules.Main.Buyer.Overview.Tabs.users',
                content: (
                    <FilterableEntityTable
                        key={usersPaginationTag}
                        tag={usersPaginationTag}
                        filters={{ 'filter.buyer.id': [id], 'filter.buyers.id': [id] }}
                        resetOnUnmount={false}
                        refreshOnMount={false}
                        {...userEntityTableProps}
                    />
                ),
            },
            {
                title: 'Modules.Main.Buyer.Overview.Tabs.costs',
                content: <BuyerCostsTabContent />,
            },
        ],
        [campaignsPaginationTag, usersPaginationTag, id],
    );

    return (
        <>
            <TabsWrapper tabs={tabs} />
        </>
    );
};

export default BuyerOverviewDetailTabs;
