import React, { useCallback, useRef, useState } from 'react';
import { Button, DateRangePicker } from 'c-components';
import { Menu, useMediaQuery } from '@mui/material';
import { isSameDay } from 'date-fns';
import { useTheme } from '@mui/styles';
import { useBoolean } from 'react-hanger';

type Props = {
    dateRangeLabel: React.ReactNode;
    startDate: Date;
    endDate: Date;
    onChange: (start: Date, end: Date) => void;
    minDate: Date;
    maxDate: Date;
};

const DateRangeDropdown: React.FC<Props> = ({
    dateRangeLabel,
    startDate,
    endDate,
    onChange,
    minDate,
    maxDate,
}) => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const anchorEl = useRef();
    const menuOpen = useBoolean(false);
    const [localDates, setLocalDates] = useState<[Date, Date]>([startDate, endDate]);

    const onBtnClick = useCallback(() => {
        setLocalDates([startDate, endDate]);
        menuOpen.setTrue();
    }, [startDate, endDate, menuOpen]);

    const onDateRangeChange = useCallback(
        (start: Date, end: Date) => {
            setLocalDates([start, end]);

            if (start && end && !isSameDay(start, end)) {
                onChange(start, end);
                menuOpen.setFalse();
            }
        },
        [onChange, menuOpen],
    );

    return (
        <>
            <Button variant="outlined" onClick={onBtnClick} ref={anchorEl}>
                {dateRangeLabel}
            </Button>

            <Menu anchorEl={anchorEl.current} open={menuOpen.value} onClose={menuOpen.setFalse}>
                <DateRangePicker
                    startDate={localDates[0]}
                    endDate={localDates[1]}
                    moveRangeOnFirstSelection={false}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={onDateRangeChange}
                    months={isMdDown ? 1 : 2}
                />
            </Menu>
        </>
    );
};

export default DateRangeDropdown;
