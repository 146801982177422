import { useCallback, useMemo } from 'react';
import { LANG_en_gb, LANG_en_us } from 'c-lib';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import { User } from '@uniled/api-sdk';
import { useEntityData } from 'c-data';
import useAuthenticatedUser from './useAuthenticatedUser';
import useAPIClientRequest from './useAPIClientRequest';

function useLanguageSwitcher() {
    const { user } = useAuthenticatedUser();

    const lang = useMemo(() => {
        if ([LANG_en_gb, LANG_en_us].indexOf(user?.locale) !== -1) return user.locale;

        return LANG_en_gb;
    }, [user]);

    const { start, isLoading, hasFailed, hasSucceeded, error, reset } = useAPIClientRequest(
        apiClient.Entities.User.updateMe,
    );
    const { upsertEntity } = useEntityData<User>('User');
    const switchLanguage = useCallback(
        async (newLang: string) => {
            if (lang === newLang || !user) return;

            const [err, success] = await to(start({ id: user.id, locale: newLang } as any));

            if (!err && success?.data?.data?.id === user.id) {
                upsertEntity(success.data.data);
            }
        },
        [start, upsertEntity, user, lang],
    );

    return {
        lang,
        switchLanguage,
        isSwitching: isLoading,
        hasFailedSwitch: hasFailed,
        hasSucceededSwitch: hasSucceeded,
        failedSwitchError: String(error),
        resetSwitch: reset,
    };
}

export default useLanguageSwitcher;
