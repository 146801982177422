/**
 *
 * @param resolutions - e.g. ['1024x288', '480x720']
 */
const sortResolutions = (resolutions: string[]): string[] =>
    resolutions.sort((a, b) => {
        const aWidth = Number(a.split('x')[0]);
        const bWidth = Number(b.split('x')[0]);

        if (aWidth > bWidth) return 1;
        if (aWidth < bWidth) return -1;

        return 0;
    });
export default sortResolutions;
