import React, { forwardRef, Ref, useCallback, useEffect, useState } from 'react';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import TextField, { CustomTextFieldProps } from './TextField';

type Props = {
    debounceMs?: number;
    onChange: (value: string) => void;
} & Omit<CustomTextFieldProps, 'onChange'>;

const DebouncedTextField = (
    { debounceMs = 200, onChange, value, ...props }: Props,
    ref: Ref<any>,
) => {
    const [currentValue, setCurrentValue] = useState(value);

    const onValueChange = useCallback<MuiTextFieldProps['onChange']>(
        e => {
            setCurrentValue(e.target.value);
        },
        [setCurrentValue],
    );

    const debounced = useDebouncedCallback((callback: () => void) => {
        callback();
    }, 200);

    useEffect(() => {
        /**
         * Queue up a delayed (debounced) search when the filters are updated
         */
        const debouncedValue = String(currentValue);
        debounced(() => {
            onChange(debouncedValue);
        });
    }, [currentValue, onChange, debounced]);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    return (
        <TextField
            {...(props as MuiTextFieldProps)}
            onChange={onValueChange}
            value={currentValue}
            ref={ref}
        />
    );
};

export default forwardRef<any, Props>(DebouncedTextField);
