import React, { useEffect } from 'react';
import { CardContent } from '@mui/material';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { useCommonTranslation } from 'c-translation';
import { TransparentCard } from 'c-components';
import { useAuthenticatedUser } from 'c-hooks';
import UserEditPasswordWrapper from 'c-admin/Components/User/UserEditPassword';
import ReactGA from 'react-ga';

const MyProfilePage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const { user } = useAuthenticatedUser();
    return (
        <TransparentCard title={useCommonTranslation('Modules.Main.MyProfile.metaTitle')}>
            <CardContent>
                <UserEditPasswordWrapper id={user.id} />
            </CardContent>
        </TransparentCard>
    );
};

const setup: PageConfig = {
    id: 'MyProfilePage',
    component: MyProfilePage,
    systemSearch: {
        title: 'Modules.Main.MyProfile.metaTitle',
        description: 'Modules.Main.MyProfile.description',
        type: 'page',
        aliases: 'SystemSearch.aliases.myProfilePage',
        route: PostAuthRoutes.Users.MyProfile,
        saveAsRecentPage: true,
        breadcrumbs: [],
    },
};

export default setup;
