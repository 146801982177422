import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Fade, Modal } from '@mui/material';
import { useHotkeys } from 'react-hotkeys-hook';
import { useIsMobileView } from 'c-hooks';
import {
    SystemSearchSearch,
    systemSearchSelectors,
    systemSearchThunks,
} from '../../../Modules/SystemSearch';

const TopBarSystemSearch = () => {
    const dispatch = useDispatch();
    const isSystemSearchOpen = useSelector(systemSearchSelectors.isOpen);
    const inputRef = useRef<HTMLInputElement>();
    const inputFocused = useRef<boolean>(false);

    const openSpotlight = useCallback(
        (e: KeyboardEvent) => {
            e.stopPropagation();
            e.preventDefault();
            if (!isSystemSearchOpen) {
                // if you remove the timeout, the character generated
                // by the shortcut is added to  the spotlight search input.
                setTimeout(() => dispatch(systemSearchThunks.open()), 100);
            }
        },
        [dispatch, isSystemSearchOpen],
    );

    useEffect(() => {
        if (isSystemSearchOpen) {
            // we auto focus the input when we open the system search
            inputFocused.current = true;
        }

        const focus = () => {
            inputFocused.current = true;
        };
        const blur = () => {
            inputFocused.current = false;
        };

        let inpRef = inputRef?.current;

        setTimeout(() => {
            if (inputRef?.current) {
                inpRef = inputRef?.current;

                inpRef.addEventListener('focus', focus);
                inpRef.addEventListener('blur', blur);
            }
        }, 500);

        return () => {
            inpRef?.removeEventListener('focus', focus);
            inpRef?.removeEventListener('blur', blur);
        };
    }, [isSystemSearchOpen]);

    const closeSpotlight = useCallback(() => {
        /**
         * the selection check just checks that you're not in the middle of highlighting
         * the contents of the text input so you can clear it
         */

        setTimeout(() => {
            if (
                !inputFocused.current &&
                isSystemSearchOpen
                // inputRef?.current?.selectionStart == inputRef?.current?.selectionEnd
            ) {
                dispatch(systemSearchThunks.close());
            }
        }, 250);
    }, [dispatch, isSystemSearchOpen]);

    useHotkeys('ctrl+k,cmd+k', openSpotlight, [openSpotlight]);

    const isMobile = useIsMobileView();
    const showSystemSearch = useMemo(
        () => isSystemSearchOpen && !isMobile,
        [isSystemSearchOpen, isMobile],
    );

    return (
        <Modal
            open={showSystemSearch}
            disableAutoFocus
            disableEnforceFocus
            disableEscapeKeyDown
            disableRestoreFocus
        >
            <Fade in={showSystemSearch}>
                <Box
                    height="100%"
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    onClick={closeSpotlight}
                >
                    <Box width={600} mt={8}>
                        <SystemSearchSearch inputRef={inputRef} />
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default TopBarSystemSearch;
