import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NetworkRequestState } from '@uniled/data-layer';
import { UserType } from '@uniled/api-sdk';
import { usePostAuthHome } from 'c-wrapper/Components/CommonWrapper';
import ContentLayout from 'c-wrapper/Components/ContentLayout';
import { AutoGrid, Button, ControlledFormInput, MuiRouterLink, TextField } from 'c-components';
import { useIsMobileView } from 'c-hooks';
import { Box, InputAdornment, Typography } from '@mui/material';
import { Translate, useCommonTranslation } from 'c-translation';
import { InviteRoutes, PreAuthRoutes } from 'c-routes';
import { Lock, Person } from '@mui/icons-material';
import { authSelectors, authThunks } from '../../Slices';

const LoginForm = () => {
    const { control, handleSubmit, setValue, getValues } = useForm();
    const location = useLocation<{ from: { pathname: string } }>();
    const $history = useHistory();
    const dispatch = useDispatch();
    const home = usePostAuthHome();

    const $user = useSelector(authSelectors.authenticatedUser);
    const $loginState = useSelector(authSelectors.loginState);
    const availableUserTypes = useSelector(authSelectors.availableUserTypes);
    const isMobile = useIsMobileView();

    const onSubmit = useCallback(
        async ({ email, password }) => {
            await dispatch(authThunks.login({ email, password, isMobile }));
        },
        [dispatch, isMobile],
    );

    const onPickUserType = useCallback(
        (userType: UserType) => {
            const email = getValues('email');
            const password = getValues('password');
            dispatch(authThunks.login({ email, password, userType, isMobile }));
        },
        [dispatch, getValues, isMobile],
    );

    useEffect(() => {
        if ($user) {
            if (location?.state?.from) {
                // redirect to location they timed out from
                $history.replace(location.state.from);
                return;
            }
            const token = new URLSearchParams(window.location.search).get('token');
            // redirect to logged in accept invite page
            if (token != null && token?.trim()?.length > 0)
                $history.replace(generatePath(InviteRoutes.InviteToken, { token }));
            else $history.replace(home);
        }
    }, [$user, $history, location, location.state, home]);

    useEffect(() => {
        if ($loginState.state === NetworkRequestState.Error) {
            setValue('password', '');
        }
    }, [$loginState, setValue]);

    const emailLabel = useCommonTranslation('Pages.Login.emailInputLabel');
    const passwordLabel = useCommonTranslation('Pages.Login.passwordInputLabel');

    const showAvailableUserTypes = useMemo(() => availableUserTypes != null, [availableUserTypes]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ContentLayout.Form>
                <ContentLayout.FormFields>
                    <ControlledFormInput
                        defaultValue=""
                        control={control}
                        name="email"
                        render={({ field }) => (
                            <TextField
                                greyBackdrop
                                autoFocus
                                // label={<Translate path="Pages.Login.emailInputLabel" />}
                                placeholder={emailLabel}
                                required
                                type="email"
                                error={$loginState.state === NetworkRequestState.Error}
                                // helperText={ $loginState?.error ?? genericLoginError}
                                {...field}
                                disabled={showAvailableUserTypes}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Person />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <ControlledFormInput
                        defaultValue=""
                        control={control}
                        name="password"
                        render={({ field }) => (
                            <TextField
                                greyBackdrop
                                placeholder={passwordLabel}
                                required
                                type="password"
                                error={$loginState.state === NetworkRequestState.Error}
                                {...field}
                                disabled={showAvailableUserTypes}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Lock />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <Typography align="right" variant="body2">
                        <MuiRouterLink
                            to={PreAuthRoutes.RequestPasswordReset}
                            id="login-forgotten-password-link"
                        >
                            {useCommonTranslation('Pages.Login.forgotPasswordLinkLabel')}
                        </MuiRouterLink>
                    </Typography>
                </ContentLayout.FormFields>

                {showAvailableUserTypes && (
                    <Box mt={2}>
                        <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 1 }}>
                            <Translate path="Pages.Login.chooseUserType" />
                        </Typography>
                        <AutoGrid spacing={1}>
                            {availableUserTypes.map(type => (
                                <Button
                                    key={type}
                                    id={`login-submit-${type}`}
                                    disabled={$loginState.state === NetworkRequestState.InProgress}
                                    onClick={() => {
                                        onPickUserType(type);
                                    }}
                                >
                                    <Translate path={`Pages.Login.userTypes.${type}`} />
                                </Button>
                            ))}
                        </AutoGrid>
                    </Box>
                )}
                {!showAvailableUserTypes && (
                    <Box mt={6} display="flex" justifyContent="center">
                        <Button
                            id="login-submit"
                            type="submit"
                            disabled={$loginState.state === NetworkRequestState.InProgress}
                            size="large"
                        >
                            <Translate path="Pages.Login.signInButtonLabel" />
                        </Button>
                    </Box>
                )}
            </ContentLayout.Form>
        </form>
    );
};

export default LoginForm;
