import React, { useCallback } from 'react';
import { PopDisplays_VirtualCampaignSummary } from '@uniled/api-sdk';
import { Button, TableCell, TableRow } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { Box, Stack } from '@mui/material';
import { formatNumber } from 'c-lib';

type Props = {
    summary: PopDisplays_VirtualCampaignSummary;
    onSummaryOpen: (displayId: number) => void;
};

const VirtualCampaignSummaryRow: React.FC<Props> = ({ summary, onSummaryOpen }) => {
    const t = useCommonTranslation();
    const onSummaryBtn = useCallback(() => {
        onSummaryOpen(summary.pop_display_id);
    }, [summary, onSummaryOpen]);
    return (
        <>
            <TableRow>
                <TableCell>{summary.name}</TableCell>
                <TableCell>{summary.channel}</TableCell>
                <TableCell>{summary.frame}</TableCell>
                <TableCell>{summary.frame_id}</TableCell>
                <TableCell>{summary.slots}</TableCell>
                <TableCell>{summary.slots_duration}</TableCell>
                <TableCell>{summary.owner}</TableCell>
                <TableCell>{summary.source}</TableCell>
                <TableCell>
                    <Stack>
                        <Box>
                            {t('Modules.PopDisplays.VirtualCampaignBuilder.summary.table.count', {
                                count: formatNumber(summary.actual.count),
                            })}
                        </Box>
                        <Box>
                            {t('Modules.PopDisplays.VirtualCampaignBuilder.summary.table.secs', {
                                count: formatNumber(summary.actual.seconds),
                            })}
                        </Box>
                        <Box>
                            {t('Modules.PopDisplays.VirtualCampaignBuilder.summary.table.time', {
                                count: summary.actual.time,
                            })}
                        </Box>
                    </Stack>
                </TableCell>
                <TableCell>{summary.min_date}</TableCell>
                <TableCell>{summary.max_date}</TableCell>
                <TableCell>
                    <Button variant="outlined" size="small" onClick={onSummaryBtn}>
                        {t(
                            'Modules.PopDisplays.VirtualCampaignBuilder.summary.table.playbackSummaryLabel',
                        )}
                    </Button>
                </TableCell>
            </TableRow>
        </>
    );
};

export default VirtualCampaignSummaryRow;
