import {
    jobStatusActions,
    jobStatusAdapter,
    jobStatusThunks as defaultThunks,
    jobStatusSelectors as defaultSelectors,
    jobStatusReducer,
} from './JobStatus-slice';
import * as thunks from './JobStatus-thunks';
import * as selectors from './JobStatus-selectors';

const jobStatusThunks = { ...defaultThunks, ...thunks };
const jobStatusSelectors = { ...defaultSelectors, ...selectors };

export { default as JobStatusSlice } from './JobStatus-slice';
export {
    jobStatusSelectors,
    jobStatusActions,
    jobStatusAdapter,
    jobStatusThunks,
    jobStatusReducer,
};
