import React from 'react';

import { Button, DialogV2 } from 'c-components';
import { useBoolean } from 'react-hanger';
import { Box, Stack } from '@mui/material';
import TargetPanel from 'c-main/Components/Schedule/Components/Targets/TargetPanel';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import CreateNewTarget from 'c-main/Components/Schedule/Components/Targets/CreateNewTarget';
import { useCommonTranslation } from 'c-translation';

const Targets = ({ id }) => {
    const createBool = useBoolean(false);
    const { targets } = useNewCampaignSchedule(id);
    const t = useCommonTranslation();
    const { listTargets } = useNewCampaignSchedule(id);
    return (
        <Box>
            <DialogV2
                onClose={createBool.setFalse}
                open={createBool.value}
                title={t(
                    'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.createTarget',
                )}
            >
                <CreateNewTarget id={id} createBool={createBool} />
            </DialogV2>
            <Stack gap={1}>
                <Button size="small" variant="text" onClick={createBool.setTrue}>
                    {t('Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.createTarget')}
                </Button>
                {targets.map(target => (
                    <Box key={target.id}>
                        <TargetPanel target={target} id={id} />
                    </Box>
                ))}
            </Stack>
        </Box>
    );
};
export default Targets;
