import * as ET from '@uniled/api-sdk/dist/Types/EntityTypes';
import { schema } from 'normalizr';
import { AllEntities, BaseEntity } from '@uniled/api-sdk';

const genericSchemas = Object.keys(new AllEntities()).reduce(
    (a, k) => ({ ...a, [k]: new schema.Entity(k) }),
    {},
) as { [key in keyof AllEntities]: schema.Entity<BaseEntity> };

const userSchema = new schema.Entity<ET.User>('User', {
    roles: [genericSchemas.Role],
    skins: [genericSchemas.Skin],
    buyer: genericSchemas.Buyer,
    buyers: [genericSchemas.Buyer],
    agency: genericSchemas.Agency,
    agencies: [genericSchemas.Agency],
    owner: genericSchemas.Owner,
    owners: [genericSchemas.Owner],
    client: genericSchemas.Client,
    clients: [genericSchemas.Client],
});

const skinSchema = new schema.Entity<ET.Skin>('Skin', {
    agencies: [genericSchemas.Agency],
    buyers: [genericSchemas.Buyer],
    clients: [genericSchemas.Client],
    owners: [genericSchemas.Owner],
    users: [userSchema],
});

const roleSchema = new schema.Entity<ET.Role>('Role', {
    permissions: [genericSchemas.Permission],
});

export const commonSchemas: { [key in keyof AllEntities]: schema.Entity<BaseEntity> } = {
    ...genericSchemas,
    Role: roleSchema,
    Skin: skinSchema,
    User: userSchema,
};
