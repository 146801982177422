import React, { useMemo } from 'react';

type Props = {
    components: React.ReactNode[];
    separator: React.ReactNode;
};
const JoinComponents: React.FC<Props> = ({ components, separator }) => (
    <>
        {useMemo(
            () =>
                (
                    components.reduce(
                        (acc, curr, currIndex) =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            [...acc, currIndex > 0 && separator, curr],
                        [],
                    ) as React.ReactNode[]
                )
                    // eslint-disable-next-line react/no-array-index-key
                    .map((c, ci) => <React.Fragment key={ci}>{c}</React.Fragment>),
            [components, separator],
        )}
    </>
);

export default JoinComponents;
