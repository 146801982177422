import React, { useMemo } from 'react';
import { useTheme } from '@mui/styles';
import { PCAReportCountField, PCAReportMetric } from '@uniled/api-sdk';
import { ChartReportPart, ReportDateTimeframe, ReportProps, ReportType } from 'c-reports/Types';
import { ReportWrapper } from 'c-reports/Components';
import { ByFieldChartProps } from 'c-main/Components/Campaign/CampaignReports/types';
import { dateSortArray } from 'c-lib';
import { ChartDataKeyDateFormat } from 'c-reports/Generators';
import {
    ByDayByHourOverall,
    ByFieldDaily,
    ByFieldHourly,
    ByFieldOverall,
} from '../byFieldDataMutators';
import useChartColors from 'c-reports/useChartColors';

type Props = ByFieldChartProps & { showExpectedLines?: boolean };

const OverTimeCharts: React.FC<Props> = ({
    pcaData,
    field,
    timeFrame,
    actualSelectedFieldParts,
    fieldLabel,
    expectedLabel,
    percentageLabel,
    actualLabel,
    dateLabelLabel,
    hourLabel,
    performanceLabel,
    missingLabel,
    inScheduleLabel,
    outScheduleLabel,
    unbookedLabel,
    renderTooltipLabel,
    reportWrapperProps,
    countFields,
    lineChartAccumulative,
    metric,
    showExpectedLines = false,
    campaignId,
}) => {
    const theme = useTheme();
    const data = useMemo(() => {
        if (field == null) {
            if (timeFrame === ReportDateTimeframe.Daily) {
                return ByDayByHourOverall(
                    pcaData,
                    actualLabel,
                    percentageLabel,
                    expectedLabel,
                    missingLabel,
                    hourLabel,
                    inScheduleLabel,
                    outScheduleLabel,
                    unbookedLabel,
                    lineChartAccumulative,
                ).sort((a, b) => +a[hourLabel] - +b[hourLabel]);
            }

            return ByFieldOverall(
                pcaData,
                actualSelectedFieldParts,
                actualLabel,
                percentageLabel,
                expectedLabel,
                missingLabel,
                dateLabelLabel,
                inScheduleLabel,
                outScheduleLabel,
                unbookedLabel,
                lineChartAccumulative,
            ).sort(dateSortArray(dateLabelLabel, ChartDataKeyDateFormat));
        }
        if (
            timeFrame === ReportDateTimeframe.Weekly ||
            timeFrame === ReportDateTimeframe.BiWeekly ||
            timeFrame === ReportDateTimeframe.Monthly ||
            timeFrame === ReportDateTimeframe.Range ||
            timeFrame === ReportDateTimeframe.All
        ) {
            return ByFieldDaily(
                pcaData,
                actualSelectedFieldParts,
                actualLabel,
                percentageLabel,
                expectedLabel,
                fieldLabel,
                dateLabelLabel,
                lineChartAccumulative,
            );
        }
        if (timeFrame === ReportDateTimeframe.Daily) {
            return ByFieldHourly(
                pcaData,
                actualSelectedFieldParts,
                actualLabel,
                percentageLabel,
                expectedLabel,
                fieldLabel,
                hourLabel,
                lineChartAccumulative,
            );
        }

        return [];
    }, [
        field,
        timeFrame,
        pcaData,
        actualSelectedFieldParts,
        actualLabel,
        percentageLabel,
        expectedLabel,
        missingLabel,
        fieldLabel,
        dateLabelLabel,
        hourLabel,
        inScheduleLabel,
        outScheduleLabel,
        unbookedLabel,
        lineChartAccumulative,
    ]);

    const xAxisDataKey = useMemo<string>(() => {
        if (
            timeFrame === ReportDateTimeframe.Weekly ||
            timeFrame === ReportDateTimeframe.BiWeekly ||
            timeFrame === ReportDateTimeframe.Monthly ||
            timeFrame === ReportDateTimeframe.Range ||
            timeFrame === ReportDateTimeframe.All
        ) {
            return dateLabelLabel;
        }
        if (timeFrame === ReportDateTimeframe.Daily) {
            return hourLabel;
        }

        return '';
    }, [dateLabelLabel, hourLabel, timeFrame]);

    const chartColors = useChartColors();

    const parts = useMemo<ChartReportPart[]>(() => {
        if (field == null) {
            const parts = [];

            if (countFields.indexOf(PCAReportCountField.InSchedule) !== -1) {
                parts.push({
                    dataKey: expectedLabel,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    lineProps: { stroke: theme.palette.grey['500'], strokeDasharray: '5 5' },
                });
            }

            parts.push({
                dataKey: actualLabel,
                lineProps: { stroke: theme.palette.primary.main },
            });
            return parts;
        }

        return actualSelectedFieldParts.reduce((acc, curr, index) => {
            acc.push({
                dataKey: curr,
                lineProps: { stroke: chartColors[index % chartColors.length] },
            });

            if (showExpectedLines) {
                acc.push({
                    dataKey: `${curr} ${expectedLabel}`,
                    lineProps: { stroke: chartColors[index], strokeDasharray: '5 5' },
                });
            }

            return acc;
        }, [] as ChartReportPart[]);
    }, [
        actualLabel,
        actualSelectedFieldParts,
        expectedLabel,
        field,
        theme.palette.grey,
        theme.palette.primary.main,
        countFields,
        showExpectedLines,
        chartColors,
    ]);

    const reportProps = useMemo<ReportProps<unknown>[]>(
        () => [
            {
                type: ReportType.Line,
                namePrefix: `${campaignId}_${metric}_${field}_overtime`,
                xAxisDataKey,
                data,
                parts,
                renderTooltipLabel,
                yAxisTickFormatterAsTime: metric === PCAReportMetric.Time,
            },
        ],
        [campaignId, data, field, metric, parts, renderTooltipLabel, xAxisDataKey],
    );

    return <ReportWrapper reportProps={reportProps} {...reportWrapperProps} />;
};

export default OverTimeCharts;
