import { mergeConfigs } from '@uniled/data-layer';
import { config as DashboardModuleConfig } from 'c-dashboard-module/config';
import { CommonState } from 'c-types/RootState';
import { config as SystemSearchConfig } from './Modules/SystemSearch/config';
import { config as MainConfig } from './Modules/Main/config';
import { config as AdminConfig } from './Modules/Admin/config';
import { config as PopDisplaysConfig } from './Modules/PopDisplays/config';
import { config as DisplaysConfig } from './Modules/Displays/config';

const commonConfig = {
    ...mergeConfigs<CommonState>([
        DashboardModuleConfig,
        SystemSearchConfig,
        MainConfig,
        AdminConfig,
        PopDisplaysConfig,
        DisplaysConfig,
    ] as any),
};

export default commonConfig;
