import React from 'react';
import { Button } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { Box, ButtonGroup } from '@mui/material';

type Props = {
    onClick: () => void;
};
const NewScheduleButton: React.FC<Props> = ({ onClick }) => {
    const t = useCommonTranslation();

    return (
        <Box>
            <ButtonGroup variant="outlined">
                <Button variant="outlined" onClick={onClick}>
                    {t('Modules.Main.Campaigns.CampaignSchedule.Schedules.list.addScheduleBtn')}
                </Button>
            </ButtonGroup>
        </Box>
    );
};

export default NewScheduleButton;
