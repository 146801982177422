import { ChartReportProps, TableOrChartProps } from 'c-reports/Types';
import { ChartDataKeyDateFormat } from 'c-reports/Generators';
import {
    BarChartFormDataSchema,
    CategoricalChartFormDataSchema,
    ChartExportImageType,
    ChartFormDataSchema,
    ChartLabelAnchor,
    LineChartFormDataSchema,
} from './types';

export const chartFormData = (
    chartProps: TableOrChartProps<any>,
): Omit<ChartFormDataSchema, 'reportNamePrefix'> => ({
    imageType: ChartExportImageType.PNG,
    height: Math.floor(window?.innerHeight * 0.8),
    width: Math.floor(window?.innerWidth * 0.95),
    showLegend: true,
    // reportNamePrefix: chartProps.namePrefix,
});

export const categoricalChartFormData = (
    chartProps: ChartReportProps<any>,
): CategoricalChartFormDataSchema => ({
    ...chartFormData(chartProps),
    xAxisProps: {
        angle: 0,
        textAnchor: ChartLabelAnchor.Middle,
        showAllLabels: false,
        dataKey: chartProps.xAxisDataKey,
        dateFormat: ChartDataKeyDateFormat,
        originalDateFormat: ChartDataKeyDateFormat,
    },
    chartProps: {
        margin: {
            top: 25,
            right: 50,
            left: 40,
            bottom: 5,
        },
    },
    // creating a new copy
    originalData: chartProps.data.map(d => ({ ...d })),
});

export const barChartFormData = (chartProps: ChartReportProps<any>): BarChartFormDataSchema => ({
    ...categoricalChartFormData(chartProps),
    segments: chartProps.parts.map((p, pi) => ({
        dataKey: p.dataKey,
        color: p.barProps?.fill,
        label: p.dataKey,
        originalLabel: p.dataKey,
        visible: true,
        originalIndex: pi,
    })),
    dataOverrides: chartProps.data.map((data, index) => ({
        originalLabel: data[chartProps.xAxisDataKey],
        originalIndex: index,
        newLabel: data[chartProps.xAxisDataKey],
        partColors: chartProps.parts.map(part => part.barProps.fill),
        visible: true,
    })),
});

export const lineChartFormData = (chartProps: ChartReportProps<any>): LineChartFormDataSchema => ({
    ...categoricalChartFormData(chartProps),
    segments: chartProps.parts.map((p, pi) => ({
        dataKey: p.dataKey,
        color: p.lineProps?.stroke,
        label: p.dataKey,
        originalLabel: p.dataKey,
        visible: true,
        originalIndex: pi,
    })),
});
