import React, { useMemo, useRef } from 'react';
import { CardControl } from 'c-types';
import { Box, CircularProgress, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { MoreVert, WarningAmber } from '@mui/icons-material';
import { useBoolean } from 'react-hanger';
import MuiRouterLink from 'c-components/MuiRouterLink';
import { useUserPermissions } from 'c-auth-module/Hooks';
import { IfHasAllPermissions, IfUserIsOneOfTypes } from 'c-auth-module/Components';
import { useAuthenticatedUser } from 'c-hooks';

type Props = {
    controls: CardControl[];
};

const TransparentCardControls: React.FC<Props> = ({ controls }) => {
    const btfRef = useRef();
    const { value: MenuOpen, toggle: ToggleMenu, setFalse: CloseMenu } = useBoolean(false);
    const { hasAll } = useUserPermissions();
    const { chosenUserType } = useAuthenticatedUser();

    const filteredControls = useMemo(
        () =>
            controls
                .filter(c => hasAll(c.permissions ?? []))
                .filter(c => {
                    if (c.userTypes?.length > 0) return c.userTypes.indexOf(chosenUserType) !== -1;

                    return true;
                }),
        [hasAll, chosenUserType, controls],
    );

    if (filteredControls.length === 0) {
        return null;
    }
    return (
        <>
            <IconButton ref={btfRef} onClick={ToggleMenu}>
                <MoreVert />
            </IconButton>
            <Menu open={MenuOpen} anchorEl={btfRef.current} onClose={CloseMenu}>
                {filteredControls.map((c, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ControlMenuItem key={i} control={c} onClick={CloseMenu} />
                ))}
            </Menu>
        </>
    );
};

const ControlMenuItem: React.FC<{ control: CardControl; onClick: () => void }> = ({
    control,
    onClick,
}) => {
    const menuItem = useMemo(
        () => (
            <MenuItem
                component={control.path != null ? MuiRouterLink : undefined}
                to={control.path ?? undefined}
                onClick={() => {
                    if (control.preventCloseOnClick !== true) onClick();

                    control.onClick?.();
                }}
                disabled={control.disabled}
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                {control.icon && (
                    <Box mr={1} display="flex" alignItems="center">
                        {control.icon}
                    </Box>
                )}
                <Box display="flex" alignItems="center">
                    {control.label}
                    {control.isLoading && <CircularProgress size={15} sx={{ ml: 1 }} />}
                    {control.error && (
                        <Tooltip title={control.error}>
                            <WarningAmber fontSize="inherit" sx={{ ml: 1 }} />
                        </Tooltip>
                    )}
                </Box>
            </MenuItem>
        ),
        [control],
    );

    if (control.permissions?.length > 0) {
        return (
            <IfHasAllPermissions permissions={control.permissions}>{menuItem}</IfHasAllPermissions>
        );
    }

    if (control.userTypes?.length > 0) {
        return <IfUserIsOneOfTypes roles={control.userTypes}>{menuItem}</IfUserIsOneOfTypes>;
    }

    return menuItem;
};

export default TransparentCardControls;
