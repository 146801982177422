import React, { PropsWithChildren } from 'react';
import { TranslationPath, useCommonTranslation } from 'c-translation';
import { Box } from '@mui/material';
import { TransparentCard } from 'c-components';

type Props = PropsWithChildren<{
    title: TranslationPath;
    controls?: React.ReactNode[];
}>;

const FullPageEntityTableWrapper: React.FC<Props> = ({ title, controls, children }) => (
    <Box flex={1} overflow="hidden" display="flex" flexDirection="column">
        <Box>
            <TransparentCard title={useCommonTranslation(title)} controls={controls} />
        </Box>
        {children}
    </Box>
);

export default FullPageEntityTableWrapper;
