import React from 'react';
import { Box, Grid, GridProps, GridSize, Typography } from '@mui/material';
import { LinkButton } from 'c-components/Forms/LinkButton';
import { DashboardTileSize } from 'c-dashboard-module/types';
import { PermissionName } from '@uniled/api-sdk';
import { IfHasAllPermissions } from 'c-auth-module/Components';

type Props = {
    size: DashboardTileSize;
    title?: React.ReactNode | React.ReactElement;
    titleIcon?: React.ReactNode;
    route?: string;
    permissions?: PermissionName[];
} & GridProps;

const xs: Record<DashboardTileSize, GridSize> = {
    [DashboardTileSize.Small]: 12,
    [DashboardTileSize.Medium]: 12,
    [DashboardTileSize.Large]: 12,
};
const sm: Record<DashboardTileSize, GridSize> = {
    [DashboardTileSize.Small]: 3,
    [DashboardTileSize.Medium]: 6,
    [DashboardTileSize.Large]: 12,
};
const md: Record<DashboardTileSize, GridSize> = {
    [DashboardTileSize.Small]: 2,
    [DashboardTileSize.Medium]: 4,
    [DashboardTileSize.Large]: 8,
};
const lg: Record<DashboardTileSize, GridSize> = {
    [DashboardTileSize.Small]: 1,
    [DashboardTileSize.Medium]: 2,
    [DashboardTileSize.Large]: 4,
};
const xl: Record<DashboardTileSize, GridSize> = {
    [DashboardTileSize.Small]: 1,
    [DashboardTileSize.Medium]: 2,
    [DashboardTileSize.Large]: 4,
};

const DashboardTile: React.FC<Props> = ({
    size,
    title,
    titleIcon,
    route,
    children,
    permissions = [],
    ...gridProps
}) => (
    <Grid item xs={xs[size]} sm={sm[size]} md={md[size]} lg={lg[size]} xl={xl[size]} {...gridProps}>
        <IfHasAllPermissions permissions={permissions}>
            <LinkButton
                to={route}
                variant="outlined"
                sx={{
                    display: 'flex',
                    cursor: 'pointer',
                    minHeight: 150,
                    height: '100%',
                    position: 'relative',
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    alignSelf="center"
                    width="100%"
                >
                    {title && (
                        <Box sx={{ color: 'primary.main' }}>
                            {titleIcon && (
                                <Box sx={{ textAlign: 'center', fontSize: '2.5em' }}>
                                    {titleIcon}
                                </Box>
                            )}

                            <Typography variant="h4" align="center">
                                {title}
                            </Typography>
                        </Box>
                    )}
                    {children}
                </Box>
            </LinkButton>
        </IfHasAllPermissions>
    </Grid>
);

// const DashboardTile: React.FC<Props> = ({ size, title, titleIcon, children, id, ...boxProps }) => {
//     return (
//         <Grid item xs={xs[size]} sm={sm[size]} md={md[size]} lg={lg[size]} xl={xl[size]}>
//             <Card
//                 elevation={0}
//                 sx={{
//                     display: 'flex',
//                     bgcolor: 'transparent',
//                     borderWidth: 3,
//                     borderRadius: 8,
//                     borderColor: 'primary.main',
//                     borderStyle: 'solid',
//                     cursor: 'pointer',
//                     minHeight: 150,
//                     height: '100%',
//                     position: 'relative',
//                 }}
//             >
//                 <Box
//                     id={id}
//                     display="flex"
//                     alignItems="center"
//                     justifyContent="center"
//                     alignSelf="center"
//                     width="100%"
//                     p={2}
//                     {...boxProps}
//                 >
//                     {title && (
//                         <Box sx={{ color: 'primary.main' }}>
//                             {titleIcon && (
//                                 <Box sx={{ textAlign: 'center', fontSize: '2.5em' }}>
//                                     {titleIcon}
//                                 </Box>
//                             )}
//
//                             <Typography variant="h4" align="center">
//                                 {title}
//                             </Typography>
//                         </Box>
//                     )}
//                     {children}
//                 </Box>
//             </Card>
//         </Grid>
//     );
// };

export default DashboardTile;
