import { EntityListColumn } from 'c-pagination';
import { PopDisplays_JobStatus } from '@uniled/api-sdk';
import React, { useMemo } from 'react';
import { secondsToDhms } from 'c-lib';
import { differenceInSeconds, parseISO } from 'date-fns';
import { useCommonTranslation } from 'c-translation';
import { AutoGrid } from 'c-components';
import { Box } from '@mui/material';

const DurationCol: EntityListColumn<PopDisplays_JobStatus>['RenderCell'] = ({ entity }) => {
    const hms = useMemo<ReturnType<typeof secondsToDhms> | null>(() => {
        if (entity.started_at && entity.completed_at) {
            try {
                return secondsToDhms(
                    differenceInSeconds(parseISO(entity.completed_at), parseISO(entity.started_at)),
                );
                // eslint-disable-next-line no-empty
            } catch {}
        }

        return null;
    }, [entity]);

    const t = useCommonTranslation();

    return (
        <>
            {entity.started_at != null && entity.completed_at != null && (
                <AutoGrid spacing={1} flexWrap="nowrap" sx={{ whiteSpace: 'nowrap' }}>
                    {hms.h > 0 && (
                        <Box>
                            {t('Duration.hours', {
                                count: hms.h,
                            })}
                        </Box>
                    )}
                    {hms.m > 0 && (
                        <Box>
                            {t('Duration.minutes', {
                                count: hms.m,
                            })}
                        </Box>
                    )}
                    {hms.s > 0 && (
                        <Box>
                            {t('Duration.seconds', {
                                count: hms.s,
                            })}
                        </Box>
                    )}
                </AutoGrid>
            )}
        </>
    );
};

export default DurationCol;
