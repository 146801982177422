import { Displays_LineItem, PermissionName, Creative } from '@uniled/api-sdk';
import { equals, uniqWith } from 'ramda';

export const UpdateCreativeGroupsPerms: PermissionName[] = [
    PermissionName.UniledportalCreativeUpdate,
    PermissionName.UniledportalCreative_creative_groupCreate,
    PermissionName.UniledportalCreative_creative_groupUpdate,
    PermissionName.UniledportalCreative_creative_groupDestroy,
];

// to avoid a dependency cycle (would've otherwise put it in neighbouring `types.ts` file
export enum StaticCreativeGroup {
    AllCreatives = -1,
    Invalid = -2,
    Ungrouped = -3,
    UnAssigned = -4,
}

export const creativeInOneOfGroups = (creative: Creative, groupIds: number[]) =>
    groupIds.reduce(
        (inGroup, groupId) =>
            // don't override value if already found to be in one of groups
            !inGroup ? creative?.creativeGroupIds?.indexOf(groupId) !== -1 : inGroup,
        false,
    );

export const isStaticGroup = (groupId: number) =>
    groupId == null ||
    [
        StaticCreativeGroup.AllCreatives,
        StaticCreativeGroup.Invalid,
        StaticCreativeGroup.Ungrouped,
        StaticCreativeGroup.UnAssigned,
    ].indexOf(groupId) !== -1;

export const getLineItemResolutions = (lineItems: Displays_LineItem[]) =>
    uniqWith(
        equals,
        lineItems.reduce((resolutions, lineItem) => {
            const lineItemResolutions = (lineItem.resolutions ?? []).map(res => [
                res.width,
                res.height,
            ]) as [number, number][];

            if (lineItemResolutions.length > 0) return [...resolutions, ...lineItemResolutions];

            return resolutions;
        }, [] as [number, number][]),
    ) as [number, number][];
export const getHumanReadableLineItemResolutions = (lineItems: Displays_LineItem[]) =>
    uniqWith(
        equals,
        lineItems.reduce((resolutions, lineItem) => {
            const lineItemResolutions = (lineItem.resolutions ?? []).map(
                res => `${res.width}x${res.height}`,
            );

            if (lineItemResolutions.length > 0) return [...resolutions, ...lineItemResolutions];

            return resolutions;
        }, [] as string[]),
    ).join(', ');

export const getHumanReadableLineItemFileTypes = (lineItems: Displays_LineItem[]) =>
    uniqWith(
        equals,
        lineItems.reduce((types, lineItem) => {
            const fileTypes = (lineItem.fileTypes ?? []).map(type => type.type);

            if (fileTypes.length > 0) return [...types, ...fileTypes];

            return types;
        }, [] as string[]),
    ).join(', ');
