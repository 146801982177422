import { pick } from 'ramda';
import { Skin } from 'c-entity-types';

export const onlySkinThemeValues = (skin?: Skin) => {
    if (skin == null) {
        return null;
    }

    return pick(
        [
            'id',
            'updated_at',
            'created_at',
            'name',
            'logo_path',
            'logo_mimetype',
            'logo_small_path',
            'logo_small_mimetype',
            'primary_text_color',
            'secondary_text_color',
            'disabled_text_color',
            'primary_color',
            'secondary_color',
            'divider_color',
            'card_bg_color',
            'content_bg_color',
            'nav_bg_color',
        ],
        skin,
    ) as Skin;
};
