import to from 'await-to-js';

export const copyTextToClipboard = async (text: string, append = false) => {
    let finalClipboardText = String(text);
    if (append) {
        const [err, clipboardText] = await to(navigator?.clipboard?.readText());

        if (!err && typeof clipboardText === 'string') {
            finalClipboardText = `${clipboardText}\n${text}`;
        }
    }

    return navigator?.clipboard?.writeText(finalClipboardText);
};
