const initials = (str = '', maxLength?: number) => {
    if (str == null) {
        return '';
    }
    const newString = str.match(/\b(\w)/g)?.join('') ?? '';

    if (maxLength != null) {
        return newString.substr(0, maxLength).trim();
    }

    return newString;
};

export default initials;
