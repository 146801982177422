import React, { useEffect } from 'react';
import { TranslationPath } from 'c-translation';
import { uiThunks } from 'c-wrapper/Slices/UI';
import { PageStatusSettings } from 'c-system-search/types';
import { uiActions } from 'c-wrapper/Slices/UI';
import { useDispatch } from 'react-redux';

type Props = {
    metaTitle: TranslationPath;
    pageId?: string;
    disableDesktopContentScrolling?: boolean;
    pageStatus?: PageStatusSettings;
};

const LayoutSetter: React.FC<Props> = ({
    metaTitle,
    pageId = undefined,
    disableDesktopContentScrolling = false,
    pageStatus,
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(uiThunks.updateLayoutMetaData(metaTitle));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(uiThunks.updateLayoutMetaData(metaTitle));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metaTitle, pageId]);

    useEffect(() => {
        dispatch(uiActions.setPageStatus(pageStatus));
    }, [pageStatus, dispatch]);

    useEffect(() => {
        dispatch(uiActions.setDisableDesktopContentScrolling(disableDesktopContentScrolling));
    }, [disableDesktopContentScrolling, dispatch]);

    return null;
};

export default LayoutSetter;
