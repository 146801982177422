import { PresenceChannelMember } from 'c-auth-module/Types';
import { CommonThunk } from 'c-types';
import { Skin } from 'c-entity-types';
import { storeDefaultSkin } from 'c-lib';
import { previewThemeRaw } from 'c-wrapper/Slices/UI/ui-selectors';
import { uiActions } from './ui-slice';

export const toggleDebug = (): CommonThunk => async dispatch => {
    dispatch(uiActions.toggleDebug());
};

/**
 * set the skin which is being previewed within the skin editing page
 */
export const setPlayAroundSkin =
    (skin: Skin): CommonThunk =>
    async dispatch => {
        dispatch(uiActions.setPlayAroundTheme(skin));
    };

/**
 * Set the user's skin after logging
 *
 * We only want to set it if no skin is currently set as an override.
 *
 * For example, users can have multiple skins available to them.
 *
 * They they've chosen skin #2, we don't want to reselect skin #1 when they log in.
 *
 * @param skin
 * @param storeSkin - pass as true if you want this to be stored in localstorage
 */
export const setPreviewThemeOnLogin =
    (skin: Skin): CommonThunk =>
    async (dispatch, getState) => {
        const currentSkinId = previewThemeRaw(getState())?.id;

        if (currentSkinId == null || skin?.id === currentSkinId) {
            // if the user doesn't currently have a skin then we want to set the default skin
            // OR
            // if on logging in, we try to set the same skin id, we want to update the copy of the skin set
            dispatch(setPreviewTheme(skin, true));
            storeDefaultSkin(skin);
        }
    };
/**
 * Choose a new theme to display on the user's GUI
 * @param skin
 * @param storeSkin - pass as true if you want this to be stored in localstorage
 */
export const setPreviewTheme =
    (skin: Skin, storeSkin = false): CommonThunk =>
    async dispatch => {
        dispatch(uiActions.setPreviewTheme(skin));

        if (storeSkin) {
            storeDefaultSkin(skin);
        }
    };

export const toggleDrawerOpen =
    (open: boolean): CommonThunk =>
    async dispatch => {
        dispatch(uiActions.toggleDrawerOpen(open));
    };

export const toggleNotesDrawerOpen =
    (open: boolean): CommonThunk =>
    async dispatch => {
        dispatch(uiActions.toggleNotesDrawerOpen(open));
    };

export const setCrmMembers =
    (members: PresenceChannelMember[]): CommonThunk =>
    async dispatch => {
        dispatch(uiActions.setCrmMembers(members));
    };

export const addCrmMember =
    (member: PresenceChannelMember): CommonThunk =>
    async dispatch => {
        dispatch(uiActions.addCrmMember(member));
    };

export const removeCrmMember =
    (member: PresenceChannelMember): CommonThunk =>
    async dispatch => {
        dispatch(uiActions.removeCrmMember(member));
    };

export const setPresenceMembers =
    (members: PresenceChannelMember[]): CommonThunk =>
    async dispatch => {
        dispatch(uiActions.setPageMembers(members));
    };

export const addPresenceMember =
    (member: PresenceChannelMember): CommonThunk =>
    async dispatch => {
        dispatch(uiActions.addPageMember(member));
    };

export const removePresenceMember =
    (member: PresenceChannelMember): CommonThunk =>
    async dispatch => {
        dispatch(uiActions.removePageMember(member));
    };

export const updateLayoutMetaData =
    (title: string): CommonThunk =>
    async dispatch => {
        dispatch(uiActions.setLayoutMetaData({ title }));
    };
