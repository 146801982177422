import React, { PropsWithChildren } from 'react';
import { ChartReportProps, ReportType, TableReportProps } from 'c-reports/Types';
import { Box } from '@mui/material';
import { css } from '@emotion/react';
import ChartWrapperControls from './ChartWrapperControls';

type Props = {
    chartProps?: ChartReportProps<any>;
    tableProps?: TableReportProps<any>;
    type: ReportType;
    namePrefix: string;
    startDate: Date;
    endDate: Date;
};

const controlsClass = 'controls';

const styles = css(`
    .${controlsClass} {
        pointer-events: none;
        opacity: 0;
        transition: all 200ms;
        z-index: 10;
    }
    &:hover {
        .${controlsClass} {
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
        }
    }

`);

const ReportControlsWrapper: React.FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
    return (
        <Box height="100%" width="100%" position="relative" css={styles}>
            <Box position="sticky" top={0} right={0} className={controlsClass}>
                <Box position="absolute" top={0} right={20} className={controlsClass}>
                    <ChartWrapperControls {...props} />
                </Box>
            </Box>
            {children}
        </Box>
    );
};

export default ReportControlsWrapper;
