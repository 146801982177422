import { Displays_Owner } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { DisplaysOwnerState } from './DisplaysOwner-types';

const initialState: DisplaysOwnerState = { ...baseInitialState };

const additionalReducers = {
    // example: (state: DisplaysOwnerState, action: PayloadAction<string>): DisplaysOwnerState => state,
};

const slice = createBaseSlice<Displays_Owner, DisplaysOwnerState, typeof additionalReducers>({
    name: 'displaysOwner',
    entityName: 'Displays_Owner',
    initialState,
    reducers: additionalReducers,
});

const displaysOwnerSlice = {
    displaysOwnerActions: slice.slice.actions,
    displaysOwnerReducer: slice.slice.reducer,
    displaysOwnerAdapter: slice.adapter,
    displaysOwnerSelectors: slice.selectors,
    displaysOwnerThunks: slice.thunks,
};

export default displaysOwnerSlice;
export const {
    displaysOwnerReducer,
    displaysOwnerActions,
    displaysOwnerAdapter,
    displaysOwnerSelectors,
    displaysOwnerThunks,
} = displaysOwnerSlice;
