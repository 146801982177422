import React, { useMemo } from 'react';
import { formatNumber, secondsToHms } from 'c-lib';
import { Box, GridProps, Typography, TypographyProps } from '@mui/material';
import { Translate } from 'c-translation';
import { AutoGrid } from 'c-components';

type Props = {
    seconds: number;
    variant: TypographyProps['variant'];
    color?: TypographyProps['color'];
    spacing?: number;
    sx?: GridProps['sx'];
    alwaysShowHours?: boolean;
    alwaysShowMinutes?: boolean;
    alwaysShowSeconds?: boolean;
    showSecondsIfNoHours?: boolean;
};

const HoursMins: React.FC<Props> = ({
    seconds,
    variant,
    color,
    spacing = 1,
    sx,
    alwaysShowHours = false,
    alwaysShowMinutes = false,
    alwaysShowSeconds = false,
    showSecondsIfNoHours = false,
}) => {
    const hms = useMemo(() => secondsToHms(seconds), [seconds]);
    const showSeconds = useMemo(() => {
        if (alwaysShowSeconds) return true;
        if (hms.h <= 0 && showSecondsIfNoHours) return true;

        return false;
    }, [hms, alwaysShowSeconds, showSecondsIfNoHours]);

    const showHours = useMemo(() => {
        if (alwaysShowHours) return true;
        if (hms.h <= 0 && showSeconds) return false;

        return true;
    }, [hms, showSeconds, alwaysShowHours]);

    return (
        <AutoGrid spacing={spacing} sx={sx}>
            {showHours && (
                <Typography variant={variant} color={color} component={Box}>
                    <Translate
                        path="Reporting.ticks.hoursLabel"
                        data={{ count: formatNumber(hms.h) }}
                    />
                </Typography>
            )}
            {(hms.m > 0 || alwaysShowMinutes) && (
                <Typography variant={variant} color={color} component={Box}>
                    <Translate
                        path="Reporting.ticks.minutesLabel"
                        data={{ count: formatNumber(hms.m) }}
                    />
                </Typography>
            )}
            {showSeconds && (
                <Typography variant={variant} color={color} component={Box}>
                    <Translate
                        path="Reporting.ticks.secondsLabel"
                        data={{ count: formatNumber(hms.s) }}
                    />
                </Typography>
            )}
        </AutoGrid>
    );
};

export default HoursMins;
