import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Divider } from '@mui/material';
import {
    CampaignStats,
    ElasticSearchAvailableFilters,
    PCAReportMetric,
    Campaign,
} from '@uniled/api-sdk';
import { useEntityData } from 'c-data';
import { format, parse } from 'date-fns';
import { usePrevious } from 'react-hanger';
import { ReportDateSettings, ReportDateTimeframe, ReportWrapperRefAPI } from 'c-reports/Types';
import { allFieldValuesEs } from 'c-main/Lib';
import { useUrlState } from 'c-hooks';
import { scrollToTopReportingTab } from 'c-main/Components/constants';
import ByFieldES from 'c-main/Components/Campaign/CampaignReports/ElasticSearch/ByFieldES';
import { PCAMap } from './Views';
import { PCAMetricUrlStateKeys, PCAUrlState, ReportTabProps, ReportView } from './types';
import MetricPerformance from './MetricPerformance';
import { ReportFieldFilterES } from './ReportFieldFilter';
import { PCAViewControlProps, PCAViewControls } from './PCAViewControls';
import useCampaignWrapperState from './useCampaignWrapperState';

type Props = {
    // campaign id
    id: number;
    metric: PCAReportMetric;
};

const urlDateFormat = 'yyyy-MM-dd';

const CampaignReportsWrapper: React.FC<Props> = ({ id, metric }) => {
    const { getById } = useEntityData<Campaign>('Campaign');
    const campaign = getById({ id });
    const {
        campaignStart,
        campaignEnd,

        urlDateValue,

        advancedFilters,
        showAdvancedFilters,
        advancedFiltersCount,

        view,
        field,
        fieldSearchValue,

        reportType,

        byFieldReportType,

        countFields,

        byFieldAccumulative,
        setByFieldAccumulative,

        onSwitchField,
        onSwitchView,
        onSearchTermUpdated,
        onReportTypeSwitched,
        onByFieldReportTypeSwitched,
        onAdvancedFiltersUpdated,
        onResetAdvancedFilters,
        environmentMode,
        source,

        notices,
        dataStatusFilter,
        setDataStatusFilter,
        urlEsSelfRefiningFiltersValue,
    } = useCampaignWrapperState(id);

    const { push } = useUrlState();

    const reportWrapperRef = useRef<ReportWrapperRefAPI>();
    const initialStartDate = useMemo(() => {
        if (urlDateValue.startDate == null) {
            return new Date(campaignStart);
        }
        return parse(urlDateValue.startDate, urlDateFormat, new Date());
    }, [urlDateValue.startDate, campaignStart]);

    const initialEndDate = useMemo(() => {
        if (urlDateValue.endDate == null) {
            return new Date(campaignEnd);
        }
        return parse(urlDateValue.endDate, urlDateFormat, new Date());
    }, [urlDateValue.endDate, campaignEnd]);

    const [campaignStats, setCampaignStats] = useState<CampaignStats>(null);

    const onReportResponse: ReportTabProps['onResponse'] = useCallback(
        ({ campaignStats }: { campaignStats: CampaignStats }) => {
            setCampaignStats(campaignStats);
        },
        [],
    );

    const scrollDownOnce = useRef(false);
    const prevCampaignStats = usePrevious(campaignStats);
    useEffect(() => {
        if (prevCampaignStats == null && campaignStats != null && !scrollDownOnce.current) {
            scrollDownOnce.current = true;
            scrollToTopReportingTab();
        }
    }, [campaignStats, prevCampaignStats]);

    const dateSettings = useMemo<ReportDateSettings>(
        () => ({
            minDate: campaignStart,
            maxDate: campaignEnd,
            availableTimeframes: [
                ReportDateTimeframe.Daily,
                ReportDateTimeframe.Weekly,
                ReportDateTimeframe.Monthly,
                ReportDateTimeframe.Range,
            ],
            timeframe: urlDateValue.timeframe,
            onDatesUpdated: (timeframe, start, end) => {
                push(PCAMetricUrlStateKeys.date, {
                    timeframe,
                    startDate:
                        start && timeframe !== ReportDateTimeframe.All
                            ? format(start, urlDateFormat)
                            : undefined,
                    endDate:
                        end && timeframe !== ReportDateTimeframe.All
                            ? format(end, urlDateFormat)
                            : undefined,
                } as PCAUrlState['date']);
            },
        }),
        [campaignEnd, campaignStart, urlDateValue.timeframe, push],
    );

    const prevTimeframeVal = usePrevious(urlDateValue.timeframe);

    useEffect(() => {
        // set the current timeframe from the URL if it is not the same as the current state
        // the prev value check is just there so this doesn't get called unnecessarily on mount
        if (prevTimeframeVal != undefined && prevTimeframeVal !== urlDateValue.timeframe) {
            reportWrapperRef?.current?.setDates(
                urlDateValue.timeframe,
                initialStartDate,
                initialEndDate,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlDateValue]);

    const prevCountFields = usePrevious(countFields);
    const prevAdvancedFilters = usePrevious(advancedFilters);

    useEffect(() => {
        // just to stop it fetching data on mount
        if (prevCountFields != undefined && prevAdvancedFilters != undefined) {
            reportWrapperRef?.current?.refreshData?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countFields, advancedFilters]);

    const [rawEsFilters, setRawEsFilters] = useState<ElasticSearchAvailableFilters>(
        {} as ElasticSearchAvailableFilters,
    );
    const esFilters = useMemo(
        () => allFieldValuesEs(rawEsFilters, urlEsSelfRefiningFiltersValue),
        [rawEsFilters, urlEsSelfRefiningFiltersValue],
    );

    const advancedFilterEs = useMemo(
        () =>
            esFilters ? (
                <ReportFieldFilterES
                    allValuesByField={esFilters}
                    selectedFields={advancedFilters}
                    onSearch={onAdvancedFiltersUpdated}
                    onReset={onResetAdvancedFilters}
                />
            ) : null,
        [esFilters, advancedFilters, onAdvancedFiltersUpdated, onResetAdvancedFilters],
    );

    const reportTabProps = useMemo<ReportTabProps>(
        () => ({
            onResponse: onReportResponse,
            campaign,
            campaignStart,
            campaignEnd,
            currentStartDate: initialStartDate,
            currentEndDate: initialEndDate,
            currentTimeframe: urlDateValue.timeframe,
            metric,
            overTimeAccumulative: byFieldAccumulative,
            setOverTimeAccumulative: setByFieldAccumulative,
            dateSettings,
            advancedFilter: advancedFilterEs,
            showAdvancedFilters: showAdvancedFilters.value,
            countFields,
            fieldFilters: advancedFilters,
            environment: environmentMode,
            source,
        }),
        [
            onReportResponse,
            campaign,
            campaignStart,
            campaignEnd,
            initialStartDate,
            initialEndDate,
            urlDateValue.timeframe,
            metric,
            byFieldAccumulative,
            setByFieldAccumulative,
            dateSettings,
            advancedFilterEs,
            showAdvancedFilters.value,
            countFields,
            advancedFilters,
            environmentMode,
            source,
        ],
    );

    const viewControlsProps = useMemo<PCAViewControlProps>(
        () => ({
            searchValue: fieldSearchValue,
            onSearchValueUpdated: onSearchTermUpdated,
            field: undefined,
            type: reportType,
            onTypeChange: onReportTypeSwitched,
            metric,
            onFieldSwitch: onSwitchField,
            view,
            onViewChange: onSwitchView,
            showAdvancedFilters,
            filtersCount: advancedFiltersCount,
            onDataStatusChange: setDataStatusFilter,
            dataStatus: dataStatusFilter,
            notices,
        }),
        [
            fieldSearchValue,
            advancedFiltersCount,
            metric,
            onReportTypeSwitched,
            onSearchTermUpdated,
            onSwitchField,
            onSwitchView,
            reportType,
            showAdvancedFilters,
            view,
            notices,
            dataStatusFilter,
            setDataStatusFilter,
        ],
    );

    const esAdditionalControls = useMemo(
        () => (
            <PCAViewControls
                {...viewControlsProps}
                field={view === ReportView.Overall ? undefined : field}
                type={byFieldReportType}
                onTypeChange={onByFieldReportTypeSwitched}
            />
        ),
        [byFieldReportType, field, onByFieldReportTypeSwitched, view, viewControlsProps],
    );

    const byFieldEs = useMemo(
        () => (
            <ByFieldES
                key={`${field}-${byFieldReportType}-${view}`}
                view={view}
                field={view === ReportView.Overall ? undefined : field}
                fieldReportType={byFieldReportType}
                onReportTypeSwitched={onByFieldReportTypeSwitched}
                initialTimeFrame={urlDateValue.timeframe}
                countFields={countFields}
                reportWrapperRef={reportWrapperRef}
                fieldFilters={advancedFilters}
                additionalControls={esAdditionalControls}
                onFiltersUpdated={setRawEsFilters}
                refineFilters={urlEsSelfRefiningFiltersValue}
                {...reportTabProps}
            />
        ),
        [
            advancedFilters,
            byFieldReportType,
            countFields,
            esAdditionalControls,
            field,
            onByFieldReportTypeSwitched,
            reportTabProps,
            urlDateValue.timeframe,
            urlEsSelfRefiningFiltersValue,
            view,
        ],
    );

    return (
        <Box pb={2} pt={1}>
            {campaignStats != null && <Performance metric={metric} campaignStats={campaignStats} />}

            <Divider sx={{ my: 2, borderBottomWidth: 2, mb: 1 }} />

            {campaign != null && (
                <Box>
                    {view === ReportView.Overall && byFieldEs}
                    {view === ReportView.Field && byFieldEs}
                    {view === ReportView.Map && (
                        <PCAMap
                            {...reportTabProps}
                            additionalControls={<PCAViewControls {...viewControlsProps} />}
                            onEsFiltersUpdated={setRawEsFilters}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};

const Performance: React.FC<{ metric: PCAReportMetric; campaignStats: CampaignStats }> = ({
    metric,
    campaignStats,
}) => {
    const performance = useMemo(() => {
        if (metric === PCAReportMetric.Plays) return campaignStats?.plays_percentage ?? '0';
        if (metric === PCAReportMetric.Impacts) return campaignStats?.impacts_percentage ?? '0';
        if (metric === PCAReportMetric.Time) return campaignStats?.time_percentage ?? '0';

        return '0';
    }, [
        metric,
        campaignStats?.impacts_percentage,
        campaignStats?.plays_percentage,
        campaignStats?.time_percentage,
    ]);

    const expected = useMemo(() => {
        if (metric === PCAReportMetric.Plays) return campaignStats?.plays_expected ?? 0;
        if (metric === PCAReportMetric.Impacts) return campaignStats?.impacts_expected ?? 0;
        if (metric === PCAReportMetric.Time) return campaignStats?.time_expected ?? 0;

        return 0;
    }, [
        metric,
        campaignStats?.impacts_expected,
        campaignStats?.plays_expected,
        campaignStats?.time_expected,
    ]);

    const actual = useMemo(() => {
        if (metric === PCAReportMetric.Plays) return campaignStats?.plays ?? 0;
        if (metric === PCAReportMetric.Impacts) return campaignStats?.impacts ?? 0;
        if (metric === PCAReportMetric.Time) return campaignStats?.time ?? 0;

        return 0;
    }, [metric, campaignStats?.impacts, campaignStats?.plays, campaignStats?.time]);

    const inSchedule = useMemo(() => {
        if (metric === PCAReportMetric.Plays) return campaignStats?.plays_in_schedule ?? 0;
        if (metric === PCAReportMetric.Impacts) return campaignStats?.impacts_in_schedule ?? 0;
        if (metric === PCAReportMetric.Time) return campaignStats?.time_in_schedule ?? 0;

        return 0;
    }, [
        metric,
        campaignStats?.plays_in_schedule,
        campaignStats?.impacts_in_schedule,
        campaignStats?.time_in_schedule,
    ]);

    const outSchedule = useMemo(() => {
        if (metric === PCAReportMetric.Plays) return campaignStats?.plays_out_schedule ?? 0;
        if (metric === PCAReportMetric.Impacts) return campaignStats?.impacts_out_schedule ?? 0;
        if (metric === PCAReportMetric.Time) return campaignStats?.time_out_schedule ?? 0;

        return 0;
    }, [
        metric,
        campaignStats?.plays_out_schedule,
        campaignStats?.impacts_out_schedule,
        campaignStats?.time_out_schedule,
    ]);

    const unbooked = useMemo(() => {
        if (metric === PCAReportMetric.Plays) return campaignStats?.plays_unbooked ?? 0;
        if (metric === PCAReportMetric.Impacts) return campaignStats?.impacts_unbooked ?? 0;
        if (metric === PCAReportMetric.Time) return campaignStats?.time_unbooked ?? 0;

        return 0;
    }, [
        metric,
        campaignStats?.plays_unbooked,
        campaignStats?.impacts_unbooked,
        campaignStats?.time_unbooked,
    ]);

    return (
        <MetricPerformance
            metric={metric}
            performance={performance}
            actual={actual}
            expected={expected}
            inSchedule={inSchedule}
            outSchedule={outSchedule}
            unbooked={unbooked}
        />
    );
};

export default CampaignReportsWrapper;
