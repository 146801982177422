import React, { useMemo } from 'react';
import { Grid, GridProps, GridSize, GridSpacing } from '@mui/material';
import { equals, reject } from 'ramda';

type Props = {
    spacing?: GridSpacing;
    xs?: boolean | GridSize;
    sm?: boolean | GridSize;
    md?: boolean | GridSize;
    lg?: boolean | GridSize;
    xl?: boolean | GridSize;
    GridItemProps?: GridProps;
} & GridProps;

const AutoGrid: React.FC<Props> = ({
    children,
    spacing,
    xs,
    sm,
    md,
    lg,
    xl,
    sx,
    GridItemProps,
    ...rest
}) => {
    const actualChildren = useMemo(() => {
        if (children == null) {
            return [];
        }
        if (!Array.isArray(children)) {
            return [children];
        }

        return reject(equals(false), children as any[]) as React.ReactNode[];
    }, [children]);

    return (
        <Grid container spacing={spacing} sx={sx} {...rest}>
            {actualChildren.map((x, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid key={i} item xs={xs} sm={sm} md={md} lg={lg} xl={xl} {...GridItemProps}>
                    {x}
                </Grid>
            ))}
        </Grid>
    );
};

export default AutoGrid;
