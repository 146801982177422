import React, { useCallback, useMemo } from 'react';
import { apiClient, useEntityData } from 'c-data';
import { AutoGrid, HoursMinsSecs } from 'c-components';
import { Translate, TranslationPath, useCommonTranslation } from 'c-translation';
import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { ReportConfig, ReportDateTimeframe } from 'c-reports/Types';
import { DisplayMediaBreakdownData, PopDisplays_ChannelFrame } from '@uniled/api-sdk';
import { format } from 'date-fns';
import { useDateUtils } from 'c-hooks';
import { LogFileParsedDaily } from 'c-pop-displays/Components/LogFileParsed';
import DisplayDetailsTable from '../Display/DisplayDetailsTable';
import MediaSummaryBreakdownTable from '../MediaSummaryBreakdownTable';

type Props = {
    id: number;
};

const ChannelFrameDetails: React.FC<Props> = ({ id }) => {
    const { getById } = useEntityData<PopDisplays_ChannelFrame>('PopDisplays_ChannelFrame');
    const channelFrame = getById({ id });
    const { parseApiDayMonthYearHourMinuteSecondFormat } = useDateUtils();

    const minDate = useMemo(
        () =>
            channelFrame.min_date
                ? parseApiDayMonthYearHourMinuteSecondFormat(channelFrame.min_date)
                : new Date(),
        [channelFrame.min_date, parseApiDayMonthYearHourMinuteSecondFormat],
    );
    const maxDate = useMemo(
        () =>
            channelFrame.max_date
                ? parseApiDayMonthYearHourMinuteSecondFormat(channelFrame.max_date)
                : new Date(),
        [channelFrame.max_date, parseApiDayMonthYearHourMinuteSecondFormat],
    );

    const fetchData: ReportConfig<{ data: DisplayMediaBreakdownData[] }>['fetchData'] = useCallback(
        (start, end, timeframe) => {
            if (timeframe === ReportDateTimeframe.All) {
                apiClient.PopDisplays.channelFrameMediaBreakdown(id, {
                    perPage: 9999999,
                    media_items: [],
                });
            }
            return apiClient.PopDisplays.channelFrameMediaBreakdown(id, {
                start_date: start ? `${format(start, 'yyyy-MM-dd')} 00:00:00` : undefined,
                end_date: end ? `${format(end, 'yyyy-MM-dd')} 23:59:59` : undefined,
                perPage: 9999999,
                media_items: [],
            });
        },
        [id],
    );

    if (!channelFrame) {
        return null;
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} xl>
                    <AutoGrid spacing={0} xs={12}>
                        <KV title="Modules.PopDisplays.ChannelFrame.table.idCol" value={id} />
                        <KV
                            title="Modules.PopDisplays.ChannelFrame.table.sourceCol"
                            value={channelFrame.source}
                        />
                        <KV
                            title="Modules.PopDisplays.ChannelFrame.table.frameCol"
                            value={channelFrame.frame}
                        />
                        <KV
                            title="Modules.PopDisplays.ChannelFrame.table.playerCol"
                            value={channelFrame.player}
                        />
                        <KV
                            title="Modules.PopDisplays.ChannelFrame.table.uuidCol"
                            value={channelFrame.uuid}
                        />
                        <KV
                            title="Modules.PopDisplays.ChannelFrame.details.countLabel"
                            value={channelFrame.count}
                        />
                        <KV
                            title="Modules.PopDisplays.ChannelFrame.details.durationLabel"
                            value={channelFrame.count}
                        />
                        <KV
                            title="Modules.PopDisplays.ChannelFrame.details.durationLabel"
                            value={
                                <AutoGrid columnGap={1} flexWrap="nowrap">
                                    {channelFrame.duration} -
                                    <HoursMinsSecs
                                        seconds={channelFrame.duration}
                                        alwaysShowHours
                                        alwaysShowMinutes
                                        alwaysShowSeconds
                                        variant="body1"
                                    />
                                </AutoGrid>
                            }
                        />
                        <KV
                            title="Modules.PopDisplays.ChannelFrame.details.minDateLabel"
                            value={channelFrame.min_date}
                        />
                        <KV
                            title="Modules.PopDisplays.ChannelFrame.details.maxDateLabel"
                            value={channelFrame.max_date}
                        />
                    </AutoGrid>
                </Grid>
                <Grid item xs={12} lg={12} xl>
                    {Array.isArray(channelFrame.displays) && (
                        <>
                            <Typography variant="h3">
                                <Translate path="Modules.PopDisplays.ChannelFrame.details.displaysHeader" />
                            </Typography>
                            <DisplayDetailsTable displays={channelFrame.displays} />
                        </>
                    )}
                </Grid>
            </Grid>

            <Box mt={2}>
                <Card>
                    <CardHeader
                        title={
                            <Typography variant="h3">
                                <Translate path="Modules.PopDisplays.ChannelFrame.details.dateBreakdownHeader" />
                            </Typography>
                        }
                    />

                    <CardContent>
                        <Box height="75vh">
                            <LogFileParsedDaily
                                minDate={minDate}
                                maxDate={maxDate}
                                chartName={`${id}-daily-totals`}
                                fetchData={fetchData}
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Box>

            {Array.isArray(channelFrame.logFilesParsedByMedia) && (
                <Box mt={2}>
                    <Card>
                        <CardHeader
                            title={
                                <Typography variant="h3">
                                    <Translate path="Modules.PopDisplays.ChannelFrame.details.mediaBreakdownHeader" />
                                </Typography>
                            }
                        />

                        <CardContent>
                            <Box height="75vh" px={2}>
                                <MediaSummaryBreakdownTable
                                    channelFrameId={id}
                                    mediaSummaries={channelFrame.logFilesParsedByMedia}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            )}
        </>
    );
};

const KV: React.FC<{ title: TranslationPath; value: React.ReactNode }> = ({ title, value }) => (
    <AutoGrid columnGap={1}>
        <Typography variant="body1">
            <strong>{useCommonTranslation(title)}:</strong>
        </Typography>
        <Typography variant="body1" component="div">
            {value}
        </Typography>
    </AutoGrid>
);

export default ChannelFrameDetails;
