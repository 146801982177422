import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    CircularProgress,
    MenuItem,
    Pagination,
    PaginationItem,
    PaginationProps,
    Select,
    Typography,
} from '@mui/material';
import { AutoGrid, TextField } from 'c-components';
import { useDebouncedCallback } from 'use-debounce';

type Props = {
    totalPages: number;
    loadingPageNumber?: number;
    onPageChange: (e, page: number, perPage?: number) => void;
    count: number;
    page: number;
    rowsPerPage: number;
    showFirstButton?: boolean;
    showLastButton?: boolean;
    onRowsPerPageChange?: (e: React.ChangeEvent<{ value: unknown }>) => void;
};

const perPageOptions = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
];

const TablePaginationNotEntity: React.FC<Props> = ({
    count,
    totalPages,
    onPageChange,
    page,
    rowsPerPage,
    showFirstButton = true,
    showLastButton = true,
    loadingPageNumber,
    onRowsPerPageChange,
}) => {
    const from = useMemo(
        () => (count === 0 ? 0 : page * rowsPerPage - rowsPerPage + 1),
        [count, page, rowsPerPage],
    );
    const to = useMemo(() => Math.min(count, page * rowsPerPage), [count, page, rowsPerPage]);

    const [skipValue, setSkipValue] = useState('');
    const onSkipUpdated = useCallback(
        e => {
            setSkipValue(curr => {
                if (+curr !== +e.target.value) {
                    return e.target.value;
                }
                return curr;
            });
        },
        [setSkipValue],
    );

    const debouncedSkipToPage = useDebouncedCallback((callback: () => void) => {
        callback();
    }, 1000);

    useEffect(() => {
        if (skipValue?.trim()?.length > 0) {
            const newPage = +skipValue;

            debouncedSkipToPage(() => {
                if (newPage >= 1 && newPage !== page && newPage <= totalPages) {
                    onPageChange(null, newPage);
                    setSkipValue('');
                }
            });
        }
    }, [skipValue, onPageChange, page, totalPages, debouncedSkipToPage]);

    useEffect(() => {
        setSkipValue('');
    }, [totalPages]);

    const skipPageTextfield = useMemo(
        () => (
            <Box minWidth="1em" maxWidth="3em" width="auto">
                <TextField
                    variant="standard"
                    placeholder="#"
                    whiteBackdrop={false}
                    greyBackdrop={false}
                    value={skipValue}
                    onChange={onSkipUpdated}
                />
            </Box>
        ),
        [onSkipUpdated, skipValue],
    );

    const loadingIcon = useMemo(
        () => (
            <CircularProgress
                size={15}
                color={page === loadingPageNumber ? 'primary' : 'secondary'}
            />
        ),
        [page, loadingPageNumber],
    );

    const renderPaginationItem = useCallback<PaginationProps['renderItem']>(
        props => {
            if (props.type === 'page') {
                return (
                    <PaginationItem
                        {...props}
                        selected={props.page === page}
                        page={props.page === loadingPageNumber ? loadingIcon : props.page}
                    />
                );
            }

            return <PaginationItem {...props} />;
        },
        [loadingIcon, loadingPageNumber, page],
    );

    const handlePerPageChange = useCallback(
        e => {
            const newRowsPerPage = +e.target.value;
            if (onRowsPerPageChange) {
                onRowsPerPageChange(e);
            } else {
                onPageChange(null, 1, newRowsPerPage);
            }
        },
        [onPageChange, onRowsPerPageChange],
    );

    return (
        <Box className="custom-table-pagination" display="flex" py={2}>
            <AutoGrid
                spacing={2}
                sx={{ ml: 'auto', alignItems: 'center', justifyContent: 'flex-end' }}
            >
                <Typography variant="body2" color="inherit">
                    {from} - {to} of {count}
                </Typography>
                <Pagination
                    page={page}
                    showFirstButton={showFirstButton}
                    showLastButton={showLastButton}
                    count={totalPages}
                    color="primary"
                    renderItem={renderPaginationItem}
                    onChange={onPageChange as any}
                />
                {skipPageTextfield}
                <Select
                    value={rowsPerPage}
                    onChange={handlePerPageChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Rows per page' }}
                >
                    {perPageOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </AutoGrid>
        </Box>
    );
};

export default TablePaginationNotEntity;
