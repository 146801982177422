import React, { useCallback, useContext, useMemo } from 'react';
import { RenderColumnDataProps } from 'c-pagination';
import {
    AllEntitiesDropdown,
    AutoGrid,
    ControlledFormInput,
    SelectField,
    TextField,
    UpdateEntityButton,
} from 'c-components';
import { PermissionName, Market } from '@uniled/api-sdk';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { PaginatedEntityTableContext } from 'c-pagination/EntityPaginationContext';
import { Translate, useCommonTranslation } from 'c-translation';
import { Controller } from 'react-hook-form';

type Props = RenderColumnDataProps<Market>;

const EditPerms = [PermissionName.UniledportalMarketUpdate];

const MarketControlsCell: React.FC<Props> = ({ entity }) => {
    const { search } = useContext(PaginatedEntityTableContext);
    const onSuccess = useCallback(() => {
        search();
    }, [search]);
    const t = useCommonTranslation();
    return (
        <AutoGrid xs gap={1} flexWrap="nowrap">
            <IfHasAllPermissions permissions={EditPerms}>
                <UpdateEntityButton<Market>
                    onSuccess={onSuccess}
                    entityId={entity.id}
                    entityName="Market"
                    title={t('Modules.Admin.MarketList.update.updateTitle', { name: entity.name })}
                    permissions={EditPerms}
                    defaultData={useMemo(
                        () =>
                            ({
                                ...entity,
                                owners: entity?.owners?.map(owner => owner.id),
                                country_id: entity?.country.id,
                            } as any),
                        [entity],
                    )}
                    form={
                        <AutoGrid xs={6} spacing={2} pb={2}>
                            <ControlledFormInput
                                render={({ field }) => (
                                    <TextField
                                        label={
                                            <Translate path="Modules.Admin.MarketList.update.nameLabel" />
                                        }
                                        {...field}
                                    />
                                )}
                                name="name"
                            />
                            <Controller
                                name="country_id"
                                render={({ field }) => (
                                    <AllEntitiesDropdown
                                        entityName="Displays_Country"
                                        labelField="name"
                                        label={t(
                                            'Modules.Displays.DisplaysMarketEdit.fields.country',
                                        )}
                                        useLocalEntityData
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="owners"
                                render={({ field }) => (
                                    <AllEntitiesDropdown
                                        entityName="Displays_Owner"
                                        labelField="name"
                                        multi
                                        label={t(
                                            'Modules.Displays.DisplaysMarketEdit.fields.owners',
                                        )}
                                        useLocalEntityData
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                name="status"
                                render={({ field }) => (
                                    <SelectField
                                        label={t(
                                            'Modules.Displays.DisplaysMarketEdit.fields.status',
                                        )}
                                        {...field}
                                        value={field.value}
                                        fullWidth
                                        options={[
                                            {
                                                value: 0,
                                                label: t(
                                                    'Modules.Displays.DisplaysMarketEdit.fields.inactive',
                                                ),
                                            },
                                            {
                                                value: 1,
                                                label: t(
                                                    'Modules.Displays.DisplaysMarketEdit.fields.active',
                                                ),
                                            },
                                        ]}
                                    />
                                )}
                            />
                        </AutoGrid>
                    }
                />
            </IfHasAllPermissions>
        </AutoGrid>
    );
};

export default MarketControlsCell;
