import React from 'react';
import { Box, ListItem, ListItemAvatar, Typography } from '@mui/material';
import { AutoGrid, Avatar } from 'c-components';
import { userInitials, userName } from 'c-admin/Lib';
import { useDateUtils } from 'c-hooks';
import { ChatMessage } from '../../types';
import MessageAttachments from './MessageAttachments';

type Props = {
    isMe: boolean;
    message: ChatMessage;
};

const avatarSx = { height: 35, width: 35, bgcolor: 'primary.dark', fontSize: '.8em' };
const avatarErrorSx = { ...avatarSx, bgcolor: 'error.dark' };

const listItemAvatarSx = { alignSelf: 'flex-start' };

const Message: React.FC<Props> = ({ isMe, message }) => {
    const { formatTime } = useDateUtils();
    return (
        <ListItem>
            <ListItemAvatar sx={listItemAvatarSx}>
                <Avatar sx={isMe ? avatarSx : avatarErrorSx}>
                    {userInitials(message.createdBy)}
                </Avatar>
            </ListItemAvatar>
            <Box>
                <AutoGrid flexWrap="nowrap" alignItems="center" gap={1}>
                    <Typography variant="body2">
                        <b>{userName(message.createdBy)}</b>
                    </Typography>
                    <Typography variant="body2" color="grey.500">
                        {formatTime(message.createdAt)}
                    </Typography>
                </AutoGrid>

                <Box>
                    <Typography variant="body2" whiteSpace="pre-line">
                        {message.content}
                    </Typography>
                </Box>

                {message?.attachments?.length > 0 && (
                    <Box mt={1}>
                        <MessageAttachments attachments={message.attachments} />
                    </Box>
                )}
            </Box>
        </ListItem>
    );
};

export default Message;
