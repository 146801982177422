import { FakeApi } from '@uniled/data-layer';
import { isRunningStorybook, isRunningTests } from 'c-is-running';
import { User } from 'c-entity-types';

// eslint-disable-next-line import/no-mutable-exports
let FakeUsers: FakeApi<User>;

if (isRunningTests || isRunningStorybook) {
    FakeUsers = {
        useFake: true,
        initialEntities: [
            {
                id: 1,
                name_first: 'Test',
                name_last: 'User',
                email: 'test.user@uniledsolutions.com',
                status: 'enabled',
                created_at: '2021-10-01T09:00:00+00:00',
            },
            {
                id: 2,
                name_first: undefined,
                name_last: undefined,
                email: undefined,
                created_at: undefined,
            },
        ] as User[],
    };
}

export default FakeUsers;
