import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { PCAFrameDetails, PCAReportMetric } from '@uniled/api-sdk';
import { ascend, descend, prop, sort } from 'ramda';
import { Box, IconButton, TableContainer, TableSortLabel, Typography } from '@mui/material';
import {
    NumberFormat,
    NumberFormatWhole,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from 'c-components';
import { Translate } from 'c-translation';
import { LocationOn, TableChart } from '@mui/icons-material';
import { googleMapsLatLngUrl } from 'c-lib';
import HoursMins from 'c-main/Components/Campaign/CampaignReports/HoursMins';
import { Direction } from 'c-types';

type Props = {
    frames: PCAFrameDetails[];
    columns: string[];
    columnHeaders: Record<string, string>;
    onSelect: (frameId: string) => void;
    metric: PCAReportMetric;
};

type PCAFrameDetailsExtended = PCAFrameDetails & {
    performance: number;
};

type OrderCol = keyof PCAFrameDetailsExtended;

const FrameTable: React.FC<Props> = ({ frames, columns, columnHeaders, onSelect, metric }) => {
    const [orderByCol, setOrderByCol] = useState<OrderCol>('address');
    const [orderByDirection, setOrderByDirection] = useState<Direction>(Direction.ASC);
    const framesExtended = useMemo<PCAFrameDetailsExtended[]>(
        () => frames.map(frame => ({ ...frame, performance: Number(frame.percentage) })),
        [frames],
    );

    const orderByClicked = useCallback(
        (field: OrderCol) => {
            let newDirection;

            let flip = false;
            // if same field, flip the ordering direction
            if (orderByCol === field) {
                flip = true;
            }

            if (flip) {
                newDirection = orderByDirection === Direction.ASC ? Direction.DESC : Direction.ASC;
            } else {
                newDirection = Direction.ASC;
            }

            // if switching fields order descending
            if (orderByCol !== field) {
                newDirection = Direction.DESC;
            }

            setOrderByCol(field);
            setOrderByDirection(newDirection);
        },
        [orderByCol, orderByDirection],
    );

    const dir = useMemo(
        () => (orderByDirection === Direction.DESC ? descend : ascend),
        [orderByDirection],
    );
    const byDirection = useMemo(() => dir(prop(orderByCol)), [dir, orderByCol]);

    // casting as any because it doesn't like the mixture of number and string values in the PCAFrameDetails type
    // but it doesn't really matter in this instance
    const sortedFrames = useMemo(
        () => sort(byDirection as any, framesExtended),
        [framesExtended, byDirection],
    );

    const headers = useMemo(
        () => (
            <>
                <TableCell isHeader>#</TableCell>
                <TableCell isHeader>
                    <OrderableTableHeader
                        onClick={orderByClicked}
                        field="address"
                        direction={orderByDirection}
                        active={orderByCol === 'address'}
                    >
                        <Translate path="Modules.Main.Campaigns.Overview.Reports.frameList.addressHeader" />
                    </OrderableTableHeader>
                </TableCell>
                <TableCell isHeader>
                    <OrderableTableHeader
                        onClick={orderByClicked}
                        field="performance"
                        direction={orderByDirection}
                        active={orderByCol === 'performance'}
                    >
                        <Translate
                            path={`Modules.Main.Campaigns.Overview.Reports.${metric}.percentageLabel`}
                        />
                    </OrderableTableHeader>
                </TableCell>
                <TableCell isHeader>
                    <OrderableTableHeader
                        onClick={orderByClicked}
                        field="expected"
                        direction={orderByDirection}
                        active={orderByCol === 'expected'}
                    >
                        <Translate
                            path={`Modules.Main.Campaigns.Overview.Reports.${metric}.expectedLabel`}
                        />
                    </OrderableTableHeader>
                </TableCell>
                <TableCell isHeader>
                    <OrderableTableHeader
                        onClick={orderByClicked}
                        field="actual"
                        direction={orderByDirection}
                        active={orderByCol === 'actual'}
                    >
                        <Translate
                            path={`Modules.Main.Campaigns.Overview.Reports.${metric}.actualLabel`}
                        />
                    </OrderableTableHeader>
                </TableCell>
                {columns.map(col => (
                    <TableCell isHeader key={col}>
                        {columnHeaders[col]}
                    </TableCell>
                ))}
            </>
        ),
        [orderByClicked, orderByDirection, orderByCol, metric, columns, columnHeaders],
    );
    const rows = useMemo(
        () => (
            <>
                {sortedFrames.map((frame, index) => (
                    <TableRow key={frame.frameId}>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                            <Box display="flex" flexWrap="nowrap" alignItems="center">
                                <Box mr={1}>
                                    <Typography variant="body2" color="grey.500">
                                        {index + 1}
                                    </Typography>
                                </Box>
                                <IconButton
                                    href={googleMapsLatLngUrl(frame.latitude, frame.longitude)}
                                    target="_blank"
                                >
                                    <LocationOn />
                                </IconButton>
                                <IconButton onClick={() => onSelect(frame.frameId)}>
                                    <TableChart />
                                </IconButton>
                            </Box>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                            <Typography
                                variant="body1"
                                sx={{
                                    maxWidth: '45em',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {frame.address} {frame.postcode}{' '}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <NumberFormat value={frame.percentage} />%
                        </TableCell>
                        <TableCell>
                            {metric === PCAReportMetric.Time ? (
                                <HoursMins
                                    variant="body2"
                                    seconds={frame.expected}
                                    alwaysShowMinutes
                                />
                            ) : (
                                <NumberFormatWhole value={frame.expected} />
                            )}
                        </TableCell>
                        <TableCell>
                            {metric === PCAReportMetric.Time ? (
                                <HoursMins
                                    variant="body2"
                                    seconds={frame.actual}
                                    alwaysShowMinutes
                                />
                            ) : (
                                <NumberFormatWhole value={frame.actual} />
                            )}
                        </TableCell>
                        {columns.map(col => (
                            <TableCell key={col}>{frame[col]}</TableCell>
                        ))}
                    </TableRow>
                ))}
            </>
        ),
        [sortedFrames, metric, columns, onSelect],
    );

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow isHeader>{headers}</TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
        </TableContainer>
    );
};

type HeaderProps = {
    field: OrderCol;
    active: boolean;
    direction: Direction;
    onClick: (field: OrderCol) => void;
};
const OrderableTableHeader: React.FC<PropsWithChildren<HeaderProps>> = ({
    field,
    active,
    direction,
    onClick,
    children,
}) => {
    const orderByClicked = useCallback(() => onClick(field), [onClick, field]);
    return (
        <TableSortLabel direction={direction} active={active} onClick={orderByClicked}>
            {children}
        </TableSortLabel>
    );
};

export default FrameTable;
