import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PermissionName, ScheduleGet } from '@uniled/api-sdk';
import { Box, Button, CircularProgress, Divider, Stack } from '@mui/material';
import { Alert, FileUploadButton, JoinComponents, LoadingSpinner } from 'c-components';
import { useEntityData } from 'c-data';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { useBoolean } from 'react-hanger';
import { uploadIcon } from 'c-main/icons';
import { useAPIClientRequest } from 'c-hooks';
import apiClient from 'c-data/apiClient';
import to from 'await-to-js';
import DialogV2 from 'c-components/DialogV2';
import { NetworkRequestState } from '@uniled/data-layer';

import SelectedLineItemsTable from 'c-main/Components/Schedule/Components/Schedule/SelectedLineItemsTable';
import { useCommonTranslation } from 'c-translation';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import NewScheduleButton from './NewScheduleButton';
import ScheduleForm from '../ScheduleForm/ScheduleForm';
import ScheduleListItem from './ScheduleListItem';

type Props = {
    id: number;
    onDelete: (id: number) => void;
    updateSchedules: (schedules: ScheduleGet[]) => void;
    fetchData: () => void;
};

const createPerms = [
    PermissionName.UniliveapiSchedulesCreate,
    PermissionName.UniliveapiRulesCreate,
];

const readListPerms = [PermissionName.UniliveapiSchedulesRead, PermissionName.UniliveapiRulesRead];
const sitelistUploadPerms = [PermissionName.UniliveapiSchedulesSitelist_upload];

const ScheduleList: React.FC<Props> = ({ id, onDelete, updateSchedules, fetchData }) => {
    const [editId, setEditId] = useState<number>(null);
    const attachSchedule = useBoolean(false);
    const detachSchedule = useBoolean(false);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const resetEdit = useCallback(() => {
        setEditId(null);
    }, []);
    const {
        selected,
        setSelected,
        setSelectedScreens,
        selectedScreens,
        schedules,
        getScheduleLineItems,
        page,
        perPage,
        prevSelectedLineItemId,
        getScreensFromLineItem,
        screensCurrentPage,
        screensPerPage,
        scheduleLoading,
    } = useNewCampaignSchedule(id);
    const editSchedule = useMemo(
        () => (editId != null ? schedules?.find(s => s?.id === editId) : null),
        [schedules, editId],
    );

    const collapseAll = useBoolean(false);

    const scheduleItems = useMemo(
        () => (
            <JoinComponents
                components={schedules?.map(schedule => (
                    <ScheduleListItem
                        key={schedule.id}
                        campaignId={id}
                        schedule={schedule}
                        onEdit={setEditId}
                        onDelete={onDelete}
                        onScreensClicked={() => attachSchedule.setTrue()}
                        onDetachClicked={() => detachSchedule.setTrue()}
                        setId={setSelectedSchedule}
                        collapseAll={collapseAll}
                    />
                ))}
                separator={<Divider />}
            />
        ),
        [schedules, id, onDelete, collapseAll, attachSchedule, detachSchedule],
    );

    const addScheduleState = useBoolean(false);

    const scheduleListContent = useMemo(
        () => (
            <Stack gap={1}>
                <IfHasAllPermissions permissions={readListPerms}>
                    {scheduleItems}
                </IfHasAllPermissions>
            </Stack>
        ),
        [scheduleItems],
    );

    const showList = useMemo(
        () => !addScheduleState.value && editSchedule == null,
        [addScheduleState.value, editSchedule],
    );

    const {
        start,
        isLoading,
        hasFailed,
        error,
        hasSucceeded,
        reset,
        abortControllerRef,
        requestStateRef,
    } = useAPIClientRequest(apiClient.Entities.Schedule.uploadSiteList);

    const {
        start: attachStart,
        isLoading: attachLoading,
        data: attachData,
        reset: attachReset,
        error: attachError,
        requestState: attachRequestState,
    } = useAPIClientRequest(apiClient.Entities.Campaign.attachSchedules);

    const {
        start: detachStart,
        isLoading: detachLoading,
        reset: detachReset,
        error: detachError,
        requestState: detachRequestState,
    } = useAPIClientRequest(apiClient.Entities.Campaign.detachSchedules);

    const onFileSelected = useCallback(
        async (files: File[]) => {
            const formData = new FormData();
            formData.set('file', files[0]);
            await to(start({ formData, campaign: id }));
        },
        [start, id],
    );
    const closeDetachDialog = useCallback(() => {
        setSelectedSchedule(null);
        detachSchedule.setFalse();
    }, [setSelectedSchedule, detachSchedule]);

    const closeAttachDialog = useCallback(() => {
        setSelectedSchedule(null);
        attachSchedule.setFalse();
    }, [setSelectedSchedule, attachSchedule]);

    const onClose = useCallback(() => {
        if (requestStateRef?.current === NetworkRequestState.Success) {
            fetchData();
        }
        abortControllerRef?.current?.abort();
        reset();
    }, [requestStateRef, abortControllerRef, reset, fetchData]);
    const t = useCommonTranslation();
    const allSelected = useMemo(
        () => selected.concat(selectedScreens),
        [selected, selectedScreens],
    );
    const groupIdsByType = items =>
        items.reduce(
            (acc, item) => {
                const key = item.type === 'Display' ? 'displays' : 'line_items';
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item.id);
                return acc;
            },
            { displays: [], line_items: [] },
        );

    const detachScheduleCall = useCallback(async () => {
        const groupedIds = groupIdsByType(allSelected);
        groupedIds.schedules = [selectedSchedule];
        await to(detachStart(id, groupedIds));
    }, [allSelected, detachStart, id, selectedSchedule]);

    const attachScheduleCall = useCallback(async () => {
        const groupedIds = groupIdsByType(allSelected);
        groupedIds.schedules = [selectedSchedule];
        await to(attachStart(id, groupedIds));
    }, [allSelected, attachStart, id, selectedSchedule]);

    const resetAllStates = useCallback(
        requestState => {
            setSelected([]);
            setSelectedScreens([]);
            if (requestState === attachRequestState) {
                attachReset();
                closeAttachDialog();
            } else {
                detachReset();
                closeDetachDialog();
            }
        },
        [
            attachRequestState,
            attachReset,
            closeAttachDialog,
            closeDetachDialog,
            detachReset,
            setSelected,
            setSelectedScreens,
        ],
    );

    useEffect(() => {
        if (attachRequestState === NetworkRequestState.Success) {
            getScheduleLineItems(page, perPage);
            if (prevSelectedLineItemId) {
                getScreensFromLineItem(prevSelectedLineItemId, screensCurrentPage, screensPerPage);
            }
            resetAllStates(attachRequestState);
        }

        if (detachRequestState === NetworkRequestState.Success) {
            getScheduleLineItems(page, perPage);
            if (prevSelectedLineItemId) {
                getScreensFromLineItem(prevSelectedLineItemId, screensCurrentPage, screensPerPage);
            }
            resetAllStates(detachRequestState);
        }
    }, [
        attachRequestState,
        detachRequestState,
        getScheduleLineItems,
        getScreensFromLineItem,
        page,
        perPage,
        prevSelectedLineItemId,
        resetAllStates,
        screensCurrentPage,
        screensPerPage,
    ]);

    return (
        <Box flex={1} overflow="auto" position="relative" display="flex">
            {scheduleLoading && <LoadingSpinner />}
            <Box
                display={!showList ? 'none' : undefined}
                flex={1}
                sx={{ opacity: scheduleLoading ? 0.5 : 1 }}
            >
                <Stack
                    textAlign="center"
                    position="sticky"
                    top={0}
                    bgcolor="white"
                    pb={2}
                    justifyContent="space-between"
                    direction="row"
                >
                    <IfHasAllPermissions permissions={createPerms}>
                        <NewScheduleButton onClick={addScheduleState.setTrue} />
                    </IfHasAllPermissions>
                    <Button onClick={collapseAll.setTrue}>
                        {t('Modules.Main.Campaigns.CampaignSchedule.Schedules.list.collapseAll')}
                    </Button>
                    <IfHasAllPermissions permissions={sitelistUploadPerms}>
                        <>
                            <FileUploadButton
                                id="site-list-upload"
                                onFileSelected={onFileSelected}
                                multiple={false}
                                accept=".csv,.txt"
                                iconChild
                                variant="outlined"
                            >
                                {uploadIcon}
                            </FileUploadButton>
                            <DialogV2
                                onClose={onClose}
                                open={isLoading || hasSucceeded || hasFailed}
                            >
                                <Box display="flex" py={2} justifyContent="center">
                                    {isLoading && <CircularProgress size={35} />}
                                    {hasSucceeded && <Alert severity="success">hello</Alert>}
                                    {hasFailed && <Alert severity="error">{String(error)}</Alert>}
                                </Box>
                            </DialogV2>
                        </>
                    </IfHasAllPermissions>
                </Stack>
                {scheduleListContent}
            </Box>
            {addScheduleState.value && (
                <Box display="flex" flex={1} overflow="hidden">
                    <ScheduleForm
                        onClose={addScheduleState.setFalse}
                        campaignId={id}
                        updateSchedules={updateSchedules}
                    />
                </Box>
            )}
            {editSchedule != null && (
                <Box display="flex" flex={1} overflow="hidden">
                    <ScheduleForm
                        onClose={resetEdit}
                        campaignId={id}
                        schedule={editSchedule}
                        updateSchedules={updateSchedules}
                    />
                </Box>
            )}
            <DialogV2
                onClose={closeAttachDialog}
                open={attachSchedule.value}
                title="Modules.Main.Campaigns.CampaignSchedule.Schedules.attachSchedules"
                maxWidth="xl"
            >
                <Stack>
                    <SelectedLineItemsTable id={id} />
                    {!attachLoading && (
                        <Button
                            disabled={allSelected.length === 0 || selectedSchedule === null}
                            onClick={attachScheduleCall}
                        >
                            {t('Modules.Main.Campaigns.CampaignSchedule.Schedules.apply')}
                        </Button>
                    )}
                    {attachLoading && <LoadingSpinner />}
                    {attachError && <Alert severity="error">{String(attachError)}</Alert>}
                </Stack>
            </DialogV2>
            <DialogV2
                onClose={closeDetachDialog}
                open={detachSchedule.value}
                title="Modules.Main.Campaigns.CampaignSchedule.Schedules.detachSchedules"
                maxWidth="xl"
            >
                <Stack>
                    <SelectedLineItemsTable id={id} />
                    {!detachLoading && (
                        <Button
                            disabled={allSelected.length === 0 || selectedSchedule === null}
                            onClick={detachScheduleCall}
                        >
                            {t('Modules.Main.Campaigns.CampaignSchedule.Schedules.apply')}
                        </Button>
                    )}
                    {detachLoading && <LoadingSpinner />}
                    {detachError && <Alert severity="error">{String(detachError)}</Alert>}
                </Stack>
            </DialogV2>
        </Box>
    );
};

export default ScheduleList;
