import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';
import { BooleanColumnProps } from 'c-pagination/types';

type Props = { value: boolean; boolProps?: BooleanColumnProps };

const EntityDataBooleanColumn: React.FC<Props> = ({
    value,
    boolProps: { transform, falseIcon, trueIcon } = {},
}) => {
    const actualValue = useMemo<boolean>(() => {
        if (typeof transform === 'function') {
            return transform(value);
        }

        return value;
    }, [value, transform]);

    const TrueIcon = useMemo(() => trueIcon ?? <CheckCircle />, [trueIcon]);
    const FalseIcon = useMemo(() => falseIcon ?? <Cancel />, [falseIcon]);

    return (
        <Box>
            {actualValue === true && (
                <Box
                    color="primary.main"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    {TrueIcon}
                </Box>
            )}
            {actualValue !== true && (
                <Box color="error.main" display="flex" alignItems="center" justifyContent="center">
                    {FalseIcon}
                </Box>
            )}
        </Box>
    );
};

export default EntityDataBooleanColumn;
