import React, { useCallback, useMemo } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import { deleteIcon, editIcon } from 'c-main/icons';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useBoolean } from 'react-hanger';
import { PermissionName } from '@uniled/api-sdk';
import { useAPIClientRequest } from 'c-hooks';
import ApiClient from 'c-data/apiClient';
import to from 'await-to-js';
import useNewCampaignSchedule from 'c-main/Components/Schedule/useNewCampaignSchedule';
import { Alert, AutoGrid, Button, DialogV2 } from 'c-components';
import UpdateTarget from 'c-main/Components/Schedule/Components/Targets/UpdateTarget';
import { useCommonTranslation } from 'c-translation';
import AttachTarget from 'c-main/Components/Schedule/Components/Targets/AttachTarget';
import { NetworkRequestState } from '@uniled/data-layer';
import DetachTarget from 'c-main/Components/Schedule/Components/Targets/DetachTarget';

const updatePerms = [
    PermissionName.UniliveapiSchedulesUpdate,
    PermissionName.UniliveapiSchedulesCreate,
    PermissionName.UniliveapiSchedulesDestroy,
    PermissionName.UniliveapiRulesCreate,
    PermissionName.UniliveapiRulesUpdate,
    PermissionName.UniliveapiRulesDestroy,
];

const deletePerms = [
    PermissionName.UniliveapiSchedulesDestroy,
    PermissionName.UniliveapiRulesDestroy,
];

const attachPerms = [
    PermissionName.UniliveapiSchedulesUpdate,
    PermissionName.UniliveapiSchedulesCreate,
    PermissionName.UniliveapiSchedulesRead,
    PermissionName.UniliveapiSchedulesDestroy,
];
const TargetPanel = ({ target, id }) => {
    const { setTargets } = useNewCampaignSchedule(id);
    const updateBool = useBoolean(false);
    const deleteBool = useBoolean(false);
    const attachBool = useBoolean(false);
    const detachBool = useBoolean(false);
    const actions = useMemo(
        () => (
            <Box>
                <IfHasAllPermissions permissions={attachPerms}>
                    <IconButton color="primary" onClick={attachBool.setTrue} size="small">
                        <AddIcon fontSize="inherit" />
                    </IconButton>
                </IfHasAllPermissions>
                <IfHasAllPermissions permissions={attachPerms}>
                    <IconButton
                        color="primary"
                        onClick={detachBool.setTrue}
                        size="small"
                        title="detach schedules from screens"
                    >
                        <RemoveIcon fontSize="inherit" />
                    </IconButton>
                </IfHasAllPermissions>
                <IfHasAllPermissions permissions={updatePerms}>
                    <IconButton onClick={updateBool.setTrue} size="small">
                        {editIcon}
                    </IconButton>
                </IfHasAllPermissions>
                <IfHasAllPermissions permissions={deletePerms}>
                    <IconButton color="error" onClick={deleteBool.setTrue} size="small">
                        {deleteIcon}
                    </IconButton>
                </IfHasAllPermissions>
            </Box>
        ),
        [attachBool.setTrue, detachBool.setTrue, updateBool.setTrue, deleteBool.setTrue],
    );
    const {
        start: startDeleteTarget,
        error: deleteTargetError,
        requestState,
    } = useAPIClientRequest(ApiClient.Entities.Campaign.deleteTargets);

    const deleteTarget = useCallback(
        async targetId => {
            const [err, success] = await to(startDeleteTarget(id, targetId));
            if (!err && success) {
                setTargets(prevTargets => prevTargets.filter(target => target.id !== targetId));
            }
        },
        [startDeleteTarget, setTargets, id],
    );

    const t = useCommonTranslation();
    return (
        <Stack>
            <DialogV2
                onClose={updateBool.setFalse}
                open={updateBool.value}
                title={t(
                    'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.updateTarget',
                )}
            >
                <UpdateTarget
                    id={id}
                    targetId={target.id}
                    initialData={target}
                    updateBool={updateBool}
                />
            </DialogV2>
            <DialogV2
                onClose={deleteBool.setFalse}
                open={deleteBool.value}
                title={t(
                    'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.deleteTarget',
                )}
            >
                <Stack gap={1}>
                    <Box display="flex" justifyContent="center">
                        <Typography>Are you sure you want to delete this target?</Typography>
                    </Box>
                    <AutoGrid justifyContent="center" gap={2}>
                        <Button color="primary" onClick={() => deleteTarget(target.id)}>
                            Yes
                        </Button>
                        <Button
                            color="error"
                            disabled={requestState === NetworkRequestState.InProgress}
                            onClick={deleteBool.setFalse}
                        >
                            No
                        </Button>
                    </AutoGrid>
                </Stack>
                {deleteTargetError && (
                    <Alert severity="error" variant="outlined">
                        {String(deleteTargetError)}
                    </Alert>
                )}
            </DialogV2>
            <DialogV2
                onClose={attachBool.setFalse}
                open={attachBool.value}
                maxWidth="md"
                title={t(
                    'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.attachTarget',
                )}
            >
                <AttachTarget target={target} id={id} attachBool={attachBool} />
            </DialogV2>
            <DialogV2
                onClose={detachBool.setFalse}
                open={detachBool.value}
                title={t(
                    'Modules.Main.Campaigns.CampaignSchedule.scheduleTabs.targets.detachTarget',
                )}
            >
                <DetachTarget target={target} id={id} detachBool={detachBool} />
            </DialogV2>
            <Stack gap={1} alignItems="center" direction="row" justifyContent="space-between">
                <Typography variant="subtitle2" color="primary">
                    {target.name}
                </Typography>
                {actions}
            </Stack>
            <Typography variant="caption">{target.type}</Typography>
            <Typography variant="caption">{target.target}</Typography>
        </Stack>
    );
};
export default TargetPanel;
