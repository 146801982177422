import { User } from 'c-entity-types';
import { Config } from 'src/config';

import { mergeSystemSearchConfig as mergeFunction } from 'c-system-search';
import { omit } from 'ramda';
import * as pages from './Pages';
import * as slices from './Slices';

export const config: Config = {
    mergeFunction,
    reducers: {
        skin: slices.skinReducer,
        role: slices.roleReducer,
        permission: slices.permissionReducer,
        user: slices.userReducer,
        buyer: slices.buyerReducer,
        owner: slices.ownerReducer,
    },
    EntitySchemaActions: {
        Skin: {
            actions: slices.skinActions as any,
            entityName: 'Skin' as any,
            selectors: slices.skinSelectors as any,
            thunks: slices.skinThunks as any,
        },
        Role: {
            actions: slices.roleActions as any,
            entityName: 'Role',
            selectors: slices.roleSelectors as any,
            thunks: slices.roleThunks as any,
        },
        Permission: {
            actions: slices.permissionActions as any,
            entityName: 'Permission',
            selectors: slices.permissionSelectors,
            thunks: slices.permissionThunks,
        },
        User: {
            actions: slices.userActions as any,
            entityName: 'User',
            selectors: slices.userSelectors as any,
            thunks: slices.userThunks as any,
        },
        Buyer: {
            actions: slices.buyerActions as any,
            entityName: 'Buyer',
            selectors: slices.buyerSelectors as any,
            thunks: slices.buyerThunks as any,
        },
        Owner: {
            actions: slices.ownerActions as any,
            entityName: 'Owner',
            selectors: slices.ownerSelectors as any,
            thunks: slices.ownerThunks as any,
        },
    },
    navigation: {
        groups: {
            2: {
                items: {
                    1: {
                        id: 'admin-home',
                        label: 'Navigation.admin',
                        icon: pages.AdminHomePage.systemSearch.icon,
                        link: pages.AdminHomePage.systemSearch.route,
                        permissions: pages.AdminHomePage.systemSearch.permissions,
                        hideIfNoChildren: true,
                        items: {
                            1: {
                                id: 'skin-list',
                                label: 'Navigation.adminSkins',
                                icon: pages.SkinListPage.systemSearch.icon,
                                link: pages.SkinListPage.systemSearch.route,
                                permissions: pages.SkinListPage.systemSearch.permissions,
                            },
                            2: {
                                id: 'market-list',
                                label: 'Navigation.adminMarkets',
                                icon: pages.MarketListPage.systemSearch.icon,
                                link: pages.MarketListPage.systemSearch.route,
                                permissions: pages.MarketListPage.systemSearch.permissions,
                            },
                            3: {
                                id: 'user-list',
                                label: 'Navigation.adminUsers',
                                icon: pages.UserListPage.systemSearch.icon,
                                link: pages.UserListPage.systemSearch.route,
                                permissions: pages.UserListPage.systemSearch.permissions,
                            },
                            4: {
                                id: 'role-list',
                                label: 'Navigation.adminRoles',
                                icon: pages.RoleListPage.systemSearch.icon,
                                link: pages.RoleListPage.systemSearch.route,
                                permissions: pages.RoleListPage.systemSearch.permissions,
                            },
                            5: {
                                id: 'impersonate-user',
                                label: 'Navigation.adminImpersonateUser',
                                icon: pages.ImpersonateUserPage.systemSearch.icon,
                                link: pages.ImpersonateUserPage.systemSearch.route,
                                permissions: pages.ImpersonateUserPage.systemSearch.permissions,
                            },
                            6: {
                                id: 'frame-search',
                                label: 'Navigation.adminFrameSearch',
                                icon: pages.FrameSearchPage.systemSearch.icon,
                                link: pages.FrameSearchPage.systemSearch.route,
                                permissions: pages.FrameSearchPage.systemSearch.permissions,
                            },
                            7: {
                                id: 'task-queue-list',
                                icon: pages.TaskQueuePage.systemSearch.icon,
                                label: 'Navigation.popDisplaysTaskQueue',
                                link: pages.TaskQueuePage.systemSearch.route,
                                permissions: pages.TaskQueuePage.systemSearch?.permissions ?? [],
                            },
                        },
                    },
                },
            },
        },
    },
    pages: [
        pages.SkinListPage,
        pages.RoleListPage,
        pages.UserListPage,
        pages.AdminHomePage,
        pages.EditSkinPage,
        pages.EditRolePage,
        pages.NewUserPage,
        pages.EditUserPage,
        pages.ImpersonateUserPage,
        pages.MarketListPage,
        pages.FrameSearchPage,
        pages.TaskQueuePage,
    ],
    systemSearch: {},
    widgets: [],
    entityMapper: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        User: (user: User) => {
            if (Array.isArray(user?.permissions)) {
                // eslint-disable-next-line no-param-reassign
                user.permissionNames = user.permissions.map(p => p.name);
                // eslint-disable-next-line no-param-reassign
                user = omit(['permissions'], user);
            }

            return user as any;
        },
    },
};
