import React, { useMemo } from 'react';
import { Box, IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { AttachFile, Send } from '@mui/icons-material';
import { FileUploadIconButton } from 'c-components';
import { ChatMessageAttachment } from '../../types';
import NewMessageAttachments from './NewMessageAttachments';

type Props = {
    sendDisabled: boolean;
    onSend: () => void;
    onAttachmentAdded: (files: File[]) => void;
    onAttachmentRemoved: (index: number) => void;
    attachments: ChatMessageAttachment[];
    newMessageError?: string;
    showAttachments?: boolean;
};

const NewMessageControls: React.FC<Props> = ({
    sendDisabled,
    onSend,
    onAttachmentAdded,
    onAttachmentRemoved,
    attachments,
    newMessageError,
    showAttachments = true,
}) => {
    const sendColor = useMemo<IconButtonProps['color']>(() => {
        if (newMessageError) return 'error';

        if (!sendDisabled) return 'primary';

        return undefined;
    }, [sendDisabled, newMessageError]);
    return (
        <Box display="flex" flex={1} width="100%">
            <Box
                display="flex"
                width="100%"
                overflow="hidden"
                alignItems="center"
                flexWrap="nowrap"
            >
                {showAttachments && (
                    <FileUploadIconButton
                        multiple
                        id="new-comment-attach"
                        onFileSelected={onAttachmentAdded}
                        accept="*"
                    >
                        <AttachFile fontSize="inherit" />
                    </FileUploadIconButton>
                )}
                <Box height={35} flex={1} overflow="auto" display="flex">
                    <NewMessageAttachments
                        attachments={attachments}
                        onRemove={onAttachmentRemoved}
                    />
                </Box>
            </Box>
            <Tooltip title={newMessageError || ''} placement="top">
                <Box ml="auto">
                    <IconButton disabled={sendDisabled} color={sendColor} onClick={onSend}>
                        <Send fontSize="inherit" />
                    </IconButton>
                </Box>
            </Tooltip>
        </Box>
    );
};

export default NewMessageControls;
