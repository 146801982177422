import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, CircularProgressProps, circularProgressClasses } from '@mui/material';
import { css } from '@emotion/react';

type Props = {
    loop: boolean;
    duration: number;
    onComplete: () => void;
    tickRate?: number;
} & Partial<CircularProgressProps>;

const CountdownProgressCircle: React.FC<Props> = ({
    loop,
    duration,
    onComplete,
    tickRate = 50,
    ...rest
}) => {
    const intervalRef = useRef<any>();
    const intervalVal = useRef(0);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            intervalVal.current += tickRate;
            const newProgress = (intervalVal.current / duration) * 100;
            if (newProgress >= 100) {
                onComplete();
                intervalVal.current = 0;
                setProgress(0);
            } else {
                setProgress(newProgress);
            }
        }, tickRate);

        return () => {
            if (intervalRef.current != null) {
                clearInterval(intervalRef.current);
            }
        };
    }, [tickRate, duration, onComplete]);

    return (
        <CircularProgress
            variant="determinate"
            value={progress}
            sx={{ transition: '0' }}
            css={css(`
            .${circularProgressClasses.circle} {transition: stroke-dashoffset 0ms !important;}
            `)}
            {...rest}
        />
    );
};

export default CountdownProgressCircle;
