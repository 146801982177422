import React, { useMemo } from 'react';
import { useEntityData } from 'c-data';
import { Creative } from '@uniled/api-sdk';
import { pick, sort } from 'ramda';
import { creativesToFileBrowserFiles } from 'c-main/Lib';
import { FileBrowser } from 'c-components';
import { Grid } from '@mui/material';

type Props = {
    creativeIds: number[];
    pagination: React.ReactNode;
};

const CreativesFileList: React.FC<Props> = ({ creativeIds, pagination }) => {
    const { keyedEntities } = useEntityData<Creative>('Creative');
    const creativeEntities = useMemo(
        () => sort((a, b) => b.id - a.id, Object.values(pick(creativeIds, keyedEntities))),
        [keyedEntities, creativeIds],
    );
    const files = useMemo(() => creativesToFileBrowserFiles(creativeEntities), [creativeEntities]);

    return (
        <FileBrowser
            files={files}
            enableCarouselView={false}
            beforeControls={
                <Grid item sx={{ mr: 'auto' }}>
                    {pagination}
                </Grid>
            }
            // enableGridView={false}
            // fileListInnerScroll={false}
        />
    );
};

export default CreativesFileList;
