import React from 'react';
import {
    FileBrowserFile,
    FileDuration,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from 'c-components';
import { Box, TableContainer, TypographyProps } from '@mui/material';
import { useCommonTranslation } from 'c-translation';
import RawFilePreview from 'c-components/FileBrowser/RawFilePreview';
import Avatar from 'c-components/Avatar';
import FileIcon from 'c-components/FileBrowser/FileIcon';
import { generateFileResolution } from 'c-components/FileBrowser/lib';
import { useDateUtils } from 'c-hooks';

type Props = {
    file: FileBrowserFile;
};

const tdProps = {
    dense: true,
    isHeader: true,
    sx: { px: 2 },
    typographyProps: { variant: 'body2', color: 'grey.700' } as TypographyProps,
};
const avatarTdProps = {
    ...tdProps,
    sx: { p: 0, width: 35, pl: 1 },
};

const CreativeDetailsTable: React.FC<Props> = ({ file }) => {
    const t = useCommonTranslation();
    const { formatDateString } = useDateUtils();
    return (
        <Box borderRadius={2} p={2} bgcolor="grey.300">
            <TableContainer>
                <Table spacedOutRows>
                    <TableHead>
                        <TableRow isHeader>
                            <TableCell {...avatarTdProps} />
                            <TableCell {...tdProps}>
                                {t('Modules.Main.CreativeManagement.creatives.table.nameCol')}
                            </TableCell>
                            <TableCell {...tdProps}>
                                {t('Modules.Main.CreativeManagement.creatives.table.typeCol')}
                            </TableCell>
                            <TableCell {...tdProps}>
                                {t('Modules.Main.CreativeManagement.creatives.table.durationCol')}
                            </TableCell>
                            <TableCell {...tdProps}>
                                {t('Modules.Main.CreativeManagement.creatives.table.resCol')}
                            </TableCell>
                            <TableCell {...tdProps}>
                                {t(
                                    'Modules.Main.CreativeManagement.creatives.table.aspectRatioCol',
                                )}
                            </TableCell>
                            <TableCell {...tdProps}>
                                {t('Modules.Main.CreativeManagement.creatives.table.liveDataCol')}
                            </TableCell>
                            <TableCell {...tdProps}>
                                {t('Modules.Main.CreativeManagement.creatives.table.endDataCol')}
                            </TableCell>
                            <TableCell {...tdProps}>
                                {t('Modules.Main.CreativeManagement.creatives.table.uploadDateCol')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell {...avatarTdProps}>
                                <Box height={35} width={35}>
                                    <RawFilePreview
                                        file={file}
                                        maxHeight={35}
                                        maxWidth={35}
                                        showVideoPreview={false}
                                        preferredSize="small"
                                        imgStyles={{ borderRadius: '4px' }}
                                        fallback={
                                            <Avatar>
                                                <FileIcon file={file} />
                                            </Avatar>
                                        }
                                    />
                                </Box>
                            </TableCell>
                            <TableCell>
                                <b>{file.name}</b>
                            </TableCell>
                            <TableCell>{file.fileTypeSimple}</TableCell>
                            <TableCell>
                                {file.durationMs > 0 ? <FileDuration file={file} /> : '-'}
                            </TableCell>
                            <TableCell>{generateFileResolution(file)}</TableCell>
                            <TableCell>{file.tags.aspectRatio}</TableCell>
                            <TableCell>{formatDateString({ date: file.liveDate })}</TableCell>
                            <TableCell>{formatDateString({ date: file.endDate })}</TableCell>
                            <TableCell>
                                {formatDateString({ date: file.createdAt, removeTime: false })}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default CreativeDetailsTable;
