import React, { useCallback, useEffect, useMemo } from 'react';
import { PageConfig } from 'c-config';
import { PostAuthRoutes } from 'c-routes';
import { tagsListIcon } from 'c-main/icons';
import { PermissionName } from '@uniled/api-sdk';
import { FilterableEntityTable, FilterableEntityTableRendererType } from 'c-pagination';
import {
    displayScreenColumnsOtherTables,
    displayTagColumns,
} from 'c-displays/Components/DisplaysEntityColumns';
import { FullPageEntityTableWrapper } from 'c-wrapper';
import { useAtom } from 'jotai/index';
import { atom_TagsDialogState } from 'c-displays/atoms';
import {
    AllEntitiesDropdown,
    ControlledFormInput,
    CreateEntityButton,
    DialogV2,
    EntityAutocomplete,
    SelectField,
    TextField,
} from 'c-components';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCommonTranslation } from 'c-translation';
import { Stack } from '@mui/material';
import ReactGA from 'react-ga';

const textSearchCols = ['name'];
const paginationTag = 'displays-admin-packs-list';
const TagsListPage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const t = useCommonTranslation();
    const [atomValue, setAtomState] = useAtom(atom_TagsDialogState);
    const closeDialog = useCallback(() => {
        setAtomState({ status: null, id: null });
    }, [setAtomState]);
    const schema = yup.object({
        name: yup.string().required(),
    });

    const displayScreensTable = useMemo(
        () => (
            <DialogV2 maxWidth="lg" onClose={closeDialog} open={atomValue.id != null}>
                <FullPageEntityTableWrapper title="Modules.Displays.DisplaysList.metaTitle">
                    <FilterableEntityTable
                        key={paginationTag}
                        tag={paginationTag}
                        columns={displayScreenColumnsOtherTables}
                        baseEntityName="Displays_Screen"
                        textSearchColumns={textSearchCols}
                        rendererType={FilterableEntityTableRendererType.Contained}
                        revertToIdSearchOnNumberOnlyInput={false}
                        orderBy="id"
                        direction="desc"
                        showFilters
                        filters={{
                            'filter.status': [atomValue.status],
                            'filter.tags.id': [atomValue.id],
                        }}
                        includes={['mediaOwners', 'resolutions', 'packs', 'status']}
                        resetOnUnmount
                    />
                </FullPageEntityTableWrapper>
            </DialogV2>
        ),
        [atomValue.id, atomValue.status, closeDialog],
    );
    return (
        <>
            {displayScreensTable}
            <FullPageEntityTableWrapper
                title="Modules.Displays.Tags.metaTitle"
                controls={useMemo(
                    () => [
                        <CreateEntityButton
                            entityName="Displays_Tag"
                            title="Modules.Displays.Tags.createBtn"
                            form={<NewTagForm />}
                            buttonLabel={t('Modules.Displays.Tags.createBtn')}
                            submitLabel="Modules.Displays.Tags.submit"
                            key="new-tag-button"
                            permissions={[PermissionName.DisplaysTagsCreate]}
                            redirectPath={PostAuthRoutes.Displays.DisplaysTagEdit}
                            formOptions={{
                                resolver: yupResolver(schema),
                            }}
                        />,
                    ],
                    [schema, t],
                )}
            >
                <FilterableEntityTable
                    key={paginationTag}
                    tag={paginationTag}
                    columns={displayTagColumns}
                    baseEntityName="Displays_Tag"
                    textSearchColumns={textSearchCols}
                    rendererType={FilterableEntityTableRendererType.Contained}
                    direction="desc"
                    showFilters
                    includes={[
                        'screensCount',
                        'operationalTimeScreensCount',
                        'activeScreensCount',
                        'inactiveScreensCount',
                        'tags',
                    ]}
                />
            </FullPageEntityTableWrapper>
        </>
    );
};
const NewTagForm = () => {
    const t = useCommonTranslation();
    return (
        <Stack gap={2}>
            <ControlledFormInput
                render={({ field }) => (
                    <TextField
                        label={t('Modules.Displays.Tags.buttonForm.name')}
                        {...field}
                        autoFocus
                    />
                )}
                name="name"
            />
            <Controller
                render={({ field }) => (
                    <AllEntitiesDropdown
                        entityName="Market"
                        labelField="name"
                        label={t('Modules.Displays.Tags.buttonForm.market')}
                        {...field}
                    />
                )}
                name="market_id"
            />
            <Controller
                render={({ field }) => (
                    <AllEntitiesDropdown
                        entityName="Displays_Partner"
                        labelField="name"
                        label={t('Modules.Displays.Tags.buttonForm.partner')}
                        {...field}
                    />
                )}
                name="partner_id"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <EntityAutocomplete
                        textFieldProps={{ label: t('Modules.Displays.Tags.buttonForm.parent') }}
                        entityName="Displays_Tag"
                        {...field}
                        searchColumns="name"
                        labelColumn="name"
                    />
                )}
                name="parent_id"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <TextField
                        label={t('Modules.Displays.Tags.buttonForm.desc')}
                        {...field}
                        autoFocus
                    />
                )}
                name="description"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <SelectField
                        label={t('Modules.Displays.Tags.buttonForm.operational')}
                        value={field.value}
                        {...field}
                        options={[
                            { value: 0, label: t('Modules.Displays.Tags.buttonForm.no') },
                            { value: 1, label: t('Modules.Displays.Tags.buttonForm.yes') },
                        ]}
                    />
                )}
                name="operational"
            />
            <ControlledFormInput
                render={({ field }) => (
                    <SelectField
                        label={t('Modules.Displays.Tags.buttonForm.status')}
                        value={field.value}
                        options={[
                            { value: 0, label: t('Modules.Displays.Tags.buttonForm.inactive') },
                            { value: 1, label: t('Modules.Displays.Tags.buttonForm.active') },
                        ]}
                        {...field}
                    />
                )}
                name="status"
            />
        </Stack>
    );
};

const setup: PageConfig = {
    id: 'TagsListPage',
    component: TagsListPage,
    systemSearch: {
        title: 'Modules.Displays.Tags.metaTitle',
        description: 'Modules.Displays.Tags.description',
        icon: tagsListIcon,
        type: 'page',
        aliases: 'SystemSearch.aliases.tagsDisplayList',
        route: PostAuthRoutes.Displays.TagsList,
        saveAsRecentPage: true,
        breadcrumbs: [
            { label: 'Modules.Admin.Home.metaTitle', link: PostAuthRoutes.Admin.AdminHome },
            {
                label: 'Modules.Displays.AdminHome.metaTitle',
                link: PostAuthRoutes.Displays.DisplaysAdminHome,
            },
            { label: 'Modules.Displays.Tags.metaTitle' },
        ],
        permissions: [PermissionName.Admin],
    },
};

export default setup;
