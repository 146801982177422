import React, { forwardRef, Ref, useCallback, useMemo } from 'react';
import { format, isValid, parse, parseISO } from 'date-fns';
import { TextFieldProps } from '@mui/material';
import { useBoolean } from 'react-hanger';
import merge from 'deepmerge';
import { useDateUtils } from 'c-hooks';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro';
import { TextField } from '../TextField';

type Props = {
    value: string;
    /**
     * This is the format that will be outputted when the form is submitted. This defaults to the displayFormat
     */
    outputFormat?: string;
    /** Set this to true if the input value is in ISO8601 format */
    inputFormatISO8601?: boolean;
    error?: boolean;
    label?: string;
    onBlur?: () => void;

    inputProps?: TextFieldProps;

    onChange: (value: string) => void;

    openOnFocus?: boolean;
} & Omit<
    DatePickerProps<Date>,
    'error' | 'onChange' | 'value' | 'type' | 'label' | 'renderInput' | 'ToolbarComponent'
>;

const DateField = (
    {
        label,
        onBlur,
        onChange,
        inputProps,
        value,
        outputFormat,
        inputFormatISO8601,
        error,
        openOnFocus,
        slotProps,
        ...props
    }: Props,
    ref: Ref<any>,
) => {
    const { datePickerInputFormat, dayMonthYearApiFormat } = useDateUtils();

    const outputDateFormat = useMemo(
        () => outputFormat ?? dayMonthYearApiFormat,
        [outputFormat, dayMonthYearApiFormat],
    );

    // the format shown to users in the input field
    const inputDateFormat = useMemo(() => datePickerInputFormat, [datePickerInputFormat]);

    const datePickerValue = useMemo(() => {
        let actualValue: string | Date = value;

        if (typeof actualValue === 'string') {
            actualValue = inputFormatISO8601
                ? parseISO(actualValue)
                : parse(actualValue, outputDateFormat, new Date());
        }

        if (actualValue == null) {
            // setting to null because undefined results in today's date being selected
            actualValue = null;
        }

        return actualValue;
    }, [value, inputFormatISO8601, outputDateFormat]);

    const handleOnBlur = useCallback(() => {
        if (onBlur) {
            onBlur();
        }
    }, [onBlur]);

    const handleOnChange = useCallback(
        (date: Date) => {
            if (isValid(date)) onChange(format(date, outputDateFormat));
            else onChange(null);
        },
        [onChange, outputDateFormat],
    );

    // open picker on click
    const { value: IsOpen, setTrue: OpenDatePicker, setFalse: CloseDatePicker } = useBoolean(false);

    return (
        <DatePicker
            label={label}
            format={inputDateFormat}
            onChange={handleOnChange}
            value={datePickerValue as any as Date}
            //
            // open picker on click

            onClose={openOnFocus ? CloseDatePicker : undefined}
            onOpen={openOnFocus ? OpenDatePicker : undefined}
            open={openOnFocus ? IsOpen : undefined}
            slots={useMemo(
                () => ({
                    textField: TextField as any,
                }),
                [],
            )}
            slotProps={useMemo(
                () =>
                    merge(
                        {
                            textField: {
                                ref,
                                fullWidth: true,
                                error,
                                onBlur: handleOnBlur,
                                onClick: openOnFocus ? OpenDatePicker : undefined,
                                ...inputProps,
                            },
                            actionBar: {
                                actions: ['clear', 'accept'],
                            },
                        },
                        { ...slotProps },
                    ),
                [OpenDatePicker, error, handleOnBlur, inputProps, openOnFocus, ref, slotProps],
            )}
            {...props}
        />
    );
};

export default forwardRef(DateField);
