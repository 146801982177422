import React from 'react';
import { Stack } from '@mui/material';
import { AllEntitiesDropdown, ControlledFormInput, TextField } from 'c-components';
import { Translate } from 'c-translation';
import { PopDisplays_Display } from '@uniled/api-sdk';

type Props = {
    display?: PopDisplays_Display;
};
const DisplayForm: React.FC<Props> = ({ display }) => (
    <Stack gap={2}>
        <ControlledFormInput
            render={({ field }) => (
                <AllEntitiesDropdown
                    entityName="PopDisplays_LogImportSource"
                    labelField="name"
                    valueField="name"
                    label={<Translate path="Modules.PopDisplays.Display.newDisplay.sourceLabel" />}
                    {...field}
                />
            )}
            name="source"
        />
        <ControlledFormInput
            render={({ field }) => (
                <TextField
                    label={
                        <Translate path="Modules.PopDisplays.Display.newDisplay.displayPortalLabel" />
                    }
                    {...field}
                />
            )}
            name="displays_portal_id"
        />
        {/* <ControlledFormInput */}
        {/*    render={({ field }) => ( */}
        {/*        <EntityAutocomplete */}
        {/*            entityName="Displays_Screen" */}
        {/*            multiple={false} */}
        {/*            searchColumns="any" */}
        {/*            labelColumn="name" */}
        {/*            autoLoadSearchTerm={display?.name ?? ''} */}
        {/*            labelGen={(entity: Displays_Screen) => entity.address} */}
        {/*            textFieldProps={{ */}
        {/*                label: ( */}
        {/*                    <Translate path="Modules.PopDisplays.Display.newDisplay.displayPortalSearchLabel" /> */}
        {/*                ), */}
        {/*            }} */}
        {/*            {...field} */}
        {/*        /> */}
        {/*    )} */}
        {/*    name="displays_portal_id" */}
        {/* /> */}
        <ControlledFormInput
            render={({ field }) => (
                <TextField
                    label={<Translate path="Modules.PopDisplays.Display.newDisplay.channelLabel" />}
                    {...field}
                />
            )}
            name="channel"
        />
        <ControlledFormInput
            render={({ field }) => (
                <TextField
                    label={<Translate path="Modules.PopDisplays.Display.newDisplay.frameLabel" />}
                    {...field}
                />
            )}
            name="frame"
        />
        <ControlledFormInput
            render={({ field }) => (
                <TextField
                    label={
                        <Translate path="Modules.PopDisplays.Display.newDisplay.displayKeyLabel" />
                    }
                    {...field}
                />
            )}
            name="display_key"
        />
    </Stack>
);

export default DisplayForm;
