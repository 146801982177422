import { ascend, sort, toLower, uniq } from 'ramda';
import {
    ElasticSearchAvailableFilters,
    EsPCAReportFilterField,
    PCAMapReportPlayStats,
    PCAMapReportResponse,
    PCAReportField,
} from '@uniled/api-sdk';
import { isPlainObject } from '@reduxjs/toolkit';
import { sortByName } from 'c-main/Components/Campaign/CampaignReports/Views/ByField/ChartTypes/sorting';
import { Direction } from 'c-types';

export type AllPCAFieldValues = Record<PCAReportField, string[]>;
export type AllEsPCAFieldValues = Record<EsPCAReportFilterField, string[]>;

export const allFieldValues = (report: PCAMapReportResponse): AllPCAFieldValues => {
    const sortFunc = ascend(toLower);

    const data = Object.keys(report.reportData).reduce(
        (acc, curr) => {
            const [
                actual,
                expected,
                percentage,
                inSchedule,
                outSchedule,
                unbooked,

                latitude,
                longitude,

                town,
                tvRegion,
                surfaceArea,
                conurbation,
                environment,
                format,
                lineItemName,
                mediaOwner,
                resolution,
            ] = report.reportData[curr] as PCAMapReportPlayStats;

            acc[PCAReportField.Town].push(town);
            acc[PCAReportField.TvRegion].push(tvRegion);
            acc[PCAReportField.Conurbation].push(conurbation);
            acc[PCAReportField.Environment].push(environment);
            acc[PCAReportField.Format].push(format);
            acc[PCAReportField.MediaOwner].push(mediaOwner);
            acc[PCAReportField.FrameId].push(curr);
            acc[PCAReportField.LineItemName].push(lineItemName);
            // acc[PCAReportField.SurfaceArea].push(surfaceArea);
            // acc[PCAReportField.Resolution].push(resolution);
            // acc[PCAReportField.CreativeName].push();
            // acc[PCAReportField.DayOfWeek].push();
            return acc;
        },
        {
            [PCAReportField.Town]: [],
            [PCAReportField.TvRegion]: [],
            [PCAReportField.Conurbation]: [],
            [PCAReportField.Environment]: [],
            [PCAReportField.Format]: [],
            [PCAReportField.MediaOwner]: [],
            [PCAReportField.FrameId]: [],
            [PCAReportField.LineItemName]: [],
            [PCAReportField.SurfaceArea]: [],
            [PCAReportField.CreativeName]: [],
            [PCAReportField.DayOfWeek]: [],
            [PCAReportField.Resolution]: [],
        } as AllPCAFieldValues,
    );

    return Object.keys(data).reduce((acc, curr) => {
        acc[curr as PCAReportField] = sort(
            sortFunc,
            uniq(data[curr as PCAReportField]),
        ) as string[];
        return acc;
    }, data);
};

export const allFieldValuesEs = (
    reportFilters: ElasticSearchAvailableFilters,
    selfRefine = false,
): AllEsPCAFieldValues =>
    Object.entries(reportFilters ?? {}).reduce((acc, [filter, values]) => {
        if (isPlainObject(values) && Object.keys(values).length > 0) {
            let options: string[] = [];
            if (!selfRefine) options = Object.keys(values);
            else {
                /**
                 * if self refine is turned on, look for options with a `true` boolean value
                 * that indicates it can be used for further refining
                 */
                const trueOpts = Object.entries(values)
                    .filter(([, turnedOn]) => turnedOn === true, [] as string[])
                    .reduce((labelList, [label]) => [...labelList, label], [] as string[]);

                // sorting the options
                if (trueOpts.length > 0) options = trueOpts;
            }

            if (Array.isArray(options)) {
                acc[filter] = sortByName(
                    options.map(opt => ({ name: opt })),
                    Direction.ASC,
                    'name',
                    filter,
                    'dd-MMM-yyyy',
                ).map(opt => opt.name);
            }
        }
        return acc;
    }, {} as AllEsPCAFieldValues);
