import React, { useMemo } from 'react';
import { Box, Tooltip } from '@mui/material';
import { HighlightWord } from 'c-components';

type Props = { value: string; highlightText: string; wrapInTooltip?: boolean };
const EntityDataTextColumn: React.FC<Props> = ({ value, highlightText, wrapInTooltip = false }) => {
    const content = useMemo(() => {
        if (typeof (value as any) == 'boolean') {
            return <Box>{(value as any).toString()}</Box>;
        }

        return (
            <Box>
                <HighlightWord text={value ?? ''} searchTerm={highlightText} />
            </Box>
        );
    }, [value, highlightText]);

    if (wrapInTooltip && typeof (value as any) === 'string') {
        return <Tooltip title={value}>{content}</Tooltip>;
    }

    return <>{content}</>;
};

export default EntityDataTextColumn;
