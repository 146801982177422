import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { Translate } from 'c-translation';
import { useFieldArray } from 'react-hook-form';
import { ScheduleFormData } from 'c-main/Components/Schedule/types';
import { DateRangePickerProps } from '@mui/x-date-pickers-pro';
import { usePrevious } from 'react-hanger';
import { AddCircle } from '@mui/icons-material';
import { emptyFormDataRule } from 'c-main/Components/Schedule/templates';
import { JoinComponents } from 'c-components';
import RuleForm from './RuleForm';

type Props = {
    hideDates: boolean;
    dateRangeSlotProps: DateRangePickerProps<any>['slotProps'];
};

const RuleFormList: React.FC<Props> = ({ dateRangeSlotProps, hideDates }) => {
    const { append, fields, remove } = useFieldArray<ScheduleFormData, 'rules'>({ name: 'rules' });
    const addDate = useCallback(() => {
        append(emptyFormDataRule());
    }, [append]);

    const [openDateIndex, setOpenDateIndex] = useState(0);

    const prevFieldLength = usePrevious(fields.length);
    useEffect(() => {
        if (
            // just added a rule, open it
            fields.length > prevFieldLength ||
            // one was deleted and the current open date form no longer exists
            (fields.length < prevFieldLength && openDateIndex > fields.length - 1)
        ) {
            setOpenDateIndex(fields.length - 1);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields.length]);

    const rules = useMemo(
        () => (
            <JoinComponents
                components={fields.map((field, fi) => (
                    <RuleForm
                        key={field.id}
                        index={fi}
                        remove={remove}
                        isOpen={openDateIndex === fi}
                        onOpen={setOpenDateIndex}
                        dateRangeSlotProps={dateRangeSlotProps}
                        hideDates={hideDates}
                    />
                ))}
                separator={<Divider />}
            />
        ),
        [fields, remove, openDateIndex, dateRangeSlotProps, hideDates],
    );
    return (
        <Box position="relative">
            <Typography variant="h6">
                <Translate path="Modules.Main.Campaigns.CampaignSchedule.Form.rulesHeader" />
                <IconButton size="small" color="primary" onClick={addDate}>
                    <AddCircle />
                </IconButton>
            </Typography>
            <Stack gap={1}>{rules}</Stack>
        </Box>
    );
};

export default RuleFormList;
