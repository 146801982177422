import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { Box, Tooltip } from '@mui/material';
import { AllEntities, BaseEntity } from '@uniled/api-sdk';
import {
    EntityListColumnRelationship,
    EntityRelationshipListColumnProps,
} from 'c-pagination/types';
import { uniq } from 'ramda';
import { Button, Dialog, EntityField } from 'c-components';
import { useBoolean } from 'react-hanger';
import { Translate, useCommonTranslation } from 'c-translation';

const DefaultWrapper: React.FC<PropsWithChildren> = ({ children }) => <Box>{children}</Box>;
const DefaultLabelWrapper: React.FC<PropsWithChildren> = ({ children }) => <Box>{children}</Box>;

function EntityRelationListColumn<Entity extends BaseEntity>({
    entity,
    relationProps: {
        relationships,
        RenderWrapper = DefaultWrapper,
        RenderLabelWrapper = DefaultLabelWrapper,
        maxShowCount = 5,
        dialogTitle = 'Pagination.moreDialogDefaultTitle',
    },
}: {
    entity: Entity;
    relationProps: EntityRelationshipListColumnProps;
}) {
    /**
     * Get a unique list of IDs per entity
     */
    const relationIds = useMemo(
        () =>
            relationships.reduce((acc, curr) => {
                let ids = entity?.[curr.relationIdKey];
                ids = Array.isArray(ids) ? ids : [ids];
                if (acc[curr.entityName] == null) {
                    acc[curr.entityName] = { relation: curr, ids: [] };
                }
                acc[curr.entityName].ids.push(...ids);
                acc[curr.entityName].ids = uniq(acc[curr.entityName].ids);

                return acc;
            }, {} as Record<keyof AllEntities, { ids: number[]; relation: EntityListColumnRelationship<any> }>),
        [entity, relationships],
    );

    const labels = useMemo(() => {
        return Object.entries(relationIds).reduce((acc, [, settings]) => {
            settings.ids.forEach(id => {
                acc.push(
                    <EntityField
                        id={id}
                        entityName={settings.relation.entityName}
                        field={settings.relation.relationLabelField}
                        Render={settings.relation.Render}
                    />,
                );
            });
            return acc;
        }, [] as React.ReactNode[]);
    }, [relationIds]);

    const renderLabels = useCallback(
        (labels: React.ReactNode[]) => (
            <RenderWrapper>
                {labels.map((lab, li) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={li}>
                        <RenderLabelWrapper>{lab}</RenderLabelWrapper>
                    </React.Fragment>
                ))}
            </RenderWrapper>
        ),
        [RenderWrapper, RenderLabelWrapper],
    );

    const firstXLabels = useMemo(
        () => renderLabels(labels.slice(0, maxShowCount)),
        [labels, maxShowCount, renderLabels],
    );
    const allLabels = useMemo(() => renderLabels(labels), [renderLabels, labels]);

    const moreDialog = useBoolean(false);
    const moreCount = useMemo(
        () => (labels?.length > maxShowCount ? Math.abs(labels.length - maxShowCount) : 0),
        [labels.length, maxShowCount],
    );
    const moreLabel = useCommonTranslation('Pagination.moreCount', {
        count: moreCount,
    });
    const onMoreClick = useCallback(
        e => {
            e.stopPropagation();
            moreDialog.setTrue();
        },
        [moreDialog],
    );
    const onClose = useCallback(
        e => {
            e.stopPropagation();
            moreDialog.setFalse();
        },
        [moreDialog],
    );

    return (
        <>
            {firstXLabels}
            {moreCount > 0 && (
                <Tooltip title={allLabels}>
                    <Button size="small" onClick={onMoreClick}>
                        {moreLabel}
                    </Button>
                </Tooltip>
            )}

            {moreCount > 0 && (
                <Box onClick={e => e.stopPropagation()}>
                    <Dialog
                        title={<Translate path={dialogTitle} />}
                        removeForm
                        onClose={onClose}
                        show={moreDialog.value}
                        content={<>{allLabels}</>}
                    />
                </Box>
            )}
        </>
    );
}

export default EntityRelationListColumn;
