import React from 'react';
import { permissionDiffs } from 'c-admin/Lib';
import { Role } from 'c-entity-types';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { roleSelectors } from 'c-admin/Slices/Role';

type Props = {
    ids: number[];
    role: Role;
};

const EntityPermissionChangeCounts: React.FC<Props> = ({ ids, role }) => {
    const val = useSelector(roleSelectors.getPermissionsDictionary);
    const diff = permissionDiffs(val, ids, role.permissions);

    return (
        <Grid container spacing={2}>
            {diff.added.length > 0 && (
                <Grid item>
                    <Typography color="success.main">+ {diff.added.length}</Typography>
                </Grid>
            )}
            {diff.removed.length > 0 && (
                <Grid item>
                    <Typography color="error.main">- {diff.removed.length}</Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default EntityPermissionChangeCounts;
