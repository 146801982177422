import { Campaign } from '@uniled/api-sdk';
import { baseInitialState, createBaseSlice } from 'c-data';
import { CampaignState } from './Campaign-types';

const initialState: CampaignState = {
    ...baseInitialState,
};

const additionalReducers = {
    // example: (state: CampaignState, action: PayloadAction<string>): CampaignState => state,
};

const slice = createBaseSlice<Campaign, CampaignState, typeof additionalReducers>({
    name: 'campaign',
    entityName: 'Campaign',
    initialState,
    reducers: additionalReducers,
    adapterOptions: {
        customMerge: key => {
            // always use the new notices
            if (key === 'notices') return (a, b) => b;

            return undefined;
        },
    },
});

const campaignSlice = {
    campaignActions: slice.slice.actions,
    campaignReducer: slice.slice.reducer,
    campaignAdapter: slice.adapter,
    campaignSelectors: slice.selectors,
    campaignThunks: slice.thunks,
};

export default campaignSlice;
export const {
    campaignReducer,
    campaignActions,
    campaignAdapter,
    campaignSelectors,
    campaignThunks,
} = campaignSlice;
