import React from 'react';
import { CampaignLineItemCost } from 'c-main/Components/Campaign/CampaignLineItems';
import useSelectedLineItems from '../../../CampaignLineItems/CampaignLineItemPicker/useSelectedLineItems';

const paginationTag = 'create-campaign-line-items-costing';

const CostingStep = () => {
    const { value: SelectedLineItemIds } = useSelectedLineItems();
    return (
        <CampaignLineItemCost
            selectedLineItems={SelectedLineItemIds}
            paginationTag={paginationTag}
        />
    );
};

export default CostingStep;
