import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { Autocomplete, OptionSchema } from 'c-components';
import { useCommonTranslation } from 'c-translation';
import { useAuthenticatedUser } from 'c-hooks';
import { Skin, useEntityData } from 'c-data';

type Props = {
    onChange: (skinId: number) => void;
    selectedSkinId: number;
};

const NonAdminSkinSwitcher: React.FC<Props> = ({ onChange, selectedSkinId }) => {
    const { keyedEntities } = useEntityData<Skin>('Skin');
    const { user } = useAuthenticatedUser();
    const options = useMemo<OptionSchema[]>(() => {
        if (Array.isArray(user?.skins)) {
            return [
                ...user?.skins
                    .filter(entityId => keyedEntities[entityId] != null)
                    .map(entityId => ({
                        value: keyedEntities[entityId]?.id,
                        label: keyedEntities[entityId]?.name,
                    })),
            ];
        }

        return [];
    }, [user?.skins, keyedEntities]);

    return (
        <Box py={2}>
            <Autocomplete
                options={options}
                multiple={false}
                value={selectedSkinId}
                onChange={onChange}
                textFieldProps={{
                    label: useCommonTranslation('Modules.Admin.SkinSwitcher.dropdownLabel'),
                }}
            />
        </Box>
    );
};

export default NonAdminSkinSwitcher;
